<div id="cpt-codes-tab" class="ibox flex-col" style="overflow: hidden;">
	<div class="ibox-title flex-thin" style="align-items: center;">
		<h2 style="flex: 1;">CPT Codes</h2>
		<button class="base-button base-button--square" style="margin-right: 10px;" *ngIf="showClearButton" (click)="clearSavedSettings()" tooltip="Clear table preferences">
			<svg-icon [icon]="svgIconNames.Preferences" class="base-icon base-icon--small"></svg-icon>
		</button>
		<a class="base-button" *aclIsAllowed="'settings.edit_cpt_codes'" [routerLink]="['/settings/cpt-codes/new']">New CPT Code</a>
	</div>

	<ag-grid-angular
		#agGrid
		class="ag-theme-balham grid-list"
		[columnDefs]="columnDefinitions"
		[rowData]="cptCodes$ | async"
		[gridOptions]="gridOptions"
		(firstDataRendered)="onFirstDataRendered()"
	></ag-grid-angular>
</div>
