import { Funding } from '@app/shared/model/types/funding';
import * as moment from 'moment/moment';

export const getDateToFollowUp = (funding: Funding, minDate: Date): Date => {
	const dateToFollowUpMoment = moment(funding?.dateToFollowUp);
	if (funding?.dateToFollowUp && dateToFollowUpMoment.isAfter(moment(minDate))) {
		return dateToFollowUpMoment.toDate();
	}
	return minDate;
}
