import { Component, Input } from '@angular/core';
import { TodoCategory } from '@app/todo/model/todo-category';

@Component({
	selector: 'todo-category-labels',
	template: `
		<ng-container *ngIf="todoCategories?.length > 0; else noCategory">
			<div
				*ngFor="let category of todoCategories"
				[ngClass]="wrapperClass"
				[style.color]="category.colorHex"
				data-cy="todo-category-label">
				{{ category.title | truncate:0:15:'...' }}
			</div>
		</ng-container>
		<ng-template #noCategory>
			<div>
				<div [ngClass]="wrapperClass">
					No categories
				</div>
			</div>
		</ng-template>
	`
})
export class TodoCategoryLabelsComponent {
	@Input() todoCategories: TodoCategory[];
	@Input() wrapperClass = '';
}
