import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CollapsibleItemComponent } from '@app/dashboard/collapsible-item/collapsible-item.component';
import { GroupedProgressBarChartComponent } from '@app/dashboard/grouped-progress-chart/grouped-progress-bar-chart.component';
import { DatePickerRangesService } from '@app/shared/daterange-picker/date-picker-ranges/date-picker-ranges.service';
import { ModalsModule } from '@app/shared/modals/modals.module';
import { ModalsService } from '@app/shared/modals/modals.service';
import { SharedModule } from '@app/shared/shared.module';
import { NgChartsModule } from 'ng2-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ModalsModule,
		BsDatepickerModule,
		NgChartsModule,
		SharedModule,
		CollapsibleItemComponent
	],
	declarations: [
		GroupedProgressBarChartComponent,
	],
	exports: [
		GroupedProgressBarChartComponent,
	],
	providers: [
		ModalsService,
		DatePickerRangesService
	],
})
export class RpChartsModule {
}
