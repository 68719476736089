import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

export const getSumByAllProperties = <T>() => pipe(
	map((monthsBreakdown: T[]) => {
		const totalSumByProperty: Partial<T> = monthsBreakdown.reduce<Partial<T>>(
			(acc, value) => {
				Object.entries(value).forEach(([property, amount]) => {
					if (property in acc) {
						acc[property] += amount;
					} else {
						acc[property] = amount;
					}
				});
				return acc;
			},
			{}
		);
		return [monthsBreakdown, totalSumByProperty];
	})
);

export const addPercentage = <T>() => {
	return pipe(
		map(([breakdowns, totalSum]: [T[], T]) => {
			return breakdowns.map(breakdown => {
				const breakdownPercentage = Object.keys(breakdown)
					.filter(key => key !== 'title')
					.reduce<Partial<Percentage<T>>>((acc, breakdownProperty) => {
						const percentagePropertyName = `${breakdownProperty}Percentage`;
						if (totalSum[breakdownProperty] === 0) {
							acc[percentagePropertyName] = 0;
						} else {
							acc[percentagePropertyName] =
								breakdown[breakdownProperty] / totalSum[breakdownProperty];
						}
						return acc;
					}, {});
				return { ...breakdown, ...breakdownPercentage } as Required<T & Percentage<T>>;
			});
		})
	)
};

export type Percentage<T> = {
	[property in keyof (Omit<T, 'title'> | Record<string, number>) as `${property}Percentage`]: number;
}
