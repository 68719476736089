<div class="modal-body">
	<h2>Vintage Detail</h2>
	<div *ngIf="config.filter.fundedOn || config.filter.settledOn || config.filter.createdOn"
		 class="alert alert-info">
		<ul>
			<li *ngIf="config.filter.fundedOn">
				<ng-template [ngTemplateOutlet]="dateTemplate"
							 [ngTemplateOutletContext]="{path: 'fundedOn', message: 'funded on'}"></ng-template>
			</li>
			<li *ngIf="config.filter.settledOn">
				<ng-template [ngTemplateOutlet]="dateTemplate"
							 [ngTemplateOutletContext]="{path: 'settledOn', message: 'settled on'}"></ng-template>
			</li>
			<li *ngIf="config.filter.createdOn">
				<ng-template [ngTemplateOutlet]="dateTemplate"
							 [ngTemplateOutletContext]="{path: 'createdOn', message: 'created on'}"></ng-template>
			</li>
		</ul>
	</div>

	<ag-grid-angular
		class="ag-theme-balham grid-list"
		[columnDefs]="config.columnDefinitions"
		[rowData]="fundings$ | async"
		[gridOptions]="gridOptions"
		(gridReady)="onGridReady($event)"
	></ag-grid-angular>
</div>

<ng-template #dateTemplate let-path="path" let-message="message">
	Showing deals {{ message }}
	<b>
		<ng-container *ngIf="config.filter[path]?.from; else singleDate">
			{{config.filter[path].from | date : 'mediumDate'}} - {{config.filter[path].to | date : 'mediumDate'}}
		</ng-container>
		<ng-template #singleDate>
			{{ config.filter[path] | date : 'mediumDate' }}
		</ng-template>
	</b>
</ng-template>
