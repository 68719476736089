export class UniqueFieldValidatorRequest {
	private readonly _fieldId;
	private _response;
	private readonly _fieldValue;
	private readonly _entityClass;
	private _timeToLive = 10;

	constructor(fieldId: string, response: object, fieldValue: string, entityClass: string) {
		this._fieldId = fieldId;
		this._response = response;
		this._fieldValue = fieldValue;
		this._entityClass = entityClass;
	}

	public isSame(request: UniqueFieldValidatorRequest, updateTTL = true): boolean {
		const isSame = parseInt(this._fieldId) == parseInt(request.fieldId)
			&& this._fieldValue == request.fieldValue
			&& this._entityClass == request.entityClass;
		if (updateTTL) {
			this._timeToLive = isSame ? 10 : this._timeToLive - 1;
		}
		return isSame;
	}

	get fieldId() {
		return this._fieldId;
	}

	get response() {
		return this._response;
	}

	set response(newResponse: object) {
		this._response = newResponse;
	}

	get fieldValue() {
		return this._fieldValue;
	}

	get entityClass() {
		return this._entityClass;
	}

	get timeToLive() {
		return this._timeToLive;
	}
}
