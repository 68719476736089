<ng-container *ngIf="isGrouping">
	{{ params.value }}
</ng-container>
<ng-container *ngIf="!isGrouping && values?.length > 1">
	<p (click)="open()">
		<fa-icon [icon]="isOpen ? faIcons.chevronDown : faIcons.chevronRight"></fa-icon>
		List ({{values.length}})
	</p>
	<ul *ngIf="isOpen" class="list-unstyled" style="margin: 0">
		<li *ngFor="let item of values" [innerHTML]="item"></li>
	</ul>
</ng-container>
<ng-container *ngIf="!isGrouping && values?.length == 1">
	<span [innerHTML]="values[0]"></span>
</ng-container>
<ng-container *ngIf="!isGrouping && values?.length == 0">
	None
</ng-container>
