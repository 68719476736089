import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GaSelectEditorOption } from '@app/shared/ga-components/components/editors/ga-select-editor.model';
import { GaSelectEditorParams } from '@app/shared/ga-components/components/editors/ga-select-editor.params';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IEditor } from '@app/shared/ga-components/utils/IEditor';
import { toNumber } from '@app/shared/general-helper';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Subject, startWith, take, takeUntil, tap, withLatestFrom } from 'rxjs';

@Component({
	selector: 'ga-select-editor',
	templateUrl: './ga-select-editor.component.html'
})
export class GaSelectEditorComponent implements IEditor, OnDestroy {
	cssClass = 'ga-select';
	typeahead$: Subject<string> = new Subject();
	options$ = new BehaviorSubject<GaSelectEditorOption[]>([]);
	selectedOption$ = new BehaviorSubject<GaSelectEditorOption[] | GaSelectEditorOption>([]);
	componentMetadata: ComponentMetadata;
	params: GaSelectEditorParams;
	formGroup: UntypedFormGroup;
	viewFormGroup: UntypedFormGroup;
	onFocusEmitter: EventEmitter<boolean>;
	isLoading$ = new BehaviorSubject<boolean>(true);
	private stopDisabling$ = new Subject<void>();
	private destroy$ = new Subject();

	constructor() {
		this.viewFormGroup = new UntypedFormGroup({
			control: new UntypedFormControl()
		});
	}

	public init(value): void {
		// finds options from given source (backend endpoint, jql) based on typeahead$ string
		//
		// purpose of withLatestFrom -> selected options disappear, when filtered option didn't contain them
		// example: selected options: [Micro Law, Mega Law], typeahead$: 'avrek'
		// result array contains [Avrek Law, Avrek Law San Francisco], so already selected options cannot match any of found results,
		// which breaks later login in combineLatest, where selected options are picked from options$ and set to viewFormGroup and formGroup
		// so request was sent without already selected options
		this.params.onInput(
			this.typeahead$.pipe(
				startWith(''),
				tap(() => this.isLoading$.next(true)),
				takeUntil(this.destroy$)
			)
		).pipe(
			withLatestFrom(this.selectedOption$),
			takeUntil(this.destroy$)
		).subscribe(([results, selectedOptions]: [any[], GaSelectEditorOption[] | GaSelectEditorOption]) => {
			const options = _.chain(results)
				.map((item: any) => ({
					id: item.id,
					label: this.componentMetadata.valueFormatter(item),
					orderIndex: item?.orderIndex,
					rawItem: item
				}))
				.concat(selectedOptions ?? [])
				.uniqBy((option: GaSelectEditorOption) => toNumber(option.id))
				.value();

			this.options$.next(options);
			this.isLoading$.next(false);
			this.stopDisablingForm();
		});

		combineLatest([this.options$, this.formGroup.get(this.componentMetadata.fieldMetadata.fieldName).valueChanges])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([options, formValue]) => {
				if (options.length) {
					if (this.params.multiple) {
						const selectedOptions = formValue?.map(id => options.find(option => toNumber(option.id) === toNumber(id)))
						this.viewFormGroup.get('control').setValue(selectedOptions, { emitEvent: false });
						this.selectedOption$.next(selectedOptions);
					} else {
						const selectedOption = options.find(option => toNumber(option.id) === toNumber(formValue));
						this.viewFormGroup.get('control').setValue(selectedOption, { emitEvent: false });
						this.selectedOption$.next(selectedOption);
					}
				}
			});

		// disable viewFormGroup when loading - only first time
		this.isLoading$.pipe(takeUntil(this.stopDisabling$))
			.subscribe((isLoading: boolean) => {
				if (isLoading) {
					this.viewFormGroup.disable({ emitEvent: false });
				} else {
					this.viewFormGroup.enable({ emitEvent: false });
				}
			});

		this.update(value);
	}

	public update(value): void {
		this.setModelValue(value);
		this.emitOnSelectChange();
	}

	public onChange(value) {
		this.setModelValue(value);
		this.emitOnSelectChange();
	}

	public onFocus($event) {
		if (this.onFocusEmitter) {
			this.onFocusEmitter.emit($event);
		}
	}

	public setModelValue(value) {
		//TODO: handles objects, ids and list of objects, should be able to handle list of ids as well
		if (value) {
			if (typeof value === 'string' || typeof value === 'number') {
				this.formGroup.get(this.componentMetadata.fieldMetadata.fieldName).setValue(value);
			} else {
				const normedValue = this.params.multiple
					? _.map(value, 'id')
					: (this.componentMetadata.valueParser ? this.componentMetadata.valueParser(value.id) : value.id);
				this.formGroup.get(this.componentMetadata.fieldMetadata.fieldName).setValue(normedValue);
			}
		} else if (_.isNil(value)) {
			this.reset();
		}
	}

	public reset() {
		const value = this.params.multiple ? [] : null;
		this.viewFormGroup.get('control').reset(value, { emitEvent: false });
		this.formGroup.get(this.componentMetadata.fieldMetadata.fieldName).reset(value);
	}

	public isPristine(): boolean {
		return this.viewFormGroup.pristine;
	}

	private emitOnSelectChange() {
		if (this.params.onSelect) {
			this.selectedOption$
				.pipe(
					take(1),
					takeUntil(this.destroy$))
				.subscribe((selectedOption) => {
					this.params.onSelect.next(selectedOption);
				});
		}
	}

	private stopDisablingForm() {
		this.stopDisabling$.next(null);
		this.stopDisabling$.complete();
	}

	public ngOnDestroy(): void {
		this.stopDisablingForm();
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
