import { Component, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GaEditorCellComponent } from '@app/ga-grid/components/ga-editor-cell.component';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IEditor } from '@app/shared/ga-components/utils/IEditor';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
	selector: 'ga-large-input-editor',
	template: `
		<ng-container [formGroup]="viewFormGroup" (click)="showModal()">
			{{value}}
		<ng-template #text>
			<div class="row" style="padding: 10px;">
				<h3 class="col-12">Updating {{componentMetadata.label}}</h3>
		<textarea class="col-12" rows="10" cols="60" formControlName="control"></textarea>
		<button class="btn btn-primary" (click)="save()">Submit</button>
			</div>
		</ng-template>
		</ng-container>
	`
})
export class GaLargeInputEditorComponent implements IEditor{

	@ViewChild('text', { static: true }) text: TemplateRef<any>;
	params: any;
	componentMetadata: ComponentMetadata;
	formGroup: UntypedFormGroup;
	viewFormGroup: UntypedFormGroup;
	onFocusEmitter: EventEmitter<boolean>;
	cssClass: string;
	value: string;
	bsModalRef: BsModalRef;

	constructor(private modalService: BsModalService,
				private gaEditor: GaEditorCellComponent) {
		this.viewFormGroup = new UntypedFormGroup({
			control: new UntypedFormControl()
		});
	}

	public init(value): void {
		this.params = this.gaEditor.agParams;
		this.value = value;
		this.modalService.onHide.subscribe(() => {
			this.params.api.stopEditing();
			this.params.api.resetRowHeights();
		});
	}

	public update(value): void {
		this.value = value;
		if (this.viewFormGroup.get('control')) {
			this.viewFormGroup.get('control').setValue(this.componentMetadata.valueFormatter(value));
		}
		this.showModal();
	}

	public save() {
		this.value = this.componentMetadata.valueParser
			? this.componentMetadata.valueParser(this.viewFormGroup.controls.control.value) : this.viewFormGroup.controls.control.value;
		this.formGroup
			.get(this.componentMetadata.fieldName)
			.setValue(this.value);
		this.bsModalRef.hide();
	}

	public showModal() {
		if (!this.bsModalRef) {
			this.bsModalRef = this.modalService.show(this.text);
		}
	}

	public reset() {
		this.formGroup
			.get(this.componentMetadata.fieldName)
			.setValue('');
	}

	public isPristine(): boolean {
		return this.viewFormGroup.pristine;
	}
}
