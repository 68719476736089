import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GaEditorParams } from '@app/shared/ga-components/components/editors/ga-editor.params';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IEditor } from '@app/shared/ga-components/utils/IEditor';

@Component({
	selector: 'ga-checkbox-editor',
	template: `<ng-container [formGroup]="formGroup">
		<input type="checkbox"
			[attr.data-cy]="componentMetadata.fieldName + '-checkbox'"
			[class]="params.cssClass" [ngClass]="cssClass"
			(focus)="onFocus(true)"
			(blur)="onFocus(false)"
			[formControlName]="componentMetadata.fieldName" />
	</ng-container>
	`,
})
export class GaCheckboxEditorComponent implements IEditor {
	cssClass = 'ga-checkbox';
	componentMetadata: ComponentMetadata;
	params: GaEditorParams;
	formGroup: UntypedFormGroup;
	onFocusEmitter: EventEmitter<boolean>;
	entityObject;

	public update(value: any, dataObj: any): void {
		this.entityObject = dataObj;
		value = value ?? false;
		this.formGroup.get(this.componentMetadata.fieldName).setValue(value);
	}

	public init(value, data): void {
		this.update(value, data);
	}

	public onFocus($event): void {
		if (this.onFocusEmitter) {
			this.onFocusEmitter.emit($event);
		}
	}

	public reset(): void {
		this.formGroup.get(this.componentMetadata.fieldName).setValue(false);
	}

	public isPristine(): boolean {
		return this.formGroup.pristine;
	}
}
