/* eslint-disable no-console */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebSocketMessageType } from '@app/core/web-socket/web-socket-message-type';
import { WebSocketService } from '@app/core/web-socket/web-socket.service';
import { objectStoresMappingConfig } from '@app/database/object-stores-mapping.config';
import { CodelistListItem, CodelistMeta } from '@app/shared/model/types/codelist-meta';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { catchError, filter, map, Observable, of, retry, Subject, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class CodelistRepositoryService {

	private onReload$ = new Subject();

	get onReload() {
		return this.onReload$.asObservable();
	}

	constructor(
		private http: HttpClient,
		private webSocketService: WebSocketService,
		private dbService: NgxIndexedDBService
	) {
		this.startListeningForUrlCodelistUpdate();
	}

	public fetchCodelistsList(): Observable<CodelistListItem[]> {
		return this.http.get<CodelistListItem[]>('/api/code-lists/_list').pipe(
			catchError((errorResponse: unknown) => this.handleCodelistsApiError<CodelistListItem>(errorResponse)),
		);
	}

	public fetchCodelistWithMetadata(slug: string): Observable<CodelistMeta> {
		const params = { params: { meta: '1', activeOnly: '0' } };
		return this.http.get<CodelistMeta>(`/api/code-lists/${slug}`, params).pipe(
			catchError((errorResponse: unknown) => this.handleSingleCodelistApiError<CodelistMeta>(errorResponse)),
		);
	}

	public get<T>(codelistClass: string): Observable<T> {
		console.log('* Fetching ' + codelistClass + ' from a server.');
		return this.http.get<T>(`/api/v2/code-lists/${this.urlize(codelistClass)}`)
			.pipe(catchError((errorResponse: unknown) => this.handleCodelistsApiError(errorResponse))) as Observable<T>;
	}

	public getByUrl<T>(url: string): Observable<T & { key?: unknown }[]> {
		const handleRequestedData$ = this.http.get(`/api/${url}`).pipe(
			switchMap((codelistData: T & { key?: unknown }[]) =>
				this.dbService.bulkAdd(url, codelistData).pipe(map(() => codelistData))),
		);
		const codelistData$ = this.dbService.count(url).pipe(
			tap({
				error: () => {
					this.dbService.createObjectStore(objectStoresMappingConfig[url]);
					console.warn(objectStoresMappingConfig[url]);
				}
			}),
			retry(),
			switchMap((itemsCount) =>
				itemsCount === 0 ? handleRequestedData$ : this.dbService.getAll<T & { key?: unknown }[]>(url)
			),
			catchError((errorResponse: unknown) => this.handleCodelistsApiError(errorResponse))
		);
		return codelistData$ as Observable<T & { key?: unknown }[]>;
	}

	private urlize(value) {
		return value.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
	}

	private startListeningForUrlCodelistUpdate() {
		// FIXME: resolve multiple tabs - new message triggers update on all tabs. One update should be enough!
		// https://dev.to/arielgueta/keeping-browser-tabs-in-sync-using-localstorage-angular-akita-p39
		this.webSocketService
			.getConnection()
			.pipe(
				filter((message) => message.type == WebSocketMessageType.UpdateUrlCodelistCache),
				switchMap(({ data }) => this.dbService.clear(data.url).pipe(map(() => data))),
				switchMap((data) => this.dbService.bulkAdd(data.url, JSON.parse(data.payload)).pipe(
					map(() => data.url)
				)),
			).subscribe((url) => {
			console.log(`* Received command to update URL codelist: ${url}`);
			this.onReload$.next(null);
		});
	}

	private handleCodelistsApiError<T>(errorResponse): Observable<T[]> {
		console.error('* CodeList could not be loaded.', errorResponse);
		return of<T[]>([] as T[]);
	}

	private handleSingleCodelistApiError<T>(errorResponse): Observable<T> {
		console.error('* CodeList could not be loaded.', errorResponse);
		return of<T>({} as T);
	}
}
