<div class="quick-list__triangle"></div>
<div class="quick-list__content">
    <div class="filter">
        <input type="text" data-cy="assigneeSearch-input" placeholder="Search for assignee..." [formControl]="filterControl"/>
    </div>
    <div (throttleClick)="setAssignee(currentUser)" data-cy="assignToMe-btn" class="quick-list__item" [ngClass]="{'active' : isActive(currentUser)}">
        <div class="quick-list__text"><b>Assign to me</b></div>
    </div>
    <div *ngFor="let assignee of filteredAssignees$ | async" (throttleClick)="setAssignee(assignee)" data-cy="assignee-btn" class="quick-list__item" [ngClass]="{'active' : isActive(assignee)}">
		<avatar [user]="assignee"></avatar>
        <div class="quick-list__text">{{ assignee?.firstName }} {{ assignee?.lastName | truncate:0:11:'...' }}</div>
    </div>
</div>
