import { Injectable } from '@angular/core';
import { JwtHelperService } from '@app/core/auth/jwt-helper.service';
import { EmptyJwtData, JwtData } from '@app/core/auth/types/logged-user';
import { StorageKeys } from '@app/core/storage-keys';

@Injectable({
	providedIn: 'root'
})
export class TokenStorageService {

	private authToken: string;

	constructor(private jwtHelper: JwtHelperService) {
		this.authToken = localStorage.getItem(StorageKeys.TokenStorageKey);
	}

	public getAuthToken() {
		return this.authToken;
	}

	public getCredentialsData(): JwtData {
		if (!this.authToken || this.isTokenExpired()) {
			return new EmptyJwtData;
		}

		return this.jwtHelper.decodeToken<JwtData>(this.authToken);
	}

	public isTokenExpired() {
		return !this.authToken || this.jwtHelper.isTokenExpired(this.authToken);
	}

	public storeToken(token: string) {
		this.authToken = token;

		localStorage.setItem(StorageKeys.TokenStorageKey, token);
	}

	public clear() {
		this.authToken = null;
		localStorage.removeItem(StorageKeys.TokenStorageKey);
	}
}
