export enum ModalDismissReason {
	Cancel = 'cancel',
	Success = 'success',
	Error = 'error',
	BackdropClick = 'backdrop-click'
}

export enum CustomModalDismissReason {
	ConfirmDialogClosed = 'confirm-dialog-closed',
}
