<div class="quick-list__triangle"></div>
<div class="quick-list__content">
    <ng-container *ngIf="(categories$ | async) as categories">
        <ng-container *ngIf="categories.length > 0; else noCategory">
	        <div *ngFor="let category of categories; let i = index" (throttleClick)="setCategory(category)" [attr.data-cy]="'category-btn'" [ngClass]="{'active' : isActive(category)}" class="quick-list__item">
                <div class="quick-list__text" [style.color]="category.colorHex">{{ category.title | truncate:0:15:'...' }}</div>
            </div>
        </ng-container>
        <ng-template #noCategory>
            <div>
               <div class="quick-list__text">No categories available</div>
            </div>
        </ng-template>
    </ng-container>
</div>
