import { GlobalPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, Input, OnDestroy, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DraganddropService } from '@app/shared/draganddrop/draganddrop.service';
import { Subject, Subscription } from 'rxjs';

interface Position {
	x: number;
	y: number;
}

@Directive({
	selector: '[gaDraggable]',
	exportAs: 'gaDraggable'
})
/* eslint-disable @angular-eslint/no-input-rename */
export class DraggableDirective implements OnDestroy {
	@Input('dragHelper') private templateRef: TemplateRef<any>;
	@Input('dragData') private data: any;
	@Input('enabled') private isEnabled: boolean;
	position: Position = { x: 0, y: 0 };
	private startPosition: Position;
	private positionStrategy = new GlobalPositionStrategy();
	private unregisterMove;
	private unregisterUp;
	private initialized = false;
	private overlayRef;

	private onMouseDown = new Subject<MouseEvent>();
	private mouseSubscription: Subscription;

	constructor(private renderer: Renderer2,
				private sanitizer: DomSanitizer,
				private viewContainerRef: ViewContainerRef,
				private overlay: Overlay,
				private draganddropService: DraganddropService,
				private element: ElementRef
	) {
		this.mouseSubscription = this.onMouseDown.subscribe((event: MouseEvent) => {
			this.onPointerDown(event);
		});
	}

	/**
	 * "move" event is registered. When it occures before mouse up, it is considered a drag operation
	 * @param {MouseEvent} event
	 */
	public onPointerDown(event: MouseEvent) {
		if (this.isEnabled) {
			event.preventDefault();
			this.unregisterMove = this.renderer.listen('window', 'mousemove', (mouseEvent) => {
				if (!this.initialized) {
					this.initializeDrag(mouseEvent);
				}
				this.positionStrategy.left(`${mouseEvent.clientX - this.startPosition.x}px`);
				this.positionStrategy.top(`${mouseEvent.clientY - this.startPosition.y}px`);
				this.positionStrategy.apply();

			});

			this.registerUp();

			this.draganddropService.isDrag = true;
		}
	}

	/**
	 * sets overlay and positioning
	 * @param event
	 */
	private initializeDrag(event) {
		const clientRect = this.element.nativeElement.getBoundingClientRect();

		this.startPosition = {
			x: event.clientX - clientRect.left,
			y: event.clientY - clientRect.top
		};

		this.overlayRef = this.overlay.create({
			positionStrategy: this.positionStrategy
		});
		this.overlayRef.attach(new TemplatePortal(this.templateRef, this.viewContainerRef));

		const overlayElement = this.overlayRef.overlayElement;
		const dragHelperElement = overlayElement.querySelector('div') as HTMLElement;
		dragHelperElement.style.width = `${clientRect.width}px`;
		dragHelperElement.style.height = `${clientRect.height}px`;
		overlayElement.style.pointerEvents = 'none';
		this.initialized = true;
	}

	/**
	 * Up call backs is registered on mouse down, it
	 * unregisters itself and also handles unregistering mousemove callback,
	 * when drag oparations was initialized
	 */
	private registerUp() {
		this.unregisterUp = this.renderer.listen('window', 'mouseup', () => {
			if (this.initialized) {
				this.draganddropService.drop(this.data);
				this.overlayRef.detach();
				this.overlayRef.dispose();
				this.position = { x: 0, y: 0 };
			}
			this.unregisterMove();
			this.unregisterUp();
			this.initialized = false;
		});
	}

	public emitMouseDownEvent(event: MouseEvent) {
		return this.onMouseDown.next(event);
	}

	public ngOnDestroy(): void {
		if (this.mouseSubscription) {
			this.mouseSubscription.unsubscribe();
		}
	}
}
