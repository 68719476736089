import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/auth/auth.service';
import { EntityAssociationType } from '@app/shared/model/constants/entity-association-type';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { NewTodoForm, NewTodoFormData } from '@app/todo/model/new-todo-form';
import { TodoAssociationsService } from '@app/todo/services/todo-associations.service';
import { map, Observable, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'new-todo-form',
	templateUrl: 'new-todo-form.component.html',
	styleUrls: ['new-todo-form.component.scss']
})
export class NewTodoFormComponent implements OnInit, OnDestroy {
	@Output() onFormChange = new EventEmitter<NewTodoFormData>();
	form: FormGroup<NewTodoForm>;
	showCreateSalesNoteCheckbox$: Observable<boolean>;
	readonly urlCodelistName = UrlCodelistLink;
	private readonly salesNoteAssociationTypes = [
		EntityAssociationType.MedicalFacility,
		EntityAssociationType.LawFirm,
		EntityAssociationType.Doctor
	];
	private destroy$ = new Subject<void>();

	constructor(
		private authService: AuthService,
		private todoAssociationsService: TodoAssociationsService) {
	}


	public ngOnInit(): void {
		this.form = new FormGroup({
			summary: new FormControl('', [Validators.required, Validators.maxLength(50)]),
			description: new FormControl(''),
			assignees: new FormControl([], Validators.required),
			categories: new FormControl([]),
			labels: new FormControl([]),
			createSalesNote: new FormControl(false)
		});

		this.showCreateSalesNoteCheckbox$ = this.todoAssociationsService.getAssociations$().pipe(
			map((associations) => associations.some((association) =>
				this.salesNoteAssociationTypes.includes(association.association_type)))
		);

		this.onFormChange.next({ value: this.form.value, isFormValid: this.form.valid });

		this.form.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((formValue) => this.onFormChange.next({ value: formValue, isFormValid: this.form.valid }));
	}

	public setCurrentUser(): void {
		const currentUser = this.authService.getCurrentUser();
		this.form.controls.assignees.setValue([currentUser.id]);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
