<div [ngClass]="wrapperClass" (clickOutside)="closeAssigneeList()">
	<div [ngClass]="wrapperClass" (click)="toggleAssigneeList()" data-cy="todoAssigneesToggle-btn">
		<avatar *ngFor="let assignee of assignees | nameInitialsSort" [user]="assignee" style="margin-right: 4px;"></avatar>
	</div>
	<assignee-list
			*ngIf="showAssigneeList"
			@fadeUpDown
			(selectAssignee)="reAssignTodo($event)"
			[activeAssignees]="assignees"
			class="quick-list left">
	</assignee-list>
</div>
