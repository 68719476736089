import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription, throttleTime } from 'rxjs';

@Directive({
	selector: '[throttleClick]'
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
	@Input() throttleTime = 1500;
	@Output() throttleClick = new EventEmitter();

	private clicks = new Subject();
	private subscription: Subscription = Subscription.EMPTY;

	public ngOnInit(): void {
		this.subscription = this.clicks.pipe(throttleTime(this.throttleTime)).subscribe(event => this.throttleClick.emit(event));
	}

	@HostListener('click', ['$event'])
	public clickEvent(event): void {
		event.preventDefault();
		event.stopPropagation();
		this.clicks.next(event);
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
