import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[clickOutside]'
})
export class ClickOutsideDirective {
	@Output() clickOutside = new EventEmitter();
	isOutside = false;

	@HostListener('mouseenter')
	public onMouseOver() {
		this.isOutside = false;
	}

	@HostListener('mouseleave')
	public onMouseOut() {
		this.isOutside = true;
	}

	@HostListener('document:click')
	public onClick() {
		if(this.isOutside) {
			this.clickOutside.emit(null);
		}
	}
}
