import { Component } from '@angular/core';
import { AgColorEditorParams } from '@app/shared/ag-grid-utils/ag-color-editor/ag-color-editor-params';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-ag-color-editor',
	templateUrl: './ag-color-editor.component.html'
})
export class AgColorEditorComponent implements ICellEditorAngularComp {
	params: AgColorEditorParams & ICellEditorParams;
	value: string;
	returnValue: string;
	updateCallback: (data, value) => Observable<any>;
	private readonly defaultColor = '#fff';

	isPopup = (): boolean => true;

	public agInit(params: AgColorEditorParams & ICellEditorParams): void {
		this.params = params;

		this.updateCallback = this.params.updateCallback;
		this.value = this.params.value ?? this.defaultColor;
		this.returnValue = this.params.value;
	}

	public save(): void {
		this.returnValue = this.value;
		this.updateCallback(this.params.data, this.returnValue).subscribe();
		this.params.api.stopEditing();
	}

	public cancel(): void {
		this.params.api.stopEditing(true);
	}

	public getValue(): string {
		return this.returnValue;
	}
}
