import { Component, HostBinding, ViewChild } from '@angular/core';
import { ConfirmClickDirective } from '@app/shared/confirmation-dialog/confirm-click.directive';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'ag-action-buttons',
	template: `
		<ng-container *ngFor="let action of params; let i = index;">
			<div *ngIf="action.showConfirmDialog" (confirm-click)="doAction(i)">
				<ng-container *ngTemplateOutlet="iconButtonTmp;context:{action: action}"></ng-container>
			</div>
			<div *ngIf="!action.showConfirmDialog" (click)="doAction(i)">
				<ng-container *ngTemplateOutlet="iconButtonTmp;context:{action: action}"></ng-container>
			</div>
		</ng-container>
		<ng-template #iconButtonTmp let-action="action">
			<button
				[ngClass]="[action.cssClass]" class="btn-margin" [tooltip]="action.tooltip" container="body"
				placement="bottom">
				<fa-icon *ngIf="action.icon" [icon]="action.icon"></fa-icon>
				{{ action.title }}
			</button>
		</ng-template>
	`,
	styles: ['.btn-margin {margin: 0 2px}']
})
export class AgActionButtonsRendererComponent implements AgRendererComponent {
	@HostBinding('class.flex') hostClass = true;

	@ViewChild(ConfirmClickDirective) confirmDialog: ConfirmClickDirective;

	dataObj: any;

	params: ActionButtonsParams[];

	private agGridParams: ICellRendererParams & { buttonParams: ActionButtonsParams[] };

	public agInit(params: ICellRendererParams & { buttonParams: ActionButtonsParams[] }): void {
		this.dataObj = params.data;
		this.agGridParams = params;
		this.params = params.buttonParams;
	}

	public refresh(): boolean {
		return true;
	}

	public doAction(index: number): void {
		this.params[index].action({ dataObj: this.dataObj, params: this.params, gridParams: this.agGridParams });
	}
}

interface ActionButtonsParams {
	cssClass: string;
	icon: IconProp;
	title: string;
	action: any;
	showConfirmDialog: boolean;
	tooltip?: string,
}
