import { Injectable } from '@angular/core';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { ExcelCell, ExcelDataType } from 'ag-grid-community';

@Injectable()
export class AgGridExcelExportService {

	public getExcelExportDataType(text: string, styleId?: string): ExcelDataType {
		if (styleId === 'idDateTimeType' || /^\d{4}-\d{1,2}-\d{1,2}$/.test(text)) {
			return 'DateTime';
		}
		return /^\d+(\.\d+)?$/.test(text) ? 'Number' : 'String';
	}


	public excelExportCell(text: string, styleId?: string): ExcelCell {
		return {
			styleId: styleId ?? 'body',
			data: {
				type: this.getExcelExportDataType(text, styleId),
				value: text,
			},
		};
	}

	public addBackgroundToExcelStyles(styleId: string): any {
		return {
			...AgGridUtilsService.EXCEL_STYLES.find(style => style.id === styleId),
			interior: {
				color: '#E4AB11',
				pattern: 'Solid',
			}
		};
	}
}
