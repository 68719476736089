import { ChartSettingsStorage } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/chart-settings.storage';
import { TooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/tooltip-theme-service';
import { ChartModel } from 'ag-grid-community';

export class CommonSavedChart<T extends { tooltip? }> {
	protected dashboardChartStorage: ChartSettingsStorage;
	protected tooltipTheme: TooltipThemeService<T>;

	constructor(protected storageKey: string) {
	}

	public getSavedChart(modelIndex = 0): ChartModel | null {
		if (this.dashboardChartStorage.hasSavedChart(this.storageKey)) {
			let tooltipConfig = {};
			if (this.tooltipTheme !== null) {
				const savedColumns = this.dashboardChartStorage.getSavedColumns(this.storageKey, modelIndex);
				tooltipConfig = this.tooltipTheme.getGroupedTooltipTheme(savedColumns);
			}
			return this.dashboardChartStorage.getSavedChart(this.storageKey, tooltipConfig, modelIndex);
		}
		return null;
	}

	public hasSavedChart(): boolean {
		return this.dashboardChartStorage.hasSavedChart(this.storageKey);
	}

	public clearSavedChart() {
		this.dashboardChartStorage.clearSavedChart(this.storageKey);
	}

	public setTooltipIds(ids: string[]) {
		this.tooltipTheme.setTooltipColIds(ids);
	}

}
