<!--TODO: adjust classes-->
<div *ngIf="associations?.length > 0" @fadeInOut class="todo-card__associations__wrapper">
	<div *ngFor="let association of associations; let i = index" class="todo-card__associations__item"
		 data-cy="entityAssociation-item">
		<ng-container *ngIf="association?.loading">
			<ng-content></ng-content>
		</ng-container>
		<span [ngSwitch]="association?.association_type" data-cy="entityAssociation-value">
				<a *ngSwitchCase="noteAssociationTypes.Applicant"
				   [routerLink]="['/applicants', association.referenceId]"><fa-icon
					[icon]="faIcons.user" class="m-r-sm"></fa-icon>
					{{ association.label }}</a>
			 	<a *ngSwitchCase="noteAssociationTypes.Funding"
				   [routerLink]="['/applicants', association.parentReferenceId || association.referenceId]"><fa-icon
					[icon]="faIcons.dollarSign" class="m-r-sm"></fa-icon>
					{{ association.label }}</a>
				<a *ngSwitchCase="noteAssociationTypes.Doctor" [routerLink]="['/doctors', association.referenceId]"><fa-icon
					[icon]="faIcons.user" class="m-r-sm"></fa-icon>
					{{ association.label }}</a>
				<a *ngSwitchCase="noteAssociationTypes.LawFirm" [routerLink]="['/law-firms', association.referenceId]"><fa-icon
					[icon]="faIcons.gavel" class="m-r-sm"></fa-icon>
					{{ association.label }}</a>
				<a *ngSwitchCase="noteAssociationTypes.MedicalFacility"
				   [routerLink]="['/medical-facilities', association.referenceId]"><fa-icon
					[icon]="faIcons.hSquare" class="m-r-sm"></fa-icon>
					{{ association.label }}</a>
            </span>
		<svg-icon *ngIf="canRemoveAssociation"
				  [icon]="svgIconNames.Trash" class="svg-icon trash" tooltip="Remove association" placement="bottom"
				  [delay]="300" [attr.data-cy]="'entityAssociationDelete-btn'"
				  (throttleClick)="removeAssociation(association)"></svg-icon>
	</div>
</div>
