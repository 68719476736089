import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';
import { ActionsPermission } from '@app/core/security/permissions/permissions.model';

/**
General usage : *aclIsAllowed="useCase"
Specific use case is when you want to show content based on condition. For example: show revert button when funding wasn't funded or settled
In that case you can pass additional parameter namely skipAclCheck,
 usage : *aclIsAllowed="useCase: string; skipAclCheck: boolean; permission: ActionsPermission"
 */
@Directive({
	selector: '[aclIsAllowed]'
})
export class AclIsAllowedDirective {
	private skipAclCheck = false;
	private useCase: string;
	private permission: ActionsPermission = null;

	constructor(private acl: AclService,
				private templateRef: TemplateRef<any>,
				private viewContainer: ViewContainerRef) {
	}

	@Input() set aclIsAllowedPermission(permission: ActionsPermission) {
		this.permission = permission;
		this.viewContainer.clear();
		this.aclIsAllowed = this.useCase;
	}

	@Input() set aclIsAllowedSkipAclCheck(condition) {
		this.skipAclCheck = condition ?? false;
		this.viewContainer.clear();
		this.aclIsAllowed = this.useCase;
	}

	@Input() set aclIsAllowed(useCase: string) {
		this.useCase = useCase;
		let isAllowed;
		if (this.permission !== null) {
			isAllowed = this.acl.isAllowedForPermission(useCase, this.permission);
		} else {
			isAllowed = this.acl.isAllowed(useCase);
		}
		if (this.skipAclCheck || isAllowed) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
