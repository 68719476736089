import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
	name: 'cypressTag'
})
export class CypressTagPipe implements PipeTransform {

	public transform(value: string, suffix: string): string {
		return `${_.camelCase(value)}-${suffix}`;
	}

}
