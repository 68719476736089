import { HttpParams } from '@angular/common/http';
import { ChartBreakdownResponse } from '@app/dashboard/shared/breakdown-chart/dashboard-breakdown-chart.model';
import { firstValueFrom, Observable, timeout } from 'rxjs';

export abstract class DashboardBreakdownChartService<T extends string> {
	protected abstract callApiRequest(params: HttpParams): Observable<ChartBreakdownResponse<T>[]>;

	public abstract openDetailAdvanced(period, userAccount): void;

	public abstract openDetailCollected(period, userAccount): void

	public getData$(configuration, fundingTypeParams: HttpParams): Observable<ChartBreakdownResponse<T>[]> {
		const params = fundingTypeParams
			.set('resolution', configuration.resolution)
			.set('startDate', configuration.from)
			.set('endDate', configuration.to);
		return this.callApiRequest(params);
	}

	protected async getCommonModalSearchOptions(fundingTypes$: Observable<number[]>) {
		const fundingTypes = await this.getFundingTypes(fundingTypes$);
		return {
			archived: 0,
			isExternalPortfolio: false,
			preArchivedOn: { '%is_null': '' },
			fundingType: fundingTypes.length > 0 ? fundingTypes : undefined
		}
	}

	private getFundingTypes(fundingTypes$:Observable<number[]>): Promise<number[]> {
		return firstValueFrom(fundingTypes$.pipe(timeout(1000)));
	}
}
