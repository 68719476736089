import { formatNumber } from '@angular/common';
import { ColDef } from 'ag-grid-community';
import { get } from 'lodash';

export function currencyColumnDefinition(field: string): ColDef {
	return {
		valueGetter: ({ data, node }) => {
			if (node?.group) {
				return undefined;
			}
			const value = get(data, field, null);
			return value ? parseFloat(value) : 0;
		},
		comparator: (valueA, valueB) => {
			if (!valueA) {
				return -1;
			}
			if (!valueB) {
				return 1;
			}
			return parseFloat(valueA) - parseFloat(valueB);
		},
		valueFormatter: params => {
			return params.value != undefined ? `$${formatNumber(params.value, 'en-US', '1.2-2')}` : '';
		},
		filter: 'agNumberColumnFilter',
		filterParams: {
			allowedCharPattern: '\\d\\-\\,\\$',
			numberParser: text => text == null ? null : parseFloat(text.replace(',', '.').replace('$', ''))
		},
		allowedAggFuncs: ['sum', 'min', 'max', 'avg'],
		cellClass: 'currencyFormat'
	}
}
