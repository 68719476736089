<div class="modal-body">
	<h3 style="text-align: center">Static Pool Options</h3>
	<div class="row">
		<div class="col-md-12">
			<h4>Filter</h4>
			<div class="form-group">
				<label>Funding Type</label>
				<select [(ngModel)]="fundingTypeSelected" (change)="updateTabs()" class="form-control">
					<option *ngFor="let option of fundingTypeFilterOptions" [ngValue]="option">{{ option.title }}</option>
				</select>
			</div>
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-md-12">
			<label class="title">Include tabs:</label>
			<div class="select">
				<span (click)="selectAll()"><fa-icon [icon]="faIcons.checkSquare"></fa-icon> Select all</span>&nbsp;
				<span (click)="selectNone()"><fa-icon [icon]="faIcons.square"></fa-icon> Select none</span>
			</div>
		</div>
		<div class="col-md-6">
			<div class="checkbox">
				<label><input type="checkbox" checked disabled>Static Pool Data</label>
			</div>
			<div *ngFor="let tab of tabs | slice:0:7" class="checkbox">
				<label [ngStyle]="tab.disabled && {'color': '#ccc'}"><input [(ngModel)]="tab.include" type="checkbox" [disabled]="tab.disabled">{{ tab.title }}</label>
			</div>
		</div>
		<div class="col-md-6">
			<div *ngFor="let tab of tabs | slice:7" class="checkbox">
				<label [ngStyle]="tab.disabled && {'color': '#ccc'}"><input [(ngModel)]="tab.include" type="checkbox" [disabled]="tab.disabled">{{ tab.title }}</label>
			</div>
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-md-12">
			<h4>Show columns:</h4>
			<div *ngFor="let column of columns" class="checkbox-inline">
				<label><input [(ngModel)]="column.include" type="checkbox" />{{ column.title }}</label>
			</div>
		</div>
	</div>
	<hr>
	<download-link [config$]="downloadLink$"></download-link>
	<div class="row">
		<div class="col-md-12">
			<button class="btn btn-primary" (click)="download()">Download</button>
		</div>
	</div>
</div>
