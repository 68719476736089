import { Directive, inject, OnDestroy } from '@angular/core';
import { AgGridColumnStorageService } from '@app/ga-grid/services/ag-grid-storage-services/ag-grid-column-storage.service';
import { AgGridFilterStorageService } from '@app/ga-grid/services/ag-grid-storage-services/ag-grid-filter-storage.service';
import { GridCodelistFilterService } from '@app/ga-grid/services/grid-codelist-filter.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ColumnState } from 'ag-grid-community';
import { isEmpty, isEqual } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Directive()
export abstract class PersistableGaGridComponent implements OnDestroy {
	showClearButton: boolean;
	abstract agGrid: AgGridAngular;
	protected destroy$ = new Subject();

	protected readonly agGridFilterStorageService = inject(AgGridFilterStorageService);
	protected readonly agGridCodelistFilterService = inject(GridCodelistFilterService);
	protected readonly agGridColumnStorageService = inject(AgGridColumnStorageService);

	private initialState: { columns: any[], filters: any } = { columns: null, filters: null };

	protected constructor() {
		this.checkClearButtonState();
	}

	private readonly filterEventListener = () => this.saveFilters();
	private readonly columnSelectListener = () => this.saveColumns();
	private readonly columnMovedListener = () => this.saveColumns();

	public onFirstDataRendered(): void {
		this.initialState.columns = this.agGrid.columnApi.getColumnState();
		this.initialState.filters = this.agGrid.api.getFilterModel();
		this.agGridCodelistFilterService.getFilterUpdates$()
			.pipe(takeUntil(this.destroy$))
			.subscribe(colId => {
				this.updateFilter(colId);
			});
		this.checkColumnsState();
		this.checkFiltersState();
		this.registerEventListeners();
	}

	private updateFilter(colId: string) {
		const fieldFilterModel = this.agGrid?.api.getFilterInstance(colId);
		const savedFilter = this.agGridFilterStorageService.getSpecificFilterModel(colId);
		if (savedFilter !== null) {
			fieldFilterModel.setModel(this.agGridFilterStorageService.getSpecificFilterModel(colId));
			this.agGrid?.api.onFilterChanged();
		}
	}

	public isFilterModelEmpty() {
		const persistedFilters = this.agGridFilterStorageService.getPersistedState();
		return isEmpty(persistedFilters);
	}

	private checkFiltersState(): void {
		const persistedFilters = this.agGridFilterStorageService.getPersistedState();
		if (persistedFilters && !isEmpty(persistedFilters)) {
			this.setAgGridFilterModel(persistedFilters);
		}
	}

	private setAgGridFilterModel(filterModel): void {
		if (this.agGrid) {
			this.agGrid.api.setFilterModel(filterModel);
			this.agGrid.api.onFilterChanged();
		}
	}

	private checkColumnsState(): void {
		const persistedColumnState = this.agGridColumnStorageService.getPersistedState();
		if (persistedColumnState && !isEmpty(persistedColumnState)) {
			this.setAgGridColumnState(persistedColumnState);
		}
	}

	private setAgGridColumnState(columnState): void {
		if (this.agGrid) {
			this.agGrid.columnApi.applyColumnState({ state: columnState });
		}
	}

	private saveFilters(): void {
		const currentFilterModel = this.agGrid.api.getFilterModel();
		if (this.showClearButton && isEqual(this.initialState.filters, currentFilterModel)) {
			this.agGridFilterStorageService.persist({});
		} else {
			this.agGridFilterStorageService.persist(this.agGrid.api.getFilterModel());
		}
		this.checkClearButtonState();
	}

	private saveColumns(): void {
		const currentColumnState = this.agGrid.columnApi.getColumnState();
		if (this.showClearButton && this.areColumnsInInitialState(currentColumnState)) {
			this.agGridColumnStorageService.persist({});
		} else {
			this.agGridColumnStorageService.persist(currentColumnState);
		}
		this.checkClearButtonState();
	}

	private checkClearButtonState(): void {
		this.showClearButton = !isEmpty(this.agGridColumnStorageService.getPersistedState())
			|| !isEmpty(this.agGridFilterStorageService.getPersistedState());
	}

	private areColumnsInInitialState(currentColumns: ColumnState[]): boolean {
		for (let i = 0; i < currentColumns.length; i++) {
			if (this.initialState.columns[i].hide != currentColumns[i].hide) {
				return false;
			}
		}
		return true;
	}

	public clearSavedSettings(): void {
		this.showClearButton = false;
		this.removeEventListeners();
		this.agGrid?.api?.setFilterModel(null);
		this.agGrid?.columnApi?.resetColumnState();
		this.agGridFilterStorageService.clearStorageForCurrentPage();
		this.agGridColumnStorageService.clearStorageForCurrentPage();
		this.registerEventListeners();
	}

	private registerEventListeners(): void {
		this.agGrid?.api?.addEventListener('filterChanged', this.filterEventListener);
		this.agGrid?.api?.addEventListener('columnVisible', this.columnSelectListener);
		this.agGrid?.api?.addEventListener('columnMoved', this.columnMovedListener);
	}

	private removeEventListeners(): void {
		this.agGrid?.api?.removeEventListener('filterChanged', this.filterEventListener);
		this.agGrid?.api?.removeEventListener('columnVisible', this.columnSelectListener);
		this.agGrid?.api?.removeEventListener('columnMoved', this.columnMovedListener);
	}

	public ngOnDestroy() {
		this.destroy$.next(null);
		this.destroy$.complete();
		this.removeEventListeners();
		this.agGrid?.api?.destroy();
	}

}
