import { TodoSidebarSortItem } from '@app/todo/todo-sidebar/todo-sidebar-sort/todo-sidebar-sort.model';

export const todoSidebarSortOptions: TodoSidebarSortItem[] = [
	{ id: 1, title: 'Due Date', value: 'dueDate' },
	{ id: 2, title: 'Created Date', value: 'createdAt' },
	{ id: 3, title: 'Summary', value: 'summary' },
];

export const defaultTodoSidebarSortOption: TodoSidebarSortItem =
	{ ...todoSidebarSortOptions.find((option) => option.value == 'dueDate') };

