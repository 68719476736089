import { InjectionToken } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { currencyColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/currency-column-definition';
import { dateColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/date-column-definition';
import { FieldMetadata } from '@app/shared/ga-components/utils/field-metadata';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { FundingHelperService } from '@app/shared/model/funding-helper.service';
import { MedicalProcedureCategory } from '@app/shared/model/types/codelists/medical-procedure-category';
import { MedicalProcedureType } from '@app/shared/model/types/codelists/medical-procedure-type';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { Subject, takeUntil } from 'rxjs';
import { capitalize } from 'lodash';

export class VintageDetailConfig {
	fieldList?: (string | Partial<FieldMetadata> | any)[];
	columnDefinitions?: (ColGroupDef | ColDef)[];
	unsubscribe?: () => void;
	filter: any;
}

export const DefaultVintageDetailConfig = new InjectionToken<VintageDetailConfig>('Defaults for Vintage Detail');
export const destroy$ = new Subject<void>();
export const defaultVintageDetailConfigProvider = {

	provide: DefaultVintageDetailConfig,
	useFactory: (
		agUtils: AgGridUtilsService,
		codelistService: CodelistRepositoryService,
		fundingHelper: FundingHelperService
	) => ({
		filter: { archived: 0 },
		unsubscribe: () => {
			destroy$.next();
			destroy$.complete();
		},
		fieldList: [
			'internalId',
			'applicant -> firstName, lastName, caseManager.firstName, caseManager.lastName',
			'approvalStatus',
			'applicant.attorney -> firstName, lastName, lawFirm.name',
			'applicant.attorney.lawFirm.salesRepresentative -> firstName, lastName',
			'fundingType -> title, isCourtReporting, medical',
			'fundedOn',
			'settledOn',
			'fundedAt',
			'settledAt',
			'purchasePrice',
			'brokerFee',
			'applicationFee',
			'buyoutAmount',
			'fundedAmount',
			'medicalProcedureType.category -> effectiveRate.amount, title',
			'medicalProcedureType.title',
			'doctor.name',
			'medicalFacility.name',
			'lawFirmStageOnApplied'
		],
		columnDefinitions: [
			{
				field: 'internalId',
				cellRenderer: ({ data, value }) =>
					data?.applicant ? agUtils.fundingLinkRenderer(value, data?.id, data?.applicant?.id) : value,
				filter: 'agTextColumnFilter',
				pinned: 'left'
			},
			{
				field: 'applicant',
				valueGetter: ({ data }) => `${data?.applicant?.firstName ?? ''} ${data?.applicant?.lastName ?? ''}`,
				sortable: true,
				filter: 'agTextColumnFilter',
				cellRenderer: ({ data, value }) => data ? agUtils.applicantLinkRenderer(data?.applicant?.id, value) : value,
				pinned: 'left'
			},
			{
				field: 'applicant.attorney.lawFirm.name',
				headerName: 'Law Firm',
				sortable: true,
				cellRenderer: ({ data, value }) =>
					data ? agUtils.lawFirmLinkRenderer(data?.applicant?.attorney?.lawFirm?.id, value) : value,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => {
						codelistService.getByUrl(UrlCodelistLink.LawFirms)
							.pipe(takeUntil(destroy$))
							.subscribe(value => params.success(agUtils.getArrayOfStringsByFiledName(value, 'name')));
					},
					buttons: ['clear', 'reset'],
				},
				minWidth: 200,
				pinned: 'left'
			},
			{
				field: 'doctor',
				sortable: true,
				filter: 'agSetColumnFilter',
				valueFormatter: ({ value }) => value?.name ?? value,
				cellRenderer: ({ value }) => agUtils.doctorLinkRenderer(value?.id, value?.name),
				keyCreator: ({ value }) => value?.name,
				filterParams: {
					values: (params) => codelistService.getByUrl(UrlCodelistLink.Doctors)
						.pipe(takeUntil(destroy$))
						.subscribe(doctors =>
							params.success(agUtils.getArrayOfStringsByFiledName(doctors, 'name'))),
					buttons: ['clear', 'reset'],
				},
				comparator: (a, b) => agUtils.stringComparator(a?.name, b?.name),
			},
			{
				field: 'medicalFacility',
				sortable: true,
				filter: 'agSetColumnFilter',
				valueFormatter: ({ value }) => value?.name ?? value,
				cellRenderer: ({ value }) => agUtils.medicalFacilityLinkRenderer(value?.id, value?.name),
				keyCreator: ({ value }) => value?.name,
				filterParams: {
					values: (params) => codelistService.getByUrl(UrlCodelistLink.MedicalFacilities)
						.pipe(takeUntil(destroy$))
						.subscribe(medicalFacilities =>
							params.success(agUtils.getArrayOfStringsByFiledName(medicalFacilities, 'name'))),
					buttons: ['clear', 'reset']
				},
				comparator: (a, b) => agUtils.stringComparator(a?.name, b?.name),
			},
			{
				field: 'medicalProcedureType.category.title',
				headerName: 'Procedure category',
				sortable: true,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) =>
						codelistService.get<MedicalProcedureCategory[]>(NonUrlCodelistName.MedicalProcedureCategory)
							.pipe(takeUntil(destroy$))
							.subscribe((data) =>
								params.success(agUtils.getArrayOfStringsByFiledName(data, 'title'))
							),
					buttons: ['clear', 'reset'],
				}
			},
			{
				field: 'medicalProcedureType.title',
				headerName: 'Procedure',
				sortable: true,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) =>
						codelistService.get<MedicalProcedureType[]>(NonUrlCodelistName.MedicalProcedureType)
							.pipe(takeUntil(destroy$))
							.subscribe((data) =>
								params.success(agUtils.getArrayOfStringsByFiledName(data, 'title'))
							),
					buttons: ['clear', 'reset'],
				},
			},
			{
				field: 'applicant.attorney.lawFirm.salesRepresentative',
				headerName: 'New Business Rep',
				valueFormatter: ({ value }) => value ? `${value?.firstName} ${value?.lastName}` : '',
				filter: 'agSetColumnFilter',
				keyCreator: ({ value }) => value ? `${value?.firstName} ${value?.lastName}` : '',
				filterParams: {
					values: (params) => {
						codelistService.getByUrl(UrlCodelistLink.SalesRepresentatives)
							.pipe(takeUntil(destroy$))
							.subscribe(salesRepresentatives => {
								params.success(agUtils.getArrayOfStringsByFiledName(salesRepresentatives,
									['firstName', 'lastName']));
							});
					},
					buttons: ['clear', 'reset'],
				},
				sortable: true,
				comparator: (a, b) => {
					const getName = salesRepresentative =>
						`${salesRepresentative?.firstName ?? ''} ${salesRepresentative?.lastName ?? ''}`;
					return agUtils.stringComparator(getName(a), getName(b));
				},
				pinned: 'left'
			},
			{
				headerName: 'Funded On',
				...dateColumnDefinition('fundedOn'),
				pinned: 'left'
			},
			{
				field: 'fundingType.title',
				headerName: 'Funding Type',
				filter: 'agTextColumnFilter'
			},
			{
				headerName: 'Funded Amount',
				...currencyColumnDefinition('fundedAt'),
			},
			{
				headerName: 'Effective Rate',
				...currencyColumnDefinition(''),
				valueGetter: ({ data, node }) => {
					if (node?.group) {
						return undefined;
					}
					let effectiveRate = data?.purchasePrice;
					if (fundingHelper.isMedical(data) && !fundingHelper.isCourtReporting(data)) {
						effectiveRate = data?.medicalProcedureType?.category?.effectiveRate?.amount;
					}
					return effectiveRate ? +effectiveRate : 0;
				}
			},
			{
				headerName: 'Purchase Price',
				...currencyColumnDefinition('purchasePrice')
			},
			{
				headerName: 'Broker Fee',
				...currencyColumnDefinition('brokerFee')
			},
			{
				headerName: 'Application Fee',
				...currencyColumnDefinition('applicationFee')
			},
			{
				field: 'approvalStatus',
				filter: 'agSetColumnFilter',
				minWidth: 200,
				valueGetter: ({ data }) => data ? fundingHelper.getStringRepresentationOfFundingState(data) : '',
			},
			{
				field: 'applicant.attorney.lawFirm.id',
				headerName: 'Firm ID',
				filter: 'agTextColumnFilter',
			},
			{
				field: 'lawFirmStageOnApplied',
				filter: 'agSetColumnFilter',
				valueFormatter: ({value}) => capitalize(value),
			},
			{
				headerName: 'Attorney',
				width: 200,
				field: 'applicant.attorney',
				valueFormatter: ({ value }) => value?.firstName ? `${value?.firstName} ${value?.lastName}` : value,
				cellRenderer: ({ value }) =>
					agUtils.attorneyLinkRenderer(value?.lawFirm?.id, value?.id, `${value?.firstName} ${value?.lastName}`),
				filter: 'agSetColumnFilter',
				keyCreator: ({ value }) => value ? `${value?.firstName} ${value?.lastName}` : '',
				filterParams: {
					values: (params) => {
						codelistService.getByUrl(UrlCodelistLink.Attorneys)
							.pipe(takeUntil(destroy$))
							.subscribe(attorneys => {
								params.success(agUtils.getArrayOfStringsByFiledName(attorneys, ['firstName', 'lastName']));
							});
					},
					buttons: ['clear', 'reset']
				},
				comparator: (a, b) => agUtils.stringComparatorByName(a, b),
			},
			{
				headerName: 'Case Manager',
				width: 180,
				field: 'applicant.caseManager',
				valueFormatter: ({ value }) => value?.firstName ? `${value?.firstName} ${value?.lastName}` : value,
				filter: 'agSetColumnFilter',
				keyCreator: ({ value }) => value ? `${value?.firstName} ${value?.lastName}` : '',
				filterParams: {
					values: (params) => {
						codelistService.getByUrl(UrlCodelistLink.CaseManagers)
							.pipe(takeUntil(destroy$))
							.subscribe(caseManagers => {
								params.success(agUtils.getArrayOfStringsByFiledName(caseManagers, ['firstName', 'lastName']));
							});
					},
					buttons: ['clear', 'reset']
				},
				comparator: (a, b) => agUtils.stringComparatorByName(a, b),
			},
			{
				headerName: 'Settled On',
				...dateColumnDefinition('settledOn'),
				hide: true
			},
			{
				headerName: 'Settled At',
				...currencyColumnDefinition('settledAt'),
				hide: true
			},
			{
				headerName: 'Buyout Amount',
				...currencyColumnDefinition('buyoutAmount')
			}
		]
	}),
	deps: [AgGridUtilsService, CodelistRepositoryService, FundingHelperService]
};
