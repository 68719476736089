import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'ga-validation-labels',
	templateUrl: './ga-validation-labels.component.html',
	styleUrls: ['./ga-validation-labels.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GaValidationLabelsComponent implements OnInit, OnDestroy {
	@Input() validators: ValidatorFn[] = [];
	@Input() validationErrors$: Observable<ValidationErrors>;
	private _validatorsObject: ValidatorsObjectType;
	private destroy$ = new Subject<void>();

	public ngOnInit(): void {
		this.initValidatorsObject(this.validators);

		this.validationErrors$.pipe(takeUntil(this.destroy$))
			.subscribe((errors: ValidationErrors) => {
				this.resetValidatorsObject();
				_.forIn(errors, (value, key) => {
					if (_.has(this._validatorsObject, key)) {
						this.setValidatorInvalid(key);
					}
				});
			});
	}

	private initValidatorsObject(validators: ValidatorFn[]): void {
		this._validatorsObject = {};
		_.each(validators, (validationFunction: ValidatorFn) => {
			const validationName: string = validationFunction.name ?? null;
			if (validationName) {
				this.resetValidator(validationName);
			}
		});
	}

	private resetValidatorsObject() {
		_.forIn(this._validatorsObject, (value, key) => {
			this.resetValidator(key);
		});
	}

	private resetValidator(key: string): void {
		this._validatorsObject[key] = { invalid: false };
	}

	private setValidatorInvalid(key: string): void {
		this._validatorsObject[key] = { invalid: true };
	}

	get validatorsObject(): ValidatorsObjectType {
		return this._validatorsObject;
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}

interface ValidatorsObjectType {
	[key: string]: { invalid: boolean }
}
