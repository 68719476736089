import { forwardRef, Inject, Injectable } from '@angular/core';
import { ComponentMetadataBuilderService } from '@app/shared/ga-components/services/component-metadata-builder.service';
import { GaMetadataAdapterService } from '@app/shared/ga-components/services/ga-metadata-adapter.service';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';

@Injectable({
	providedIn: 'root'
})
export class MetadataProviderService {

	constructor(
		@Inject(forwardRef(() => GaMetadataAdapterService)) private metadataAdapter: GaMetadataAdapterService,
		private componentMetadataBuilder: ComponentMetadataBuilderService
	) {}

	public getFieldMetadata(entityClass, fieldName) {
		return this.metadataAdapter.getMetadata(entityClass, fieldName);
	}

	public getClassMetadata(entityClass) {
		return this.metadataAdapter.getClassMetadata(entityClass);
	}

	public getComponentMetadata(entityClass: string, fieldName: string, overrides: MetadataDecorator) {
		const fieldMetadata = this.metadataAdapter.getMetadata(entityClass, fieldName);
		return this.componentMetadataBuilder.buildMetadataFromField(fieldName, fieldMetadata, overrides);
	}

}
