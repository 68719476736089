import { AsyncPipe, NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SalesboardModalAddNoteComponent } from '@app/sales/board/salesboard-modal/salesboard-modal-add-note.component';
import { SalesboardModalAddTodoComponent } from '@app/sales/board/salesboard-modal/salesboard-modal-add-todo/salesboard-modal-add-todo.component';
import { CaseManagerTrackingToggleComponent } from '@app/sales/case-manager-tracking/case-manager-tracking-toggle.component';
import { CaseManagerTrackingComponent } from '@app/sales/case-manager-tracking/case-manager-tracking.component';
import { SalesRoutingModule } from '@app/sales/sales-routing.module';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { SharedModule } from '@app/shared/shared.module';
import { SVGIconComponent } from '@app/shared/svg-icon/svg-icon.component';
import { TodoModule } from '@app/todo/todo.module';
import { EntityAssociationsListComponent } from '@app/widgets/entity-associations-list/entity-associations-list.component';
import { EntityAssociationsSelectComponent } from '@app/widgets/entity-associations-select/entity-associations-select.component';
import { LoadingButtonModule } from '@app/widgets/loading-button/loading-button.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LetModule } from '@ngrx/component';
import { AgGridModule } from 'ag-grid-angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';

@NgModule({
	imports: [
		AgGridModule,
		AsyncPipe,
		NgClass,
		NgIf,
		DirectivesModule,
		NgForOf,
		SVGIconComponent,
		NgStyle,
		SharedModule,
		FormsModule,
		LMarkdownEditorModule,
		EntityAssociationsListComponent,
		EntityAssociationsSelectComponent,
		LetModule,
		BsDatepickerModule,
		NgSelectModule,
		ReactiveFormsModule,
		TodoModule,
		LoadingButtonModule,
		SalesRoutingModule
	],
	declarations: [
		CaseManagerTrackingComponent,
		CaseManagerTrackingToggleComponent,
		SalesboardModalAddNoteComponent,
		SalesboardModalAddTodoComponent
	],
	exports: [],
	providers: [],
})

export class SalesModule {

}
