import { Injectable } from '@angular/core';
import { DecoratorProviderService } from '@app/shared/ga-components/services/decorator-provider.service';
import { GaResolverService } from '@app/shared/ga-components/services/ga-resolver.service';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { FieldMetadata } from '@app/shared/ga-components/utils/field-metadata';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ComponentMetadataBuilderService {

	constructor(
		private decoratorProvider: DecoratorProviderService,
		private resolver: GaResolverService
	) {
	}

	public buildMetadataFromField(path: string, fieldMetadata: FieldMetadata, metadataOverride?: MetadataDecorator): ComponentMetadata {
		let wrapper: ComponentMetadata;
		wrapper = new ComponentMetadata({
			path: path,
			fieldName: fieldMetadata.fieldName,
			entityClass: fieldMetadata.entity,
			label: fieldMetadata.label,
			isCollection: fieldMetadata.association && fieldMetadata.association.isToMany(),
			dependencies: fieldMetadata.dependencies,
			fieldMetadata: fieldMetadata
		});

		//apply server defaults
		wrapper.valueFormatter = this.resolver.resolveFormatter(fieldMetadata);
		wrapper.editor = this.resolver.resolveEditor(fieldMetadata);
		wrapper.renderer = this.resolver.resolveRenderer(fieldMetadata);
		wrapper.valueParser = this.resolver.resolveParser(fieldMetadata);
		wrapper.valueGetter = this.resolver.resolveGetter(fieldMetadata, path);

		wrapper.rendererParams = this.resolver.resolveRendererParams(wrapper.renderer, fieldMetadata);
		wrapper.editorParams = this.resolver.resolveEditorParams(wrapper.editor, fieldMetadata);

		wrapper.validators = _.map(fieldMetadata.validators, this.resolver.resolveValidator);

		//apply decorators
		if (fieldMetadata.decorator) {
			wrapper = this.applyDecorator(fieldMetadata.decorator, wrapper);
		}

		//apply decorator override
		if (metadataOverride && metadataOverride.decoratorName) {
			wrapper = this.applyDecorator(metadataOverride.decoratorName, wrapper);
		}
		//adjust for associations
		if (fieldMetadata.association) {
			let dependencies = [path]; //default
			if (wrapper.dependencies.length > 0) {
				dependencies = _.map(wrapper.dependencies, item => path + '.' + item);
			}

			wrapper.dependencies = dependencies;
		}

		//apply individual overrides
		if (metadataOverride) {
			if (metadataOverride.asyncValidators && metadataOverride.asyncValidators.length > 0) {
				metadataOverride.asyncValidators = metadataOverride.asyncValidators.map((validator, index) =>
					this.resolver.resolveValidator(validator, metadataOverride.asyncValidatorOptions[index]));
			}
			if (metadataOverride.validators && metadataOverride.validators.length > 0) {
				metadataOverride.validators = metadataOverride.validators.map(validator => this.resolver.resolveValidator(validator));
			}
			wrapper = _.merge(wrapper, metadataOverride);
		}

		return wrapper;
	}

	private applyDecorator(decorator: ComponentMetadata | string, componentMetadata: ComponentMetadata) {
		if (typeof decorator === 'string' || typeof decorator === 'function') {
			const decoratorObj = this.decoratorProvider.getDecorator(decorator, componentMetadata);

			// I don't want to overwrite dependencies,
			// but make an union ouf of them so I rather mergeWith than { ...componentMetadata, ...decoratorObj }
			// see :https://jsfiddle.net/oswbpd8j/
			componentMetadata = _.mergeWith(componentMetadata, decoratorObj, (origin, updateWith, key) => {
				if (key === 'dependencies') {
					return _.union(origin, updateWith);
				}
			});
		} else if (typeof decorator === 'object') {
			//use decorator directly as class
			componentMetadata = { ...componentMetadata, ...decorator };
		}
		return componentMetadata;
	}
}
