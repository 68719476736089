import { Injectable } from '@angular/core';
import { GaResolverService } from '@app/shared/ga-components/services/ga-resolver.service';
import { isObject } from '@app/shared/general-helper';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment, MomentInput } from 'moment';
import * as momentTimezone from 'moment-timezone';

@Injectable({
	providedIn: 'root'
})
export class DateTimeHelperService {

	static readonly iso8601DatePattern = '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$';
	static readonly datePattern = '^\\d{4}-\\d\\d-\\d\\d$';

	constructor(private gaResolver: GaResolverService) {
	}

	/**
	 * Builds dateTime string from date and time inputs. Date and time might be in Date, Moment, string, ... formats
	 *
	 * @param date
	 * @param time
	 * @param transformToUTC
	 * @param isoFormat
	 */
	public buildDateTimeString(
		date: MomentInput = null,
		time: MomentInput = null,
		transformToUTC = false,
		isoFormat = false
	): string | null {
		const dateObject = date ? moment(date) : null;
		const timeObject = time ? moment(time) : null;
		const dateTimeObject = this.buildDateTimeObject(dateObject, timeObject, transformToUTC);

		return dateTimeObject ? this.formatDateTime(dateTimeObject, isoFormat) : null;
	}

	/**
	 * Builds Moment object from date and time inputs. Date and time might be in Date, Moment, string, ... formats
	 *
	 * @param date
	 * @param time
	 * @param transformToUTC
	 */
	private buildDateTimeObject(date: MomentInput = null, time: MomentInput = null, transformToUTC = false): Moment {
		const dateObject = date ? moment(date) : null;
		const timeObject = time ? moment(time) : null;
		if (dateObject) {
			if (timeObject) {
				const timeString = timeObject.format('HH:mm:ss');
				const dateString = dateObject.format('Y-MM-DD');
				const composedDateTimeObject = moment(`${dateString} ${timeString}`);

				return transformToUTC ? composedDateTimeObject.utc() : composedDateTimeObject;
			}

			const dateTimeObject = dateObject.endOf('day');
			return transformToUTC ? dateTimeObject.utc() : dateTimeObject;
		}

		return null;
	}

	public formatDateTime(dateTimeObject: Moment = null, isoFormat = false): string | null {
		if (dateTimeObject) {
			return isoFormat ? dateTimeObject.format() : dateTimeObject.format('Y-MM-DD HH:mm:ss');
		}
		return null;
	}

	public formatDatesInFormPayload(formValues) {
		_.forIn(formValues, (value, key) => {
			if (_.isDate(value)) {
				formValues[key] = moment(value).format('YYYY-MM-DD');
			} else if (isObject(value)) {
				this.formatDatesInFormPayload(value);
			}
		});

		return formValues;
	}

	public getDateTimeBasedOnTimezone(value, timezone, momentFormat = 'yyyy-MM-DD hh:mm A'): string {
		const zone = timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;
		return momentTimezone(value).tz(zone).format(momentFormat);
	}

	public getValueAsDate(value): Date {
		return value ? moment(value).toDate() : moment().toDate();
	}

	public findDatesAndFormat(html: string) {
		const dateRegex = new RegExp(DateTimeHelperService.datePattern);
		const iso8601DateRegex = new RegExp(DateTimeHelperService.iso8601DatePattern);

		const elements = _.split(html, ' ');
		const formattedElements = _.map(elements, (element: string) => {
			let formatter;
			if (dateRegex.test(element)) {
				formatter = this.gaResolver.resolveFormatter({ type: 'date' });
			} else if (iso8601DateRegex.test(element)) {
				formatter = this.gaResolver.resolveFormatter({ type: 'datetime' });
			} else {
				formatter = (value) => value;
			}

			return formatter(element);
		});

		return _.join(formattedElements, ' ');
	}
}
