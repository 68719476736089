<div class="new-todo">
	<div class="new-todo__header">
		<h2>Add Todo</h2>
	</div>
	<new-todo-form (onFormChange)="todoFormChanged($event)"></new-todo-form>
	<div class="new-todo__footer">
		<button class="base-button base-button--cancel" data-cy="cancel-btn" (click)="cancel()">Cancel</button>
		<app-loading-button [dataCy]="'submit-btn'" [disabled]="!todoFormData.isFormValid"
							(onBtnClick)="saveTodo($event)">Save
		</app-loading-button>
	</div>
</div>
