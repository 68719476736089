import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardWidgetDateFilterModule } from '@app/dashboard/shared/dashboard-widget-date-filter/dashboard-widget-date-filter.module';
import { AgeBreakdownComponent } from '@app/dashboard/top-five-section/age-breakdown/age-breakdown.component';
import { CaseTypeBreakdownComponent } from '@app/dashboard/top-five-section/case-type-breakdown/case-type-breakdown.component';
import { TopEntitiesStatsComponent } from '@app/dashboard/top-five-section/top-entities-stats/top-entities-stats.component';
import { GaComponentsModule } from '@app/shared/ga-components/ga-components.module';
import { SVGIconComponent } from '@app/shared/svg-icon/svg-icon.component';
import { AgGridModule } from 'ag-grid-angular';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipelineBreakdownComponent } from './pipeline-breakdown/pipeline-breakdown.component';

@NgModule({
	imports: [
		CommonModule,
		AgGridModule,
		ReactiveFormsModule,
		DashboardWidgetDateFilterModule,
		SVGIconComponent,
		TooltipModule,
		GaComponentsModule
	],
	exports: [
		TopEntitiesStatsComponent,
		AgeBreakdownComponent,
		CaseTypeBreakdownComponent,
		PipelineBreakdownComponent
	],
	declarations: [
		TopEntitiesStatsComponent,
		AgeBreakdownComponent,
		CaseTypeBreakdownComponent,
		PipelineBreakdownComponent
	]
})
export class TopFiveSectionModule {}
