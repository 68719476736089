import { TooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/tooltip-theme-service';
import { AgCartesianSeriesOptions } from 'ag-grid-community/dist/lib/interfaces/iAgChartOptions';
import { get } from 'lodash';

export class CartesianGroupedTooltipThemeService extends TooltipThemeService<AgCartesianSeriesOptions> {

	public copy() {
		return new CartesianGroupedTooltipThemeService(this._gridTooltipConfigs);
	}

	public getTooltip(): Pick<AgCartesianSeriesOptions, 'tooltip'> {
		return {
			tooltip: {
				renderer: (params) => {
					const categoryVal = get(params.datum, `${params.xKey}.value`);
					const seriesData = this.tooltipData.find(data => data.chartLabel == categoryVal);
					const config = this.tooltipColIds
						.map(colId => this._gridTooltipConfigs[colId])
						.filter(colConfig => colConfig !== undefined && this.includeColumnInTooltip(colConfig));
					const tooltipContent = config.reduce((acc, series) => {
						const data = get(seriesData, series.path);
						const title = series.title == params.yName ? `<b>${series.title}</b>` : series.title;
						return `${acc}
							<div>${title}: ${series.valueFormatter(data)}</div>`}, '');
					return `
    					<div class="ag-chart-tooltip-title">${get(params.datum, `${params.xKey}.value`)}</div>
    					<div class="ag-chart-tooltip-content">${tooltipContent}</div>
					`
				}
			}
		}
	}
}
