import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GaSwitchEditorParams } from '@app/shared/ga-components/components/editors/ga-switch.params';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IEditor } from '@app/shared/ga-components/utils/IEditor';
import { defaultSwitchParams, SwitchParams } from '@app/shared/switch/switch.model';

@Component({
	selector: 'ga-switch-editor',
	template: `
		<ng-container [formGroup]="formGroup">
			<rp-switch
				[value]="dataObj[componentMetadata.fieldName]"
				[params]="switchParams"
				(switched)="update($event)">
			</rp-switch>
		</ng-container>`,
})
export class GaSwitchEditorComponent implements IEditor {

	componentMetadata: ComponentMetadata;
	params: GaSwitchEditorParams;
	switchParams: SwitchParams;
	formGroup: UntypedFormGroup;
	onFocusEmitter: EventEmitter<boolean>;

	readonly cssClass: string = 'ga-checkbox';

	dataObj;

	public update(switchValue: boolean, dataObj?: any): void {
		this.dataObj = dataObj ?? this.dataObj;
		this.formGroup.get(this.componentMetadata.fieldName).setValue(switchValue);
	}

	public init(value, data) {
		this.switchParams = this.params.settings ?? defaultSwitchParams;
		this.dataObj = data;
		this.update(value, data);
	}

	public reset() {
		this.formGroup.get(this.componentMetadata.fieldName).setValue(false);
	}

	public isPristine(): boolean {
		return this.formGroup.pristine;
	}
}
