import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

type CollapseState = {
	isExpanded: boolean
}

@Injectable()
export class CollapseStateService extends ComponentStore<CollapseState> {

	isExpanded$ = this.select(({ isExpanded }) => isExpanded);

	constructor() {
		super({ isExpanded: true });
	}

	collapse = () => this.setState({ isExpanded: false });
	expand = () => this.setState({ isExpanded: true });

	toggleCollapse = () => {
		if (this.get().isExpanded) {
			this.collapse();
		} else {
			this.expand();
		}
	}
}
