import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { trimEnd } from '@app/shared/general-helper';
import { ApprovalStatusPipe } from '@app/shared/pipes/approval-status.pipe';
import { DealTagPipe } from '@app/shared/pipes/deal-tag.pipe';
import { MainDoctorContactInfoPipe } from '@app/shared/pipes/main-doctor-contact-info.pipe';
import { TruncatePipe } from '@app/shared/pipes/truncate.pipe';
import * as _ from 'lodash';

@Injectable(
	{providedIn: 'root'}
)
export class PipeProviderService {

	private mapping = new Map<string, any>([
		['date', DatePipe],
		['approvalStatus', ApprovalStatusPipe],
		['currency', CurrencyPipe],
		['truncate', TruncatePipe],
		['percent', PercentPipe],
		['mainDoctorContactInfo', MainDoctorContactInfoPipe],
		['dealTag', DealTagPipe],
	]);

	constructor(private injector: Injector) {
	}

	// Working with following expressions (zero args, multiple args, different ways of inputting string literals)
	//
	// <ga-value field="appliedOn" pipe="date"></ga-value>							<--- preferred way
	// <ga-value field="appliedOn" pipe="date:'aa:bb:cc':'dd:ee:ff'"></ga-value>	<--- preferred way
	// <ga-value field="appliedOn" [pipe]="'date:\'xx:yy:zz\''"></ga-value>
	public resolvePipe(pipeString: string): (data) => unknown {
		// Regex solution - kept for reference
		// not sufficient - all pipeArgs are in one matching group, e.g: 'aa:bb:cc':'dd:ee:ff'.
		//
		// let regex = "\^([^\:]*)(?:\:(\'.*\'))*$\g";
		// let [all, pipeName, pipeArgs] = pipeString.match(regex);
		// console.log(all);
		// console.log(pipeName);
		// console.log(pipeArgs);
		let pipeArgs = pipeString.split(":'");
		const pipeName = pipeArgs.shift();
		pipeArgs = _.map(pipeArgs, (item) => trimEnd(item, "'") ); // strip trailing ' from arg elements
		const pipe = this.getPipe(pipeName);

		return (value) => pipe.transform(value, ...pipeArgs);
	}


	private getPipe(pipeName: string) {
		if (this.mapping.has(pipeName)) {
			const pipe = this.mapping.get(pipeName);

			return this.injector.get(pipe);
		}

		throw new Error(`${pipeName} pipe in not defined in PipeProviderService. Please update string-to-pipe mapping there.`);
	}
}
