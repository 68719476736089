import { FilterVisitor } from '@app/shared/filter-visitors/models/filter-visitor';
import { Doctor } from '@app/shared/model/types/doctor';
import { get } from 'lodash';

export class DoctorAddressMultiselectVisitor extends FilterVisitor<string[]> {
	constructor(private path: string) {
		super(path);
	}

	public filter(doctor: Doctor): boolean {
		return doctor.addresses.some((address) =>
			this.value.some((codeListItemId) => codeListItemId === get(address, `${this.path}.id`, null)));
	}

	public isActive(): boolean {
		return this.value?.length > 0;
	}
}
