import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CptCodesForm } from '@app/settings/cpt-codes/form/cpt-codes-form.model';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { finalize } from 'rxjs';

@Component({
	selector: 'cpt-codes-form',
	templateUrl: './cpt-codes-form.component.html',
	styles: ['.mt-20 {margin-top: 20px}']
})
export class CptCodesFormComponent implements OnInit {
	@HostBinding('class.view') hostClass = true;

	cptHook = [{ name: 'urlCodelistUpdate', meta: { 'url': [UrlCodelistLink.CptCodes] } }];
	form: FormGroup<CptCodesForm>;

	constructor(
		private apiLink: GaApiLink,
		private router: Router) {
	}

	public ngOnInit(): void {
		this.initForm();
	}

	public saveAndBackToList(btnDisableLoaderFn: () => void) {
		const cptCode = { ...this.form.value };
		this.apiLink.create('cPTCode', cptCode, [], {}, this.cptHook)
			.pipe(finalize(() => btnDisableLoaderFn()))
			.subscribe({
				next: () => this.router.navigate(['/settings/cpt-codes']),
			});
	}

	private initForm(): void {
		this.form = new FormGroup<CptCodesForm>({
			code: new FormControl('', Validators.required),
			title: new FormControl('', Validators.required)
		});
	}
}
