import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TimezoneItem } from '@app/shared/model/types/timezone';

@Injectable()
export class TimezoneInputService {
	private timezoneControl = new FormControl<TimezoneItem>(null);

	get timezoneCtrl() {
		return this.timezoneControl;
	}

	set timezone(timezoneItem) {
		this.timezoneControl.setValue(timezoneItem);
	}
}
