import { Injectable } from '@angular/core';
import { EntityAssociationType } from '@app/shared/model/constants/entity-association-type';
import { EntityAssociation } from '@app/shared/model/types/entity-association';
import { Todo } from '@app/shared/model/types/todo';
import { map, pipe } from 'rxjs';

interface LawFirmAssociation {
	lawFirm: {
		id: string,
		name: string
	},
	id: number
}

interface FundingAssociation {
	funding: {
		id: string,
		internalId: string,
		applicant: {
			id: string,
			firstName: string,
			lastName: string
		}
	},
	id: number
}

interface ApplicantAssociation {
	applicant: {
		id: string,
		internalId: string,
		firstName: string,
		lastName: string,
	},
	id: number
}

interface MedicalFacilityAssociation {
	medicalFacility: {
		id: string,
		name: string
	},
	id: number
}

interface DoctorAssociation {
	doctor: {
		id: string,
		name: string
	},
	id: number
}

interface AttorneyAssociation {
	attorney: {
		id: string,
		lawFirm: {
			id: string,
			name: string
		},
		firstName: string,
		lastName: string
	},
	id: number
}

type TodoAssociation = LawFirmAssociation |
	FundingAssociation |
	ApplicantAssociation |
	MedicalFacilityAssociation |
	DoctorAssociation |
	AttorneyAssociation

type UpdatedTodo = Omit<Todo, 'associations'> & { associations: TodoAssociation[] }

@Injectable()
export class TodoAssociationHelperService {
	public transformTodoAssociations() {
		return pipe(
			map<UpdatedTodo, Todo>((todo) => {
				const associations = todo.associations.map((association) => this.convertAssociation({ ...association }));
				return { ...todo, associations };
			})
		);
	}

	private convertAssociation(association: TodoAssociation): EntityAssociation {
		const result: EntityAssociation = {
			id: association.id.toString(),
			association_type: null,
			label: '',
			referenceId: ''
		};
		if (EntityAssociationType.LawFirm in association) {
			result.association_type = EntityAssociationType.LawFirm;
			result.label = association.lawFirm.name;
			result.referenceId = association.lawFirm.id;
		} else if (EntityAssociationType.Funding in association) {
			result.association_type = EntityAssociationType.Funding;
			const applicantName = `${association.funding.applicant.firstName} ${association.funding.applicant.lastName}`;
			result.label = `${association.funding.internalId} - ${applicantName}`;
			result.referenceId = association.funding.applicant.id;
		} else if (EntityAssociationType.Applicant in association) {
			result.association_type = EntityAssociationType.Applicant;
			result.label = `${association.applicant.firstName} ${association.applicant.lastName}`;
			result.referenceId = association.applicant.id;
		} else if (EntityAssociationType.MedicalFacility in association) {
			result.association_type = EntityAssociationType.MedicalFacility;
			result.label = association.medicalFacility.name;
			result.referenceId = association.medicalFacility.id;
		} else if (EntityAssociationType.Doctor in association) {
			result.association_type = EntityAssociationType.Doctor;
			result.label = association.doctor.name;
			result.referenceId = association.doctor.id;
		} else if (EntityAssociationType.Attorney in association) {
			result.association_type = EntityAssociationType.Attorney;
			const attorneyName = `${association.attorney.firstName} ${association.attorney.lastName}`;
			result.label = `${attorneyName} - ${association.attorney.lawFirm.name} (#${association.attorney.lawFirm.id})`;
			result.referenceId = association.attorney.id;
		}
		return result;
	}
}

