import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { toNumber } from '@app/shared/general-helper';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { Codelist } from '@app/shared/model/types/codelists/codelist';
import { EntityId } from '@app/shared/model/types/entity-id';
import { TodoCardData } from '@app/todo/model/todo-card-data';
import { TodoLabelResponseModel } from '@app/todo/todo-card/todo-label-response.model';
import { faPlusCircle, faTags } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
	selector: 'todo-labels',
	templateUrl: './todo-labels.component.html',
	styleUrls: ['./todo-labels.component.scss']
})
export class TodoLabelsComponent implements OnInit {

	@Input() todo: TodoCardData;
	todoLabels$ = new BehaviorSubject<Codelist[]>([]);
	readonly todoLabelCodeList = NonUrlCodelistName.TodoLabel;
	readonly faIcons = {
		plusCircle: faPlusCircle,
		tag: faTags
	}
	showTodoLabelPicker$ = new BehaviorSubject<boolean>(false);

	constructor(
		private apiLink: GaApiLink,
		private http: HttpClient
	) {}

	public ngOnInit(): void {
		this.todoLabels$.next([...this.todo.labels]);
	}

	public updateLabels(labelsToChange: EntityId[], isDelete: boolean): void {
		let payload: EntityId[];
		if (!isDelete) {
			payload = [...labelsToChange, ...this.todoLabels$.getValue().map(label => label.id)];
		} else {
			const copyLabels = [...this.todoLabels$.getValue()];
			const element = copyLabels.find(label => label.id === labelsToChange[0]);
			copyLabels.splice(copyLabels.indexOf(element), 1);
			payload = copyLabels.map(label => label.id);
		}
		this.updateTodoLabels(payload).subscribe((response) => {
			this.todoLabels$.next(response.labels)
			this.hideTodoLabelPicker();
		});
	}

	public removeLabel(labelId: EntityId): void {
		this.updateLabels([labelId].flat(), true);
	}

	public triggerTodoLabelPicker(): void {
		this.showTodoLabelPicker$.next(true);
	}

	public hideTodoLabelPicker(): void {
		this.showTodoLabelPicker$.next(false);
	}

	private updateTodoLabels(payload: EntityId[]): Observable<TodoLabelResponseModel> {
		const labels = payload.map((id) => toNumber(id));
		return this.http.patch<TodoLabelResponseModel>(`/api/todos/${this.todo.id}`, { labels });
	}
}
