import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BaseUrlInterceptor implements HttpInterceptor {

	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const baseUrl = environment.apiUrl;

		const debugParams = localStorage.getItem('debug') && !environment.production ? { 'XDEBUG_SESSION_START': '1' } : {};

		return next.handle(request.clone({
			url: `${baseUrl}${request.url}`,
			setParams: debugParams
		}));
	}
}
