<div (clickOutside)="closeCategoryList()" *ngrxLet="categories$; let categories">
	<div class="{{wrapperClass}}" (click)="toggleCategoryList()">
		<todo-category-labels [todoCategories]="categories" wrapperClass="{{categoryLabelsWrapperClass}}"></todo-category-labels>
	</div>
	<todo-category-list
		*ngIf="showCategoryList"
		@fadeUpDown
		(selectCategory)="setCategory($event)"
		[activeCategories]="categories"
		class="quick-list left">
	</todo-category-list>
</div>
