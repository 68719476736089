import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { routes } from '@app/app-routing.module';
import { IdentityProviderService } from '@app/core/auth/identity-provider.service';
import { TokenStorageService } from '@app/core/auth/token-storage.service';
import { Credentials } from '@app/core/auth/types/credentials';
import { Identity } from '@app/core/auth/types/identity';
import { ServerFieldsDefinitionService } from '@app/core/fields-definition/server/server-fields-definition.service';
import { GoogleAnalyticsService } from '@app/core/googleanalytics/googleanalytics.service';
import { RevisionsService } from '@app/core/revisions/revisions.service';
import { IRevisions, RevisionType } from '@app/core/revisions/types/revision-type';
import { AclRoleType } from '@app/core/security/acl-role-type';
import { PermissionsService } from '@app/core/security/permissions/new/permissions.service';
import { WebSocketService } from '@app/core/web-socket/web-socket.service';
import { objectStoresMappingConfig } from '@app/database/object-stores-mapping.config';
import { AgGridColumnStorageService } from '@app/ga-grid/services/ag-grid-storage-services/ag-grid-column-storage.service';
import { AgGridFilterStorageService } from '@app/ga-grid/services/ag-grid-storage-services/ag-grid-filter-storage.service';
import { LocalStorageKeys } from '@app/shared/model/constants/local-storage-keys';
import { clearBoards } from '@app/w-boards/store/actions/board.actions';
import { Store } from '@ngrx/store';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { combineLatest, forkJoin, map, Subject, switchMap, takeUntil, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthService implements OnDestroy {

	private destroy$ = new Subject<void>();

	constructor(
		private router: Router,
		private store: Store,
		private http: HttpClient,
		private ws: WebSocketService,
		private agGridFilterStorageService: AgGridFilterStorageService,
		private agGridColumnStorageService: AgGridColumnStorageService,
		private tokenStorage: TokenStorageService,
		private identityProvider: IdentityProviderService,
		private revisionsService: RevisionsService,
		private permissionsService: PermissionsService,
		private fieldsDefinitionService: ServerFieldsDefinitionService,
		private dbService: NgxIndexedDBService,
		private googleAnalyticsService : GoogleAnalyticsService
	) {
	}

	public login(credentials: Credentials) {
		return this.http.post('/api/auth/login', credentials, {
			headers: { InterceptorSkipHeader: 'true' },
			observe: 'response'
		}).pipe(
			tap((response: HttpResponse<any>) => this.tokenStorage.storeToken(response.body.token)),
			switchMap(() => this.revisionsService.getCurrentRevisions()),
			switchMap((revisions: IRevisions) => forkJoin([
				this.permissionsService.initPermissions(revisions.getRevision(RevisionType.PermissionsRevision)),
				this.fieldsDefinitionService.initFieldDefinitions(revisions.getRevision(RevisionType.FieldDefinitionsRevision)),
			])),
			tap(() => this.identityProvider.createAuthenticatedIdentity()),
			tap(() => this.googleAnalyticsService.trackEvent('login', {userId: this.getCurrentUser().id })),
			map(() => this.ws.reconnect(this.tokenStorage.getAuthToken()))
		);
	}

	public logout() {
		this.clearIndexedDb();
		const todoNotificationStatus = localStorage.getItem('todoNotificationStatus');
		const whiteboardFilters = localStorage.getItem(LocalStorageKeys.WHITEBOARD_FILTERS) ?? null;
		const keysToRemove = [
			'auth_token',
			'codelists',
			'revisions',
			'permissions',
			'serverFieldsDefinition',
			'showDot',
			'unreadSmsCount'
		];
		keysToRemove.forEach(key => {
			localStorage.removeItem(key);
		});
		localStorage.setItem('todoNotificationStatus', todoNotificationStatus);
		localStorage.setItem(LocalStorageKeys.WHITEBOARD_FILTERS, whiteboardFilters);
		this.agGridFilterStorageService.persistToLocalStorage();
		this.agGridColumnStorageService.persistToLocalStorage();
		this.identityProvider.clearAuthenticatedIdentity();

		this.ws.disconnect();
		this.store.dispatch(clearBoards());
		this.router.navigate(['/login']);
	}

	private clearIndexedDb(): void {
		const clearRequests$ = Object.keys(objectStoresMappingConfig).map((storeName) =>
			this.dbService.clear(storeName)
		);

		combineLatest(clearRequests$).pipe(takeUntil(this.destroy$)).subscribe();
	}

	private routeExists(routePath: string): boolean {
		return routes.some(route => route.path && routePath.includes(route.path));
	}

	public redirectAfterLogin(): UrlTree {
		const lastVisitedUrl = localStorage.getItem(LocalStorageKeys.LAST_VISITED_PAGE);
		if (lastVisitedUrl) {
			localStorage.removeItem(LocalStorageKeys.LAST_VISITED_PAGE);
			return this.routeExists(lastVisitedUrl) ? this.router.parseUrl(lastVisitedUrl) : this.router.createUrlTree(['dashboard']);
		}

		switch (this.identityProvider.getIdentity().roleType) {
			case AclRoleType.Admin:
			case AclRoleType.SuperAdmin:
			case AclRoleType.Consultant:
				return this.router.createUrlTree(['dashboard']);
			default:
				return this.router.createUrlTree(['dashboard']);
		}
	}

	public getCurrentUser(): Identity {
		return this.identity;
	}

	get identity() {
		return this.identityProvider.getIdentity();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
