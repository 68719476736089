<div class="dash-panel ibox">
	<collapsible-item>
		<h4 header-left>Dashboard Breakdowns</h4>
		<tabset>
			<tab heading="Pipeline" *aclIsAllowed="'dashboard.summary'" class="flex min-height-tab" #pipelineTab="tab">
				<pipeline-breakdown *ngIf="pipelineTab.active" class="flex-item"></pipeline-breakdown>
			</tab>
			<tab heading="Case Type Breakdown" *aclIsAllowed="'dashboard.summary'" #caseTypeTab="tab">
				<case-type-breakdown *ngIf="caseTypeTab.active"
									 class="flex-item">
				</case-type-breakdown>
			</tab>
			<tab heading="Age Breakdown" *aclIsAllowed="'dashboard.summary'" class="flex" #ageBreakdownTab="tab">
				<age-breakdown class="flex-item" *ngIf="ageBreakdownTab.active"></age-breakdown>
			</tab>
			<tab heading="Top 5 Insurance Carriers" *aclIsAllowed="'dashboard.summary'" class="flex" #carriersTab="tab">
				<top-entities-stats dataUrl="/api/dashboard/top-five-insurance-carriers"
									titleHeaderName="Insurance Carrier"
									storageKey="dashboard-insurance-carrier-chart"
									class="flex-item"
									*ngIf="carriersTab.active">
				</top-entities-stats>
			</tab>
			<tab heading="Top 5 Law Firms" *aclIsAllowed="'dashboard.summary'" class="flex" #lawFirmsTab="tab">
				<top-entities-stats dataUrl="/api/dashboard/top-five-law-firms"
									titleHeaderName="Law Firm"
									storageKey="dashboard-law-firm-chart"
									class="flex-item"
									*ngIf="lawFirmsTab.active">
				</top-entities-stats>
			</tab>
			<tab heading="Top 5 States" *aclIsAllowed="'dashboard.summary'" class="flex" #statesTab="tab">
				<top-entities-stats dataUrl="/api/dashboard/top-five-states"
									titleHeaderName="State"
									storageKey="dashboard-state-chart"
									class="flex-item"
									*ngIf="statesTab.active">
				</top-entities-stats>
			</tab>
			<tab heading="Top 5 Facility/Hospitals" *aclIsAllowed="'dashboard.summary'" class="flex"
				 #facilitiesTab="tab">
				<top-entities-stats dataUrl="/api/dashboard/top-five-medical-facilities"
									titleHeaderName="Medical Facility"
									storageKey="dashboard-medical-facility-chart"
									class="flex-item"
									*ngIf="facilitiesTab.active">
				</top-entities-stats>
			</tab>
			<tab heading="Top 5 Doctors" *aclIsAllowed="'dashboard.summary'" class="flex" #doctorsTab="tab">
				<top-entities-stats dataUrl="/api/dashboard/top-five-doctors"
									titleHeaderName="Doctor"
									storageKey="dashboard-doctor-chart"
									class="flex-item"
									*ngIf="doctorsTab.active">
				</top-entities-stats>
			</tab>
		</tabset>
	</collapsible-item>
</div>
