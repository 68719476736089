import { Component, OnDestroy, OnInit } from '@angular/core';
import { SalesActivityWidgetModalComponent } from '@app/dashboard/sales-activity-widget/sales-activity-widget-modal.component';
import { SalesChartConfig } from '@app/dashboard/sales-activity-widget/sales-chart-config';
import { SalesActivityReportService } from '@app/reports/sales-activity-report/sales-activity-report.service';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChartOptions } from 'chart.js';

@Component({
	selector: 'sales-activity-widget',
	templateUrl: './sales-activity-widget.component.html',
	providers: [SalesActivityReportService]
})
export class SalesActivityWidgetComponent implements OnInit, OnDestroy {

	subscription: Subscription;
	salesChartConfig: SalesChartConfig;
	isLoading = false;
	datesFilter: Date[];
	notes: any[];
	chartOptions: ChartOptions<'bar'> = {
		plugins: {
			legend: {
				display: true,
				position: 'bottom'
			},
		},
		scales: {
			x: {
				ticks: {
					autoSkip: false,
				},
				min: 0
			},
			y: {
				type: 'linear',
				position: 'left',
				ticks: {
					stepSize: 1,
				},
				min: 0,
				beginAtZero: true
			},
		}
	};

	private destroy$ = new Subject<void>();

	constructor(private salesActivityReportService: SalesActivityReportService,
				public bsModal: BsModalService
	) {}

	public ngOnInit(): void {
		this.salesChartConfig = new SalesChartConfig([]);
		this.setInitialDateFilter();
	}

	public setInitialDateFilter(): void {
		this.datesFilter = [
			moment().startOf('isoWeek').subtract(2, 'days').startOf('isoWeek').toDate(),
			moment().startOf('isoWeek').subtract(1, 'day').toDate()
		];
	}

	public fetchSalesActivities(): void {
		this.isLoading = true;

		this.destroy$.next();

		this.subscription = this.salesActivityReportService.fetchData(this.datesFilter)
			.pipe(takeUntil(this.destroy$)).subscribe((notes) => {
				this.notes = notes;
				this.salesChartConfig = new SalesChartConfig(notes);
				this.isLoading = false;
			});
	}

	public onDateRangeSelected(dates: Date[]): void {
		if (dates.length !== 0) {
			this.datesFilter = dates;
			this.fetchSalesActivities();
		}
	}

	public onChartClick(event): void {
		if (event.active.length == 0) {
			return;
		}
		const selectedName = this.salesChartConfig.getLabels()[event.active[0].index];
		const gridData = this.notes.filter(note => `${note.createdBy.firstName} ${note.createdBy.lastName}` == selectedName);
		this.bsModal.show(SalesActivityWidgetModalComponent, { class: 'modal-lg', initialState: { gridData: gridData } });
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

}
