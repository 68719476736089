<div class="base-card">
	<div class="base-card__header">
		<div class="base-card__header__title">Medical Facility</div>
	</div>
	<div class="base-card__content">
		<div class="row col-12">
			<div class="col-3 base-input__wrapper">
				<codelist-multiselect data-cy="medicalFacilityTypeMultiselect"
				                      [selectControl]="formControls.medicalFacilityType"
				                      label="Medical Facility Type"
				                      [codelistClass]="'medicalFacilityType'"
				                      [extraOptions]="othersOption"></codelist-multiselect>
			</div>
			<div class="col-3 base-input__wrapper">
				<codelist-multiselect data-cy="loaTypeMultiselect"
				                      [selectControl]="formControls.loaType"
				                      label="LOA Type"
				                      [codelistClass]="'loaType'"></codelist-multiselect>
			</div>
			<div class="col-3">
				<boolean-select label="Active" [inputControl]="formControls.active"></boolean-select>
			</div>
			<div class="col-3 base-input__wrapper">
				<codelist-multiselect data-cy="medicalFacilityTierMultiselect" [selectControl]="formControls.tier" label="Tier" [codelistClass]="medicalFacilityTier">
				</codelist-multiselect>
			</div>
		</div>
		<div class="row col-12">
			<div class="col-3 base-input__wrapper">
				<google-places-autocomplete (onAddressSelected)="setLocation($event)"></google-places-autocomplete>
			</div>
			<div class="col-3 base-input__wrapper">
				<label class="base-label" for="city">City</label>
				<input class="base-input" id="city" [formControl]="formControls.city" />
			</div>
			<div class="col-3 base-input__wrapper">
				<label class="base-label" for="zip">Zip</label>
				<input class="base-input" id="zip" [formControl]="formControls.zip" />
			</div>
			<div class="col-3">
				<rp-cost-editor (rpCostChanged)="onRpCostChanged($event)"
				                data-cy="medicalFacilityRpCost"></rp-cost-editor>
			</div>
		</div>
	</div>
</div>





