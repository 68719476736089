import { Injectable } from '@angular/core';
import { defaultFilterContext, FilterContext } from '@app/shared/filter-visitors/models/filter-context';
import { FilterMode } from '@app/shared/filter-visitors/models/filter-mode';
import { FilterVisitor } from '@app/shared/filter-visitors/models/filter-visitor';
import { BoardTypeModel } from '@app/w-boards/models/board-type.model';
import { BoardLocalStorageService } from '@app/w-boards/services/board-local-storage.service';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class BoardFilterService {
	globalContext: FilterContext;
	isFilterExpanded: boolean;
	private filter$ = new BehaviorSubject(null);
	private filterVisitors: FilterVisitor[] = [];
	private resetFilters$ = new Subject();

	private destroy$ = new Subject();

	constructor(private boardLocalStorageService: BoardLocalStorageService) {}

	set board(board: BoardTypeModel) {
		this.boardLocalStorageService.setBoard(board);
		this.globalContext = this.boardLocalStorageService.globalContext;
		this.isFilterExpanded = this.boardLocalStorageService.isFilterExpanded;
	}

	public addFilter(filters: FilterVisitor[]): void {
		this.destroy$.next(null);
		filters.forEach(filter => {
			const savedFilter = this.boardLocalStorageService.getSavedFilter(filter.getId());
			filter.setFilter(savedFilter?.value ?? null);
			filter.setContext(savedFilter?.context ?? defaultFilterContext);
		});
		this.filterVisitors.push(...filters);
		this.refreshOnChange();
	}

	private refreshOnChange(): void {
		const formControlsListener = this.filterVisitors.map(filterVisitor => filterVisitor.getFormControl().valueChanges);
		merge(...formControlsListener).pipe(
			debounceTime(600),
			takeUntil(this.destroy$)
		).subscribe(() => this.refreshFilters());
	}

	applyFilter = () =>
		switchMap((fundings: any[]) =>
			this.filter$
				.pipe(
					tap(() => this.saveFilters()),
					map(() => {
						return fundings.filter(funding => this.includeInBoard(funding));
					}))
		);

	private saveFilters(): void {
		this.boardLocalStorageService.saveFilters(this.filterVisitors);
	}

	private includeInBoard(funding): boolean {
		const activeFilters = this.filterVisitors.filter(visitor => visitor.isActive());
		if (activeFilters.length == 0) {
			return true;
		}
		if (this.globalContext.mode == FilterMode.AND) {
			return activeFilters.every(visitor => visitor.filter(funding));
		} else {
			return activeFilters.some(visitor => visitor.filter(funding));
		}
	}

	public refreshFilters(): void {
		this.filter$.next(null);
	}

	public setGlobalContext(context: FilterContext): void {
		this.globalContext = context;
		this.boardLocalStorageService.setGlobalContext(context);
		this.refreshFilters();
	}

	public setIsFilterExpanded(isFilterExpanded: boolean): void {
		this.isFilterExpanded = isFilterExpanded;
		this.boardLocalStorageService.setIsFilterExpanded(isFilterExpanded);
	}

	public clearFilters(): void {
		this.filterVisitors = [];
		this.destroy$.next(null);
	}

	public isFiltering$(): Observable<boolean> {
		return this.filter$.asObservable()
			.pipe(
				map(() => {
					return this.globalContext.mode != defaultFilterContext.mode
						|| this.filterVisitors.some(visitor => visitor.isFiltering());
				}),
			);
	}

	public resetFilters(): void {
		this.filterVisitors.forEach(visitor => {
			visitor.setFilter(null, false);
			visitor.setContext(defaultFilterContext);
		});
		this.setGlobalContext(defaultFilterContext);
		this.resetFilters$.next(null);
	}

	public getResetFilters$(): Observable<any> {
		return this.resetFilters$.asObservable();
	}

}
