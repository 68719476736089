export const environment = {
	production: false,
	apiUrl: 'https://preview-api.rockpointlegalfunding.com',
	websocketApiUrl: 'https://preview.rockpointlegalfunding.com:3100',
	titleSuffix: 'Rockpoint Legal Funding',
	disableAgGridVirtualization: false,
	enabledRouteTracing: false,
	agGridLicence: '',
	googleApiToken: 'AIzaSyB-61qDdoQ0yReHWFkbZI0pmvQPAtsRjiI',
	googleAnalyticsMsrmntId: 'G-CX4XVVXTP4',
	sentry: {
		dsn: "https://6c72f908353b4bc18466c9dba614626d@o1418023.ingest.sentry.io/6761140",
		sampleRate: 0.0,
		origins: ['preview-api.rockpointlegalfunding.com', 'preview.rockpointlegalfunding.com']
	},
	unleash: {
		proxyUrl: "https://preview.rockpointlegalfunding.com:3200/proxy",
		clientKey: "ef91cc47-e7ec-400c-bfad-cc9bcd914d18",
		appName: "preview",
		refreshInterval: 600
	},
	envVar: {
		SENTRY_RELEASE: ""
	}
};
