import { Injectable } from '@angular/core';
import { EntityAssociationType } from '@app/shared/model/constants/entity-association-type';
import { EntityAssociationsListEntry, InitialEntityAssociation } from '@app/widgets/entity-associations-list/model/entity-associations-list-entry';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class EntityAssociationsService {
	private associations$ = new BehaviorSubject<EntityAssociationsListEntry[]>([]);

	public getAssociations$(): Observable<EntityAssociationsListEntry[]> {
		return this.associations$.asObservable();
	}

	public addAssociation(initialAssociation: InitialEntityAssociation): void {
		const association: EntityAssociationsListEntry = {
			label: initialAssociation.label,
			association_type: initialAssociation.association_type,
			referenceId: initialAssociation.referenceId,
			parentReferenceId: initialAssociation.parentReferenceId,
			id: uuidv4()
		};

		this.associations$.next([...this.associations$.value, association]);
	}

	public addAllAssociations(associations: EntityAssociationsListEntry[]): void {
		this.associations$.next([...this.associations$.value, ...associations]);
	}

	public removeAssociation(associationToRemove: EntityAssociationsListEntry): void {
		const associations = this.associations$.value;
		const indexToRemove = associations.findIndex((association) => association.id == associationToRemove.id);
		if (indexToRemove >= 0) {
			associations.splice(indexToRemove, 1)
			this.associations$.next([...associations]);
		}
	}

	get associations() {
		return this.associations$.value.map((association) => {
			let associationTypeRequest = `${association.association_type}s`;
			if (association.association_type == EntityAssociationType.Attorney) {
				associationTypeRequest = 'lawFirmStaff';
			}
			if (association.association_type == EntityAssociationType.MedicalFacility) {
				associationTypeRequest = 'medicalFacilities';
			}
			return { ...association, association_type: associationTypeRequest };
		});
	}

	public createAssociationMirrors(): Record<string, string[] | boolean> {
		const mappedAssociationTypes = this.associations$.value.map((association) => {
			let associationRequest: { associationType: string, id: string };
			switch (association.association_type) {
				case EntityAssociationType.Applicant:
					associationRequest = { associationType: 'mirrorToApplicants', id: association.referenceId };
					break;
				case EntityAssociationType.Doctor:
					associationRequest = { associationType: 'mirrorToDoctors', id: association.referenceId };
					break;
				case EntityAssociationType.LawFirm:
					associationRequest = { associationType: 'mirrorToLawFirms', id: association.referenceId };
					break;
				case EntityAssociationType.MedicalFacility:
					associationRequest = { associationType: 'mirrorToMedicalFacilities', id: association.referenceId };
					break;
			}
			return associationRequest;
		})
		const groupedAssociations = _.groupBy(mappedAssociationTypes, 'associationType');
		const associationTypesRequest = Object.keys(groupedAssociations);
		const result: Record<string, string[] | boolean> = {};
		if (associationTypesRequest.length > 0) {
			result.mirror = true;
		}
		associationTypesRequest.forEach((associationType) => {
			result[associationType] = groupedAssociations[associationType].flatMap((item) => item.id);
		});
		return result;
	}
}
