<div class="label-wrapper">
	<label class="base-label">
		Due Date
	</label>
</div>
<div class="row date-input-wrapper">
	<datepicker-input [validator]="dateValidator"
					  [bsConfig]="bsConfig"
					  dataCyTag="dueDate-datepicker"
					  (onDateControlChange)="dateControlChanged($event)"
					  placeholder="Due date..." class="col-sm-4"></datepicker-input>
	<div class="todo-card__due-date__wrapper__timepicker col-sm-4">
		<timepicker [formControl]="timeControl" [mousewheel]="true" [minuteStep]="1"></timepicker>
		<div *ngIf="timeControl.value" @fadeInOut (click)="clearTime()" class="clear">clear</div>
	</div>
	<ng-container *ngrxLet="dateControlData$; let dateControlData">
		<timezone-input class="col-sm-4" [disabled]="!dateControlData.value"></timezone-input>
	</ng-container>
</div>
