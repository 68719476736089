<div (clickOutside)="dropdownOpened = false">
	<div class="sms-btn" (click)="openSmsWidget()" data-cy="sms-btn" tooltip="SMS Inbox" placement="left">
		<svg-icon [icon]="svgIconNames.Envelope" class="base-icon primary-color"></svg-icon>
		<unread-sms-badge class="sms-notification__badge"></unread-sms-badge>
		<svg class="badge-icon" *ngIf="showDot" height="20" width="20">
			<circle cx="12" cy="12" r="5" stroke="black" stroke-width="0" fill="#32c3a6" />
		</svg>
	</div>
	<div *ngIf="dropdownOpened" class="sms-widget" @fadeInRight>
		<div class="triangle"></div>
		<div class="sms-widget__header">
			<div class="sms-widget__header__link" (click)="markAllConversationsAsRead()">Mark All As Read</div>
			<a [routerLink]="['/sms-inbox']" (click)="dropdownOpened = !dropdownOpened" class="base-flex__item-right">Go to InBox</a>
		</div>
		<div class="sms-widget__content">
			<inbox-conversation-list *ngIf="isInboxReady"
									[showSender]="true"
									[showMarkReadButton]="false"
									[conversations]="conversations"
									(onSelectConversation)="selectConversation($event)">
			</inbox-conversation-list>
		</div>
	</div>
</div>
