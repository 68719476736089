import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FileUploader, FileUploaderOptions } from '@app/widgets/file-upload/model/file-uploader.model';

@Directive({ selector: '[fileDrop]' })
export class FileDropDirective {
	@Input() uploader?: FileUploader;
	@Output() fileOver = new EventEmitter<boolean>();
	@Output() onFileDrop = new EventEmitter<File[]>();

	protected element: ElementRef;

	constructor(element: ElementRef) {
		this.element = element;
	}

	public getOptions(): FileUploaderOptions {
		return this.uploader?.options;
	}

	public getFilters(): string {
		return '';
	}

	@HostListener('drop', ['$event'])
	public onDrop(event: DragEvent): void {
		const transfer = event.dataTransfer;
		if (!transfer) {
			return;
		}

		const options = this.getOptions();
		const filters = this.getFilters();
		this.preventAndStop(event);
		const files = this.uploader.parseFileList(transfer.files);
		if (options) {
			this.uploader?.addToQueue(files, options, filters);
		}
		this.fileOver.emit(false);
		this.onFileDrop.emit(files);
	}

	@HostListener('dragover', ['$event'])
	public onDragOver(event: DragEvent): void {
		const transfer = event.dataTransfer;
		if (!this.haveFiles(transfer.types)) {
			return;
		}

		transfer.dropEffect = 'copy';
		this.preventAndStop(event);
		this.fileOver.emit(true);
	}

	@HostListener('dragleave', ['$event'])
	public onDragLeave(event: DragEvent): void {
		if ((this as any).element) {
			if (event.currentTarget === (this as any).element[0]) {
				return;
			}
		}

		this.preventAndStop(event);
		this.fileOver.emit(false);
	}

	private preventAndStop(event: Event): void {
		event.preventDefault();
		event.stopPropagation();
	}

	private haveFiles(types: any): boolean {
		if (!types) {
			return false;
		}

		if (types.indexOf) {
			return types.indexOf('Files') !== -1;
		} else if (types.contains) {
			return types.contains('Files');
		} else {
			return false;
		}
	}
}
