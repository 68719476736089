import { Router } from '@angular/router';
import { get } from 'lodash';

export abstract class AgGridStorageService {
	abstract readonly storageId;
	private persistedState = null;

	protected constructor(private router: Router) {}

	public initializeStorage(): void {
		this.persistedState = JSON.parse(localStorage.getItem(this.storageId)) || {};
	}

	public clearStorageForCurrentPage(): void {
		this.persistedState[this.router.url] = {};
		this.persistToLocalStorage();
	}

	public persist(columnState): void {
		this.persistedState[this.router.url] = columnState;
		this.persistToLocalStorage();
	}

	public getPersistedState(): any | null {
		if (this.persistedState === null) {
			this.initializeStorage();
		}
		return get(this.persistedState, this.router.url, null);
	}

	public persistToLocalStorage(): void {
		localStorage.setItem(this.storageId, JSON.stringify(this.persistedState));
	}
}
