import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GhostElementComponent } from '@app/widgets/ghost-element/ghost-element.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		GhostElementComponent
	],
	exports: [
		GhostElementComponent
	]
})
export class GhostElementModule {}
