import { Injectable } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { GaUtils } from '@app/shared/ga-utils';
import { firstValueFrom, Observable, Subject } from 'rxjs';

@Injectable()
export class GridCodelistFilterService {
	private columnEmitter$ = new Subject<string>();

	constructor(private codelistService: CodelistRepositoryService,
				private utils: GaUtils) {}

	public resolveCodelistFilter(fieldMetadata, codelistFilter, valueGetter): object {

		// promise handle
		if (codelistFilter && codelistFilter instanceof Promise) {
			return {
				values: (params) => {
					codelistFilter.then((data) => {
						params.success(data);
						this.setGridFilter(fieldMetadata.field);
					});
				},
				valueGetter:  ({data}) => valueGetter(data),
				cellRenderer: (params) => params.value,
			};
		}

		// array handle
		if (codelistFilter && Array.isArray(codelistFilter)) {
			return {
				values: codelistFilter,
				valueGetter:  ({data}) => valueGetter(data),
				cellRenderer: (params) => params.value,
			};
		}

		// string handle
		if (codelistFilter && typeof codelistFilter === 'string') {
			return {
				values: (params) => this.getCodelistValues(params, codelistFilter, true),
				cellRenderer: (params) => params.value,
			};
		}

		// codelist handle
		if(fieldMetadata.isCodelist) {
			return {
				values: (params) => this.getCodelistValues(params, fieldMetadata.association.targetEntity, false),
				cellRenderer: (params) => params.value,
			};
		}

		return {};
	}

	private async getCodelistValues(params, codelistName, codelistByUrl): Promise<any> {
		const codelistData = codelistByUrl
			? await firstValueFrom(this.codelistService.getByUrl<any>(codelistName))
			: await firstValueFrom(this.codelistService.get(codelistName));
		const titlesArray = codelistData.map((item) => {
			return codelistByUrl ? this.utils.formatUserAccountName(item, 'N/A') : item?.title;
		});

		titlesArray.push('N/A');
		params.success(titlesArray);
		this.setGridFilter(params.colDef.colId);
	}

	public setGridFilter(colId: string): void {
		this.columnEmitter$.next(colId);
	}

	public getFilterUpdates$(): Observable<string> {
		return this.columnEmitter$.asObservable();
	}
}
