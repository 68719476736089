import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GaDatepickerEditorParams } from '@app/shared/ga-components/components/editors/ga-datepicker-editor.params';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IEditor } from '@app/shared/ga-components/utils/IEditor';
import * as moment from 'moment';

@Component({
	selector: 'ga-datepicker-editor',
	template: `
		<ng-container [formGroup]="formGroup">
			<input type="text"
				placeholder=""
				class="ga-datepicker"
				[ngClass]="cssClass"
				[bsConfig]="{
					adaptivePosition: true,
					containerClass: 'custom-datepicker-theme with-timepicker',
					customTodayClass: 'custom-today-class'}"
				[bsValue]="bsValue"
				[isOpen]="params.openByDefault || false"
				(bsValueChange)="onChange($event)"
				[attr.data-cy]="componentMetadata.fieldName + '-input'"
				bsDatepicker>
		</ng-container>
	`,
})
export class GaDatepickerEditorComponent implements IEditor {
	cssClass = 'ga-datepicker';
	componentMetadata: ComponentMetadata;
	params: GaDatepickerEditorParams;
	formGroup: UntypedFormGroup;
	onFocusEmitter: EventEmitter<boolean>;
	bsValue = null;

	public init(value): void {
		if ('defaultValue' in this.params) {
			if (this.params.defaultValue) {
				this.update(this.params.defaultValue);
			}
		} else {
			this.update(value);
		}
	}

	public update(value: string): void {
		if (value) {
			if (!moment(value).isSame(this.bsValue, 'day')) {
				this.bsValue = moment(value).toDate();
				this.setModelValue(value);
			}
		} else if (!this.params.allowNull) {
			const today = new Date();
			this.bsValue = today;
			value = this.formatDateToString(today);
			this.setModelValue(value);
		} else {
			this.bsValue = null;
			this.setModelValue(value);
		}
	}

	public onChange($event) {
		this.setModelValue($event ? this.formatDateToString($event) : null);
	}

	public onFocus($event) {
		if (this.onFocusEmitter) {
			this.onFocusEmitter.emit($event);
		}
	}

	public setModelValue(value: string) {
		if (value || this.params.allowNull) {
			this.formGroup
				.get(this.componentMetadata.fieldName)
				?.setValue(this.componentMetadata.valueParser ? this.componentMetadata.valueParser(value) : value);
		}
	}

	public reset() {
		this.bsValue = null;
		this.formGroup.get(this.componentMetadata.fieldName).setValue(null);
	}

	public isPristine(): boolean {
		return this.formGroup.pristine;
	}

	private formatDateToString(date: any): string {
		return moment(date).format('YYYY-MM-DD');
	}
}
