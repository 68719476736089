import { BoardTypeModel } from '@app/w-boards/models/board-type.model';

export const boardRoutesMapping: Record<BoardTypeModel, BoardRouteConfig> = {
	[BoardTypeModel.UnderwritingBoard]: {
		route: '/boards/underwriting-board',
		name: 'Underwriting Board',
		dataCy: 'underwritingBoard'
	},
	[BoardTypeModel.LawFirmManagerBoard]: {
		route: '/boards/law-firm-manager-board',
		name: 'Law Firm Manager Board',
		dataCy: 'lfmBoard'
	},
	[BoardTypeModel.MedicalSchedulingBoard]: {
		route: '/boards/medical-scheduler-board',
		name: 'Medical Scheduler',
		dataCy: 'medSchedulerBoard'
	},
	[BoardTypeModel.MriBoard]: {
		route: '/boards/mri-scheduler-board',
		name: 'MRI Scheduler Board',
		dataCy: 'mriBoard'
	},
	[BoardTypeModel.MedicalDreamBoard]: {
		route: '/boards/medical-dream-board',
		name: 'Medical Dream Board',
		dataCy: 'medicalDreamBoard'
	},
	[BoardTypeModel.KwBoard]: { route: '/boards/kw-board', name: 'KW Board', dataCy: 'kwBoard' },
	[BoardTypeModel.CsrBoard]: { route: '/boards/csr-board', name: 'CSR Board', dataCy: 'csrBoard' },
	[BoardTypeModel.QcBoard]: { route: '/boards/qc-board', name: 'QC Board', dataCy: 'qcBoard' },
	[BoardTypeModel.PaymentsBoard]: { route: '/boards/payments-board', name: 'Payments Board', dataCy: 'paymentsBoard' }
};

export interface BoardRouteConfig {
	route: string;
	name: string;
	dataCy: string;
}
