import { Identity } from '@app/core/auth/types/identity';

export class GuestUser implements Identity {
	id;
	isAuthenticated = false;
	firstName = 'guest';
	lastName = 'guest';
	role = 'none';
	roleType;
	medicalAccountExecutive = false;
	aeFilterActive = false;
	salesRepresentative = false;
	fundingConsultant = false;

	public getFullName(): string {
		return 'guest';
	}

	public isAccountExecutive(): boolean {
		return false;
	}

	public isSalesPerson(): boolean {
		return false;
	}
}
