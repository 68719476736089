import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IRenderer } from '@app/shared/ga-components/utils/IRenderer';
import { GaUtils } from '@app/shared/ga-utils';
import { isFunction } from 'lodash';

@Component({
	selector: 'ga-badge-renderer',
	template: '{{formattedValue}}'
})
export class GaBadgeRendererComponent implements IRenderer {

	componentMetadata: ComponentMetadata;
	dataObj: any;
	value: any;
	params: BadgeComponentParams;
	formattedValue: string;

	//TODO add support for custom background based on badgeColor - will be decorator

	constructor(
		public elementRef: ElementRef,
		public renderer: Renderer2,
		public utils: GaUtils) {
	}

	public redraw() {
		this.formattedValue = isFunction(this.componentMetadata.valueFormatter)
			? this.componentMetadata.valueFormatter(this.value) : this.value;

		const el = this.elementRef.nativeElement;
		this.renderer.setAttribute(el, 'data-cy', `${this.componentMetadata.fieldName}-value`);
		this.renderer.addClass(el, 'badge');
		if (isFunction(this.params.getCssClass)) {
			const cssClass = this.params.getCssClass(this.value);
			if (cssClass) {
				this.renderer.addClass(el, cssClass);
			}
		}
		if (isFunction(this.params.getBackgroundColor)) {
			const backgroundColor = this.params.getBackgroundColor(this.dataObj);
			const textColor = this.utils.determineTextColor(backgroundColor);
			this.renderer.setStyle(el, 'background-color', backgroundColor);
			this.renderer.setStyle(el, 'color', textColor);
		}
	}
}

export interface BadgeComponentParams {
	getCssClass?: (data) => string,
	getBackgroundColor?: (data) => string
}
