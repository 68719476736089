<div class="dashboard__columns">
	<div class="dashboard__columns__column">
		<current-month id="dashboard-current-month-md" *aclIsAllowed="'dashboard.current_month'"></current-month>
		<historical-performance *aclIsAllowed="'dashboard.summary'"
								id="dashboard-historical-performance-md"></historical-performance>
	</div>
	<div class="dashboard__columns__column">
		<ng-container *aclIsAllowed="'dashboard.summary'">
			<total-ever-purchased id="dashboard-total-purchased-md"></total-ever-purchased>
			<current-portfolio id="dashboard-current-portfolio-md"></current-portfolio>
		</ng-container>
	</div>
</div>
<dashboard-breakdowns id="dashboard-breakdowns"></dashboard-breakdowns>
<dashboard-charts id="dashboard-charts" class="tabs-container"></dashboard-charts>
<board-deals-grid id="dashboard-deals" *aclIsAllowed="'sales.show_sales_deals_table'"></board-deals-grid>
