import { Pipe, PipeTransform } from '@angular/core';
import { ApprovalStatus } from '@app/shared/model/constants/approval-status';

@Pipe({ name: 'approvalStatus' })
export class ApprovalStatusPipe implements PipeTransform {

	public transform(approvalStatus: number) {
		return ApprovalStatus[approvalStatus] ?? '';
	}
}
