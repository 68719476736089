import { formatCurrency, formatNumber } from '@angular/common';

export const chartAxesFormatterTheme = (formatter: (value) => string, imageName: string) => ({
	common: {
		legend: {
			position: 'bottom'
		}
	},
	cartesian: {
		title: {
			enabled: false,
			text: imageName
		},
		axes: {
			// eslint-disable-next-line id-denylist
			number: {
				label: {
					formatter: axisParams => {
						return formatter(axisParams.value);
					}
				}
			}
		}
	},
});

export const chartNumberAxesTheme = imageName => chartAxesFormatterTheme(value => formatNumber(value, 'en-US', '1.0-2'), imageName);

export const chartPriceAxesTheme = imageName => chartAxesFormatterTheme(value => formatCurrency(value, 'en-US', '$', '1.2-2'), imageName);
