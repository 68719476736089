import { Component, Input } from '@angular/core';
import { RpDetailModalComponent } from '@app/dashboard/current-portfolio/current-portfolio-outstanding-table/rp-detail/rp-detail-modal.component';
import { OutstandingGrouped } from '@app/dashboard/current-portfolio/current-portfolio.model';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
	selector: 'current-portfolio-outstanding-table',
	templateUrl: 'current-portfolio-outstanding-table.component.html'
})
export class CurrentPortfolioOutstandingTableComponent {
	@Input() groups: OutstandingGrouped[];
	@Input() roundValue: boolean;

	constructor(private modalService: BsModalService) {
	}


	public openDetail(fundingIds: number[]): void {
		this.modalService.show(RpDetailModalComponent, {
			initialState: { fundingIds },
			class: 'modal-xl centered-modal-xl'
		});
	}
}
