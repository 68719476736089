export const LawFirmManagerColumnIds = {
	Preset: {
		Approved: 'approved',
		Rejected: 'rejected',
		WaitingOnDocs: 'waiting_on_docs',
		ReviewedNeedsMoreDocs: 'reviewed_needs_more_docs',
		DocsOut: 'docs_out',
		WaitingOnAttorney: 'waiting_on_attorney',
		SendNotice: 'send_notice'
	},
	Medical: {
		Rejected: 'rejected',
		WaitingOnDocs: 'waiting_on_docs',
		PreOp: 'lfm_preop',
		ReadyForReview: 'lfm_ready_for_review',
		ReviewedNeedsMoreDocs: 'reviewed_needs_more_docs',
		Approved: 'approved_lop',
		SendNotice: 'send_notice'
	},
	MedicalScheduling: {
		ConsultationPending: 'law_firm_manager_consultation_pending',
		AppointmentConfirmed: 'appointment_confirmed',
		ConfirmingRecommendation: 'confirming_recommendation'
	},
	Mri: {
		ConsultationPending: 'law_firm_manager_consultation_pending',
		AppointmentConfirmed: 'appointment_confirmed',
		WaitingOnBilling: 'waiting_on_billing',
		SendNotice: 'send_notice'
	},
	CourtReporting: {
		ApprovedLOP: 'approved_lop',
		Rejected: 'rejected',
		WaitingOnDocs: 'waiting_on_docs',
		ReviewedNeedsMoreDocs: 'reviewed_needs_more_docs',
		SendNotice: 'send_notice'
	}
}

export const LawFirmManagerColumnNames = {
	Preset: {
		[LawFirmManagerColumnIds.Preset.Approved]: 'Approved',
		[LawFirmManagerColumnIds.Preset.Rejected]: 'Rejected',
		[LawFirmManagerColumnIds.Preset.WaitingOnDocs]: 'Waiting On Docs',
		[LawFirmManagerColumnIds.Preset.ReviewedNeedsMoreDocs]: 'Reviewed Needs More Docs',
		[LawFirmManagerColumnIds.Preset.DocsOut]: 'Docs Out',
		[LawFirmManagerColumnIds.Preset.WaitingOnAttorney]: 'Waiting on Attorney',
		[LawFirmManagerColumnIds.Preset.SendNotice]: 'Send Notice',
	},
	Medical: {
		[LawFirmManagerColumnIds.Medical.Rejected]: 'Rejected',
		[LawFirmManagerColumnIds.Medical.WaitingOnDocs]: 'Waiting On Docs',
		[LawFirmManagerColumnIds.Medical.PreOp]: 'Pre Op',
		[LawFirmManagerColumnIds.Medical.ReadyForReview]: 'Ready For Review',
		[LawFirmManagerColumnIds.Medical.ReviewedNeedsMoreDocs]: 'Reviewed Needs More Docs',
		[LawFirmManagerColumnIds.Medical.Approved]: 'Approved LOP',
		[LawFirmManagerColumnIds.Medical.SendNotice]: 'Send Notice'
	},
	MedicalScheduling: {
		[LawFirmManagerColumnIds.MedicalScheduling.ConsultationPending]: 'Consultation Pending',
		[LawFirmManagerColumnIds.MedicalScheduling.AppointmentConfirmed]: 'Appointment Confirmed',
		[LawFirmManagerColumnIds.MedicalScheduling.ConfirmingRecommendation]: 'Confirming Recommendation'
	},
	Mri: {
		[LawFirmManagerColumnIds.Mri.ConsultationPending]: 'Consultation Pending',
		[LawFirmManagerColumnIds.Mri.AppointmentConfirmed]: 'Appointment Confirmed',
		[LawFirmManagerColumnIds.Mri.WaitingOnBilling]: 'Report Received - Waiting on Billing',
		[LawFirmManagerColumnIds.Mri.SendNotice]: 'Send Notice'
	},
	CourtReporting: {
		[LawFirmManagerColumnIds.CourtReporting.ApprovedLOP]: 'Approved LOP',
		[LawFirmManagerColumnIds.CourtReporting.Rejected]: 'Rejected',
		[LawFirmManagerColumnIds.CourtReporting.WaitingOnDocs]: 'Waiting On Docs',
		[LawFirmManagerColumnIds.CourtReporting.ReviewedNeedsMoreDocs]: 'Reviewed Needs More Docs',
		[LawFirmManagerColumnIds.CourtReporting.SendNotice]: 'Send Notice'
	}
}
