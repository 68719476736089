import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Subject, fromEvent, takeUntil, map } from 'rxjs';
import { fadeUpDown } from '../animations/animations';
import { ScrollToTopService } from './scroll-to-top.service';

@Component({
	selector: 'app-scroll-to-top',
	template: `
			<button class="base-button base-button--round" *ngIf="showButton$ | async"
					(click)="triggerScroll()" @fadeUpDown>
				<fa-icon [icon]="faIcons.upIcon"></fa-icon>
			</button>`,
	styleUrls: ['./scroll-to-top.component.scss'],
	animations: [fadeUpDown]
})
export class ScrollToTopComponent implements OnInit, OnDestroy {
	@Input() container: Element;
	@Input() innerScroll = false;

	readonly faIcons = {
		upIcon: faArrowUp
	};

	showButton$ = this.scrollService.showButton$.pipe(
		map((show: boolean) => !this.innerScroll && show)
	);

	private destroy$ = new Subject<void>();

	constructor(public scrollService: ScrollToTopService) { }

	public ngOnInit(): void {
		fromEvent(this.container, 'scroll').pipe(takeUntil(this.destroy$))
			.subscribe(() => this.scrollService.toggleButtonVisibility(this.container, this.innerScroll));
		this.scrollService.triggerScrollToTop$.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.scrollToTop());
	}

	public triggerScroll() {
		this.scrollService.triggerScrollToTop$.next();
	}

	private scrollToTop() {
		this.container.scroll({ top: 0, behavior: 'smooth' });
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
