import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FileUploader, FileUploaderOptions } from '@app/widgets/file-upload/model/file-uploader.model';

@Directive({ selector: '[fileSelect]' })
export class FileSelectDirective {
	@Input() uploader?: FileUploader;
	@Output() onFileSelected: EventEmitter<File[]> = new EventEmitter<File[]>();

	protected element: ElementRef;

	constructor(element: ElementRef) {
		this.element = element;
	}

	public getOptions(): FileUploaderOptions | undefined {
		return this.uploader?.options;
	}

	public getFilters(): string {
		return '';
	}

	public isEmptyAfterSelection(): boolean {
		return !!this.element.nativeElement.attributes.multiple;
	}

	@HostListener('change')
	public onChange(): void {
		const files = this.element.nativeElement.files;
		const options = this.getOptions();
		const filters = this.getFilters();
		this.uploader?.addToQueue(files, options, filters);

		this.onFileSelected.emit(files);
		if (this.isEmptyAfterSelection()) {
			this.element.nativeElement.value = '';
		}
	}
}
