import { Component, Input, OnInit, Optional } from '@angular/core';
import { GhostElementService } from '@app/widgets/ghost-element/ghost-element.service';
import { EMPTY, merge, Observable, ReplaySubject } from 'rxjs';

@Component({
	selector: 'ghost-element',
	styleUrls: ['ghost-element.component.scss'],
	template: `
		<div class="ghost-wrapper">
			<ng-container *ngIf="showData$ | async; else ghost">
				<ng-content></ng-content>
			</ng-container>
			<ng-template #ghost>
				<div class="loading" [ngStyle]="{ width: width+'rem', height: height+'rem' }"></div>
			</ng-template>
		</div>
	`,
})
export class GhostElementComponent implements OnInit {
	@Input() set showData(show: boolean) {
		this.showDataEmitter.next(show);
	}
	@Input() width: number;
	@Input() height = 1;

	showData$: Observable<boolean>;

	private showDataEmitter = new ReplaySubject<boolean>(1);

	constructor(
		@Optional() private parentGhostService: GhostElementService) {
	}

	public ngOnInit(): void {
		this.showData$ = merge(this.parentGhostService ? this.parentGhostService.show$ : EMPTY, this.showDataEmitter.asObservable());
	}
}
