import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'interactionRating' })
export class InteractionRatingPipe implements PipeTransform {

	public transform(interactionRatingTitle: string, getOnlyEmoji?: boolean): string {
		if (!interactionRatingTitle) {
			return '';
		}
		const splitValue = interactionRatingTitle.split(' ');
		if (getOnlyEmoji) {
			return splitValue[0];
		} else {
			return `${splitValue[1]} ${splitValue[2]}`;
		}
	}
}
