import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoardDealsGridComponent } from '@app/dashboard/board-deals-grid/board-deals-grid.component';
import { BoardListRendererComponent } from '@app/dashboard/board-deals-grid/board-list-renderer.component';
import { CollapsibleItemComponent } from '@app/dashboard/collapsible-item/collapsible-item.component';
import { CurrentMonthComponent } from '@app/dashboard/current-month/current-month.component';
import { CurrentPortfolioModule } from '@app/dashboard/current-portfolio/current-portfolio.module';
import { DashboardBreakdownsComponent } from '@app/dashboard/dashboard-breakdowns/dashboard-breakdowns.component';
import { DashboardChartsComponent } from '@app/dashboard/dashboard-charts/dashboard-charts.component';
import { DashboardDataUtilsService } from '@app/dashboard/dashboard-data-utils.service';
import { DashboardFilterComponent } from '@app/dashboard/dashboard-filter/dashboard-filter.component';
import { DashboardRoutingModule } from '@app/dashboard/dashboard-routing.module';
import { DashboardComponent } from '@app/dashboard/dashboard.component';
import { DefaultScreenComponent } from '@app/dashboard/default-screen/default-screen.component';
import { FundingConsultantsBreakdownComponent } from '@app/dashboard/funding-consultants-breakdown/funding-consultants-breakdown.component';
import { GroupedFundingsListComponent } from '@app/dashboard/grouped-fundings-list/grouped-fundings-list.component';
import { HistoricalPerformanceModule } from '@app/dashboard/historical-performance/historical-performance.module';
import { DashboardPortfoliosComponent } from '@app/dashboard/large-screen/dashboard-portfolios/dashboard-portfolios.component';
import { MedicalPurchasesTableComponent } from '@app/dashboard/medical-purchases-table/medical-purchases-table.component';
import { MediumScreenComponent } from '@app/dashboard/medium-screen/medium-screen.component';
import { PortfolioFollowUpHistoryComponent } from '@app/dashboard/portfolio-follow-up-history/portfolio-follow-up-history.component';
import { SalesActivityWidgetModalComponent } from '@app/dashboard/sales-activity-widget/sales-activity-widget-modal.component';
import { SalesActivityWidgetComponent } from '@app/dashboard/sales-activity-widget/sales-activity-widget.component';
import { SalesReportComponent } from '@app/dashboard/sales-report/sales-report.component';
import { SalesRepresentativesBreakdownComponent } from '@app/dashboard/sales-representatives-breakdown/sales-representatives-breakdown.component';
import { DashboardBreakdownChartComponent } from '@app/dashboard/shared/breakdown-chart/dashboard-breakdown-chart.component';
import { DashboardWidgetDateFilterModule } from '@app/dashboard/shared/dashboard-widget-date-filter/dashboard-widget-date-filter.module';
import { OpenModalRendererComponent } from '@app/dashboard/shared/open-modal-renderer.component';
import { TimeRelatedConfigurationComponent } from '@app/dashboard/shared/time-related-configuration/time-related-configuration.component';
import { SalesSummaryStatisticsComponent } from '@app/dashboard/summary-statistics/sales-summary-statistics/sales-summary-statistics.component';
import { SettledCollectedTableComponent } from '@app/dashboard/summary-statistics/settled-collected-table/settled-collected-table.component';
import { SummaryStatisticsComponent } from '@app/dashboard/summary-statistics/summary-statistics.component';
import { TotalFundedTableComponent } from '@app/dashboard/summary-statistics/total-funded-table/total-funded-table.component';
import { TopFiveSectionModule } from '@app/dashboard/top-five-section/top-five-section.module';
import { TotalEverPurchasedComponent } from '@app/dashboard/total-ever-purchased/total-ever-purchased.component';
import { SalesActivityReportGridModule } from '@app/reports/sales-activity-report/sales-activity-report-grid/sales-activity-report-grid.module';
import { CodelistSelectsModule } from '@app/shared/codelist-selects/codelist-selects.module';
import { ModalsModule } from '@app/shared/modals/modals.module';
import { defaultVintageDetailConfigProvider } from '@app/shared/modals/vintage-detail/vintage-detail-config';
import { RpChartsModule } from '@app/shared/rp-charts.module';
import { SharedModule } from '@app/shared/shared.module';
import { SVGIconComponent } from '@app/shared/svg-icon/svg-icon.component';
import { BoardHelperModule } from '@app/w-boards/services/board-helper.module';
import { BoardLocalStorageService } from '@app/w-boards/services/board-local-storage.service';
import { GhostElementModule } from '@app/widgets/ghost-element/ghost-element.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LetModule } from '@ngrx/component';
import { AgGridModule } from 'ag-grid-angular';
import { NgChartsModule } from 'ng2-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LargeScreenComponent } from './large-screen/large-screen.component';
import { SmallScreenComponent } from './small-screen/small-screen.component';

@NgModule({
	declarations: [
		DashboardComponent,
		DefaultScreenComponent,
		MediumScreenComponent,
		SmallScreenComponent,
		CurrentMonthComponent,
		DashboardChartsComponent,
		DashboardBreakdownsComponent,
		TotalEverPurchasedComponent,
		SalesRepresentativesBreakdownComponent,
		FundingConsultantsBreakdownComponent,
		SalesReportComponent,
		GroupedFundingsListComponent,
		SalesActivityWidgetComponent,
		SalesActivityWidgetModalComponent,
		BoardDealsGridComponent,
		BoardListRendererComponent,
		OpenModalRendererComponent,
		PortfolioFollowUpHistoryComponent,
		SummaryStatisticsComponent,
		DashboardBreakdownChartComponent,
		DashboardFilterComponent,
		LargeScreenComponent,
		DashboardPortfoliosComponent,
		SalesSummaryStatisticsComponent,
		MedicalPurchasesTableComponent,
	],
	imports: [
		SharedModule,
		CommonModule,
		NgChartsModule,
		FormsModule,
		ReactiveFormsModule,
		RpChartsModule,
		ModalsModule,
		TooltipModule,
		TabsModule,
		BsDatepickerModule,
		ModalModule.forChild(),
		SalesActivityReportGridModule,
		AgGridModule,
		BoardHelperModule,
		DashboardRoutingModule,
		LetModule,
		TopFiveSectionModule,
		HistoricalPerformanceModule,
		CurrentPortfolioModule,
		GhostElementModule,
		DashboardWidgetDateFilterModule,
		CodelistSelectsModule,
		NgSelectModule,
		SVGIconComponent,
		SettledCollectedTableComponent,
		TimeRelatedConfigurationComponent,
		TotalFundedTableComponent,
		CollapsibleItemComponent,
	],
	providers: [
		DashboardDataUtilsService,
		BoardLocalStorageService,
		DecimalPipe,
		defaultVintageDetailConfigProvider
	]
})
export class DashboardModule {
}
