<div class="dash-panel ibox">
	<collapsible-item>
		<h4 header-left>Dashboard Charts</h4>
		<tabset class="flex-col" active="active">
			<tab heading="Summary Statistics" *aclIsAllowed="'dashboard.summary_statistics'">
				<div class="ibox">
					<div class="ibox-content">
						<summary-statistics
							*aclIsAllowed="'dashboard.summary_statistics'; permission: permissionTypes.All"></summary-statistics>
						<ng-container *ngIf="!isSuperAdmin">
							<sales-summary-statistics
								*aclIsAllowed="'dashboard.summary_statistics'; permission: permissionTypes.Own"></sales-summary-statistics>
						</ng-container>
					</div>
				</div>
			</tab>
			<tab heading="New Business Reps Breakdown"
				 *aclIsAllowed="'dashboard.law_firm_managers_breakdown'"
				 #salesBreakdownTab="tab">
				<div class="ibox o-hidden">
					<div class="ibox-content">
						<sales-representatives-breakdown
							*ngIf="salesBreakdownTab.active">
						</sales-representatives-breakdown>
					</div>
				</div>
			</tab>
			<tab heading="Account Growth Reps Breakdown"
				 *aclIsAllowedAll="['dashboard.funding_consultants_breakdown', 'dashboard.collected_stats']"
				 #fundingConsultantsTab="tab">
				<div class="ibox o-hidden">
					<div class="ibox-content">
						<funding-consultants-breakdown
							*ngIf="fundingConsultantsTab.active">
						</funding-consultants-breakdown>
					</div>
				</div>
			</tab>
			<tab heading="Underwriting Auditing"
				 *aclIsAllowed="'reports.underwriting'"
				 #underwritingAuditingTab="tab">
				<div class="ibox">
					<div class="ibox-content">
						<grouped-progress-bar-chart *ngIf="underwritingAuditingTab.active"
													[rawData]="underwritingAudit$ | async"
													mode="weekly"
													property="funding_count">
						</grouped-progress-bar-chart>
					</div>
				</div>
			</tab>
			<tab heading="Sales Activity"
				 *aclIsAllowed="'reports.sales_activity_report'"
				 (selectTab)="element.fetchSalesActivities()">
				<div class="ibox">
					<div class="ibox-content">
						<sales-activity-widget #element></sales-activity-widget>
					</div>
				</div>
			</tab>
			<tab heading="Sales Report"
				 *aclIsAllowed="'reports.sales'"
				 #salesReportTab="tab">
				<div class="ibox indicator-report">
					<div class="ibox-content">
						<sales-report *ngIf="salesReportTab.active">Loading...</sales-report>
					</div>
				</div>
			</tab>
			<tab heading="Portfolio Servicing History"
				 *aclIsAllowed="'reports.portfolio_follow_up_history'"
				 (selectTab)="portfolioFollowUpHistory.init()">
				<div class="ibox">
					<div class="ibox-content">
						<portfolio-follow-up-history #portfolioFollowUpHistory></portfolio-follow-up-history>
					</div>
				</div>
			</tab>
		</tabset>
	</collapsible-item>
</div>
