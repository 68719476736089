import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IdentityProviderService } from '@app/core/auth/identity-provider.service';
import { PublicViewEventBusService } from '@app/public/public-view.service';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'public-view',
	templateUrl: './public-view.component.html',
})
export class PublicViewComponent implements OnInit, OnDestroy {

	@Input() title = '';
	@Input() subtitle = '';

	loading = false;
	currentYear: number = (new Date()).getFullYear();
	readonly svgIconNames = SvgIconName;
	private destroy$ = new Subject();

	constructor(public publicEventBus: PublicViewEventBusService,
				public identityProvider: IdentityProviderService) {}

	public ngOnInit(): void {
		this.publicEventBus.listenerLoadingSpinner().pipe(
			takeUntil(this.destroy$)
		).subscribe((value) => this.loading = value);
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
