import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { fromEvent, map, Observable, of, startWith } from 'rxjs';
import { EVENTS as UnleashEvents, UnleashClient } from 'unleash-proxy-client';

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService {
	private readonly unleash: UnleashClient | null = environment.unleash ? new UnleashClient({
		url: environment.unleash.proxyUrl,
		clientKey: environment.unleash.clientKey,
		appName: environment.unleash.appName,
		environment: environment.unleash.appName,
		refreshInterval: environment.unleash.refreshInterval
	}) : null;

	public start(): void {
		this.unleash?.start();
	}

	public stop(): void {
		this.unleash?.stop();
	}

	public setUser(userId: string): void {
		this.unleash?.setContextField('userId', userId);
	}

	public getFeatureAvailabilityUpdates(featureName: string): Observable<boolean> {
		if (!this.unleash) {
			return of(true);
		}
		return fromEvent(this.unleash, UnleashEvents.UPDATE)
			.pipe(
				map(() => this.isEnabled(featureName)),
				startWith(this.isEnabled(featureName))
			);
	}

	public isEnabled(feature: string): boolean {
		if (!this.unleash) {
			return true;
		}
		return this.unleash.isEnabled(feature);
	}
}
