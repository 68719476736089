import { HttpClient } from '@angular/common/http';
import { Component, HostBinding } from '@angular/core';
import { DateRangeGroupedProgressChartComponent } from '@app/reports/shared/date-range-grouped-progress-chart.component';
import { fadeInOut } from '@app/shared/animations/animations';
import { DatePickerRangesService } from '@app/shared/daterange-picker/date-picker-ranges/date-picker-ranges.service';
import { toNumber } from '@app/shared/general-helper';
import { InitGraph } from '@app/shared/model/types/init-graph';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ChartOptions } from 'chart.js';

interface PortfolioFollowUpHistoryItem {
	allServicedApplicants: number;
	notServicedLast90Days: number;
	notServicedLast90DaysPerc: number;
	notServicedLast120Days: number;
	notServicedLast120DaysPerc: number;
	day: string;
	week: string;
	month: string;
	year: string;
}

@Component({
	selector: 'portfolio-follow-up-history',
	templateUrl: './portfolio-follow-up-history.component.html',
	animations: [
		fadeInOut
	]
})
export class PortfolioFollowUpHistoryComponent extends DateRangeGroupedProgressChartComponent implements InitGraph {
	@HostBinding('class.view') hostClass = true;

	dots90days: number[] = [];
	dots120days: number[] = [];
	chartOptions: ChartOptions<'line'> = {
		datasets: {
			line: {
				fill: 'origin',
				tension: 0.25
			}
		},
		plugins: {
			legend: {
				display: true,
				position: 'bottom'
			},
			tooltip: {
				mode: 'point',
			},
		},
		scales: {
			y: {
				ticks: {
					callback: value => {
						return value + '%';
					}
				}
			},
			x: {
				ticks: {
					autoSkip: false,
				}
			}
		}
	};

	constructor(public http: HttpClient,
				dateRangesService: DatePickerRangesService) {
		super(dateRangesService);
	}

	public init(): void {
		this.rangesOverride = {
			[DatePickerRangesService.LAST_7_DAYS]: { start: moment().subtract(7, 'days'), end: moment().subtract(1, 'days') },
			[DatePickerRangesService.LAST_30_DAYS]: { start: moment().subtract(30, 'days'), end: moment().subtract(1, 'days') }
		};
		this.ranges$.next(this.getRanges(this.chartMode));
	}

	public recalculateStatsForDate([from, to]): void {
		this.areDataReady = false;
		from = from ? moment(from) : moment().subtract(30, 'days');
		to = to ? moment(to) : moment();
		this.http.get('/api/statistics/portfolio-follow-up-history', {
			params: {
				'from': from.format('Y-MM-DD'),
				'to': to.format('Y-MM-DD')
			}
		}).subscribe({
			next:(data: PortfolioFollowUpHistoryItem[]) => {
				this.prepareData(data, from, to);
				this.chartDataSets = [
					{ data: this.dots90days, label: '% of cases not serviced in last 3 month' },
					{ data: this.dots120days, label: '% of cases not serviced in last 4 month' },
				];
				this.areDataReady = true;
			},
			error: (errorResponse: unknown) => {
				console.error(errorResponse);
			}
		});
	}

	public prepareDailyData(data: PortfolioFollowUpHistoryItem[], daysRange): void {
		this.clearData();
		_.each(daysRange, (item) => {
			this.chartLabels.push(item.day);
			const dayData = data.find(el => el.day === item.day);
			const dot90 = dayData ? toNumber(dayData.notServicedLast90DaysPerc) : 0;
			const dot120 = dayData ? toNumber(dayData.notServicedLast120DaysPerc) : 0;
			this.dots90days.push(dot90);
			this.dots120days.push(dot120);
		});
	}

	public prepareWeeklyData(data, weeksRange): void {
		this.clearData();
		const dataByWeekPeriods = _.groupBy(data, 'week');
		_.each(weeksRange, week => {
			this.chartLabels.push(`${week.start} - ${week.end}`);
			const weekPeriod = _.get(dataByWeekPeriods, week.weekId, null);
			let dot90 = 0;
			let dot120 = 0;
			if (weekPeriod) {
				dot90 = _.meanBy(weekPeriod, (item: PortfolioFollowUpHistoryItem) => toNumber(item.notServicedLast90DaysPerc));
				dot120 = _.meanBy(weekPeriod, (item: PortfolioFollowUpHistoryItem) => toNumber(item.notServicedLast120DaysPerc));
			}
			this.dots90days.push(dot90);
			this.dots120days.push(dot120);
		});
	}

	public prepareMonthlyData(data, monthsRange): void {
		this.clearData();
		const dataByMonthPeriods = _.groupBy(data, 'month');
		_.each(monthsRange, month => {
			this.chartLabels.push(month.start.format('MMMM YYYY'));
			const monthPeriod = _.get(dataByMonthPeriods, month.monthId, null);
			let dot90 = 0;
			let dot120 = 0;
			if (monthPeriod) {
				dot90 = _.meanBy(monthPeriod, (item: PortfolioFollowUpHistoryItem) => toNumber(item.notServicedLast90DaysPerc));
				dot120 = _.meanBy(monthPeriod, (item: PortfolioFollowUpHistoryItem) => toNumber(item.notServicedLast120DaysPerc));
			}
			this.dots90days.push(dot90);
			this.dots120days.push(dot120);
		});
	}

	private clearData(): void {
		this.dots90days = [];
		this.dots120days = [];
		this.chartLabels = [];
	}
}
