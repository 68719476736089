import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AddressMarker } from '@app/applicant/applicant-detail/applicant-detail-main-section/applicant-detail-main-section-applicant/applicant-detail-main-section-applicant-map/model/address-marker';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { BooleanFilterVisitor } from '@app/shared/filter-visitors/boolean-filter-visitor';
import { IdFilterVisitor } from '@app/shared/filter-visitors/id-filter-visitor';
import { FilterVisitor } from '@app/shared/filter-visitors/models/filter-visitor';
import { PricingFilterVisitor } from '@app/shared/filter-visitors/pricing-filter-visitor';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { Doctor } from '@app/shared/model/types/doctor';
import { PlacesAutocompleteAddress } from '@app/shared/model/types/places-autocomplete-address';
import { DoctorAddressMultiselectVisitor } from '@app/widgets/medical-markers-map/filter-visitor/doctor-address-multiselect-visitor';
import { DoctorAddressPropertyVisitor } from '@app/widgets/medical-markers-map/filter-visitor/doctor-address-property-visitor';
import { MedicalMarkersMapFilterService } from '@app/widgets/medical-markers-map/service/medical-markers-map-filter.service';
import { MedicalMarkersMapHelperService } from '@app/widgets/medical-markers-map/service/medical-markers-map-helper.service';
import { combineLatest, map, Observable, startWith, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'medical-markers-map-doctor-filter',
	templateUrl: './medical-markers-map-doctor-filter.component.html',
	providers: [MedicalMarkersMapFilterService]
})
export class MedicalMarkersMapDoctorFilterComponent implements OnInit, OnDestroy {
	@Output() onDoctorMarkersCreated = new EventEmitter<AddressMarker[]>();
	formControls: { [key: string]: FormControl<any> } = {};
	readonly othersOption = [{ id: '-1', title: 'Others' }];

	readonly doctorTier = NonUrlCodelistName.DoctorAndMedicalFacilityTier;
	readonly doctorLocationTier = NonUrlCodelistName.DoctorLocationTier;
	private readonly doctorSpecializationIcon = 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
	private destroy$ = new Subject<void>();

	constructor(
		private doctorFilterService: MedicalMarkersMapFilterService<Doctor>,
		private medicalMarkersMapHelper: MedicalMarkersMapHelperService,
		private codelistRepository: CodelistRepositoryService,) {
	}

	public ngOnInit(): void {
		const filters: Record<string, FilterVisitor> = {
			specialization: new IdFilterVisitor<Doctor>('specialization'),
			active: new BooleanFilterVisitor<Doctor>('active'),
			pursuing: new BooleanFilterVisitor<Doctor>('pursuing'),
			preferredDoctor: new BooleanFilterVisitor<Doctor>('preferredDoctor'),
			sellingTechnicalLiens: new BooleanFilterVisitor<Doctor>('sellingTechnicalLiens'),
			spanish: new BooleanFilterVisitor<Doctor>('spanish'),
			telemed: new BooleanFilterVisitor<Doctor>('telemed'),
			quarterback: new IdFilterVisitor<Doctor>('quarterback'),
			rpCost: new PricingFilterVisitor<Doctor>('rpCost'),
			city: new DoctorAddressPropertyVisitor('city'),
			zip: new DoctorAddressPropertyVisitor('zip'),
			tier: new IdFilterVisitor<Doctor>('tier'),
			doctorLocationTier: new DoctorAddressMultiselectVisitor('doctorLocationTier')
		};

		Object.entries(filters).forEach(([key, filter]) => {
			this.formControls[key] = filter.getFormControl();
		});

		this.doctorFilterService.addFilters(Object.values(filters));

		const doctors$ = this.codelistRepository.getByUrl<Doctor[]>(UrlCodelistLink.Doctors)
			.pipe(this.doctorFilterService.applyFilter());

		const filteredDoctors$ = this.includeLocationFilter(doctors$);

		filteredDoctors$.pipe(
			map((filteredDoctors) => filteredDoctors.flatMap((doc) => this.createDoctorMarkers(doc))),
			startWith([]),
			takeUntil(this.destroy$)
		).subscribe((markers) => {
			this.onDoctorMarkersCreated.next(markers);
		});
	}

	public onRpCostChanged(rpCost: string) {
		this.formControls.rpCost.setValue(+rpCost);
	}

	private includeLocationFilter(doctors$: Observable<Doctor[]>): Observable<Doctor[]> {
		return combineLatest([doctors$,
			this.formControls.city.valueChanges.pipe(startWith('')),
			this.formControls.zip.valueChanges.pipe(startWith('')),
			this.formControls.doctorLocationTier.valueChanges.pipe(startWith([]))
				]).pipe(map(([doctors, city, zip, locationTiers]) => {
					return doctors.map((doctor) => {
						const addresses = doctor.addresses.filter((address) => {
								const cityCondition = city ? city.toLowerCase() == address.city?.toLowerCase() : true;
								const zipCondition = zip ? zip.toLowerCase() == address.zip?.toLowerCase() : true;
								const locationTierCondition = locationTiers.length > 0 ?
									locationTiers.some(locationTier => locationTier === address.doctorLocationTier.id) : true;
								return cityCondition && zipCondition && locationTierCondition;
							}
						);
						return { ...doctor, addresses };
					}).filter((docs) => docs.addresses.length > 0);
				}));
	}

	private createDoctorMarkers(doctor: Doctor): AddressMarker[] {
		return doctor.addresses
			.filter((address) => address.active && address.addressLat && address.addressLng)
			.map((address) => this.medicalMarkersMapHelper.createMarker(
					doctor.name ?? '',
					{
						icon: doctor.specialization?.mapMarker ?? this.doctorSpecializationIcon,
						cursor: 'pointer'
					},
					address
				)
			);
	}

	public setLocation(autoCompleteAddress: PlacesAutocompleteAddress): void {
		this.formControls.city.setValue(autoCompleteAddress.city);
		this.formControls.zip.setValue(autoCompleteAddress.zip);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
