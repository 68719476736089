import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RpCostEditorComponent } from '@app/widgets/rp-cost-editor/rp-cost-editor.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RatingModule } from 'ngx-bootstrap/rating';

@NgModule({
	imports: [
		CommonModule,
		FontAwesomeModule,
		RatingModule,
		ReactiveFormsModule
	],
	exports: [
		RpCostEditorComponent
	],
	declarations: [
		RpCostEditorComponent
	],
})
export class RpCostEditorModule {}
