import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { AclService } from '@app/core/security/acl.service';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { currencyColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/currency-column-definition';
import { dateTimeColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/date-column-definition';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { StatisticsRepositoryService } from '@app/shared/model/statistics-repository.service';
import { EntityId } from '@app/shared/model/types/entity-id';
import { ApprovalStatusPipe } from '@app/shared/pipes/approval-status.pipe';
import { GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment/moment';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'grouped-fundings-list',
	templateUrl: './grouped-fundings-list.component.html',
	styleUrls: ['./grouped-fundings-list.component.scss'],
	providers: [ApprovalStatusPipe],
})
export class GroupedFundingsListComponent implements OnInit, OnDestroy {

	@Input() attorneyIds: EntityId[];
	@Input() startDate: Date;
	@Input() endDate: Date;
	@Input() externalLeadSources: EntityId[];
	@Input() fundingTypes: EntityId[];

	attorneys$: Observable<unknown>;
	columnDefinitions = [
		{
			headerName: 'Attorney',
			valueGetter: ({ data }) => `${data.firstName} ${data.lastName}`,
			cellRenderer: 'agGroupCellRenderer',
			cellRendererParams: {
				innerRenderer: ({ data, value }) => this.agGridUtils.attorneyLinkRenderer(
					data.lawFirm.id,
					data.id,
					value
				),
				suppressDoubleClickExpand: true
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				values: (params) => {
					this.codelistService.getByUrl(UrlCodelistLink.Attorneys)
						.pipe(takeUntil(this.destroy$))
						.subscribe(attorneys => {
							params.success(this.agUtils.getArrayOfStringsByFiledName(attorneys, ['firstName', 'lastName']));
						});
				},
				buttons: ['clear', 'reset'],
			},
		},
		{
			field: 'lawFirm.name',
			headerName: 'Law Firm',
			cellRenderer: ({ data, value }) =>
				data ? this.agGridUtils.lawFirmLinkRenderer(data?.lawFirm?.id, value) : value,
			filter: 'agSetColumnFilter',
			filterParams: {
				values: (params) => {
					this.codelistService.getByUrl(UrlCodelistLink.LawFirms)
						.pipe(takeUntil(this.destroy$))
						.subscribe(value => params.success(this.agUtils.getArrayOfStringsByFiledName(value, 'name')));
				},
				buttons: ['clear', 'reset'],
			},
			minWidth: 200
		}
	];
	detailCellRendererParams;
	gridOptions;
	private destroy$ = new Subject<void>();

	constructor(
		private apiLink: GaApiLink,
		private statisticsRepository: StatisticsRepositoryService,
		private agGridUtils: AgGridUtilsService,
		private acl: AclService,
		private agUtils: AgGridUtilsService,
		private codelistService: CodelistRepositoryService,
		private approvalStatusPipe: ApprovalStatusPipe) {
	}

	public ngOnInit(): void {
		this.detailCellRendererParams = {
			detailGridOptions: {
				suppressCsvExport: true,
				excelStyles: AgGridUtilsService.EXCEL_STYLES,
				defaultColDef: {
					floatingFilter: true,
				},
				columnDefs: [
					{
						field: 'internalId',
						cellRenderer: ({ data, value }) =>
							data?.applicant ? this.agUtils.fundingLinkRenderer(value, data?.id, data?.applicant?.id) : value,
						filter: 'agTextColumnFilter'
					},
					{
						headerName: 'Purchase Price',
						sortable: true,
						...currencyColumnDefinition('purchasePrice')
					},
					{
						field: 'approvalStatus',
						sortable: true,
						filter: 'agTextColumnFilter',
						valueGetter: params => this.approvalStatusPipe.transform(params.data.approvalStatus)
					},
					{
						headerName: 'Current Amount Owed',
						sortable: true,
						...currencyColumnDefinition('currentAmountOwed'),
					},
					{
						headerName: 'Funded On',
						sortable: true,
						...dateTimeColumnDefinition('fundedOn')
					},
					{
						field: 'applicant',
						valueGetter: ({ data }) => `${data.applicant.firstName ?? ''} ${data.applicant.lastName ?? ''}`,
						sortable: true,
						filter: 'agTextColumnFilter',
						cellRenderer: ({ data, value }) =>
							data ? this.agGridUtils.applicantLinkRenderer(data.applicant.id, value) : value
					}
				],
				onFirstDataRendered: params => {
					params.api.sizeColumnsToFit();
				}
			},
			getDetailRowData: function (params) {
				params.successCallback(params.data.fundings);
			}
		};
		this.gridOptions = this.agGridUtils.getGridOptions({
			suppressExcelExport: !this.acl.isAllowed('export.dashboard_sales_report'),
			defaultColDef: {
				filter: 'agTextColumnFilter'
			},
			sideBar: false,
			masterDetail: true
		});

		const params = {
			['attorneyIds[]']: this.attorneyIds,
			startDate: moment(this.startDate).format('YYYY-MM-DD'),
			endDate: moment(this.endDate).format('YYYY-MM-DD'),
			['externalLeadSource[]']: this.externalLeadSources,
		}
		if (this.fundingTypes) {
			params['fundingType[]'] = this.fundingTypes;
		}

		this.attorneys$ = this.statisticsRepository.getAttorneysList(params);
	}

	public onGridReady(params: GridReadyEvent): void {
		params.api.sizeColumnsToFit();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
