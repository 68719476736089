import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Sms } from '@app/shared/model/types/sms';

@Pipe({name: 'smsConversationDate'})
export class SmsConversationDatePipe implements PipeTransform {

	public transform(sms: Sms) {
		if (sms) {
			const datePipe = new DatePipe('en-US');
			const date = (sms.direction == 'inbound-api') ? sms.receivedAt : sms.deliveredAt ?? sms.queuedAt;

			return datePipe.transform(date, 'MMMM d, y h:mm a');
		}

		return '';
	}
}
