<ng-container *ngrxLet="round$; let roundValue">
	<div *ngrxLet="purchasedInfo$; let purchasedInfo" class="dash-panel ibox">
		<collapsible-item [isManagedOutside]="isCollapseManaged">
			<div header-left>
				<h4> Total Ever Purchased </h4>
				<span class="flex">in <ghost-element
					width="5">&nbsp;<b>{{ purchasedInfo?.totals.fundingsAdvanced | number : '1.0-0' }}</b>&nbsp;</ghost-element> fundings /<ghost-element
					width="4">&nbsp;<b>{{ purchasedInfo?.totals.totalClaims | number: '1.0-0' }}</b>&nbsp;</ghost-element> claims</span>
			</div>
			<h1 header-right>
				<ghost-element width="25"
							   height="4">{{ purchasedInfo?.totals.totalAdvanced | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
				</ghost-element>
			</h1>
			<div class="ibox-content lifetime-advances">
				<div class="date-boundary-filter__wrapper">
					<dashboard-widget-date-filter class="date-boundary-filter"
												  [inputControl]="dateFilterControl"></dashboard-widget-date-filter>
				</div>
				<div class="row">
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Avg. Funding:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ purchasedInfo?.totals.avgFunding | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Median Funding:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ purchasedInfo?.medianFunding | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Avg. Claim:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ purchasedInfo?.totals.avgClaim | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Median Claim:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ purchasedInfo?.medianClaim | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<hr>
				<div class="row">
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Plaintiff:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ purchasedInfo?.totals?.plaintiff | number: '1.0-0' }}
											/ {{ purchasedInfo?.totals?.plaintiffPercentage | number: '1.0-0' }}%</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Case Cost:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ purchasedInfo?.totals?.caseCost | number: '1.0-0' }}
											/ {{ purchasedInfo?.totals?.caseCostPercentage | number: '1.0-0' }}%</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Medical:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ purchasedInfo?.totals?.medical | number: '1.0-0' }}
											/ {{ purchasedInfo?.totals?.medicalPercentage | number: '1.0-0' }}%</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Plaintiff:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ purchasedInfo?.totals?.plaintiffAmount | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
											/ {{ purchasedInfo?.totals?.plaintiffAmountPercentage | number: '1.0-0' }}
											%</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Case Cost:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ purchasedInfo?.totals?.caseCostAmount | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
											/ {{ purchasedInfo?.totals?.caseCostAmountPercentage | number: '1.0-0' }}
											%</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Medical:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ purchasedInfo?.totals?.medicalAmount | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
											/ {{ purchasedInfo?.totals?.medicalAmountPercentage | number: '1.0-0' }}
											%</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<hr>
				<div class="row">
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td># Total Law Firms:</td>
								<td class="number">
									<ghost-element width="5"><b
										class="number">{{ purchasedInfo?.firmsTotal.lawFirmsCount | number: '1.0-0' }}</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Avg. Cases per Law Firm:</td>
								<td class="number">
									<ghost-element width="4">
										<b>{{ purchasedInfo?.firmsTotal.avgCasesPerLawFirm | number: '1.0-1' }}</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Median Cases per Law Firm:</td>
								<td class="number">
									<ghost-element width="4">
										<b>{{ purchasedInfo?.medianCasesPerLawFirm | number: '1.0-1' }}</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Law Firms Medical Liens:</td>
								<td class="number">
									<ghost-element width="4">
										<b>{{ purchasedInfo?.firmsMedicalLiens | number: '1.0-1' }}</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Law Firms Plaintiff:</td>
								<td class="number">
									<ghost-element width="4">
										<b>{{ purchasedInfo?.firmsPlaintiff | number: '1.0-1' }}</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Law Firms Case Cost:</td>
								<td class="number">
									<ghost-element width="4">
										<b>{{ purchasedInfo?.firmsCaseCost | number: '1.0-1' }}</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<hr>
				<div>
					<ghost-element width="60">
						<medical-purchases-table
							[medicalBreakdown]="purchasedInfo?.totals?.medicalBreakdown"></medical-purchases-table>
					</ghost-element>
				</div>
			</div>
		</collapsible-item>
	</div>
</ng-container>
