import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { ReportOptionsLayout1Config } from '@app/reports/modals/report-options/report-options-modal-layout-1.component';
import { ReportOptionsLayout2Config } from '@app/reports/modals/report-options/report-options-modal-layout-2.component';
import { DatePickerRangesService } from '@app/shared/daterange-picker/date-picker-ranges/date-picker-ranges.service';
import { DateRangePeriod } from '@app/shared/model/types/date-range-period';
import { DownloadLinkConfig } from '@app/widgets/download-link/download-link-config.model';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';

export abstract class ReportOptionsModalComponent {
	dateRangesService: DatePickerRangesService;
	codelistService: CodelistRepositoryService;
	bsModalRef: BsModalRef;

	reportUrl: string;
	downloadLink$: Subject<DownloadLinkConfig> = new Subject<DownloadLinkConfig>();
	rangeLabels: string[] = [
		DatePickerRangesService.THIS_MONTH,
		DatePickerRangesService.LAST_MONTH,
		DatePickerRangesService.THIS_YEAR,
		DatePickerRangesService.LAST_YEAR,
		DatePickerRangesService.ENTIRE_HISTORY,
	];
	ranges$: BehaviorSubject<DateRangePeriod> = new BehaviorSubject<DateRangePeriod>(null);
	config: ReportOptionsLayout1Config | ReportOptionsLayout2Config;

	constructor(dateRangesService: DatePickerRangesService,
				codelistService: CodelistRepositoryService,
				bsModalRef: BsModalRef) {
		this.dateRangesService = dateRangesService;
		this.codelistService = codelistService;
		this.bsModalRef = bsModalRef;
	}

	protected init(): void {
		this.ranges$.next({
			labels: this.rangeLabels,
			values: this.dateRangesService.getRangeDates(this.rangeLabels)
		});
	}

	public setDateRange([from, to]): void {
		this.config.from = moment(from).format('Y-MM-DD');
		this.config.to = moment(to).format('Y-MM-DD');
	}

	protected download(params): void {
		this.downloadLink$.next({
			url: this.reportUrl,
			customHttpParams: params
		});
		this.bsModalRef.hide();
	}
}
