import { ChangeDetectionStrategy, Component, Input, OnInit, } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { MetadataProviderService } from '@app/shared/ga-components/services/metadata-provider.service';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';

@Component({
	selector: 'ga-label',
	template: '<ng-container *ngIf="isShown" class="ga-label-content">{{componentMetadata.label}}</ng-container>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
/* eslint-disable @angular-eslint/no-input-rename */
export class GaLabelComponent implements OnInit {

	@Input() componentMetadata: ComponentMetadata;

	@Input('metadata') metadataOverride: ComponentMetadata;
	@Input('field') fieldPath: string;

	isShown;

	constructor(
		public acl: AclService,
		public entityScope: EntityScopeService,
		public metadataProvider: MetadataProviderService,

	) {}

	public ngOnInit(): void {
		//TODO find a way how to optimize this when same gaValue component is rendered multiple times
		if (!this.componentMetadata) {
			this.componentMetadata =
				this.metadataProvider.getComponentMetadata(this.entityScope.entityClass, this.fieldPath, this.metadataOverride);
		}

		this.isShown = this.acl.isReadable(this.componentMetadata.fieldName, this.componentMetadata.entityClass);
	}

}
