import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AgGridStorageService } from '@app/ga-grid/services/ag-grid-storage-services/ag-grid-storage.service';

@Injectable()
export class AgGridColumnStorageService extends AgGridStorageService {
	readonly storageId = 'ag-grid-columns-state';

	constructor(router: Router) {
		super(router);
	}
}
