<label class="base-label" for="sortItemSelect">
	Sort By
</label>
<div class="sort-wrapper">
	<fa-icon [icon]="sortIcon$ | async" size="2x" (click)="changeSortDirection()"></fa-icon>
	<ng-select
		id="sortItemSelect"
		class="base-input"
		bindLabel="title"
		placeholder="Sort by..."
		[items]="sortOptions"
		[formControl]="sortControl"
		(change)="itemSelected($event)"
	></ng-select>
</div>
