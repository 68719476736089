import { Component, Input } from '@angular/core';
import { EditedBy } from '@app/applicant/applicant-detail/applicant-detail-tabs-section/fundings-tab/fundings-tab-main-section/main-section-detail/tabs/accounting-tab/funding-accounting-tab.model';

@Component({
	selector: 'edited-by',
	templateUrl: './edited-by.component.html',
})
export class EditedByComponent {
	@Input() editedBy: EditedBy;
}
