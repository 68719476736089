<div class="base-form__view">
	<div class="base-form__header">
		<div class="base-form__header__title">CPT Code Form</div>
	</div>
	<form #cptCodeFormScope="ngForm" [formGroup]="form" autocomplete="off">
		<div class="base-card mt-20">
			<div class="base-card__content">
				<div class="row col-12">
					<div class="col-4 base-input__wrapper">
						<div class="label-wrapper">
							<label class="base-label" for="code">
								Code<span class="required"
								          [class.invalid]="form.controls.code.invalid">(required)</span>
							</label>
						</div>
						<input class="base-input" id="code" formControlName="code" data-cy="code-input" />
					</div>

					<div class="col-4 base-input__wrapper">
						<div class="label-wrapper">
							<label class="base-label" for="title">
								Title<span class="required"
								           [class.invalid]="form.controls.title.invalid">(required)</span>
							</label>
						</div>
						<input class="base-input" id="title" formControlName="title" data-cy="title-input" />
					</div>
				</div>
			</div>
		</div>
		<div class="base-flex mt-20">
			<div class="base-flex__item-right">
				<app-loading-button
					[disabled]="form.invalid"
					[dataCy]="'saveAndGoToList-btn'"
					(onBtnClick)="saveAndBackToList($event)"
				> Save
				</app-loading-button>
			</div>
		</div>
	</form>
</div>
