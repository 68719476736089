import { Component, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GaEditorParams } from '@app/shared/ga-components/components/editors/ga-editor.params';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IEditor } from '@app/shared/ga-components/utils/IEditor';

@Component({
	selector: 'ga-input-editor',
	template: `
		<ng-container [formGroup]="viewFormGroup">
			<input
				(focus)="onFocus(true)"
				(blur)="onFocus(false)"
				formControlName="control"
				[ngClass]="cssClass"
				(change)="onChange($event)"
				[attr.data-cy]="componentMetadata.fieldName + '-input'"
				#inputElement/>
		</ng-container>
	`,
})
export class GaInputEditorComponent implements IEditor {
	@ViewChild('inputElement', { static: true }) inputElement;

	cssClass = 'ga-input';
	componentMetadata: ComponentMetadata;
	params: GaEditorParams;
	formGroup: UntypedFormGroup;
	viewFormGroup: UntypedFormGroup;
	onFocusEmitter: EventEmitter<boolean>;

	constructor() {
		this.viewFormGroup = new UntypedFormGroup({
			control: new UntypedFormControl()
		});
	}

	@HostListener('keydown', ['$event'])
	public onKeyDown(key) {
		if (key.key === 'Enter' && this.inputElement) {
			this.trimAndSetValue(this.inputElement.nativeElement.value);
		}
	}

	public update(value): void {
		this.setModelValue(value);
		if (this.viewFormGroup.get('control')) {
			this.viewFormGroup.get('control').setValue(value ? this.componentMetadata.valueFormatter(value) : null);
		}
	}

	public onChange($event) {
		this.trimAndSetValue($event.target.value);
	}

	private trimAndSetValue(value): void {
		value = value.trim();
		this.setModelValue(value.length == 0 ? null : value);
	}

	public init(value) {
		this.update(value);
	}

	public onFocus(isFocused: boolean) {
		const value = this.viewFormGroup.get('control').value;
		if (isFocused) {
			this.viewFormGroup.get('control').setValue(value ? this.componentMetadata.valueParser(value) : null);
		} else {
			this.viewFormGroup.get('control').setValue(value ? this.componentMetadata.valueFormatter(value) : null);
		}
		if (this.onFocusEmitter) {
			this.onFocusEmitter.emit(isFocused);
		}
	}

	public setModelValue(value) {
		this.formGroup
			.get(this.componentMetadata.fieldMetadata.fieldName)
			?.setValue(this.componentMetadata.valueParser ? this.componentMetadata.valueParser(value) : value);
	}

	public reset() {
		this.viewFormGroup.get('control').reset('', { emitEvent: false });
		this.formGroup.get(this.componentMetadata.fieldMetadata.fieldName).reset(null);
	}

	public isPristine(): boolean {
		return this.viewFormGroup.pristine;
	}
}
