import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { AclService } from '@app/core/security/acl.service';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { CptCodeCodelistItem } from '@app/shared/model/types/cpt-code';
import { PersistableGaGridComponent } from '@app/shared/persistable-ga-grid-component/persistable-ga-grid-component';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions, NewValueParams } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Component({
	selector: 'cpt-codes-list',
	templateUrl: './cpt-codes-list.component.html',
})
export class CptCodesListComponent extends PersistableGaGridComponent implements OnInit {

	@HostBinding('class.view') hostClass = true;
	@ViewChild('agGrid') agGrid: AgGridAngular;

	gridOptions: GridOptions;
	columnDefinitions: ColDef[];
	cptCodes$: Observable<CptCodeCodelistItem>;

	readonly svgIconNames = SvgIconName;
	private cptHook = [{ name: 'urlCodelistUpdate', meta: { 'url': [UrlCodelistLink.CptCodes] } }];

	constructor(private codelistRepository: CodelistRepositoryService,
				private apiLink: GaApiLink,
				private acl: AclService,
				private agUtils: AgGridUtilsService) {
		super();
	}

	public ngOnInit(): void {
		this.gridOptions = this.agUtils.getGridOptions({
			suppressExcelExport: !this.acl.isAllowed('export.settings_cpt_codes'),
			defaultColDef: {
				headerClass: 'ag-editable-cell',
				filter: 'agTextColumnFilter'
			},
			defaultExcelExportParams: {
				fileName: 'CPT Codelist Export'
			}
		});

		this.columnDefinitions = [
			{
				field: 'code',
				cellClass: 'stringFormat',
				editable: true,
				onCellValueChanged: (event) => this.onCellValueChange(event, true)
			},
			{
				field: 'title',
				editable: true,
				onCellValueChanged: (event) => this.onCellValueChange(event, false)
			}
		];

		this.cptCodes$ = this.codelistRepository.getByUrl(UrlCodelistLink.CptCodes);
	}

	private onCellValueChange(params: NewValueParams, codeEdit: boolean): void {
		if (params.oldValue == params.newValue) {
			return;
		}
		this.apiLink.update('cPTCode',
			{ id: params.data.id },
			codeEdit ? { code: params.newValue } : { title: params.newValue },
			[],
			{},
			this.cptHook).subscribe();
	}
}
