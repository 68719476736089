import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { Codelist } from '@app/shared/model/types/codelists/codelist';
import { EntityId } from '@app/shared/model/types/entity-id';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { combineLatest, Observable, map, BehaviorSubject } from 'rxjs';

@Component({
	selector: 'entity-label-picker',
	templateUrl: './entity-label-picker.component.html',
	styleUrls: ['./entity-label-picker.component.scss']
})
export class EntityLabelPickerComponent implements OnInit {
	@Input() codeListName: string;
	@Input()
	set selectedLabels(value: Codelist[]) {
		this.dropDownItems$.next(value);
	}
	@Output() onCancel = new EventEmitter();
	@Output() onSave = new EventEmitter<EntityId[]>();
	labelsControl = new FormControl<EntityId[]>([]);
	labels$: Observable<Codelist[]>;
	readonly faIcons = {
		check: faCheck
	};
	private dropDownItems$ = new BehaviorSubject<Codelist[]>([]);

	constructor(
		private codelistRepository: CodelistRepositoryService
	) {}

	public ngOnInit(): void {
		this.labels$ = combineLatest<[Codelist[], Codelist[]]>([
			this.codelistRepository.get(this.codeListName),
			this.dropDownItems$.asObservable()
		]).pipe(map(([allLabels, selectedLabels]) => this.removeSelectedItems(allLabels, selectedLabels)
		));
	}

	public cancelAdding(): void {
		this.onCancel.emit();
	}

	public save(): void {
		this.onSave.emit(this.labelsControl.value);
	}

	private removeSelectedItems(allLabels: Codelist[], selectedTodoLabels: Codelist[]): Codelist[] {
		const todoLabelsIds = selectedTodoLabels.map((label) => label.id);
		return allLabels.filter((label) => !todoLabelsIds.includes(label.id));
	}
}
