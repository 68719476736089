import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AclIsAllowedAllDirective } from '@app/shared/directives/acl/acl-is-allowed-all.directive';
import { AclIsAllowedSomeDirective } from '@app/shared/directives/acl/acl-is-allowed-some.directive';
import { AclIsAllowedDirective } from '@app/shared/directives/acl/acl-is-allowed.directive';
import { AclIsWritableDirective } from '@app/shared/directives/acl/acl-is-writable.directive';
import { ClickOutsideDirective } from '@app/shared/directives/click-outside.directive';
import { TemplateObjectDirective } from '@app/shared/directives/template-object.directive';
import { ThrottleClickDirective } from '@app/shared/directives/throttle-click.directive';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		ThrottleClickDirective,
		TemplateObjectDirective,
		ClickOutsideDirective,
		AclIsAllowedDirective,
		AclIsAllowedAllDirective,
		AclIsAllowedSomeDirective,
		AclIsWritableDirective,
	],
	exports: [
		ThrottleClickDirective,
		TemplateObjectDirective,
		ClickOutsideDirective,
		AclIsAllowedDirective,
		AclIsAllowedAllDirective,
		AclIsAllowedSomeDirective,
		AclIsWritableDirective,
	]
})
export class DirectivesModule {}
