import { formatCurrency, formatNumber, formatPercent } from '@angular/common';
import { ChartLegendDatum, TooltipData, TooltipThemeConfig } from '@app/shared/ag-grid-utils/chart-themes/tooltip-theme-service.model';
import { currencyColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/currency-column-definition';
import { numberColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/number-column-definition';
import { percentageColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/percentage-column-definition';
import { ColDef } from 'ag-grid-community';

export abstract class TooltipThemeService<T extends { tooltip? }> {
	protected readonly _gridTooltipConfigs: Record<string, TooltipThemeConfig> = {};
	protected tooltipColIds: string[] = [];
	protected tooltipData;
	protected chart = null;

	constructor(tooltipConfigs = {}) {
		this._gridTooltipConfigs = tooltipConfigs;
	}

	protected abstract getTooltip(): Pick<T, 'tooltip'>;

	public getGroupedTooltipTheme(colIds: string[]|null): Pick<T, 'tooltip'> {
		if (colIds !== null && colIds.length > 0) {
			this.tooltipColIds = colIds;
		}
		return this.getTooltip();
	}

	public setTooltipColIds(colIds: string[]): void {
		this.tooltipColIds = colIds;
	}

	public setTooltipData(data: TooltipData<unknown>): void {
		this.tooltipData = data;
	}

	public setChart(chart): void {
		this.chart = chart;
	}

	public addPriceColumn(headerName: string, path: string, colId: string = null): ColDef {
		const columnId = colId ?? path;
		this._gridTooltipConfigs[path] = this.getPriceValueConfig(headerName, path, columnId);
		return {
			field: path,
			colId: columnId,
			headerName,
			...currencyColumnDefinition(path),
			chartDataType: 'series'
		}
	}

	public addNumberColumn(headerName: string, path: string, colId: string = null): ColDef {
		const columnId = colId ?? path;
		this._gridTooltipConfigs[path] = this.getNumberValueConfig(headerName, path, columnId);
		return {
			field: path,
			colId: columnId,
			headerName,
			...numberColumnDefinition(path),
			chartDataType: 'series'
		};
	}

	public addPercentageColumn(headerName: string, path: string, colId: string = null): ColDef {
		this._gridTooltipConfigs[path] = {
			title: headerName,
			path,
			colId: colId ?? path,
			valueFormatter: val => {
				const cleanVal = val ?? 0;
				return formatPercent(+cleanVal, 'en-US', '1.0-2');
			}
		};
		return {
			field: path,
			colId: path,
			headerName,
			...percentageColumnDefinition(path),
			chartDataType: 'series'
		};
	}

	private getPriceValueConfig(title: string, path: string, colId: string): TooltipThemeConfig {
		return {
			title,
			path,
			colId,
			valueFormatter: val => {
				const cleanVal = val ?? 0;
				return formatCurrency(+cleanVal, 'en-US', '$', 'symbol', '1.2-2');
			}
		}
	}

	private getNumberValueConfig(title: string, path: string, colId: string): TooltipThemeConfig {
		return {
			title,
			path,
			colId,
			valueFormatter: val => {
				const cleanVal = val ?? 0;
				return formatNumber(+cleanVal, 'en-US', '1.0-2')
			}
		}
	}

	protected includeColumnInTooltip(colConfig: TooltipThemeConfig): boolean {
		const legendLabels: ChartLegendDatum[] = this.chart?.legend?.data;

		const checkSecondaryAxis = (legendLabel: ChartLegendDatum) => {
			return legendLabel.itemId === undefined && legendLabel.label.text === colConfig.title;
		}

		return !legendLabels || legendLabels.some(legendLabel => legendLabel.enabled &&
			(legendLabel.itemId === colConfig.colId || checkSecondaryAxis(legendLabel)));
	}
}
