import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class GoogleApiService {
	apiLoaded$: Observable<boolean>;

	constructor(private http: HttpClient) {
	}

	public getGoogleApiLoaded(): Observable<boolean> {

		if (this.apiLoaded$) {
			return this.apiLoaded$;
		} else {
			this.apiLoaded$ = this.http.jsonp(
				`https://maps.googleapis.com/maps/api/js?key=${environment.googleApiToken}&libraries=places&language=en`,
				'callback'
			)
				.pipe(
					map(() => true),
					shareReplay({ bufferSize: 1, refCount: true }),
					catchError(() => of(false)),
				);
			return this.apiLoaded$;
		}
	}
}
