<div *ngIf="acl.isReadable(fieldPath, entityScope.entityClass)" class="base-input__wrapper" [attr.data-cy]="formControlId+'-element'">
	<div class="label-wrapper">
		<label class="base-label" [for]="formControlId" *ngIf="showLabel">
			<ga-label [componentMetadata]="componentMetadata"></ga-label>
		</label>
		<ga-validation-labels [validators]="componentMetadata.validators" [validationErrors$]="validationErrors$"></ga-validation-labels>
	</div>
	<ga-editor [fieldMetadata]="fieldMetadata"
			   [customInputClass]="customInputClass"
			   [componentMetadata]="componentMetadata"
			   (onFocus)="onFocus($event)"
			   (onValueChange)="onValueChange($event)"
			   (onStatusChange)="onStatusChange($event)">
	</ga-editor>
	<ng-container *ngFor="let error of customErrors">
		<strong class="text-danger"><small [innerHTML]="error"></small></strong>
	</ng-container>
	<ng-container *ngIf="warning">
		<strong class="text-warning"><small [innerHTML]="warning"></small></strong>
	</ng-container>
	<div class="loading-panel" data-cy="loading-element" *ngIf="isPending">
		<div class="loading-panel__message">
			<div class="spinner">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
		</div>
	</div>
</div>

