import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CurrentPortfolioResponse, OutstandingGrouped } from '@app/dashboard/current-portfolio/current-portfolio.model';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { DashboardRoundStore } from '@app/dashboard/service/dashboard-round-store.service';
import { dateBoundaryOperator } from '@app/dashboard/shared/date-boundary-operator';
import { GhostElementService } from '@app/widgets/ghost-element/ghost-element.service';
import { combineLatest, merge, Observable, of, switchMap } from 'rxjs';
import { filter, map, shareReplay, startWith, tap } from 'rxjs/operators';

@Component({
	selector: 'current-portfolio',
	templateUrl: './current-portfolio.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: ['h1::first-letter {padding-right: 0.3em;}'],
	styleUrls: ['../shared/dashboard-date-filter-styling.scss'],
	providers: [GhostElementService]
})
export class CurrentPortfolioComponent implements OnInit {
	@Input() isCollapseManaged = false;

	portfolio$: Observable<CurrentPortfolioResponse>;
	projection$: Observable<OutstandingGrouped[]>;
	round$: Observable<boolean>;
	dateFilterControl = new FormControl<Date>(null);

	preSetMultiplier = new FormControl(1.7);
	medicalMultiplier = new FormControl(2.0);

	constructor(
		private http: HttpClient,
		private ghostVisibilityService: GhostElementService,
		private dashboardFilterStore: DashboardFilterStore,
		private dashboardRoundStore: DashboardRoundStore) {
	}

	public ngOnInit(): void {
		this.round$ = this.dashboardRoundStore.selectRound$;
		const portfolioGetter$ = combineLatest([
			this.dashboardFilterStore.fundingParams$,
			this.dateFilterControl.valueChanges.pipe(
				filter(() => this.dateFilterControl.valid),
				dateBoundaryOperator
			)
		]).pipe(
			tap(() => {
				this.ghostVisibilityService.showData(false);
			}),
			switchMap(([params, dateFilter]) => {
				if (dateFilter != null) {
					params = params.set('dateBoundary', dateFilter);
				}
				return this.http.get<CurrentPortfolioResponse>('/api/dashboard/current-portfolio', { params });
			}),
			tap((res) => {
				this.preSetMultiplier.patchValue(res.preSetExpectedFee);
				this.medicalMultiplier.patchValue(res.medicalExpectedFee);
				this.ghostVisibilityService.showData(true);
			}),
			shareReplay({ bufferSize: 1, refCount: true })
		);
		this.projection$ = portfolioGetter$
			.pipe(
				switchMap(portfolio => this.getProjectionEmitter(portfolio))
			);
		this.portfolio$ = merge(of(null), portfolioGetter$);
	}

	private getProjectionEmitter(portfolio: CurrentPortfolioResponse) {
		return merge(this.preSetMultiplier.valueChanges, this.medicalMultiplier.valueChanges)
			.pipe(
				startWith(null),
				map(() => this.getProjection(portfolio.outstandingGrouped))
			);
	}

	public getProjection(outstandingGrouped: OutstandingGrouped[]): OutstandingGrouped[] {
		return outstandingGrouped.map(group => {
			const multiplier = group.fundingType === 'Medical' ? this.medicalMultiplier.value : this.preSetMultiplier.value;
			const fee = group.principal * (multiplier - 1);
			return {
				...group,
				fee,
				faceValue: group.principal + fee
			};
		});
	}
}
