import { Pipe, PipeTransform } from '@angular/core';
import { DoctorAddress } from '@app/shared/model/types/doctor-address';
import { get } from 'lodash';

@Pipe({ name: 'mainDoctorContactInfo' })
export class MainDoctorContactInfoPipe implements PipeTransform {

	public transform(addresses: Array<DoctorAddress>, fieldName: string, returnEmpty = false): string {
		if (addresses && addresses.length > 0) {
			const mainAddresses = addresses.filter((address: DoctorAddress) => address.active);

			return get(mainAddresses, `0.${fieldName}`, 'n/a');
		}

		return returnEmpty ? '' : 'n/a';
	}
}
