import { formatPercent } from '@angular/common';
import { ColDef } from 'ag-grid-community';
import { get } from 'lodash';

export function percentageColumnDefinition(field: string): ColDef {
	return {
		valueGetter: ({ data }) => {
			const value = get(data, field, 0) ?? 0;
			return +value;
		},
		valueFormatter: ({ value }) => formatPercent(value, 'en-US', '1.0-2'),
		filter: 'agNumberColumnFilter',
		filterValueGetter: ({ data }) => {
			const value = get(data, field, 0) ?? 0;
			return +value * 100;
		},
		cellClass: 'percentage'
	}
}
