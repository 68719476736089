import { Component } from '@angular/core';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface CustomGroupRendererParams {
	delimiter?: string;
	innerCellRenderer?: (data) => string[];
}

type AgGroupRendererParams = CustomGroupRendererParams & ICellRendererParams;

@Component({
	selector: 'ag-group-renderer',
	templateUrl: './ag-group-renderer.component.html',
})
export class AgGroupRendererComponent implements AgRendererComponent {
	params: any;
	isOpen = false;
	isGrouping = false;

	readonly faIcons = {
		chevronRight: faChevronRight,
		chevronDown: faChevronDown,
	};

	values: any[] = [];

	public agInit(params: AgGroupRendererParams): void {
		this.params = params;
		if (!params.delimiter && !params.innerCellRenderer) {
			console.error('Please set either delimiter or innerCellRenderer for AgGroupRenderer');
			return;
		}
		this.values = params.delimiter ? params.value.split(params.delimiter) : params.innerCellRenderer(params.data);
		this.isGrouping = params.column.isRowGroupActive();
	}

	public refresh(): boolean {
		return false;
	}

	public open(): void {
		this.isOpen = !this.isOpen;
		if (this.isOpen) {
			this.params.node.setRowHeight((this.values.length + 1) * 25.6 + 20);
		} else {
			this.params.node.setRowHeight(32);
		}
		this.params.api.onRowHeightChanged();
	}
}
