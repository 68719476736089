import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { EntityId } from '@app/shared/model/types/entity-id';
import { TodoCommentRequest } from '@app/shared/model/types/todo';
import { TodoService } from '@app/todo/services/todo.service';
import { TodoCardStoreService } from '@app/todo/todo-card/todo-card-store.service';
import { TodoCommentService } from '@app/todo/todo-card/todo-comments/service/todo-comment.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'todo-comment-card-form',
	templateUrl: 'todo-comment-card-form.component.html',
	providers: [TodoCommentService]
})
export class TodoCommentCardFormComponent implements OnInit, OnDestroy {
	@Input() todoId: EntityId;

	private currentUserId: number;
	private destroy$ = new Subject<void>();

	constructor(
		public todoCommentService: TodoCommentService,
		private todoCardStore: TodoCardStoreService,
		private todoService: TodoService,
		private authService: AuthService) {
	}

	public ngOnInit(): void {
		this.currentUserId = this.authService.getCurrentUser().id;
		this.todoCommentService.getInputTouch().pipe(takeUntil(this.destroy$))
			.subscribe(() => this.todoCardStore.markAsSeen())
	}

	public submit(): void {
		const newComment = new TodoCommentRequest(
			this.currentUserId,
			this.todoId.toString(),
			this.todoCommentService.newCommentText,
			this.todoCommentService.commentMentionRequests
		);
		this.todoService.createTodoComment(newComment)
			.pipe(takeUntil(this.destroy$))
			.subscribe((todoComment) => {
				this.todoCardStore.markAsSeen();
				this.todoCardStore.updateComment(todoComment);
				this.todoCommentService.resetNewCommentTextCtrl();
				this.todoCommentService.commentMentionRequests = [];
				this.todoCommentService.selectedChoices = [];
				this.todoCommentService.showCheck = true;
				setTimeout(() => this.todoCommentService.showCheck = false, 1000);
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
