<div class="todo-comments__sorting-wrapper">
	Sort by:
	<span class="badge todo-comments__sorting-badge" (click)="changeSorting()">
		<ng-container *ngIf="currentSortMode == availableSortModes.OLDEST_FIRST; else newestFirst">
			Oldest <fa-icon [icon]="icons.arrow"></fa-icon> Newest
		</ng-container>
		<ng-template #newestFirst>
			Newest <fa-icon [icon]="icons.arrow"></fa-icon> Oldest
		</ng-template>
	</span>
</div>
