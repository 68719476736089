import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import StartOf = moment.unitOfTime.StartOf;

@Pipe({ name: 'isInPast' })
export class IsInPastPipe implements PipeTransform {

	public transform(value: any, granularity: StartOf = 'hours'): boolean {
		return value && moment(value).isBefore(moment(), granularity);
	}
}
