<div class="chart-clear-wrapper chart-clear-wrapper--space-between">
	<dashboard-widget-date-filter class="date-boundary-filter"
								  [inputControl]="dateFilterControl"></dashboard-widget-date-filter>
	<button class="base-button base-button--square" tooltip="Clear graph preferences" *ngIf="hasSavedChart$ | async"
			(click)="clearSavedChartState()">
		<svg-icon class="base-icon base-icon--small" [icon]="clearIcon"></svg-icon>
	</button>
</div>
<div class="flex breakdown-content">
	<div id="pipeline-chart" class="top-five-dashboard-chart"></div>
	<ag-grid-angular
		class="ag-theme-balham ag-grid-overflow"
		[rowData]="rowData$ | async"
		[columnDefs]="colDef"
		[gridOptions]="gridOptions"
		[masterDetail]="true"
		(gridReady)="onGridReady($event)"
		(firstDataRendered)="onFirstDataRendered($event)"
		(chartOptionsChanged)="onChartOptionsChanged()"
	></ag-grid-angular>
</div>
