<div class="user-avatar" [class.active]="isActive$ | async" [ngClass]="customClass" [tooltip]="tooltip$ | async"
	container="body" [placement]="tooltipPlacement" [adaptivePosition]="false">
	<ng-container *ngIf="avatarPath$ | async as avatarPath; else initials">
		<ng-container *ngIf="avatarPath; then hideImage ? initials : img; else initials">
		</ng-container>
		<ng-template #img>
			<img [src]="avatarPath" (error)="hideImage=true;" alt="User avatar" />
		</ng-template>
	</ng-container>
	<ng-template #initials>
		<ng-container *ngIf="!user?.firstName">
			<fa-icon [icon]="unknownUserIcon"></fa-icon>
		</ng-container>
		<ng-container *ngIf="user?.firstName">{{ $any(user) | nameInitials }}</ng-container>
	</ng-template>
	<div class="active-badge"></div>
</div>
