export interface SwitchParams {
	checkedColor: string,
	checkedText: string,
	checkedTextColor: string,
	uncheckedColor: string,
	uncheckedText: string,
	uncheckedTextColor: string,
	sliderColor: string,
}

export const defaultSwitchParams = {
	checkedColor: 'var(--primary)',
	checkedText: 'On',
	checkedTextColor: '#fff',
	uncheckedColor: 'var(--dark-grey)',
	uncheckedText: 'Off',
	uncheckedTextColor: '#fff',
	sliderColor: '#fff',
}
