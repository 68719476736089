<div class="new-todo">
	<div class="new-todo__header">
		<h2>Add Todo</h2>
	</div>
	<div class="new-todo__label-wrapper">
		<label class="base-label" for="salesActivity">
			Sales Activity Note
		</label>
		<form-validation-label [control]="salesActivityCtrl"></form-validation-label>
	</div>
	<ng-select
		id="salesActivity"
		class="base-input"
		bindLabel="subject"
		bindValue="id"
		placeholder="Select sales activity note..."
		[class.invalid]="salesActivityCtrl.invalid"
		[items]="allSalesActivityNotes"
		[formControl]="salesActivityCtrl">
	</ng-select>
	<new-todo-form (onFormChange)="todoFormChanged($event)"></new-todo-form>
	<div class="new-todo__footer">
		<button class="base-button base-button--cancel" data-cy="cancel-btn" (click)="cancel()">Cancel</button>
		<app-loading-button [dataCy]="'submit-btn'" [disabled]="!todoFormData.isFormValid"
							(onBtnClick)="saveTodo($event)">Save
		</app-loading-button>
	</div>
</div>
