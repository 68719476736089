import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CodelistSelectComponent } from '@app/shared/codelist-selects/codelist-select/codelist-select.component';
import { TitleCodelistSelectComponent } from '@app/shared/codelist-selects/title-codelist-select/title-codelist-select.component';
import { UserAccountCodelistSelectComponent } from '@app/shared/codelist-selects/user-account-codelist-select/user-account-codelist-select.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports: [
		CommonModule,
		NgSelectModule,
		ReactiveFormsModule,
	],
	exports: [
		UserAccountCodelistSelectComponent,
		TitleCodelistSelectComponent,
		CodelistSelectComponent,
	],
	declarations: [
		UserAccountCodelistSelectComponent,
		TitleCodelistSelectComponent,
		CodelistSelectComponent,
	]
})
export class CodelistSelectsModule {}
