import { Injectable } from '@angular/core';
import { EntityId } from '@app/shared/model/types/entity-id';

//TODO: Rewrite TodoService as ComponentStore and make dueDate part of the state
@Injectable()
export class TodoDueDateService {
	private _timezoneId: EntityId;
	private _todoDueDateString = '';

	set timezoneId(timezoneId: EntityId) {
		this._timezoneId = timezoneId;
	}

	get timezoneId() {
		return this._timezoneId;
	}

	set todoDueDateString(dueDateString: string) {
		this._todoDueDateString = dueDateString;
	}

	get todoDueDateString() {
		return this._todoDueDateString;
	}
}
