import { SeriesChartType } from 'ag-grid-community';

export type SummaryStatisticsResponse = {
	title: string;
	fundedDetails?: FundedDetails;
	settledDetails?: SettledDetails;
	collectedDetails?: CollectedDetails;
	month?: number;
	quarter?: number;
	year: number;
}

type FundedDetails = {
	numberOfFundings: number,
	numberOfCases: number,
	totalPrice: number,
	growthDeclineFundings: number,
	growthDeclinePrice: number,
	numberOfFundingsPerBizDay: number,
	presetCountPerBizDay: number,
	mriCountPerBizDay: number,
	medicalCountPerBizDay: number,
	caseCostCountPerBizDay: number,
}

type SettledDetails = {
	numberOfFundings: number,
	principalCollected: number,
	principalLoss: number,
	principalSettled: number,
	settledPercentage: number
}

type CollectedDetails = {
	numberOfFundings: number,
	totalPrice: number,
	totalPrincipal: number,
	totalFee: number
}

export enum SummaryStatisticsChartMode {
	Collected = 'collected',
	Funded = 'funded',
	EffectiveRates = 'effective-rates'
}

export enum SummaryStatisticsChartId {
	FundedFundings,
	FundedDollars,
	CollectedFundings,
	CollectedDollars,
	EffectiveRatesFundings,
	EffectiveRatesDollars
}

export type SummaryStatisticsChartConfig = {
	columns: SeriesChartType[];
	chartId: SummaryStatisticsChartId;
	chartElementSelector: string;
}
