import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TokenStorageService } from '@app/core/auth/token-storage.service';
import { DownloadLinkConfig } from '@app/widgets/download-link/download-link-config.model';
import { environment } from '@env/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'download-link',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class DownloadLinkComponent implements OnInit, OnDestroy {
	@Input() config$: Subject<DownloadLinkConfig>;

	private destroy$ = new Subject<void>();

	constructor(
		private tokenStorage: TokenStorageService,
		private renderer: Renderer2,
		) {
	}

	public ngOnInit() {
		this.config$.pipe(takeUntil(this.destroy$)).subscribe((config: DownloadLinkConfig) => {
			let httpParams: HttpParams = new HttpParams()
				.append('token', this.tokenStorage.getAuthToken());

			if (localStorage.getItem('debug')) {
				httpParams = httpParams.append('XDEBUG_SESSION_START', '1');
			}

			if (config.customHttpParams) {
				httpParams = httpParams.appendAll(config.customHttpParams);
			}
			const downloadLink = this.renderer.createElement('a');
			this.renderer.setAttribute(downloadLink, 'target', '_self');
			this.renderer.setProperty(downloadLink, 'download', '');
			this.renderer.setProperty(downloadLink, 'href', `${environment.apiUrl}${config.url}?${httpParams.toString()}`);
			downloadLink.dispatchEvent(new MouseEvent('click'));
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
