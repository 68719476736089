import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { SortMode } from '@app/shared/model/types/todo';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'todo-comments-sorting',
	templateUrl: './todo-comments-sorting.component.html',
	styleUrls: ['./todo-comments-sorting.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoCommentsSortingComponent {
	@Output() onSortChange = new EventEmitter();
	currentSortMode = SortMode.OLDEST_FIRST;

	readonly icons = {
		arrow: faLongArrowAltRight
	};
	readonly availableSortModes = SortMode;

	public changeSorting(): void {
		this.currentSortMode = Math.abs(this.currentSortMode - 1);
		this.onSortChange.emit(this.currentSortMode);
	}
}
