import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmClickDirective } from '@app/shared/confirmation-dialog/confirm-click.directive';
import { ConfirmationDialogComponent } from '@app/shared/confirmation-dialog/confirmation-dialog.component';

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [
		ConfirmClickDirective,
		ConfirmationDialogComponent
	],
	exports: [
		ConfirmClickDirective,
		ConfirmationDialogComponent
	]
})
export class ConfirmationDialogModule {
}
