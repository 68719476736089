import { Pipe, PipeTransform } from '@angular/core';
import { GaUtils } from '@app/shared/ga-utils';

@Pipe({ name: 'textColor' })
export class TextColorPipe implements PipeTransform {

	constructor(private gaUtils: GaUtils) {
	}

	public transform(backgroundColor: string) {
		return this.gaUtils.determineTextColor(backgroundColor);
	}
}
