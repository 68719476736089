import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Todo } from '@app/shared/model/types/todo';

@Component({
	selector: 'todo-card-summary',
	templateUrl: 'todo-card-summary.component.html'
})
export class TodoCardSummaryComponent implements OnInit, OnChanges {

	@Input() originalSummary: string;
	@Output() onSave = new EventEmitter<Partial<Todo>>();

	summary: string;
	isEditing = false;
	isSummaryEmpty = false;

	public ngOnInit(): void {
		this.summary = this.originalSummary;
	}

	public ngOnChanges(): void {
		this.summary = this.originalSummary;
	}

	public editSummaryText(): void {
		this.isEditing = true;
	}

	public cancelEditSummary(): void {
		this.isEditing = false;
		this.summary = this.originalSummary;
	}

	public saveSummary() {
		if ((this.summary.trim()).length === 0) {
			this.isSummaryEmpty = true;
			setTimeout(() => {
				this.isSummaryEmpty = false;
			}, 3000);
			return;
		}
		this.isSummaryEmpty = false;
		this.onSave.emit({ 'summary': this.summary });
		this.isEditing = false;
	}
}
