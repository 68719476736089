import { Injectable } from '@angular/core';
import { TokenStorageService } from '@app/core/auth/token-storage.service';
import { GuestUser } from '@app/core/auth/types/guest-user';
import { Identity } from '@app/core/auth/types/identity';
import { LoggedUser } from '@app/core/auth/types/logged-user';
import { FeatureFlagService } from '@app/core/unleash/feature-flag.service';
import * as Sentry from '@sentry/angular-ivy';
import { AclRoleType } from '@app/core/security/acl-role-type';

@Injectable({
	providedIn: 'root'
})
export class IdentityProviderService {

	private identity: Identity;

	constructor(
		private credentialsStorage: TokenStorageService,
		private featureFlags: FeatureFlagService
	) {
		if (this.credentialsStorage.isTokenExpired()) {
			this.identity = new GuestUser();
		} else {
			this.identity = LoggedUser.fromJwtToken(this.credentialsStorage.getCredentialsData());
		}
	}

	public getIdentity(): Identity {
		return this.identity;
	}

	public createAuthenticatedIdentity() {
		this.identity = LoggedUser.fromJwtToken(this.credentialsStorage.getCredentialsData());
		this.setupSentryUser();
		this.setupFeatureFlagUser();
	}

	public clearAuthenticatedIdentity() {
		this.credentialsStorage.clear();
		this.identity = new GuestUser();
		this.setupSentryUser();
	}

	get isAdmin() {
		return [AclRoleType.SuperAdmin].includes(this.identity.roleType);
	}

	get isSalesRep() {
		return this.identity.salesRepresentative;
	}

	private setupSentryUser() {
		Sentry.setUser({ id: `${this.identity.id}`, username: this.identity.getFullName() });
	}

	private setupFeatureFlagUser() {
		this.featureFlags.setUser(this.identity.id.toString());
	}
}
