import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { ObjectStoreMeta } from 'ngx-indexed-db';

export const medicalFacilityDbConfig: ObjectStoreMeta = {
	store: UrlCodelistLink.MedicalFacilities,
	storeConfig: { keyPath: 'id', autoIncrement: false },
	storeSchema: [
		{ name: 'id', keypath: 'id', options: { unique: true } },
		{ name: 'active', keypath: 'active', options: { unique: false } },
		{ name: 'pursuing', keypath: 'pursuing', options: { unique: false } },
		{ name: 'addressLat', keypath: 'addressLat', options: { unique: false } },
		{ name: 'addressLng', keypath: 'addressLng', options: { unique: false } },
		{ name: 'city', keypath: 'city', options: { unique: false } },
		{ name: 'loaType', keypath: 'loaType', options: { unique: false } },
		{ name: 'medicalFacilityType', keypath: 'medicalFacilityType', options: { unique: false } },
		{ name: 'name', keypath: 'name', options: { unique: false } },
		{ name: 'rpCost', keypath: 'rpCost', options: { unique: false } },
		{ name: 'state', keypath: 'state', options: { unique: false } },
		{ name: 'street', keypath: 'street', options: { unique: false } },
		{ name: 'tier', keypath: 'tier', options: { unique: false } },
		{ name: 'zip', keypath: 'zip', options: { unique: false } },
	]
}
