import { Component, Input } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Component({
	selector: 'form-validation-label',
	templateUrl: './form-validation-label.component.html',
	styleUrls: ['./form-validation-label.component.scss']
})
export class FormValidationLabelComponent {

	@Input() control: AbstractControl;
	@Input() dataCy: string;
	readonly validators = Validators;
	readonly defaultDataCy = 'validation-label';
}
