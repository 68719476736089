import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';
import { DashboardDataUtilsService } from '@app/dashboard/dashboard-data-utils.service';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { DashboardBreakdownChartService } from '@app/dashboard/shared/breakdown-chart/dashboard-breakdown-chart-service';
import { BreakdownChartMode, ChartBreakdown, ChartBreakdownResponse, UserAccountBreakdownInfo } from '@app/dashboard/shared/breakdown-chart/dashboard-breakdown-chart.model';
import { OpenModalRendererComponent, OpenModalRendererParams } from '@app/dashboard/shared/open-modal-renderer.component';
import { ConfigurationChangedEvent } from '@app/dashboard/shared/time-related-configuration/time-related-configuration.model';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { CartesianGroupedTooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/cartesian-grouped-tooltip-theme.service';
import { chartNumberAxesTheme, chartPriceAxesTheme } from '@app/shared/ag-grid-utils/chart-themes/chart-axes-theme';
import { UserAccountCodelist } from '@app/shared/model/types/codelists/user-account-codelist';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { AgChartTheme, ChartRef, ColDef, ColGroupDef, GridReadyEvent } from 'ag-grid-community';
import { combineLatest, map, Observable, ReplaySubject, shareReplay, Subject, switchMap, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'dashboard-breakdown-chart',
	templateUrl: 'dashboard-breakdown-chart.component.html'
})
export class DashboardBreakdownChartComponent implements OnInit, OnDestroy {
	@Input() codelistUsers: Observable<UserAccountCodelist[]>;
	@Input() path: 'salesRepresentatives' | 'fundingConsultants';
	@Input() exportImageName: string;
	@Input() exportAclRule: string = null;
	@Input() exportExcelName: string;

	readonly faIcons = {
		table: faTable
	};

	readonly chartModes = BreakdownChartMode;
	mode = BreakdownChartMode.FundedAmount;

	rowData$: Observable<ChartBreakdown<typeof this.path>[]>;
	gridOptions;

	chart: ChartBreakdownResponse<typeof this.path>;

	private userAccounts: UserAccountCodelist[];
	private timeConfiguration$ = new ReplaySubject<ConfigurationChangedEvent>(1);
	private destroy$ = new Subject<void>();
	private tooltipTheme = new CartesianGroupedTooltipThemeService();
	private columnDefinitions: ColDef[] = [];
	private gridParams;
	private chartRef: ChartRef = null;
	private chartTheme: Record<string, AgChartTheme> = {
		breakdownChartTheme: {
			baseTheme: 'ag-pastel',
			palette: {
				fills: ['#c16068', '#ebcc87', '#b58dae', '#a2bf8a', '#80a0c3', '#85c0d1', '#FDE74C', '#009688', '#C3C3E6', '#533A71'],
				strokes: ['#874349', '#a48f5f', '#7f637a', '#718661', '#5a7088', '#5d8692', '#B1A235', '#00695f', '#8989a1', '#2a1d39']
			}
		}
	};

	constructor(
		private dashboardDataUtils: DashboardDataUtilsService,
		private dashboardFilterStore: DashboardFilterStore,
		private dataProvider: DashboardBreakdownChartService<'salesRepresentatives' | 'fundingConsultants'>,
		private agGridUtils: AgGridUtilsService,
		public acl: AclService) {
	}

	public ngOnInit(): void {
		this.gridOptions = this.agGridUtils.getGridOptions({
			defaultColDef: {
				width: 110
			},
			sideBar: {
				toolPanels: [
					{
						id: 'columns',
						labelDefault: 'Columns',
						labelKey: 'columns',
						iconKey: 'columns',
						toolPanel: 'agColumnsToolPanel',
						toolPanelParams: {
							suppressRowGroups: false,
							suppressValues: false,
							suppressPivots: true,
							suppressPivotMode: true,
							suppressColumnFilter: false,
							suppressColumnSelectAll: false,
							suppressColumnExpandAll: false,
						},
					},
					'filters'
				],
				defaultToolPanel: 'columns'
			},
			suppressExcelExport: this.exportAclRule === null ? false : !this.acl.isAllowed(this.exportAclRule),
			defaultExcelExportParams: {
				fileName: this.exportExcelName
			},
			customChartThemes: this.chartTheme,
			chartThemes: ['breakdownChartTheme', 'ag-pastel', 'ag-vivid', 'ag-material', 'ag-solar']
		});
		this.rowData$ = combineLatest([this.timeConfiguration$, this.dashboardFilterStore.fundingParams$])
			.pipe(
				switchMap((params) => this.dataProvider.getData$(...params)),
				map((data: ChartBreakdownResponse<typeof this.path>[]) => data.map(datum => {
					const userAccountMapping: Record<string, UserAccountBreakdownInfo> = {};
					datum[this.path].forEach(userAccount => {
						userAccountMapping[userAccount.id] = userAccount;
					});
					return {
						...datum,
						chartLabel: this.dashboardDataUtils.getPeriodLabel(datum),
						[this.path]: userAccountMapping,
					} as unknown as ChartBreakdown<typeof this.path>;
				})),
				tap(data => this.tooltipTheme.setTooltipData(data)),
				shareReplay({ bufferSize: 1, refCount: true })
			);
		this.codelistUsers.pipe(takeUntil(this.destroy$))
			.subscribe(userAccounts => {
				this.userAccounts = userAccounts;
				const columnDefinitions: ColDef[] = [
					{
						...this.dashboardDataUtils.getPeriodColDef(),
						headerName: '',
						pinned: 'left',
						filter: 'agSetColumnFilter',
						chartDataType: 'category',
						colId: 'title'
					}
			];

			userAccounts.forEach(userAccount => {
				columnDefinitions.push(this.getColumnDefForUserAccount(userAccount));
			});

			this.columnDefinitions = columnDefinitions;
			this.gridParams?.api.setColumnDefs(columnDefinitions);
			if (this.gridParams != undefined && this.chartRef === null) {
				this.toggleChartMode(this.mode);
			}
		});
	}

	private getColumnDefForUserAccount(userAccount): ColGroupDef {
		const getHeaderValue = (defaultLabel, location) => {
			if (location === 'chart') {
				return `${userAccount.firstName} ${userAccount.lastName}`;
			}
			return defaultLabel;
		}

		const fundedCountColId = `${this.chartModes.FundedCount}Col-${userAccount.id}`;
		const collectedCountColId = `${this.chartModes.CollectedCount}Col-${userAccount.id}`;

		const colDefs: ColDef[] = [
			{
				...this.tooltipTheme.addPriceColumn(
					getHeaderValue('', 'chart'),
					`${this.path}.[${userAccount.id}].fundedAmount`,
					`${this.chartModes.FundedAmount}Col-${userAccount.id}`
				),
				headerValueGetter: ({ location }) => getHeaderValue('$ Funded', location),
				valueGetter: ({ data }: { data: ChartBreakdown<typeof this.path> }) => {
					if (userAccount?.id == undefined) {
						return '0';
					}
					return data?.[this.path]?.[userAccount.id]?.fundedAmount ?? '0';
				},
				cellRenderer: OpenModalRendererComponent,
				cellRendererParams: <OpenModalRendererParams>{
					showLink: ({ node }) => this.gridParams.api.getValue(fundedCountColId, node) > 0,
					showDetail: params => this.dataProvider.openDetailAdvanced(params.data, userAccount)
				}
			},
			{
				...this.tooltipTheme.addPriceColumn(
					getHeaderValue('', 'chart'),
					`${this.path}.[${userAccount.id}].dealsAmountWithEffectiveRates`,
					`${this.chartModes.EffectiveRates}Col-${userAccount.id}`,
				),
				headerValueGetter: ({ location }) => getHeaderValue('$ Effective Rates', location),
				valueGetter: ({ data }: { data: ChartBreakdown<typeof this.path> }) => {
					if (userAccount?.id == undefined) {
						return 0;
					}
					return data?.[this.path]?.[userAccount.id]?.dealsAmountWithEffectiveRates ?? 0;
				},
				minWidth: 150,
				width: 150,
			},
			{
				...this.tooltipTheme.addNumberColumn(
					getHeaderValue('', 'chart'),
					`${this.path}.[${userAccount.id}].fundedCount`,
					fundedCountColId
				),
				headerValueGetter: ({ location }) => getHeaderValue('# Funded', location),
				valueGetter: ({ data }: { data: ChartBreakdown<typeof this.path> }) => {
					if (userAccount?.id == undefined) {
						return 0;
					}
					return data?.[this.path]?.[userAccount.id]?.fundedCount ?? 0;
				}
			},
			{
				...this.tooltipTheme.addNumberColumn(
					getHeaderValue('', 'chart'),
					`${this.path}.[${userAccount.id}].newCases`,
					`${this.chartModes.NewCases}Col-${userAccount.id}`
				),
				headerValueGetter: ({ location }) => getHeaderValue('New Cases', location),
				valueGetter: ({ data }: { data: ChartBreakdown<typeof this.path> }) => {
					if (userAccount?.id == undefined) {
						return 0;
					}
					return data?.[this.path]?.[userAccount.id]?.newCases ?? 0;
				}
			}
		];

		if (this.acl.isAllowed('dashboard.collected_stats')) {
			colDefs.splice(2, 0,
				{
					...this.tooltipTheme.addPriceColumn(
						getHeaderValue('', 'chart'),
						`${this.path}.[${userAccount.id}].collectedAmount`,
						`${this.chartModes.CollectedAmount}Col-${userAccount.id}`
					),
					headerValueGetter: ({ location }) => getHeaderValue('$ Collected', location),
					valueGetter: ({ data }: { data: ChartBreakdown<typeof this.path> }) => {
						if (!userAccount?.id) {
							return '0';
						}
						return data?.[this.path]?.[userAccount?.id]?.collectedAmount ?? '0';
					},
					cellRenderer: OpenModalRendererComponent,
					cellRendererParams: <OpenModalRendererParams>{
						showLink: ({ node }) => this.gridParams.api.getValue(collectedCountColId, node) > 0,
						showDetail: params => this.dataProvider.openDetailCollected(params.data, userAccount)
					}
				},
				{
					...this.tooltipTheme.addNumberColumn(
						getHeaderValue('', 'chart'),
						`${this.path}.[${userAccount.id}].collectedCount`,
						collectedCountColId
					),
					headerValueGetter: ({ location }) => getHeaderValue('# Collected', location),
					valueGetter: ({ data }) => {
						if (!userAccount?.id) {
							return 0;
						}
						return data?.[this.path]?.[userAccount.id]?.collectedCount ?? 0;
					}
				});
		}

		return {
			headerName: `${userAccount.firstName} ${userAccount.lastName}`,
			children: (colDefs as ColDef[])
		};
	}

	public toggleChartMode(mode: BreakdownChartMode): void {
		this.mode = mode;
		this.createChart(mode);
	}

	private createChart(mode: BreakdownChartMode): void {
		if (this.chartRef !== null) {
			this.chartRef.destroyChart();
		}
		const columnIds = this.userAccounts.map(userAccount => `${mode}Col-${userAccount.id}`);
		const visibleColIds = this.gridParams.columnApi.getAllDisplayedColumns().map(column => column.colId);
		const visibleUsers = this.userAccounts.filter(userAccount => visibleColIds.includes(`${mode}Col-${userAccount.id}`));
		this.chartRef = this.gridParams.api.createRangeChart({
			chartType: 'groupedColumn',
			cellRange: {
				columns: ['title', ...columnIds]
			},
			chartContainer: document.querySelector(`#${this.path}-breakdown-chart`),
			chartThemeName: 'breakdownChartTheme',
			chartThemeOverrides: this.getChartTheme(mode, visibleUsers)
		});

		this.tooltipTheme.setChart(this.chartRef.chart);
	}

	private getChartTheme(mode: BreakdownChartMode, userAccounts: UserAccountCodelist[]) {
		let axesTheme;
		switch (mode) {
			case BreakdownChartMode.CollectedAmount:
			case BreakdownChartMode.FundedAmount:
				axesTheme = chartPriceAxesTheme;
				break;
			default:
				axesTheme = chartNumberAxesTheme;
		}
		const colIds = userAccounts.map(salesRepresentative => {
			return `${this.path}.[${salesRepresentative.id}].${mode}`;
		});
		const groupedTooltipConfig = this.tooltipTheme.getGroupedTooltipTheme(colIds);

		return {
			...axesTheme(this.exportImageName),
			column: {
				series: {
					...groupedTooltipConfig
				}
			},
		};
	}

	public onConfigurationChanged(event: ConfigurationChangedEvent) {
		this.timeConfiguration$.next(event);
	}

	public onGridReady(params: GridReadyEvent): void {
		this.gridParams = params;
		this.gridParams.api.setColumnDefs(this.columnDefinitions);
		if (this.userAccounts && this.chartRef === null) {
			this.toggleChartMode(this.mode);
		}
	}

	//TODO this is necessary because of the bug in the AgGrid: AG-5749. Remove this after it's fixed.
	public recreateChart(): void {
		this.toggleChartMode(this.mode);
	}

	public ngOnDestroy() {
		this.chartRef?.destroyChart();
		this.destroy$.next();
		this.destroy$.complete();
	}

}
