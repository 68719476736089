import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DashboardDateValidator } from '@app/dashboard/shared/dashboard-widget-date-filter/dashboard-date-validator';
import { datePickerDefaultConfig } from '@app/shared/model/constants/date-picker-default-config';

@Component({
	selector: 'dashboard-widget-date-filter',
	templateUrl: 'dashboard-widget-date-filter.component.html',
})
export class DashboardWidgetDateFilterComponent implements OnInit {
	@Input() inputControl: FormControl<Date | null>;

	readonly bsDatePickerConfig = {
		...datePickerDefaultConfig,
		showClearButton: true,
		maxDate: new Date(),
		minDate: new Date(2015, 0, 1),
	};

	public ngOnInit(): void {
		const { minDate, maxDate } = this.bsDatePickerConfig;
		this.inputControl.addValidators(DashboardDateValidator.invalidDate(minDate, maxDate));
		this.inputControl.updateValueAndValidity({ emitEvent: false });
	}
}
