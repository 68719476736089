import { Inject, Injectable } from '@angular/core';
import { GaSelectEditorComponent } from '@app/shared/ga-components/components/editors/ga-select-editor/ga-select-editor.component';
import { GaSelectEditorParams } from '@app/shared/ga-components/components/editors/ga-select-editor.params';
import { GA_CONFIG, IGaConfig } from '@app/shared/ga-components/ga-components.config';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { GaDecoratorInterface } from '@app/shared/ga-components/utils/ga-decorator-interface';
import { getMatches } from '@app/shared/ga-components/utils/get-matches';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';

@Injectable()
export class ConditionalCodelistDecorator implements GaDecoratorInterface {

	constructor(
		@Inject(GA_CONFIG) private config: IGaConfig,
		private apiLink: GaApiLink,
	) {}

	public create(componentMetadata: ComponentMetadata): MetadataDecorator {
		const fieldList = getMatches(componentMetadata.fieldMetadata.association.toStringTemplate, this.config.templatePattern, 1);
		let sort = {};

		if (fieldList.indexOf('firstName') != -1) {
			sort = { firstName: 'ASC' };
		} else if (fieldList.indexOf('name') != -1) {
			sort = { name: 'ASC' };
		}

		const values = this.apiLink.retrieve(componentMetadata.fieldMetadata.association.targetEntity,
			fieldList, componentMetadata.fieldMetadata.constraints, sort);

		return {
			editor: GaSelectEditorComponent,
			editorParams: <GaSelectEditorParams>{
				onInput: () => {
					return values;
				},
				multiple: componentMetadata.fieldMetadata.association.isToMany(),
			}
		};
	}

}
