import { AfterViewChecked, Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';

@Directive({
	selector: '[gaEntity], ga-entity',
	exportAs: 'gaEntity',
	providers: [EntityScopeService]
})
export class GaEntityDirective implements OnChanges, OnInit, AfterViewChecked {
	@Input() entityClass;
	@Input() entity = null;
	@Input() entityId = null;

	constructor(
		public apiLink: GaApiLink,
		public entityService: EntityScopeService
	) {
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.entityService.entityClass = this.entityClass;
		if (changes.entity?.currentValue) {
			this.entityService.setEntity(changes.entity.currentValue);
		} else if (this.entityClass != null && changes.entityId?.currentValue) {
			this.entityService.setEntityId(changes.entityId.currentValue);
			this.entityService.retrieveData(changes.entityId.currentValue);
		}
	}

	public ngOnInit(): void {
		if (this.entity !== null && this.entityId) {
			throw Error("Only one of 'entity' and 'entityId' could be set.");
		}
		if (this.entityId) {
			this.entityService.setEntityId(this.entityId);
		}

		if (this.entity) {
			this.entityService.setEntity(this.entity);
		}
	}


	public ngAfterViewChecked(): void {
		this.entityService.synchronize();
	}
}
