import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeyboardNavigationService } from '@app/app-layout/app-header/smart-search/keyboard-navigation.service';
import { SmartSearchApplicant, SmartSearchDoctor, SmartSearchDoctorContact, SmartSearchItem, SmartSearchLawFirm, SmartSearchLawFirmStaff, SmartSearchMedicalFacility, SmartSearchResponse, SmartSearchSection, SmartSearchSectionState, SmartSearchState, SmartSearchVisibleItemsCounts } from '@app/app-layout/app-header/smart-search/smart-search.model';
import { SmartSearchService } from '@app/app-layout/app-header/smart-search/smart-search.service';
import { ResponsiveService } from '@app/core/startup/responsive.service';
import { fadeUpDown } from '@app/shared/animations/animations';
import { FundingHelperService } from '@app/shared/model/funding-helper.service';
import { faChevronLeft, faSearchLocation, faSpinner, faStar } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, merge, Subject, switchMap, takeUntil } from 'rxjs';

// TODO refactor template to use component composition
@Component({
	selector: 'smart-search',
	templateUrl: './smart-search.component.html',
	animations: [
		trigger('outerResize', [
			state(SmartSearchState.Expanded, style({ height: '*' })),
			state(SmartSearchState.Collapsed, style({ height: '0' })),
			transition(SmartSearchState.Collapsed + ' => ' + SmartSearchState.Void, [
				style({ height: '0' }),
				animate(300, style({ height: '*' }))
			]),
			transition(SmartSearchState.Void + ' => ' + SmartSearchState.Collapsed, [
				style({ height: '*' }),
				animate(300, style({ height: '0' }))
			])
		]),
		trigger('innerResize', [
			state(SmartSearchState.Expanded, style({ height: '*' })),
			transition(SmartSearchState.Void + ' => ' + SmartSearchState.Expanded, [
				style({ height: '0' }),
				animate(300, style({ height: '*' }))
			]),
			transition(SmartSearchState.Expanded + ' => ' + SmartSearchState.Void, [
				style({ height: '*' }),
				animate(300, style({ height: '0' }))
			]),
		]),
		fadeUpDown
	],
	styleUrls: ['smart-search.component.scss'],
	providers: [SmartSearchService, KeyboardNavigationService]
})
export class SmartSearchComponent implements OnInit, OnDestroy {
	exactMatch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	visibleItemsCounts = SmartSearchVisibleItemsCounts;
	state = SmartSearchState;
	section = SmartSearchSection;
	selectedItem: SmartSearchItem;
	sectionsState: SmartSearchSectionState;

	readonly faIcons = {
		spinner: faSpinner,
		chevronLeft: faChevronLeft,
		star: faStar,
		locationSearch: faSearchLocation
	};

	applicants: SmartSearchApplicant[] = [];
	lawFirmStaff: SmartSearchLawFirmStaff[] = [];
	lawFirms: SmartSearchLawFirm[] = [];
	doctors: SmartSearchDoctor[] = [];
	medicalFacilities: SmartSearchMedicalFacility[] = [];
	doctorContacts: SmartSearchDoctorContact[] = [];
	searchTerm$ = new Subject<string>();
	showSuggestions = false;
	inProgress = false;
	searchTerm = '';

	private destroy$ = new Subject<void>();

	constructor(
		private http: HttpClient,
		private router: Router,
		private keyboardNavService: KeyboardNavigationService,
		private smartSearchService: SmartSearchService,
		public responsiveService: ResponsiveService,
		public fundingHelper: FundingHelperService
	) {
	}

	createApplicantSearchItemCallback = (index: number) => this.smartSearchService.createApplicantSearchItem(this.applicants[index]);
	createLawFirmStaffSearchItemCallback = (index: number) =>
		this.smartSearchService.createLawFirmStaffSearchItem(this.lawFirmStaff[index]);
	createLawFirmSearchItemCallback = (index: number) => this.smartSearchService.createLawFirmSearchItem(this.lawFirms[index]);
	createDoctorSearchItemCallback = (index: number) => this.smartSearchService.createDoctorSearchItem(this.doctors[index]);
	createMedicalFacilitySearchItemCallback = (index: number) =>
		this.smartSearchService.createMedicalFacilitySearchItem(this.medicalFacilities[index]);
	createDoctorContactSearchItemCallback = (index: number) =>
		this.smartSearchService.createDoctorContactSearchItem(this.doctorContacts[index]);

	public ngOnInit(): void {
		this.sectionsState = this.smartSearchService.getDefaultAccordionsState();
		const searchTerm = this.searchTerm$.pipe(
			debounceTime(250),
			distinctUntilChanged(),
		);

		merge(this.exactMatch$, searchTerm).pipe(
			filter(() => this.searchTerm.trim().length >= 3),
			switchMap(() => {
				this.showSuggestions = true;
				this.inProgress = true;
				return this.http.get<SmartSearchResponse>('/api/smart-search', {
					params: {
						term: this.searchTerm,
						exactMatch: this.exactMatch$.value.toString()
					}
				});
			}),
			takeUntil(this.destroy$),
		).subscribe((response) => {
			this.applicants = response.applicants;
			this.lawFirmStaff = response.lawFirmStaff;
			this.lawFirms = response.lawFirms;
			this.medicalFacilities = response.medicalFacilities;
			this.doctors = response.doctors;
			this.doctorContacts = response.doctorContacts;
			const itemsConfig = {};
			itemsConfig[SmartSearchSection.Applicants] = response.applicants;
			itemsConfig[SmartSearchSection.LawFirmStaff] = response.lawFirmStaff;
			itemsConfig[SmartSearchSection.LawFirms] = response.lawFirms;
			itemsConfig[SmartSearchSection.Doctors] = response.doctors;
			itemsConfig[SmartSearchSection.MedicalFacilities] = response.medicalFacilities;
			itemsConfig[SmartSearchSection.DoctorContacts] = response.doctorContacts;
			this.keyboardNavService.setItems(itemsConfig);
			this.inProgress = false;
			this.selectFirstItem();
		});
	}

	public keyDown(event: KeyboardEvent): void {
		switch (event.key) {
			case 'Enter':
				if (this.smartSearchService.isApplicantSearchItem(this.selectedItem)) {
					this.router.navigate(['applicants', this.selectedItem.entity.id]);
					this.clean();
				} else if (this.smartSearchService.isLawFirmStaffSearchItem(this.selectedItem)) {
					const navigateCommands = this.selectedItem.entity?.lawFirm?.id ?
						['law-firms', this.selectedItem.entity.lawFirm.id, 'staff', this.selectedItem.entity.id] :
						['/staff', this.selectedItem.entity.id];
					this.router.navigate(navigateCommands);
					this.clean();
				} else if (this.smartSearchService.isLawFirmSearchItem(this.selectedItem)) {
					this.router.navigate(['law-firms', this.selectedItem.entity.id, 'detail']);
					this.clean();
				} else if (this.smartSearchService.isDoctorSearchItem(this.selectedItem)) {
					this.router.navigate(['doctors', this.selectedItem.entity.id]);
					this.clean();
				} else if (this.smartSearchService.isMedicalFacilityItem(this.selectedItem)) {
					this.router.navigate(['medical-facilities', this.selectedItem.entity.id]);
					this.clean();
				} else if (this.smartSearchService.isDoctorContactItem(this.selectedItem)) {
					this.router.navigate(['doctors', this.selectedItem.entity.id]);
					this.clean();
				} else if (this.selectedItem) {
					this.showSuggestions = true;
				}
				break;
			case 'Escape':
				this.selectedItem = null;
				this.showSuggestions = false;
				break;
			case 'ArrowUp':
				this.showSuggestions = true;
				this.goUp();
				break;
			case 'ArrowDown':
				this.showSuggestions = true;
				this.goDown();
				break;
		}
	}

	public goUp(): void {
		if (!this.selectedItem) {
			return;
		}
		if (this.smartSearchService.isApplicantSearchItem(this.selectedItem)) {
			this.selectedItem = this.keyboardNavService.moveUp(
				SmartSearchSection.DoctorContacts,
				this.createApplicantSearchItemCallback
			);
		} else if (this.smartSearchService.isLawFirmStaffSearchItem(this.selectedItem)) {
			this.selectedItem = this.keyboardNavService.moveUp(SmartSearchSection.Applicants, this.createLawFirmStaffSearchItemCallback);
		} else if (this.smartSearchService.isLawFirmSearchItem(this.selectedItem)) {
			this.selectedItem = this.keyboardNavService.moveUp(SmartSearchSection.LawFirmStaff, this.createLawFirmSearchItemCallback);
		} else if (this.smartSearchService.isDoctorSearchItem(this.selectedItem)) {
			this.selectedItem = this.keyboardNavService.moveUp(SmartSearchSection.LawFirms, this.createDoctorSearchItemCallback);
		} else if (this.smartSearchService.isMedicalFacilityItem(this.selectedItem)) {
			this.selectedItem = this.keyboardNavService.moveUp(SmartSearchSection.Doctors, this.createMedicalFacilitySearchItemCallback);
		} else if (this.smartSearchService.isDoctorContactItem(this.selectedItem)) {
			this.selectedItem =
				this.keyboardNavService.moveUp(SmartSearchSection.MedicalFacilities, this.createDoctorContactSearchItemCallback);
		} else {
			this.selectedItem = this.keyboardNavService.trySelectPreviousLastItem(SmartSearchSection.DoctorContacts);
		}
	}

	public goDown(): void {
		if (!this.selectedItem) {
			return;
		}
		if (this.smartSearchService.isApplicantSearchItem(this.selectedItem)) {
			this.selectedItem = this.keyboardNavService.moveDown(
				SmartSearchSection.LawFirmStaff,
				this.createApplicantSearchItemCallback,
				this.applicants.length,
				SmartSearchVisibleItemsCounts.Applicants
			);
		} else if (this.smartSearchService.isLawFirmStaffSearchItem(this.selectedItem)) {
			this.selectedItem = this.keyboardNavService.moveDown(
				SmartSearchSection.LawFirms,
				this.createLawFirmStaffSearchItemCallback,
				this.lawFirmStaff.length,
				SmartSearchVisibleItemsCounts.LawFirmStaff
			);
		} else if (this.smartSearchService.isLawFirmSearchItem(this.selectedItem)) {
			this.selectedItem = this.keyboardNavService.moveDown(
				SmartSearchSection.Doctors,
				this.createLawFirmSearchItemCallback,
				this.lawFirms.length,
				SmartSearchVisibleItemsCounts.LawFirms
			);
		} else if (this.smartSearchService.isDoctorSearchItem(this.selectedItem)) {
			this.selectedItem = this.keyboardNavService.moveDown(
				SmartSearchSection.MedicalFacilities,
				this.createDoctorSearchItemCallback,
				this.doctors.length,
				SmartSearchVisibleItemsCounts.Doctors
			);
		} else if (this.smartSearchService.isMedicalFacilityItem(this.selectedItem)) {
			this.selectedItem = this.keyboardNavService.moveDown(
				SmartSearchSection.DoctorContacts,
				this.createMedicalFacilitySearchItemCallback,
				this.medicalFacilities.length,
				SmartSearchVisibleItemsCounts.MedicalFacilities
			);
		} else if (this.smartSearchService.isDoctorContactItem(this.selectedItem)) {
			this.selectedItem = this.keyboardNavService.moveDown(
				SmartSearchSection.Applicants,
				this.createDoctorContactSearchItemCallback,
				this.doctorContacts.length,
				SmartSearchVisibleItemsCounts.DoctorContacts
			);
		} else {
			this.selectedItem = this.keyboardNavService.trySelectNextFirstItem(SmartSearchSection.Applicants);
		}
	}

	public open(event: Event): void {
		this.showSuggestions = true;
		event.stopPropagation();
	}

	public close(): void {
		this.showSuggestions = false;
	}

	public isSelected(item): boolean {
		return this.selectedItem?.entity === item;
	}

	public toggleAccordion(selectedSection: SmartSearchSection): void {
		if (this.sectionsState[selectedSection] === SmartSearchState.Void) {
			this.sectionsState = this.smartSearchService.expandSection(selectedSection);
		} else {
			this.sectionsState = this.smartSearchService.getDefaultAccordionsState();
		}
	}

	public selectFirstItem(): void {
		if (this.applicants.length > 0) {
			this.selectedItem = this.smartSearchService.createApplicantSearchItem(this.applicants[0]);
		} else if (this.lawFirmStaff.length > 0) {
			this.selectedItem = this.smartSearchService.createLawFirmStaffSearchItem(this.lawFirmStaff[0]);
		} else if (this.lawFirms.length > 0) {
			this.selectedItem = this.smartSearchService.createLawFirmSearchItem(this.lawFirms[0]);
		} else if (this.doctors.length > 0) {
			this.selectedItem = this.smartSearchService.createDoctorSearchItem(this.doctors[0]);
		} else if (this.medicalFacilities.length > 0) {
			this.selectedItem = this.smartSearchService.createMedicalFacilitySearchItem(this.medicalFacilities[0]);
		} else if (this.doctorContacts.length > 0) {
			this.selectedItem = this.smartSearchService.createDoctorContactSearchItem(this.doctorContacts[0]);
		}
	}

	public onSwitched(): void {
		this.exactMatch$.next(!this.exactMatch$.value);
	}

	public clean(): void {
		this.close();
		// I need to clear distinctUntilChanged()
		// so you can type "ram" - click on 1 of results - and type "ram" again
		// it works with copy - paste as well
		// !! filter() needs to be applied after distinctUntilChanged()
		this.searchTerm$.next(' ');
		this.selectedItem = null;
		this.searchTerm = '';
		this.lawFirmStaff = [];
		this.applicants = [];
		this.lawFirms = [];
		this.doctors = [];
		this.medicalFacilities = [];
		this.doctorContacts = [];
		this.sectionsState = this.smartSearchService.getDefaultAccordionsState();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
