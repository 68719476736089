import { AfterViewInit, Directive, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { FormScopeService } from '@app/shared/ga-components/services/form-scope.service';
import { GaMetadataAdapterService } from '@app/shared/ga-components/services/ga-metadata-adapter.service';
import { FormControlValidationStatus } from '@app/shared/ga-components/utils/form-control-validation-status';
import { FormScopeSavingProgress, FormScopeSavingProgressHelper } from '@app/shared/ga-components/utils/form-scope-saving-progress';
import { Hook } from '@app/shared/ga-components/utils/hook';
import * as _ from 'lodash';
import { EMPTY, Observable, Subject, switchMap } from 'rxjs';
import { first, startWith, takeUntil, tap } from 'rxjs/operators';

@Directive()
export abstract class FormScopeProviderDirective implements AfterViewInit, OnInit, OnDestroy {
	@Input() extraFields = [];
	@Input() readOnlyFields = [];
	@Output() onSuccess = new EventEmitter<any>();

	private _editMode = false;
	private _savingProgress$ = new Subject<FormScopeSavingProgress>();
	private destroy$ = new Subject<void>();

	get editMode() {
		return this._editMode;
	}

	set editMode(isEdit: boolean) {
		this._editMode = isEdit;
		if (this.entityScope.entity && isEdit) {
			this.formScope.resetForm();
			this.formScope.formGroup.patchValue(this.entityScope.entity);
			this.registerExtraFields();
		}
	}

	constructor(
		@Inject(forwardRef(() => EntityScopeService)) public entityScope: EntityScopeService,
		protected fieldMetadataService: GaMetadataAdapterService,
		public formScope: FormScopeService,
		public savingProgressHelper: FormScopeSavingProgressHelper) {
	}

	public ngOnInit(): void {
		_.each(this.extraFields, (field) => {
			const extraFieldMetadata = this.fieldMetadataService.getMetadata(this.entityScope.entityClass, field);
			this.entityScope.addField(extraFieldMetadata.dependencies);
			this.formScope.addGaGroupDependencies(extraFieldMetadata.dependencies);
		});
		_.each(this.readOnlyFields, (field) => {
			const readOnlyFieldMetadata = this.fieldMetadataService.getMetadata(this.entityScope.entityClass, field);
			this.entityScope.addField(readOnlyFieldMetadata.dependencies);
			this.formScope.addGaGroupDependencies(readOnlyFieldMetadata.dependencies);
		});
	}

	public ngAfterViewInit(): void {
		_.each(this.extraFields, (field) => {
			const path = field.split('.');
			const controlName = path.pop();
			if (path.length == 0) {
				this.formScope.formGroup.addControl(controlName, new UntypedFormControl());
			} else {
				const formGroup = (<UntypedFormGroup>this.formScope.formGroup.get(path));
				// @todo FIXME formGroup variable doesn't contain method 'addControl'
				if (formGroup) {
					formGroup.addControl(controlName, new UntypedFormControl());
				}
			}
		});

		if (this.entityScope.entity) {
			this.formScope.formGroup.patchValue(this.entityScope.entity);
		}
	}

	public save(hooks: Hook[] = [], responseFieldsOverride: string[] = [], payloadFilter: (value: any) => void = (val) => val) {
		this._savingProgress$.next(FormScopeSavingProgress.OnSubmit);
		this.touchAndUpdate(this.formScope.formGroup);

		this.formScope.formGroup.statusChanges.pipe(
			startWith(this.formScope.formGroup.status),
			tap((status: FormControlValidationStatus) => this._savingProgress$.next(this.savingProgressHelper.getSavingProgress(status))),
			first((status: FormControlValidationStatus) => status !== FormControlValidationStatus.Pending),
			switchMap((formStatus: string) => {
				if (formStatus === FormControlValidationStatus.Valid) {
					return this.formScope.save(hooks, responseFieldsOverride, payloadFilter);
				}
				return EMPTY;
			}),
			takeUntil(this.destroy$)
		).subscribe({
			next: (data) => {
				this.onSuccess.emit(data);
				this._savingProgress$.next(FormScopeSavingProgress.OnSuccess);
			},
			error: (error: unknown) => {
				this._savingProgress$.next(FormScopeSavingProgress.OnError);
				console.error(error);
			}
		});
	}

	public setFormValue(value) {
		this.formScope.formGroup.patchValue(value);
	}

	public getFormControl(path) {
		return this.formScope.formGroup.get(path);
	}

	public getChanges$(): Observable<any> {
		return this.formScope.formGroup.valueChanges;
	}

	private registerExtraFields() {
		_.each(this.extraFields, (field) => {
			this.entityScope.addField([field]);
		});
		_.each(this.extraFields, (field) => {
			this.formScope.formGroup.addControl(field, new UntypedFormControl());
		});
	}

	private touchAndUpdate(control: AbstractControl): void {
		control.markAsTouched();
		control.updateValueAndValidity();
		if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
			_.each(control.controls, (abstractControl: AbstractControl) => {
				this.touchAndUpdate(abstractControl);
			});
		}
	}

	get savingProgress$(): Observable<FormScopeSavingProgress> {
		return this._savingProgress$.asObservable();
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
