import { Injectable } from '@angular/core';
import { GaDecoratorInterface } from '@app/shared/ga-components/utils/ga-decorator-interface';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';
import { CurrencyHelperService } from '@app/shared/model/currency-helper.service';

@Injectable()
export class CurrencyDecorator implements GaDecoratorInterface {

	constructor(private currencyHelper: CurrencyHelperService) {
	}

	public create(): MetadataDecorator {
		return {
			valueFormatter: (value) => {
				return this.currencyHelper.formatCurrency(value);
			},
			valueParser: value => {
				return this.currencyHelper.parseCurrency(value);
			}
		};
	}
}
