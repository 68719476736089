import { Injectable } from '@angular/core';
import { RevisionType } from '@app/core/revisions/types/revision-type';
import { StorageKeys } from '@app/core/storage-keys';

@Injectable({
	providedIn: 'root'
})
export class RevisionStorageService {
	private revisions = new Map<RevisionType, string>();

	constructor() {
		if (localStorage.getItem(StorageKeys.RevisionStorageKey)) {
			this.revisions = new Map<RevisionType, string>(JSON.parse(localStorage.getItem(StorageKeys.RevisionStorageKey)));
		}
	}

	public storeRevision(type: RevisionType, newValue: string) {
		this.revisions.set(type, newValue);

		this.persistRevisions();
	}

	public isRevisionUpToDate(type: RevisionType, value: string): boolean {
		return this.revisions.has(type) && this.revisions.get(type) === value;
	}

	public getRevision(type: RevisionType): string {
		return this.revisions.get(type);
	}

	private persistRevisions() {
		localStorage.setItem(StorageKeys.RevisionStorageKey, JSON.stringify(Array.from(this.revisions.entries())));
	}
}
