import { LicenseManager } from 'ag-grid-enterprise';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import * as Sentry from "@sentry/angular-ivy";

Sentry.init({
	dsn: environment.sentry.dsn,
	environment: environment.production ? 'production' : 'dev',
	release: environment.envVar.SENTRY_RELEASE,
	integrations: [
		new Sentry.BrowserTracing({
			tracePropagationTargets: environment.sentry.origins,
			routingInstrumentation: Sentry.routingInstrumentation,
		}),
	],
	tracesSampleRate: environment.sentry.sampleRate,
});

if (environment.production) {
	enableProdMode();
}

LicenseManager.setLicenseKey(environment.agGridLicence);
platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.error(err));
