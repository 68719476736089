import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'timeLeft' })
export class TimeLeftPipe implements PipeTransform {

	public transform(dateString: string | null, showExtended = true, showToday = false) {
		if (dateString) {
			const inputDate = moment(dateString);
			const timeAgo = `${moment(dateString).fromNow()}`;
			if (showToday && inputDate.isSame(moment().startOf('day'), 'day')) {
				return 'today';
			}
			return showExtended ? `${timeAgo} (${inputDate.format('MM/DD/YYYY h:mm a')})` : timeAgo;
		}
		return 'N/A';
	}

}
