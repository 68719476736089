import { Inject, Injectable } from '@angular/core';
import { FieldsDefinitionService } from '@app/core/fields-definition/fields-definition.service';
import { CodelistDecorator } from '@app/shared/ga-components/decorators/codelist.decorator';
import { ConditionalCodelistDecorator } from '@app/shared/ga-components/decorators/conditional-codelist.decorator';
import { CurrencyDecorator } from '@app/shared/ga-components/decorators/currency.decorator';
import { PercentageDecorator } from '@app/shared/ga-components/decorators/percentage.decorator';
import { GA_CONFIG, IGaConfig } from '@app/shared/ga-components/ga-components.config';
import { DecoratorProviderService } from '@app/shared/ga-components/services/decorator-provider.service';
import { GaResolverService } from '@app/shared/ga-components/services/ga-resolver.service';
import { AssociationMetadata } from '@app/shared/ga-components/utils/association-metadata';
import { EntityMetadata } from '@app/shared/ga-components/utils/entity-metadata';
import { FieldMetadata } from '@app/shared/ga-components/utils/field-metadata';
import { getMatches } from '@app/shared/ga-components/utils/get-matches';

@Injectable({ providedIn: 'root' })
export class GaMetadataAdapterService {

	private fieldDefinitionObject;

	/**
	 * TODO: until we create own metadata directly from doctirne mapping, will use this class as an adapter for fields definition     *
	 */
	constructor(
		private fieldsDefinitionService: FieldsDefinitionService,
		private decoratorProvider: DecoratorProviderService,
		private gaResolverService: GaResolverService,
		@Inject(GA_CONFIG) private config: IGaConfig
	) {
		this.fieldDefinitionObject = this.fieldsDefinitionService.getFieldsDefinitionObject();
	}

	public getMetadata(entityClass: string, fieldName: string): FieldMetadata {
		const fieldDefinition = this.getFieldDefinition(entityClass, fieldName);
		let dependencies = [fieldName]; //initialy set field itself as dependency (virtual field could have more dependencies)

		let association = null;
		if (fieldDefinition.ormType === 'association') {
			dependencies = []; // no dependencies are set for association by default (either set more by decorators, or toStringTemplate)
			association = new AssociationMetadata({ ...fieldDefinition.ormAssociation, toStringTemplate: '<%=id=%>' });
			const associationClassMetadata = this.getClassMetadata(association.targetEntity);
			if (associationClassMetadata) {
				association.toStringTemplate = associationClassMetadata.toStringTemplate;
				dependencies = getMatches(association.toStringTemplate, this.config.templatePattern, 1);
			}
		}

		let decorator = null;
		let decoratorParams = null
		if (fieldDefinition.codelist) {
			if (fieldDefinition.codelist.isCodelist) {
				decorator = CodelistDecorator;
			} else if (fieldDefinition.codelist.isConditionalCodelist) {
				decorator = ConditionalCodelistDecorator;
			}
		} else if (fieldDefinition.currency) {
			decorator = CurrencyDecorator;
		} else if (fieldDefinition.percentage) {
			decorator = PercentageDecorator;
			decoratorParams = fieldDefinition.percentage;
		}

		const metadata: FieldMetadata = {
			fieldName: fieldDefinition.id,
			entity: fieldDefinition.entityClass,
			type: fieldDefinition.ormType,
			label: fieldDefinition.title,
			readOnly: fieldDefinition.readOnly || false,
			isCodelist: (fieldDefinition.codelist && fieldDefinition.codelist.isCodelist) || false,
			condition: () => true,
			dependencies: dependencies,
			sort: [],
			search: [],
			association: association,
			decorator: decorator,
			decoratorParams: decoratorParams,
			validators: fieldDefinition.validation || [],
			constraints: fieldDefinition.constraints || {},
		};

		metadata.toString = this.gaResolverService.resolveToStringFn(metadata);

		return metadata;
	}

	public getClassMetadata(entityClass: string): EntityMetadata {
		const serverDefinitions = this.fieldsDefinitionService.getServerFieldsDefinitions();

		if (!serverDefinitions[entityClass]) {
			return null;
		}

		const metadata = new EntityMetadata();
		metadata.title = serverDefinitions[entityClass].name;
		metadata.shortName = entityClass;
		metadata.className = serverDefinitions[entityClass].className;
		metadata.toStringTemplate = serverDefinitions[entityClass].toStringTemplate;
		return metadata;
	}

	public getFieldDefinition(entityClass: string, fieldPath: string) {
		if (!this.fieldDefinitionObject[entityClass]) {
			console.warn('There are no fields definitions for: ', entityClass);
			return false;
		}
		return this.locateFieldDefinition(this.fieldDefinitionObject[entityClass], fieldPath.split('.'));
	}

	private locateFieldDefinition(entityDefinitionObject: any, fieldPath: string[]) {
		const fieldDefinition = entityDefinitionObject[fieldPath[0]];
		if (fieldDefinition) {
			if (fieldPath.length === 1) {
				return fieldDefinition;
			}
			return this.locateFieldDefinition(this.fieldDefinitionObject[fieldDefinition.targetEntity], fieldPath.slice(1));
		}
		return false;
	}

}
