export class UseCase {

	private constructor(private _names: string[]) {}

	public static for(...names: string[]) {
		return new this(names);
	}

	get useCases() {
		return this._names;
	}
}
