import { Component, Input } from '@angular/core';
import { DoctorAddress } from '@app/shared/model/types/doctor-address';

@Component({
	selector: 'doctor-address',
	templateUrl: 'doctor-address.component.html'
})
export class DoctorAddressComponent {
	@Input() address: DoctorAddress;
}
