import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { DefaultRouteGuard } from '@app/core/default-route.guard';
import { environment } from '@env/environment';

export const routes: Routes = [
	{ path: '', component: AppComponent, canActivate: [AuthGuard, DefaultRouteGuard] },
	{
		path: 'applicants',
		canActivate: [AuthGuard],
		loadChildren: () => import('./applicant/applicant.module').then(m => m.ApplicantModule)
	},
	{
		path: 'employees',
		canActivate: [AuthGuard],
		loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule)
	},
	{
		path: 'doctors',
		canActivate: [AuthGuard],
		loadChildren: () => import('./doctors/doctors.module').then(m => m.DoctorsModule)
	},
	{
		path: 'insurance-companies',
		canActivate: [AuthGuard],
		loadChildren: () => import('./insurance-companies/insurance-companies.module').then(m => m.InsuranceCompaniesModule)
	},
	{
		path: 'logs',
		canActivate: [AuthGuard],
		loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule)
	},
	{
		path: 'portfolio',
		canActivate: [AuthGuard],
		loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule)
	},
	{
		path: 'profile',
		canActivate: [AuthGuard],
		loadChildren: () => import('./user-account/user-account.module').then(m => m.UserAccountModule)
	},
	{
		path: 'security',
		canActivate: [AuthGuard],
		loadChildren: () => import('./security/security.module').then(m => m.SecurityModule)
	},
	{
		path: 'servicing',
		canActivate: [AuthGuard],
		loadChildren: () => import('./servicing/servicing.module').then(m => m.ServicingModule)
	},
	{
		path: 'settings',
		canActivate: [AuthGuard],
		loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
	},
	{
		path: 'boards',
		canActivate: [AuthGuard],
		loadChildren: () => import('./w-boards/boards.module').then(m => m.BoardsModule)
	},
	{
		path: 'metrics',
		canActivate: [AuthGuard],
		loadChildren: () => import('./metrics/metrics.module').then(m => m.MetricsModule)
	},
	{
		path: 'law-firms',
		canActivate: [AuthGuard],
		loadChildren: () => import('./law-firms/law-firms.module').then(m => m.LawFirmsModule)
	},
	{
		path: 'unassigned-attorneys',
		canActivate: [AuthGuard],
		loadChildren: () => import('./unassigned-attorneys/unassigned-attorneys.module').then(m => m.UnassignedAttorneysModule)
	},
	{
		path: 'medical-facilities',
		canActivate: [AuthGuard],
		loadChildren: () => import('./medical-facilities/medical-facilities.module').then(m => m.MedicalFacilitiesModule)
	},
	{
		path: 'practice-groups',
		canActivate: [AuthGuard],
		loadChildren: () => import('./practice-groups/practice-groups.module').then(m => m.PracticeGroupsModule)
	},
	{
		path: 'reporting',
		canActivate: [AuthGuard],
		loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
	},
	{ path: '**', redirectTo: '/' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		enableTracing: environment.enabledRouteTracing
	})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
