import { Type } from '@angular/core';
import { GaEditorParams } from '@app/shared/ga-components/components/editors/ga-editor.params';
import { GaTextRendererComponent } from '@app/shared/ga-components/components/renderers/ga-text-renderer.component';
import { FieldMetadata } from '@app/shared/ga-components/utils/field-metadata';
import { Hook } from '@app/shared/ga-components/utils/hook';
import { IEditor } from '@app/shared/ga-components/utils/IEditor';
import { IRenderer } from '@app/shared/ga-components/utils/IRenderer';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';
import * as _ from 'lodash';

export class ComponentMetadata implements MetadataDecorator {
	path: string;
	fieldName: string;
	entityClass: string;
	label: string;
	isCollection: boolean;
	isEditableInGrid = false;
	gridPostEditHooks: Hook[];
	dependencies: string[];
	defaultHidden = false;
	filter: string | boolean;
	fieldMetadata: FieldMetadata;
	sort: string = null;
	filterValueGetter: (obj) => unknown;
	valueSetter: (obj) => unknown;
	valueFormatter: (obj) => unknown;
	valueParser: (obj) => unknown;
	cellRenderer: (obj) => unknown;
	tooltip: (obj) => unknown;
	comparator: (obj) => unknown;
	allowUpdateRewrite = false;
	comparatorField: string;
	cellClass: string[];
	cellStyle: any = {};
	width: number;
	autoHeight: boolean;
	rendererParams: any = {};
	editorParams: GaEditorParams = new GaEditorParams();
	validators = [];
	asyncValidators = [];
	renderer: Type<IRenderer> = GaTextRendererComponent;
	editor: Type<IEditor>;
	dataCy?: string;

	constructor(params) {
		_.merge(this, params);
	}

	editRequirements = () => '';
	valueGetter = (dataObj) => _.get(dataObj, this.path);
}
