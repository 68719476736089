export enum ApprovalStatus {
	Pending = 0,
	Approved = 1,
	Funded = 2,
	Rejected = 3,
	Settled = 4,
	Consult = 5,
}

export const approvalStatusClass = {
	[ApprovalStatus.Pending] : 'pending',
	[ApprovalStatus.Approved]: 'approved',
	[ApprovalStatus.Funded]: 'funded',
	[ApprovalStatus.Rejected]: 'rejected',
	[ApprovalStatus.Settled]: 'settled',
	[ApprovalStatus.Consult]: 'consult',
};
