import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { merge, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class TitleService {
	private title$ = new Subject<string>();
	private readonly aggregatedTitle: Observable<string>;
	private lastTitle = '';

	constructor(private router: Router) {
		this.aggregatedTitle = this.getMergedTitleObservable();
	}

	private getMergedTitleObservable(): Observable<string> {
		return merge(
			this.router.events.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => {
					let route = this.router.routerState.root.firstChild;

					while (route.firstChild) {
						route = route.firstChild;
					}

					return route.snapshot.data.title ?? this.lastTitle;
				})
			),
			this.title$.asObservable()
		).pipe(
			filter(title => title != ''),
			shareReplay({ bufferSize: 1, refCount: true })
		);
	}

	get title(): Observable<string> {
		return this.aggregatedTitle;
	}

	public setTitle(title: string) {
		this.lastTitle = title;
		this.title$.next(title);
	}
}
