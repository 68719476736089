import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInOut, fadeUpDown, heightGrow } from '@app/shared/animations/animations';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { EntityId } from '@app/shared/model/types/entity-id';
import { UserAccount } from '@app/shared/model/types/user-account';

@Component({
	selector: 'todo-assignees',
	templateUrl: './todo-assignees.component.html',
	animations: [
		fadeUpDown,
		heightGrow,
		fadeInOut,
	],
})
export class TodoAssigneesComponent {

	@Input() wrapperClass: string;
	@Input() assignees: UserAccount[];
	@Input() todoId: EntityId;

	@Output() assigneesChanged = new EventEmitter<UserAccount[]>();

	showAssigneeList = false;

	constructor(
		private apiLink: GaApiLink) {
	}

	public closeAssigneeList(): void {
		this.showAssigneeList = false;
	}

	public toggleAssigneeList(): void {
		this.showAssigneeList = !this.showAssigneeList;
	}

	public reAssignTodo(assigneeToManage: UserAccount): void {
		const updatedAssignees = [...this.assignees];
		const assigneeIndex = updatedAssignees.findIndex(item => item.id == assigneeToManage.id);
		if (assigneeIndex != -1) {
			if (this.assignees.length === 1) {
				return;
			}
			updatedAssignees.splice(assigneeIndex, 1);
		} else {
			updatedAssignees.push(assigneeToManage);
		}

		const ids = updatedAssignees.map(assignee => assignee.id);

		this.apiLink.update(
			'todo',
			{ id: this.todoId },
			{ assignees: ids },
			[], [], [])
			.subscribe(() => {
				this.assigneesChanged.emit(updatedAssignees);
			});
	}
}
