<table class="table-striped">
	<thead>
	<tr>
		<th><ng-content></ng-content></th>
		<th class="number">Settled</th>
		<th class="number">Collected</th>
		<th class="number">Reduced</th>
		<th class="number">Rdc. %</th>
	</tr>
	</thead>
	<tbody>
	<tr>
		<th>Principal</th>
		<td class="number">
			<ghost-element
				[width]="ghostElementWidths.numberContent">{{ (entry?.principal ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
		</td>
		<td class="number">
			<ghost-element
				[width]="ghostElementWidths.numberContent">{{ (entry?.principalCollected ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
		</td>
		<td class="number">
			<ghost-element [width]="ghostElementWidths.numberContent">
				<strong>{{ (entry?.principalLoss ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</strong>
			</ghost-element>
		</td>
		<td class="number">
			<ghost-element
				[width]="ghostElementWidths.multiplier">{{ (entry?.principalLossPct ?? 0) | number : '1.0-0' }}%
			</ghost-element>
		</td>
	</tr>
	<tr>
		<th>Fee</th>
		<td class="number">
			<ghost-element
				[width]="ghostElementWidths.numberContent">{{ (entry?.feeSettled ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
		</td>
		<td class="number">
			<ghost-element
				[width]="ghostElementWidths.numberContent">{{ (entry?.feeCollected ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
		</td>
		<td class="number">
			<ghost-element [width]="ghostElementWidths.numberContent">
				<strong>{{ (entry?.feeLoss ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</strong>
			</ghost-element>
		</td>
		<td class="number">
			<ghost-element [width]="ghostElementWidths.multiplier">{{ (entry?.feeLossPct ?? 0) | number : '1.0-0' }}%
			</ghost-element>
		</td>
	</tr>
	<tr>
		<th>Total</th>
		<td class="number">
			<ghost-element
				[width]="ghostElementWidths.numberContent">{{ (entry?.faceValue ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
		</td>
		<td class="number">
			<ghost-element
				[width]="ghostElementWidths.numberContent">{{ (entry?.settledAt ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
		</td>
		<td class="number">
			<ghost-element [width]="ghostElementWidths.numberContent">
				<strong>{{ (entry?.loss ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</strong>
			</ghost-element>
		</td>
		<td class="number">
			<ghost-element [width]="ghostElementWidths.multiplier">{{ (entry?.lossPct ?? 0) | number : '1.0-0' }}%
			</ghost-element>
		</td>
	</tr>
	</tbody>
</table>
