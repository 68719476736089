<div class="new-modal" data-cy="applicantMap-modal">
	<div class="top-bar">
		<medical-markers-map-doctor-filter
			(onDoctorMarkersCreated)="setMarkers($event, true)"></medical-markers-map-doctor-filter>
		<medical-markers-map-medical-facility-filter
			(onMedicalFacilityMarkersCreated)="setMarkers($event, false)"></medical-markers-map-medical-facility-filter>
	</div>
	<google-map [center]="center" [zoom]="10" [options]="{maxZoom}" width="100%" height="700px">
		<ng-container *ngrxLet="markers$; let markers">
			<map-marker-clusterer [imagePath]="markerClustererImagePath"
								  (clusterClick)="onClusterClick($event, markers)">
				<map-marker *ngFor="let marker of markers"
							#markerScope="mapMarker"
							[position]="marker.position"
							[label]="marker.label"
							[options]="marker.options"
							(mapClick)="openInfoWindow(markerScope, marker)">
				</map-marker>
			</map-marker-clusterer>
		</ng-container>
		<map-info-window>
			<ng-container *ngrxLet="selectedMarkers$; let markers">
				<div *ngFor="let marker of markers" class="address">
					<h4>{{marker.label.text}}</h4>
					<span>{{marker.street}}</span><br>
					<span>{{marker.city}}</span><br>
					<span>{{marker.stateCode}}</span><br>
					<span>{{marker.zip}}</span>
				</div>
			</ng-container>
		</map-info-window>
	</google-map>
</div>
