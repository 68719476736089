<div class="modal-body modal-sm" style="overflow: visible">
	<h3 class="m-t-none m-b" style="text-align: center">Report Options</h3>
	<div class="row">
		<div class="col-md-12 m-b">
			<h4>Date Range</h4>
			<daterange-picker [rangesEmitter]="ranges$" (dateRangeSelected)="setDateRange($event)" style="min-width: 400px;"></daterange-picker>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12 managers-wrapper m-b" (clickOutside)="hidesalesRepresentativesList()">
			<span>
				<label>New Business Reps</label>
				<div class="select">
					<span (click)="selectAll()"><fa-icon [icon]="faIcons.checkSquare"></fa-icon> Select All</span>&nbsp;
					<span (click)="selectNone()"><fa-icon [icon]="faIcons.square"></fa-icon> Select None</span>
				</div>
			</span>
			<div class="sidebar-section__content multiple">
				<div *ngFor="let salesRepresentative of config.salesRepresentatives" class="new-label">{{ salesRepresentative.firstName }} {{ salesRepresentative.lastName }}</div>
			</div>
			<input class='base-input' placeholder="Select New Business Reps" readonly (click)="togglesalesRepresentativesList()">
			<select-list
				[items]="salesRepresentatives"
				[show]="showsalesRepresentativesList"
				[multiple]="true"
				[selected]="config.salesRepresentatives"
				[template]="'${firstName} ${lastName}'"
				[enableThrottleClick]="false"
				(onItemSelect)="updateSelectedsalesRepresentatives($event)">
			</select-list>
		</div>
	</div>
	<download-link [config$]="downloadLink$"></download-link>
	<div class="row">
		<div class="col-md-12"><button class="btn btn-primary" (click)="download()">Download</button></div>
	</div>
</div>
