import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { ObjectStoreMeta } from 'ngx-indexed-db';

export const cptCodeDbConfig: ObjectStoreMeta = {
	store: UrlCodelistLink.CptCodes,
	storeConfig: { keyPath: 'id', autoIncrement: false },
	storeSchema: [
		{ name: 'id', keypath: 'id', options: { unique: true } },
		{ name: 'code', keypath: 'code', options: { unique: false } },
		{ name: 'title', keypath: 'title', options: { unique: false } },
	]
}
