import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { NgSelectSearchingHelperService } from '@app/shared/model/ng-select-searching-helper.service';
import { Codelist } from '@app/shared/model/types/codelists/codelist';
import { EntityId } from '@app/shared/model/types/entity-id';
import { DropdownPosition } from '@ng-select/ng-select';
import { combineLatest, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
	selector: 'codelist-select',
	templateUrl: './codelist-select.component.html'
})
export class CodelistSelectComponent implements OnInit, OnDestroy {
	@Input() inputControl: UntypedFormControl;
	@Input() path: string;
	@Input() isUrl: boolean;
	@Input() dataCy: string;
	@Input() valueFormatter: (value) => string = null;
	@Input() searchFn;
	@Input() filterFn: (value) => boolean = null;
	@Input() multiple = false;
	@Input() appendTo: string = null;
	@Input() closeOnSelect = true;
	@Input() clearSearchOnAdd = true;
	@Input() dropdownPosition: DropdownPosition = 'auto';
	@Input() placeholder = '';
	@Input() classInvalid = false;
	@Input() showId = false;
	@Output() selectedItems = new EventEmitter<unknown[] | unknown>();
	codelistItems$: Observable<any>;
	private destroy$ = new Subject<void>();

	constructor(
		private codelistRepository: CodelistRepositoryService,
		public searchingHelper: NgSelectSearchingHelperService) {
		this.filterFn = () => true;
	}

	public ngOnInit() {
		let codelistItems$;
		if (this.isUrl) {
			codelistItems$ = this.codelistRepository.getByUrl(this.path);
		} else {
			codelistItems$ = this.codelistRepository.get(this.path);
		}
		this.codelistItems$ = codelistItems$
			.pipe(
				map((codelistItems: Codelist[]) =>
					codelistItems
						.filter(this.filterFn)
						.map(codelistItem => {
							delete codelistItem.entityString;
							return { ...codelistItem };
						})),
				shareReplay(({ bufferSize: 1, refCount: true }))
			);
		combineLatest([this.inputControl.valueChanges.pipe(startWith(this.inputControl.value)), this.codelistItems$])
			.pipe(
				map(([ids, items]) => this.getItems(ids, items)),
				takeUntil(this.destroy$)
			).subscribe((items) => {
			this.selectedItems.next(items);
		});
	}

	compareWith = (optionValue: { id: EntityId }, selectedValue: EntityId): boolean => {
		return optionValue.id.toString() === selectedValue.toString();
	}

	private getItems(ids: string[] | string, items: { id: EntityId }[]) {
		if (ids == undefined) {
			return [];
		}
		if (typeof ids === 'object') {
			return Array.isArray(ids) ? ids?.map(id => items.find(item => item.id.toString() === id.toString())) : ids
		}
		return items.find(item => item.id.toString() === ids.toString());
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
