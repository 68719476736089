<ng-container *ngrxLet="filterItems$;let filterItems">
	<ng-select
		[formControl]="selectControl"
		class="base-input"
		[multiple]="true"
		[searchable]="true"
		[searchFn]="searchingHelper.searchCodelist"
		[closeOnSelect]="false"
		placeholder="All"
		[clearSearchOnAdd]="true"
		[virtualScroll]="true"
		appendTo="body"
		[items]="filterItems"
		[groupBy]="groupByFn"
		[selectableGroup]="true"
		[selectableGroupAsModel]="false"
	>
		<ng-template ng-optgroup-tmp let-item="item">
			<span><strong>{{item.label}}</strong></span>
		</ng-template>
		<ng-template ng-label-tmp let-item="item" let-clear="clear">
			<span class="ng-value-icon left" (click)="clear(item);" style="margin-right: 5px;" aria-hidden="true">×</span>
			<span>{{ item.title }}</span>
		</ng-template>
		<ng-template ng-option-tmp let-item="item">
			<div class="base-flex base-flex--align-center">
				<span class="option">{{ item.title }}</span>
			</div>
		</ng-template>
		<ng-template ng-header-tmp>
			<div>
				<button class="btn btn-primary"
				        (click)="selectAll(filterItems)">Select All
				</button>
				<button class="btn btn-primary ml-1"
				        (click)="clearAll()">Clear All
				</button>
			</div>
		</ng-template>
	</ng-select>
</ng-container>
