import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { fadeInOut, fadeUpDown, heightGrow } from '@app/shared/animations/animations';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { DateTimeHelperService } from '@app/shared/model/date-time-helper.service';
import { NgSelectSearchingHelperService } from '@app/shared/model/ng-select-searching-helper.service';
import { TimezoneItem } from '@app/shared/model/types/timezone';
import { Todo } from '@app/shared/model/types/todo';
import { Moment } from 'moment';
import * as momentTimezone from 'moment-timezone';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'todo-card-due-date',
	templateUrl: './todo-card-due-date.component.html',
	animations: [
		fadeUpDown,
		heightGrow,
		fadeInOut,
	],
})
export class TodoCardDueDateComponent implements OnInit, OnChanges, OnDestroy {
	@Input() dueDateString: string;
	@Input() timezone: TimezoneItem;
	@Output() onSave = new EventEmitter<Partial<Todo>>();

	dueDate: Date;
	time: Date;
	minDate: Date;
	showDatepicker = false;
	isLocalTimeVisible = false;
	timezones: TimezoneItem[] = [];
	selectedTimezone: TimezoneItem;

	readonly svgIconNames = SvgIconName;
	private destroy$ = new Subject<void>();

	constructor(private dateTimeHelper: DateTimeHelperService,
				private codelistRepository: CodelistRepositoryService,
				public searchingHelper: NgSelectSearchingHelperService,
				private flashMessageService: FlashMessageService) {
	}

	public ngOnInit(): void {
		this.initDueDate();
		this.minDate = new Date();
		this.testVisibilityOfLocalTime();
		if (this.showDatepicker) {
			this.setupLocalTimezone();
		}
	}

	public ngOnChanges(_changes: SimpleChanges) {
		this.initDueDate();
		this.testVisibilityOfLocalTime();
	}

	private initDueDate(): void {
		const dateObjectByTimezone = new Date(momentTimezone(this.dueDateString).tz(this.timezone?.zone)?.format('Y-MM-DD HH:mm:ss'));
		this.dueDate = this.dueDateString ? dateObjectByTimezone : null;
		this.time = this.dueDate ?? null;
		this.selectedTimezone = this.timezone ?? null;
	}

	public toggleDatepicker(): void {
		this.showDatepicker = !this.showDatepicker;
		this.setupLocalTimezone();
		this.initDueDate();
	}

	public closeDatepicker(): void {
		this.showDatepicker = false;
	}

	private getDueDateString(): string {
		let todoDateTime;
		if (this.selectedTimezone.zone !== Intl.DateTimeFormat().resolvedOptions().timeZone) {
			const dueDate = this.dateTimeHelper.buildDateTimeString(this.dueDate, this.time, false);
			const timezone = momentTimezone.tz(dueDate, this.selectedTimezone.zone) as unknown as Moment;
			todoDateTime = this.dateTimeHelper.formatDateTime(timezone.utc(), true);
		} else {
			todoDateTime = this.dateTimeHelper.buildDateTimeString(this.dueDate, this.time, true, true);
		}

		return todoDateTime;
	}

	public saveDueDate(): void {
		this.dueDateString = this.getDueDateString();
		this.onSave.emit({ dueDate: this.dueDateString, timezone: this.selectedTimezone.id });
		this.showDatepicker = false;
	}

	public removeDueDate(): void {
		this.dueDate = null;
		this.time = null;
		this.onSave.emit({ dueDate: null });
		this.showDatepicker = false;
	}

	public clearTime(): void {
		this.time = null;
	}

	private testVisibilityOfLocalTime(): void {
		this.isLocalTimeVisible = this.timezone?.zone !== Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	private setupLocalTimezone(): void {
		if (this.timezones.length === 0) {
			this.codelistRepository.get<TimezoneItem[]>(NonUrlCodelistName.Timezone)
				.pipe(takeUntil(this.destroy$))
				.subscribe(timezones => {
				this.timezones = timezones;
			})
		}
	}

	public timezoneChange(timezone: TimezoneItem): void {
		this.selectedTimezone = timezone;
	}

	public setLocalTimezone(): void {
		let timezone = this.timezones.find((item) => item.zone === Intl.DateTimeFormat().resolvedOptions().timeZone);
		if (!timezone) {
			timezone = this.timezones.find((item) => item.default);
			this.flashMessageService.showMessage(FlashMessageType.Danger, 'Timezone not found, default will be used instead.');
		}
		this.selectedTimezone = timezone;
	}

	public setLaTimezone(): void {
		this.selectedTimezone = this.timezones.find((item) => item.default);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
