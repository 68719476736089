export enum MriColumnIds {
	WaitingToSchedule = 'waiting_to_schedule',
	ConsultationPending = 'mri_consultation_pending',
	AppointmentConfirmed = 'appointment_confirmed',
	WaitingOnFindings = 'waiting_on_findings',
	WaitingOnBilling = 'waiting_on_billing',
	PendingInvoice = 'pending_invoice'
}

export const MriColumnNames = {
	[MriColumnIds.WaitingToSchedule]: 'Waiting to Schedule',
	[MriColumnIds.ConsultationPending]: 'Scheduling',
	[MriColumnIds.AppointmentConfirmed]: 'Appointment Confirmed',
	[MriColumnIds.WaitingOnFindings]: 'Waiting On Findings',
	[MriColumnIds.WaitingOnBilling]: 'Waiting On Billing',
	[MriColumnIds.PendingInvoice]: 'Pending Invoice'
}
