import { TooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/tooltip-theme-service';
import { get } from 'lodash';

export class PieGroupedTooltipThemeService extends TooltipThemeService<any> {
	public getTooltip(): Pick<any, 'tooltip'> {
		return {
			tooltip: {
				renderer: (params) => {
					const seriesData = this.tooltipData.find(data => data.chartLabel == params.datum.title);
					const config = this.tooltipColIds
						.map(colId => this._gridTooltipConfigs[colId])
						.filter(colConfig => colConfig !== undefined);
					const tooltipContent = config.reduce((acc, series) => {
						const data = get(seriesData, series.path);
						return `${acc}
							<div>${series.valueFormatter(data)}</div>`}, '');
					return `
    					<div class="ag-chart-tooltip-title"
    					style="background-color: ${params.color}">
${params.angleName} - ${params.datum.title}
</div>
    					<div class="ag-chart-tooltip-content">${tooltipContent}</div>
					`
				}
			}
		}
	}
}
