export enum SvgIconName {
	USPSCard = 'usps-card',
	DoctorAudit = 'doctor-audit',
	LawFirmAudit = 'law-firm-audit',
	Todos = 'todos',
	Datepicker = 'datepicker',
	Avatar = 'avatar',
	Clock = 'clock',
	Attachment = 'attachment',
	Trash = 'trash',
	Search = 'search',
	Save = 'save',
	Add = 'add',
	Category = 'category',
	Done = 'done',
	LowPriority = 'low-priority',
	MediumPriority = 'medium-priority',
	HighPriority = 'high-priority',
	Law = 'law',
	Medical = 'medical',
	Manager = 'manager',
	Clients = 'clients',
	Doctor = 'doctor',
	Place = 'place',
	Contact = 'contact',
	MedicalFacility = 'medical-facility',
	Crown = 'crown',
	Gift = 'gift',
	Visit = 'visit',
	Drink = 'drink',
	Message = 'message',
	Call = 'call',
	Presentation = 'presentation',
	Info = 'info',
	Route = 'route',
	DropOff = 'drop-off',
	Preferences = 'preferences',
	Envelope = 'envelope',
	OpenEnvelope = 'envelope-open',
	Edit = 'edit',
	CashBack = 'cash-back',
	Consult = 'consult',
	Dashboard = 'nav-dashboard',
	Agenda = 'nav-agenda',
	Sales = 'nav-sales',
	Whiteboard = 'nav-whiteboard',
	Applicants = 'nav-my-applicants',
	Database = 'nav-database',
	Servicing = 'nav-servicing',
	Chart = 'nav-chart',
	LienLetter = 'nav-lien-letter',
	Security = 'nav-security',
	Settings = 'nav-settings',
	Log = 'nav-log',
	Metrics = 'nav-metrics',
	LogOut = 'log-out',
	AddDocument = 'add-document',
	DownloadAll = 'download-all',
	Download = 'download',
	DocuSign = 'docusign',
	Clear = 'clear',
	Template = 'template',
	In = 'in',
	Out = 'out',
	SendDocuSign = 'send-docusign',
	LawFirm = 'law-firm',
	Instruction = 'instruction',
	Accident = 'accident',
	Funding = 'select-funding',
	CptCodes = 'cpt-codes',
	BrowseFile = 'browse-file',
	Refresh = 'refresh',
	Availability = 'availability',
	Filter = 'filter',
	Zoom = 'zoom'
}
