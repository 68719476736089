<div (clickOutside)="hideReportsList()" style="position: relative; margin-right: 10px;">
	<button class="base-button" (click)="toggleReportsList()">
		Export reports
	</button>
	<select-list
		[items]="reportListOptions"
		align="right"
		class="without-triangle"
		[show]="showReportsList"
		[checkAcl]="true"
		(onItemSelect)="onReportSelect($event)">
	</select-list>
</div>
<download-link [config$]="downloadLink$"></download-link>
