import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export class DashboardDateValidator {

	public static invalidDate(minDate: Date, maxDate: Date): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}
			const selectedDate = moment(control.value);
			return selectedDate.isBefore(minDate) || selectedDate.isAfter(maxDate) ? { dateOutOfRange: true } : null;
		}
	}

}
