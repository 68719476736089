import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { CodelistSelectsModule } from '@app/shared/codelist-selects/codelist-selects.module';
import { SharedModule } from '@app/shared/shared.module';
import { BooleanSelectComponent } from '@app/widgets/medical-markers-map/boolean-select/boolean-select.component';
import { MedicalMarkersMapDoctorFilterComponent } from '@app/widgets/medical-markers-map/medical-markers-map-doctor-filter/medical-markers-map-doctor-filter.component';
import { MedicalMarkersMapMedicalFacilityFilterComponent } from '@app/widgets/medical-markers-map/medical-markers-map-medical-facility-filter/medical-markers-map-medical-facility-filter.component';
import { MedicalMarkersMapComponent } from '@app/widgets/medical-markers-map/medical-markers-map.component';
import { MedicalMarkersMapHelperService } from '@app/widgets/medical-markers-map/service/medical-markers-map-helper.service';
import { RpCostEditorModule } from '@app/widgets/rp-cost-editor/rp-cost-editor.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LetModule } from '@ngrx/component';

@NgModule({
	imports: [
		GoogleMapsModule,
		CommonModule,
		SharedModule,
		LetModule,
		NgSelectModule,
		ReactiveFormsModule,
		CodelistSelectsModule,
		RpCostEditorModule
	],
	declarations: [
		MedicalMarkersMapComponent,
		BooleanSelectComponent,
		MedicalMarkersMapDoctorFilterComponent,
		MedicalMarkersMapMedicalFacilityFilterComponent
	],
	exports: [
		MedicalMarkersMapComponent
	],
	providers: [
		MedicalMarkersMapHelperService
	]
})
export class MedicalMarkersMapModule {

}
