import { Component } from '@angular/core';
import { SummaryStatisticsService } from '@app/dashboard/summary-statistics/summary-statistics.service';

@Component({
	selector: 'summary-statistics',
	templateUrl: './summary-statistics.component.html',
	providers: [SummaryStatisticsService]
})
export class SummaryStatisticsComponent {
}
