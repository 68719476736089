export class CurrentMonth {
	dealsAmountPer3MoAverage: number;
	avgFundingSize: number;
	avgDealsAmount: number;
	dealsAmount: number;
	avgDealsAmountPerDay: number;
	fundingsAmountProjected: number;
	medicalBreakdown: MedicalBreakdown[];
	lastMonthCollected: LastMonthCollected;
	lastMonthFundings: LastMonthFundings;
	threeMonthsCollected: ThreeMonthsCollected;
	threeMonthsFundings: ThreeMonthsFundings;
	topFiveFundings: TopFiveFunding[];

	constructor(isEffective: boolean, currentMonth: CurrentMonthResponse) {
		if (isEffective) {
			this.dealsAmountPer3MoAverage = currentMonth?.lastMonthEffectiveRates.dealsAmountPer3MoAverage;
			this.avgFundingSize = currentMonth?.lastMonthEffectiveRates.avgFundingSize;
			this.avgDealsAmount = currentMonth?.threeMonthsEffectiveRates.avgDealsAmount;
			this.dealsAmount = currentMonth?.lastMonthEffectiveRates.dealsAmount;
			this.avgDealsAmountPerDay = currentMonth?.lastMonthEffectiveRates.avgDealsAmountPerDay;
			this.fundingsAmountProjected = currentMonth?.lastMonthEffectiveRates.fundingsAmountProjected;
			this.medicalBreakdown = currentMonth?.lastMonthFundings.medicalBreakdown.map((medicalBreakdown) => ({
				...medicalBreakdown,
				dealsAmount: medicalBreakdown.dealsAmountWithEffectiveRates
			}));
		} else {
			this.dealsAmountPer3MoAverage = currentMonth?.lastMonthFundings.dealsAmountPer3MoAverage;
			this.avgFundingSize = currentMonth?.lastMonthFundings.avgFundingSize;
			this.avgDealsAmount = currentMonth?.threeMonthsFundings.avgDealsAmount;
			this.dealsAmount = currentMonth?.lastMonthFundings.dealsAmount;
			this.avgDealsAmountPerDay = currentMonth?.lastMonthFundings.avgDealsAmountPerDay;
			this.fundingsAmountProjected = currentMonth?.lastMonthFundings.fundingsAmountProjected;
			this.medicalBreakdown = currentMonth?.lastMonthFundings.medicalBreakdown;
		}
		this.lastMonthCollected = currentMonth?.lastMonthCollected;
		this.lastMonthFundings = currentMonth?.lastMonthFundings;
		this.threeMonthsCollected = currentMonth?.threeMonthsCollected;
		this.threeMonthsFundings = currentMonth?.threeMonthsFundings;
		this.topFiveFundings = currentMonth?.topFiveFundings;
	}
}

export type CurrentMonthResponse = {
	lastMonthCollected: LastMonthCollected,
	lastMonthFundings: LastMonthFundings,
	threeMonthsCollected: ThreeMonthsCollected,
	threeMonthsFundings: ThreeMonthsFundings
	lastMonthEffectiveRates: LastMonthEffectiveRates;
	threeMonthsEffectiveRates: ThreeMonthsEffectiveRates;
	topFiveFundings: TopFiveFunding[];
}

type ThreeMonthsEffectiveRates = {
	avgDealsAmount: number;
	dealsAmount: number;
}

type LastMonthEffectiveRates = {
	avgDealsAmountPerDay: number;
	avgFundingSize: number;
	dealsAmount: number;
	dealsAmountPer3MoAverage: number;
	fundingsAmountProjected: number;
}

type LastMonthCollected = {
	collectionProjected: number,
	dealsAmount: number,
	dealsAmountCollectedPer3MoAverage: number,
	dealsCount: number,
	dealsCountCollectedPer3MoAverage: number,
	faceValue: number,
	fee: number,
	principalLoss: number,
	principal: number,
	settlementMultiple: number,
	medicalCount: number,
	plaintiffCount: number,
	caseCostCount: number,
	medicalAmount: number,
	plaintiffAmount: number,
	caseCostAmount: number
}

type LastMonthFundings = {
	avgDealsAmountPerDay: number,
	avgFundingSize: number,
	avgFundingsPerDay: number,
	businessDaysInMonth: number,
	businessDaysSoFar: number,
	dealsAmount: number,
	dealsAmountPer3MoAverage: number,
	dealsCount: number,
	dealsCountPer3MoAverage: number,
	fundingsAmountProjected: number,
	fundingsProjected: number,
	medicalBreakdown: MedicalBreakdown[],
	medicalCount: number,
	plaintiffCount: number,
	caseCostCount: number,
	medicalAmount: number,
	plaintiffAmount: number,
	caseCostAmount: number,
	avgMedicalCountPerDay: number,
	avgPlaintiffCountPerDay: number,
	avgCaseCostCountPerDay: number,
}

type ThreeMonthsCollected = {
	avgDealsAmount: number,
	avgDealsCount: number,
	dealsAmount: number,
	dealsCount: number,
	faceValue: number,
	fee: number,
	principal: number
}

type ThreeMonthsFundings = {
	avgDealsAmount: number,
	avgDealsCount: number,
	avgThreeMonthsCountPerDay: number,
	dealsAmount: number,
	dealsCount: number
}

export interface MedicalBreakdown {
	title: string,
	dealsCount: number,
	dealsAmount: number,
	dealsAmountWithEffectiveRates: number,
	avgPurchasePrice: number,
	purchaseRate: number,
}

interface TopFiveFunding {
	id: number,
	internalId: string,
	applicantId: number,
	applicantName: string,
	purchasePrice: number,
	fundingTypeId: number,
	fundingTypeTitle: string,
	lawFirmId: number,
	lawFirmName: string
}
