import { Component, EventEmitter } from '@angular/core';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IEditor } from '@app/shared/ga-components/utils/IEditor';
import { UntypedFormGroup } from '@angular/forms';
import { GaEditorParams } from '@app/shared/ga-components/components/editors/ga-editor.params';

@Component({
	selector: 'ga-textarea-editor',
	template: `<ng-container [formGroup]="formGroup">
					<textarea (focus)="onFocus(true)"
							(blur)="onFocus(false)"
							[formControlName]="componentMetadata.fieldName"
							[attr.data-cy]="componentMetadata.fieldName + '-input'"
							[ngClass]="cssClass">
					</textarea>
				</ng-container>`,
	styles: ['']
})
export class GaTextareaEditorComponent implements IEditor {

	cssClass = 'ga-textarea';

	componentMetadata: ComponentMetadata;

	params: GaEditorParams;

	formGroup: UntypedFormGroup;

	onFocusEmitter: EventEmitter<boolean>;

	entityData: any;

	public update(value: any, dataObj: any): void {
		this.entityData = dataObj;
		this.formGroup.get(this.componentMetadata.fieldName).setValue(this.componentMetadata.valueFormatter(value));
	}

	public init(value, data): void {
		this.update(value, data);
	}

	public onFocus($event): void {
		if(this.onFocusEmitter){
			this.onFocusEmitter.emit($event);
		}
	}

	public reset(): void {
		this.update('', {});
	}

	public isPristine(): boolean {
		return this.formGroup.pristine;
	}
}
