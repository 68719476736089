import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { map, Observable } from 'rxjs';
import { ResponsiveService } from './startup/responsive.service';

@Injectable({
	providedIn: 'root'
})
export class DefaultRouteGuard {

	constructor(
		private router: Router,
		private authService: AuthService,
		private responsiveService: ResponsiveService
	) {
	}

	public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.responsiveService.isMobile$.pipe(
			map((isMobile) => isMobile ?
				this.router.createUrlTree(['dashboard'])
				: this.authService.redirectAfterLogin()
			)
		)
	}
}
