import { Component } from '@angular/core';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IRenderer } from '@app/shared/ga-components/utils/IRenderer';

@Component({
	selector: 'ga-simple-renderer',
	template: '{{formattedValue}}'
})
export class GaSimpleRendererComponent implements IRenderer {

	value: string;
	dataObj: string;
	componentMetadata: ComponentMetadata;
	params;
	formattedValue: string;

	public redraw() {
		this.formattedValue = this.value;
	}
}
