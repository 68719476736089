<textarea #commentInput class="base-input" placeholder="Mention somebody by using @ in the comment..."
          (click)="emitInputTouch()" [formControl]="newCommentTextCtrl" data-cy="newComment-input"></textarea>

<ngx-mentions [textInputElement]="commentInput"
			  [menuTemplate]="employeesDropdown"
			  [triggerCharacter]="'@'"
			  [getChoiceLabel]="getChoiceLabel"
			  [searchRegexp]="'^([-&.\\w]+ *){0,3}$'"
			  (selectedChoicesChange)="onSelectedChoicesChange($event)"
			  (search)="filterMentions($event)"
			  [tagCssClass]="'highlight-tag'"
			  [selectedChoices]="selectedChoices$ | async"
></ngx-mentions>

<ng-template #employeesDropdown
			 let-selectChoice="selectChoice">

	<div class="quick-list" @fadeUpDown mentionsDropdownPosition [mentionsDropdownInput]="commentInput">
		<div class="quick-list__content">
			<div *ngFor="let employee of filteredEmployees$ | async" (throttleClick)="selectChoice(employee)"
				 [attr.data-cy]="'assignee__#' + employee.id" class="quick-list__item">
				<avatar [user]="employee"></avatar>
				<div
					class="quick-list__text">{{ employee?.firstName }} {{ employee?.lastName | truncate:0:11:'...' }}</div>
			</div>
		</div>
	</div>
</ng-template>

<div *ngIf="showCheck$ | async" class="todo-card__comments__body__done" @fadeInOut>
	<svg-icon icon="done" class="todo-card__comments__body__done__icon animated tada infinite"></svg-icon>
</div>
