import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input, OnInit, Optional, Self, SkipSelf } from '@angular/core';
import { CollapseStateService } from '@app/dashboard/collapsible-item/collapse-state.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { map, Observable } from 'rxjs';

@Component({
	selector: 'collapsible-item',
	templateUrl: './collapsible-item.component.html',
	styles: ['.collapsible {display: flex; justify-content: center; width: 20px; height: 20px} .cursor-pointer { cursor: pointer}'],
	providers: [CollapseStateService],
	imports: [
		FontAwesomeModule,
		AsyncPipe,
		NgIf
	],
	standalone: true
})
export class CollapsibleItemComponent implements OnInit {
	@Input() isManagedOutside = false;
	@Input() isManagedOutsideWithIcon = false;
	@Input() isCollapsedAtFirst = false;
	expanded$: Observable<boolean>;
	collapsingIcon$: Observable<IconDefinition>;
	showIcon = true;

	constructor(
		@Self() private innerCollapseStateProvider: CollapseStateService,
		@Optional() @SkipSelf() private outsideCollapseStateProvider: CollapseStateService) {
	}

	public ngOnInit(): void {
		if (this.isCollapsedAtFirst) {
			this.collapseStateProvider.collapse();
		}
		this.showIcon = !this.isManagedOutside || this.isManagedOutsideWithIcon;
		this.expanded$ = this.collapseStateProvider.isExpanded$;
		this.collapsingIcon$ = this.expanded$.pipe(
			map((expanded) => expanded ? faChevronUp : faChevronDown)
		);
	}

	public toggleCollapsed(): void {
		this.collapseStateProvider.toggleCollapse();
	}

	private get collapseStateProvider(): CollapseStateService {
		return (this.isManagedOutside || this.isManagedOutsideWithIcon) ?
			this.outsideCollapseStateProvider : this.innerCollapseStateProvider;
	}
}
