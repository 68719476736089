<ng-select [items]="codelistItems$ | async"
		   [searchable]="true"
		   [searchFn]="searchingHelper.searchByFullName"
		   [multiple]="multiple"
		   [virtualScroll]="true"
		   [formControl]="inputControl"
		   [inputAttrs]="{'data-cy': dataCy + '-input'}"
		   [attr.data-cy]="dataCy + '-select'"
		   [appendTo]="appendTo"
		   [closeOnSelect]="closeOnSelect"
		   [clearSearchOnAdd]="clearSearchOnAdd"
		   [compareWith]="compareWith"
		   [placeholder]="placeholder"
		   [class.invalid]="classInvalid"
		   bindValue="id"
		   [ngClass]="{'show-clear-all': multiple}"
		   class="base-input">
	<ng-template ng-label-tmp let-item="item" let-clear="clear">
		<span *ngIf="multiple" class="ng-value-icon left" (click)="clear(item);" style="margin-right: 5px;"
			  aria-hidden="true">×</span>
		<span [ngStyle]="{paddingRight: multiple ? '5px': '0'}">{{ item.firstName }} {{ item.lastName }} <span
			*ngIf="showId"> ({{ item.id }})</span></span>
	</ng-template>
	<ng-template ng-option-tmp let-item="item">{{ item.firstName }} {{ item.lastName }}<span
		*ngIf="showId"> ({{ item.id }})</span></ng-template>
</ng-select>
