import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {

	public transform(text, startOfText, endOfText, endingOfText) {
		if (text && text.length > endOfText) {
			return `${text.slice(startOfText, endOfText)} ${endingOfText}`;
		} else {
			return text;
		}
	}
}
