import { Injectable } from '@angular/core';
import { GaDecoratorInterface } from '@app/shared/ga-components/utils/ga-decorator-interface';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';
import * as moment from 'moment';

@Injectable()
export class TimeAgoDecorator implements GaDecoratorInterface {

	public create(): MetadataDecorator {
		return {
			valueFormatter: value => {
				// TODO replace with TimeAgoPipe !!
				const date = moment(value);
				const now = moment();
				const seconds = now.diff(date, 'seconds');
				const minutes = now.diff(date, 'minutes');
				const hours = now.diff(date, 'hours');
				const days = now.diff(date, 'days');
				const months = now.diff(date, 'months');
				const years = now.diff(date, 'years');

				if (Number.isNaN(seconds)) {
					return '';
				} else if (seconds <= 45) {
					return 'a few seconds ago';
				} else if (seconds <= 90) {
					return 'a minute ago';
				} else if (minutes <= 45) {
					return minutes + ' minutes ago';
				} else if (minutes <= 90) {
					return 'an hour ago';
				} else if (hours <= 22) {
					return hours + ' hours ago';
				} else if (hours <= 36) {
					return 'a day ago';
				} else if (days <= 25) {
					return days + ' days ago';
				} else if (days <= 45) {
					return 'a month ago';
				} else if (days <= 345) {
					return months + ' months ago';
				} else if (days <= 545) {
					return 'a year ago';
				} else {
					return years + ' years ago';
				}
			},
		};
	}
}
