import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { SummaryStatisticsChartConfig, SummaryStatisticsChartId } from '@app/dashboard/summary-statistics/summary-statistics.model';
import { CartesianSavedChart } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/cartesian-saved-chart';
import { chartNumberAxesTheme } from '@app/shared/ag-grid-utils/chart-themes/chart-axes-theme';
import { ChartModel, ChartRef, GridApi } from 'ag-grid-community';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class SummaryStatisticsGraphService {
	private chartRefs: ChartRef[] = [];
	private savedCharts: Map<SummaryStatisticsChartId, CartesianSavedChart>;
	private readonly hasSavedChartEmitter = new ReplaySubject<boolean>(1);
	private gridApi: GridApi;

	get hasSavedChart$() {
		return this.hasSavedChartEmitter.asObservable();
	}

	set hasSavedChart(value: boolean) {
		this.hasSavedChartEmitter.next(value);
	}

	public initialize(gridApi: GridApi, savedCharts: Map<SummaryStatisticsChartId, CartesianSavedChart>): void {
		this.gridApi = gridApi;
		this.savedCharts = savedCharts;
	}

	public destroyCharts(): void {
		this.chartRefs.forEach(chartRef => chartRef.destroyChart());
		this.chartRefs = [];
	}

	public createCharts(chartConfigs: SummaryStatisticsChartConfig[]): void {
		this.destroyCharts();

		chartConfigs.forEach(chartConfig => {
			const savedChart = this.savedCharts.get(chartConfig.chartId);
			const chartRef = this.createChart(chartConfig, savedChart);
			savedChart.getTooltipTheme().setChart(chartRef.chart);
			this.chartRefs.push(chartRef);
		});
	}

	private createChart(chartConfig: SummaryStatisticsChartConfig, savedChart: CartesianSavedChart): ChartRef {
		const savedChartRef = this.getSavedChart(savedChart, chartConfig);
		if (savedChartRef !== null) {
			return savedChartRef;
		}
		const { columns, chartId, chartElementSelector } = chartConfig;
		const columnIds = columns.map(column => column.colId);
		const groupedTooltip = savedChart.getTooltipTheme().getGroupedTooltipTheme(columnIds);
		return this.gridApi.createRangeChart({
			chartType: 'customCombo',
			cellRange: {
				columns: ['title', ...columnIds]
			},
			seriesChartTypes: columns,
			chartContainer: document.querySelector(chartElementSelector),
			chartThemeName: 'ag-pastel',
			chartThemeOverrides: {
				...chartNumberAxesTheme('Summary Statistics Chart'),
				column: {
					series: {
						...groupedTooltip,
						label: {
							formatter: this.getSeriesLabelFormatter(chartId)
						},
						fillOpacity: 0.8
					}
				},
				line: {
					series: {
						...groupedTooltip,
						strokeOpacity: 0.8,
						strokeWidth: 5
					}
				}
			}
		});
	}

	private getSavedChart(savedChart: CartesianSavedChart, chartConfig: SummaryStatisticsChartConfig): ChartRef | null {
		let chartModel = savedChart.getSavedChart();
		if (chartModel !== null) {
			chartModel = this.overrideSavedState(chartModel, chartConfig.chartId);
			this.hasSavedChartEmitter.next(true);
			return this.gridApi.restoreChart(chartModel, document.querySelector(chartConfig.chartElementSelector));
		}
		this.hasSavedChartEmitter.next(false);
		return null;
	}

	private overrideSavedState(chartModel: ChartModel, chartId: SummaryStatisticsChartId): ChartModel {
		const chartModelOverride = { ...chartModel };

		chartModelOverride.chartOptions.column.series.label.formatter = this.getSeriesLabelFormatter(chartId);

		return chartModelOverride;
	}

	public getSeriesLabelFormatter(chartId: SummaryStatisticsChartId) {
		return ({ value }) => {
			if (this.isFundingGraph(chartId)) {
				return formatNumber(value, 'en-US', '1.0-0');
			}
			return value.toString();
		};
	}

	public isFundingGraph(chartId: SummaryStatisticsChartId): boolean {
		const fundingChartIds = [
			SummaryStatisticsChartId.FundedFundings,
			SummaryStatisticsChartId.CollectedFundings,
			SummaryStatisticsChartId.EffectiveRatesFundings
		];
		return fundingChartIds.includes(chartId);
	}
}
