import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Sms } from '@app/shared/model/types/sms';

/*
 * Takes delivery date for outbound messages and receive date for inbound messages and formats it.
 * Uses DatePipe date formats.
 * 'short' format is default option
 *
 * Usage:
 *   object | smsMessageDate:format[optional]
 * Example:
 *   {{ sms | smsMessageDate }}
 *   formats to: '2/21/18, 3:29 AM'
 * Example:
 *   {{ sms | smsMessageDate:long}}
 *   formats to: 'February 21, 2018 at 3:29:37 AM GMT+1'
*/
@Pipe({ name: 'smsMessageDate' })
export class SmsMessageDatePipe implements PipeTransform {

	public transform(sms: Sms, format = 'short') {

		const datePipe = new DatePipe('en-US');
		const date = (sms.direction == 'inbound-api') ? sms.receivedAt : sms.deliveredAt ?? sms.queuedAt;

		return datePipe.transform(date, format);
	}
}
