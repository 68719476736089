import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { fadeInOut } from '@app/shared/animations/animations';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { EntityAssociationType } from '@app/shared/model/constants/entity-association-type';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { SVGIconComponent } from '@app/shared/svg-icon/svg-icon.component';
import { EntityAssociationsListEntry } from '@app/widgets/entity-associations-list/model/entity-associations-list-entry';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAddressBook, faDollarSign, faGavel, faHSquare, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
	selector: 'entity-associations-list',
	templateUrl: 'entity-associations-list.component.html',
	styleUrls: ['entity-associations-list.component.scss'],
	animations: [fadeInOut],
	imports: [
		NgSwitch,
		NgSwitchCase,
		FontAwesomeModule,
		RouterLink,
		SVGIconComponent,
		TooltipModule,
		DirectivesModule,
		NgForOf,
		NgIf
	],
	standalone: true
})
export class EntityAssociationsListComponent {
	@Input() associations: EntityAssociationsListEntry[];
	@Input() canRemoveAssociation = true;
	@Output() onRemoveAssociation = new EventEmitter<EntityAssociationsListEntry>();
	readonly noteAssociationTypes = EntityAssociationType;
	readonly svgIconNames = SvgIconName;
	readonly faIcons = {
		spinner: faSpinner,
		user: faUser,
		dollarSign: faDollarSign,
		gavel: faGavel,
		hSquare: faHSquare,
		addressBook: faAddressBook,
	};

	public removeAssociation(association: EntityAssociationsListEntry): void {
		this.onRemoveAssociation.emit(association);
	}
}
