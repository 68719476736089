import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import * as moment from 'moment';

@Component({
	selector: 'ag-bs-datepicker-editor',
	templateUrl: './ag-bs-date-picker-editor.component.html',
})
export class AgBsDatePickerEditorComponent implements ICellEditorAngularComp {
	selectControl = new FormControl<Date | null>(null);
	private params: any;
	private bsValue: Date | null;

	public agInit(params: any): void {
		this.params = params;
		this.bsValue = this.params.value ? moment(this.params.value,'YYYY-MM-DD').toDate() : null;
		this.selectControl.setValue(this.bsValue);
	}

	public getValue(): any {
		return this.selectControl.value ? moment(this.selectControl.value).format('YYYY-MM-DD') : '';
	}

	public isPopup(): boolean {
		return true;
	}

	public onChange(value): any {
		if (!moment(value).isSame(this.bsValue, 'day')) {
			this.bsValue = value;
			this.selectControl.setValue(this.bsValue);
		}
	}
}
