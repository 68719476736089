import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { AclGuard } from '@app/core/security/acl.guard';
import { UseCase } from '@app/core/security/use-case';
import { FundingsViewComponent } from '@app/fundings/fundings-view.component';

const routes: Routes = [
	{
		path: 'portfolio', children: [
			{
				path: 'fundings', component: FundingsViewComponent, canActivate: [AuthGuard, AclGuard],
				data: { security: UseCase.for('applicants.fundings'), title: 'Fundings Portfolio' }
			},
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class FundingRoutingModule {}
