<collapsible-item>
	<h4 header-left>Chart:</h4>
	<div class="flex-col">
		<div class="flex-thin" style="margin-bottom: 10px;">
			<daterange-picker [rangesEmitter]="ranges$" (dateRangeSelected)="recalculateStatsForDate($event)" style="min-width: 400px;"></daterange-picker>
			<div class="base-button-group small" style="max-width: 340px; margin-left: 20px;">
				<button (click)="toggleChartMode('daily')" type="button"
				        class="base-button" [ngClass]="{'active': chartMode == 'daily' }">Daily
				</button>
				<button (click)="toggleChartMode('weekly')" type="button"
				        class="base-button" [ngClass]="{'active': chartMode == 'weekly' }">Weekly
				</button>
				<button (click)="toggleChartMode('monthly')" type="button"
				        class="base-button" [ngClass]="{'active': chartMode == 'monthly' }">Monthly
				</button>
			</div>
		</div>
		<div class="flex" style="position: relative;">
			<div class="flex-item">
				<canvas baseChart
				        [datasets]="chartDataSets"
				        [labels]="chartLabels"
				        [options]="chartOptions"
				        [type]="'line'">
				</canvas>
			</div>
			<loading-spinner [show]="!areDataReady" loadingLabel="Loading data"></loading-spinner>
		</div>
	</div>
</collapsible-item>
