import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({ selector: '[mentionsDropdownPosition]' })
export class MentionsDropdownRepositionDirective implements OnInit {
	@Input() mentionsDropdownInput: HTMLTextAreaElement;

	constructor(private el: ElementRef) {
	}

	public ngOnInit(): void {
		const { top, left } = this.el.nativeElement.parentElement.style;
		const { width, height, marginTop, marginBottom } = window.getComputedStyle(this.mentionsDropdownInput);
		const elWidthStyle = window.getComputedStyle(this.el.nativeElement).width;
		const [
			elWidth,
			parentWidth,
			parentHeight,
			parentTop,
			parentLeft,
			inputMTop,
			inputMBottom
		] = this.getPropertiesAsNumber(elWidthStyle, width, height, top, left, marginTop, marginBottom);
		this.el.nativeElement.parentElement.style.top = `${Math.min(parentTop + inputMTop, parentHeight + inputMTop)}px`;
		const padding = 200;
		const minLeft = Math.min(parentLeft + inputMBottom, parentWidth - elWidth + inputMBottom);
		this.el.nativeElement.parentElement.style.left = `${minLeft + padding}px`;
	}

	private getPropertiesAsNumber(...styles: string[]): number[] {
		return styles.map(styleProperty => parseFloat(styleProperty.replace('px', '')));
	}

}
