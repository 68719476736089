import { Component, HostBinding, OnInit } from '@angular/core';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { FormScopeService } from '@app/shared/ga-components/services/form-scope.service';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { GaToggleSwitchParams } from '@app/shared/ga-components/utils/ga-toggle-switch-params';
import { IRenderer } from '@app/shared/ga-components/utils/IRenderer';
import { isFunction } from 'lodash';

@Component({
	selector: 'ga-toggle-switch-renderer',
	templateUrl: './ga-toggle-switch-renderer.component.html',
	providers: [EntityScopeService, FormScopeService]
})
export class GaToggleSwitchRendererComponent implements OnInit, IRenderer {
	@HostBinding('class.base-flex') hostClass = true;
	value: any;
	dataObj: any;
	show = false;
	componentMetadata: ComponentMetadata;
	params: GaToggleSwitchParams;
	borderColor: string;
	toggledValue: any;

	defaultColors = {
		checkedColor: 'var(--primary)',
		uncheckedColor: 'var(--dark-grey)',
		sliderColor: 'white',
	};

	constructor(public entityScope: EntityScopeService,
				public formScope: FormScopeService,
				private apiLink: GaApiLink) {
	}

	public redraw() {
		if (!this.value) {
			this.value = this.params.off.value;
		}
		this.setBorderColor();
	}

	public ngOnInit() {
		if (this.params && this.params.colors) {
			this.defaultColors = this.params.colors;
		}
		if (this.params.shouldUpdate == undefined) {
			this.params.shouldUpdate = true;
		}
		this.setBorderColor();
	}

	public assignNewValue() {
		const payload = { id: null };
		payload.id = this.dataObj.id;

		if (this.params.toggleAfterResponse) {
			this.toggledValue = this.value == this.params.on.value ? this.params.off.value : this.params.on.value;
			payload[this.componentMetadata.fieldName] = this.toggledValue;
		} else {
			this.value = this.value == this.params.on.value ? this.params.off.value : this.params.on.value;
			this.dataObj[this.componentMetadata.fieldName] = this.value;
			payload[this.componentMetadata.fieldName] = this.value;
		}

		if (this.params.postUpdate) {
			this.params.postUpdate(this.dataObj, payload[this.componentMetadata.fieldName]);
		}
		if (this.params.shouldUpdate) {
			const hooks = isFunction(this.params.hooks) ? this.params.hooks(this.dataObj) : this.params.hooks;
			this.apiLink.update(this.componentMetadata.entityClass, this.entityScope.entity, payload,
				this.componentMetadata.dependencies, {}, hooks, this.params.responseFieldsOverride)
				.subscribe((data) => {
					const updatedEntity = FormScopeService.smartMerge({ ...this.entityScope.entity }, data);
					this.entityScope.setEntity(updatedEntity);
				});
		}
		this.setBorderColor();
	}

	public setBorderColor() {
		this.borderColor = this.value == this.params.on.value
			? `0 0 0 2px ${this.defaultColors.checkedColor}, 0 0 0 2px ${this.defaultColors.checkedColor} inset`
			: `0 0 0 2px ${this.defaultColors.uncheckedColor}, 0 0 0 2px ${this.defaultColors.uncheckedColor} inset`;
	}
}
