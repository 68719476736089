import { Injectable } from '@angular/core';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { AvatarServiceResponse } from '@app/shared/model/types/avatar-service-model';
import { UserAccount } from '@app/shared/model/types/user-account';
import { Observable, Subject, filter } from 'rxjs';

@Injectable()
export class AvatarService {

	private avatarEmitter$ = new Subject<AvatarServiceResponse>();

	constructor(public apiLink: GaApiLink) {
	}

	public updateUserAvatar(userAvatar, userId) {
		this.avatarEmitter$.next({userAvatar, userId});
	}

	public getAvatar(): Observable<AvatarServiceResponse> {
		return this.avatarEmitter$.asObservable();
	}

	public fetchUserAvatar(userId): void {
		this.apiLink.retrieve('userAccount', ['userAvatar', 'userAvatar.localPath'], { id: userId })
			.pipe(filter((response: UserAccount[]) => response?.length > 0))
			.subscribe((response: any) => {
				this.updateUserAvatar(response[0].userAvatar, userId);
			});
	}
}
