import { Inject, Injectable } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { currencyColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/currency-column-definition';
import { ModalsService } from '@app/shared/modals/modals.service';
import { DefaultVintageDetailConfig, VintageDetailConfig } from '@app/shared/modals/vintage-detail/vintage-detail-config';
import { ApprovalStatus } from '@app/shared/model/constants/approval-status';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { CurrencyHelperService } from '@app/shared/model/currency-helper.service';
import { FundingHelperService } from '@app/shared/model/funding-helper.service';
import { MedicalProcedureType } from '@app/shared/model/types/codelists/medical-procedure-type';
import { ColDef } from 'ag-grid-community';
import { firstValueFrom, Subject, takeUntil, timeout } from 'rxjs';

@Injectable()
export class SummaryStatisticsModalService {

	private destroy$ = new Subject<void>();

	constructor(
		private dashboardFilter: DashboardFilterStore,
		private codelistService: CodelistRepositoryService,
		private currencyHelper: CurrencyHelperService,
		private agUtils: AgGridUtilsService,
		private fundingHelper: FundingHelperService,
		@Inject(DefaultVintageDetailConfig) private vintageDetailConfig: VintageDetailConfig,
		private modalService: ModalsService
	) {
	}

	public async openDetailAdvanced({ from, to }): Promise<void> {
		const search = {
			fundedOn: {
				from,
				to
			},
			approvalStatus: [ApprovalStatus.Funded, ApprovalStatus.Settled],
			...(await this.getModalFilter())
		};
		this.modalService.openVintageDetail({
			...this.getCommonVintageDetailConfig(),
			filter: search
		});
	}

	public async openDetailSettled({ from, to }): Promise<void> {
		const search = {
			fundedOn: {
				from,
				to
			},
			approvalStatus: [ApprovalStatus.Settled],
			...(await this.getModalFilter())
		};
		this.modalService.openVintageDetail({
			...this.getCommonVintageDetailConfig(),
			filter: search
		});
	}

	public async openDetailCollected({ from, to }): Promise<void> {
		const search = {
			settledOn: {
				from,
				to
			},
			approvalStatus: [ApprovalStatus.Settled],
			...(await this.getModalFilter())
		};
		this.modalService.openVintageDetail({
			...this.getCommonVintageDetailConfig(),
			filter: search
		});
	}

	public async openFundedDetailForSales({ from, to }): Promise<void> {
		const search = {
			fundedOn: {
				from,
				to
			},
			approvalStatus: [ApprovalStatus.Funded, ApprovalStatus.Settled],
			...(await this.getModalFilter())
		};

		const vintageDetailConfig = {
			...this.getCommonVintageDetailConfig()
		};

		const namesToExclude = ['Funded Amount', 'Purchase Price', 'Effective Rate'];
		vintageDetailConfig.columnDefinitions = vintageDetailConfig.columnDefinitions.filter(colDef => {
			return !namesToExclude.includes(colDef.headerName);
		});

		const fundingTypeIndex = vintageDetailConfig.columnDefinitions.findIndex(colDef => {
			return 'field' in colDef && colDef.field == 'fundingType.title';
		})
		const dealAmountColDef: ColDef = {
			headerName: 'Deal Amount',
			...currencyColumnDefinition(''),
			valueGetter: ({ data, node }) => {
				if (node?.group) {
					return undefined;
				}
				let effectiveRate = data?.purchasePrice;
				if (this.fundingHelper.isMedical(data) && !this.fundingHelper.isCourtReporting(data)) {
					effectiveRate = data?.medicalProcedureType?.category?.effectiveRate?.amount ?? data?.purchasePrice;
				}
				return effectiveRate ? +effectiveRate : 0;
			}
		};
		vintageDetailConfig.columnDefinitions.splice(fundingTypeIndex + 1, 0, dealAmountColDef);

		this.modalService.openVintageDetail({
			...vintageDetailConfig,
			filter: search
		});
	}

	private async getModalFilter() {
		const selectedFundingTypes = await this.selectedFundingTypes$;
		return {
			archived: 0,
			isExternalPortfolio: false,
			preArchivedOn: { '%is_null': '' },
			fundingType: selectedFundingTypes.length > 0 ? selectedFundingTypes : undefined
		};
	}

	private getCommonVintageDetailConfig(): Omit<VintageDetailConfig, 'filter'> {
		return {
			fieldList: [
				...this.vintageDetailConfig.fieldList,
				'doctor -> id, name',
				'medicalFacility -> id, name',
				'medicalProcedureType.title'
			],
			columnDefinitions: [
				...this.vintageDetailConfig.columnDefinitions,
				{
					headerName: 'Doctor',
					valueGetter: ({ data }) => data?.doctor?.name,
					cellRenderer: ({ data, value }) => this.agUtils.doctorLinkRenderer(data?.doctor?.id, value),
					filter: 'agSetColumnFilter',
					filterParams: {
						values: (params) => this.codelistService.getByUrl(UrlCodelistLink.Doctors)
							.pipe(takeUntil(this.destroy$))
							.subscribe(doctors =>
								params.success(this.agUtils.getArrayOfStringsByFiledName(doctors, 'name'))),
						buttons: ['clear', 'reset'],
					},
					sortable: true,
				},
				{
					headerName: 'Medical Facility',
					valueGetter: ({ data }) => data?.medicalFacility?.name ?? '',
					cellRenderer: ({ data, value }) => this.agUtils.medicalFacilityLinkRenderer(data?.medicalFacility?.id, value),
					filter: 'agSetColumnFilter',
					filterParams: {
						values: (params) => {
							this.codelistService.getByUrl(UrlCodelistLink.MedicalFacilities)
								.pipe(takeUntil(this.destroy$))
								.subscribe(medicalFacilities => {
									params.success(this.agUtils.getArrayOfStringsByFiledName(medicalFacilities, 'name'));
								});
						},
						buttons: ['clear', 'reset'],
					},
					sortable: true,
				},
				{
					headerName: 'Procedure',
					valueGetter: ({ data }) => data?.medicalProcedureType?.title ?? '',
					filter: 'agSetColumnFilter',
					filterParams: {
						values: (params) => {
							this.codelistService.get<MedicalProcedureType[]>(NonUrlCodelistName.MedicalProcedureType)
								.pipe(takeUntil(this.destroy$))
								.subscribe(procedureTypes =>
									params.success(this.agUtils.getArrayOfStringsByFiledName(procedureTypes, 'title')));
						},
						buttons: ['clear', 'reset'],
					},
				}
			]
		}
	}

	get selectedFundingTypes$() {
		return firstValueFrom(this.dashboardFilter.selectedFundingTypes$.pipe(timeout(1000)));
	}

	public completeDestroyObs(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.vintageDetailConfig.unsubscribe();
	}
}
