import { CartesianTooltipCreator } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/cartesian-tooltip-creator';
import { ChartSettingsStorage } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/chart-settings.storage';
import { CommonSavedChart } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/common-saved.chart';
import { CartesianGroupedTooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/cartesian-grouped-tooltip-theme.service';
import { ChartModel } from 'ag-grid-community';
import { AgCartesianSeriesOptions } from 'ag-grid-community/dist/lib/interfaces/iAgChartOptions';

export class CartesianSavedChart extends CommonSavedChart<AgCartesianSeriesOptions> {

	constructor(storageKey: string, axesFormatter, tooltipTheme?, private modelIndex = 0) {
		super(storageKey);
		const tooltipCreator = new CartesianTooltipCreator();
		tooltipCreator.setAxesFormatter(axesFormatter);
		this.dashboardChartStorage = new ChartSettingsStorage(tooltipCreator);
		this.tooltipTheme = tooltipTheme ?? new CartesianGroupedTooltipThemeService();
	}

	public getTooltipTheme() {
		return this.tooltipTheme;
	}

	public saveChart(models: ChartModel[]) {
		this.dashboardChartStorage.saveChartModel(this.storageKey, models);
	}

	public getSavedChart(): ChartModel | null {
		return super.getSavedChart(this.modelIndex);
	}

}
