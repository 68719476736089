<div class="flex">
	<ng-select [formControl]="labelsControl"
	           class="bg-ignore select-labels"
	           [multiple]="true"
	           [items]="labels$ | async"
	           bindLabel="title"
	           bindValue="id"
	           data-cy="fundingLabels-select"
	           [hideSelected]="true"
	           [closeOnSelect]="false"
	           placeholder="Add labels">
		<ng-template ng-label-tmp let-item="item" let-clear="clear">
			<entity-label-badge [label]="item" (onRemove)="clear(item)"></entity-label-badge>
		</ng-template>
		<ng-template ng-option-tmp let-item="item">
			<div class="flex">
				<span class="option filter-color" data-cy="label-title" [ngStyle]="{'color': item.colorHex}">{{item.title}}</span>
			</div>
		</ng-template>
	</ng-select>
	<svg-icon icon="clear" fillColor="red" class="svg-icon cursor-pointer" data-cy="cancel-button" (throttleClick)="cancelAdding()"></svg-icon>
	<fa-icon [icon]="faIcons.check" class="fundingLabel-submit cursor-pointer" data-cy="submit-btn" (throttleClick)="save()"></fa-icon>
</div>
