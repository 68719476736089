import { Injectable } from '@angular/core';
import { ConfirmationDialogComponent } from '@app/shared/confirmation-dialog/confirmation-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
	providedIn: 'root'
})
export class ConfirmClickService {

	constructor(private modalService: BsModalService) {}

	public createModalInstance(event: Event, confirmSubtitle = ''): BsModalRef {
		event?.stopPropagation();
		const modalConfig = {
			initialState: { subtitle: confirmSubtitle },
			class: 'modal-sm', ignoreBackdropClick: true
		};
		return this.modalService.show(ConfirmationDialogComponent, modalConfig);
	}
}
