import { Persistable } from '@app/core/data-watch/utils';
import { FilterVisitor } from '@app/shared/filter-visitors/models/filter-visitor';
import { EntityId } from '@app/shared/model/types/entity-id';
import { unassignedUser } from '@app/shared/user-filter/model/user-filter-constants';
import * as _ from 'lodash';
import { Observable, map } from 'rxjs';

export class IdFilterVisitor<T extends Persistable> extends FilterVisitor<EntityId[]> {

	constructor(private path: string, id: string = null) {
		super(id ?? path);
	}

	public filter(filterObject: T): boolean {
		if (this.value == null || this.value.length == 0) {
			return true;
		}
		const entityId = _.get(filterObject, `${this.path}.id`, -1);
		const valueIds = this.value.map(value => value.toString());
		return valueIds.includes(entityId.toString());
	}

	public isActive(): boolean {
		return this.value != null && this.value.length > 0;
	}

	getActiveOptions = (options$: Observable<any>) => options$.pipe(map(allOptions => {
		const savedOption = this.value?.map(filteredValue => filteredValue.toString()) ?? [];
		const initialActiveValues = allOptions.filter(option => savedOption.includes(option.id.toString()));
		if (savedOption.includes(unassignedUser.id.toString())) {
			initialActiveValues.push(unassignedUser);
		}
		return initialActiveValues;
	}));
}
