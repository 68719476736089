// call types
// TODO: Get rid of numbers from inbound/outbound
export const inboundCallType = 1;
export const outboundCallType = 2;

// call receiver (Speaking with)
export const plaintiffCallReceiver = 1;
export const lawOfficeCallReceiver = 2;

export const defaults = {
	type: {
		inbound: inboundCallType,
		outbound: outboundCallType
	},
	receiver: {
		plaintiff: plaintiffCallReceiver,
		office: lawOfficeCallReceiver
	}
};

export enum CallType {
	Inbound = 'inbound',
	OutBound = 'outbound',
}

export enum MedicalReachOutTarget {
	LawFirm = 'lawFirm',
	Doctor = 'doctor',
	Client = 'client',
	Patient = 'patient',
	Facility = 'facility',
}

export enum MedicalReachOutTargetTitle {
	LawFirm = 'Law Firm',
	Doctor = 'Doctor',
	Client = 'Client',
	Patient = 'Patient',
	Facility = 'Facility',
}
