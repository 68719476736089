import { Injectable } from '@angular/core';
import { GaCollectionComponent } from '@app/shared/ga-components/components/collection/ga-collection.component';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EntityScopeService {
	uid: string;
	entity: any = {};
	entityClass;
	emitter = new BehaviorSubject<any>({});
	gaCollection: GaCollectionComponent;
	syncinc$ = new Subject<boolean>();
	syncCounter = 0;
	private newFields = [];
	private _fieldList = ['id'];

	constructor(private apiLink: GaApiLink) {
	}

	public setEntity(nextValue) {
		this.entity = nextValue;

		//gaCollection controller is set by collection item component
		if (this.gaCollection) {
			//propagate changes upwards
			this.gaCollection.updateCollectionItem(this.uid, nextValue);
		} else {
			//propagate downwards
			this.emitter.next(nextValue);
		}
	}

	public setEntityId(id) {
		this.entity.id = id;
	}

	public retrieveData(id) {
		this._fieldList = _.union(this.newFields, this._fieldList);
		this.newFields = [];

		if (this._fieldList.length <= 1) {
			console.warn('Attempting to load empty fieldlist, please investigate');
			return;
		}

		this.apiLink.retrieve(this.entityClass, this._fieldList, { id }).subscribe((data) => {
			this.entity = data[0];
			this.emitter.next(data[0]);
		});
	}

	public addField(dependencies: string[]) {
		if (this.gaCollection) {
			this.gaCollection.addField(dependencies);
		}
		const notLoaded = _.difference(dependencies, this._fieldList);

		this.newFields = _.union(notLoaded, this.newFields);
		return this.entity.id ? this.newFields : []; //return fields that has actually been added
	}

	public synchronize() {
		if (this.newFields.length > 0 && this.entity.id) {
			this.syncinc$.next(true);
			const toLoadFields = [...this.newFields];
			this._fieldList = _.union(this.newFields, this._fieldList);
			this.newFields = [];
			this.reload(toLoadFields);
		}
	}

	public reload(toLoadFields = this.fieldList) {
		this.syncCounter++;
		this.apiLink.retrieve(this.entityClass, toLoadFields, { id: this.entity.id }).subscribe((data) => {
			// see: https://jsfiddle.net/vo3k25mq/
			this.entity = _.mergeWith(this.entity, data[0], (origin, updateWith) => {
				if (Array.isArray(origin)) {
					// first merge elements data together
					// removed elements are still there, so remove them with intersection
					return _.intersectionBy(_.merge(origin, updateWith), updateWith, 'id');
				}
			});
			this.emitter.next(this.entity);

			this.syncCounter--;
			if (this.syncCounter === 0) {
				this.syncinc$.next(false);
			}
		});
	}

	get fieldList(): string[] {
		return this._fieldList;
	}
}
