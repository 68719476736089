import { Pipe, PipeTransform } from '@angular/core';
import { BoardSwitchFunding, Funding } from '@app/shared/model/types/funding';
import { BoardHelperService } from '@app/w-boards/services/board-helper.service';

@Pipe({ name: 'whiteboardFundingLocation' })
export class WhiteboardFundingLocationPipe implements PipeTransform {

	constructor(private boardHelperService: BoardHelperService) {}

	public transform(funding: Funding | BoardSwitchFunding | null, separator = ', '): string {
		const boards = funding?.boards;
		let tooltip = '';
		boards?.forEach((board, index) => {
			if (index > 0) {
				tooltip = `${tooltip}${separator}`;
			}
			const resolvedBoard = this.boardHelperService.resolveBoard(funding, board);
			if (resolvedBoard !== null) {
				tooltip = `${tooltip}${resolvedBoard.name}: ${resolvedBoard.column}`;
			}
		});
		return tooltip;
	}
}
