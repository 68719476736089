import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { confirmPasswordValidator } from '@app/public/new-password/confirm-password.validator';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';

@Component({
	selector: 'new-password-form',
	templateUrl: './new-password.component.html'
})
export class NewPasswordComponent implements OnInit, OnDestroy {

	shake = false;

	newPasswordForm = new FormGroup({
		newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
		confirmPassword: new FormControl('', [Validators.required])
	}, { validators: confirmPasswordValidator });

	readonly subtitle = 'Please setup your new password. Password must be least 6 character long.';
	readonly faIcons = {
		exclamationCircle: faExclamationCircle,
	};

	private destroy$ = new Subject<void>();
	private queryParamsToken: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private http: HttpClient,
		private flashMessage: FlashMessageService,
		private router: Router) {
	}

	public ngOnInit(): void {
		this.queryParamsToken = this.activatedRoute.snapshot.queryParams?.token;
	}

	public setPassword(): void {
		this.shake = this.newPasswordForm.invalid;
		if (this.newPasswordForm.valid) {
			const headers = new HttpHeaders().set('InterceptorSkipHeader', '');
			this.http.post(
				this.activatedRoute.snapshot.data?.setAsNew ? '/api/users/set-up-password' : '/api/reset-password',
				{
					password: this.newPasswordForm.value?.newPassword,
					token: this.queryParamsToken
				},
				{ headers })
				.subscribe(() => {
					this.flashMessage.showMessage(FlashMessageType.Success, 'New password has been saved.', { timeout: 10000 });
					this.router.navigate(['/login']);
				}, (errorResponse: unknown) => {
					console.error(errorResponse);
					this.flashMessage.showMessage(
						FlashMessageType.Danger, 'There was an error processing your request', { timeout: 10000 }
					);
				});
		}
	}

	get newPassword(): AbstractControl | null {
		return this.newPasswordForm.get('newPassword');
	}

	get confirmPassword(): AbstractControl | null {
		return this.newPasswordForm.get('confirmPassword');
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
