import { Codelist } from '@app/shared/model/types/codelists/codelist';

export interface FundsDeliveryOption extends Codelist {
	check: boolean;
	wire: boolean;
	trackingNumber: boolean;
	systemCode: FundsDeliveryOptionSystemCodes;
}

export enum FundsDeliveryOptionSystemCodes {
	USPS = 'usps',
	FedEx = 'fedex',
	Wire = 'wire',
	PickUp = 'pick_up',
	WesternUnion = 'western_union',
	CreditCard = 'credit_card'
}
