export const PaymentsColumnNames = {
	na: 'N/A',
	fedEx: 'FedEx',
	westernUnion: 'Western Union',
	wire: 'Wire',
	usps: 'USPS',
	creditCard: 'Credit Card',
	pickUp: 'Pick Up',
	mri: 'MRI'
}
