import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { TodoCategoryCodelist } from '@app/shared/model/types/codelists/todo-category-codelist';
import { TodoCategory } from '@app/todo/model/todo-category';
import { map, Observable } from 'rxjs';

@Component({
	selector: 'todo-category-list',
	templateUrl: './todo-category-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoCategoryListComponent implements OnInit {

	@Input() activeCategories: TodoCategory[];

	@Output() selectCategory: EventEmitter<TodoCategory> = new EventEmitter<TodoCategory>();

	categories$: Observable<TodoCategoryCodelist[]>;

	constructor(public codeListService: CodelistRepositoryService) {
	}

	public ngOnInit(): void {
		this.categories$ = this.codeListService.get<TodoCategoryCodelist[]>('todoCategory')
			.pipe(
				map((categories: TodoCategoryCodelist[]) => categories.map(category => {
					delete category.entityString;
					return category
				}))
			);
	}

	public isActive(category) {
		return this.activeCategories.find((item) => item.id === category.id);
	}

	public setCategory(category: TodoCategory): void {
		this.selectCategory.emit(category);
	}
}
