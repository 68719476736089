import { Injectable } from '@angular/core';
import { ReportOptionsModalLayout1Component } from '@app/reports/modals/report-options/report-options-modal-layout-1.component';
import { ReportOptionsModalLayout2Component } from '@app/reports/modals/report-options/report-options-modal-layout-2.component';
import { ServicingOptionsModalComponent } from '@app/reports/modals/servicing-report/servicing-options-modal.component';
import { StaticPoolOptionsModalComponent } from '@app/reports/modals/static-pool-report/static-pool-options-modal.component';
import { VintageDetailConfig } from '@app/shared/modals/vintage-detail/vintage-detail-config';
import { VintageDetailModalComponent } from '@app/shared/modals/vintage-detail/vintage-detail-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable()
export class ModalsService {

	constructor(
		private modalService: BsModalService) {
	}

	public openVintageDetail(config: VintageDetailConfig): void {
		this.modalService.show(VintageDetailModalComponent, {
			initialState: { config: config },
			class: 'modal-xl centered-modal-xl',
		});
	}

	public openStaticPoolOptionsModal(): void {
		this.modalService.show(StaticPoolOptionsModalComponent);
	}

	public openReportOptionsModalLayout1(reportUrl: string): void {
		this.modalService.show(ReportOptionsModalLayout1Component, {
			initialState: { reportUrl: reportUrl },
			class: 'modal-sm'
		});
	}

	public openReportOptionsModalLayout2(reportUrl: string): void {
		this.modalService.show(ReportOptionsModalLayout2Component, {
			initialState: { reportUrl: reportUrl },
			class: 'modal-sm'
		});
	}

	public openServicingOptionsModal(): void {
		this.modalService.show(ServicingOptionsModalComponent);
	}
}
