import { formatPercent } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardDataUtilsService } from '@app/dashboard/dashboard-data-utils.service';
import { ConfigurationChangedEvent } from '@app/dashboard/shared/time-related-configuration/time-related-configuration.model';
import { SummaryStatisticsResponse } from '@app/dashboard/summary-statistics/summary-statistics.model';
import { AgActionButtonsRendererComponent } from '@app/shared/ag-grid-utils/ag-action-buttons-renderer/ag-action-buttons-renderer.component';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ColDef } from 'ag-grid-community';
import { get } from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class SummaryStatisticsService {

	constructor(
		private dashboardDataUtils: DashboardDataUtilsService,
		private http: HttpClient) {
	}

	public getTotalFundedData$(
		configuration: ConfigurationChangedEvent,
		fundingTypeParams: HttpParams): Observable<SummaryStatisticsResponse[]> {
		let params = new HttpParams()
			.set('resolution', configuration.resolution)
			.set('startDate', configuration.from)
			.set('endDate', configuration.to);

		fundingTypeParams.getAll('fundingType[]').forEach(id => {
			params = params.append('fundingType[]', id);
		})
		return this.http.get<SummaryStatisticsResponse[]>('/api/dashboard/summary-funded', { params });
	}

	public getSettledCollectedData$(
		configuration: ConfigurationChangedEvent,
		fundingTypeParams: HttpParams): Observable<SummaryStatisticsResponse[]> {
		let params = new HttpParams()
			.set('resolution', configuration.resolution)
			.set('startDate', configuration.from)
			.set('endDate', configuration.to);

		fundingTypeParams.getAll('fundingType[]').forEach(id => {
			params = params.append('fundingType[]', id);
		})
		return this.http.get<SummaryStatisticsResponse[]>('/api/dashboard/summary-settled-collected', { params });
	}

	public getGrowthDeclineColDef(): ColDef {
		return {
			valueGetter: ({ data, colDef }) => get(data, colDef.field, 0) / 100,
			cellRenderer: ({ value }) => `<span class="${value < 0 ? 'text-danger' : 'text-navy'}">
											${formatPercent(value, 'en-US', '1.0-0')}
										</span>`,
			filter: 'agNumberColumnFilter',
			cellClass: 'percentage',
			headerName: '\u{2B06}/\u{2B07}',
			headerTooltip: 'Growth/Decline over 3 periods',
			minWidth: 70,
			width: 70,
			chartDataType: 'excluded'
		};
	}

	public getInfoButtonColDef(modalAction): ColDef {
		return {
			headerName: '',
			filter: false,
			sortable: false,
			field: '',
			minWidth: 70,
			width: 70,
			cellRenderer: AgActionButtonsRendererComponent,
			cellRendererParams: {
				buttonParams: [{
					cssClass: 'btn btn-info-icon btn--grid',
					title: '',
					icon: faInfoCircle,
					action: ({ dataObj }) => {
						modalAction(this.dashboardDataUtils.getPeriod(dataObj));
					}
				}]
			},
			chartDataType: 'excluded'
		};
	}
}
