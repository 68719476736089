import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { ObjectStoreMeta } from 'ngx-indexed-db';

export const doctorDbConfig: ObjectStoreMeta = {
	store: UrlCodelistLink.Doctors,
	storeConfig: { keyPath: 'id', autoIncrement: false },
	storeSchema: [
		{ name: 'id', keypath: 'id', options: { unique: true } },
		{ name: 'active', keypath: 'active', options: { unique: false } },
		{ name: 'addresses', keypath: 'addresses', options: { unique: false } },
		{ name: 'liens', keypath: 'liens', options: { unique: false } },
		{ name: 'name', keypath: 'name', options: { unique: false } },
		{ name: 'pmConsultRequired', keypath: 'pmConsultRequired', options: { unique: false } },
		{ name: 'preferredDoctor', keypath: 'preferredDoctor', options: { unique: false } },
		{ name: 'pursuing', keypath: 'pursuing', options: { unique: false } },
		{ name: 'quarterback', keypath: 'quarterback', options: { unique: false } },
		{ name: 'requirements', keypath: 'requirements', options: { unique: false } },
		{ name: 'rpCost', keypath: 'rpCost', options: { unique: false } },
		{ name: 'sellingProfessionalLiens', keypath: 'sellingProfessionalLiens', options: { unique: false } },
		{ name: 'sellingTechnicalLiens', keypath: 'sellingTechnicalLiens', options: { unique: false } },
		{ name: 'spanish', keypath: 'spanish', options: { unique: false } },
		{ name: 'specialization', keypath: 'specialization', options: { unique: false } },
		{ name: 'telemed', keypath: 'telemed', options: { unique: false } },
		{ name: 'tier', keypath: 'tier', options: { unique: false } },
		{ name: 'userAvatar', keypath: 'userAvatar', options: { unique: false } },
	]
}
