import { formatNumber } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { AclService } from '@app/core/security/acl.service';
import { GroupedFundingsListComponent } from '@app/dashboard/grouped-fundings-list/grouped-fundings-list.component';
import { SalesReportData, SalesReportFilter } from '@app/dashboard/sales-report/sales-report.model';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { OpenModalRendererComponent, OpenModalRendererParams } from '@app/dashboard/shared/open-modal-renderer.component';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { currencyColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/currency-column-definition';
import { dateColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/date-column-definition';
import { numberColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/number-column-definition';
import { ModalsService } from '@app/shared/modals/modals.service';
import { ApprovalStatus } from '@app/shared/model/constants/approval-status';
import { datePickerDefaultConfig } from '@app/shared/model/constants/date-picker-default-config';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { StatisticsRepositoryService } from '@app/shared/model/statistics-repository.service';
import { Codelist } from '@app/shared/model/types/codelists/codelist';
import { EntityId } from '@app/shared/model/types/entity-id';
import { OverlayPanelComponent } from '@app/shared/overlay-panel/overlay-panel.component';
import { DownloadLinkConfig } from '@app/widgets/download-link/download-link-config.model';
import { faCogs, faDownload, faTable } from '@fortawesome/free-solid-svg-icons';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, filter, map, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'sales-report',
	templateUrl: './sales-report.component.html',
	styles: ['ag-grid-angular {width: 100%; height: 500px;}']
})
export class SalesReportComponent implements OnDestroy, OnInit {
	gridOptions: GridOptions;
	rowData$: Observable<SalesReportData[]>;
	columnDefs: ColDef[];
	filterEmitter$ = new BehaviorSubject<SalesReportFilter>(null);
	externalLeadSources$: Observable<Codelist[]>;
	externalLeadSourceControl = new FormControl<EntityId[]>(null, [Validators.required]);
	dateRangeControl = new FormControl<[Date, Date]>([
		moment().subtract(10, 'weeks').startOf('week').toDate(),
		moment().endOf('week').toDate()
	]);
	downloadLink$: Subject<DownloadLinkConfig> = new Subject<DownloadLinkConfig>();

	readonly faIcons = {
		download: faDownload,
		cogs: faCogs,
		table: faTable
	};

	readonly bsDaterangeConfig = {
		...datePickerDefaultConfig,
		containerClass: 'custom-datepicker-theme without-arrow'
	}

	private fundingType: number[];
	private destroy$ = new Subject<void>();

	constructor(
		private agGridUtils: AgGridUtilsService,
		private dashboardFilterStore: DashboardFilterStore,
		private statisticsRepository: StatisticsRepositoryService,
		private codelistRepository: CodelistRepositoryService,
		private modals: ModalsService,
		private acl: AclService,
		private modalService: BsModalService,
	) {
	}

	public ngOnInit(): void {
		this.gridOptions = this.agGridUtils.getGridOptions({
			suppressExcelExport: !this.acl.isAllowed('export.dashboard_sales_report'),
			defaultExcelExportParams: {
				fileName: 'Sales Report'
			},
			defaultColDef: {
				width: 120
			},
		});

		this.columnDefs = [
			{
				headerName: 'Start Date',
				...dateColumnDefinition('from')
			},
			{
				headerName: 'End Date',
				...dateColumnDefinition('to')
			},
			{
				headerName: 'New Fundings',
				...numberColumnDefinition('newDealsCount'),
				cellRenderer: OpenModalRendererComponent,
				cellRendererParams: <OpenModalRendererParams>{
					showLink: ({ value }) => value > 0,
					showDetail: ({ data }) => this.openNewFundings(data.year, data.week)
				}
			},
			{
				headerName: 'Re-Up Fundings',
				...numberColumnDefinition('reUpDealsCount'),
				cellRenderer: OpenModalRendererComponent,
				cellRendererParams: <OpenModalRendererParams>{
					showLink: ({ value }) => value > 0,
					showDetail: ({ data }) => this.openReUpFundings(data.year, data.week)
				}
			},
			{
				headerName: 'Total Fundings',
				...numberColumnDefinition('dealsCount'),
				cellRenderer: OpenModalRendererComponent,
				cellRendererParams: <OpenModalRendererParams>{
					showLink: ({ value }) => value > 0,
					showDetail: ({ data }) => this.openTotalFundings(data.year, data.week)
				}
			},
			{
				headerName: 'New Fundings',
				...currencyColumnDefinition('newDealsAmount'),
				cellRenderer: OpenModalRendererComponent,
				cellRendererParams: <OpenModalRendererParams>{
					showLink: ({ value }) => value > 0,
					showDetail: ({ data }) => this.openNewFundings(data.year, data.week),
				}
			},
			{
				headerName: 'Re-Up Fundings',
				...currencyColumnDefinition('reUpDealsAmount'),
				cellRenderer: OpenModalRendererComponent,
				cellRendererParams: <OpenModalRendererParams>{
					showLink: ({ value }) => value > 0,
					showDetail: ({ data }) => this.openReUpFundings(data.year, data.week),
				}
			},
			{
				headerName: 'Total Fundings',
				cellRenderer: OpenModalRendererComponent,
				cellRendererParams: <OpenModalRendererParams>{
					showLink: ({ value }) => value > 0,
					showDetail: ({ data }) => this.openTotalFundings(data.year, data.week),
				},
				...currencyColumnDefinition('dealsAmount')
			},
			{
				headerName: 'Current Attorneys',
				...numberColumnDefinition('currentAttorneys'),
				valueGetter: ({ data }) => data?.currentAttorneys?.length,
				valueFormatter: ({ value }) => value != null ? formatNumber(value, 'en-US', '1.0-2') : '',
				cellRenderer: OpenModalRendererComponent,
				cellRendererParams: <OpenModalRendererParams>{
					showLink: ({ value }) => value > 0,
					showDetail: ({ data }) => this.openAttorneysDetail(data?.currentAttorneys)
				}
			},
			{
				headerName: 'Brand New Attorneys',
				...numberColumnDefinition('newAttorneys'),
				valueGetter: ({ data }) => data?.newAttorneys?.length,
				valueFormatter: ({ value }) => value != null ? formatNumber(value, 'en-US', '1.0-2') : '',
				cellRenderer: OpenModalRendererComponent,
				cellRendererParams: <OpenModalRendererParams>{
					showLink: ({ value }) => value > 0,
					showDetail: ({ data }) => this.openAttorneysDetail(data?.newAttorneys)
				}
			},
			{
				headerName: 'Total Attorneys',
				...numberColumnDefinition('totalAttorneys'),
				valueGetter: ({ data }) => data?.totalAttorneys?.length,
				valueFormatter: ({ value }) => value != null ? formatNumber(value, 'en-US', '1.0-2') : '',
				cellRenderer: OpenModalRendererComponent,
				cellRendererParams: <OpenModalRendererParams>{
					showLink: ({ value }) => value > 0,
					showDetail: ({ data }) => this.openAttorneysDetail(data?.totalAttorneys)
				}
			},
			{
				headerName: 'Approvals Amount',
				...currencyColumnDefinition('approvalsAmount')
			},
			{
				headerName: 'Approvals Count',
				...numberColumnDefinition('approvalsCount')
			},
			{
				headerName: '# Fundings / Attorneys',
				...numberColumnDefinition('fundingsPerAttorneyCount'),
				minWidth: 140
			},
			{
				headerName: '$ Fundings / Attorneys',
				...currencyColumnDefinition('fundingsPerAttorneyAmount'),
				minWidth: 140
			},
		];

		this.externalLeadSources$ = this.codelistRepository.get<Codelist[]>(NonUrlCodelistName.ExternalLeadSource);
		this.externalLeadSources$
			.pipe(
				map(externalLeadSources => externalLeadSources.map(leadSource => leadSource.id)),
				takeUntil(this.destroy$)
			).subscribe(externalLeadSources => {
			this.externalLeadSourceControl.setValue(externalLeadSources);
			const [fromDate, toDate] = this.dateRangeControl.value;
			this.filterEmitter$.next({
				externalLeadSources, dateRange: { fromDate, toDate }
			});
		});

		this.rowData$ = combineLatest([
			this.filterEmitter$.asObservable(),
			this.dashboardFilterStore.selectedFundingTypes$.pipe(tap((fundingType) => this.fundingType = fundingType)),
		]).pipe(
			filter(([filterEmitter, fundingTypes]) => !!filterEmitter && !!fundingTypes),
			switchMap(([{ externalLeadSources, dateRange }, fundingTypes]) => {
				return this.statisticsRepository.getIndicatorStats(
					externalLeadSources,
					this.stringifyDate(dateRange.fromDate),
					this.stringifyDate(dateRange.toDate),
					fundingTypes
				)
			}),
			map((data) => {
					return Object.values(data).map((item) => {
						const week = +_.last(item.week.split('-'));
						return {
							...item,
							week,
							fundingsPerAttorneyCount: item.currentAttorneys > 0 ? item.dealsCount / item.currentAttorneys : 0,
							fundingsPerAttorneyAmount: item.currentAttorneys > 0 ? item.dealsAmount / item.currentAttorneys : 0,
							...this.getStartEndDateAsString(item.year, week)
						};
					})
				}
			)
		);
	}

	get externalLeadSources(): EntityId[] {
		return this.externalLeadSourceControl.value;
	}

	public openTotalFundings(year: number, week: number): void {
		const jqlFilter = {
			fundedOn: this.createDataRangeFromWeekNumber(year, week),
			approvalStatus: [ApprovalStatus.Funded, ApprovalStatus.Settled],
			fundingType: this.fundingType.length > 0 ? this.fundingType : undefined,
			externalLeadSource: { '%in': this.externalLeadSources }
		};
		this.modals.openVintageDetail({ filter: jqlFilter });
	}

	public openNewFundings(year: number, week: number): void {
		const jqlFilter = {
			fundedOn: this.createDataRangeFromWeekNumber(year, week),
			approvalStatus: [ApprovalStatus.Funded, ApprovalStatus.Settled],
			nthFunded: 1,
			fundingType: this.fundingType.length > 0 ? this.fundingType : undefined,
			externalLeadSource: { '%in': this.externalLeadSources }
		};
		this.modals.openVintageDetail({ filter: jqlFilter });
	}

	public openReUpFundings(year: number, week: number): void {
		const jqlFilter = {
			fundedOn: this.createDataRangeFromWeekNumber(year, week),
			approvalStatus: [ApprovalStatus.Funded, ApprovalStatus.Settled],
			nthFunded: { '%gte': 2 },
			fundingType: this.fundingType.length > 0 ? this.fundingType : undefined,
			externalLeadSource: { '%in': this.externalLeadSources }
		};
		this.modals.openVintageDetail({ filter: jqlFilter });
	}

	public openAttorneysDetail(attorneyIds: string[]): void {
		const [startDate, endDate] = this.dateRangeControl.value;
		const config = {
			attorneyIds,
			startDate,
			endDate,
			externalLeadSources: this.externalLeadSources,
			fundingTypes: this.fundingType.length > 0 ? this.fundingType : undefined
		}

		this.modalService.show(GroupedFundingsListComponent, {
			class: 'modal-xl',
			initialState: config
		});
	}

	public selectExternalLeadSources(leadSources: Codelist[]): void {
		const ids = leadSources.map(leadSource => leadSource.id);
		this.externalLeadSourceControl.setValue(ids);
	}

	public clearExternalLeadSources(): void {
		this.externalLeadSourceControl.reset();
	}

	public apply(panel: OverlayPanelComponent): void {
		panel.toggle();
		const [fromDate, toDate] = this.dateRangeControl.value;
		this.filterEmitter$.next({
			externalLeadSources: this.externalLeadSources, dateRange: { fromDate, toDate }
		});
	}

	private getStartEndDateAsString(year: number, week: number): { from: string, to: string } {
		const fromToAsDates = this.createDataRangeFromWeekNumber(year, week);
		return {
			from: moment(fromToAsDates.from).format('YYYY-MM-DD'),
			to: moment(fromToAsDates.to).format('YYYY-MM-DD')
		};
	}

	private createDataRangeFromWeekNumber(year: number, week: number): { from: Date, to: Date } {
		moment.updateLocale('en', {
			week: {
				dow: 1,
				doy: 4
			}
		});
		const weekMoment = moment().year(year).week(week);
		const startOfWeek = weekMoment.startOf('week').toDate();
		const endOfWeek = weekMoment.endOf('week').startOf('day').toDate();
		moment.updateLocale('en', null);
		return {
			from: startOfWeek,
			to: endOfWeek
		};
	}

	private stringifyDate(date: string | Date): string {
		const local = moment(date);
		return local.isValid() ? local.format('YYYY-MM-DD') : null;
	}

	public onGridReady(params: GridReadyEvent): void {
		params.api.sizeColumnsToFit();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
	}
}
