import { Component, OnDestroy, OnInit } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { ReportOptionsModalComponent } from '@app/reports/modals/report-options/report-options-modal.component';
import { DatePickerRangesService } from '@app/shared/daterange-picker/date-picker-ranges/date-picker-ranges.service';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { CreditFacility } from '@app/shared/model/types/codelists/credit-facility';
import { FundingTypeCodelist } from '@app/shared/model/types/codelists/funding-type';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'report-options-modal-1',
	templateUrl: './report-options-modal-layout-1.component.html'
})
export class ReportOptionsModalLayout1Component extends ReportOptionsModalComponent implements OnInit, OnDestroy {
	config: ReportOptionsLayout1Config = new ReportOptionsLayout1Config();

	readonly faIcons = {
		checkSquare: faCheckSquare,
		square: faSquare
	};
	private destroy$ = new Subject<void>();

	constructor(dateRangesService: DatePickerRangesService,
				codelistService: CodelistRepositoryService,
				bsModalRef: BsModalRef) {
		super(dateRangesService, codelistService, bsModalRef);
	}

	public ngOnInit(): void {
		this.init();
		this.codelistService.get<CreditFacility[]>(NonUrlCodelistName.CreditFacility)
			.pipe(takeUntil(this.destroy$))
			.subscribe((facilities: CreditFacility[]) => {
				_.each(facilities, (item) => {
					this.config.creditFacilities.push({id: item.id.toString(), title: item.title, selected: true});
				});
			});
		this.codelistService.get<FundingTypeCodelist[]>(NonUrlCodelistName.FundingType)
			.pipe(takeUntil(this.destroy$))
			.subscribe((fundingTypes: FundingTypeCodelist[]) => {
				_.each(fundingTypes, (item, index) => {
					this.config.fundingTypes.push({id: item.id.toString(), title: item.title, selected: index === 0});
				});
			});
	}

	public selectAll(checkboxes: CheckboxConfig[]): void {
		_.each(checkboxes, (checkbox: CheckboxConfig) => {
			checkbox.selected = true;
		});
	}

	public selectNone(checkboxes: CheckboxConfig[]): void {
		_.each(checkboxes, (checkbox: CheckboxConfig) => {
			checkbox.selected = false;
		});
	}

	public download(): void {
		const params = {
			fundingTypes: JSON.stringify(_.chain(this.config.fundingTypes).filter({selected: true}).map('id').value()),
			creditFacilities: JSON.stringify(_.chain(this.config.creditFacilities).filter({selected: true}).map('id').value()),
			from: this.config.from,
			to: this.config.to
		};
		super.download(params);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}

export class ReportOptionsLayout1Config {
	from = '';
	to = '';
	creditFacilities: CheckboxConfig[] = [];
	fundingTypes: CheckboxConfig[] = [];
}

export interface CheckboxConfig {
	id: string,
	title: string
	selected: boolean
}
