import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { JqlAsyncValidatorOptions } from '@app/shared/ga-components/utils/jql-async-validator-options';
import { UniqueFieldValidatorRequest } from '@app/shared/ga-components/utils/unique-field-validator-request';
import { ValidationHelper } from '@app/shared/ga-components/utils/validation-helper';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UniqueFieldValidator {
	private previousRequests: UniqueFieldValidatorRequest[] = [];

	constructor(private validationHelper: ValidationHelper) {}

	public validate(options: JqlAsyncValidatorOptions): AsyncValidatorFn {
		return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
			return new Promise(resolve => {
				if (control.untouched) {
					resolve(null);
					return;
				}
				const currentFieldValidationRequest =
					new UniqueFieldValidatorRequest(control.parent.get('id').value, null, control.value, options.entityClass);
				let lastRequest = null;
				const lastRequests = [];
				this.previousRequests.forEach(request => {
					if (request.isSame(currentFieldValidationRequest)) {
						lastRequest = request;
					}
					if (request.timeToLive > 0) {
						lastRequests.push(request);
					}
				});
				this.previousRequests = lastRequests;
				if (lastRequest) {
					resolve(lastRequest.response);
					return;
				}
				this.validationHelper.validate(options, control.value).subscribe(result => {
					result = result.filter(item => item.id != currentFieldValidationRequest.fieldId);
					currentFieldValidationRequest.response = result.length > 0 ? { 'uniqueField': options.errorMsg(result) } : null;
					this.previousRequests.push(currentFieldValidationRequest);
					resolve(currentFieldValidationRequest.response);
				});
			});
		};
	}

	public addRequest(request: UniqueFieldValidatorRequest): void {
		const index = this.previousRequests.findIndex(previousRequest => previousRequest.isSame(request, false));
		if (index == -1) {
			this.previousRequests.push(request);
		} else {
			this.previousRequests[index] = request;
		}
	}

}
