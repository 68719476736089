import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { loadTodos } from '@app/core/todo-store/todo.actions';
import { selectSidebarTodos } from '@app/core/todo-store/todo.selectors';
import { TodoStatus } from '@app/shared/model/constants/todo-status';
import { Todo } from '@app/shared/model/types/todo';
import { TodoCardData } from '@app/todo/model/todo-card-data';
import { NewTodoModalComponent } from '@app/todo/new-todo-modal/new-todo-modal.component';
import { TodoSortService } from '@app/todo/services/todo-sort.service';
import { TodoSidebarService } from '@app/todo/todo-sidebar/service/todo-sidebar.service';
import { TodosListWidgetComponent } from '@app/todo/todos-list-widget/todos-list-widget.component';
import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { filter, Observable, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'todo-sidebar',
	templateUrl: 'todo-sidebar.component.html',
	styleUrls: ['todo-sidebar.component.scss'],
	providers: [TodoSidebarService, TodoSortService]
})
export class TodoSidebarComponent implements OnInit, OnDestroy {
	@ViewChild(TodosListWidgetComponent) todoWidget: TodosListWidgetComponent;
	todos$: Observable<TodoCardData[]>;
	isExpanded$: Observable<boolean>;
	readonly faIcons = {
		plus: faPlus,
		chevronRight: faChevronRight
	};
	private destroy$ = new Subject<void>();
	private currentUserId: number;

	constructor(
		private store: Store<{ todos: Todo[] }>,
		private todoSortService: TodoSortService,
		private authService: AuthService,
		public todoService: TodoSidebarService,
		private modalService: BsModalService,
	) {
	}

	public ngOnInit(): void {
		this.store.dispatch(loadTodos());
		this.currentUserId = this.authService.getCurrentUser().id;
		this.isExpanded$ = this.todoService.isExpanded$;

		this.todos$ = this.todoSortService.sortTodos$(<Observable<TodoCardData[]>>
			(this.store.select(selectSidebarTodos(this.currentUserId))));

		this.isExpanded$.pipe(
			filter((value) => value),
			takeUntil(this.destroy$)
		).subscribe(() => {
			this.todoWidget.setOffset(1);
			this.todoWidget.setFilterStatus(TodoStatus.Todo);
			this.todoWidget.filterTasks();
		})
	}

	public openAddTodoModal(): void {
		this.modalService.show(NewTodoModalComponent)
	}

	public closeSidebar(): void {
		this.todoService.isExpanded = false;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
