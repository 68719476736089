<div class="navigation-sidebar">
	<div class="navigation-sidebar__header">
		<a [routerLink]="['/']" class="logo-element"><img src="/assets/images/rp.png" alt="RP"></a>
	</div>
	<nav class="navigation">
		<div *aclIsAllowed="'application.dashboard'" routerLinkActive="active" class="navigation__item">
			<a [routerLink]="['/dashboard']" class="link">
				<svg-icon [icon]="svgIconNames.Dashboard" class="base-icon base-icon--nav"></svg-icon>
			</a>
			<div class="navigation__item__submenu">
				<a [routerLink]="['/dashboard']" class="navigation__item__submenu__title link">Dashboard</a>
			</div>
		</div>
		<div *aclIsAllowedSome="['sales.dashboard', 'sales.case_managers_tracking']" routerLinkActive="active"
			 class="navigation__item">
			<a [routerLink]="['/sales/board']" class="link">
				<svg-icon [icon]="svgIconNames.Sales" class="base-icon base-icon--nav"></svg-icon>
			</a>
			<div class="navigation__item__submenu">
				<div class="navigation__item__submenu__title">Sales</div>
				<a *aclIsAllowed="'sales.case_managers_tracking'" [routerLink]="['/sales/case-managers-tracking']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Case Manager Tracking</a>
			</div>
		</div>
		<div *aclIsAllowed="'whiteboard.whiteboards'" routerLinkActive="active" class="navigation__item">
			<svg-icon [icon]="svgIconNames.Whiteboard" class="base-icon base-icon--nav"></svg-icon>
			<div class="navigation__item__submenu">
				<div class="navigation__item__submenu__title">Boards</div>
				<a [routerLink]="['/boards/underwriting-board']"
				   routerLinkActive="active"
				   class="navigation__item__submenu__item">
					Underwriting Board
				</a>
				<a [routerLink]="['/boards/law-firm-manager-board']"
				   routerLinkActive="active"
				   class="navigation__item__submenu__item">
					Law Firm Manager Board
				</a>
				<a [routerLink]="['/boards/medical-scheduler-board']"
				   routerLinkActive="active"
				   class="navigation__item__submenu__item">
					Medical Scheduler
				</a>
				<a [routerLink]="['/boards/mri-scheduler-board']"
				   routerLinkActive="active"
				   class="navigation__item__submenu__item">
					MRI Scheduler Board
				</a>
				<a [routerLink]="['/boards/medical-dream-board']"
				   routerLinkActive="active"
				   class="navigation__item__submenu__item">
					Medical Dream Board
				</a>
				<a [routerLink]="['/boards/medical-audit']"
				   routerLinkActive="active"
				   class="navigation__item__submenu__item">
					Medical Audit
				</a>
				<a [routerLink]="['/boards/flagged-applicants']"
				   routerLinkActive="active"
				   class="navigation__item__submenu__item">
					Flagged Applicants
				</a>
				<a [routerLink]="['/boards/kw-board']"
				   routerLinkActive="active"
				   class="navigation__item__submenu__item">
					KW Board
				</a>
				<a [routerLink]="['/boards/csr-board']"
				   routerLinkActive="active"
				   class="navigation__item__submenu__item">
					CSR Board
				</a>
				<a [routerLink]="['/boards/qc-board']"
				   routerLinkActive="active"
				   class="navigation__item__submenu__item">
					QC Board
				</a>
				<a [routerLink]="['/boards/payments-board']"
				   routerLinkActive="active"
				   class="navigation__item__submenu__item">
					Payments Board
				</a>
			</div>
		</div>
		<div
			*aclIsAllowedSome="['applicants.fundings_table', 'applicants.fundings', 'applicants.web_applications', 'applicants.external_portfolio']"
			routerLinkActive="active" class="navigation__item">
			<svg-icon [icon]="svgIconNames.Clients" class="base-icon base-icon--nav"></svg-icon>
			<div class="navigation__item__submenu">
				<div class="navigation__item__submenu__title">Portfolio</div>
				<a *aclIsAllowed="'applicants.fundings'" [routerLink]="['/portfolio/fundings']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Fundings portfolio</a>
				<a *aclIsAllowed="'applicants.pre_archived'" [routerLink]="['/portfolio/pre-archived']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Pre-archived deals</a>
				<a *aclIsAllowed="'applicants.web_applications'" [routerLink]="['/portfolio/web']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Web applications</a>
				<a *aclIsAllowed="'applicants.external_portfolio'" [routerLink]="['/portfolio/external-portfolio']"
				   routerLinkActive="active" class="navigation__item__submenu__item">External portfolio</a>
			</div>
		</div>
		<div
			*aclIsAllowedSome="['law_firms.law_firms_list','settings.insurance_companies', 'crm.doctors', 'crm.medical_facilities']"
			routerLinkActive="active" class="navigation__item">
			<svg-icon [icon]="svgIconNames.Database" class="base-icon base-icon--nav"></svg-icon>
			<div class="navigation__item__submenu">
				<div class="navigation__item__submenu__title">Databases</div>
				<a *aclIsAllowed="'law_firms.law_firms_list'" [routerLink]="['/law-firms']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Law Firms</a>
				<a *aclIsAllowed="'crm.medical_facilities'" [routerLink]="['/medical-facilities']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Medical Facilities</a>
				<a *aclIsAllowed="'settings.insurance_companies'" [routerLink]="['/insurance-companies']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Insurance Companies</a>
				<a *aclIsAllowed="'crm.doctors'" [routerLink]="['/doctors']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Doctors</a>
				<a [routerLink]="['/practice-groups']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Practice Groups</a>
				<a *aclIsAllowed="'law_firms.law_firms_list'" [routerLink]="['/unassigned-attorneys']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Unassigned Attorneys</a>
			</div>
		</div>
		<div
			*aclIsAllowedSome="['servicing.portfolio_follow_up', 'servicing.compliance_servicing', 'servicing.collection_issues', 'servicing.pending_payoffs', 'servicing.stopped_accruals', 'servicing.payment_issues', 'servicing.reduction_files']"
			routerLinkActive="active" class="navigation__item">
			<svg-icon [icon]="svgIconNames.Servicing" class="base-icon base-icon--nav"></svg-icon>
			<div class="navigation__item__submenu">
				<div class="navigation__item__submenu__title">Servicing</div>
				<a *aclIsAllowed="'servicing.compliance_servicing'" [routerLink]="['/servicing/servicing-compliance']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Servicing Compliance</a>
				<a *aclIsAllowed="'servicing.collection_issues'" [routerLink]="['/servicing/collection-issues']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Collection Issues</a>
				<a *aclIsAllowed="'servicing.pending_payoffs'" [routerLink]="['/servicing/pending-payoffs']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Pending Payoffs</a>
				<a *aclIsAllowed="'servicing.disabled_status_updates'"
				   [routerLink]="['/servicing/disabled-status-updates']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Disabled Status Updates</a>
				<a *aclIsAllowed="'servicing.stopped_accruals'" [routerLink]="['/servicing/stopped-accruals']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Stopped Accrual</a>
				<a *aclIsAllowed="'servicing.payment_issues'" [routerLink]="['/servicing/payment-issues']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Payment Issues</a>
				<a *aclIsAllowed="'servicing.reduction_files'" [routerLink]="['/servicing/reduction-documents']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Reduction Documents</a>
				<a *aclIsAllowed="'servicing.portfolio_follow_up'" [routerLink]="['/servicing/portfolio-follow-up']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Portfolio Follow Up</a>
			</div>
		</div>
		<div *aclIsAllowedSome="['todo.whiteboard', 'todo.list']" routerLinkActive="active" class="navigation__item">
			<svg-icon [icon]="svgIconNames.Todos" class="base-icon base-icon--nav todo-icon"></svg-icon>
			<div class="navigation__item__submenu">
				<div class="navigation__item__submenu__title">To-do</div>
				<a *aclIsAllowed="'todo.whiteboard'" [routerLink]="['/todos/board']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Board</a>
				<a *aclIsAllowed="'todo.list'" [routerLink]="['/todos/list']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Archive</a>
			</div>
		</div>
		<div *aclIsAllowed="'servicing.lien_letters'" routerLinkActive="active" class="navigation__item">
			<a [routerLink]="['/servicing/lien-letters']" class="link">
				<svg-icon [icon]="svgIconNames.LienLetter" class="base-icon base-icon--nav"></svg-icon>
			</a>
			<div class="navigation__item__submenu">
				<div class="navigation__item__submenu__title">Lien Letters</div>
				<a [routerLink]="['/servicing/lien-letters']" class="navigation__item__submenu__item">Default
					Portfolio</a>
				<a [routerLink]="['/servicing/lien-letters/external']" class="navigation__item__submenu__item">External
					Portfolio</a>
			</div>
		</div>
		<div
			*aclIsAllowedSome="[
				'reports.sales_activity_report',
				'reports.medical_appointments_report',
				'reports.liquidation_curve',
				'reports.attorneys_performance',
				'reports.portfolio_metrics',
			]"
			routerLinkActive="active" class="navigation__item">
			<svg-icon [icon]="svgIconNames.Chart" class="base-icon base-icon--nav"></svg-icon>
			<div class="navigation__item__submenu bottom">
				<div class="navigation__item__submenu__title">Reporting</div>
				<a *aclIsAllowed="'servicing.servicing_report'" [routerLink]="['/reporting/servicing-report']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Servicing Report</a>
				<a *aclIsAllowed="'reports.sales_activity_report'" [routerLink]="['/reporting/sales-activity-report']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Sales Activity Report</a>
				<a *aclIsAllowed="'reports.medical_appointments_report'"
				   [routerLink]="['/reporting/medical-appointments-report']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Medical Appointments Report</a>
				<a *aclIsAllowed="'reports.liquidation_curve'" [routerLink]="['/reporting/liquidation-curve']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Liquidation Curve</a>
				<a *aclIsAllowed="'reports.attorneys_performance'" [routerLink]="['/reporting/attorneys-performance']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Attorneys Performance</a>
				<a *aclIsAllowed="'reports.portfolio_metrics'" [routerLink]="['/reporting/portfolio-metrics']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Portfolio Metrics</a>
			</div>
		</div>
		<div
			*aclIsAllowedSome="[
				'reports.csr_contracts',
				'reports.reduction_amount_metrics',
				'reports.portfolio_progression',
				'metrics.requires_servicing_history'
			]"
			routerLinkActive="active" class="navigation__item">
			<svg-icon [icon]="svgIconNames.Metrics" class="base-icon base-icon--nav"></svg-icon>
			<div class="navigation__item__submenu bottom">
				<div class="navigation__item__submenu__title">Metrics</div>
				<a *aclIsAllowed="'reports.csr_contracts'" [routerLink]="['/metrics/csr-contracts-report']"
				   routerLinkActive="active" class="navigation__item__submenu__item">CSR Contracts</a>
				<a *aclIsAllowed="'reports.reduction_amount_metrics'"
				   [routerLink]="['/metrics/reduction-amount-metrics']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Reduction Amount Metrics</a>
				<a *aclIsAllowed="'reports.portfolio_progression'" [routerLink]="['/metrics/portfolio-progression']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Portfolio Progression</a>
			</div>
		</div>
		<div *aclIsAllowedSome="['application.application_logs','application.showed_doctor_locations']"
			 routerLinkActive="active" class="navigation__item">
			<svg-icon [icon]="svgIconNames.Log" class="base-icon base-icon--nav"></svg-icon>
			<div class="navigation__item__submenu bottom">
				<div class="navigation__item__submenu__title">Logs</div>
				<a *aclIsAllowed="'reports.application_logs'" [routerLink]="['/logs/application-logs']"
				   class="navigation__item__submenu__item" routerLinkActive="active">Application Logs</a>
				<a *aclIsAllowed="'reports.showed_doctor_locations'" [routerLink]="['/logs/showed-locations']"
				   class="navigation__item__submenu__item" routerLinkActive="active">Rocknet Showed Locations</a>
			</div>
		</div>
		<div *aclIsAllowed="'applicants.sms'" routerLinkActive="active" class="navigation__item">
			<a [routerLink]="['/sms-inbox']" class="link">
				<svg-icon [icon]="svgIconNames.Envelope" class="base-icon base-icon--nav"></svg-icon>
			</a>
			<div class="navigation__item__submenu">
				<a [routerLink]="['/sms-inbox']" class="navigation__item__submenu__title link">Sms Inbox</a>
			</div>
		</div>
		<div *aclIsAllowedSome="['application.security', 'application.users_management']" routerLinkActive="active"
			 class="navigation__item">
			<svg-icon [icon]="svgIconNames.Security" class="base-icon base-icon--nav"></svg-icon>
			<div class="navigation__item__submenu bottom">
				<div class="navigation__item__submenu__title">Security</div>
				<a *aclIsAllowed="'application.security'" [routerLink]="['/security/fields']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Fields</a>
				<a *aclIsAllowed="'application.security'" [routerLink]="['/security/actions']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Actions</a>
				<a *aclIsAllowed="'application.security'" [routerLink]="['/security/roles']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Roles</a>
				<a *aclIsAllowed="'application.users_management'" [routerLink]="['/employees/list']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Employees</a>
				<a *aclIsAllowed="'application.security'" [routerLink]="['/security/portal-case-status-changes']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Portal Case Status Changes</a>
			</div>
		</div>
		<div
			*aclIsAllowedSome="['settings.cpt_codes', 'settings_document_templates', 'settings.codelists', 'settings.underwriting_information']"
			routerLinkActive="active" class="navigation__item">
			<svg-icon [icon]="svgIconNames.Settings" class="base-icon base-icon--nav"></svg-icon>
			<div class="navigation__item__submenu bottom">
				<div class="navigation__item__submenu__title link">Settings</div>
				<a *aclIsAllowed="'settings.cpt_codes'" [routerLink]="['/settings/cpt-codes']" routerLinkActive="active"
				   class="navigation__item__submenu__item">CPT Codes</a>
				<a *aclIsAllowed="'settings.underwriting_information'"
				   [routerLink]="['/settings/underwriting-information']" routerLinkActive="active"
				   class="navigation__item__submenu__item">Underwriting Info.</a>
				<a *aclIsAllowed="'settings.codelists'" [routerLink]="['/settings/code-lists']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Code Lists</a>
				<a *aclIsAllowed="'settings.document_templates'" [routerLink]="['/settings/document-templates']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Template Management</a>
				<a *aclIsAllowed="'settings.report_email'" [routerLink]="['/settings/email-report-recipient']"
				   routerLinkActive="active" class="navigation__item__submenu__item">Report Email</a>
			</div>
		</div>
	</nav>
</div>
