import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Codelist } from '@app/shared/model/types/codelists/codelist';
import { EntityId } from '@app/shared/model/types/entity-id';

@Component({
	selector: 'entity-label-badge',
	templateUrl: 'entity-label-badge.component.html',
	styleUrls: ['./entity-label-badge.component.scss']
})
export class EntityLabelBadgeComponent {

	@Input() label: Codelist;
	@Output() onRemove = new EventEmitter<EntityId>();

	public propagateOnRemove(): void {
		this.onRemove.emit(this.label.id);
	}
}
