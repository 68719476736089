import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInOut } from '@app/shared/animations/animations';
import { EntityAssociationType } from '@app/shared/model/constants/entity-association-type';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { TodoAssociationsListEntry } from '@app/todo/model/todo-associations-list-entry';
import { faAddressBook, faDollarSign, faGavel, faHSquare, faSpinner, faUser, faUserTie } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'todo-associations-list',
	templateUrl: 'todo-associations-list.component.html',
	animations: [fadeInOut]
})
export class TodoAssociationsListComponent {
	@Input() associations: TodoAssociationsListEntry[];

	@Output() onRemoveAssociation = new EventEmitter<TodoAssociationsListEntry>();

	readonly svgIconNames = SvgIconName;
	readonly faIcons = {
		spinner: faSpinner,
		user: faUser,
		dollarSign: faDollarSign,
		gavel: faGavel,
		hSquare: faHSquare,
		addressBook: faAddressBook,
		userTie: faUserTie,
	};
	readonly todoAssociationTypes = EntityAssociationType;

	public removeAssociation(association: TodoAssociationsListEntry): void {
		this.onRemoveAssociation.emit(association);
	}
}
