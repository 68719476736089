import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { fadeInOut, fadeUpDown, heightGrow } from '@app/shared/animations/animations';
import { Todo } from '@app/shared/model/types/todo';
import { trim } from 'lodash';

@Component({
	selector: 'todo-card-description',
	templateUrl: './todo-card-description.html',
	animations: [
		fadeUpDown,
		heightGrow,
		fadeInOut,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoCardDescriptionComponent implements OnChanges {

	@Input() description = '';
	@Input() showHeader = true;

	@Output() descriptionChanged = new EventEmitter<Partial<Todo>>();

	editDescription = false;
	originalDescription: string;

	public ngOnChanges(): void {
		this.originalDescription = this.description;
	}

	public editDesc(): void {
		this.editDescription = true;
	}

	public cancelEditDescription(): void {
		this.description = this.originalDescription;
		this.editDescription = false;
	}

	public saveDescription(tooltip) {
		if(this.originalDescription == this.description) {
			this.editDescription = false;
			return;
		}
		const trimmedString = trim(this.description);
		if(!this.originalDescription && trimmedString.length == 0) {
			tooltip.show();
			setTimeout(() => {
				tooltip.hide();
			}, 2000);
			return;
		}
		this.originalDescription = trimmedString.length == 0 ? null : this.description;
		this.cancelEditDescription();
		this.descriptionChanged.emit({description: this.description});
	}
}
