import { Component, Input } from '@angular/core';

@Component({
	selector: 'sales-activity-widget-modal',
	template: `
		<div class="ibox flex-col" style="height: 90vh; min-width: 75vw;">
			<div class="flex">
				<sales-activity-report-grid [gridData]="gridData" style="width: 100%"></sales-activity-report-grid>
			</div>
		</div>
	`
})
export class SalesActivityWidgetModalComponent {
	@Input() gridData;
}
