import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CollapsibleItemComponent } from '@app/dashboard/collapsible-item/collapsible-item.component';
import { CurrentPortfolioOutstandingTableComponent } from '@app/dashboard/current-portfolio/current-portfolio-outstanding-table/current-portfolio-outstanding-table.component';
import { CurrentPortfolioComponent } from '@app/dashboard/current-portfolio/current-portfolio.component';
import { DashboardWidgetDateFilterModule } from '@app/dashboard/shared/dashboard-widget-date-filter/dashboard-widget-date-filter.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { GhostElementModule } from '@app/widgets/ghost-element/ghost-element.module';
import { LetDirective } from '@ngrx/component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		LetDirective,
		GhostElementModule,
		PipesModule,
		TooltipModule,
		DashboardWidgetDateFilterModule,
		CollapsibleItemComponent,
	],
	declarations: [
		CurrentPortfolioOutstandingTableComponent,
		CurrentPortfolioComponent
	],
	exports: [
		CurrentPortfolioComponent
	]
})
export class CurrentPortfolioModule {}
