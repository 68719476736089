import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fadeInOut, fadeUpDown, heightGrow } from '@app/shared/animations/animations';
import { TodoStatus } from '@app/shared/model/constants/todo-status';
import { EntityId } from '@app/shared/model/types/entity-id';
import { UserAccount } from '@app/shared/model/types/user-account';
import { TodoCardData } from '@app/todo/model/todo-card-data';
import { TodoCategory } from '@app/todo/model/todo-category';
import { NewTodoModalComponent } from '@app/todo/new-todo-modal/new-todo-modal.component';
import { TodoFilterPropertyName } from '@app/todo/whiteboard/model/todo-filter-property-name.model';
import { TodoWhiteboardStoreService } from '@app/todo/whiteboard/todo-whiteboard-store.service';
import { faChevronDown, faPlus, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, startWith, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'todo-whiteboard',
	templateUrl: './whiteboard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [fadeUpDown, heightGrow, fadeInOut],
	providers: [TodoWhiteboardStoreService]
})
export class TodoWhiteboardComponent implements OnInit, OnDestroy {
	todosTodo$: Observable<TodoCardData[]>;
	todosInProgress$: Observable<TodoCardData[]>;
	todosDone$: Observable<TodoCardData[]>;
	allAssignees$: Observable<UserAccount[]>;
	selectedCategories$: Observable<TodoCategory[]>;
	searchInput = new FormControl('');
	showFilterCategoryList$ = new BehaviorSubject(false);

	readonly todoStatus = TodoStatus;
	readonly configs = {
		todo: { label: 'Todo', lengthLabel: 'open', labelColor: '#efb40e' },
		inProgress: { label: 'In Progress', lengthLabel: 'in progress', labelColor: '#0e9aef' },
		done: { label: 'Done', lengthLabel: 'closed', labelColor: '#00c722' },
	};
	readonly faIcons = {
		search: faSearch,
		times: faTimes,
		chevronDown: faChevronDown,
		plus: faPlus,
	};

	private destroy$ = new Subject<void>();

	constructor(
		private store: TodoWhiteboardStoreService,
		public modalService: BsModalService
	) {
	}

	public ngOnInit(): void {
		this.store.init();
		this.allAssignees$ = this.store.allAssignees$;
		this.searchInput.valueChanges.pipe(
			startWith(''),
			distinctUntilChanged(),
			debounceTime(300),
			takeUntil(this.destroy$)
		).subscribe((searchTerm) => this.store.updateSearchTerm(searchTerm));

		this.selectedCategories$ = this.store.selectedCategories$;
		this.todosTodo$ = this.store.todosByStatus$(TodoStatus.Todo);
		this.todosInProgress$ = this.store.todosByStatus$(TodoStatus.InProgress);
		this.todosDone$ = this.store.todosByStatus$(TodoStatus.Done);
	}

	public filterByAssigneeUser(assignee): void {
		this.store.updateIdsFilter({ item: assignee, filterPropertyName: TodoFilterPropertyName.AssigneesFilter });
	}

	public filterByCategory(category): void {
		this.store.updateIdsFilter({ item: category, filterPropertyName: TodoFilterPropertyName.CategoriesFilter });
	}

	public toggleFilterCategoryList(): void {
		this.showFilterCategoryList$.next(!this.showFilterCategoryList$.value);
	}

	public closeFilterCategoryList(): void {
		this.showFilterCategoryList$.next(false);
	}

	public clearSearchInput(): void {
		this.searchInput.patchValue('', { emitViewToModelChange: true });
	}

	public cancelAssigneesFilter(): void {
		this.store.clearAssigneesFilter();
	}

	public openAddTodoModal(): void {
		this.modalService.show(NewTodoModalComponent);
	}

	public removeTodo(todoId: EntityId) {
		this.store.removeTodo(todoId);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
