import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dealTag' })
export class DealTagPipe implements PipeTransform {

	public transform(value: any): string {
		return value ? `(${value.toString().padStart(6, '0')})` : ''
	}

}
