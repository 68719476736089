<div class="modal-body">
	<h3 style="text-align: center">Servicing Report Options</h3>
	<div class="row">
		<div class="col-md-12">
			<h4>Show columns:</h4>
			<div *ngFor="let column of columns" class="checkbox-inline">
				<label><input [(ngModel)]="column.include" type="checkbox">{{ column.title }}</label>
			</div>
		</div>
	</div>
	<download-link [config$]="downloadLink$"></download-link>
	<div class="row">
		<div class="col-md-12">
			<button class="btn btn-primary" (click)="download()">Download</button>
		</div>
	</div>
</div>
