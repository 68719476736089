import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { SVGIconComponent } from '@app/shared/svg-icon/svg-icon.component';
import { EntityLabelBadgeComponent } from '@app/widgets/entity-label/entity-label-badge/entity-label-badge.component';
import { EntityLabelPickerComponent } from '@app/widgets/entity-label/entity-label-picker/entity-label-picker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
		SVGIconComponent,
		NgSelectModule,
		FontAwesomeModule,
		ReactiveFormsModule,
		DirectivesModule
	],
	declarations: [
		EntityLabelPickerComponent,
		EntityLabelBadgeComponent
	],
	exports: [
		EntityLabelPickerComponent,
		EntityLabelBadgeComponent
	]
})

export class EntityLabelModule {}
