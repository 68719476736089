<div
	*ngIf="!isEditing"
	class="todo-card__summary dont-break-out"
	(dblclick)="editSummaryText()"
	data-cy="todoSummary-value">
	{{ summary }}
</div>
<div *ngIf="isEditing" class="todo-card__summary-edit" (clickOutside)="cancelEditSummary()" data-cy="todoCardSummary-element">
	<input
		[(ngModel)]="summary"
		data-cy="todoSummary-input"
		tooltip="Summary cannot be empty"
		triggers=""
		[isOpen]="isSummaryEmpty"
		placeholder="Type short to-do summary"/>
	<div class="todo-card__summary-edit__btn" (throttleClick)="saveSummary()" data-cy="submit-btn">
		<svg-icon icon="save" class="svg-icon"></svg-icon>
	</div>
</div>
