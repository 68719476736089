import { InjectionToken } from '@angular/core';

export interface IGaConfig {
	templatePattern: RegExp;
}

export const GA_CONFIG = new InjectionToken<IGaConfig>('ga.config');

export const GaConfig: IGaConfig = {
	templatePattern: /<%=([\s\S]+?)=%>/g,
};
