export enum RevisionType {
	CodelistsRevision = 'codelists-revision',
	PermissionsRevision = 'permissions-revision',
	FieldDefinitionsRevision = 'fields-definitions-revision',
}

export interface IRevisions {
	getRevision(type: RevisionType);

	getRevisions(): Map<RevisionType, string>;
}

export class Revisions implements IRevisions {

	private revisions: Map<RevisionType, string> = new Map<RevisionType, string>();

	public getRevision(type: RevisionType) {
		return this.revisions.get(type);
	}

	public getRevisions(): Map<RevisionType, string> {
		return this.revisions;
	}

	public static fromResponse(response: any[]) {
		const revisions = new Revisions();

		revisions.revisions
			.set(RevisionType.CodelistsRevision, response[RevisionType.CodelistsRevision])
			.set(RevisionType.PermissionsRevision, response[RevisionType.PermissionsRevision])
			.set(RevisionType.FieldDefinitionsRevision, response[RevisionType.FieldDefinitionsRevision]);

		return revisions;
	}
}
