import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { loadTodos } from '@app/core/todo-store/todo.actions';
import { selectBoardTodos, selectSidebarTodos } from '@app/core/todo-store/todo.selectors';
import { WebSocketMessageType } from '@app/core/web-socket/web-socket-message-type';
import { WebSocketService } from '@app/core/web-socket/web-socket.service';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { TodoStatus } from '@app/shared/model/constants/todo-status';
import { Todo } from '@app/shared/model/types/todo';
import { TodoSidebarService } from '@app/todo/todo-sidebar/service/todo-sidebar.service';
import { Store } from '@ngrx/store';
import { filter, map, Observable, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'todo-sidebar-expand-button',
	templateUrl: 'todo-sidebar-expand-button.component.html',
	styleUrls: ['todo-sidebar-expand-button.component.scss']
})
export class TodoSidebarExpandButtonComponent implements OnInit, OnDestroy {
	isCurrentUserMentioned$: Observable<boolean>;
	toDosCount$: Observable<number>;
	readonly svgIconNames = SvgIconName;
	private currentUserId: number;
	private destroy$ = new Subject<void>();

	constructor(
		private authService: AuthService,
		private store: Store<{ todos: Todo[] }>,
		private todoSidebarService: TodoSidebarService,
		private webSocketServer: WebSocketService,
	) {
	}

	public ngOnInit(): void {
		this.currentUserId = this.authService.getCurrentUser().id;

		this.isCurrentUserMentioned$ = this.store.select(selectBoardTodos)
			.pipe(map((todos) => todos.some((todo) =>
					todo.comments.some((comment) =>
						comment.mentions.some((mention) => mention.mentionedUser.id == this.currentUserId && !mention.seen)
					)
				))
			);

		this.toDosCount$ = this.store.select(selectSidebarTodos(this.currentUserId))
			.pipe(
				map(todos => todos
					.filter(todo => todo.status === TodoStatus.Todo && todo.assignees.some(assignee => assignee.id == this.currentUserId))
					.length
				)
			);

		this.webSocketServer.getConnection()
			.pipe(
				filter((message) => message.type === WebSocketMessageType.TodoCreated),
				takeUntil(this.destroy$)
			).subscribe((message) => {
			this.store.dispatch(loadTodos());
			if (!this.todoSidebarService.isExpanded
				&& !this.todoSidebarService.showDotNotification
				&& this.todoSidebarService.hasNewTodoAssigned(message.data.todo))
				this.todoSidebarService.setNotificationStatus(true);
		});
	}

	public toggleTodoWidget(): void {
		this.todoSidebarService.toggleIsExpanded();
		this.todoSidebarService.setNotificationStatus(false);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
