import { Component, OnInit } from '@angular/core';
import { FundingConsultantsBreakdownChartService } from '@app/dashboard/funding-consultants-breakdown/funding-consultants-breakdown-chart.service';
import { DashboardBreakdownChartService } from '@app/dashboard/shared/breakdown-chart/dashboard-breakdown-chart-service';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';

@Component({
	selector: 'funding-consultants-breakdown',
	templateUrl: './funding-consultants-breakdown.component.html',
	providers: [
		{ provide: DashboardBreakdownChartService, useClass: FundingConsultantsBreakdownChartService }
	]
})
export class FundingConsultantsBreakdownComponent implements OnInit {
	codelistData$;

	constructor(private codelistRepository: CodelistRepositoryService
	) {}

	public ngOnInit(): void {
		this.codelistData$ = this.codelistRepository.getByUrl(UrlCodelistLink.FundingConsultants);
	}
}
