import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { fadeInOut } from '@app/shared/animations/animations';
import { EntityId } from '@app/shared/model/types/entity-id';
import { SalesActivityNote } from '@app/shared/model/types/note';
import { NewTodoFormData } from '@app/todo/model/new-todo-form';
import { NewTodo } from '@app/todo/model/new-todo.model';
import { TodoAssociationsService } from '@app/todo/services/todo-associations.service';
import { TodoService } from '@app/todo/services/todo.service';
import { TodoCommentService } from '@app/todo/todo-card/todo-comments/service/todo-comment.service';
import { TodoDueDateService } from '@app/todo/todo-due-date/service/todo-due-date.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs';

@Component({
	selector: 'salesboard-modal-add-todo',
	templateUrl: './salesboard-modal-add-todo.component.html',
	styleUrls: ['salesboard-modal-add-todo.component.scss'],
	animations: [
		fadeInOut
	],
	providers: [TodoCommentService, TodoDueDateService, TodoAssociationsService]
})
export class SalesboardModalAddTodoComponent implements OnInit {
	@Input() salesActivityNote: SalesActivityNote;
	@Input() allSalesActivityNotes: SalesActivityNote[] = [];
	@Input() noteEntityName: string;
	todoFormData: NewTodoFormData;
	salesActivityCtrl: FormControl<EntityId | null>;

	constructor(
		private bsModalRef: BsModalRef,
		private todoService: TodoService,
		private todoCommentService: TodoCommentService,
		private todoAssociationsService: TodoAssociationsService,
		private todoDueDateService: TodoDueDateService) {
	}

	public ngOnInit(): void {
		if (this.allSalesActivityNotes.length === 0 && this.salesActivityNote) {
			this.allSalesActivityNotes.push(this.salesActivityNote);
		}
		this.salesActivityCtrl = new FormControl(this.salesActivityNote?.id, [Validators.required]);
	}

	public todoFormChanged(todoFormData: NewTodoFormData): void {
		this.todoFormData = todoFormData;
	}

	public cancel(): void {
		this.bsModalRef.hide();
	}

	public saveTodo(btnDisableLoaderFn: () => void): void {
		const newTodo: NewTodo = {
			...this.todoFormData.value,
			comment: this.todoCommentService.newCommentText,
			mentions: this.todoCommentService.commentMentionRequests.map(mention => mention.mentionedUser),
			dueDate: this.todoDueDateService.todoDueDateString,
			timezone: this.todoDueDateService.timezoneId,
			[this.noteEntityName]: this.salesActivityCtrl.value
		};

		const associations = this.todoAssociationsService.associations;
		associations.forEach((association) => {
			if (association.association_type in newTodo) {
				newTodo[association.association_type].push(association.referenceId);
			} else {
				newTodo[association.association_type] = [association.referenceId];
			}
		});

		this.todoService.createTodo(newTodo)
			.pipe(finalize(() => btnDisableLoaderFn()))
			.subscribe(() => this.bsModalRef.hide());
	}
}
