export class FlashMessage {

	constructor(
		public id: string,
		public body: string,
		public type: string,
		public timeout: number = 5000,
		public showCloseBtn: boolean = true) {}
}

export enum FlashMessageType {
	Danger = 'danger',
	Warning = 'warning',
	Success = 'success',
	Info = 'info',
}
