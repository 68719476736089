import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'app-loading-overlay',
	templateUrl: './ag-grid-custom-loader.component.html',
})
export class AgGridCustomLoaderComponent implements ILoadingOverlayAngularComp {

	params: any;

	public agInit(params): void {
		this.params = params;
	}
}
