import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'forgotten-password',
	templateUrl: './forgotten-password.component.html'
})
export class ForgottenPasswordComponent {

	shake = false;
	subtitle = "Enter the email address associated with your account and we'll send you a link to reset your password.";

	passwordForm = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(255)])
	});

	readonly faIcons = {
		exclamationCircle: faExclamationCircle
	};

	constructor(public http: HttpClient,
				public flashMessage: FlashMessageService,
				public router: Router) {}

	public resetPassword() : void {
		this.shake = this.passwordForm.invalid;

		if (this.passwordForm.valid) {
			const headers = new HttpHeaders().set('InterceptorSkipHeader', '');
			this.http.post('/api/request-password-reset', { ...this.passwordForm.value }, { headers }).subscribe(() => {
				this.flashMessage.showMessage(
					FlashMessageType.Success,
					'The link to reset your password has been sent to your email address.',
					{ timeout: 10000 }
				);
				this.router.navigate(['/login']);
			}, (errorResponse: unknown) => {
				const {error} = errorResponse as {error: {message: string}};
				this.flashMessage.showMessage(FlashMessageType.Danger, error.message, { timeout: 10000 });
			});
		}
	}

	get userEmail(): AbstractControl | null {
		return this.passwordForm.get('email');
	}

}
