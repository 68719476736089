<ng-container *ngrxLet="round$; let roundValue">
	<div *ngrxLet="portfolio$; let portfolio" class="dash-panel ibox">
		<collapsible-item [isManagedOutside]="isCollapseManaged">
			<div header-left>
				<h4>Current Portfolio:</h4>
				<span class="flex">in <ghost-element
					width="5">&nbsp;<b>{{ portfolio?.currentFundings | number: '1.0-0' }}</b>&nbsp;</ghost-element> fundings /
					<ghost-element
						width="5">&nbsp;<b>{{ portfolio?.currentClaims | number: '1.0-0' }}</b>&nbsp;</ghost-element> claims</span>
			</div>
			<h1 header-right>
				<ghost-element width="25"
							   height="4">{{ portfolio?.currentOwed | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
			</h1>
			<div class="ibox-content">
				<div class="date-boundary-filter__wrapper">
					<dashboard-widget-date-filter class="date-boundary-filter"
												  [inputControl]="dateFilterControl"></dashboard-widget-date-filter>
				</div>
				<div class="row">
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Plaintiff:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ portfolio?.currentBreakdown?.plaintiffCount| number: '1.0-0' }}
											/ {{ portfolio?.currentBreakdown?.plaintiffCountPercentage | number: '1.0-0' }}
											%</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Case Cost:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ portfolio?.currentBreakdown?.caseCostCount| number: '1.0-0' }}
											/ {{ portfolio?.currentBreakdown?.caseCostCountPercentage | number: '1.0-0' }}
											%</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Medical:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ portfolio?.currentBreakdown?.medicalCount | number: '1.0-0' }}
											/ {{ portfolio?.currentBreakdown?.medicalCountPercentage | number: '1.0-0' }}
											%</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Probate:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ (portfolio?.currentBreakdown?.probateCount ?? 0) | number: '1.0-0' }}
											/ {{ (portfolio?.currentBreakdown?.probateCountPercentage ?? 0) | number: '1.0-0' }}
											%</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Plaintiff:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ portfolio?.currentBreakdown?.plaintiffOwed | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
											/ {{ portfolio?.currentBreakdown?.plaintiffOwedPercentage | number: '1.0-0' }}
											%</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Case Cost:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ portfolio?.currentBreakdown?.caseCostOwed | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
											/ {{ portfolio?.currentBreakdown?.caseCostOwedPercentage | number: '1.0-0' }}
											%</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Medical:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ portfolio?.currentBreakdown?.medicalOwed | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
											/ {{ portfolio?.currentBreakdown?.medicalOwedPercentage | number: '1.0-0' }}
											%</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Probate:</td>
								<td class="number">
									<ghost-element width="6">
										<b>{{ (portfolio?.currentBreakdown?.probateOwed ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
											/ {{ (portfolio?.currentBreakdown?.probateOwed ?? 0) | number: '1.0-0' }}
											%</b>
									</ghost-element>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<hr>
				<div class="row">
					<div class="col-sm-12">
						<table class="table-striped">
							<thead>
							<tr>
								<th></th>
								<th class="text-right">Avg. Fndg.</th>
								<th class="text-right">Med. Fndg.</th>
								<th class="text-right">Avg. Claim</th>
								<th class="text-right">Med. Claim</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<th>Purchase Price</th>
								<td class="number">
									<ghost-element
										width="6">{{ portfolio?.avgPriceAdvance | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="6">{{ portfolio?.medianFunding | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }} </ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="6">{{ portfolio?.avgPriceClaim | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="6">{{ portfolio?.medianClaim | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
								</td>
							</tr>
							<tr>
								<th>Face Value</th>
								<td class="number">
									<ghost-element
										width="6">{{ portfolio?.avgValueAdvance | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="6">{{ portfolio?.medianFundingFaceValue | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="6">{{ portfolio?.avgValueClaim | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="6">{{ portfolio?.medianClaimFaceValue | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				<hr>
				<current-portfolio-outstanding-table
					[groups]="portfolio?.outstandingGrouped"
					[roundValue]="roundValue"
				></current-portfolio-outstanding-table>
				<hr>
				<h5 class="m-b-md">Projection</h5>
				<div class="row">
					<div class="col-sm-4">
						<label class="col-form-label">PreSet Expected Fee</label><br>
						<input type="text" class="form-control" name="preSetMultiplier"
							   [formControl]="preSetMultiplier"/>
					</div>
					<div class="col-sm-4">
						<label class="col-form-label">Medical Expected Fee</label><br>
						<input type="text" class="form-control" name="medicalMultiplier"
							   [formControl]="medicalMultiplier"/>
					</div>
					<div class="col-sm-4">
						<label class="col-form-label">Probate Expected Fee</label><br>
						<input type="text" class="form-control" name="probateMultiplier"
							   [formControl]="probateMultiplier"/>
					</div>
				</div>
				<current-portfolio-outstanding-table
					[groups]="projection$ | async"
					[roundValue]="roundValue"
				></current-portfolio-outstanding-table>
				<hr>
				<div class="row">
					<div class="col-sm-6 flex">
						# Total Law Firms:&nbsp;
						<ghost-element width="5"><b
							class="number">{{ portfolio?.lawFirms.lawFirmsCount | number : '1.0-0' }}</b>
						</ghost-element>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Avg. Cases per Law Firm:</td>
								<td class="number">
									<ghost-element width="4">
										<b>{{ portfolio?.lawFirms.avgCasesPerLawFirm | number : '1.0-1' }}</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Avg. Funding Duration:</td>
								<td class="number">
									<ghost-element width="4">
										<b>{{ portfolio?.durations.average | number : '1.0-1' }}</b></ghost-element>
								</td>
							</tr>
						</table>
					</div>
					<div class="col-sm-6">
						<table class="table-striped">
							<tr>
								<td>Median Cases per Law Firm:</td>
								<td class="number">
									<ghost-element width="4">
										<b>{{ portfolio?.lawFirms.medianCasesPerLawFirm | number : '1.0-1' }}</b>
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td>Weighted Avg. Funding Duration:</td>
								<td class="number">
									<ghost-element width="4">
										<b>{{ portfolio?.durations.weighted | number : '1.0-1' }}</b></ghost-element>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div *ngIf="portfolio?.topNetPurchases">
					<hr>
					<div class="row">
						<div class="col-sm-12" *ngIf="portfolio.topNetPurchases.top10Percents">
							Net Purchase Amounts > 10% of Expected Settlement Amount: <b
							class="number">{{ portfolio.topNetPurchases.top10Percents.sum | currency : 'USD' : 'symbol' : '1.2-2' }}</b>
							(<b class="number">{{ portfolio.topNetPurchases.top10Percents.count }}</b> cases -
							<b class="number"
							   [tooltip]="top10PercentTooltip">{{ portfolio.topNetPurchases.top10Percents.share | number : '1.0-2' }}
								% share</b>)
							<ng-template #top10PercentTooltip>'Net Purchase Amounts > 10% of Expected Settlement Amount'
								/
								'Total Purchase Price Outstanding'<br/><br/>* Refreshed once in hour
							</ng-template>
						</div>
						<div class="col-sm-12" *ngIf="portfolio.topNetPurchases.top1nHalfPercent">
							Net Purchase Amounts > 1.5% of Outstanding Portfolio: <b
							class="number">{{ portfolio.topNetPurchases.top1nHalfPercent.sum | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
							(<b class="number">{{ portfolio.topNetPurchases.top1nHalfPercent.count }}</b> cases -
							<b class="number"
							   [tooltip]="top1nHalfPercentTooltip">{{ portfolio.topNetPurchases.top1nHalfPercent.share | number : '1.0-2' }}
								% share</b>)
							<ng-template #top1nHalfPercentTooltip>'Net Purchase Amounts > 1.5% of Outstanding Portfolio'
								/
								'Total Purchase Price Outstanding'<br/><br/>* Refreshed once in hour
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</collapsible-item>
	</div>
</ng-container>
