import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fadeInOut, fadeUpDown, heightGrow } from '@app/shared/animations/animations';
import { TodoCategory } from '@app/todo/model/todo-category';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
	selector: ' todo-categories',
	templateUrl: './todo-categories.component.html',
	animations: [
		fadeUpDown,
		heightGrow,
		fadeInOut,
	],
})
export class TodoCategoriesComponent implements OnInit, OnDestroy {

	@Input() set categories(value: TodoCategory[]) {
		this.categories$.next(value);
	}

	@Input() wrapperClass = '';
	@Input() categoryLabelsWrapperClass = '';
	@Output() updateTodo = new EventEmitter<TodoCategory[]>();

	categories$ = new BehaviorSubject<TodoCategory[]>([]);
	showCategoryList = false;

	private saveEmitter$ = new Subject<TodoCategory[]>();
	private destroy$ = new Subject<void>();

	public ngOnInit(): void {
		this.saveEmitter$.pipe(
			debounceTime(1000),
			distinctUntilChanged((prev, next) => prev.length == next.length),
			takeUntil(this.destroy$)
		).subscribe(categories => {
			this.updateTodo.emit(categories);
		});
	}

	public toggleCategoryList(): void {
		this.showCategoryList = !this.showCategoryList;
	}

	public closeCategoryList(): void {
		this.showCategoryList = false;
	}

	public setCategory(category: TodoCategory): void {
		const categories = [ ...this.categories$.value ];
		const categoryIndex = categories.findIndex(item => item.id === category.id);
		if (categoryIndex !== -1) {
			categories.splice(categoryIndex, 1);
		} else {
			categories.push(category);
		}

		this.saveEmitter$.next(categories);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

}
