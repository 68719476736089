import { EntityAssociationType } from '@app/shared/model/constants/entity-association-type';

export type TodoAssociationsSelectOption = {
	referencedEntity: any,
	referencedClass: EntityAssociationType,
	associationClass: string,
	label: string
}

export const associationTypes: AssociationTypesConfig[] = [
	{
		title: 'Funding',
		type: EntityAssociationType.Funding,
		searchEntity: 'fundings',
		associationClass: 'todoFundingAssociation',
		parentReferenceIdGetter: entityData => entityData.applicant.id,
		createLabel: item => getFundingLabel(item),
		isEqual: (association, entity) => association.label === associationTypes[0].createLabel(entity)
	},
	{
		title: 'Case',
		type: EntityAssociationType.Applicant,
		searchEntity: 'applicants',
		associationClass: 'todoApplicantAssociation',
		createLabel: (item) => (item.internalId + ' (' + item.firstName + ' ' + item.lastName + ')'),
		isEqual: (association, entity) => association.referenceId == entity.id
	},
	{
		title: 'Law Firm',
		type: EntityAssociationType.LawFirm,
		searchEntity: 'lawFirms',
		associationClass: 'todoLawFirmAssociation',
		createLabel: (item) => (item.name),
		isEqual: (association, entity) => association.referenceId == entity.id
	},
	{
		title: 'Medical Facility',
		type: EntityAssociationType.MedicalFacility,
		searchEntity: 'medicalFacilities',
		associationClass: 'todoMedicalFacilityAssociation',
		createLabel: (item) => (item.name),
		isEqual: (association, entity) => association.referenceId == entity.id
	},
	{
		title: 'Doctor',
		type: EntityAssociationType.Doctor,
		searchEntity: 'doctors',
		associationClass: 'todoDoctorAssociation',
		createLabel: (item) => (item.name),
		isEqual: (association, entity) => association.referenceId == entity.id
	},
	{
		title: 'Law Firm Staff',
		type: EntityAssociationType.Attorney,
		searchEntity: 'lawFirmStaff',
		associationClass: 'todoAttorneyAssociation',
		parentReferenceIdGetter: entityData => entityData?.lawFirm?.id ?? '',
		createLabel: item => `${item.firstName} ${item.lastName} - ${item?.lawFirm?.name ?? ''}`,
		isEqual: (association, entity) => association.referenceId == entity.id
	},
];

export function getFundingLabel(item): string {
	if (item?.applicant?.firstName) {
		return `${item.internalId} - ${item.applicant.firstName} ${item.applicant.lastName ?? ''}`
	} else {
		return `${item.internalId}`;
	}
}

export interface AssociationTypesConfig {
	title: string,
	type: EntityAssociationType,
	searchEntity: string;
	isEqual: (association, entity) => boolean,
	parentReferenceIdGetter?: (entityData) => string,
	createLabel: (item) => string,
	associationClass: string,
}
