import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnreadSms } from '@app/applicant/applicant-detail/applicant-detail-header/applicant-detail-header.component';
import { EntityId } from '@app/shared/model/types/entity-id';
import { Sms, SmsInbox } from '@app/shared/model/types/sms';
import { SmsTemplateData } from '@app/shared/model/types/sms-template-data';
import { SmsConversation } from '@app/sms/sms-conversation';
import * as _ from 'lodash';
import { map, Observable, Subscription } from 'rxjs';

@Injectable()
export class SmsRepositoryService {

	constructor(private http: HttpClient) {
	}

	public sendMessage(applicantId: string | number, sms: Sms): Observable<string> {
		return this.http.post<string>(`/api/applicants/${applicantId}/sms-send`, sms);
	}

	private getInbox(offset = 0, limit = 0, searchTerm = null): Observable<SmsInbox> {
		let params = new HttpParams().append('offset', offset.toString()).append('max-items', limit.toString());
		if (searchTerm) {
			params = params.append('searchTerm', searchTerm);
		}
		return this.http.get<SmsInbox>('/api/sms/inbox', { params: params });
	}

	public prepareInbox(offset ?: number, limit ?: number, searchTerm?: string) {
		return this.getInbox(offset, limit, searchTerm).pipe(
			map((response) => {
				const conversations: SmsConversation[] = response.inbox.map((conversation) => {
					const unreadMessages = response.unreadMessages.find(item => item.id === conversation.id)?.smsMessages ?? []
					const smsMessages = [...conversation.smsMessages].reverse();
					return { ...conversation, unreadMessages, smsMessages, unreadMessagesCount: unreadMessages.length };
				});
				return { conversations, nextOffset: response.nextOffset };
			})
		)
	}

	public getConversation(applicantId: string | number): Observable<SmsConversation> {
		return this.http.get<SmsConversation>(`/api/applicant/${applicantId}/sms-conversation`);
	}

	public getUnreadMessages(applicantId: string | number): Observable<UnreadSms> {
		return this.http.get<UnreadSms>(`/api/applicant/${applicantId}/sms-unread`)
			.pipe(
				map((unreadSms) => unreadSms?.smsMessages ? unreadSms : null)
			);
	}

	public markRead(smsArray: EntityId[] = []): Observable<UnreadSms[]> {
		return this.http.patch<UnreadSms[]>('/api/sms/mark-read', { 'unreadMessages': smsArray });
	}

	public markAllAsRead(conversations: SmsConversation[]): Subscription {
		return this.markRead()
			.subscribe(() => {
				return _.map(conversations, function (conversation: SmsConversation) {
					conversation.unreadMessages = [];
					conversation.unreadMessagesCount = 0;
				});
			});
	}

	public markConversationAsRead(conversation: SmsConversation): Subscription {
		return this.markRead(_.map(conversation.unreadMessages, 'id'))
			.subscribe(() => {
				conversation.unreadMessages = [];
				conversation.unreadMessagesCount = 0;

				return conversation;
			});
	}

	public markActionTaken(sms: Sms): Observable<string> {
		return this.http.patch<string>(`/api/sms/${sms.id}/take-action`, {});
	}

	public getTemplatesData(conversationId: number | string): Observable<SmsTemplateData> {
		return this.http.get<SmsTemplateData>(`/api/applicant/${conversationId}/sms-template-data`);
	}
}
