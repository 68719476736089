import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

@Component({
	selector: 'numeric-cell',
	template: `<input #input [formControl]="numericValue" (keydown)="onKeyDown($event)" class="numeric-input"/>`,
	styles: [`
		:host {
			display: block;
		}
		
		.numeric-input {
			width: 100%;
			box-sizing: border-box;
		}
	`]
})
export class NumericEditorComponent implements ICellEditorAngularComp, AfterViewInit {
	@ViewChild('input', { read: ViewContainerRef }) input: ViewContainerRef;
	highlightAllOnFocus = true;
	numericValue: FormControl<number | string>;

	public agInit(params: ICellEditorParams): void {
		let startValue: number | string | null;
		let highlightAllOnFocus = true;
		const eventKey = params.eventKey;

		if (eventKey === KEY_BACKSPACE) {
			startValue = null;
		} else if (eventKey && eventKey.length === 1) {
			startValue = null;
			highlightAllOnFocus = false;
		} else {
			startValue = params.value ? parseInt(params.value) : null;
			if (params.eventKey === KEY_F2) {
				highlightAllOnFocus = false;
			}
		}

		this.numericValue = new FormControl(startValue, Validators.pattern(/^[0-9]/));
		this.highlightAllOnFocus = highlightAllOnFocus;
	}

	public getValue(): number | null {
		const stringValue = this.numericValue.value.toString() || '';
		const numericString = stringValue.replace(/\D/g, '');
		if (numericString.length === 0) return 0;
		return numericString ? parseInt(numericString, 10) : null;
	}

	public onKeyDown(event: KeyboardEvent): void {
		if (this.isLeftOrRight(event) || this.isBackspace(event)) {
			event.stopPropagation();
			return;
		}
		if (!this.finishedEditingPressed(event) && !this.isNumericKey(event)) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	public ngAfterViewInit(): void {
		window.setTimeout(() => {
			if (this.input?.element) {
				this.input.element.nativeElement.focus();
				if (this.highlightAllOnFocus) {
					this.input.element.nativeElement.select();
					this.highlightAllOnFocus = false;
				} else {
					const length = this.input.element.nativeElement.value.length ?? 0;
					if (length > 0) {
						this.input.element.nativeElement.setSelectionRange(length, length);
					}
				}
				this.input.element.nativeElement.focus();
			}
		});
	}

	private isNumericKey(event: KeyboardEvent): boolean {
		return /^\d+$/.test(event.key);
	}

	private isBackspace(event: KeyboardEvent): boolean {
		return event.key === KEY_BACKSPACE;
	}

	private isLeftOrRight(event: KeyboardEvent): boolean {
		return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
	}

	private finishedEditingPressed(event: KeyboardEvent): boolean {
		return event.key === KEY_ENTER || event.key === KEY_TAB;
	}
}
