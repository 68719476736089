import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms an object to array of `{key, value}` pairs using `Object.keys`
 */
@Pipe({name: 'entries'})
export class EntriesPipe implements PipeTransform {

	public transform(object: Record<string, any>): Record<string, any>[] {
		if (object == null) {
			return [];
		}

		return Object.keys(object).map(key => ({key, value: object[key]}));
	}
}
