import { NgModule } from '@angular/core';
import { BoardFilterService } from '@app/w-boards/services/board-filter.service';
import { BoardHelperService } from '@app/w-boards/services/board-helper.service';
import { BoardLocalStorageService } from '@app/w-boards/services/board-local-storage.service';
import { BoardSortingService } from '@app/w-boards/services/board-sorting.service';

@NgModule({
	providers: [
		BoardHelperService,
		BoardFilterService,
		BoardSortingService,
		BoardLocalStorageService
	]
})
export class BoardHelperModule {}
