import { AfterViewInit, Directive, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GaCollectionComponent } from '@app/shared/ga-components/components/collection/ga-collection.component';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { FormScopeProviderDirective } from '@app/shared/ga-components/components/group/form-scope-provider.directive.abstract';
import { FormScopeService } from '@app/shared/ga-components/services/form-scope.service';
import { GaMetadataAdapterService } from '@app/shared/ga-components/services/ga-metadata-adapter.service';
import { FormScopeSavingProgressHelper } from '@app/shared/ga-components/utils/form-scope-saving-progress';
import { GaCollectionItem } from '@app/shared/ga-components/utils/ga-collection-item';
import * as _ from 'lodash';

@Directive({
	selector: 'ga-collection-item, [ga-collection-item]',
	exportAs: 'gaCollectionItem',
	providers: [EntityScopeService, FormScopeService, FormScopeSavingProgressHelper]
})
export class GaCollectionItemDirective extends FormScopeProviderDirective implements AfterViewInit, OnInit {

	isPersisted = false;
	private _item: GaCollectionItem;

	constructor(entityScope: EntityScopeService,
				formScope: FormScopeService,
				fieldMetadataService: GaMetadataAdapterService,
				savingProgressHelper: FormScopeSavingProgressHelper,
				@Inject(forwardRef(() => GaCollectionComponent)) private gaCollection: GaCollectionComponent) {
		super(entityScope, fieldMetadataService, formScope, savingProgressHelper);
	}

	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('item') set item(value: GaCollectionItem) {
		this._item = value;
		if (value) {
			this.entityScope.entity = value.data;
			this.formScope.formGroup.get('id').setValue(value.data.id);
			if (value.config) {
				this.editMode = value.config.editMode;
				this.isPersisted = value.config.persisted;
			}
			if (value.config.mappedByField && value.config.mappedById) {
				this.formScope.formGroup.addControl(value.config.mappedByField, new UntypedFormControl(value.config.mappedById));
			}
			this.entityScope.uid = value.id;
		}
	}

	get value() {
		return this.entityScope && this.entityScope.entity;
	}

	get form() {
		return this.formScope;
	}

	public ngOnInit(): void {
		this.entityScope.gaCollection = this.gaCollection;
		this.entityScope.entityClass = this.gaCollection.fieldMetadata.association.targetEntity;
		super.ngOnInit();
		if (this._item) {
			this.gaCollection.registerCollectionItem(this.formScope.formGroup);
			this.onSuccess.subscribe(() => {
				this.isPersisted = true;
			});
		}
	}

	public cancel() {
		if (this.isPersisted) {
			this.editMode = false;
		} else {
			this.gaCollection.removeItem(this._item);
		}
	}

	public ngAfterViewInit(): void {
		_.each(this.extraFields, (field) => {
			this.formScope.formGroup.addControl(field, new UntypedFormControl());
		});
		if (this._item) {
			_.each(this.extraFields, (field) => {
				this.formScope.formGroup.get([field]).patchValue(_.get(this.entityScope.entity, field),
					{ emitEvent: false }); //instead of using _.get, valueGetter should be probaly used (need componentMetadata to do so)
			});
			this.entityScope.emitter.next(this._item.data);
		}
	}

}

export interface CollectionItemConfig {
	mappedByField?: string;
	mappedById?: string;
	editMode?: boolean;
	persisted?: boolean;
	addOnTop?: boolean;
	showRemoveDialog?: boolean;
}
