<span class="validation-labels">
    <span *ngIf="control.hasValidator(validators.required)">
        (<span [attr.data-cy]="defaultDataCy" [class.invalid]="control?.errors?.required" class="validation-labels__item">required</span>)
    </span>
    <span *ngIf="control.hasValidator(validators.email)">
        (<span [attr.data-cy]="defaultDataCy" [class.invalid]="control?.errors?.email" class="validation-labels__item">email</span>)
    </span>
	<span *ngIf="control.errors?.maxlength">
        (<span [attr.data-cy]="defaultDataCy" [class.invalid]="control?.errors?.maxlength"
			   class="validation-labels__item">max length: {{control.errors.maxlength.requiredLength}}</span>)
    </span>
	<ng-container *ngIf="control?.errors?.customValidationErrors?.length > 0">
		<span *ngFor="let error of control.errors?.customValidationErrors" class="validation-labels__item invalid">
			{{ error.customError }}
		</span>
	</ng-container>
</span>
