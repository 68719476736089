import { formatNumber } from '@angular/common';
import { ColDef } from 'ag-grid-community';
import { get } from 'lodash';

export function numberColumnDefinition(field: string, isDecimal = false): ColDef {
	return {
		valueGetter: ({ data, node }) => {
			if (node.group) {
				return null;
			}
			const value = get(data, field, null);
			return value ? parseFloat(value) : 0;
		},
		valueFormatter: ({ value }) => {
			const digitsInfo = isDecimal ? '1.2' : '1.0-2';
			return value != null ? formatNumber(value, 'en-US', digitsInfo) : '';
		},
		filter: 'agNumberColumnFilter',
		cellClass: ({ value }) => Number.isInteger(value) ? 'numberType' : 'decimalNumberType'
	};
}
