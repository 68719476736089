import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { fadeInOut, fadeUpDown, heightGrow } from '@app/shared/animations/animations';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { EntityId } from '@app/shared/model/types/entity-id';
import { TodoCardData } from '@app/todo/model/todo-card-data';
import { TodoSortService } from '@app/todo/services/todo-sort.service';
import { SortType } from '@app/todo/todo-sidebar/todo-sidebar-sort/todo-sidebar-sort.model';
import * as _ from 'lodash';
import { combineLatest, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'todo-whiteboard-column',
	templateUrl: './whiteboard-column.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		fadeUpDown,
		heightGrow,
		fadeInOut,
	],
	providers: [TodoSortService]
})
export class TodoWhiteboardColumnComponent implements OnInit, OnDestroy {

	@Input() todos: TodoCardData[] = [];
	@Input() tag: string;
	@Input() config: { label: string, labelColor: string, lengthLabel: string };
	@Output() onRemove = new EventEmitter<EntityId>();

	isLoading = false;
	private destroy$ = new Subject<void>();

	constructor(
		public changeDetector: ChangeDetectorRef,
		public apiLink: GaApiLink,
		public flashMessage: FlashMessageService,
		private todoSortService: TodoSortService
	) {
	}

	public ngOnInit(): void {
		combineLatest([
			this.todoSortService.sortItem$,
			this.todoSortService.getSortDirectionString$
		]).pipe(takeUntil(this.destroy$))
			.subscribe(([sortItem, sortType]) => {
				if (sortType && sortItem?.value) {
					this.todos = _.orderBy(this.todos, [sortItem.value], [sortType]);
				} else {
					this.todos = _.orderBy(this.todos, ['dueDate'], [SortType.Asc]);
				}
			});
	}

	public addItem(todo): void {
		this.isLoading = true;
		this.apiLink.update('todo', todo, { status: this.tag }, [])
			.subscribe({
				next() {
					this.isLoading = false;
					this.changeDetector?.markForCheck();
				},
				error() {
					this.isLoading = false;
					this.changeDetector?.markForCheck();
					this.flashMessage.showMessage(
						FlashMessageType.Danger,
						'Some error occurred when moving card. Please contact the dev team.'
					);
				}
			});
	}

	public removeItem(todo): void {
		this.todos = _.without(this.todos, todo);
	}

	public removeFromStore(todoId: EntityId) {
		this.onRemove.next(todoId);
	}

	public trackByFn(index, item): number | null {
		return !item ? null : item.id;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
