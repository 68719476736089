import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DashboardDataUtilsService } from '@app/dashboard/dashboard-data-utils.service';
import { CustomPeriod } from '@app/dashboard/shared/dashboard-shared.model';
import { ConfigurationChangedEvent, Period, Resolution } from '@app/dashboard/shared/time-related-configuration/time-related-configuration.model';
import { NgSelectModule } from '@ng-select/ng-select';
import { LetDirective } from '@ngrx/component';
import * as moment from 'moment';
import { combineLatest, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'time-related-configuration',
	templateUrl: 'time-related-configuration.component.html',
	styleUrls: ['time-related-configuration.component.scss'],
	imports: [
		NgSelectModule,
		AsyncPipe,
		ReactiveFormsModule,
		LetDirective,
		NgIf,
		NgClass
	],
	standalone: true
})
export class TimeRelatedConfigurationComponent implements OnInit, OnDestroy {
	@Output() configurationChanged = new EventEmitter<ConfigurationChangedEvent>();

	resolution = Resolution.Monthly;
	readonly Resolution = Resolution;
	periodEmitter$ = new ReplaySubject<Period>(1);
	readonly Period = Period;

	startDateOptions$: Observable<CustomPeriod[]>;
	endDateOptions$: Observable<CustomPeriod[]>;
	startDateControl = new FormControl<CustomPeriod>(null);
	endDateControl = new FormControl<CustomPeriod>(null);

	quarterOptions: CustomPeriod[];
	annuallyOptions: CustomPeriod[];
	monthlyOptions: CustomPeriod[];

	private customDateOptions$ = new ReplaySubject<CustomPeriod[]>(1);
	private configurationChangedEmitter = new Subject<ConfigurationChangedEvent>();
	private destroy$ = new Subject<void>();

	constructor(private dashboardDataUtils: DashboardDataUtilsService) {
	}

	public ngOnInit(): void {
		const [yearOptions, quarterOptions, monthlyOptions] = this.dashboardDataUtils.getDateOptions();
		this.annuallyOptions = yearOptions;
		this.quarterOptions = quarterOptions;
		this.monthlyOptions = monthlyOptions;
		this.startDateOptions$ = this.customDateOptions$.asObservable();

		this.configurationChangedEmitter
			.pipe(
				distinctUntilChanged((prev, current) => {
					return prev.resolution == current.resolution &&
						prev.from == current.from &&
						prev.to == current.to;
				}),
				takeUntil(this.destroy$)
			).subscribe(event => {
			this.configurationChanged.next(event)
		});

		const startDateValueChanges = this.startDateControl.valueChanges
			.pipe(
				startWith(null),
				map(() => this.startDateControl.value)
			);
		this.endDateOptions$ = combineLatest([startDateValueChanges, this.customDateOptions$])
			.pipe(
				map(([selectedOption, options]) => {
					const minIndex = options.findIndex(option => option.label == selectedOption.label);
					return options.slice(minIndex);
				})
			);

		merge(this.startDateControl.valueChanges, this.endDateControl.valueChanges)
			.pipe(
				filter(() => this.startDateControl.value !== null && this.endDateControl.value !== null),
				map(() => [this.startDateControl.value, this.endDateControl.value]),
				takeUntil(this.destroy$)
			)
			.subscribe(([startDateOption, endDateOption]) => {
				const endDate = endDateOption.to;
				const startDate = startDateOption.from;
				if (endDate && moment(startDate) > moment(endDate)) {
					this.endDateControl.reset();
				} else {
					this.configurationChangedEmitter.next({ from: startDate, to: endDate, resolution: this.resolution });
				}
			});

		combineLatest([this.periodEmitter$, this.customDateOptions$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([period, options]) => {
				this.setCustomDatesByPeriod(period, options);
			});

		this.togglePeriod(Period.Last12Months);
		this.toggleChartResolution(Resolution.Monthly);
	}

	private setCustomDatesByPeriod(period: Period, options: CustomPeriod[]): void {
		let startDate;
		let endDate = options[options.length - 1];
		if (period == Period.Last12Months) {
			const lastYear = moment().subtract(11, 'month');
			startDate = options.find(option => lastYear.isBetween(option.from, option.to, 'day', '[]'));
		} else if (period == Period.Lifetime) {
			startDate = options[0];
		} else {
			const currentStartDate = moment(this.startDateControl.value.from);
			const currentEndDate = moment(this.endDateControl.value.from);
			startDate = options.find(option => currentStartDate.isBetween(option.from, option.to, 'day', '[]')) ?? options[0];
			endDate = options.find(option => currentEndDate.isBetween(option.from, option.to, 'day', '[]')) ?? options[options.length - 1];
		}
		this.endDateControl.setValue(endDate, { emitEvent: false });
		this.startDateControl.setValue(startDate);
	}

	public toggleChartResolution(resolution: Resolution) {
		this.resolution = resolution;
		switch (resolution) {
			case Resolution.Monthly:
				this.customDateOptions$.next(this.monthlyOptions);
				break;
			case Resolution.Quarterly:
				this.customDateOptions$.next(this.quarterOptions);
				break;
			case Resolution.Annually:
				this.customDateOptions$.next(this.annuallyOptions);
				this.togglePeriod(Period.Lifetime);
				break;
		}
	}

	public togglePeriod(period: Period) {
		this.periodEmitter$.next(period);
	}

	public ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
