import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { TodoCommentCardFormComponent } from '@app/todo/todo-card/todo-comments/todo-comment-card-form/todo-comment-card-form.component';
import { MentionsDropdownRepositionDirective } from '@app/todo/todo-card/todo-comments/todo-comment-form/mentions-dropdown-reposition.directive';
import { TodoCommentFormComponent } from '@app/todo/todo-card/todo-comments/todo-comment-form/todo-comment-form.component';
import { ReplaceMentionsPipe } from '@app/todo/todo-card/todo-comments/todo-comment/replace-mentions.pipe';
import { TodoCommentComponent } from '@app/todo/todo-card/todo-comments/todo-comment/todo-comment.component';
import { TodoCommentsSortingComponent } from '@app/todo/todo-card/todo-comments/todo-comments-sorting/todo-comments-sorting.component';
import { TodoCommentsComponent } from '@app/todo/todo-card/todo-comments/todo-comments.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMentionsModule } from '@grownapps/ngx-mentions';
import { LetModule } from '@ngrx/component';

@NgModule({
	imports: [
		ReactiveFormsModule,
		CommonModule,
		SharedModule,
		FontAwesomeModule,
		LetModule,
		NgxMentionsModule,
	],
	declarations: [
		TodoCommentsComponent,
		TodoCommentComponent,
		TodoCommentsSortingComponent,
		TodoCommentFormComponent,
		MentionsDropdownRepositionDirective,
		TodoCommentCardFormComponent,
		ReplaceMentionsPipe,
	],
	exports: [
		TodoCommentsComponent,
		TodoCommentFormComponent
	]
})
export class TodoCommentsModule {}
