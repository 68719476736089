import { Persistable } from '@app/core/data-watch/utils';
import { FilterVisitor } from '@app/shared/filter-visitors/models/filter-visitor';
import _ from 'lodash';

export class StringFilterVisitor<T extends Persistable> extends FilterVisitor<string> {

	constructor(private path: string) {
		super(path);
	}

	public filter(filterObject: T): boolean {
		return _.get(filterObject, this.path, null)?.toLowerCase() == this.value.toLowerCase();
	}

	public isActive(): boolean {
		return this.value != null && this.value.length > 0;
	}
}
