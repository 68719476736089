import { ChartModel } from 'ag-grid-community';
import { merge } from 'lodash';
import { TooltipCreator } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/tooltip-creator';

export class ChartSettingsStorage {

	constructor(private tooltipCreator: TooltipCreator) {
	}

	public hasSavedChart(storageKey: string): boolean {
		return localStorage.getItem(storageKey) !== null;
	}

	public getSavedColumns(storageKey: string, modelIndex = 0): string[] {
		if (!this.hasSavedChart(storageKey)) {
			return [];
		}
		const savedChartModels: ChartModel[] = JSON.parse(localStorage.getItem(storageKey));
		return savedChartModels[modelIndex].cellRange.columns as string[];
	}

	public getSavedChart(storageKey: string, tooltipConfig: any, modelIndex = 0): ChartModel | null {
		if (!this.hasSavedChart(storageKey)) {
			return null;
		}
		const savedChartModels = JSON.parse(localStorage.getItem(storageKey));
		const savedModel = savedChartModels[modelIndex];

		return merge({}, this.tooltipCreator.create(tooltipConfig), savedModel);
	}

	public saveChartModel(storageKey: string, models: ChartModel[]) {
		localStorage.setItem(storageKey, JSON.stringify(models));
	}

	public clearSavedChart(storageKey: string) {
		localStorage.removeItem(storageKey);
	}

}
