import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScrollToTopService {
	triggerScrollToTop$ = new Subject<void>;
	innerContainerScrollTop$ = new BehaviorSubject(0);
	outerContainerScrollTop$ = new BehaviorSubject(0);
	showButton$ = new BehaviorSubject(false);

	readonly showScrollOffset = 100;

	public toggleButtonVisibility(container: Element, isInnerContainer = false) {
		const scrollTop = container.scrollTop;
		if (isInnerContainer) {
			this.innerContainerScrollTop$.next(scrollTop)
		} else {
			this.outerContainerScrollTop$.next(scrollTop)
		}
		return Math.max(this.outerContainerScrollTop$.value, this.innerContainerScrollTop$.value) > this.showScrollOffset ?
			this.showButton$.next(true) : this.showButton$.next(false)
	}
}
