import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ConfirmClickService } from '@app/shared/confirmation-dialog/confirm-click.service';
import { CustomModalDismissReason } from '@app/shared/model/constants/modal-dismiss-reason';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Directive({
	selector: '[confirm-click]'
})
export class ConfirmClickDirective {
	@Input() confirmClickSubtitle = '';
	@Output('confirm-click') confirm: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		private modalService: BsModalService,
		private confirmClickService: ConfirmClickService
	) {
	}

	@HostListener('click', ['$event'])
	public onClick(event = null, confirmSubtitle?: string): void {
		const subtitle = (this.confirmClickSubtitle != '') ? this.confirmClickSubtitle : confirmSubtitle;
		const modal: BsModalRef = this.confirmClickService.createModalInstance(event, subtitle);

		modal.content.choice$.subscribe((choice: boolean) => {
			if (choice) {
				this.confirm.emit(choice);
			}
			this.modalService.setDismissReason(CustomModalDismissReason.ConfirmDialogClosed);
			modal.hide();
		});
	}
}
