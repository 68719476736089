import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlashMessage, FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { faCheck, faExclamationCircle, faExclamationTriangle, faInfo } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'flash-message',
	templateUrl: './flash-message.component.html',
	styleUrls: ['./flash-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlashMessageComponent implements OnInit, OnChanges, OnDestroy {

	@Input() message: FlashMessage;
	@Input() count: number;
	@Output() closeMessage: EventEmitter<string> = new EventEmitter<string>();

	flashMessageType = FlashMessageType;

	readonly faIcons = {
		exclamationCircle: faExclamationCircle,
		exclamationTriangle: faExclamationTriangle,
		info: faInfo,
		check: faCheck
	};

	private countChanged$ = new BehaviorSubject<void>(null);
	private destroy$ = new Subject<void>();

	public ngOnInit(): void {
		this.countChanged$.pipe(
			switchMap(() => timer(this.message.timeout)),
			map(() => this.close()),
			takeUntil(this.destroy$)
		).subscribe();
	}

	public close(): void {
		this.closeMessage.emit(this.message.id);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.count) {
			this.countChanged$.next(null);
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
