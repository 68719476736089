import { PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { GaDecoratorInterface } from '@app/shared/ga-components/utils/ga-decorator-interface';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';

@Injectable()
export class PercentageDecorator implements GaDecoratorInterface {

	constructor(private percentPipe: PercentPipe) {
	}

	public create(componentMetadata: ComponentMetadata): MetadataDecorator {
		return {
			valueFormatter: (value) => {
				if (value === null) {
					return 'N/A';
				}

				try {
					const fractionDigits = componentMetadata.fieldMetadata.decoratorParams?.fractionDigits ?? 0;
					return this.percentPipe.transform(value, `1.${fractionDigits}-${fractionDigits}`);
				} catch (error) {
					return 'N/A';
				}
			},
			valueParser: value => {
				if (value) {
					const matchArray = value.match(/[0-9.]+/g);
					if (matchArray) {
						return Number(matchArray.join('')) / 100;
					}
				}
				return null;
			}
		};
	}
}
