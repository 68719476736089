import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { AclService } from '@app/core/security/acl.service';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { AgGroupRendererComponent } from '@app/shared/ag-grid-utils/ag-group-renderer/ag-group-renderer.component';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { DefaultVintageDetailConfig, defaultVintageDetailConfigProvider, VintageDetailConfig } from '@app/shared/modals/vintage-detail/vintage-detail-config';
import { Funding } from '@app/shared/model/types/funding';
import { ApprovalStatusPipe } from '@app/shared/pipes/approval-status.pipe';
import { GridReadyEvent } from 'ag-grid-community';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

@Component({
	selector: 'vintage-detail',
	templateUrl: './vintage-detail-modal.component.html',
	styleUrls: ['vintage-detail-modal.component.scss'],
	providers: [
		ApprovalStatusPipe,
		defaultVintageDetailConfigProvider
	]
})
export class VintageDetailModalComponent implements OnInit, OnDestroy {
	fundings$: Observable<Funding[]>;
	config: VintageDetailConfig;
	jqlFilter;
	gridOptions;

	private destroy$ = new Subject<void>();

	constructor(
		private apiLink: GaApiLink,
		private agUtils: AgGridUtilsService,
		private router: Router,
		private acl: AclService,
		@Inject(DefaultVintageDetailConfig) private defaultConfig: VintageDetailConfig,
		private bsModalRef: BsModalRef) {}

	public ngOnInit(): void {
		this.gridOptions = this.agUtils.getGridOptions({
			suppressExcelExport: !this.acl.isAllowed('export.dashboard_vintage_tables'),
			defaultExcelExportParams: {
				fileName: 'Vintage Detail Export',
				processCellCallback: this.agUtils.generalProcessCellCallback
			},
			components: {
				agGroup: AgGroupRendererComponent
			},
			defaultColDef: {
				minWidth: 130
			},
		});

		this.config.filter = _.defaultsDeep(this.config.filter, this.defaultConfig.filter);
		this.config.fieldList = this.config.fieldList ?? this.defaultConfig.fieldList;
		this.config.columnDefinitions = this.config.columnDefinitions ?? this.defaultConfig.columnDefinitions;
		this.jqlFilter = _.cloneDeep(this.config.filter);
		this.setJqlDateFilter('fundedOn');
		this.setJqlDateFilter('createdOn');
		this.setJqlDateFilter('settledOn');
		this.router.events
			.pipe(filter(event => event instanceof RoutesRecognized), takeUntil(this.destroy$))
			.subscribe(() => {
				this.bsModalRef.hide();
			});
		this.fundings$ = this.apiLink.retrieve('funding', this.config.fieldList, this.jqlFilter);
	}

	private setJqlDateFilter(path: string): void {
		if (_.has(this.config, `filter.${path}`)) {
			_.set(this.jqlFilter, path, this.getJqlDateFilter(_.get(this.config, `filter.${path}`)));
		}
	}

	private getJqlDateFilter(date): any {
		if (!_.has(date, 'from')) {
			const dateAsMoment = moment(date);
			return this.getJqlDateFilter({ from: dateAsMoment, to: dateAsMoment });
		}
		const fromDate = moment(date.from);
		const toDate = moment(date.to);
		return {
			'%and': {
				'%gte': fromDate.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
				'%lte': toDate.endOf('day').format('YYYY-MM-DD HH:mm:ss')
			}
		};
	}

	public onGridReady(params: GridReadyEvent): void {
		params.api.sizeColumnsToFit();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.defaultConfig.unsubscribe();
	}
}
