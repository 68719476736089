<div class="modal-body" style="overflow: visible">
	<h3 class="m-t-none m-b" style="text-align: center">Report Options</h3>
	<div class="row">
		<div class="col-md-12 m-b">
			<h4>Date Range</h4>
			<daterange-picker [rangesEmitter]="ranges$" (dateRangeSelected)="setDateRange($event)" style="min-width: 400px;"></daterange-picker>
		</div>
	</div>
	<div class="row m-t-md m-b">
		<div class="col-md-6">
			<label class="title">Credit Facility</label>
			<div class="select">
				<span (click)="selectAll(config.creditFacilities)"><fa-icon [icon]="faIcons.checkSquare"></fa-icon> all</span>&nbsp;
				<span (click)="selectNone(config.creditFacilities)"><fa-icon [icon]="faIcons.square"></fa-icon> none</span>
			</div>
			<div *ngFor="let creditFacility of config.creditFacilities" class="checkbox">
				<label><input type="checkbox" [(ngModel)]="creditFacility.selected"> {{ creditFacility.title }}</label>
			</div>
		</div>
		<div class="col-md-6">
			<label class="title">Funding Type</label>
			<div class="select">
				<span (click)="selectAll(config.fundingTypes)"><fa-icon [icon]="faIcons.checkSquare"></fa-icon> all</span>&nbsp;
				<span (click)="selectNone(config.fundingTypes)"><fa-icon [icon]="faIcons.square"></fa-icon> none</span>
			</div>
			<div *ngFor="let fundingType of config.fundingTypes" class="checkbox">
				<label><input type="checkbox" [(ngModel)]="fundingType.selected"> {{ fundingType.title }}</label>
			</div>
		</div>
	</div>
	<download-link [config$]="downloadLink$"></download-link>
	<div class="row">
		<div class="col-md-12"><button class="btn btn-primary" (click)="download()">Download</button></div>
	</div>
</div>
