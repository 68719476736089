import { Pipe, PipeTransform } from '@angular/core';
import { ApprovalStatus } from '@app/shared/model/constants/approval-status';

@Pipe({ name: 'approvalStatus' })
export class ApprovalStatusPipe implements PipeTransform {

	public transform(approvalStatus: number) {
		switch (approvalStatus) {
			case ApprovalStatus.Pending:
				return ApprovalStatus[ApprovalStatus.Pending];
			case ApprovalStatus.Approved:
				return ApprovalStatus[ApprovalStatus.Approved];
			case ApprovalStatus.Funded:
				return ApprovalStatus[ApprovalStatus.Funded];
			case ApprovalStatus.Rejected:
				return ApprovalStatus[ApprovalStatus.Rejected];
			case ApprovalStatus.Settled:
				return ApprovalStatus[ApprovalStatus.Settled];
			case ApprovalStatus.Consult:
				return ApprovalStatus[ApprovalStatus.Consult];
			default:
				return '';
		}
	}
}
