import { Component, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IRenderer } from '@app/shared/ga-components/utils/IRenderer';
import { isFunction } from 'lodash';

@Component({
	selector: 'ga-default-text-renderer',
	template: `<span
		[attr.data-cy]="(componentMetadata.dataCy ?? componentMetadata.fieldName) + '-value'"
		[innerHTML]="formattedValue">
	</span>`,
	styles: ['span {white-space: pre-wrap;}']
})
export class GaTextRendererComponent implements IRenderer {

	value: string;
	dataObj: string;
	componentMetadata: ComponentMetadata;

	params: any;

	formattedValue: string;

	constructor(public domSanitizer: DomSanitizer) {}

	public redraw(): void {
		this.formattedValue = isFunction(this.componentMetadata.valueFormatter)
			? this.componentMetadata.valueFormatter(this.value)
			: this.value;
		this.formattedValue = this.domSanitizer.sanitize(SecurityContext.HTML, this.formattedValue);
	}

}
