import { Component } from '@angular/core';
import { IStatusPanelParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { startWith, take } from 'rxjs/operators';

interface ServerSideStatusBarParams extends IStatusPanelParams {
	source$: Observable<number>
}

@Component({
	selector: 'ag-server-side-status-bar',
	template: '<span>Total Rows: <b>{{ totalRows$ | async }}</b></span>'
})
export class AgServerSideStatusBarComponent {
	totalRows$: Observable<number>;
	private params: ServerSideStatusBarParams;

	public agInit(params: ServerSideStatusBarParams): void {
		this.params = params;
		this.totalRows$ = params.source$.pipe(take(1), startWith(0));
	}

}
