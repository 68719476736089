import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeInOut, fadeUpDown, heightGrow } from '@app/shared/animations/animations';
import { EntityAssociationType } from '@app/shared/model/constants/entity-association-type';
import { EntityAssociation } from '@app/shared/model/types/entity-association';
import { Todo } from '@app/shared/model/types/todo';
import { TodoAssociationsListEntry } from '@app/todo/model/todo-associations-list-entry';
import { TodoCardAssociationState } from '@app/todo/todo-card/todo-card-associations/todo-card-associations.model';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'todo-card-associations',
	templateUrl: './todo-card-associations.component.html',
	animations: [fadeUpDown, heightGrow, fadeInOut],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoCardAssociationsComponent {
	@Input() set associations(items: EntityAssociation[]) {
		if (items) {
			const associations = items.map(item => {
				return {
					...item,
					loading: false
				};
			});
			this.associations$.next(associations);
		} else {
			this.associations$.next([]);
		}
	}

	get associations(): TodoCardAssociationState[] {
		return this.associations$.value;
	}

	@Input() todoItemId: string;
	@Input() headerClass = '';

	associations$ = new BehaviorSubject<TodoCardAssociationState[]>([]);

	readonly faIcons = {
		spinner: faSpinner,
	};

	constructor(
		private http: HttpClient
	) {
	}

	public addAssociation(association: TodoAssociationsListEntry): void {
		let associationTypeRequest = `${association.association_type}s`;
		if (association.association_type == EntityAssociationType.Attorney) {
			associationTypeRequest = 'lawFirmStaff';
		}
		if (association.association_type == EntityAssociationType.MedicalFacility) {
			associationTypeRequest = 'medicalFacilities';
		}
		const payload = { [associationTypeRequest]: [association.referenceId] };

		const { parentReferenceId, associationClass, ...associationState } = association;
		this.associations$.next([...this.associations, { ...associationState, loading: true }]);

		this.http.post<Todo>(`/api/todos/${this.todoItemId}/associations`, payload).subscribe(() => {
			this.associations = [...this.associations];
		});
	}

	public removeAssociation(associationItem: TodoAssociationsListEntry): void {
		associationItem.loading = true;
		this.http.delete(`/api/todos/${this.todoItemId}/associations/${associationItem.id}`).subscribe(() => {
			const indexToRemove = this.associations.findIndex((association) => association.id == associationItem.id);
			if (indexToRemove !== -1) {
				const associationsCopy = [...this.associations];
				associationsCopy.splice(indexToRemove, 1);
				this.associations = associationsCopy;
			}
		});
	}
}
