import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

interface DashboardRoundState {
	round: boolean;
}

@Injectable()
export class DashboardRoundStore extends ComponentStore<DashboardRoundState> {
	readonly setRound = this.updater((state, round: boolean) => {
		localStorage.setItem('dashboardRound', round.toString());
		return { round };
	});
	readonly selectRound$ = this.select(({ round }) => round);

	constructor() {
		super({ round: false });
	}
}
