import { attorneyDbConfig } from '@app/database/codelist-db-configs/attorney-db.config';
import { caseManagerDbConfig } from '@app/database/codelist-db-configs/case-manager-db.config';
import { cptCodeDbConfig } from '@app/database/codelist-db-configs/cpt-code-db.config';
import { csrDbConfig } from '@app/database/codelist-db-configs/csr-db.config';
import { doctorDbConfig } from '@app/database/codelist-db-configs/doctor-db.config';
import { employeeDbConfig } from '@app/database/codelist-db-configs/employee-db.config';
import { fundingConsultantDbConfig } from '@app/database/codelist-db-configs/funding-consultant-db.config';
import { fundingSchedulerDbConfig } from '@app/database/codelist-db-configs/funding-scheduler-db.config';
import { insuranceCompanyDbConfig } from '@app/database/codelist-db-configs/insurannce-company-db.config';
import { lawFirmsDbConfig } from '@app/database/codelist-db-configs/law-firm-db.config';
import { lawFirmManagerDbConfig } from '@app/database/codelist-db-configs/law-firm-manager-db.config';
import { medicalAEDbConfig } from '@app/database/codelist-db-configs/medical-ae-db.config';
import { medicalFacilityDbConfig } from '@app/database/codelist-db-configs/medical-facility-db.config';
import { mriSchedulerDbConfig } from '@app/database/codelist-db-configs/mri-scheduler-db.config';
import { negotiatorDbConfig } from '@app/database/codelist-db-configs/negotiator-db.config';
import { practiceGroupDbConfig } from '@app/database/codelist-db-configs/practice-group-db.config';
import { salesPersonDbConfig } from '@app/database/codelist-db-configs/sales-person-db.config';
import { salesRepresentativeDbConfig } from '@app/database/codelist-db-configs/sales-representative-db.config';
import { underwriterDbConfig } from '@app/database/codelist-db-configs/underwriter-db.config';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';

export const objectStoresMappingConfig = {
	[UrlCodelistLink.Attorneys]: attorneyDbConfig,
	[UrlCodelistLink.CaseManagers]: caseManagerDbConfig,
	[UrlCodelistLink.CptCodes]: cptCodeDbConfig,
	[UrlCodelistLink.CustomerServiceRepresentatives]: csrDbConfig,
	[UrlCodelistLink.Doctors]: doctorDbConfig,
	[UrlCodelistLink.Employees]: employeeDbConfig,
	[UrlCodelistLink.FundingConsultants]: fundingConsultantDbConfig,
	[UrlCodelistLink.FundingSchedulers]: fundingSchedulerDbConfig,
	[UrlCodelistLink.InsuranceCompanies]: insuranceCompanyDbConfig,
	[UrlCodelistLink.LawFirms]: lawFirmsDbConfig,
	[UrlCodelistLink.LawFirmManagers]: lawFirmManagerDbConfig,
	[UrlCodelistLink.MedicalAEs]: medicalAEDbConfig,
	[UrlCodelistLink.MedicalFacilities]: medicalFacilityDbConfig,
	[UrlCodelistLink.MriSchedulers]: mriSchedulerDbConfig,
	[UrlCodelistLink.Negotiators]: negotiatorDbConfig,
	[UrlCodelistLink.PracticeGroups]: practiceGroupDbConfig,
	[UrlCodelistLink.SalesPersons]: salesPersonDbConfig,
	[UrlCodelistLink.SalesRepresentatives]: salesRepresentativeDbConfig,
	[UrlCodelistLink.Underwriters]: underwriterDbConfig
}
