import { Pipe, PipeTransform } from '@angular/core';
import { padStart } from 'lodash';

@Pipe({ name: 'leftPad' })
export class LeftPadPipe implements PipeTransform {

	public transform(value: string, length: number): string {
		return padStart(value, length);
	}

}
