import { formatPercent } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { addPercentage, getSumByAllProperties, Percentage } from '@app/dashboard/top-five-section/shared/top-five-section.utils';
import { CartesianSavedChart } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/cartesian-saved-chart';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { CartesianGroupedTooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/cartesian-grouped-tooltip-theme.service';
import { chartNumberAxesTheme, chartPriceAxesTheme } from '@app/shared/ag-grid-utils/chart-themes/chart-axes-theme';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { ChartRef, FirstDataRenderedEvent, GridApi } from 'ag-grid-community';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

type AgeBreakdownData = Required<MonthsBreakdownResponse & Percentage<MonthsBreakdownResponse>>;

enum ChartIndex {
	Deals,
	Prices
}

@Component({
	selector: 'age-breakdown',
	templateUrl: 'age-breakdown.component.html',
	styleUrls: ['age-breakdown.component.scss'],
})
export class AgeBreakdownComponent implements OnInit, OnDestroy {

	rowData$: Observable<AgeBreakdownData[]>;

	columnDefs;
	gridOptions;

	gridApi: GridApi;

	hasSavedChart$ = new ReplaySubject<boolean>(1);

	readonly clearIcon = SvgIconName.Preferences;

	private priceChartDirective: CartesianSavedChart;
	private dealsChartDirective: CartesianSavedChart;
	private chartRefs: [ChartRef, ChartRef] = [null, null];

	private destroy$ = new Subject<void>();

	constructor(
		private http: HttpClient,
		private dashboardFilterStore: DashboardFilterStore,
		private agGridUtils: AgGridUtilsService) {
	}

	public ngOnInit(): void {
		this.gridOptions = this.agGridUtils.getGridOptions({
			sideBar: false,
			defaultExcelExportParams: {
				fileName: 'Age Breakdown Export'
			}
		});
		this.rowData$ = this.dashboardFilterStore.fundingParams$
			.pipe(
				tap(() => this.gridApi?.showLoadingOverlay()),
				switchMap(params => this.http.get('/api/dashboard/age-breakdown', { params })),
				getSumByAllProperties<MonthsBreakdownResponse>(),
				addPercentage<MonthsBreakdownResponse>(),
				tap(() => this.gridApi?.hideOverlay()),
				shareReplay({ bufferSize: 1, refCount: true })
			);

		const tooltipTheme = new CartesianGroupedTooltipThemeService();
		this.columnDefs = [
			{
				field: 'title',
				headerName: 'Duration',
				chartDataType: 'category'
			},
			{
				headerName: 'Purchase Price',
				children: [
					{
						...tooltipTheme.addPercentageColumn(
							'Percentage',
							'principalPercentage'
						),
						valueFormatter: ({ value }) => formatPercent(value, 'en-US', '1.0-0'),
					},
					{
						...tooltipTheme.addPriceColumn(
							'Purchase Price',
							'principal'
						),
						headerValueGetter: ({ location }) => {
							if (location === 'chart') {
								return 'Purchase Price';
							}
							return 'Amount';
						}
					}
				]
			},
			{
				headerName: 'Face Value',
				children: [
					{
						...tooltipTheme.addPercentageColumn(
							'Percentage',
							'faceValuePercentage'
						),
						valueFormatter: ({ value }) => formatPercent(value, 'en-US', '1.0-0'),
					},
					{
						...tooltipTheme.addPriceColumn(
							'Face Value',
							'faceValue'
						),
						headerValueGetter: ({ location }) => {
							if (location === 'chart') {
								return 'Face Value';
							}
							return 'Amount';
						},
					}
				]
			},
			{
				headerName: '# of Deals',
				children: [
					{
						...tooltipTheme.addPercentageColumn(
							'Percentage',
							'dealsCountPercentage'
						),
						valueFormatter: ({ value }) => formatPercent(value, 'en-US', '1.0-0'),
					},
					{
						...tooltipTheme.addNumberColumn(
							'Deals',
							'dealsCount'
						),
						headerValueGetter: ({ location }) => {
							if (location === 'chart') {
								return 'Deals';
							}
							return 'Amount';
						},
					}
				]
			},
			{
				headerName: '# of Cases',
				children: [
					{
						...tooltipTheme.addPercentageColumn(
							'Percentage',
							'claimsPercentage'
						),
						valueFormatter: ({ value }) => formatPercent(value, 'en-US', '1.0-0'),
					},
					{
						...tooltipTheme.addNumberColumn(
							'Cases',
							'claims'
						),
						headerValueGetter: ({ location }) => {
							if (location === 'chart') {
								return 'Cases';
							}
							return 'Amount';
						},
					}
				]
			}
		];

		this.priceChartDirective = new CartesianSavedChart(
			'dashboard-age-breakdown-charts',
			() => chartPriceAxesTheme('Age Breakdown - Prices'),
			tooltipTheme.copy(),
			1
		);
		this.dealsChartDirective = new CartesianSavedChart(
			'dashboard-age-breakdown-charts',
			() => chartNumberAxesTheme('Age Breakdown - Deals & Cases'),
			tooltipTheme.copy()
		);
		this.hasSavedChart$.next(this.dealsChartDirective.hasSavedChart() || this.priceChartDirective.hasSavedChart());

		this.rowData$
			.pipe(
				map(data => data.map(datum => ({ ...datum, chartLabel: datum.title }))),
				takeUntil(this.destroy$)
			).subscribe(data => {
			this.priceChartDirective.getTooltipTheme().setTooltipData(data);
			this.dealsChartDirective.getTooltipTheme().setTooltipData(data);
		});
	}

	public onFirstDataRendered(params: FirstDataRenderedEvent) {
		params.api?.sizeColumnsToFit();
		this.gridApi = params.api;
		this.createDealsChart();
		this.createPriceChart();
	}

	private createPriceChart() {
		if (this.chartRefs[ChartIndex.Prices] !== null) {
			this.chartRefs[ChartIndex.Prices].destroyChart();
		}
		const savedChart = this.getSavedChart(ChartIndex.Prices);
		if (savedChart !== null) {
			this.chartRefs[ChartIndex.Prices] = savedChart;
			this.priceChartDirective.getTooltipTheme().setChart(savedChart.chart);
			return;
		}
		const pricesGroupTooltip = this.priceChartDirective.getTooltipTheme().getGroupedTooltipTheme(['principal', 'faceValue']);
		this.chartRefs[ChartIndex.Prices] = this.gridApi.createRangeChart({
			chartType: 'customCombo',
			cellRange: {
				columns: ['title', 'principal', 'faceValue']
			},
			seriesChartTypes: [
				{ colId: 'principal', chartType: 'groupedColumn' },
				{ colId: 'faceValue', chartType: 'groupedColumn' },
			],
			chartContainer: document.querySelector('#age-breakdown-prices'),
			chartThemeName: 'ag-pastel',
			chartThemeOverrides: {
				...chartPriceAxesTheme('Age Breakdown - Prices'),
				column: {
					series: {
						...pricesGroupTooltip
					}
				},
				line: {
					series: {
						...pricesGroupTooltip
					}
				}
			}
		});

		this.priceChartDirective.getTooltipTheme().setChart(this.chartRefs[ChartIndex.Prices].chart);
	}

	private createDealsChart() {
		if (this.chartRefs[ChartIndex.Deals] !== null) {
			this.chartRefs[ChartIndex.Deals].destroyChart();
		}
		const savedChart = this.getSavedChart(ChartIndex.Deals);
		if (savedChart !== null) {
			this.chartRefs[ChartIndex.Deals] = savedChart;
			this.dealsChartDirective.getTooltipTheme().setChart(savedChart.chart);
			return;
		}
		const dealsCasesGroupTooltip = this.dealsChartDirective.getTooltipTheme().getGroupedTooltipTheme(['dealsCount', 'claims']);
		this.chartRefs[ChartIndex.Deals] = this.gridApi.createRangeChart({
			chartType: 'customCombo',
			cellRange: {
				columns: ['title', 'dealsCount', 'claims']
			},
			seriesChartTypes: [
				{ colId: 'dealsCount', chartType: 'groupedColumn' },
				{ colId: 'claims', chartType: 'groupedColumn' },
			],
			chartThemeName: 'ag-pastel',
			chartContainer: document.querySelector('#age-breakdown-cases'),
			chartThemeOverrides: {
				...chartNumberAxesTheme('Age Breakdown - Deals & Cases'),
				column: {
					series: {
						...dealsCasesGroupTooltip
					}
				},
				line: {
					series: {
						...dealsCasesGroupTooltip
					}
				}
			}
		});

		this.dealsChartDirective.getTooltipTheme().setChart(this.chartRefs[ChartIndex.Deals].chart);
	}

	private getSavedChart(modelIndex = 0): ChartRef | null {
		const savedDirective = modelIndex === ChartIndex.Prices ? this.priceChartDirective : this.dealsChartDirective;
		const savedModel = savedDirective.getSavedChart();
		if (savedModel !== null) {
			return this.gridApi.restoreChart(savedModel, this.getChartElement(modelIndex));
		}
		return null;
	}

	public onChartOptionsChanged() {
		const models = this.gridApi.getChartModels();
		this.dealsChartDirective.setTooltipIds(models[ChartIndex.Deals].cellRange.columns as string[]);
		this.priceChartDirective.setTooltipIds(models[ChartIndex.Prices].cellRange.columns as string []);
		this.dealsChartDirective.saveChart(models);
		this.hasSavedChart$.next(true);
	}

	public clearSavedChartState() {
		this.dealsChartDirective.clearSavedChart();
		this.priceChartDirective.clearSavedChart();
		this.hasSavedChart$.next(false);
		this.createDealsChart();
		this.createPriceChart();
	}

	protected getChartElement(modelIndex: ChartIndex): HTMLElement {
		if (modelIndex === ChartIndex.Prices) {
			return document.querySelector('#age-breakdown-prices');
		}
		return document.querySelector('#age-breakdown-cases');
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.chartRefs.forEach(chartRef => chartRef.destroyChart());
	}

}

type MonthsBreakdownResponse = {
	claims: number,
	dealsCount: number,
	faceValue: number,
	principal: number,
	fee: number,
	title: string
}
