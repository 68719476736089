<div class="inline-field">
	<label class="inline-field__label">RP Cost</label>
	<div class="inline-field__value">
		<fa-icon [icon]="faIcons.times" (click)="resetRpCost()" data-cy="rpCost-0"></fa-icon>
		<rating
			[max]="maxRpCost"
			[formControl]="rpCostControl"
			[customTemplate]="icon"
			[attr.data-cy]="">
		</rating>
		<ng-template #icon let-index="index" let-value="value">
			<fa-icon [icon]="faIcons.dollar" [attr.data-cy]="'rpCost-' + (index+1)" size="lg" [styles]="{color: index < value ? 'var(--primary)' : 'inherit'}"></fa-icon>
		</ng-template>
	</div>
</div>
