import { Injectable } from '@angular/core';
import { GaDecoratorInterface } from '@app/shared/ga-components/utils/ga-decorator-interface';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';
import * as _ from 'lodash';

@Injectable()
export class StateSelectboxDecorator implements GaDecoratorInterface {

	public create(): MetadataDecorator {
		return {
			dependencies: ['state.code', 'state.title'],
			valueFormatter: state => {
				return _.has(state, 'label') ? state.label : `${state.code} - ${state.title}`;
			}
		};
	}
}
