import { Directive } from '@angular/core';
import { FormScopeProviderDirective } from '@app/shared/ga-components/components/group/form-scope-provider.directive.abstract';
import { FormScopeService } from '@app/shared/ga-components/services/form-scope.service';

@Directive({
	selector: '[gaGroup]',
	exportAs: 'gaGroup',
	providers: [FormScopeService]
})
export class GaGroupDirective extends FormScopeProviderDirective {

	public toggleEditMode() {
		this.editMode = !this.editMode;
	}

}
