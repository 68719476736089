<ng-select [items]="codelistItems$ | async"
		   [searchable]="true"
		   [searchFn]="searchFn"
		   [virtualScroll]="true"
		   [formControl]="inputControl"
		   [inputAttrs]="{'data-cy': dataCy + '-input'}"
		   [attr.data-cy]="dataCy + '-select'"
		   [multiple]="multiple"
		   [appendTo]="appendTo"
		   [closeOnSelect]="closeOnSelect"
		   [clearSearchOnAdd]="clearSearchOnAdd"
		   [dropdownPosition]="dropdownPosition"
		   [compareWith]="compareWith"
		   [placeholder]="placeholder"
		   [class.invalid]="classInvalid"
		   bindValue="id"
		   class="base-input">
	<ng-template ng-label-tmp let-item="item" let-clear="clear">
		<span *ngIf="multiple" class="ng-value-icon left" (click)="clear(item);" style="margin-right: 5px;" aria-hidden="true">×</span>
		<span [ngStyle]="{paddingRight: multiple ? '5px': '0'}">{{ valueFormatter(item) }}</span>
	</ng-template>
	<ng-template ng-option-tmp let-item="item">{{ valueFormatter(item) }}</ng-template>
</ng-select>
