import { Directive, Input } from '@angular/core';
import { DatePickerRangesService } from '@app/shared/daterange-picker/date-picker-ranges/date-picker-ranges.service';
import { DateRangeGroupedProgressChart } from '@app/shared/model/types/date-range-grouped-progress-chart';
import { DateRangePeriod } from '@app/shared/model/types/date-range-period';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
@Directive()
export abstract class DateRangeGroupedProgressChartComponent implements DateRangeGroupedProgressChart {
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('mode') chartMode = 'daily';

	areDataReady = false;
	ranges$: BehaviorSubject<DateRangePeriod> = new BehaviorSubject<DateRangePeriod>(null);
	rangesOverride = {};

	chartDataSets: any[] = [{ data: {}, label: '' }];
	chartLabels: string[] = [];
	chartOptions = {};

	protected constructor(public dateRangesService: DatePickerRangesService) {}

	public getRanges(chartMode): DateRangePeriod {
		if (chartMode === 'weekly') {
			return {
				labels: DatePickerRangesService.WEEKLY_GRAPH,
				values: this.dateRangesService.getRangeDates(DatePickerRangesService.WEEKLY_GRAPH)
			};
		} else if (chartMode === 'daily') {
			return {
				labels: DatePickerRangesService.DAILY_GRAPH,
				values: this.dateRangesService.getRangeDates(DatePickerRangesService.DAILY_GRAPH, this.rangesOverride)
			};
		} else if (chartMode === 'monthly') {
			return {
				labels: DatePickerRangesService.MONTHLY_GRAPH,
				values: this.dateRangesService.getRangeDates(DatePickerRangesService.MONTHLY_GRAPH)
			};
		} else {
			return { labels: [], values: [{}] };
		}
	}

	public prepareData(data: any[], dateStart: moment.Moment, dateEnd: moment.Moment) {
		if (this.chartMode === 'daily') {
			this.prepareDailyData(data, this.buildDailyRange(dateStart, dateEnd));
		} else if (this.chartMode === 'weekly') {
			this.prepareWeeklyData(data, this.buildWeeklyRange(dateStart, dateEnd));
		} else if (this.chartMode === 'monthly') {
			this.prepareMonthlyData(data, this.buildMonthlyRange(dateStart, dateEnd));
		}
	}

	public toggleChartMode(mode) {
		this.chartMode = mode;
		this.setupDefaultDatePickerRanges(mode);
	}

	private setupDefaultDatePickerRanges(chartMode) {
		this.ranges$.next(this.getRanges(chartMode));
	}

	public buildDailyRange(dateStart: moment.Moment, dateEnd: moment.Moment) {
		const daysRange = [];
		const dateCursor = dateStart.clone();

		while (dateCursor <= dateEnd) {
			daysRange.push({
				day: dateCursor.format('YYYY-MM-DD'),
				data: {}
			});
			dateCursor.add(1, 'day');
		}

		return daysRange;
	}

	public buildWeeklyRange(dateStart: moment.Moment, dateEnd: moment.Moment) {
		const weeksRange = [];
		const dateCursor = dateStart.clone();

		while (dateCursor <= dateEnd) {
			const weekId = dateCursor.format('gggg-ww');
			const contains = weeksRange.find(item => item.weekId === weekId)

			if (!contains) {
				weeksRange.push({
					weekId: weekId,
					start: dateCursor.clone().startOf('week').format('YYYY-MM-DD'),
					end: dateCursor.clone().endOf('week').format('YYYY-MM-DD'),
					data: {},
					series: [],
				});
			}
			dateCursor.add(1, 'week');
		}
		return weeksRange;
	}

	public buildMonthlyRange(dateStart: moment.Moment, dateEnd: moment.Moment) {
		const monthsRange = [];
		const dateCursor = dateStart.clone();

		while (dateCursor <= dateEnd) {
			const monthId = dateCursor.format('YYYY-MM');
			const contains = monthsRange.find(item => item.monthId === monthId);

			if (!contains) {
				monthsRange.push({
					monthId: monthId,
					start: dateCursor.clone().startOf('month'),
					end: dateCursor.clone().endOf('month'),

					data: {},
					series: []
				});
			}

			dateCursor.add(1, 'month');
		}

		return monthsRange;
	}

	public abstract prepareDailyData(data, daysRange);

	public abstract prepareWeeklyData(data, weeksRange);

	public abstract prepareMonthlyData(data, monthsRange);
}
