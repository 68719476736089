import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleService } from '@app/core/tab-title/title.service';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Directive({
	selector: 'app-tab-title'
})
export class TabTitleDirective implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();

	constructor(private titleService: TitleService,
				private title: Title) {}

	public ngOnInit(): void {
		this.titleService.title.pipe(
			distinctUntilChanged(),
			map((title: string) => title ? title : ''),
			map((title: string) => title.trim().length > 0 ? `${title.trim()} | ` : ''),
			takeUntil(this.destroy$)
		).subscribe(title => {
			this.title.setTitle(`${title}${environment.titleSuffix}`);
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
