import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogEntity } from '@app/shared/model/types/log-entity.type';

@Injectable()
export class DataHistoryLogRepositoryService {

	constructor(private http: HttpClient) {
	}

	public fetchEntitiesChanges(entity: LogEntity, showRelatedEntities: boolean) {
		return this.http.get('/api/data-history-log/get-changes', {
			params: {
				'entity': JSON.stringify(entity),
				'showRelatedEntities': JSON.stringify(showRelatedEntities)
			}
		});
	}
}
