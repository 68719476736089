import { Todo } from '@app/shared/model/types/todo';
import { TodoCardData } from '@app/todo/model/todo-card-data';
import { createAction, props } from '@ngrx/store';

export const loadTodos = createAction('[Todos] Load Todos');
export const setTodos = createAction('[Todos] Store Todos', props<{ todos: Todo[] }>());
export const fetchDetail = createAction('[Todos] Fetch Todo Detail', props<{ id: string }>());
export const replaceTodo = createAction('[Todos] Replace Todo', props<{ payload: any }>());
export const removeTodo = createAction('[Todos] Remove Todo', props<{ id: string }>());
export const updateTodo = createAction('[Todos] Update Todo', props<{ payload: Partial<TodoCardData> }>());
