import { Component, OnInit } from '@angular/core';
import { FundingFilterType } from '@app/shared/model/types/funding-filter-type';
import { StaticPoolReportOptions } from '@app/shared/model/types/static-pool-report-options';
import { DownloadLinkConfig } from '@app/widgets/download-link/download-link-config.model';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
	selector: 'static-pool-options-modal',
	templateUrl: './static-pool-options-modal.component.html'
})
export class StaticPoolOptionsModalComponent implements OnInit {
	tabs;
	downloadLink$: Subject<DownloadLinkConfig> = new Subject<DownloadLinkConfig>();
	// filters
	fundingTypeFilterOptions: FundingFilterType[] = [
		{ id: StaticPoolReportOptions.All, title: 'All' },
		{ id: StaticPoolReportOptions.PreSettlement, title: 'Pre-Settlement Specific' },
		{ id: StaticPoolReportOptions.MedicalFactoring, title: 'Medical Factoring Specific' }
	];
	fundingTypeSelected = this.fundingTypeFilterOptions.find(item => item.id === StaticPoolReportOptions.All)

	readonly faIcons = {
		checkSquare: faCheckSquare,
		square: faSquare
	};

	readonly columns = [
		{ id: StaticPoolReportOptions.AttorneysAndLawFirms, title: 'Attorneys & Law Firms', include: false },
		{ id: StaticPoolReportOptions.Facility, title: 'Facility', include: true },
		{ id: StaticPoolReportOptions.ClaimantName, title: 'Claimant Name', include: true },
		{ id: StaticPoolReportOptions.EffectiveRate, title: 'Effective Rate', include: true },
	];

	constructor(private bsModalRef: BsModalRef) {
	}

	public ngOnInit(): void {
		this.updateTabs();
	}

	public download(): void {
		this.downloadLink$.next({
			url: '/api/reports/static-pool-report',
			customHttpParams: {
				fundingType: JSON.stringify(this.fundingTypeSelected),
				tabs: JSON.stringify(this.tabs),
				columns: JSON.stringify(this.columns),
			}
		});
		this.bsModalRef.hide();
	}

	public updateTabs(): void {
		this.tabs = [
			{
				id: StaticPoolReportOptions.ProcedureAnalysis,
				title: 'Procedure Analysis',
				include: !this.isPreSettlement(),
				disabled: this.isPreSettlement()
			},
			{
				id: StaticPoolReportOptions.DoctorAnalysis,
				title: 'Doctor Analysis',
				include: !this.isPreSettlement(),
				disabled: this.isPreSettlement()
			},
			{
				id: StaticPoolReportOptions.FacilityAnalysis,
				title: 'Facility Analysis',
				include: !this.isPreSettlement(),
				disabled: this.isPreSettlement()
			},
			{ id: StaticPoolReportOptions.Curves, title: 'Curves', include: true, disabled: false },
			{ id: StaticPoolReportOptions.PeriodPayoffAnalysis, title: 'Period Payoff Analysis', include: true, disabled: false },
			{ id: StaticPoolReportOptions.QuaterlyPerformance, title: 'Quarterly Performance', include: true, disabled: false },
			{ id: StaticPoolReportOptions.MonthlyPerformance, title: 'Monthly Performance', include: true, disabled: false },
			{ id: StaticPoolReportOptions.VintageTables, title: 'Vintage Tables', include: true, disabled: false },
			{ id: StaticPoolReportOptions.OutstandingByCaseType, title: 'Outstanding By Case Type', include: true, disabled: false },
			{ id: StaticPoolReportOptions.HarvestedByCaseType, title: 'Harvested By Case Type', include: true, disabled: false },
			{ id: StaticPoolReportOptions.HarvestedByDealStatus, title: 'Harvested By Deal Status', include: true, disabled: false },
			{
				id: StaticPoolReportOptions.ReasonForShortPay,
				title: 'Reason for Short Pay',
				include: !this.isMedical(),
				disabled: this.isMedical()
			},
			{ id: StaticPoolReportOptions.Dictionary, title: 'Dictionary', include: true, disabled: false },
		];
	}

	private isMedical(): boolean {
		return this.fundingTypeSelected.id == StaticPoolReportOptions.MedicalFactoring;
	}

	private isPreSettlement(): boolean {
		return this.fundingTypeSelected.id == StaticPoolReportOptions.PreSettlement;
	}

	public selectAll(): void {
		this.tabs.forEach((tab) => {
			if (!tab.disabled) {
				tab.include = true;
			}
		});
	}

	public selectNone(): void {
		this.tabs.forEach((tab) => {
			if (!tab.disabled) {
				tab.include = false;
			}
		});
	}
}
