import { Injectable } from '@angular/core';
import { AgGroupRendererComponent } from '@app/shared/ag-grid-utils/ag-group-renderer/ag-group-renderer.component';
import { dateTimeColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/date-column-definition';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { GaUtils } from '@app/shared/ga-utils';
import { TodoTask } from '@app/shared/model/types/todo';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Injectable()
export class TodosListService {

	readonly fieldList = [
		'summary',
		'createdBy -> firstName, lastName',
		'assignees -> firstName, lastName',
		'status',
		'createdAt',
		'dueDate',
		'timezone.title',
		'startedAt',
		'finishedAt'
	];

	constructor(
		private utils: GaUtils,
		private apiLink: GaApiLink
	) {}

	public getTodosColDef(): ColDef[] {
		return [
			{
				field: 'summary',
				headerName: 'Summary',
				filter: 'agTextColumnFilter',
				headerClass: 'gold-header',
			},
			{
				headerName: 'Author',
				field: 'author',
				filter: 'agTextColumnFilter',
				headerClass: 'gold-header',
				valueGetter: ({ data }) => this.utils.formatUserAccountName(data?.createdBy, '')
			},
			{
				headerName: 'Assignees',
				field: 'assignees',
				valueGetter: ({ data }) => {
					if (data?.assignees) {
						const formattedValue = data?.assignees.reduce((acc, assignee) => {
							return acc + `${this.utils.formatUserAccountName(assignee, '')} | `;
						}, '');
						return formattedValue.slice(0, -3);
					}
					return '';
					},
				headerClass: 'gold-header',
				cellRenderer: AgGroupRendererComponent,
				cellRendererParams: {
					delimiter: '|',
				},
				enableRowGroup: false,
				enableValue: false,
				filter: 'agTextColumnFilter'
			},
			{
				field: 'status',
				headerName: 'Status',
				filter: 'agSetColumnFilter',
				headerClass: 'gold-header',
				filterParams: {
					values: ['todo', 'in-progress', 'done', 'archived']
				}
			},
			{
				field: 'createdAt',
				headerName: 'Created At',
				sort: 'desc',
				headerClass: 'gold-header',
				...dateTimeColumnDefinition('createdAt'),
				cellClass: 'idDateTimeType'
			},
			{
				field: 'dueDate',
				headerName: 'Due date',
				headerClass: 'gold-header',
				...dateTimeColumnDefinition('dueDate'),
				cellClass: 'idDateTimeType'
			},
			{
				headerName: 'Timezone',
				field: 'timezone.title',
				filter: 'agTextColumnFilter',
				headerClass: 'gold-header',
			},
			{
				field: 'startedAt',
				headerName: 'Started at',
				headerClass: 'gold-header',
				...dateTimeColumnDefinition('startedAt'),
				cellClass: 'idDateTimeType'
			},
			{
				field: 'finishedAt',
				headerName: 'Finished at',
				headerClass: 'gold-header',
				...dateTimeColumnDefinition('finishedAt'),
				cellClass: 'idDateTimeType'
			},
		];
	}

	public getTodos(todoIds?: number[]): Observable<TodoTask[]> {
		return this.apiLink.retrieve(
			'todo',
			this.fieldList,
			{ id: todoIds }
		);
	}
}
