<loading-spinner [show]="showLoading" [style]="{zIndex: 2}"></loading-spinner>
<div class="inbox-conversation-list__wrapper">
	<div *ngIf="showMarkReadButton" class="inbox-conversation-list__header">
		<button type="button" class="base-button base-button" (click)="markAllConversationsAsRead()">Mark All As Read
		</button>
	</div>
	<div *ngIf="showMarkReadButton" class="inbox-conversation-list scroll" infiniteScroll [infiniteScrollDistance]="2"
		 [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="scrollDown()">
		<div class="search__wrapper">
			<input [formControl]="searchControl" placeholder="Search..." class="base-input"/>
		</div>
		<inbox-conversation-list-item
			*ngFor="let conversation of conversations"
			[conversation]="conversation"
			[showSender]="showSender"
			(onSelectConversation)="selectConversation($event)">
		</inbox-conversation-list-item>
	</div>
	<div *ngIf="!showMarkReadButton" class="inbox-conversation-list">
		<inbox-conversation-list-item
			*ngFor="let conversation of conversations"
			[conversation]="conversation"
			[showSender]="showSender"
			(onSelectConversation)="selectConversation($event)">
		</inbox-conversation-list-item>
	</div>
</div>
