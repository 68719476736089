import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterByCategory',
	pure: false
})
export class FilterByCategoryPipe implements PipeTransform {

	public transform(files: any, category: any): any {
		return files ? files.filter((file) => file.category === category.identifier) : [];
	}

}
