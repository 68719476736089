export enum CsrColumnIds {
	CreateContract = 'create_contract',
	DocsOut = 'docs_out',
	WaitingOnClient = 'waiting_on_client',
	WaitingOnAttorney = 'waiting_on_attorney',
	WebIntake = 'web_intake'
}

export const CsrColumnNames = {
	[CsrColumnIds.CreateContract]: 'Create Contract',
	[CsrColumnIds.DocsOut]: 'Docs Out',
	[CsrColumnIds.WaitingOnClient]: 'Waiting On Client',
	[CsrColumnIds.WaitingOnAttorney]: 'Waiting on Attorney',
	[CsrColumnIds.WebIntake]: 'Web Intake'
}
