import { Injectable } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { GaSelectEditorParams } from '@app/shared/ga-components/components/editors/ga-select-editor.params';
import { GaSelectEditorComponent } from '@app/shared/ga-components/components/editors/ga-select-editor/ga-select-editor.component';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { GaDecoratorInterface } from '@app/shared/ga-components/utils/ga-decorator-interface';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';

@Injectable()
export class CodelistDecorator implements GaDecoratorInterface {

	constructor(private codelistRepository: CodelistRepositoryService) {
	}

	public create(componentMetadata: ComponentMetadata): MetadataDecorator {
		const fieldMetadata = componentMetadata.fieldMetadata;

		return {
			valueFormatter: (value) => this.codelistValueFormatter(value),
			editor: GaSelectEditorComponent,
			editorParams: <GaSelectEditorParams>{
				onInput: () => {
					return this.codelistRepository.get(fieldMetadata.association.targetEntity);
				},
				multiple: fieldMetadata.association.isToMany(),
			},
			dependencies: [] //empty dependencies means the whole entity
		};
	}

	private codelistValueFormatter(value): string {
		if (value) {
			return value instanceof Object && value?.title ? value.title : value;
		}

		return 'N/A';
	}
}
