type Dataset = {
	data: number[];
	label: string;
}

export class SalesChartConfig {
	private readonly dataset: Dataset[];
	private readonly labels: string[];

	constructor(notes) {
		this.labels = [];
		this.dataset = [
			{ data: [], label: 'Doctor' },
			{ data: [], label: 'Law Firm' },
			{ data: [], label: 'Medical Facility' }
		];
		notes.forEach(note => {
			const keys = Object.keys(note);
			let salesPersonIndex = this.labels.indexOf(`${note.createdBy.firstName} ${note.createdBy.lastName}`);
			if (salesPersonIndex == -1) {
				salesPersonIndex = this.labels.push(`${note.createdBy.firstName} ${note.createdBy.lastName}`) - 1;
				this.dataset.forEach(dataset => {
					dataset.data.push(0);
				});
			}
			if (keys.includes('lawFirm')) {
				this.dataset[1].data[salesPersonIndex] += 1;
			} else if (keys.includes('doctor')) {
				this.dataset[0].data[salesPersonIndex] += 1;
			} else if (keys.includes('medicalFacility')) {
				this.dataset[2].data[salesPersonIndex] += 1;
			}
		});
	}

	public getDataset(): Dataset[] {
		return this.dataset;
	}

	public getLabels(): string[] {
		return this.labels;
	}
}
