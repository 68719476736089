import { Component, Input, OnInit } from '@angular/core';
import { MedicalBreakdown } from '@app/dashboard/current-month/current-month.model';
import { DashboardRoundStore } from '@app/dashboard/service/dashboard-round-store.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'medical-purchases-table',
	templateUrl: './medical-purchases-table.component.html'
})
export class MedicalPurchasesTableComponent implements OnInit {
	@Input() medicalBreakdown: MedicalBreakdown[];

	round$: Observable<boolean>;

	constructor(private dashboardRoundStore: DashboardRoundStore) {
	}

	public ngOnInit(): void {
		this.round$ = this.dashboardRoundStore.selectRound$;
    }
}
