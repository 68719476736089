<button
        class="base-button"
        [ngClass]="classes"
        [disabled]="disabled || isLoading"
        [attr.data-cy]="dataCy"
        (click)="handleClick()"
>
    <span class="spinner-wrapper" *ngIf="isLoading">
        <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
    </span>
    <span>
       <ng-content></ng-content>
    </span>
</button>
