import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { DashboardRoundStore } from '@app/dashboard/service/dashboard-round-store.service';
import { dateBoundaryOperator } from '@app/dashboard/shared/date-boundary-operator';
import { TotalEverPurchasedResponse } from '@app/dashboard/total-ever-purchased/total-ever-purchased.model';
import { GhostElementService } from '@app/widgets/ghost-element/ghost-element.service';
import { combineLatest, merge, Observable, of, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
	selector: 'total-ever-purchased',
	templateUrl: './total-ever-purchased.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: [
		'../shared/dashboard-date-filter-styling.scss',
		'total-ever-purchased.component.scss'
	],
	providers: [GhostElementService]
})
export class TotalEverPurchasedComponent implements OnInit {
	@Input() isCollapseManaged = false;

	purchasedInfo$: Observable<TotalEverPurchasedResponse>;
	round$: Observable<boolean>;
	dateFilterControl = new FormControl<Date>(null);

	constructor(
		private http: HttpClient,
		private ghostVisibilityService: GhostElementService,
		private dashboardFilterStore: DashboardFilterStore,
		private dashboardRoundStore: DashboardRoundStore) {
	}

	public ngOnInit(): void {
		this.round$ = this.dashboardRoundStore.selectRound$;
		const purchasedInfoEmitter = combineLatest([
			this.dashboardFilterStore.fundingParams$,
			this.dateFilterControl.valueChanges.pipe(
				filter(() => this.dateFilterControl.valid),
				dateBoundaryOperator
			)
		]).pipe(
			tap(() => {
				this.ghostVisibilityService.showData(false);
			}),
			switchMap(([params, dateFilter]) => {
				if (dateFilter != null) {
					params = params.set('dateBoundary', dateFilter);
				}
				return this.http.get<TotalEverPurchasedResponse>('/api/dashboard/total-ever-purchased', { params });
			}),
			tap(() => {
				this.ghostVisibilityService.showData(true);
			})
		);
		this.purchasedInfo$ = merge(of(null), purchasedInfoEmitter);
	}
}
