import { Injectable } from '@angular/core';
import { GaMetadataAdapterService } from '@app/shared/ga-components/services/ga-metadata-adapter.service';
import { AssociationMetadata } from '@app/shared/ga-components/utils/association-metadata';
import { FieldMetadata } from '@app/shared/ga-components/utils/field-metadata';
import * as _ from 'lodash';

@Injectable()
export class SanitizeService {

	constructor(private metadataProvider: GaMetadataAdapterService) {}

	public sanitize(data, className) {
		data = { ...data };
		for (const property in data) {
			if (property in data) {
				const fieldMetadata = this.metadataProvider.getMetadata(className, property);
				if (!fieldMetadata) {
					continue;
				}
				switch (fieldMetadata.type) {
					case 'association':
						data[property] = this.sanitizeAssociationField(data[property], fieldMetadata);
						break;
					case 'date' :
						//not sure what to do here yet
						break;

				}
			}
		}
		return data;
	}

	private sanitizeAssociationField(data: any, metadata: FieldMetadata) {
		switch (metadata.association.type) {
			case AssociationMetadata.ONE_TO_ONE:
			case AssociationMetadata.MANY_TO_ONE:
				return data.id;
			case AssociationMetadata.ONE_TO_MANY:
			case AssociationMetadata.MANY_TO_MANY:
				return _.map(data, 'id');
		}
		return data;
	}

}
