import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessage } from '@app/core/flash-messages/flash-message/flash-message';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'flash-messages-container',
	templateUrl: './flash-messages-container.component.html',
	styleUrls: ['./flash-messages-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlashMessagesContainerComponent implements OnInit, OnDestroy {
	messages: Map<string, { message: FlashMessage, count: number }> = new Map<string, { message: FlashMessage, count: number }>();
	private destroy$: Subject<void> = new Subject<void>();

	constructor(public flashMessageService: FlashMessageService,
				private cd: ChangeDetectorRef) {	//TODO: remove ChangeDetectorRef
	}

	public ngOnInit(): void {
		this.flashMessageService.messages.pipe(takeUntil(this.destroy$)).subscribe((flashMsg: FlashMessage) => {
			if (this.messages.has(flashMsg.id)) {
				this.messages.get(flashMsg.id).count++;
			} else {
				this.messages.set(flashMsg.id, { message: flashMsg, count: 1 });
			}

			this.cd.detectChanges();
		});
	}

	public onCloseMessage(id: string): void {
		this.messages.delete(id);
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
