import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { Component, Input, OnChanges, OnInit, Optional, SimpleChanges, SkipSelf } from '@angular/core';
import { FundingsTablePreset } from '@app/fundings-table/presets/fundings-table-preset.service';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { DetailFundingsTableService } from '@app/shared/detail-fundings-table/services/detail-fundings-table.service';
import { CountParams } from '@app/shared/model/types/count-params';
import { Funding } from '@app/shared/model/types/funding';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { LetDirective } from '@ngrx/component';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, ColGroupDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
	selector: 'detail-fundings-table',
	templateUrl: './detail-fundings-table.component.html',
	styleUrls: ['detail-fundings-table.component.scss'],
	standalone: true,
	imports: [
		AgGridModule,
		NgForOf,
		NgClass,
		AsyncPipe,
		PipesModule,
		LetDirective,
	],
	providers: [
		DetailFundingsTableService
	]
})
export class DetailFundingsTableComponent implements OnInit, OnChanges {
	@Input() set countsParams(params: CountParams) {
		this.countsParamsEmitter.next(params);
	}
	@Input() filter: Record<string, unknown>;
	@Input() config: Record<string, FundingsTablePreset>;
	@Input() allowExcelExport = true;

	currentTab = 'pending';
	counts$: Observable<Record<string, number>>;
	colDefs: (ColGroupDef | ColDef)[] = [];
	gridOptions;

	fundings$: Observable<Funding[]>;

	private countsParamsEmitter = new ReplaySubject<CountParams>(1);
	private gridApi: GridApi;

	constructor(
		private localInstance: DetailFundingsTableService,
		@SkipSelf() @Optional() private overrideWithInstance: DetailFundingsTableService,
		private agUtils: AgGridUtilsService,
	) {
	}

	public ngOnInit(): void {
		this.counts$ = this.tableService.getCounts$(this.countsParamsEmitter.asObservable());
		this.gridOptions = this.agUtils.getGridOptions({
			suppressExcelExport: !this.allowExcelExport,
			defaultExcelExportParams: {
				fileName: 'Detail Fundings Export',
				processCellCallback: this.agUtils.generalProcessCellCallback
			},
			defaultColDef: {
				minWidth: 130
			},
		});

		this.refreshGrid();
	}

	public changeTab(tab: string): void {
		this.currentTab = tab;
		this.refreshGrid();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if ('config' in changes) {
			this.tableService.setConfig(changes.config.currentValue);
		}
		this.refreshGrid();
	}

	public onGridReady(params: GridReadyEvent): void {
		params.api.sizeColumnsToFit();
		this.gridApi = params.api;
	}

	private refreshGrid() {
		this.fundings$ = this.tableService.getFundings$(this.currentTab, this.filter);
		this.colDefs = this.tableService.getColDef(this.currentTab);
	}

	private get tableService(): DetailFundingsTableService {
		if (this.overrideWithInstance) {
			return this.overrideWithInstance;
		}
		return this.localInstance;
	}
}
