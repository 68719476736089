import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { DateRangeGroupedProgressChartComponent } from '@app/reports/shared/date-range-grouped-progress-chart.component';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { AgGroupRendererComponent } from '@app/shared/ag-grid-utils/ag-group-renderer/ag-group-renderer.component';
import { currencyColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/currency-column-definition';
import { dateColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/date-column-definition';
import { DatePickerRangesService } from '@app/shared/daterange-picker/date-picker-ranges/date-picker-ranges.service';
import { GaUtils } from '@app/shared/ga-utils';
import { ModalsService } from '@app/shared/modals/modals.service';
import { ChartsHelperService } from '@app/shared/model/charts-helper.service';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { FundingHelperService } from '@app/shared/model/funding-helper.service';
import { ChartOptions } from 'chart.js';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'grouped-progress-bar-chart',
	templateUrl: './grouped-progress-bar-chart.component.html',
	styles: ['.canvas-wrapper { height: 70vh }']
})
export class GroupedProgressBarChartComponent extends DateRangeGroupedProgressChartComponent implements OnInit, OnChanges, OnDestroy {
	@Input() rawData;
	@Input() property: string;

	@ViewChild(BaseChartDirective) chartDirective: BaseChartDirective = null;

	bsRangeValue: Date[] = [];
	usersMapping;
	chartSeries;
	chartValues;
	chartColors = [];
	chartOptions: ChartOptions<'bar'> = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: true,
				position: 'bottom'
			},
			tooltip: {
				mode: 'index',
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				min: 0
			},
			x: {
				ticks: {
					autoSkip: false,
				},
				min: 0
			}
		}
	};

	private destroy$ = new Subject<void>();

	constructor(
		public dateRangesService: DatePickerRangesService,
		private agUtils: AgGridUtilsService,
		private utils: GaUtils,
		private fundingHelper: FundingHelperService,
		private codelistService: CodelistRepositoryService,
		private modalsService: ModalsService,
		private chartHelper: ChartsHelperService) {
		super(dateRangesService);
	}

	public ngOnInit(): void {
		this.ranges$.next(this.getRanges(this.chartMode));
		this.drawChart();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.rawData && !changes.rawData.isFirstChange()) {
			this.drawChart();
		}
	}

	public onDateRangeSelected(ranges: Date[]): void {
		this.bsRangeValue = ranges;
		this.drawChart();
	}

	private drawChart(): void {
		const startDate = this.bsRangeValue[0];
		const endDate = this.bsRangeValue[1];
		const dateStart = startDate ? moment(startDate, 'MM/DD/YYYY') : moment().subtract(30, 'days');
		const dateEnd = endDate ? moment(endDate, 'MM/DD/YYYY') : moment();

		this.prepareData(this.rawData, dateStart, dateEnd);
		this.initChart();
	}

	private initChart(): void {
		this.chartDataSets = [];
		_.each(this.chartSeries, (element) => {
			const dataSet = _.map(this.chartValues, _.property(['data', element]));
			this.chartDataSets.push({ data: _.map(dataSet, _.toInteger), label: element });
		});
		this.chartDirective?.update();
	}

	public prepareWeeklyData(data, weeksRange): void {
		const byWeekPeriods = _.groupBy(data, 'week');
		const dataset = {};

		_.each(byWeekPeriods, (notes, weekPeriod) => {
			dataset[weekPeriod] = _.groupBy(notes, 'username');

			_.each(dataset[weekPeriod], (items, username) => {
				dataset[weekPeriod][username] = _.sumBy(items, (element) => {
					return _.toInteger(element[this.property]);
				});
			});
		});

		_.each(weeksRange, (date) => {
			const weeklyData = _.get(dataset, date.weekId, null);
			if (weeklyData) {
				date.data = weeklyData;
				date.series = Object.keys(weeklyData);
			}
		});

		this.chartValues = weeksRange;
		this.chartSeries = _.uniq(_.map(data, 'username'));
		this.chartLabels = this.chartValues.map((item) => `${item.start} - ${item.end}`);
		if (!this.chartColors) {
			this.chartColors = this.chartHelper.setupChartColors(this.chartLabels);
		}
		const usernames = _.keyBy(data, 'username');
		this.usersMapping = _.zipObject(Object.keys(usernames), _.map(usernames, 'user_id'));
	}

	public prepareMonthlyData(data, monthsRange): void {
		const byMonthPeriods = _.groupBy(data, 'month');

		const dataset = {};

		_.each(byMonthPeriods, (notes, monthPeriod) => {
			dataset[monthPeriod] = _.groupBy(notes, 'username');

			_.each(dataset[monthPeriod], (items, username) => {
				dataset[monthPeriod][username] = _.sumBy(items, (element) => {
					return _.toInteger(element[this.property]);
				});
			});
		});

		_.each(monthsRange, (date) => {
			const monthlyData = _.get(dataset, date.monthId, null);
			if (monthlyData) {
				date.data = monthlyData;
				date.series = Object.keys(monthlyData);
			}
		});

		this.chartValues = monthsRange;
		this.chartSeries = _.uniq(_.map(data, 'username'));

		this.chartLabels = _.map(this.chartValues, (item: any) => {
			return item.start.format('MMMM YYYY');
		});

		if (!this.chartColors) {
			this.chartColors = this.chartHelper.setupChartColors(this.chartLabels);
		}
		const usernames = _.keyBy(data, 'username');
		this.usersMapping = _.zipObject(Object.keys(usernames), _.map(usernames, 'user_id'));
	}

	public prepareDailyData(data, daysRange): void {
		this.usersMapping = {};
		_.each(data, (item) => {
			const target = daysRange.find(el => el.day === item.day)
			if (target) {
				target.data[item.username] = item[this.property];
			}
			this.usersMapping[item.username] = item.user_id;
		});

		this.chartValues = daysRange;
		this.chartSeries = _.uniq(_.map(data, 'username'));
		this.chartLabels = _.map(this.chartValues, 'day');
		if (!this.chartColors) {
			this.chartColors = this.chartHelper.setupChartColors(this.chartLabels);
		}
	}

	public onChartClick(event: any): void {
		if (!event.active[0]) {
			return;
		}

		const dataset = this.chartValues[event.active[0].index];
		const users = Object.keys(dataset.data).map(username => this.usersMapping[username]);
		let date;
		if (this.chartMode === 'weekly') {
			date = {
				'from': dataset.start,
				'to': dataset.end,
			};
		}
		if (this.chartMode === 'monthly') {
			date = {
				'from': dataset.start.format('YYYY-MM-DD'),
				'to': dataset.end.format('YYYY-MM-DD'),
			};
		} else if (this.chartMode === 'daily') {
			date = dataset.day;
		}

		this.modalsService.openVintageDetail({
			filter: { createdOn: date, applicant: { underwriters: { id: users } } },
			fieldList: [
				'internalId',
				'fundedOn',
				'applicant -> firstName, lastName',
				'approvalStatus',
				'applicant.attorney -> firstName, lastName, lawFirm.name',
				'applicant.totalAmountFunded',
				'applicant.totalAmountOwed',
				'applicant.underwritingTheory',
				'applicant.underwriters'
			],
			columnDefinitions: [
				{
					field: 'internalId',
					cellRenderer: ({ data, value }) =>
						data?.applicant ? this.agUtils.fundingLinkRenderer(value, data?.id, data?.applicant?.id) : value,
					filter: 'agTextColumnFilter'
				},
				{
					headerName: 'Funded On',
					...dateColumnDefinition('fundedOn'),
				},
				{
					field: 'applicant',
					valueGetter: ({ data }) => `${data?.applicant?.firstName ?? ''} ${data?.applicant?.lastName ?? ''}`,
					sortable: true,
					filter: 'agTextColumnFilter',
					cellRenderer: ({ data, value }) =>
						data ? this.agUtils.applicantLinkRenderer(data?.applicant?.id, value) : value
				},
				{
					field: 'approvalStatus',
					filter: 'agSetColumnFilter',
					minWidth: 200,
					valueGetter: ({ data }) => data ? this.fundingHelper.getStringRepresentationOfFundingState(data) : '',
				},
				{
					headerName: 'Attorney',
					width: 200,
					field: 'applicant.attorney',
					valueFormatter: ({ value }) => value?.firstName ? `${value?.firstName} ${value?.lastName}` : value,
					cellRenderer: ({ value }) =>
						this.agUtils.attorneyLinkRenderer(value?.lawFirm?.id, value?.id, `${value?.firstName} ${value?.lastName}`),
					filter: 'agSetColumnFilter',
					keyCreator: ({ value }) => value ? `${value?.firstName} ${value?.lastName}` : '',
					filterParams: {
						values: (params) => {
							this.codelistService.getByUrl(UrlCodelistLink.Attorneys)
								.pipe(takeUntil(this.destroy$))
								.subscribe(attorneys => {
									params.success(this.agUtils.getArrayOfStringsByFiledName(attorneys, ['firstName', 'lastName']));
								});
						},
						buttons: ['clear', 'reset']
					},
					comparator: (a, b) => this.agUtils.stringComparatorByName(a, b),
				},
				{
					field: 'applicant.attorney.lawFirm.name',
					headerName: 'Law Firm',
					sortable: true,
					cellRenderer: ({ data, value }) =>
						data ? this.agUtils.lawFirmLinkRenderer(data?.applicant?.attorney?.lawFirm?.id, value) : value,
					filter: 'agSetColumnFilter',
					filterParams: {
						values: (params) => {
							this.codelistService.getByUrl(UrlCodelistLink.LawFirms)
								.pipe(takeUntil(this.destroy$))
								.subscribe(value => params.success(this.agUtils.getArrayOfStringsByFiledName(value, 'name')));
						},
						buttons: ['clear', 'reset'],
					},
					minWidth: 200
				},
				{
					headerName: 'Total Amount Funded',
					...currencyColumnDefinition('applicant.totalAmountFunded')
				},
				{
					headerName: 'Total Amount Owed',
					...currencyColumnDefinition('applicant.totalAmountOwed')
				},
				{
					field: 'applicant.underwritingTheory',
					filter: 'agTextColumnFilter'
				},
				{
					headerName: 'Underwriters',
					valueGetter: ({ data }) => {
						if (data?.applicant?.underwriters) {
							return data?.applicant.underwriters.map(underwriter =>
								this.utils.formatUserAccountName(underwriter)).join(' | ');
						}
						return '';
					},
					cellRenderer: AgGroupRendererComponent,
					cellRendererParams: {
						delimiter: '|',
					},
					enableRowGroup: false,
					enableValue: false,
					filter: 'agTextColumnFilter'
				},
			]
		})
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
