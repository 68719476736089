import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class ChartsHelperService {

	public setupChartColors(chartLabels: string[]) {
		const chartColors = [];
		chartLabels.forEach(() => {
			const randomColor = `rgba(${_.random(0, 255)},${_.random(0, 255)},${_.random(0, 255)}, `;
			chartColors.push({backgroundColor: randomColor + '0.2)', borderColor: randomColor + '1)', borderWidth: 2});
		});

		return chartColors;
	}
}
