import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { ObjectStoreMeta } from 'ngx-indexed-db';

export const insuranceCompanyDbConfig: ObjectStoreMeta = {
	store: UrlCodelistLink.InsuranceCompanies,
	storeConfig: { keyPath: 'id', autoIncrement: false },
	storeSchema: [
		{ name: 'id', keypath: 'id', options: { unique: true } },
		{ name: 'title', keypath: 'title', options: { unique: false } }
	]
}
