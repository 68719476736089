import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { TitleService } from '@app/core/tab-title/title.service';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { template } from 'lodash';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Directive({
	selector: 'ga-page-title',
})
export class GaPageTitleDirective implements OnInit, OnDestroy {
	@Input() template: string;

	private destroy$ = new Subject();

	constructor(
		private entityScope: EntityScopeService,
		private titleService: TitleService
	) {
	}

	public ngOnInit(): void {
		this.entityScope.emitter.pipe(
			filter((entity) => entity.id),
			map((entity: any) => {
				try {
					const title = template(this.template)(entity);
					this.titleService.setTitle(title);
				} catch (error) {
					console.error(error);
				}
			}),
			takeUntil(this.destroy$)
		).subscribe();
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
