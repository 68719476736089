import { Pipe, PipeTransform } from '@angular/core';
import { toNumber } from '@app/shared/general-helper';
import * as _ from 'lodash';

@Pipe({ name: 'toFiniteNumber' })
export class ToFiniteNumberPipe implements PipeTransform {

	public transform(value: any): number {
		const valueAsNumber = toNumber(value ?? 0);
		return _.isFinite(valueAsNumber) ? valueAsNumber : 0;
	}

}
