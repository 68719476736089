import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { FundingNote } from '@app/shared/model/types/note';
import { SharedModule } from '@app/shared/shared.module';
import { LetDirective } from '@ngrx/component';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';

@Component({
	selector: 'ag-latest-note-renderer',
	templateUrl: 'ag-latest-note-renderer.component.html',
	styleUrls: ['ag-latest-note-renderer.component.scss'],
	imports: [
		LetDirective,
		NgIf,
		AsyncPipe,
		SharedModule
	],
	standalone: true
})
export class AgLatestNoteRendererComponent implements AgRendererComponent {

	params: ICellRendererParams;
	latestNote$: Observable<string>;
	showNote$ = new BehaviorSubject(false);
	isLoading$ = new BehaviorSubject(false)

	constructor(private apiLink: GaApiLink) {
	}

	public agInit(params: ICellRendererParams & { fundingId: string }): void {
		this.latestNote$ = this.apiLink.retrieve<FundingNote>(
			'fundingNote', ['content'],
			{ funding: { id: params.data.id } }, { createdOn: 'desc' }).pipe(
			map((notes) => notes[0]?.content ?? ''),
			tap(() => this.isLoading$.next(false))
		);
		this.params = params;
	}

	public refresh(_params: ICellRendererParams): boolean {
		return false;
	}

	public toggleNote(): void {
		this.isLoading$.next(true);
		this.showNote$.next(true);
	}
}
