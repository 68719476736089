import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerFieldsDefinitionService } from '@app/core/fields-definition/server/server-fields-definition.service';
import { RevisionsService } from '@app/core/revisions/revisions.service';
import { RevisionType } from '@app/core/revisions/types/revision-type';
import { PermissionsStorageService } from '@app/core/security/permissions/permissions-storage.service';
import { PermissionsStoreService } from '@app/core/security/permissions/permissions-store.service';
import { WebSocketMessageType } from '@app/core/web-socket/web-socket-message-type';
import { WebSocketService } from '@app/core/web-socket/web-socket.service';
import { Observable, Observer, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
/* eslint-disable no-console */
export class PermissionsService {

	constructor(
		private http: HttpClient,
		private webSocketService: WebSocketService,
		private serverFieldsDefinitionService: ServerFieldsDefinitionService,
		private permissionStorage: PermissionsStorageService,
		private revisionsService: RevisionsService,
		private permissionsStore: PermissionsStoreService // Fixme: get rid of this service
	) {

	}

	//TODO: Call this method somehow and resolve the call of fetchPermissions method
	private startListeningForPermissionsUpdates() {
		this.webSocketService
			.getConnection()
			.pipe(filter((message: any) => message.type == WebSocketMessageType.ClearPermissionsCache))
			.subscribe(() => {
					console.log('* Received command to flush permissions in a local storage');
					localStorage.setItem('permissions', JSON.stringify(''));
					this.revisionsService.clearRevision(RevisionType.PermissionsRevision);
					// this.fetchPermissions(this.authService.identity, '');
				}
			);
	}

	private fetchPermissions(identity, revision) {
		return this.fetchMetadata(identity, 'permissions', '/api/security/permissions', revision).subscribe(response => response);
	}

	private fetchMetadata(identity, type, url, serverRevision?) {
		serverRevision = serverRevision || '';
		const revisionIsOk = serverRevision
			? this.revisionsService.isRevisionUpToDate(RevisionType.PermissionsRevision, serverRevision) : true;
		if (serverRevision) {
			console.log(`* ${type} revision is ${revisionIsOk ? '' : 'not '}ok on app bootstrap`);
		}

		const cachedMeta = JSON.parse(localStorage.getItem(type));
		if (cachedMeta && revisionIsOk) {
			// if meta are already in a local storage & revision is ok
			return new Observable((observer: Observer<any>) => {
				if (identity.isAuthenticated) {
					console.log(`* Fetching ${type} from a local storage`);
					this.storeData(type, cachedMeta);
					observer.next(cachedMeta);
				} else {
					console.log(`ng2: !! not authenticated !! not fetching ${type} from a local storage`);
					this.storeData(type, {});
					observer.next({});
				}
			});
		} else {
			// if meta are not cached yet or revision is incorrect
			return new Observable((observer: Observer<any>) => {
				if (identity.isAuthenticated) {
					console.log(`ng2: fetching ${type} from a server`);
					this.http.get(url, { headers: { 'x-auth-token': identity.token }, observe: 'response' })
						.subscribe((response: HttpResponse<any>) => {
							this.storeData(type, response.body);
							localStorage.setItem(type, JSON.stringify(response.body));
							this.revisionsService.storeRevision(
								RevisionType.PermissionsRevision,
								response.headers.get(RevisionType.PermissionsRevision)
							);
							observer.next(response.body);
						}, (error: unknown) => {
							this.storeData(type, {});
							observer.error(error);
						});
				} else {
					console.log(`ng2: !! not authenticated !! not fetching ${type} from a server`);
					this.storeData(type, {});
					observer.next({});
				}
			});
		}
	}

	private storeData(type, data) {
		switch (type) {
			case 'serverFieldsDefinition':
				return this.serverFieldsDefinitionService.definitions.next(data);
			case 'permissions':
				return this.permissionsStore.setPermissions(data);
		}
	}

	public initPermissions(revision: string): Observable<any> {
		const localPermissions = JSON.parse(localStorage.getItem('permissions'));
		const revisionIsOk = this.revisionsService.isRevisionUpToDate(RevisionType.PermissionsRevision, revision);
		console.log(`Permissions revision is ${revisionIsOk ? '' : 'not '}ok on app bootstrap`);
		if (!localPermissions || localPermissions?.length == 0 || !revisionIsOk) {
			return this.http.get('/api/security/permissions', { observe: 'response' }).pipe(
				tap((response) => {
					console.log('ng1: fetching permissions from a server');
					this.permissionsStore.setPermissions(response.body);
					localStorage.setItem('permissions', JSON.stringify(response.body));
					this.revisionsService.storeRevision(RevisionType.PermissionsRevision, revision);
				})
			);
		}

		console.log('ng1: fetching permissions from a local storage');
		return of(localPermissions).pipe(
			tap((data) => {
				this.permissionsStore.setPermissions(data);
			})
		);

	}
}
