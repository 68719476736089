<div class="indicator-report-modal">
	<ag-grid-angular
	class="ag-theme-balham"
	[columnDefs]="columnDefinitions"
	[gridOptions]="gridOptions"
	[detailCellRendererParams]="detailCellRendererParams"
	[rowData]="attorneys$ | async"
	(gridReady)="onGridReady($event)"
	>
	</ag-grid-angular>
</div>
