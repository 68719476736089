import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DateTimeHelperService } from '@app/shared/model/date-time-helper.service';
import * as _ from 'lodash';

@Component({
	selector: 'todo-activity-log-item',
	template: `
		<div [innerHTML]="activityLogHtml"></div>
	`,
})
export class TodoActivityLogItemComponent implements OnInit, AfterViewInit {
	@Input() logEntry;

	activityLogHtml;

	constructor(private elRef: ElementRef,
				private renderer: Renderer2,
				private domSanitizer: DomSanitizer,
				private datesHelper: DateTimeHelperService) {
	}

	public ngOnInit(): void {
		const compiledTemplate = _.template(this.logEntry.html);
		const html = this.datesHelper.findDatesAndFormat(compiledTemplate(this.logEntry));
		this.activityLogHtml = this.domSanitizer.bypassSecurityTrustHtml(html);
	}

	public ngAfterViewInit(): void {
		this.elRef.nativeElement.querySelectorAll('span[tooltip]').forEach((span) => {
			const tooltipSpan = this.renderer.createElement('span');
			this.renderer.setProperty(tooltipSpan, 'innerHTML', span.getAttribute('tooltip'));
			this.renderer.addClass(span, '___tooltip');
			this.renderer.addClass(tooltipSpan, 'tooltipText');
			this.renderer.appendChild(span, tooltipSpan);
		});
	}
}
