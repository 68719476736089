import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CurrentMonth, CurrentMonthResponse } from '@app/dashboard/current-month/current-month.model';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { DashboardRoundStore } from '@app/dashboard/service/dashboard-round-store.service';
import { GhostElementService } from '@app/widgets/ghost-element/ghost-element.service';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, combineLatest, map, merge, Observable, of, switchMap, tap } from 'rxjs';

@Component({
	selector: 'current-month',
	templateUrl: './current-month.component.html',
	styleUrls: ['current-month.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [GhostElementService]
})
export class CurrentMonthComponent implements OnInit {
	@Input() isCollapseManaged = false;
	showEffectiveRates$ = new BehaviorSubject<boolean>(false);
	round$: Observable<boolean>;
	readonly faIcons = {
		calendar: faCalendar,
	};

	readonly ghostElementWidths = {
		sectionHeader: 8,
		sectionPercentage: 4,
		sectionSubHeading: 5,
		footerStatsHeader: 8
	};
	readonly ghostElementHeights = {
		h2: 2.5,
		h3: 2
	};

	currentMonth$: Observable<CurrentMonth>;

	constructor(
		private http: HttpClient,
		private ghostVisibilityService: GhostElementService,
		private dashboardFilterStore: DashboardFilterStore,
		private dashboardRoundStore: DashboardRoundStore) {
	}

	public ngOnInit(): void {
		this.round$ = this.dashboardRoundStore.selectRound$;
		const currentMonthGetter$ = this.dashboardFilterStore.fundingParams$
			.pipe(
				tap(() => {
					this.ghostVisibilityService.showData(false);
				}),
				switchMap(params => this.http.get<CurrentMonthResponse>('/api/dashboard/current-month', { params })),
				tap(() => {
					this.ghostVisibilityService.showData(true);
				})
			);

		const currentMonth$ = merge(of(null), currentMonthGetter$);

		this.currentMonth$ = combineLatest([
			this.showEffectiveRates$,
			currentMonth$
		]).pipe(
			map(([isEffectiveRate, currentMonth]) => {
				if (currentMonth !== null) {
					return new CurrentMonth(isEffectiveRate, currentMonth);
				} else {
					return null;
				}
			}),
		);
	}

	public toggleFundingsData(event: boolean): void {
		this.showEffectiveRates$.next(event);
	}
}
