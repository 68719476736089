import * as _ from 'lodash';

export class AssociationMetadata {

	static readonly ONE_TO_ONE = 'one-to-one';
	static readonly MANY_TO_ONE = 'many-to-one';
	static readonly ONE_TO_MANY = 'one-to-many';
	static readonly MANY_TO_MANY = 'many-to-many';

	type: string;
	targetEntity: string;
	mappedBy: string;
	toStringTemplate: string;

	constructor(params) {
		_.merge(this, params);
	}

	public isToMany() {
		return this.type == AssociationMetadata.MANY_TO_MANY || this.type == AssociationMetadata.ONE_TO_MANY;
	}

	public isManyToMany() {
		return this.type == AssociationMetadata.MANY_TO_MANY;
	}
}
