<input
	placeholder="Filter by date..."
	class="base-input"
	bsDatepicker
	[bsConfig]="bsDatePickerConfig"
	[formControl]="inputControl"/>
<small
	*ngIf="inputControl.invalid"
	class="text-danger">
	Date should be from {{ bsDatePickerConfig.minDate | date : 'MM/dd/yyyy' }}
	to {{ bsDatePickerConfig.maxDate | date : 'MM/dd/yyyy' }}
</small>
