import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'tier' })
export class TierPipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) {
	}

	public transform(item) {
		return this.sanitizer.bypassSecurityTrustHtml(`
<span class="badge-round" style="background-color: ${item.badgeColor}" title="Tier ${item.title}">T${item.title[0]}</span>
`);
	}
}
