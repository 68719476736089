import { CsrColumnNames } from '@app/w-boards/models/constants/csr-columns-constant';
import { KwColumnNames } from '@app/w-boards/models/constants/kw-columns-constant';
import { LawFirmManagerColumnNames } from '@app/w-boards/models/constants/law-firm-manager-columns-constant';
import { MedSchedulerColumnNames } from '@app/w-boards/models/constants/med-scheduler-columns-constant';
import { MedicalDreamBoardColumnNames } from '@app/w-boards/models/constants/medical-dream-board-columns-constant';
import { MriColumnNames } from '@app/w-boards/models/constants/mri-columns-constant';
import { PaymentsColumnNames } from '@app/w-boards/models/constants/payments-columns-constant';
import { QcColumnNames } from '@app/w-boards/models/constants/qc-columns-constant';
import { UnderwritingColumnNames } from '@app/w-boards/models/constants/underwriting-columns-constant';

export enum BoardTypeModel {
	UnderwritingBoard = 'underwriting',
	LawFirmManagerBoard = 'law_firm_manager',
	MedicalSchedulingBoard = 'med_scheduler',
	MriBoard = 'mri',
	CsrBoard = 'csr',
	QcBoard = 'qc',
	KwBoard = 'kw',
	PaymentsBoard = 'payments',
	MedicalDreamBoard = 'mdb'
}

export const BoardColumns = {
	[BoardTypeModel.UnderwritingBoard]: UnderwritingColumnNames,
	[BoardTypeModel.LawFirmManagerBoard]: LawFirmManagerColumnNames,
	[BoardTypeModel.MedicalSchedulingBoard]: MedSchedulerColumnNames,
	[BoardTypeModel.MriBoard]: MriColumnNames,
	[BoardTypeModel.CsrBoard]: CsrColumnNames,
	[BoardTypeModel.QcBoard]: QcColumnNames,
	[BoardTypeModel.KwBoard]: KwColumnNames,
	[BoardTypeModel.PaymentsBoard]: PaymentsColumnNames,
	[BoardTypeModel.MedicalDreamBoard]: MedicalDreamBoardColumnNames
}
