import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '@app/shared/model/constants/local-storage-keys';
import { SortSettings } from '@app/w-boards/shared/sort-icon/models/sort-settings';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class BoardSortingService {
	private sortSettings$ = new BehaviorSubject<SortSettings>(null);

	applySorting = () =>
		switchMap((fundings: any[]) =>
			this.sortSettings$
				.pipe(
					map(sortSettings => {
						if (sortSettings?.isSortEnabled()) {
							return [...fundings].sort((fundingA, fundingB) => sortSettings.sortFn(fundingA, fundingB));
						} else {
							return fundings;
						}
					}))
		);

	public setSorting(sortSettings: SortSettings): void {
		this.sortSettings$.next(sortSettings);
		localStorage.setItem(LocalStorageKeys.WHITEBOARD_SORTING, sortSettings.toJSON());
	}

}
