import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SmsRepositoryService } from '@app/shared/model/sms-repository.service';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { SharedModule } from '@app/shared/shared.module';
import { SmsCommonModule } from '@app/sms-common/sms-common.module';
import { InboxConversationDetailMessageComponent } from '@app/sms/inbox/inbox-conversation-detail/inbox-conversation-detail-message/inbox-conversation-detail-message.component';
import { InboxConversationDetailComponent } from '@app/sms/inbox/inbox-conversation-detail/inbox-conversation-detail.component';
import { InboxComponent } from '@app/sms/inbox/inbox.component';
import { SmsConversationDatePipe } from '@app/sms/pipes/sms-conversation-date.pipe';
import { SmsMessageDatePipe } from '@app/sms/pipes/sms-message-date.pipe';
import { SmsModalService } from '@app/sms/sms-modal.service';
import { SmsRoutingModule } from '@app/sms/sms-routing.module';

@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		FormsModule,
		PipesModule,
		SmsCommonModule,
		SmsRoutingModule,
	],
	declarations: [
		InboxComponent,
		InboxConversationDetailComponent,
		InboxConversationDetailMessageComponent,
		SmsConversationDatePipe,
		SmsMessageDatePipe
	],
	exports: [
		InboxComponent,
		InboxConversationDetailComponent,
		InboxConversationDetailMessageComponent,
		SmsConversationDatePipe,
		SmsMessageDatePipe
	],
	providers: [
		SmsModalService,
		SmsRepositoryService,
	]
})

export class SmsModule {}
