import { Component, OnInit } from '@angular/core';
import { CollapseStateService } from '@app/dashboard/collapsible-item/collapse-state.service';
import { DashboardRoundStore } from '@app/dashboard/service/dashboard-round-store.service';
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'dashboard-portfolios',
	templateUrl: './dashboard-portfolios.component.html',
	providers: [CollapseStateService]
})
export class DashboardPortfoliosComponent implements OnInit {
	collapsingIcon$: Observable<IconDefinition>;
	roundValue$: Observable<boolean>;

	constructor(
		private collapseStateProvider: CollapseStateService,
		private dashboardRoundStore: DashboardRoundStore
	) {
	}

	public ngOnInit(): void {
		this.roundValue$ = this.dashboardRoundStore.selectRound$;
		this.collapsingIcon$ = this.collapseStateProvider.isExpanded$.pipe(
			map((expanded) => expanded ? faChevronUp : faChevronDown)
		);
	}

	public toggleCollapse(): void {
		this.collapseStateProvider.toggleCollapse();
	}

	public toggleRound(value: boolean): void {
		this.dashboardRoundStore.setRound(value);
	}
}
