import { ChangeDetectionStrategy, Component, ComponentRef, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { GaTextRendererComponent } from '@app/shared/ga-components/components/renderers/ga-text-renderer.component';
import { MetadataProviderService } from '@app/shared/ga-components/services/metadata-provider.service';
import { PipeProviderService } from '@app/shared/ga-components/services/pipe-provider.service';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { FieldMetadata } from '@app/shared/ga-components/utils/field-metadata';
import { IRenderer } from '@app/shared/ga-components/utils/IRenderer';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';
import { isFunction } from '@app/shared/general-helper';
import * as _ from 'lodash';

@Component({
	selector: 'ga-value',
	template: '<ng-container #vc></ng-container>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
/* eslint-disable @angular-eslint/no-input-rename */
export class GaValueComponent implements OnInit, OnDestroy {

	@Input() componentMetadata: ComponentMetadata;
	@Input() fieldMetadata: FieldMetadata;
	@Input('metadata') metadataOverride: MetadataDecorator;
	@Input('field') fieldPath: string;
	@Input() pipe: string;
	@Input() dataCy: string = null;
	@ViewChild('vc', {read: ViewContainerRef, static: true}) viewContainer;
	value: any;
	rendererComponent: ComponentRef<IRenderer>;
	entityChangesEmitterSubscription;

	constructor(
		public acl: AclService,
		public entityScope: EntityScopeService,
		public metadataProvider: MetadataProviderService,
		private pipeProviderService: PipeProviderService,
		private elementRef: ElementRef,
		private renderer: Renderer2
	) {
	}

	public ngOnInit(): void {
		//TODO find a way how to optimize this when same gaValue component is rendered multiple times
		if (!this.componentMetadata) {
			this.componentMetadata =
				this.metadataProvider.getComponentMetadata(this.entityScope.entityClass, this.fieldPath, this.metadataOverride);
		}
		if (this.pipe) {
			this.componentMetadata = { ...this.componentMetadata, valueFormatter: this.pipeProviderService.resolvePipe(this.pipe) };
		}
		if (!this.fieldMetadata) {
			this.fieldMetadata = this.metadataProvider.getFieldMetadata(this.entityScope.entityClass, this.fieldPath);
		}
		if (this.dataCy) {
			this.componentMetadata.dataCy = this.dataCy;
		}

		this.entityScope.addField(this.componentMetadata.dependencies);

		if (this.fieldMetadata) {
			if (!this.acl.isReadable(this.fieldMetadata.fieldName, this.fieldMetadata.entity)) {
				return;
			}
		}

		this.rendererComponent = this.viewContainer.createComponent(this.componentMetadata.renderer || GaTextRendererComponent);
		this.rendererComponent.instance.componentMetadata = this.componentMetadata;
		this.rendererComponent.instance.params = this.componentMetadata.rendererParams;
		this.updateRenderer(this.entityScope.entity);

		this.entityChangesEmitterSubscription = this.entityScope.emitter.subscribe((data) => {
			this.updateRenderer(data);
		});

		this.viewContainer.insert(this.rendererComponent.hostView);
	}

	// TODO should update from closest entity not root entity
	public updateRenderer(data) {
		const newValue = _.clone(this.componentMetadata.valueGetter(data));
		this.rendererComponent.instance.dataObj = data;

		if (!_.isEqual(newValue, this.value)) {
			this.renderer.setAttribute(this.elementRef.nativeElement, 'title',
				isFunction(this.componentMetadata.tooltip) ? <string>this.componentMetadata.tooltip(newValue) : '');
			this.value = newValue;
			this.rendererComponent.instance.value = this.value;
			this.rendererComponent.instance.redraw();
			this.rendererComponent.changeDetectorRef.detectChanges();
		}
	}

	public ngOnDestroy(): void {
		if (this.entityChangesEmitterSubscription) {
			this.entityChangesEmitterSubscription.unsubscribe();
		}
	}
}
