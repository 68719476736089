import { Pipe, PipeTransform } from '@angular/core';

/*
 * Displays UID for given object and object type
 *
 * Usage:
 *   object | uid:type
*/
@Pipe({ name: 'uid' })
export class UidPipe implements PipeTransform {

	public transform(input, type): number | string {
		switch (type) {
			case 'funding':
			case 'applicant':
				return input.internalId || '';
			case 'attorney':
			case 'staff':
				return input ? (input.id || input) : '';
		}
	}
}
