<ng-container *ngrxLet="todo$; let todo">
	<ng-container *ngrxLet="expandCard$; let expandCard">
		<div class="todo-card__wrapper" #draggable="gaDraggable" gaDraggable [dragData]="todo" [dragHelper]="helper"
			 [enabled]="!expandCard"
			 [ngClass]="highlightColor$ | async">
			<div class="todo-card__header">
				<div class="todo-card__header__labels">
					<div class="todo-card__header__labels__item in-progress"
						 [ngClass]="todo?.status"
						 (click)="toggleProgressMenu()"
						 data-cy="progressMenu-btn"
						 (clickOutside)="closeProgressMenu()">
						{{ todo?.status | todoLabel }}
						<div class="quick-list left" *ngIf="showProgressMenu$ | async" @fadeUpDown>
							<div class="quick-list__triangle"></div>
							<div class="quick-list__content">
								<div class="quick-list__item" (throttleClick)="moveTaskToState(todoStatus.Todo)"
									 data-cy="progressMenuTodo-btn">
									<div class="quick-list__text todo-color">To do</div>
								</div>
								<div class="quick-list__item" (throttleClick)="moveTaskToState(todoStatus.InProgress)"
									 data-cy="progressMenuInProgress-btn">
									<div class="quick-list__text in-progress-color">In Progress</div>
								</div>
								<div class="quick-list__item" (throttleClick)="moveTaskToState(todoStatus.Done)"
									 data-cy="progressMenuDone-btn">
									<div class="quick-list__text done-color">Done</div>
								</div>
							</div>
						</div>
					</div>
					<todo-categories wrapperClass="todo-card__header__labels__categories__wrapper"
									 categoryLabelsWrapperClass="todo-card__header__labels__categories__item"
									 class="todo-card__header__labels__categories"
									 data-cy="todo-categories"
									 [categories]="todo?.todoCategories"
									 (updateTodo)="saveTodoCategories($event)">
					</todo-categories>
				</div>
				<div *ngIf="hasUnreadMention$ | async">
					<fa-icon [icon]="faIcons.at" (click)="markAsSeen()" tooltip="Mark as seen"
							 data-cy="markAsSeen-btn"></fa-icon>
				</div>
				<button *ngIf="isDraggable" class="btn-link" (mousedown)="draggable.emitMouseDownEvent($event)">
					<fa-icon [icon]="faIcons.arrowsAlt"></fa-icon>
				</button>
				<div class="todo-card__header__menu-btn" (click)="toggleQuickMenu()" data-cy="todoMenuToggle-btn"
					 (clickOutside)="closeQuickMenu()">
					<span></span>
					<span></span>
					<span></span>
					<div class="quick-list right" *ngIf="showQuickMenu$ | async" @fadeUpDown>
						<div class="quick-list__triangle"></div>
						<div class="quick-list__content">
							<div class="quick-list__item" (click)="toggleCardDialog()" data-cy="todoDelete-btn">
								<div class="quick-list__icon">
									<svg-icon [icon]="svgIconNames.Trash" class="svg-icon"></svg-icon>
								</div>
								<div class="quick-list__text">Delete</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<todo-card-summary [originalSummary]="todo?.summary" (onSave)="saveTodo($event)"></todo-card-summary>
			<todo-card-due-date [dueDateString]="todo?.dueDate" [timezone]="todo?.timezone"
								(onSave)="saveTodo($event)"></todo-card-due-date>
			<todo-labels [todo]="todo" data-cy="todo-labels"></todo-labels>
			<todo-card-associations [associations]="todo?.associations"
									[todoItemId]="todo?.id"></todo-card-associations>
			<div class="todo-card__footer">
				<todo-assignees wrapperClass="todo-card__footer__assignee"
								data-cy="todo-assignees"
								[todoId]="todo?.id"
								[assignees]="assignees$ | async"
								(assigneesChanged)="updateTodo({ assignees: $event })"
				></todo-assignees>
				<div class="todo-card__footer__actions">
					<button *ngIf="todo?.status === todoStatus.Todo" data-cy="todoStart-btn" class="btn btn--start"
							(throttleClick)="moveTaskToState(todoStatus.InProgress)">Start
					</button>
					<button *ngIf="todo?.status === todoStatus.InProgress" data-cy="todoFinish-btn"
							class="btn btn--finish"
							(throttleClick)="moveTaskToState(todoStatus.Done)">Finish
					</button>
					<button *ngIf="todo?.status === todoStatus.Done" data-cy="todoReopen-btn" class="btn btn--reopen"
							(throttleClick)="moveTaskToState(todoStatus.Todo)">Reopen
					</button>
				</div>
				<div class="todo-card__footer__expand" [class.active]="expandCard" (click)="toggleExpandCard()"
					 data-cy="todoExpand-btn">
					<fa-icon [icon]="faIcons.chevronDown"></fa-icon>
				</div>
			</div>
		</div>
		<!-- expanded card -->
		<div *ngIf="expandCard" @heightGrow class="todo-card__expand-area">
			<loading-spinner [show]="isLoadingDetail$ | async"></loading-spinner>
			<div class="todo-card__expand-area__wrapper">
				<todo-card-description (descriptionChanged)="saveTodo($event)"
									   [description]="todo?.description"></todo-card-description>
				<todo-comments [comments]="comments$ | async" [todoId]="todo?.id"></todo-comments>
				<todo-card-activity-log [todoId]="todo?.id"></todo-card-activity-log>
			</div>
		</div>
	</ng-container>
	<!-- card dialog -->
	<div class="todo-card__action-dialog" *ngIf="showCardDialog$ | async" @fadeInOut>
		<div>
			<h3>Remove this <span>"To do"</span>?</h3>
			<div class="todo-card__action-dialog__buttons">
				<button class="btn btn--cancel" data-cy="todoDeleteCancel-btn" (click)="closeCardDialog()">Cancel
				</button>
				<button class="btn btn--remove" data-cy="todoDeleteSubmit-btn" (throttleClick)="removeTodo()">Remove
				</button>
			</div>
		</div>
	</div>

	<ng-template #helper>
		<div class="drag-helper">{{ todo?.summary }}</div>
	</ng-template>
</ng-container>
