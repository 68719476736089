import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { unassignedUser } from '@app/shared/user-filter/model/user-filter-constants';
import { faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
	selector: 'unassigned-avatar',
	templateUrl: './unassigned-avatar.html',
	styleUrls: ['../../avatar/avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnassignedAvatarComponent implements OnInit {
	@Input() activeEmitter: Observable<[{ userId: number; status: boolean }]> = of();

	readonly faIcons = {
		userAltSlash: faUserAltSlash,
	};

	isActive$: Observable<boolean>;

	public ngOnInit(): void {
		this.isActive$ = this.activeEmitter.pipe(
			filter((users) => users.some((userSelected) => userSelected.userId == null || userSelected.userId == unassignedUser.id)),
			map((userSelected) => userSelected.find((user) => user.userId == unassignedUser.id)?.status),
			startWith(false)
		);
	}
}
