import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BsCustomDates } from 'ngx-bootstrap/datepicker/themes/bs/bs-custom-dates-view.component';

@Injectable()
export class DatePickerRangesService {
	static readonly REAL_TIME = 'Real-time';
	static readonly ENTIRE_HISTORY = 'Entire History';
	// days
	static readonly TODAY = 'Today';
	static readonly YESTERDAY = 'Yesterday';
	static readonly LAST_7_DAYS = 'Last 7 Days';
	static readonly LAST_30_DAYS = 'Last 30 Days';
	static readonly LAST_60_DAYS = 'Last 60 Days';
	static readonly LAST_90_DAYS = 'Last 90 Days';
	// weeks
	static readonly LAST_WEEK = 'Last Week';
	static readonly THIS_WEEK = 'This Week';
	// months
	static readonly LAST_MONTH = 'Last Month';
	static readonly THIS_MONTH = 'This Month';
	static readonly LAST_3_MONTHS = 'Last 3 Months';
	static readonly LAST_6_MONTHS = 'Last 6 Months';
	static readonly LAST_12_MONTHS = 'Last 12 Months';
	// years
	static readonly LAST_YEAR = 'Last Year';
	static readonly THIS_YEAR = 'This Year';
	static readonly SINCE_THIS_YEAR = 'Since this year';

	// presets
	static readonly WEEKLY_GRAPH = [
		DatePickerRangesService.LAST_30_DAYS,
		DatePickerRangesService.LAST_60_DAYS,
		DatePickerRangesService.LAST_90_DAYS,
		DatePickerRangesService.SINCE_THIS_YEAR
	];
	static readonly DAILY_GRAPH = [
		DatePickerRangesService.LAST_7_DAYS,
		DatePickerRangesService.LAST_WEEK,
		DatePickerRangesService.THIS_WEEK,
		DatePickerRangesService.LAST_30_DAYS,
		DatePickerRangesService.LAST_MONTH,
		DatePickerRangesService.THIS_MONTH
	];
	static readonly MONTHLY_GRAPH = [
		DatePickerRangesService.SINCE_THIS_YEAR,
		DatePickerRangesService.LAST_YEAR,
		DatePickerRangesService.LAST_6_MONTHS,
		DatePickerRangesService.LAST_3_MONTHS,
		DatePickerRangesService.LAST_MONTH,
		DatePickerRangesService.THIS_MONTH
	];

	ranges = {
		[DatePickerRangesService.REAL_TIME]: {},
		[DatePickerRangesService.ENTIRE_HISTORY]: { start: moment('2015-01-07'), end: moment().subtract(1, 'month').endOf('month') },
		// days
		[DatePickerRangesService.TODAY]: { start: moment(), end: moment() },
		[DatePickerRangesService.YESTERDAY]: { start: moment().subtract(1, 'days'), end: moment().subtract(1, 'days') },
		[DatePickerRangesService.LAST_7_DAYS]: { start: moment().subtract(6, 'days'), end: moment() },
		[DatePickerRangesService.LAST_30_DAYS]: { start: moment().subtract(29, 'days'), end: moment() },
		[DatePickerRangesService.LAST_60_DAYS]: { start: moment().subtract(59, 'days'), end: moment() },
		[DatePickerRangesService.LAST_90_DAYS]: { start: moment().subtract(89, 'days'), end: moment() },
		// weeks
		[DatePickerRangesService.LAST_WEEK]:
			{ start: moment().subtract(7, 'days').startOf('week'), end: moment().subtract(7, 'days').endOf('week') },
		[DatePickerRangesService.THIS_WEEK]: { start: moment().startOf('week'), end: moment().endOf('week') },
		// months
		[DatePickerRangesService.THIS_MONTH]: { start: moment().startOf('month'), end: moment().endOf('month') },
		[DatePickerRangesService.LAST_MONTH]:
			{ start: moment().subtract(1, 'month').startOf('month'), end: moment().subtract(1, 'month').endOf('month') },
		[DatePickerRangesService.LAST_3_MONTHS]:
			{ start: moment().subtract(3, 'months').startOf('month'), end: moment().subtract(1, 'months').endOf('month') },
		[DatePickerRangesService.LAST_6_MONTHS]:
			{ start: moment().subtract(6, 'month').startOf('month'), end: moment().subtract(1, 'month').endOf('month') },
		[DatePickerRangesService.LAST_12_MONTHS]:
			{ start: moment().subtract(12, 'month').startOf('month'), end: moment().subtract(1, 'month').endOf('month') },
		// years
		[DatePickerRangesService.LAST_YEAR]:
			{ start: moment().subtract(1, 'year').startOf('year'), end: moment().subtract(1, 'year').endOf('year') },
		[DatePickerRangesService.THIS_YEAR]: { start: moment().startOf('year'), end: moment().endOf('year') },
		[DatePickerRangesService.SINCE_THIS_YEAR]: { start: moment().startOf('year'), end: moment() },

	};

	public getRangeDates(ranges: string[], rangesOverride = {}) {
		const values = [];
		ranges.forEach((range) => {
			const rangeToUse = rangesOverride[range] ?? this.ranges[range];
			values.push(rangeToUse ? rangeToUse : { start: '', end: '' });
		});
		return values;
	}

	public getRanges(rangeNames: string[]): BsCustomDates[] {
		const ranges: BsCustomDates[] = [];
		rangeNames.forEach((name) => {
			ranges.push({ label: name, value: [this.ranges[name].start.toDate(), this.ranges[name].end.toDate()] });
		});
		return ranges;
	}
}
