<div class="chart-clear-wrapper chart-clear-wrapper--space-between">
	<div>
		<ng-content></ng-content>
	</div>
	<button class="base-button base-button--square" tooltip="Clear graph preferences" *ngIf="hasSavedChart$ | async"
			(click)="clearSavedChartState()">
		<svg-icon class="base-icon base-icon--small" [icon]="clearIcon"></svg-icon>
	</button>
</div>
<div class="breakdown-content">
	<div id="chart" class="ag-theme-alpine top-five-dashboard-chart"></div>
	<ag-grid-angular
		style="height: 300px"
		class="ag-theme-balham"
		[columnDefs]="columnDefs"
		[rowData]="data$ | async"
		[gridOptions]="gridOptions"
		(firstDataRendered)="onFirstDataRendered($event)"
		(chartOptionsChanged)="onChartOptionsChanged()"
	></ag-grid-angular>
</div>
