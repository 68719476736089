<div class="todo-card__due-date" (clickOutside)="closeDatepicker()">
	<div style="display: flex; align-items: center;"
		 (click)="toggleDatepicker()"
		 data-cy="todoDueDateToggle-btn"
		 [ngClass]="{'text-danger' : dueDateString | isInPast : 'days'}">
		<svg-icon [icon]="svgIconNames.Clock" class="svg-icon"></svg-icon>
		<div>
			<div *ngIf="dueDateString">{{ timezone?.title }}</div>
			{{ dueDateString | timezone:timezone?.zone | timeLeft }}
			<div *ngIf="isLocalTimeVisible && dueDateString" style="font-size: 10px; font-weight: 400; color: var(--dark);">Your local time: {{ dueDateString | timeLeft }}</div>
		</div>
	</div>
	<div *ngIf="dueDateString" (throttleClick)="removeDueDate()" data-cy="todoDueDateReset-btn" class="todo-card__due-date__remove">
		<svg-icon [icon]="svgIconNames.Trash" class="svg-icon"></svg-icon>
	</div>
	<div *ngIf="showDatepicker" [@fadeUpDown] class="todo-card__due-date__wrapper" style="height: 425px;">
		<bs-datepicker-inline
				bsDatepicker
				[bsConfig]="{ containerClass: 'custom-datepicker-theme with-timepicker', customTodayClass: 'custom-today-class' }"
				[minDate]="minDate"
				[(bsValue)]="dueDate"
		>
		</bs-datepicker-inline>
		<div class="todo-card__due-date__wrapper__timepicker">
			<timepicker [(ngModel)]="time" [mousewheel]="true" [minuteStep]="1"></timepicker>
			<div *ngIf="time" [@fadeInOut] (click)="clearTime()" class="clear">clear</div>
		</div>
		<div  style="color: var(--primary); cursor: pointer; margin: 10px 10px 0; font-size: 11px; font-weight: 400; display: flex; justify-content: space-between;">
			<span (click)="setLaTimezone()">Use LA timezone</span>
			<span (click)="setLocalTimezone()">Use local timezone</span>
		</div>
		<div class="todo-widget__header__datepicker__wrapper__timezone" style="padding: 8px 10px 0;">
			<ng-select class="base-input"
					   placeholder="Timezone"
					   data-cy="todoDueDateTimezone-input"
					   [items]="timezones"
					   [groupBy]="'countryCode'"
					   [searchable]="true"
					   [searchFn]="searchingHelper.searchCodelist"
					   bindLabel="title"
					   [(ngModel)]="selectedTimezone"
					   (change)="timezoneChange($event)">
			</ng-select>
		</div>
		<div class="todo-card__due-date__wrapper__btn-wrapper">
			<button (throttleClick)="saveDueDate()" data-cy="todoDueDateSubmit-btn">Save</button>
		</div>
	</div>
</div>
