import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SalesActivityReportGridComponent } from '@app/reports/sales-activity-report/sales-activity-report-grid/sales-activity-report-grid.component';
import { SalesActivityReportService } from '@app/reports/sales-activity-report/sales-activity-report.service';
import { TodosListService } from '@app/todo/list/todos-list.service';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
	declarations: [
		SalesActivityReportGridComponent
	],
	exports: [
		SalesActivityReportGridComponent
	],
	imports: [
		CommonModule,
		AgGridModule
	],
	providers: [SalesActivityReportService, TodosListService]
})
export class SalesActivityReportGridModule {}
