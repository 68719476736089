import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingButtonComponent } from '@app/widgets/loading-button/loading-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
	imports: [CommonModule, FontAwesomeModule],
	declarations: [LoadingButtonComponent],
	exports: [LoadingButtonComponent]
})
export class LoadingButtonModule {}
