export interface TodoSidebarSortItem {
	id: number,
	title: string,
	value: string | null
}

export enum SortType {
	Asc = 'asc',
	Desc = 'desc'
}
