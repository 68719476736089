import { CommonModule, Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalsService } from '@app/shared/modals/modals.service';
import { VintageDetailModalComponent } from '@app/shared/modals/vintage-detail/vintage-detail-modal.component';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
	imports: [
		CommonModule,
		AgGridModule,
	],
	declarations: [
		VintageDetailModalComponent,
	],
	exports: [
		VintageDetailModalComponent,
	],
	providers: [
		ModalsService,
		Location,
	],
})
export class ModalsModule {}
