import { Component, OnInit } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { DashboardBreakdownChartService } from '@app/dashboard/shared/breakdown-chart/dashboard-breakdown-chart-service';
import { SalesRepresentativesBreakdownChartService } from '@app/dashboard/sales-representatives-breakdown/sales-representatives-breakdown-chart.service';

@Component({
	selector: 'sales-representatives-breakdown',
	templateUrl: './sales-representatives-breakdown.component.html',
	providers: [
		{ provide: DashboardBreakdownChartService, useClass: SalesRepresentativesBreakdownChartService }
	]
})
export class SalesRepresentativesBreakdownComponent implements OnInit {

	codelistUsers$;

	constructor(
		private codelistRepository: CodelistRepositoryService) {
	}

	public ngOnInit(): void {
		this.codelistUsers$ = this.codelistRepository.getByUrl(UrlCodelistLink.SalesRepresentatives);
	}

}
