<user-filter
	(onClickUser)="filterByAssigneeUser($event)"
	(onClickCancel)="cancelFilters()"
	[users]="assigneeUsers"
>
</user-filter>
<ng-content></ng-content>
<div class="todo-widget__filter">
	<div *ngFor="let item of filterItems"
		 class="todo-widget__filter__item"
		 [attr.data-cy]="'todoFilter-'+item.offset+'-btn'"
		 [class.active]="offset === item.offset"
		 (click)="filter(item.status, item.offset)">
		{{ item.title }} ({{ todoTasks | todoCount : item.status : currentUserId$.value }})
	</div>
	<div *ngIf="unseenMentionsTabVisible" class="todo-widget__filter__item"
		 [attr.data-cy]="'todoFilter-'+unseenMentionsTabOffset+'-btn'"
		 [class.active]="offset === unseenMentionsTabOffset"
		 (click)="filter(null, unseenMentionsTabOffset)">
		Unseen Mentions ({{ unseenMentionsTasks.length }})

	</div>
	<div class="todo-widget__filter__line" [ngClass]="{'mentions-tab-visible' : unseenMentionsTabVisible}"
		 [style.transform]="'translateX(' + (100 * offset) + '%)'"></div>
</div>
<div class="todo-widget__content">
	<div class="todo-widget__content__shadow-top"></div>
	<div class="todo-widget__content__pool">
		<todo-card *ngFor="let todo of filteredTasks$ | async; trackBy: trackByFn" [todo]="todo" class="todo-card"
				   [attr.data-cy]="'todoCard__#'+todo?.id" [isDraggable]="false"
				   (onRemove)="removeTodo($event)"></todo-card>
		<div class="todo-list-widget__todo-widget__content__pool-separator"></div>
	</div>
	<div class="todo-widget__content__shadow-bottom"></div>
	<div *ngIf="showProgress" @fadeInOut class="loading-panel" data-cy="loading-element">
		<loading-spinner [show]="showProgress" loadingLabel="Saving data"></loading-spinner>
	</div>
</div>
