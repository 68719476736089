import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';

@Component({
	selector: 'svg-icon',
	template: `
		<svg [ngStyle]="{fill: fillColor}">
			<use attr.xlink:href="/assets/images/svg-icons-sprite.svg#{{icon}}"></use>
		</svg>
	`,
	standalone: true,
	imports: [CommonModule]
})
export class SVGIconComponent {
	@Input() icon: SvgIconName|string;
	@Input() fillColor: string;
}
