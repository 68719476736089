<div class="ag-overlay-loading-center" data-cy="loading-element">
    <div class="loading-panel">
        <div class="loading-panel__message">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <div class="loading-panel__message__text2">
                {{ params.loadingMessage }}
            </div>
        </div>
    </div>
</div>
