import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '@app/core/auth/auth.service';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { toNumber } from '@app/shared/general-helper';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { UserAccount } from '@app/shared/model/types/user-account';
import { combineLatest, debounceTime, distinctUntilChanged, map, Observable, startWith } from 'rxjs';

@Component({
	selector: 'assignee-list',
	templateUrl: './assignee-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssigneeListComponent implements OnInit {

	@Input() activeAssignees: UserAccount[];

	@Output() selectAssignee: EventEmitter<UserAccount> = new EventEmitter<UserAccount>();

	filteredAssignees$: Observable<UserAccount[]>;
	filterControl = new FormControl<string>('');
	currentUser;

	constructor(
		private authService: AuthService,
		private codelistService: CodelistRepositoryService
	) {
	}

	public ngOnInit(): void {
		this.setCurrentUser();
		this.initializeAssigneeFilter();
	}

	private setCurrentUser() {
		const currentUser = this.authService.getCurrentUser();
		this.currentUser = { id: currentUser.id, firstName: currentUser.firstName, lastName: currentUser.lastName };
	}

	private initializeAssigneeFilter() {
		const filter$ = this.filterControl.valueChanges.pipe(startWith(''));
		this.filteredAssignees$ = combineLatest([this.getAssignees$(), filter$])
			.pipe(
				distinctUntilChanged(),
				debounceTime(500),
				map(([assignees, term]) => this.filterAssignees(assignees, term)),
			);
	}

	private getAssignees$() {
		return this.codelistService.getByUrl(UrlCodelistLink.Employees).pipe(
			map(assignees => this.removeCurrentUserFromAssignees(assignees)),
		);
	}

	private removeCurrentUserFromAssignees(assignees): UserAccount[] {
		const currentUserIndex = assignees.findIndex((assignee) => assignee.id == this.currentUser.id);
		if (currentUserIndex !== -1) {
			assignees.splice(currentUserIndex, 1);
		}
		return assignees;
	}

	private filterAssignees(assignees: UserAccount[], searchTerm: string) {
		return assignees.filter(
			assignee => `${assignee.firstName} ${assignee.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}

	public isActive(assignee) {
		return this.activeAssignees.find(item => toNumber(item.id) === toNumber(assignee.id))
	}

	public setAssignee(assignee): void {
		this.filterControl.setValue('');
		this.selectAssignee.emit(assignee);
	}
}
