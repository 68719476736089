import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'procedureName' })
export class ProcedureNamePipe implements PipeTransform {

	public transform(selectedProcedure, medicalProcedures) {
		if (selectedProcedure == null || !medicalProcedures?.length) {
			return 'N/A';
		}
		return this.recursiveNameBuilder(medicalProcedures, selectedProcedure.id);
	}

	private recursiveNameBuilder(medicalProcedures, id) {
		let name = '';
		const procedure = medicalProcedures.find((item) => item.id == id);

		if (procedure) {
			if (procedure.parent) {
				name += this.recursiveNameBuilder(medicalProcedures, procedure.parent.id) + ' → ';
			}
			name += procedure.entityString;
		}

		return name;
	}
}
