import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toNumber } from '@app/shared/general-helper';
import { NewAttorneysResponse } from '@app/shared/model/statistics-repository.model';
import { EntityId } from '@app/shared/model/types/entity-id';
import { Observable } from 'rxjs';

@Injectable()
export class StatisticsRepositoryService {
	constructor(protected http: HttpClient) {
	}

	public getLiquidationCurve(search: { caseTypes: string[], periods: string[] }) {
		return this.http.get('/api/statistics/liquidation-curve', { params: { search: JSON.stringify(search) } });
	}

	public getAttorneysList(params): Observable<NewAttorneysResponse[]> {
		return this.http.get<NewAttorneysResponse[]>('/api/statistics/attorneys-list', { params });
	}

	public getAttorneysPerformance(search) {
		return this.http.get('/api/statistics/attorneys-performance', { params: { 'search': JSON.stringify(search) } });
	}

	public getPortfolioMetrics() {
		return this.http.get('/api/statistics/portfolio-metrics');
	}

	public getUnderwritingStats(fundingTypeIds: number[]) {
		return this.http.get('/api/statistics/underwriting', { params: { fundingType: fundingTypeIds } });
	}

	public getPortfolioProgressionData(resolution) {
		return this.http.get('/api/statistics/portfolio-progression', { params: { 'resolution': resolution } });
	}

	public getIndicatorStats(externalLeadSource: EntityId[], from: string, to: string, fundingType: number[]) {
		const externalLeadSourceIds = externalLeadSource.map(id => toNumber(id));
		const params = new HttpParams({
			fromObject: {
				'fundingType[]': fundingType,
				'externalLeadSource[]': externalLeadSourceIds
			}
		})
			.set('startDate', from)
			.set('endDate', to);
		return this.http.get('/api/statistics/sales', { params });
	}
}
