import { Pipe, PipeTransform } from '@angular/core';
import { toNumber } from '@app/shared/general-helper';
import * as _ from 'lodash';

/**
 * Return total value of property in Array
 */
@Pipe({ name: 'sumProperties' })
export class ObjectsSumPropertiesPipe implements PipeTransform {

	public transform(array: any[], property: string): number {
		if (array == null || array.length == 0) {
			return 0;
		}

		return array.reduce((sum, current) => sum + toNumber(_.get(current, property, 0)), 0);
	}
}
