import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInOut, fadeUpDown, heightGrow } from '@app/shared/animations/animations';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { toNumber } from '@app/shared/general-helper';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { TodoStatus } from '@app/shared/model/constants/todo-status';
import { EntityId } from '@app/shared/model/types/entity-id';
import { TodoUpdateRequest } from '@app/shared/model/types/todo';
import { UserAccount } from '@app/shared/model/types/user-account';
import { TodoCategory } from '@app/todo/model/todo-category';
import { TodoAssociationHelperService } from '@app/todo/services/todo-association-helper.service';
import { TodoCardStoreService } from '@app/todo/todo-card/todo-card-store.service';
import { faArrowsAlt, faAt, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { TodoCardComment, TodoCardData } from '../model/todo-card-data';

@Component({
	selector: 'todo-card',
	templateUrl: './todo-card.component.html',
	animations: [fadeUpDown, heightGrow, fadeInOut],
	providers: [TodoCardStoreService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoCardComponent implements OnInit {
	@Input() set todo(data: TodoCardData) {
		this.todoId = data.id;
		this.todoStore.setTodo(data);
	}

	@Input() isDraggable = true;
	@Output() onRemove = new EventEmitter<EntityId>();

	readonly todoStatus = TodoStatus;
	showQuickMenu$ = new BehaviorSubject(false);
	showProgressMenu$ = new BehaviorSubject(false);
	showCardDialog$ = new BehaviorSubject(false);
	expandCard$ = new BehaviorSubject(false);

	assignees$: Observable<UserAccount[]>;
	todo$: Observable<TodoCardData>;
	comments$: Observable<TodoCardComment[]>;
	hasUnreadMention$: Observable<boolean>;

	highlightColor$: Observable<string>;

	isLoadingDetail$: Observable<boolean>;

	readonly svgIconNames = SvgIconName;
	readonly faIcons = {
		at: faAt,
		arrowsAlt: faArrowsAlt,
		chevronDown: faChevronDown,
	};

	private todoId: EntityId;

	constructor(
		private apiLink: GaApiLink,
		private http: HttpClient,
		private todoStore: TodoCardStoreService,
		private todoAssociationHelperService: TodoAssociationHelperService
	) {
	}

	public ngOnInit(): void {
		this.todo$ = this.todoStore.todo$;
		this.isLoadingDetail$ = this.todoStore.isLoading$;
		this.assignees$ = this.todoStore.assignees$;
		this.comments$ = this.todoStore.comments$;
		this.highlightColor$ = this.todoStore.todo$
			.pipe(
				map(todo => this.getHighlightColor(todo))
			);
		this.hasUnreadMention$ = this.todoStore.hasUnreadMentions$;
	}

	private getHighlightColor({ dueDate, status }: TodoCardData): string {
		const followUpDate = moment(dueDate);
		const now = moment();
		const diff = followUpDate.diff(now, 'minutes');
		const threeDaysInMinutes = 4320;
		if (diff <= 0 && this.isInWorkingStage(status)) {
			return 'todo-card--danger';
		} else if (diff > 0 && diff <= threeDaysInMinutes && this.isInWorkingStage(status)) {
			return 'todo-card--warning';
		} else {
			return '';
		}
	}

	public isInWorkingStage(status: TodoStatus): boolean {
		return status === this.todoStatus.Todo || status === this.todoStatus.InProgress;
	}

	public toggleExpandCard(): void {
		this.expandCard$.next(!this.expandCard$.value);
		if (this.expandCard$.value) {
			this.todoStore.fetchDetail(this.todoId);
		}
	}

	public toggleQuickMenu(): void {
		this.showQuickMenu$.next(!this.showQuickMenu$.value);
	}

	public toggleProgressMenu(): void {
		this.showProgressMenu$.next(!this.showProgressMenu$.value);
	}

	public toggleCardDialog(): void {
		this.showCardDialog$.next(!this.showCardDialog$.value);
	}

	public closeQuickMenu(): void {
		this.showQuickMenu$.next(false);
	}

	public closeProgressMenu(): void {
		this.showProgressMenu$.next(false);
	}

	public closeCardDialog(): void {
		this.showCardDialog$.next(false);
	}

	public moveTaskToState(state: TodoStatus): void {
		this.saveTodo({ status: state });
	}

	public removeTodo(): void {
		this.apiLink.delete('todo', this.todoId).subscribe(() => {
			this.todoStore.removeTodo(this.todoId);
			this.onRemove.next(this.todoId);
			this.showCardDialog$.next(false);
		});
	}

	public saveTodoCategories(categories: TodoCategory[]): void {
		const ids = categories.map(relation => toNumber(relation.id));
		this.saveTodo({ categories: ids });
	}

	public saveTodo(changeSet: TodoUpdateRequest): void {
		this.http.patch(`/api/todos/${this.todoId}`, changeSet).pipe(
			this.todoAssociationHelperService.transformTodoAssociations()
		).subscribe(
			todoUpdate => {
				this.updateTodo(todoUpdate);
			}
		);
	}

	public updateTodo(changeSet: Partial<TodoCardData>): void {
		this.todoStore.updateTodo({ id: this.todoId.toString(), ...changeSet });
	}

	public markAsSeen(): void {
		this.todoStore.markAsSeen();
	}
}
