import { ElementRef, Injectable, Renderer2 } from '@angular/core';

@Injectable()
export class SpinnerService {
	private previousDisabledState: boolean = null;

	constructor(private elementRef: ElementRef,
				private renderer: Renderer2) {
	}

	public getElementContent(): string {
		return this.elementRef.nativeElement.innerHTML;
	}

	public showSpinner(): void {
		const spinner = this.createSpinner();
		this.renderer.insertBefore(this.elementRef.nativeElement, spinner, this.elementRef.nativeElement.firstChild);
	}

	private createSpinner() {
		const spinner = this.renderer.createElement('span');
		this.renderer.setProperty(spinner, 'innerHTML', "<i class='fa fa-spinner fa-spin' aria-hidden='true'></i>");
		this.renderer.setStyle(spinner, 'margin-right', '5px');
		this.renderer.setStyle(spinner, 'font-size', '15px');

		return spinner;
	}

	public hideSpinner(originalContent: string): void {
		this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', originalContent);
	}

	public disableElement(): void {
		this.previousDisabledState = this.elementRef.nativeElement.disabled;
		this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
	}

	public enableElement(): void {
		if (this.previousDisabledState || this.previousDisabledState === null) {
			return;
		}
		this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
	}
}
