<div *ngIf="associations?.length > 0" @fadeInOut class="todo-card__associations__wrapper">
	<div *ngFor="let association of associations; let i = index" class="todo-card__associations__item" data-cy="todoAssociation-item">
		<ng-container *ngIf="association.loading">
			<ng-content></ng-content>
		</ng-container>
		<span [ngSwitch]="association.association_type" data-cy="todoAssociation-value">
				<a *ngSwitchCase="todoAssociationTypes.Applicant" [routerLink]="['/applicants', association.referenceId]"><fa-icon
					[icon]="faIcons.user" class="m-r-sm"></fa-icon>
					{{ association.label }}</a>
			 	<a *ngSwitchCase="todoAssociationTypes.Funding"
				   [routerLink]="['/applicants', association.parentReferenceId || association.referenceId]"><fa-icon
					[icon]="faIcons.dollarSign" class="m-r-sm"></fa-icon>
					{{ association.label }}</a>
				<a *ngSwitchCase="todoAssociationTypes.Doctor" [routerLink]="['/doctors', association.referenceId]"><fa-icon
					[icon]="faIcons.user" class="m-r-sm"></fa-icon>
					{{ association.label }}</a>
				<a *ngSwitchCase="todoAssociationTypes.LawFirm" [routerLink]="['/law-firms', association.referenceId]"><fa-icon
					[icon]="faIcons.gavel" class="m-r-sm"></fa-icon>
					{{ association.label }}</a>
				<a *ngSwitchCase="todoAssociationTypes.MedicalFacility" [routerLink]="['/medical-facilities', association.referenceId]"><fa-icon
					[icon]="faIcons.hSquare" class="m-r-sm"></fa-icon>
					{{ association.label }}</a>
				<a *ngSwitchCase="todoAssociationTypes.Attorney"
				   [routerLink]="['/law-firms', association.label | associationIdSplitter : 1 : association.parentReferenceId, 'staff', association.referenceId]"><fa-icon
					[icon]="faIcons.userTie" class="m-r-sm"></fa-icon>
					{{ association.label | associationIdSplitter : 0 }}</a>
            </span>
		<svg-icon [icon]="svgIconNames.Trash" class="svg-icon trash" tooltip="Remove association" placement="bottom"
				  [delay]="300" [attr.data-cy]="'todoAssociationDelete-btn'"
				  (throttleClick)="removeAssociation(association)"></svg-icon>
	</div>
</div>
