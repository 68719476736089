import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UserAccountCodelist } from '@app/shared/model/types/codelists/user-account-codelist';
import { UserAccount } from '@app/shared/model/types/user-account';
import { unassignedUser } from '@app/shared/user-filter/model/user-filter-constants';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'user-filter',
	templateUrl: './user-filter.component.html',
	styles: ['.avatar-margin {margin: 0 4px 4px 0}']
})
export class UserFilterComponent implements OnInit, OnDestroy {
	@Input() showUnassignedFilter = true;
	@Input() users: UserAccount[] | UserAccountCodelist[];
	@Input() initialUsers: Observable<UserAccount[]>;
	@Input() cancelFilterEmitter: Observable<any>;

	@Output() onClickUser: EventEmitter<any> = new EventEmitter<any>();
	@Output() onClickCancel: EventEmitter<any> = new EventEmitter<any>();
	@Output() onUserChanged = new EventEmitter<number[]>();

	readonly unassignedUser = unassignedUser;
	readonly faIcons = {
		times: faTimes,
	};

	selectedUserIds: number[] = [];
	activeUser$: Observable<any>;
	private activeUserEmitter = new ReplaySubject(1);
	private destroy$ = new Subject();

	public ngOnInit(): void {
		this.activeUser$ = this.activeUserEmitter.asObservable();
		this.initialUsers?.pipe(first()).subscribe((users) => {
			users.forEach((user) => this.filterByUser(user));
			const activeUsers = this.selectedUserIds.map((userId) => ({ userId, status: true }));
			this.activeUserEmitter.next(activeUsers);
		});
		this.cancelFilterEmitter?.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.cancelFilters();
		});
	}

	public filterByUser(user): void {
		this.onClickUser.emit(user);
		this.manageInternalState(user);
		this.onUserChanged.emit(this.selectedUserIds);
		this.activeUserEmitter.next([
			{ userId: user?.id, status: this.selectedUserIds.includes(user == null ? null : parseInt(user?.id)) },
		]);
	}

	public cancelFilters(): void {
		this.onClickCancel.emit();
		this.selectedUserIds = [];
		this.activeUserEmitter.next([{ userId: null, status: false }]);
	}

	private manageInternalState(user: any): void {
		const userId = user != null ? parseInt(user.id, 10) : null;
		const userIndex = this.selectedUserIds.findIndex((id) => id == userId);
		if (userIndex == -1) {
			this.selectedUserIds.push(userId);
		} else {
			this.selectedUserIds.splice(userIndex, 1);
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
