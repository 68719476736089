<h4 [ngClass]="headerClass">Associations
	<button class="btn--attachment" [class.active]="showAssociationSearchBar"
			type="button"
			(clickOutside)="closeAssociationTypeMenu()"
			(click)="toggleAssociationTypeMenu()"><span data-cy="todoAssociationsAdd-btn">+</span></button>
	<div class="quick-list right" *ngIf="showAssociationTypeMenu" @fadeUpDown>
		<div class="quick-list__triangle"></div>
		<div class="quick-list__content">
			<div *ngFor="let association of associationTypes" class="quick-list__item" data-cy="todoAssociationType-btn"
				 (throttleClick)="setAssociationType(association)">
				<div class="quick-list__text">{{ association.title }}</div>
			</div>
		</div>
	</div>
</h4>

<div *ngIf="associations?.length === 0 && showAssociationSearchBar === false"
	 class="todo-card__associations__no-message">
	There are <span>no associations yet</span>.
</div>

<div *ngIf="showAssociationSearchBar" @fadeInOut class="todo-card__associations__search-bar">
	<div class="todo-card__associations__search-bar__search">
		<label>Add {{associationType.title}}</label>
		<ng-select placeholder=""
				   [attr.data-cy]="'associations' | cypressTag : 'select'"
				   [inputAttrs]="{'data-cy': 'associations' | cypressTag : 'input'}"
				   [items]="results$ | async"
				   [typeahead]="searchTerm$"
				   (change)="addAssociation($event)"
				   [loading]="resultsLoading$ | async"
				   class="form-control">
			<ng-template ng-typetosear ch-tmp>
				<div class="ng-option disabled">
					Type at least 3 letters...
				</div>
			</ng-template>
			<ng-template ng-notfound-tmp let-searchTerm="searchTerm">
				<div class="ng-option disabled">
					{{ searchTerm?.length > 2 ? 'No results' : 'Type at least 3 letters...'}}
				</div>
			</ng-template>
			<ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
				<div class="ng-option disabled">
					Fetching data for "{{searchTerm}}"
				</div>
			</ng-template>
		</ng-select>
	</div>
</div>
