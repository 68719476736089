import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'associationIdSplitter', pure: true })
export class AssociationIdSplitterPipe implements PipeTransform {

	public transform(label: string, index: number, defaultValue?: string): string {
		return label.split(/\(#([0-9]*)\)$/g, 2)[index] ?? defaultValue
	}

}
