import { Component, HostBinding, Input } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { SmsRepositoryService } from '@app/shared/model/sms-repository.service';
import { Sms } from '@app/shared/model/types/sms';
import { SmsDirection } from '@app/sms/sms-direction';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'inbox-conversation-detail-message',
	templateUrl: './inbox-conversation-detail-message.component.html',
	styleUrls: ['./inbox-conversation-detail-message.component.scss'],
})
export class InboxConversationDetailMessageComponent {
	@Input() sms: Sms;
	@HostBinding('className') className = 'inbox-message-wrapper';

	loggedUser;
	readonly faIcons = {
		briefcase: faBriefcase,
	};

	constructor(public smsRepository: SmsRepositoryService, public authService: AuthService) {
		this.loggedUser = authService.getCurrentUser();
	}

	public markActionTaken(sms: Sms): void {
		this.smsRepository.markActionTaken(sms).subscribe(() => {
			this.sms.actionTakenAt = Date.now();
			this.sms.actionTakenBy = { firstName: this.loggedUser.firstName, lastName: this.loggedUser.lastName };
		});
	}

	public isDirectionInbound(sms: Sms): boolean {
		return sms.direction === SmsDirection.Inbound;
	}

	public isDirectionOutbound(sms: Sms): boolean {
		return sms.direction === SmsDirection.Outbound;
	}
}
