import { Injectable } from '@angular/core';
import { IdentityProviderService } from '@app/core/auth/identity-provider.service';
import { selectTodo } from '@app/core/todo-store/todo.selectors';
import { WebSocketMessageType } from '@app/core/web-socket/web-socket-message-type';
import { WebSocketService } from '@app/core/web-socket/web-socket.service';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { EntityId } from '@app/shared/model/types/entity-id';
import { TodoCardData, TodoCardDetailData } from '@app/todo/model/todo-card-data';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, pipe, catchError, filter, first, map, switchMap } from 'rxjs';
import {Todo} from '@app/shared/model/types/todo';

@Injectable()
export class TodoDataProviderService {

	readonly fieldList = {
		minimum: [
			'summary',
			'status',
			'dueDate',
			'timezone -> zone, countryCode, title',
			'assignees -> id, firstName, lastName',
			'assignees.userAvatar -> id, localPath',
			'lastEditedAt',
			'todoCategories -> id, title, colorHex',
			'createdBy -> id, firstName, lastName',
			'todoLogEntries.id',
			'comments.author -> id, firstName, lastName',
			'comments.text',
			'comments.createdAt',
			'comments.mentions -> id, seen',
			'comments.mentions.mentionedUser -> id, firstName, lastName',
			'labels',
			'createdAt',
			'associations.label',
			'associations.referenceId',
			'associations.associationType'
		],
		detail: [
			'description'
		]
	};

	constructor(
		private apiLink: GaApiLink,
		private identityProvider: IdentityProviderService,
		private store: Store,
		private websocket: WebSocketService) {
	}

	readonly processUpdate = (managementCondition: (state) => boolean = () => true) => pipe(
		map(([todoUpdate, todoState]: [TodoCardData, TodoCardData[]]) => {
			const shouldBeManaged = managementCondition([todoUpdate, todoState]);

			const todos = [...todoState];
			const todoIndex = todos.findIndex(todo => todo?.id == todoUpdate?.id);
			if (todoIndex !== -1 && shouldBeManaged) {
				todos.splice(todoIndex, 1, todoUpdate);
			} else if (todoIndex !== -1 && !shouldBeManaged) {
				todos.splice(todoIndex, 1);
			} else if (shouldBeManaged) {
				todos.push(todoUpdate);
			}
			return todos;
		})
	)

	public fetchAll(mine = true): Observable<TodoCardData[]> {
		const loggedUserId = this.identityProvider.getIdentity().id;
		const requestFilter = mine ? {
			'%or': {
				assignees: { id: loggedUserId },
				createdBy: loggedUserId,
				comments: {
					mentions: {
						seen: false,
						mentionedUser: loggedUserId
					}
				}
			}
		} : {};
		return this.apiLink.retrieve<TodoCardData>(
			'todo',
			this.fieldList.minimum,
			{
				archivedAt: { '%is_null': '' },
				...requestFilter
			}
		);
	}

	public fetch(ids: EntityId[]): Observable<TodoCardData[]> {
		if (ids.length === 0) {
			return of([]);
		}
		return this.apiLink.retrieve('todo', this.fieldList.minimum, { id: ids });
	}

	public fetchUpdate(id: string): Observable<Partial<Todo>> {
		return this.store.select(selectTodo(id))
			.pipe(
				first(),
				map(todo => {
					const fieldList = this.fieldList.minimum;
					if (!this.mustFetchDetail(todo)) {
						fieldList.push(...this.fieldList.detail);
					}
					return fieldList;
				}),
				switchMap(fieldList => this.apiLink.retrieve('todo', fieldList, { id }).pipe(map(result => result[0])))
			);
	}

	public fetchWhole(id: EntityId): Observable<TodoCardData> {
		return this.apiLink.retrieve('todo', [...this.fieldList.minimum, ...this.fieldList.detail], { id })
			.pipe(
				map(todos => todos ? todos[0] : {})
			)
	}

	public fetchDetail(id: string): Observable<TodoCardDetailData> {
		return this.apiLink.retrieve('todo', this.fieldList.detail, { id })
			.pipe(
				map(todos => todos ? todos[0] : {})
			);
	}

	public mustFetchDetail(todo: unknown): boolean {
		return !Object.getOwnPropertyNames(todo).includes('description');
	}

	public getUpdates(todoId: EntityId = null): Observable<any> {
		const updates$ = this.websocket.getConnection()
			.pipe(
				//@todo: DataWatch type replaced with TodoUpdated, but it's not implemented yet
				filter((message) => message.type === WebSocketMessageType.TodoUpdated),
				map(message => message.data),
				catchError(() => EMPTY)
			);
		if (todoId !== null) {
			return updates$.pipe(
				filter(todo => todo.id == todoId)
			);
		}
		return updates$;
	}

	public getCommentUpdates(todoId: EntityId): Observable<any> {
		return this.websocket.getConnection()
			.pipe(
				filter((message) => message.type === WebSocketMessageType.CommentCreated && message.data.comment.todo.id == todoId),
				map((message) => message.data.comment),
			);
	}

}
