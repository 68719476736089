import { Injectable } from '@angular/core';
import { TodoDataProviderService } from '@app/core/todo-store/todo-data-provider.service';
import { uniqBy } from '@app/shared/general-helper';
import { TodoStatus } from '@app/shared/model/constants/todo-status';
import { EntityId } from '@app/shared/model/types/entity-id';
import { UserAccount } from '@app/shared/model/types/user-account';
import { TodoCardData } from '@app/todo/model/todo-card-data';
import { TodoCategory } from '@app/todo/model/todo-category';
import { TodoFilterPropertyName } from '@app/todo/whiteboard/model/todo-filter-property-name.model';
import { ComponentStore } from '@ngrx/component-store';
import { map, Observable, switchMap, tap, withLatestFrom } from 'rxjs';

interface TodoWhiteboardState {
	todos: TodoCardData[],
	searchTerm: string,
	assigneesFilter: UserAccount[],
	categoriesFilter: TodoCategory[]
}

interface FilterParams {
	item: UserAccount | TodoCategory,
	filterPropertyName: TodoFilterPropertyName
}

const initialState = {
	todos: [],
	searchTerm: '',
	assigneesFilter: [],
	categoriesFilter: []
};

@Injectable()
export class TodoWhiteboardStoreService extends ComponentStore<TodoWhiteboardState> {

	readonly todos$ = this.select(({ todos, searchTerm, assigneesFilter, categoriesFilter }) =>
		todos.filter((todo) => this.searchTermCondition(todo, searchTerm)
			&& this.idFilterCondition(todo.assignees, assigneesFilter)
			&& this.idFilterCondition(todo.todoCategories, categoriesFilter)
		));

	readonly allAssignees$ = this.select(({ todos }) => uniqBy(todos.flatMap((todo) => todo?.assignees), (item: UserAccount) => item.id));
	readonly selectedCategories$ = this.select(({ categoriesFilter }) => categoriesFilter);

	readonly updateSearchTerm = this.updater((state, searchTerm: string) => ({ ...state, searchTerm }));
	readonly updateIdsFilter = this.updater((state, filterParams: FilterParams) => {
		const filterItemsCopy = [...state[filterParams.filterPropertyName]];
		const currentAssigneeIndex = filterItemsCopy.findIndex((itemFromState) => itemFromState.id == filterParams.item.id);
		if (currentAssigneeIndex !== -1) {
			filterItemsCopy.splice(currentAssigneeIndex, 1);
		} else {
			filterItemsCopy.push(filterParams.item);
		}
		return { ...state, [filterParams.filterPropertyName]: filterItemsCopy };
	});

	readonly init = this.effect((ignored$: Observable<void>) => {
		return ignored$
			.pipe(
				switchMap(() => this.dataProvider.fetchAll(false)),
				tap(todos => this.patchState({ todos })),
				switchMap(() => this.dataProvider.getUpdates()),
				switchMap(todo => this.dataProvider.fetch(todo.id)),
				map(todos => todos[0]),
				withLatestFrom(this.todos$),
				this.dataProvider.processUpdate(),
				tap((todos: TodoCardData[]) => {
					this.updateTodos(todos);
				})
			);
	});

	readonly removeTodo = this.updater((state, todoId: EntityId) => {
		const todos = [...state.todos];
		const indexToRemove = todos.findIndex((todo) => todo.id == todoId);
		todos.splice(indexToRemove, 1);
		return { ...state, todos };
	});

	private readonly updateTodos = this.updater((state, todos: TodoCardData[]) => ({ ...state, todos }));

	constructor(
		private dataProvider: TodoDataProviderService
	) {
		super(initialState);
	}

	readonly clearAssigneesFilter = () => this.patchState({ assigneesFilter: [] });

	private searchTermCondition(todo: TodoCardData, searchTerm: string): boolean {
		const summaryContains = todo?.summary ? todo.summary.toLowerCase().includes(searchTerm.toLowerCase().trim()) : false;
		const descriptionContains = todo?.description ? todo.description.toLowerCase().includes(searchTerm.trim()) : false;
		return summaryContains || descriptionContains;
	}

	private idFilterCondition(todoPropertyItems: UserAccount[] | TodoCategory[], filterItems: UserAccount[] | TodoCategory[]): boolean {
		if (filterItems.length > 0) {
			return todoPropertyItems.some((todoItem) =>
				filterItems.map((filterItem) => filterItem.id).includes(todoItem.id));
		}
		return true;
	}

	public todosByStatus$(status: TodoStatus): Observable<TodoCardData[]> {
		return this.todos$.pipe(
			map((todos) => todos.filter((todo) => todo.status === status))
		);
	}
}
