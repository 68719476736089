import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SmsRepositoryService } from '@app/shared/model/sms-repository.service';
import { EntityId } from '@app/shared/model/types/entity-id';
import { SmsConversation } from '@app/sms/sms-conversation';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'inbox',
	templateUrl: './inbox.component.html',
	styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent implements OnInit, OnDestroy {

	@HostBinding('class.view') hostClass = true;

	conversations: SmsConversation[] = [];
	selectedConversation: SmsConversation;
	selectedConversationId: EntityId;
	nextOffset = 0;
	showLoading = false;

	private readonly conversationsLimit = 25;
	private searchTerm: string = null;
	private destroy$ = new Subject<void>();

	constructor(
		private smsService: SmsRepositoryService,
		private activatedRoute: ActivatedRoute, //@Fixme, reactive activatedroute will be needed here
		private router: Router) {
	}

	public ngOnInit(): void {
		this.loadMessages(0);
	}

	private loadMessages(offset?: number, searchTerm?: string): void {
		this.showLoading = offset == 0;
		this.smsService.prepareInbox(offset, this.conversationsLimit, searchTerm)
			.pipe(takeUntil(this.destroy$))
			.subscribe((inboxData) => {
				this.conversations = offset == 0 ? inboxData.conversations : this.conversations.concat(inboxData.conversations);
				this.nextOffset = inboxData.nextOffset;
				this.openDefaultConversation();
				this.showLoading = false;
			});
	}

	private openDefaultConversation(): void {
		this.selectedConversationId = this.activatedRoute.snapshot.params.conversation;
		if (!this.conversations[0]) {
			this.router.navigate(['/sms-inbox']);
		}
		if (!this.selectedConversationId && this.conversations[0] !== undefined) {
			this.router.navigate(['/sms-inbox', { conversation: this.conversations[0].id }]);
			this.selectConversation(this.conversations[0]);
		} else {
			this.router.navigate(['/sms-inbox', { conversation: this.selectedConversationId }]);
			const conversation = this.conversations.find(item => item.id === this.selectedConversationId)
			if (conversation) {
				this.selectConversation(conversation);
			}
		}
	}

	public searchConversations(searchTerm: string): void {
		this.searchTerm = searchTerm;
		this.nextOffset = 0;
		this.fetchMoreConversations();
	}

	public fetchMoreConversations(): void {
		this.loadMessages(this.nextOffset, this.searchTerm);
	}

	public selectConversation(conversation: SmsConversation): void {
		if (this.selectedConversation) {
			this.selectedConversation.isSelected = false;
		}
		conversation.isSelected = true;
		if (conversation.unreadMessages.length) {
			this.markConversationAsRead(conversation);
		}
		this.selectedConversation = conversation;
	}

	private markConversationAsRead(conversation: SmsConversation): void {
		this.smsService.markConversationAsRead(conversation);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
