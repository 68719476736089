import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DetailFundingsTableConfig, GetCountsResponse } from '@app/shared/detail-fundings-table/detail-fundings-table.model';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { CountParams } from '@app/shared/model/types/count-params';
import { Funding } from '@app/shared/model/types/funding';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { merge } from 'lodash';
import { map, Observable, switchMap, shareReplay, filter } from 'rxjs';

@Injectable()
export class DetailFundingsTableService {

	protected config: DetailFundingsTableConfig;

	constructor(
		private apiLink: GaApiLink,
		private http: HttpClient,
	) {
	}

	public setConfig(config: DetailFundingsTableConfig) {
		this.config = config;
	}

	public getFundings$(tab: string, fundingsFilter: Record<string, unknown>): Observable<Funding[]> {
		const configTable = { ...this.config[tab] };
		const updatedFilter = merge({}, { ...configTable.filter }, fundingsFilter);
		return this.apiLink.retrieve('funding', configTable.fieldList, updatedFilter);
	}

	public getCounts$(params$: Observable<CountParams>): Observable<Record<string, number>> {
		return params$.pipe(
			filter((params) => !!params),
			switchMap((params) => this.http.get<GetCountsResponse>('/api/fundings/tabs-counts', { params })),
			shareReplay({ bufferSize: 1, refCount: true }),
			map((response) => {
				const counts = {};
				Object.entries(this.config).forEach(([key, value]) => {
					counts[key] = value.getCount(response);
				});
				return counts;
			})
		);
	}

	public getColDef(tab: string): (ColGroupDef | ColDef)[] {
		return this.config[tab].preset;
	}
}
