<div class="base-input__wrapper">
	<label class="base-label" [for]="uniqueId">{{label}}</label>
	<ng-select
		[items]="booleanSelectOptions"
		data-cy="active-input"
		[id]="uniqueId"
		[formControl]="inputControl"
		class="base-input"
		appendTo="body"
		bindLabel="title"
		bindValue="value">
	</ng-select>
</div>
