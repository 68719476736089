import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';
import { AgActionButtonsRendererComponent } from '@app/shared/ag-grid-utils/ag-action-buttons-renderer/ag-action-buttons-renderer.component';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { dateTimeColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/date-column-definition';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { CaseStatusUpdateNotification } from '@app/shared/model/types/case-status-update-notification';
import { EntityId } from '@app/shared/model/types/entity-id';
import { PersistableGaGridComponent } from '@app/shared/persistable-ga-grid-component/persistable-ga-grid-component';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable } from 'rxjs';

@Component({
	selector: 'portal-case-status-changes-table',
	templateUrl: './portal-case-status-changes-table.component.html',
	styleUrls: ['./portal-case-status-changes-table.component.scss']
})
export class PortalCaseStatusChangesTableComponent extends PersistableGaGridComponent implements OnInit, OnDestroy {

	@ViewChild('agGrid') agGrid: AgGridAngular;

	@Input() caseStatusUpdatesIds?: number[];

	@Output() caseStatusUpdated = new EventEmitter<EntityId>();

	columnDefs;
	gridOptions;

	rowData$: Observable<CaseStatusUpdateNotification[]>;

	private readonly fieldList = [
		'applicant.firstName',
		'applicant.lastName',
		'applicant.attorney.lawFirm.name',
		'applicant.attorney.lawFirm.id',
		'changeBy.email',
		'oldCaseStatus.title',
		'newCaseStatus.title',
		'changeOn'
	];

	constructor(
		private acl: AclService,
		private apiLink: GaApiLink,
		private agGridUtils: AgGridUtilsService) {
		super();
	}

	public ngOnInit(): void {
		this.gridOptions = this.agGridUtils.getGridOptions({
			suppressExcelExport: !this.acl.isAllowed('export.security_rocknet_case_status_changes'),
			defaultExcelExportParams: {
				fileName: 'Rocknet Case Status Changes Export'
			},
			defaultColDef: {
				filter: 'agTextColumnFilter',
			},
		});

		this.columnDefs = [
			{
				headerName: 'Applicant',
				valueGetter: obj => `${obj.data.applicant.firstName} ${obj.data.applicant.lastName}`,
				cellRenderer: params => this.agGridUtils.applicantLinkRenderer(params.data?.applicant.id, params.value)
			},
			{
				headerName: 'Law Firm',
				valueGetter: obj => obj.data.applicant.attorney.lawFirm.name,
				cellRenderer: params => this.agGridUtils.lawFirmLinkRenderer(params.data?.applicant.attorney.lawFirm.id, params.value)
			},

			{ field: 'changeBy.email', headerName: 'Changed By' },
			{ field: 'oldCaseStatus.title', headerName: 'Old Case Status', filter: true },
			{ field: 'newCaseStatus.title', headerName: 'New Case Status', filter: true },
			{
				headerName: 'Change on',
				width: 150,
				...dateTimeColumnDefinition('changeOn')
			},
			{
				field: 'actions',
				filter: false,
				sortable: false,
				width: 120,
				cellRenderer: AgActionButtonsRendererComponent,
				cellRendererParams: {
					buttonParams: [
						{
							title: 'Resolve',
							showConfirmDialog: false,
							icon: faCheck,
							cssClass: 'btn btn-success btn--grid',
							action: ({ dataObj, _params, gridParams }) => {
								this.apiLink.update('caseStatusUpdateNotification', dataObj, { status: 'RESOLVED' }, []).subscribe(
									() => {
										this.caseStatusUpdated.emit(dataObj.id);
										gridParams.api.applyTransaction({ remove: [dataObj] });
									}
								);
							}
						}
					]
				}
			}
		];

		this.rowData$ = this.apiLink.retrieve('caseStatusUpdateNotification',
			this.fieldList,
			{ id: this.caseStatusUpdatesIds, 'status': 'PENDING' }
		);
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
	}
}
