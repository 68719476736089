import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { ObjectStoreMeta } from 'ngx-indexed-db';

export const medicalAEDbConfig: ObjectStoreMeta = {
	store: UrlCodelistLink.MedicalAEs,
	storeConfig: { keyPath: 'id', autoIncrement: false },
	storeSchema: [
		{ name: 'id', keypath: 'id', options: { unique: true } },
		{ name: 'department', keypath: 'department', options: { unique: false } },
		{ name: 'departmentPosition', keypath: 'departmentPosition', options: { unique: false } },
		{ name: 'dummyAccount', keypath: 'dummyAccount', options: { unique: false } },
		{ name: 'firstName', keypath: 'firstName', options: { unique: false } },
		{ name: 'lastName', keypath: 'lastName', options: { unique: false } },
		{ name: 'supervisor', keypath: 'supervisor', options: { unique: false } },
		{ name: 'userAvatar', keypath: 'userAvatar', options: { unique: false } },
	]
}
