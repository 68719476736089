<input class="base-input"
       [(colorPicker)]="value"
       [style.background]="value"
       [cpToggle]="true"
       [cpSaveClickOutside]="false"
       [cpOKButton]="true"
       [cpOKButtonClass]="'base-button base-button--small'"
       [cpCancelButton]="true"
       [cpCancelButtonClass]="'base-button base-button--small'"
       [cpOutputFormat]="'hex'"
       [cpPosition]="'bottom-right'"
       [cpPresetColors]="['#ffcc43', '#ff9900', '#ed5565', '#16a085', '#23c6c8', '#1c84c6']"
       (colorPickerSelect)="save()"
       (colorPickerCancel)="cancel()"
/>
