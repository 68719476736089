import { Injectable } from '@angular/core';
import { IdentityProviderService } from '@app/core/auth/identity-provider.service';
import { ServerFieldsDefinitionService } from '@app/core/fields-definition/server/server-fields-definition.service';
import { RevisionsService } from '@app/core/revisions/revisions.service';
import { IRevisions, RevisionType } from '@app/core/revisions/types/revision-type';
import { PermissionsService } from '@app/core/security/permissions/new/permissions.service';
import { FeatureFlagService } from '@app/core/unleash/feature-flag.service';
import * as Sentry from '@sentry/angular-ivy';
import { firstValueFrom, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AppInitializer {

	constructor(
		private identityProvider: IdentityProviderService,
		private revisionsService: RevisionsService,
		private permissionsService: PermissionsService,
		private fieldsDefinitionService: ServerFieldsDefinitionService,
		private featureFlags: FeatureFlagService
	) { }

	public bootstrap(): Promise<any> | boolean {
		console.info('* Starting bootstrap...');

		const identity = this.identityProvider.getIdentity();
		Sentry.setUser({ id: `${identity.id}`, username: identity.getFullName() });

		if (!identity.isAuthenticated) {
			console.warn('* No identity found. Nothing to do here...');
			return true;
		}

		console.info('* Authenticated identity found, initializing...');

		this.featureFlags.setUser(identity.id.toString());

		console.info('* Fetching most recent revisions...');

		return firstValueFrom(this.revisionsService.getCurrentRevisions()
			.pipe(
				switchMap((revisions: IRevisions) => forkJoin([
					this.permissionsService.initPermissions(revisions.getRevision(RevisionType.PermissionsRevision)),
					this.fieldsDefinitionService.initFieldDefinitions(revisions.getRevision(RevisionType.FieldDefinitionsRevision)),
				])),
			)).then(() => console.info('* Init complete...'));
	}
}
