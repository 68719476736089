import { Pipe, PipeTransform } from '@angular/core';
import { DoctorAddress } from '@app/shared/model/types/doctor-address';

@Pipe({
	name: 'doctorAddress'
})
export class DoctorAddressPipe implements PipeTransform {

	public transform(address: DoctorAddress, defaultValue = ''): string {
		if (!address) {
			return defaultValue;
		}
		let formattedAddress = `${address.street}, ${address.city}`;
		formattedAddress += address.state ? `, ${address.state.code}` : '';
		formattedAddress += `, ${address?.zip ?? ''}`;

		return formattedAddress;
	}

}
