import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ExportReportsButtonComponent } from '@app/fundings/export-reports-button/export-reports-button.component';
import { FundingRoutingModule } from '@app/fundings/funding-routing.module';
import { FundingsTableGridComponent } from '@app/fundings/fundings-table-grid/fundings-table-grid.component';
import { FundingsViewComponent } from '@app/fundings/fundings-view.component';
import { SharedModule } from '@app/shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		BsDatepickerModule,
		TooltipModule,
		ButtonsModule,
		AgGridModule,
		FundingRoutingModule,
	],
	declarations: [
		FundingsViewComponent,
		FundingsTableGridComponent,
		ExportReportsButtonComponent,
	]
})
export class FundingsModule {}
