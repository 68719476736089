import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { fadeInOut, fadeUpDown } from '@app/shared/animations/animations';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { UserAccountCodelist } from '@app/shared/model/types/codelists/user-account-codelist';
import { TodoCommentMentionRequest } from '@app/shared/model/types/todo';
import { UserAccount } from '@app/shared/model/types/user-account';
import { TodoCommentService } from '@app/todo/todo-card/todo-comments/service/todo-comment.service';
import { ChoiceWithIndices } from '@grownapps/ngx-mentions';
import { BehaviorSubject, combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'todo-comment-form',
	templateUrl: './todo-comment-form.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		fadeInOut,
		fadeUpDown,
	],
})
export class TodoCommentFormComponent implements OnInit, OnDestroy {
	newCommentTextCtrl: FormControl<string>;
	selectedChoices$: Observable<any[]>;
	filteredEmployees$: Observable<UserAccountCodelist[]>;
	showCheck$: Observable<boolean>;

	private selectedChoices: ChoiceWithIndices[] = [];
	private employeeSearchTerm$ = new BehaviorSubject<string>('');
	private destroy$ = new Subject<void>();

	constructor(
		private codelistService: CodelistRepositoryService,
		private todoCommentService: TodoCommentService) {
	}

	public ngOnInit(): void {
		this.newCommentTextCtrl = this.todoCommentService.getNewCommentTextCtrl();
		this.showCheck$ = this.todoCommentService.getShowCheck();
		this.selectedChoices$ = this.todoCommentService.getSelectedChoices();
		this.filteredEmployees$ = combineLatest([
			this.codelistService.getByUrl<UserAccountCodelist[]>(UrlCodelistLink.Employees),
			this.employeeSearchTerm$.asObservable()
		]).pipe(
			map(([employees, searchTerm]) => {
				const selectedEmployees = this.selectedChoices.map(({ choice }) => choice.id);
				return employees.filter(employee =>
					!selectedEmployees.includes(employee.id) && `${employee.firstName} ${employee.lastName}`
						.toLowerCase()
						.includes(searchTerm.toLowerCase())
				);
			}),
			takeUntil(this.destroy$)
		);
	}

	getChoiceLabel = (user: UserAccount): string => `@${user.firstName} ${user.lastName}`;

	public filterMentions(searchTerm: string): void {
		this.employeeSearchTerm$.next(searchTerm);
	}

	public onSelectedChoicesChange(mentions: ChoiceWithIndices[]): void {
		this.selectedChoices = mentions;
		this.todoCommentService.commentMentionRequests = mentions.map(mention => new TodoCommentMentionRequest(mention.choice.id));
	}

	public emitInputTouch(): void {
		if (this.newCommentTextCtrl.untouched) {
			this.todoCommentService.emitInputTouch();
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
