import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fullName' })
export class FullNamePipe implements PipeTransform {

	public transform(userAccount: {firstName?: string, lastName?: string}, defaultValue: string): any {
		if (userAccount?.firstName || userAccount?.lastName) {
			return `${userAccount?.firstName ?? ''} ${userAccount?.lastName ?? ''}`;
		}
		return defaultValue;
	}
}
