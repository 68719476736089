import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { AsyncValidatorOptions } from '@app/shared/ga-components/utils/async-validator-options';
import { JqlAsyncValidatorOptions } from '@app/shared/ga-components/utils/jql-async-validator-options';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ValidationHelper {

	constructor(private apiLink: GaApiLink, private http: HttpClient) {}

	public validate(options: JqlAsyncValidatorOptions|AsyncValidatorOptions, value: string): Observable<any> {
		if (Object.keys(options).includes('entityClass')) {
			return this.validateByJql(options as JqlAsyncValidatorOptions, value);
		} else {
			return this.validateByUrl(options as AsyncValidatorOptions, value);
		}
	}

	private validateByJql(options: JqlAsyncValidatorOptions, valueToSearch: string): Observable<any> {
		const filter = {};
		filter[options.fieldToCheck] = valueToSearch;
		return this.apiLink.retrieve(options.entityClass, options.fieldList, filter).pipe(first());
	}

	private validateByUrl(options: AsyncValidatorOptions, valueToSearch: string): Observable<any> {
		const termParams = new HttpParams().set('term', valueToSearch);
		return this.http.get(options.url, {params: termParams}).pipe(first());
	}
}
