import { CommonModule, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApprovalStatusPipe } from '@app/shared/pipes/approval-status.pipe';
import { ArrayContainsPipe } from '@app/shared/pipes/array-contains.pipe';
import { CamelCaseToDashStringPipe } from '@app/shared/pipes/camel-case-to-dash-string.pipe';
import { CodelistPipe } from '@app/shared/pipes/codelist.pipe';
import { CypressTagPipe } from '@app/shared/pipes/cypress-tag.pipe';
import { DealTagPipe } from '@app/shared/pipes/deal-tag.pipe';
import { DoctorAddressPipe } from '@app/shared/pipes/doctor-address.pipe';
import { EntriesPipe } from '@app/shared/pipes/entries.pipe';
import { FilterByCategoryPipe } from '@app/shared/pipes/filter-by-category.pipe';
import { FullNamePipe } from '@app/shared/pipes/full-name.pipe';
import { HighlightPipe } from '@app/shared/pipes/highlight.pipe';
import { InteractionRatingPipe } from '@app/shared/pipes/interaction-rating.pipe';
import { IsInPastPipe } from '@app/shared/pipes/is-in-past.pipe';
import { LoaTypeColorPipe } from '@app/shared/pipes/loa-type-color.pipe';
import { MainDoctorContactInfoPipe } from '@app/shared/pipes/main-doctor-contact-info.pipe';
import { MapToIdsPipe } from '@app/shared/pipes/map-to-ids.pipe';
import { NameInitialsSortPipe } from '@app/shared/pipes/name-initials-sort.pipe';
import { NameInitialsPipe } from '@app/shared/pipes/name-initials.pipe';
import { ObjectLengthPipe } from '@app/shared/pipes/object-length.pipe';
import { ObjectsSumPropertiesPipe } from '@app/shared/pipes/objects-sum-properties.pipe';
import { OrdinalPipe } from '@app/shared/pipes/ordinal.pipe';
import { PartialScrollerPipe } from '@app/shared/pipes/partial-scroller.pipe';
import { ProcedureNamePipe } from '@app/shared/pipes/procedure-name.pipe';
import { SortByPipe } from '@app/shared/pipes/sort-by.pipe';
import { StringifyObjectArrayPipe } from '@app/shared/pipes/stringify-object-array.pipe';
import { TextColorPipe } from '@app/shared/pipes/text-color.pipe';
import { TierPipe } from '@app/shared/pipes/tier.pipe';
import { TimeLeftPipe } from '@app/shared/pipes/time-left.pipe';
import { TimezonePipe } from '@app/shared/pipes/timezone.pipe';
import { ToFiniteNumberPipe } from '@app/shared/pipes/to-finite-number.pipe';
import { TruncatePipe } from '@app/shared/pipes/truncate.pipe';
import { UidPipe } from '@app/shared/pipes/uid.pipe';
import { WeeksAgoPipe } from '@app/shared/pipes/weeks-ago.pipe';
import { WhiteboardFundingLocationPipe } from '@app/shared/pipes/whiteboard-funding-location.pipe';

@NgModule({
	imports: [
		CommonModule,
		FormsModule
	],
	declarations: [
		ApprovalStatusPipe,
		CodelistPipe,
		StringifyObjectArrayPipe,
		EntriesPipe,
		HighlightPipe,
		ObjectLengthPipe,
		ObjectsSumPropertiesPipe,
		OrdinalPipe,
		TierPipe,
		UidPipe,
		TruncatePipe,
		NameInitialsPipe,
		NameInitialsSortPipe,
		TimeLeftPipe,
		TextColorPipe,
		ProcedureNamePipe,
		WeeksAgoPipe,
		SortByPipe,
		CamelCaseToDashStringPipe,
		MainDoctorContactInfoPipe,
		PartialScrollerPipe,
		FilterByCategoryPipe,
		DealTagPipe,
		IsInPastPipe,
		ToFiniteNumberPipe,
		IsInPastPipe,
		TimezonePipe,
		CypressTagPipe,
		FullNamePipe,
		WhiteboardFundingLocationPipe,
		DoctorAddressPipe,
		LoaTypeColorPipe,
		InteractionRatingPipe,
		ArrayContainsPipe,
		MapToIdsPipe
	],
	exports: [
		ApprovalStatusPipe,
		CodelistPipe,
		StringifyObjectArrayPipe,
		EntriesPipe,
		HighlightPipe,
		ObjectLengthPipe,
		ObjectsSumPropertiesPipe,
		OrdinalPipe,
		TierPipe,
		UidPipe,
		TruncatePipe,
		NameInitialsPipe,
		NameInitialsSortPipe,
		TimeLeftPipe,
		TextColorPipe,
		ProcedureNamePipe,
		WeeksAgoPipe,
		SortByPipe,
		CamelCaseToDashStringPipe,
		MainDoctorContactInfoPipe,
		PartialScrollerPipe,
		FilterByCategoryPipe,
		DealTagPipe,
		IsInPastPipe,
		ToFiniteNumberPipe,
		IsInPastPipe,
		TimezonePipe,
		CypressTagPipe,
		FullNamePipe,
		WhiteboardFundingLocationPipe,
		DoctorAddressPipe,
		LoaTypeColorPipe,
		InteractionRatingPipe,
		ArrayContainsPipe,
		MapToIdsPipe
	],
	providers: [
		ApprovalStatusPipe,
		TruncatePipe,
		PercentPipe,
		MainDoctorContactInfoPipe,
		DealTagPipe
	]
})

export class PipesModule {
}
