import { Injectable } from '@angular/core';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { EntityId } from '@app/shared/model/types/entity-id';
import { DocumentFile } from '@app/shared/model/types/file';
import { map, Observable } from 'rxjs';

@Injectable()
export class DocumentFilesService {

	constructor(private apiLink: GaApiLink) {
	}

	public fetchEntityFiles(entityName: string, entityId: EntityId): Observable<DocumentFile[]> {
		return this.apiLink.retrieve(entityName, [
				'files -> filename, originalTitle, uploadedOn, category',
				'files.uploadedBy -> firstName, lastName',
			],
			{ id: entityId })
			.pipe(map(data => data[0].files))
	}
}
