import { formatPercent } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { AclService } from '@app/core/security/acl.service';
import { FundingsTableGridFunding } from '@app/fundings/fundings-table-grid/fundings-table-grid-funding';
import { FundingsViewService } from '@app/fundings/fundings-view.service';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { AgGroupRendererComponent } from '@app/shared/ag-grid-utils/ag-group-renderer/ag-group-renderer.component';
import { currencyColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/currency-column-definition';
import { dateColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/date-column-definition';
import { toggleColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/toggle-column-definition';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { FundingHelperService } from '@app/shared/model/funding-helper.service';
import { BuyoutFunder } from '@app/shared/model/types/codelists/buyout-funder';
import { CaseStatus } from '@app/shared/model/types/codelists/case-status';
import { CaseTypes } from '@app/shared/model/types/codelists/case-types';
import { Codelist } from '@app/shared/model/types/codelists/codelist';
import { DefendantPolicyLimit } from '@app/shared/model/types/codelists/defendant-policy-limit';
import { ExternalLeadSource } from '@app/shared/model/types/codelists/external-lead-source';
import { FundingTypeCodelist } from '@app/shared/model/types/codelists/funding-type';
import { MedicalProcedureCategory } from '@app/shared/model/types/codelists/medical-procedure-category';
import { MedicalProcedureType } from '@app/shared/model/types/codelists/medical-procedure-type';
import { MedicalProviderType } from '@app/shared/model/types/codelists/medical-provider-type';
import { MedicalSpecialty } from '@app/shared/model/types/codelists/medical-specialty';
import { Tier } from '@app/shared/model/types/codelists/tier';
import { UserAccountCodelist } from '@app/shared/model/types/codelists/user-account-codelist';
import { FundsDeliveryOption } from '@app/shared/model/types/funds-delivery-option';
import { LawFirmURLCodelist } from '@app/shared/model/types/law-firm';
import { SelectListItem } from '@app/shared/model/types/select-list-item';
import { PersistableGaGridComponent } from '@app/shared/persistable-ga-grid-component/persistable-ga-grid-component';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, ColGroupDef, ColumnApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { capitalize } from 'lodash';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'fundings-table-grid',
	templateUrl: './fundings-table-grid.component.html',
	styleUrls: ['fundings-table-grid.component.scss'],
	providers: [FundingsViewService],
})
export class FundingsTableGridComponent extends PersistableGaGridComponent implements OnInit, OnChanges {
	@Input() dateRange: string[] = [];
	@Input() dateFilter: SelectListItem;
	@Input() archivedFilter: SelectListItem;
	@Input() fundingFilter: SelectListItem;
	@ViewChild('agGrid') agGrid: AgGridAngular;

	gridData: FundingsTableGridFunding[];
	gridOptions: GridOptions;
	columnDefs: (ColGroupDef | ColDef)[];

	private gridColumnApi: ColumnApi;
	private readonly plaintiffColumns = [
		'applicant.caseType.title',
		'applicant.caseStatus.title',
		'brokerFee',
		'fundsDeliveryOption.title',
		'feesCharged'
	];
	private readonly medicalColumns = [
		'medicalSpecialty.title',
		'medicalProcedureType.title',
		'medicalProcedureType.category.title',
		'doctor',
		'medicalFacility',
		'purchasePrice',
		'currentAmountOwed'
	];

	constructor(
		private agUtils: AgGridUtilsService,
		private fundingHelper: FundingHelperService,
		private codelistService: CodelistRepositoryService,
		private fundingsReportService: FundingsViewService,
		private acl: AclService,
	) {
		super();
	}

	private getAccountCellFilterOptions(codelistLink: UrlCodelistLink) {
		return {
			filter: 'agSetColumnFilter',
			filterParams: {
				values: (params) => this.codelistService.getByUrl(codelistLink)
					.pipe(takeUntil(this.destroy$))
					.subscribe((accountData) => params.success(
						this.agUtils.getArrayOfStringsByFiledName(accountData, ['firstName', 'lastName']))
					),
				buttons: ['clear', 'reset']
			}
		}
	}

	public ngOnInit(): void {
		this.gridOptions = this.agUtils.getGridOptions({
			autoGroupColumnDef: {
				cellRenderer: 'agGroupCellRenderer',
				cellRendererParams: {
					innerRenderer: (params) => params.value,
					suppressDoubleClickExpand: true
				}
			},
			onFilterChanged: event => event.api.refreshCells({ columns: ['#'] }),
			onSortChanged: event => event.api.refreshCells({ columns: ['#'] }),
			suppressExcelExport: !this.acl.isAllowed('export.portfolio_fundings'),
			defaultExcelExportParams: {
				fileName: 'Fundings Portfolio Export'
			},
		});

		this.columnDefs = [
			{
				field: '#',
				headerName: '#',
				valueGetter: params => params.node.rowIndex + 1,
				pinned: 'left',
				minWidth: 50
			},
			{
				field: 'internalId',
				headerName: 'Internal ID',
				cellRenderer: ({ data, value }) =>
					data?.applicant ? this.agUtils.fundingLinkRenderer(value, data?.id, data?.applicant?.id) : value,
				sortable: true,
				filter: 'agTextColumnFilter',
				pinned: 'left',
				minWidth: 120
			},
			{
				headerName: 'Applicant',
				valueGetter: ({ data }) => this.agUtils.getFullName(data?.applicant, ''),
				cellRenderer: ({ data, value }) =>
					data ? this.agUtils.applicantLinkRenderer(data?.applicant?.id, value) : value,
				filter: 'agTextColumnFilter',
				sortable: true,
				minWidth: 200,
				pinned: 'left',
			},
			{
				headerName: 'City',
				field: 'applicant.city',
				filter: 'agTextColumnFilter',
				sortable: true,
				minWidth: 200
			},
			{
				field: 'applicant.state.title',
				headerName: 'State',
				filter: 'agTextColumnFilter',
				sortable: true,
				minWidth: 200,
			},
			{
				headerName: 'Law Firm',
				children: [
					{
						headerName: 'Law Firm',
						valueGetter: ({ data }) => data?.applicant?.attorney?.lawFirm?.name ?? '',
						cellRenderer: ({ data, value, ...params }) => {
							if (params.node.group) {
								return params.getValue();
							}
							return this.agUtils.lawFirmLinkRenderer(data?.applicant?.attorney?.lawFirm?.id, value);
						},
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => this.codelistService.getByUrl<LawFirmURLCodelist[]>(UrlCodelistLink.LawFirms)
								.pipe(takeUntil(this.destroy$))
								.subscribe(lawFirms =>
									params.success(this.agUtils.getArrayOfStringsByFiledName(lawFirms, 'name'))
								),
							buttons: ['clear', 'reset'],
						},
						sortable: true,
						minWidth: 200
					},
					{
						field: 'applicant.attorney.lawFirm.id',
						headerName: 'Law Firm ID',
						sortable: true,
						filter: 'agNumberColumnFilter',
						minWidth: 120
					},
					{
						field: 'applicant.attorney.lawFirm.city',
						headerName: 'Law Firm City',
						filter: 'agTextColumnFilter',
						sortable: true,
						minWidth: 200,
					},
					{
						field: 'applicant.attorney.lawFirm.state.title',
						headerName: 'Law Firm State',
						filter: 'agTextColumnFilter',
						sortable: true,
						minWidth: 200,
					},
					{
						field: 'applicant.attorney.lawFirm.tier.title',
						headerName: 'Law Firm Tier',
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => {
								this.codelistService.get<Tier[]>(NonUrlCodelistName.LawFirmTier)
									.pipe(takeUntil(this.destroy$))
									.subscribe((data) => {
										params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title'));
										this.agGridCodelistFilterService.setGridFilter('lawFirmTier');
									});
							},
						},
						minWidth: 200,
						hide: true,
					},
					{
						field: 'applicant.caseManager',
						headerName: 'Case Manager',
						valueGetter: ({ data }) => this.agUtils.getFullName(data?.applicant?.caseManager, ''),
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => {
								this.codelistService.getByUrl(UrlCodelistLink.CaseManagers)
									.pipe(takeUntil(this.destroy$))
									.subscribe(data => {
										params.success(this.agUtils.getArrayOfStringsByFiledName(data, ['firstName', 'lastName']));
									});
							}
						},
						minWidth: 200
					},
					{
						field: 'applicant.lawFirmManager',
						headerName: 'Law Firm Manager',
						valueGetter: ({ data }) => this.agUtils.getFullName(data?.applicant?.lawFirmManager, ''),
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => this.codelistService.getByUrl(UrlCodelistLink.LawFirmManagers)
								.pipe(takeUntil(this.destroy$))
								.subscribe(data =>
									params.success(this.agUtils.getArrayOfStringsByFiledName(data, ['firstName', 'lastName']))
								),
							buttons: ['clear', 'reset']

						},
						minWidth: 200
					},
					{
						field: 'lawFirmStageOnApplied',
						filter: 'agSetColumnFilter',
						minWidth: 180,
						valueFormatter: ({ value }) => capitalize(value),
					},
					{
						field: 'newBiz',
						headerName: 'Is New Business',
						minWidth: 100,
						valueFormatter: ({ value }) => value ? 'Yes' : 'No',
						filterValueGetter: params => params.data.newBiz ? 'Yes' : 'No',
					},
				]
			},
			{
				headerName: 'Case Details',
				children: [
					{
						headerName: 'Created on',
						sortable: true,
						minWidth: 150,
						sort: 'desc',
						...dateColumnDefinition('createdOn')
					},
					{
						headerName: 'Applied on',
						sortable: true,
						minWidth: 150,
						hide: true,
						...dateColumnDefinition('appliedOn')
					},
					{
						headerName: 'Funded On',
						sortable: true,
						minWidth: 150,
						hide: true,
						...dateColumnDefinition('fundedOn')
					},
					{
						headerName: 'Settled On',
						sortable: true,
						minWidth: 150,
						hide: true,
						...dateColumnDefinition('settledOn')
					},
					{
						field: 'fundingType.title',
						headerName: 'Funding type',
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => this.codelistService.get<FundingTypeCodelist[]>(NonUrlCodelistName.FundingType)
								.pipe(takeUntil(this.destroy$))
								.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title'))),
							buttons: ['clear', 'reset']
						},
						sortable: true,
						minWidth: 150,
					},
					{
						field: 'approvalStatus',
						headerName: 'Approval Status',
						sortable: true,
						filter: 'agSetColumnFilter',
						minWidth: 200,
						valueGetter: ({ data }) => data ? this.fundingHelper.getStringRepresentationOfFundingState(data) : '',
					},
					{
						field: 'applicant.caseType.title',
						headerName: 'Case Type',
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => this.codelistService.get<CaseTypes[]>(NonUrlCodelistName.CaseType)
								.pipe(takeUntil(this.destroy$))
								.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title'))),
							buttons: ['clear', 'reset']
						},
						minWidth: 200
					},
					{
						field: 'applicant.caseStatus.title',
						headerName: 'Case Status',
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => this.codelistService.get<CaseStatus[]>(NonUrlCodelistName.CaseStatus)
								.pipe(takeUntil(this.destroy$))
								.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title'))),
							buttons: ['clear', 'reset']
						},
						minWidth: 200
					},
					{
						field: 'applicant.defendantPolicyLimit.title',
						headerName: 'Defendant Policy Limits',
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => this.codelistService.get<DefendantPolicyLimit[]>(NonUrlCodelistName.DefendantsLimit)
								.pipe(takeUntil(this.destroy$))
								.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title'))),
							buttons: ['clear', 'reset']
						},
						minWidth: 200,
						hide: true
					},
					{
						field: 'applicant.docketNumber',
						headerName: 'Docket Number',
						sortable: true,
						filter: 'agTextColumnFilter',
						minWidth: 200
					},
				]
			},
			{
				headerName: 'Case Owners',
				children: [
					{
						field: 'salesRepresentative',
						headerName: 'New Business Rep',
						valueGetter: ({ data }) => this.agUtils.getFullName(data?.salesRepresentative, ''),
						sortable: true,
						...this.getAccountCellFilterOptions(UrlCodelistLink.SalesRepresentatives),
						minWidth: 200
					},
					{
						field: 'fundingConsultant',
						headerName: 'Account Growth Rep',
						valueGetter: ({ data }) => this.agUtils.getFullName(data?.fundingConsultant, ''),
						sortable: true,
						...this.getAccountCellFilterOptions(UrlCodelistLink.FundingConsultants),
						minWidth: 200
					},
				]
			},
			{
				headerName: 'Rockpoint representatives',
				children: [
					{
						field: 'customerServiceRepresentative',
						headerName: 'CSR',
						valueGetter: ({ data }) => this.agUtils.getFullName(data?.customerServiceRepresentative, ''),
						sortable: true,
						...this.getAccountCellFilterOptions(UrlCodelistLink.CustomerServiceRepresentatives),
						minWidth: 200,
					},
					{
						field: 'accountExecutive',
						headerName: 'Account executive',
						valueGetter: ({ data }) => this.agUtils.getFullName(data?.accountExecutive, ''),
						sortable: true,
						...this.getAccountCellFilterOptions(UrlCodelistLink.MedicalAEs),
						minWidth: 200,
					},
					{
						field: 'applicant.attorney.lawFirm.executivePresence.title',
						headerName: 'Executive Presence',
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => {
								this.codelistService.get<Tier[]>(NonUrlCodelistName.ExecutivePresence)
									.pipe(takeUntil(this.destroy$))
									.subscribe((data) => {
										params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title'));
										this.agGridCodelistFilterService.setGridFilter('executivePresence');
									});
							},
						},
						minWidth: 200
					},
					{
						field: 'applicant.attorney.lawFirm.salesRepresentative',
						headerName: 'New Business Rep',
						valueGetter: ({ data }) => this.agUtils.getFullName(data?.applicant?.attorney?.lawFirm?.salesRepresentative, ''),
						sortable: true,
						...this.getAccountCellFilterOptions(UrlCodelistLink.SalesRepresentatives),
						minWidth: 200
					},
					{
						field: 'applicant.lawFirmManager',
						headerName: 'Law Firm Manager',
						valueGetter: ({ data }) => this.agUtils.getFullName(data?.applicant?.lawFirmManager, ''),
						sortable: true,
						...this.getAccountCellFilterOptions(UrlCodelistLink.LawFirmManagers),
						minWidth: 200
					},
					{
						field: 'applicant.attorney.lawFirm.fundingConsultant',
						headerName: 'Account Growth Rep',
						valueGetter: ({ data }) => this.agUtils.getFullName(data?.applicant?.attorney?.lawFirm?.fundingConsultant, ''),
						sortable: true,
						...this.getAccountCellFilterOptions(UrlCodelistLink.FundingConsultants),
						minWidth: 200
					},
				]
			},
			{
				headerName: 'Pricing',
				children: [
					{
						headerName: 'Requested Funding',
						sortable: true,
						minWidth: 200,
						...currencyColumnDefinition('requestedFunding')
					},
					{
						headerName: 'Approved At',
						sortable: true,
						minWidth: 200,
						...currencyColumnDefinition('approvedAt'),
					},
					{
						headerName: 'Approved Up To',
						sortable: true,
						minWidth: 200,
						...currencyColumnDefinition('applicant.approvedUpTo')
					},
					{
						headerName: 'Purchase Price',
						field: 'purchasePrice',
						sortable: true,
						minWidth: 200,
						...currencyColumnDefinition('purchasePrice')
					},
					{
						headerName: 'Effective rate',
						sortable: true,
						minWidth: 200,
						...currencyColumnDefinition('medicalProcedureType.category.effectiveRate.amount')
					},
					{
						headerName: 'Face Value',
						field: 'currentAmountOwed',
						sortable: true,
						minWidth: 200,
						...currencyColumnDefinition('currentAmountOwed')
					},
					{
						headerName: 'Percentage of Face Value',
						sortable: true,
						minWidth: 200,
						valueGetter: ({ data }) => data?.purchasePrice
						&& data.currentAmountOwed
						&& parseFloat(data.currentAmountOwed) > 0
						&& (this.fundingHelper.isMri(data) || this.fundingHelper.isMedicalLien(data))
							? (parseFloat(data.purchasePrice) / parseFloat(data.currentAmountOwed)) * 100
							: 'N/A',
						hide: true,
						filter: 'agNumberColumnFilter',
						valueFormatter: ({ value }) => typeof value === 'number' ? formatPercent(value / 100, 'en-US', '1.0-2') : value,
					},
					{
						field: 'settledAt',
						headerName: 'Settlement Amount',
						sortable: true,
						minWidth: 200,
						...currencyColumnDefinition('settledAt')
					},
					{
						headerName: 'Application fee',
						sortable: true,
						minWidth: 200,
						...currencyColumnDefinition('applicationFee')
					},
					{
						headerName: 'Broker Fee',
						field: 'brokerFee',
						sortable: true,
						minWidth: 200,
						...currencyColumnDefinition('brokerFee')
					},
					{
						headerName: 'Fees Charged',
						field: 'feesCharged',
						sortable: true,
						minWidth: 200,
						...currencyColumnDefinition('feesCharged')
					},
					{
						field: 'buyoutAmount',
						headerName: 'Buyout Amount',
						sortable: true,
						minWidth: 200,
						...currencyColumnDefinition('buyoutAmount')
					},
					{
						field: 'buyoutFunder.title',
						headerName: 'Buyout Funder',
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => this.codelistService.get<BuyoutFunder[]>(NonUrlCodelistName.BuyoutFunder)
								.pipe(takeUntil(this.destroy$))
								.subscribe((data: BuyoutFunder[]) =>
									params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title'))
								)
						},
						minWidth: 200,
					},
					{
						field: 'fundsDeliveryOption.title',
						headerName: 'Funds Delivery Option',
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => this.codelistService.get<FundsDeliveryOption[]>(NonUrlCodelistName.FundsDeliveryOption)
								.pipe(takeUntil(this.destroy$))
								.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title')))
						},
						minWidth: 200
					}
				]
			},
			{
				headerName: 'Lead Source',
				children: [
					{
						field: 'externalLeadSource.title',
						headerName: 'External',
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => this.codelistService.get<ExternalLeadSource[]>(NonUrlCodelistName.ExternalLeadSource)
								.pipe(takeUntil(this.destroy$))
								.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title')))
						},
						minWidth: 200
					},
					{
						field: 'origin.title',
						headerName: 'Funding Origin',
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => this.codelistService.get<Codelist[]>(NonUrlCodelistName.FundingOrigin)
								.pipe(takeUntil(this.destroy$))
								.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title')))
						},
						minWidth: 200
					},
				]
			},
			{
				field: 'medicalSpecialty.title',
				headerName: 'Speciality',
				sortable: true,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.get<MedicalSpecialty[]>(NonUrlCodelistName.MedicalFundingSpecialty)
						.pipe(takeUntil(this.destroy$))
						.subscribe((data) =>
							params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title')))
				},
				minWidth: 200
			},
			{
				field: 'medicalProcedureType.title',
				headerName: 'Procedure Type',
				sortable: true,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.get<MedicalProcedureType[]>(NonUrlCodelistName.MedicalProcedureType)
						.pipe(takeUntil(this.destroy$))
						.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title')))
				},
				minWidth: 200
			},
			{
				field: 'medicalProviderType.title',
				headerName: 'Provider Type',
				sortable: true,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.get<MedicalProviderType[]>(NonUrlCodelistName.MedicalProviderType)
						.pipe(takeUntil(this.destroy$))
						.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title')))
				},
				minWidth: 150
			},
			{
				field: 'medicalProcedureType.category.title',
				headerName: 'Procedure Category',
				sortable: true,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.get<MedicalProcedureCategory[]>(NonUrlCodelistName.MedicalProcedureCategory)
						.pipe(takeUntil(this.destroy$))
						.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title')))
				},
				minWidth: 200
			},
			{
				headerName: 'Doctor',
				field: 'doctor',
				valueGetter: ({ data }) => data?.doctor?.name,
				cellRenderer: ({ data, value }) => this.agUtils.doctorLinkRenderer(data?.doctor?.id, value),
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.getByUrl(UrlCodelistLink.Doctors)
						.pipe(takeUntil(this.destroy$))
						.subscribe(doctors => params.success(this.agUtils.getArrayOfStringsByFiledName(doctors, 'name'))),
					buttons: ['clear', 'reset'],
				},
				sortable: true,
				minWidth: 200
			},
			{
				field: 'doctorReferralSource.title',
				headerName: 'Doctor Referral Source',
				sortable: false,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.get<Codelist[]>(NonUrlCodelistName.DoctorReferralSource)
						.pipe(takeUntil(this.destroy$))
						.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title')))
				},
				minWidth: 200
			},
			{
				headerName: 'Medical Facility',
				field: 'medicalFacility',
				valueGetter: ({ data }) => data?.medicalFacility?.name ?? '',
				cellRenderer: ({ data, value }) =>
					this.agUtils.medicalFacilityLinkRenderer(data?.medicalFacility?.id, value),
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.getByUrl(UrlCodelistLink.MedicalFacilities)
						.pipe(takeUntil(this.destroy$))
						.subscribe(medicalFacilities =>
							params.success(this.agUtils.getArrayOfStringsByFiledName(medicalFacilities, 'name'))),
					buttons: ['clear', 'reset'],
				},
				sortable: true,
				minWidth: 200
			},
			{
				headerName: 'Archived',
				children: [
					{
						field: 'archived',
						headerName: 'Is Archived',
						sortable: true,
						...toggleColumnDefinition('archived'),
						cellRendererParams: {
							isEditable: false
						},
						minWidth: 200,
					},
					{
						field: 'archivedReasonType.title',
						headerName: 'Reason Type',
						sortable: true,
						filter: 'agSetColumnFilter',
						filterParams: {
							values: (params) => this.codelistService.get<Codelist[]>(NonUrlCodelistName.ArchivedReasonType)
								.pipe(takeUntil(this.destroy$))
								.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title')))
						},
						minWidth: 200
					},
					{
						field: 'archivedReasonNote',
						headerName: 'Reason Note',
						sortable: true,
						filter: 'agTextColumnFilter',
						minWidth: 200
					},
				]
			},
			{
				headerName: 'Rejection Reason',
				field: 'rejectionReason.title',
				filter: 'agTextColumnFilter',
				sortable: true,
				minWidth: 200
			},
			{
				headerName: 'Rejected On',
				...dateColumnDefinition('rejectedOn'),
				minWidth: 150
			},
			{
				headerName: 'Underwriters',
				valueGetter: ({ data }) => {
					if (data?.applicant?.underwriters) {
						return data?.applicant.underwriters.map(underwriter =>
							this.agUtils.getFullName(underwriter, '')).join(',');
					}
					return '';
				},
				cellRenderer: AgGroupRendererComponent,
				cellRendererParams: {
					delimiter: ',',
				},
				enableRowGroup: false,
				enableValue: false,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.getByUrl<UserAccountCodelist[]>(UrlCodelistLink.Underwriters)
						.pipe(takeUntil(this.destroy$))
						.subscribe((underwriters) =>
							params.success(this.agUtils.getArrayOfStringsByFiledName(underwriters, ['firstName', 'lastName']))
						),
					valueGetter: ({ data }) => data?.applicant?.underwriters?.map(underwriter => this.agUtils.getFullName(underwriter, ''))
				},
				minWidth: 200
			},
		];
	}

	public onGridReady(params: GridReadyEvent): void {
		params.api.sizeColumnsToFit();
		this.gridColumnApi = params.columnApi;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.dateRange || changes.dateFilter || changes.fundingFilter || changes.archivedFilter) {

			if (this.gridOptions) {
				this.gridOptions.api.showLoadingOverlay();
			}
			this.fundingsReportService
				.fetchData(this.dateRange, this.dateFilter.value, this.fundingFilter.value, this.archivedFilter.value)
				.pipe(takeUntil(this.destroy$))
				.subscribe((data) => {
					this.gridData = data;
					if (this.gridOptions) {
						this.gridOptions.api.hideOverlay();
						this.gridOptions.api.sizeColumnsToFit();
					}
				});
		}
	}

	public showAllColumns(): void {
		this.gridColumnApi.resetColumnState();
	}

	public hideMedicalColumns(): void {
		this.showAllColumns();
		this.gridColumnApi.setColumnsVisible(this.medicalColumns, false);
	}

	public hidePlaintiffColumns(): void {
		this.showAllColumns();
		this.gridColumnApi.setColumnsVisible(this.plaintiffColumns, false);
	}
}
