import { Injectable } from '@angular/core';
import { CustomPeriod } from '@app/dashboard/shared/dashboard-shared.model';
import * as moment from 'moment';

@Injectable()
export class DashboardDataUtilsService {

	public getPeriodColDef() {
		return {
			valueGetter: ({ data }) => this.getPeriodLabel(data),
			comparator: (valueA, valueB, nodeA, nodeB) => {
				if (!nodeA || !nodeB) {
					return 0;
				}
				const firstData = nodeA.data;
				const secondData = nodeB.data;
				let pathToCompare = '';
				if ('month' in firstData) {
					pathToCompare = 'month';
				} else if ('quarter' in firstData) {
					pathToCompare = 'quarter';
				} else {
					return firstData.year > secondData.year ? 1 : -1;
				}
				return firstData[pathToCompare] > secondData[pathToCompare] && firstData.year > secondData.year ? -1 : 1;
			},
		};
	}

	public getPeriodLabel(data: { year: number, month?: number, quarter?: number, title: string }): string {
		if ('month' in data) {
			return moment().year(data.year).month(data.month - 1).startOf('month').format('MMM-YYYY');
		} else if ('quarter' in data) {
			return `Q${data.title}`;
		}
		return data.title;
	}

	public getPeriod(dateInfo: { year: number, quarter?: number, month?: number }): CustomPeriod {
		const [yearOptions, quarterOptions, monthlyOptions] = this.getDateOptions();
		if ('month' in dateInfo) {
			return monthlyOptions.find(option => option.year == dateInfo.year && option.month == dateInfo.month - 1);
		} else if ('quarter' in dateInfo) {
			return quarterOptions.find(option => option.year == dateInfo.year && option.quarter == dateInfo.quarter - 1);
		} else {
			return yearOptions.find(option => option.year == dateInfo.year);
		}
	}

	public getDateOptions() {
		const allQuarterOptions: CustomPeriod[] = [];
		const allAnnuallyOptions: CustomPeriod[] = [];
		const allMonthlyOptions: CustomPeriod[] = [];
		const originDate = moment('2015-06-01');
		Array(moment().year() + 1 - originDate.year()).fill(0).forEach((_val, index) => {
			const year = originDate.year() + index;
			const [yearOptions, rawQuarterOptions, rawMonthlyOptions] = this.calculateDateIntervals(year);
			let monthlyOptions = rawMonthlyOptions;
			let quarterOptions = rawQuarterOptions;
			if (year == originDate.year()) {
				quarterOptions = rawQuarterOptions.slice(1);
				monthlyOptions = rawMonthlyOptions.slice(originDate.month());
			} else if (year == moment().year()) {
				const now = moment();
				quarterOptions = rawQuarterOptions.slice(0, now.quarter());
				monthlyOptions = rawMonthlyOptions.slice(0, now.month() + 1);
			}
			allAnnuallyOptions.push(yearOptions);
			allQuarterOptions.push(...quarterOptions);
			allMonthlyOptions.push(...monthlyOptions);
		});
		return [allAnnuallyOptions, allQuarterOptions, allMonthlyOptions];
	}

	private calculateDateIntervals(year: number): [CustomPeriod, CustomPeriod[], CustomPeriod[]] {
		const monthOptions = Array(12).fill(0).map((_val, index) => {
			const now = moment(`${year}-01-01`).month(index);
			return {
				label: now.format('MMM-YYYY'),
				year: now.year(),
				month: index,
				from: now.startOf('month').format('YYYY-MM-DD'),
				to: now.endOf('month').format('YYYY-MM-DD')
			};
		});
		const quarters = [0, 3, 6, 9].map(quarter => monthOptions.slice(quarter, quarter + 3))
			.map((monthsByQuarter, index) => {
				return {
					label: `Q${index + 1}-${year}`,
					from: monthsByQuarter[0].from,
					to: monthsByQuarter[2].to,
					quarter: index,
					year: year
				};
			});
		const yearOption = {
			label: year.toString(),
			year: year,
			from: monthOptions[0].from,
			to: monthOptions[11].to
		};
		return [yearOption, quarters, monthOptions];
	}
}
