import { Pipe, PipeTransform } from '@angular/core';
import { TodoStatus } from '@app/shared/model/constants/todo-status';

@Pipe({ name: 'todoLabel' })
export class TodoLabelPipe implements PipeTransform {

	public transform(todoStatus): string {
		switch (todoStatus) {
			case TodoStatus.Todo:
				return 'To do';
			case TodoStatus.InProgress:
				return 'In Progress';
			case TodoStatus.Done:
				return 'Done';
			case TodoStatus.Archived:
				return 'Archived';
			default:
				return 'To do';
		}
	}

}
