import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	template: `<a [routerLink]="params.link">{{params.value}}</a>`
})
export class AgLinkRendererComponent implements ICellRendererAngularComp {
	params: any;

	public agInit(params: any): void {
		this.params = params;
	}

	public refresh(): boolean {
		return false;
	}
}
