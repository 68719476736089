import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CurrencyHelperService {

	constructor(private currencyPipe: CurrencyPipe) {
	}

	public formatCurrency(value, digitsInfo = '1.2-2'): string {
		try {
			const parsedValue = this.parseCurrency(value);
			return this.currencyPipe.transform(parsedValue, 'USD', 'symbol', digitsInfo);
		} catch (error) {
			return null;
		}
	}

	public parseCurrency(value): number|null {
		if (value && value != '') {
			const matchArray = value.toString().replace(/[$,]/gi, '').match(/[0-9]+\.?[0-9]{0,2}/g);
			if (matchArray) {
				return parseFloat(matchArray[0]);
			}
		}
		return 0;
	}

}
