import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { InboxConversationListItemComponent } from '@app/sms-common/inbox-conversation-list/inbox-conversation-list-item/inbox-conversation-list-item.component';
import { InboxConversationListComponent } from '@app/sms-common/inbox-conversation-list/inbox-conversation-list.component';
import { SmsBadgeStoreService } from '@app/sms-common/notification/sms-badge-store.service';
import { UnreadSmsBadgeComponent } from '@app/sms-common/notification/unread-sms-badge.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		InfiniteScrollModule,
		ReactiveFormsModule,
	],
	declarations: [
		UnreadSmsBadgeComponent,
		InboxConversationListComponent,
		InboxConversationListItemComponent,
	],
	exports: [
		UnreadSmsBadgeComponent,
		InboxConversationListComponent,
		InboxConversationListItemComponent,
	],
	providers: [
		SmsBadgeStoreService,
	]
})

export class SmsCommonModule {}
