import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgottenPasswordComponent } from '@app/public/forgotten-password/forgotten-password.component';
import { LoginComponent } from '@app/public/login/login.component';
import { NewPasswordComponent } from '@app/public/new-password/new-password.component';
import { PublicRouteGuard } from '@app/public/public-route.guard';

const routes: Routes = [
	{ path: 'login', component: LoginComponent, data: { title: 'Sign in' }, canActivate: [PublicRouteGuard] },
	{
		path: 'password-recovery', canActivate: [PublicRouteGuard], children: [
			{ path: '', pathMatch: 'full', redirectTo: '/password-recovery/request' },
			{ path: 'request', component: ForgottenPasswordComponent, data: { title: 'Forgotten Password' } },
			{ path: 'reset', component: NewPasswordComponent }
		]
	},
	{ path: 'setup-password', component: NewPasswordComponent, data: { setAsNew: true } }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PublicRoutingModule {}
