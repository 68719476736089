import { AgPieSeriesOptions, ChartModel } from 'ag-grid-community';
import { PieGroupedTooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/pie-grouped-tooltip-theme.service';
import { CommonSavedChart } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/common-saved.chart';
import { PieTooltipCreator } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/pie-tooltip-creator';
import { ChartSettingsStorage } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/chart-settings.storage';

export class PieSavedChart extends CommonSavedChart<AgPieSeriesOptions> {

	constructor(storageKey: string, axesFormatter, tooltipTheme?) {
		super(storageKey);
		const tooltipCreator = new PieTooltipCreator();
		tooltipCreator.setAxesFormatter(axesFormatter);
		this.dashboardChartStorage = new ChartSettingsStorage(tooltipCreator);
		this.tooltipTheme = tooltipTheme ?? new PieGroupedTooltipThemeService();
	}

	public getTooltipTheme() {
		return this.tooltipTheme;
	}

	public saveChart(models: ChartModel[]) {
		this.dashboardChartStorage.saveChartModel(this.storageKey, models);
	}
}
