import { removeTodo, replaceTodo, setTodos, updateTodo } from '@app/core/todo-store/todo.actions';
import { Todo } from '@app/shared/model/types/todo';
import { createReducer, on } from '@ngrx/store';

export const TODO_FEATURE_KEY = 'todos';

export type TodoState = Partial<Todo>[];

export const initialState: TodoState = [];

export const todoReducer = createReducer(
	initialState,
	on(setTodos, (state, { todos }) => todos),
	on(replaceTodo, (state, props) => {
		const payload = props.payload;
		const updatedState = [...state];
		if (!payload) {
			return updatedState;
		}
		const todoIndex = updatedState.findIndex(todo => todo.id == payload.id);
		if (todoIndex != -1) {
			updatedState.splice(todoIndex, 1, payload);
		} else {
			updatedState.push(payload);
		}
		return updatedState;
	}),
	on(updateTodo, (state, props: any) => {
		const payload = props.payload;
		const updatedState = [...state];
		if (!payload) {
			return updatedState;
		}
		const todoIndex = updatedState.findIndex(todo => todo.id == payload.id);
		if (todoIndex != -1) {
			const todo = { ...updatedState[todoIndex], ...payload };
			updatedState.splice(todoIndex, 1, todo);
		}
		return updatedState;
	}),
	on(removeTodo, (state, props) => {
		const updatedState = [...state];
		const todoIndex = updatedState.findIndex(todo => todo.id == props.id);
		if (todoIndex != -1) {
			updatedState.splice(todoIndex, 1);
		}
		return updatedState;
	})
);
