<form [formGroup]="form" autocomplete="off">
	<div class="base-input__wrapper">
		<div class="label-wrapper">
			<label class="base-label" for="summary">Summary</label>
			<form-validation-label [control]="form.controls.summary"></form-validation-label>
		</div>
		<input class="base-input" id="summary" [formControl]="form.controls.summary" placeholder="Summary..."
			   data-cy="summary-input" [class.invalid]="form.controls.summary.invalid"/>
	</div>

	<div class="base-input__wrapper">
		<div class="label-wrapper">
			<label class="base-label" for="description">Description</label>
		</div>
		<textarea class="base-input" id="description" [formControl]="form.controls.description"
				  placeholder="Description..."
				  data-cy="description-input"></textarea>
	</div>

	<div class="base-input__wrapper">
		<div class="label-wrapper">
			<label class="base-label">Assignees</label>
			<div class="label-right">
				<a (click)="setCurrentUser()" data-cy="assignToMe-btn">Assign to me</a>
				<form-validation-label [control]="form.controls.assignees"></form-validation-label>
			</div>
		</div>
		<user-account-codelist-select dataCy="assignees"
									  placeholder="Select assignees..."
									  [classInvalid]="form.controls.assignees.invalid"
									  [path]="urlCodelistName.Employees"
									  [inputControl]="form.controls.assignees"
									  [multiple]="true"
									  [closeOnSelect]="false"
									  [isUrl]="true">
		</user-account-codelist-select>
	</div>

	<todo-due-date></todo-due-date>

	<codelist-multiselect dataCyTag="categories"
						  placeholder="Select categories..."
						  label="Categories"
						  codelistClass="todoCategory"
						  [selectControl]="form.controls.categories">
	</codelist-multiselect>
	<codelist-multiselect
		dataCyTag="labels"
		placeholder="Select todo labels..."
		label="Labels"
		codelistClass="todoLabel"
		[selectControl]="form.controls.labels">
	</codelist-multiselect>

	<todo-form-associations></todo-form-associations>

	<label *ngIf="showCreateSalesNoteCheckbox$ | async" class="base-checkbox__wrapper base-label">
		<input class="base-checkbox__wrapper__input"
			   type="checkbox"
			   data-cy="createSalesNote-checkbox"
			   formControlName="createSalesNote"/>
		<span class="base-checkbox__wrapper__checkmark"></span>
		Create Sales Note
	</label>

	<div class="label-wrapper">
		<label class="base-label">Comment</label>
	</div>
	<todo-comment-form></todo-comment-form>
</form>
