import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'daterange-picker',
	templateUrl: './daterange-picker.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DaterangePickerComponent implements OnInit {
	@Input() rangesEmitter: BehaviorSubject<{ labels: string[], values: any[] }>;
	@Input() bsRangeValue: Date[] = [];
	@ViewChild(BsDaterangepickerDirective, { static: true }) dp: BsDaterangepickerDirective;
	@Output() dateRangeSelected = new EventEmitter<Date[]>();

	static readonly DATE_FORMAT = 'MMM DD, YYYY';
	rangeValues: any = [];
	rangeLabels: string[];
	dateLabelEmitter = new Subject<string>();
	dateLabel$;
	btnClicked = false;
	bsConfig = {
		dateInputFormat: DaterangePickerComponent.DATE_FORMAT,
		containerClass: 'custom-datepicker-theme', customTodayClass: 'custom-today-class'
	};

	constructor(public renderer: Renderer2) {
	}

	public ngOnInit() {
		this.dateLabel$ = this.dateLabelEmitter.asObservable();
		if (this.rangesEmitter) {
			this.rangesEmitter
				.pipe(filter(ranges => ranges != null))
				.subscribe((ranges) => {
					this.rangeValues = ranges.values;
					this.rangeLabels = ranges.labels;
					this.buttonClick(this.rangeLabels[0]);
				});
		}
	}

	public createRanges() {
		if (!this.rangesEmitter) {
			return;
		}
		const datepicker = document.querySelector('div.bs-calendar-container');
		const wrapperDiv = this.renderer.createElement('div');
		const leftDiv = this.createInputDiv('Start Date', 0);
		const rightDiv = this.createInputDiv('End Date', 1);
		const divRanges = this.renderer.createElement('div');
		this.renderer.addClass(divRanges, 'range-list');
		for (let i = 0; i < this.rangeLabels.length; i++) {
			const button = this.createButton(this.rangeLabels[i]);
			this.renderer.appendChild(divRanges, button);
		}
		this.renderer.listen(wrapperDiv, 'keydown', (e) => {
			if (e.keyCode == 27 || e.keyCode == 13) {
				this.dp.hide();
			}
		});
		this.renderer.appendChild(wrapperDiv, leftDiv);
		this.renderer.appendChild(wrapperDiv, rightDiv);
		this.renderer.addClass(wrapperDiv, 'dateOptions');
		this.renderer.insertBefore(document.querySelector('div.bs-datepicker-container'), wrapperDiv, datepicker);
		this.renderer.appendChild(datepicker, divRanges);
	}

	private createButton(text: string): any {
		const button = this.renderer.createElement('button');
		const label = this.renderer.createText(text);
		this.renderer.addClass(button, 'btn-primary');
		this.renderer.addClass(button, 'btn');
		this.renderer.addClass(button, 'range-button');
		this.renderer.listen(button, 'click', () => {this.buttonClick(text);});
		this.renderer.appendChild(button, label);
		return button;
	}

	private createInputDiv(text: string, id: number): any {
		const label = this.renderer.createElement('label');
		const labelText = this.renderer.createText(text);
		const input = this.renderer.createElement('input');
		this.renderer.addClass(input, 'form-control');
		const div = this.renderer.createElement('div');
		this.renderer.listen(input, 'change', (event) => {
			this.inputValueChanged(event, id);
		});
		this.renderer.setAttribute(input, 'value', this.bsRangeValue[id]
			? moment(this.bsRangeValue[id]).format(DaterangePickerComponent.DATE_FORMAT) : 'n/a');
		this.renderer.appendChild(label, labelText);
		this.renderer.appendChild(div, label);
		this.renderer.appendChild(div, input);
		return div;
	}

	private inputValueChanged(event, inputId: number) {
		this.bsRangeValue[inputId] = new Date(event.target.value);
		this.dp.bsValueChange.emit(this.bsRangeValue);
		this.emitRangeValue();
		event.target.value = moment(new Date(event.target.value)).format(DaterangePickerComponent.DATE_FORMAT);
	}

	private buttonClick(text: string) {
		this.dateLabelEmitter.next(text);
		this.btnClicked = true;
		const rangeValue = this.rangeValues[this.rangeLabels.indexOf(text)];
		this.bsRangeValue = !_.isEmpty(rangeValue) ? [rangeValue.start.toDate(), rangeValue.end.toDate()] : [null, null];
		this.emitRangeValue();
		this.dp.hide();
	}

	public onValueChange(event) {
		if (!this.btnClicked) {
			this.dateLabelEmitter.next(moment(event[0])
				.format(DaterangePickerComponent.DATE_FORMAT) + ' - ' + moment(event[1]).format(DaterangePickerComponent.DATE_FORMAT));
		}
		if (this.bsRangeValue != event) {
			this.bsRangeValue = event;
			this.emitRangeValue();
		}
		this.btnClicked = false;
	}

	public emitRangeValue() {
		this.dateRangeSelected.emit(this.bsRangeValue);
	}
}
