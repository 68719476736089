<public-view [title]="title" [subtitle]="subtitle">
	<form class="login-view__form" role="form" data-cy="loginForm" [formGroup]="loginForm" autocomplete="off" (ngSubmit)="login()" #form="ngForm">
		<div class="login-view__form__input-wrapper" [ngClass]="{'error' : username.invalid && form.submitted}">
			<input placeholder="User email" type="text" name="username" formControlName="username" id="username" data-cy="email-input" autocomplete="off">
			<fa-icon [icon]="faIcons.exclamationCircle"></fa-icon>
			<div *ngIf="username.invalid && form.submitted" class="clue-info">
				<div *ngIf="username.errors.required">Username is required</div>
				<div *ngIf="username.errors.email">Must be an email.</div>
				<div *ngIf="username.errors.maxlength">Maximum length is 255.</div>
			</div>
		</div>
		<div class="login-view__form__input-wrapper" [ngClass]="{'error' : password.invalid && form.submitted}">
			<input placeholder="Password" name="password" formControlName="password" type="password" id="password" data-cy="password-input" autocomplete="off">
			<fa-icon [icon]="faIcons.exclamationCircle"></fa-icon>
			<div *ngIf="password.invalid && form.submitted" class="clue-info">
				<div *ngIf="password.errors.required">Password is required</div>
				<div *ngIf="password.errors.maxlength">Maximum length is 50.</div>
			</div>
		</div>
		<div class="login-view__form__input-wrapper" [ngClass]="{'error' : oneTimeToken.invalid && form.submitted}">
			<input name="one-time-token" formControlName="oneTimeToken" id="one-time-token" data-cy="oneTimeToken-input" placeholder="One-time token (if enabled)" type="text" autocomplete="off">
			<fa-icon [icon]="faIcons.infoCircle" class="clue"></fa-icon>
			<fa-icon [icon]="faIcons.exclamationCircle"></fa-icon>
			<div class="clue-info">
				<div>
					Please enter one-time token if you have 2 Factor Authentication enabled.
					<div *ngIf="oneTimeToken.invalid && form.submitted">Token is 6 characters long.</div>
				</div>
			</div>
		</div>
		<div class="login-view__form__forgot-password">
			<a [routerLink]="['/password-recovery/request']" data-cy="forgotPassword-btn">Forgot password?</a>
		</div>
		<button class="login-view__form__button animated" data-cy="submit-btn" (animationend)="shake = false" [ngClass]="{'shake': shake}" type="submit">
			Login
		</button>
	</form>
</public-view>
