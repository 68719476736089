<div class="app-header"  *ngrxLet="responsiveService.isMobile$; let isMobile" [ngClass]="{'mobile': isMobile}">
	<a [routerLink]="['/']" *ngIf="isMobile" class="rp-logo">
		<img src="assets/images/rp.png" alt="RP">
	</a>
	<smart-search *aclIsAllowed="'application.smart_search'" class="smart-search"></smart-search>
	<div class="app-header__menu">
		<ng-container *ngIf="!isMobile">
			<a *aclIsAllowed="'applicants.create'" [routerLink]="['/applicants/new']" class="base-button" data-cy="newApplicant-btn">New Applicant</a>
		</ng-container>
		<div *ngIf="googleApiLoaded$ | async" data-cy="openMap-btn" class="app-header__menu__map-btn"
			 (click)="openMapModal()">
			<div class="tooltip-wrapper" tooltip="Open Map" placement="left">
				<div class="tooltip-content">
					<fa-icon class="base-icon" [icon]="faIcons.map"></fa-icon>
				</div>
			</div>
		</div>

		<app-sms-notification *aclIsAllowed="'applicants.sms'"></app-sms-notification>
		<todo-sidebar *aclIsAllowed="'todo.todo_list'"></todo-sidebar>
		<!-- INFO Postponed for later phase -->
		<!-- <svg-icon [icon]="svgIconNames.Todos" class="svg-icon" *ngIf="isMobile"></svg-icon> -->
		<div class="btn-group" dropdown placement="bottom right">
			<div dropdownToggle class="app-header__menu__item dropdown-toggle" data-cy="app-header-user-options">
				<avatar [user]="user" [isActive]="true" [showTooltip]="!isMobile"></avatar>
			</div>
			<ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
			  <li class="menu-item" [routerLink]="['/profile/me']">
				<fa-icon [icon]="faIcons.userIcon" class="base-icon text-color"></fa-icon>  My Profile
			  </li>
			  <li class="menu-item" (click)="authService.logout()" data-cy="logout-btn">
				<svg-icon [icon]="svgIconNames.LogOut" class="base-icon text-color"></svg-icon> Log Out
			  </li>
			</ul>
		</div>
	</div>
</div>
