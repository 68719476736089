import { ChangeDetectionStrategy, Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AclService } from '@app/core/security/acl.service';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { FormScopeService } from '@app/shared/ga-components/services/form-scope.service';
import { MetadataProviderService } from '@app/shared/ga-components/services/metadata-provider.service';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { FieldMetadata } from '@app/shared/ga-components/utils/field-metadata';
import { IEditor } from '@app/shared/ga-components/utils/IEditor';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';
import { UniqueFieldValidator } from '@app/shared/ga-components/utils/unique-field-validator';
import { UniqueFieldValidatorRequest } from '@app/shared/ga-components/utils/unique-field-validator-request';
import * as _ from 'lodash';

@Component({
	selector: 'ga-editor',
	template: `
		<ng-container #vc></ng-container>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */
export class GaEditorComponent implements OnInit, OnDestroy {
	@Input() componentMetadata: ComponentMetadata;
	@Input() fieldMetadata: FieldMetadata;
	@Input() customInputClass: string;
	@Input('metadata') metadataOverride: MetadataDecorator;
	@Input('field') fieldPath: string;

	@Output('onFocus') onFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output('onStatusChange') onStatusChange: EventEmitter<UntypedFormControl> = new EventEmitter<UntypedFormControl>();
	@Output() onValueChange: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('vc', { read: ViewContainerRef, static: true }) viewContainer;

	editorComponent: ComponentRef<IEditor>;
	entityChangesEmitterSubscription;
	wasAttached = false;

	constructor(
		public acl: AclService,
		public entityScope: EntityScopeService,
		public metadataProvider: MetadataProviderService,
		public formScope: FormScopeService,
		private uniqueValidator: UniqueFieldValidator
	) {
	}

	public ngOnInit(): void {
		//TODO find a way how to optimize this when same gaValue component is rendered multiple times
		if (!this.componentMetadata) {
			this.componentMetadata =
				this.metadataProvider.getComponentMetadata(this.entityScope.entityClass, this.fieldPath, this.metadataOverride);
		}
		if (!this.fieldMetadata) {
			this.fieldMetadata = this.metadataProvider.getFieldMetadata(this.entityScope.entityClass, this.fieldPath);
		}

		if (this.fieldMetadata) {
			if (!this.acl.isReadable(this.fieldMetadata.fieldName, this.fieldMetadata.entity)) {
				return;
			}
		}

		const control = this.formScope.registerField(this.componentMetadata);
		control.valueChanges.subscribe((data) => {
			this.onValueChange.emit(data);
		});
		control.statusChanges.subscribe(() => {
			this.onStatusChange.next(control);
		});
		this.onFocus.subscribe(() => {
			control.markAsTouched({ onlySelf: true });
			this.onStatusChange.next(control);
		});
		const newlyAddedFields = this.entityScope.addField(this.componentMetadata.dependencies);

		if (newlyAddedFields.length === 0 || (newlyAddedFields.length === 1 && newlyAddedFields[0] === this.fieldMetadata.fieldName)) {
			this.attach();
		}

		this.entityChangesEmitterSubscription = this.entityScope.emitter.subscribe((data) => {
			const value = this.componentMetadata.valueGetter(this.entityScope.entity);
			if (!this.wasAttached) {
				this.attach();
			} else if (this.editorComponent.instance.isPristine() || this.componentMetadata.allowUpdateRewrite){
				this.editorComponent.instance.update(value, data);
			}
			if (this.componentMetadata.asyncValidators.length > 0 && _.get(data, this.componentMetadata.path, null)) {
				const request = new UniqueFieldValidatorRequest(this.entityScope.entity.id,
					null, _.get(data, this.componentMetadata.path, null), this.entityScope.entityClass);
				this.uniqueValidator.addRequest(request);
			}
			this.editorComponent.changeDetectorRef.detectChanges();
		});
	}

	public ngOnDestroy(): void {
		this.formScope.getFormGroup(this.componentMetadata).removeControl(this.componentMetadata.fieldName);

		if (this.entityChangesEmitterSubscription) {
			this.entityChangesEmitterSubscription.unsubscribe();
		}
	}

	public attach() {
		if (this.componentMetadata.editor) {
			this.viewContainer.clear();
			this.editorComponent = this.viewContainer.createComponent(this.componentMetadata.editor);
			this.editorComponent.instance.formGroup = this.formScope.getFormGroup(this.componentMetadata);
			this.editorComponent.instance.componentMetadata = this.componentMetadata;
			this.editorComponent.instance.params = this.componentMetadata.editorParams;
			this.editorComponent.instance.onFocusEmitter = this.onFocus;
			this.editorComponent.instance.cssClass = this.customInputClass;

			const value = _.clone(this.componentMetadata.valueGetter(this.entityScope.entity));
			this.editorComponent.instance.init(value, this.entityScope.entity);

			this.viewContainer.insert(this.editorComponent.hostView);
			this.wasAttached = true;
		}

	}

	public updateView(value, data): void {
		this.editorComponent.instance.update(value, data);
		this.editorComponent.changeDetectorRef.detectChanges();
	}

	public reset(): void {
		if (this.editorComponent) {
			this.editorComponent.instance.reset();
			this.editorComponent.changeDetectorRef.detectChanges();
		}
	}
}
