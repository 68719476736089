export enum QcColumnIds {
	ContractQC = 'contract_qc',
	DocsIn = 'docs_in',
	Medical = 'medical',
	CourtReporting = 'court_reporting',
	Closing = 'closing',
	Mri = 'mri',
	Compliance = 'compliance'
}

export const QcColumnNames = {
	[QcColumnIds.ContractQC]: 'Contract QC',
	[QcColumnIds.Medical]: 'Medical',
	[QcColumnIds.Compliance]: 'Compliance',
	[QcColumnIds.Mri]: 'MRI',
	[QcColumnIds.CourtReporting]: 'Court Reporting',
	[QcColumnIds.Closing]: 'Closing',
	[QcColumnIds.DocsIn]: 'Docs In (PreSet/Case Cost)',
}
