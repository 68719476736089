import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'change-password',
	templateUrl: 'change-password.component.html',
	styleUrls: ['change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
	@Input() userId;

	passwordForm: FormGroup<{
		newPassword: FormControl<string | null>,
		confirmPassword: FormControl<string | null>
	}>;

	constructor(
		private http: HttpClient,
		private flashMessage: FlashMessageService,
		private bsModalRef: BsModalRef
	) {
	}

	passwordMatchValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
		const newPassword = control.get('newPassword');
		const confirmPassword = control.get('confirmPassword');
		return newPassword.value != confirmPassword.value ? { 'passwordMismatch': true } : null;
	};

	public ngOnInit(): void {
		this.passwordForm = new FormGroup({
			newPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(255)]),
			confirmPassword: new FormControl('', Validators.required)
		}, { validators: this.passwordMatchValidator });
	}

	public save(): void {
		const password = this.passwordForm.value;
		this.http.patch(`/api/users/${this.userId}/password`, { password: password.newPassword })
			.subscribe({
				next: () => {
					this.flashMessage.showMessage(FlashMessageType.Success, 'Password was successfully changed.');
					if (this.bsModalRef) {
						this.bsModalRef.hide();
					}
				}, error: (response: unknown) => {
					const errorResponse = response as { status: number, data: { error: string } };
					if (errorResponse.status === 400) {
						if (errorResponse.data.error) {
							this.flashMessage.showMessage(FlashMessageType.Danger, errorResponse.data.error);
						}
					}
				}
			});

	}
}
