export enum ApprovalStatus {
	Pending = 0,
	Approved = 1,
	Funded = 2,
	Rejected = 3,
	Settled = 4,
	Consult = 5,
}

export const approvalStatusClass = [
	{ id: ApprovalStatus.Pending, class: 'pending' },
	{ id: ApprovalStatus.Approved, class: 'approved' },
	{ id: ApprovalStatus.Funded, class: 'funded' },
	{ id: ApprovalStatus.Rejected, class: 'rejected' },
	{ id: ApprovalStatus.Settled, class: 'settled' },
	{ id: ApprovalStatus.Consult, class: 'consult' },
];

export class ApprovalStatusHelper {

	public static getClassByStatus(approvalStatus: ApprovalStatus) {
		return approvalStatus ? approvalStatusClass.find((status) => status.id === approvalStatus ).class : '';
	}
}
