import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GaCellComponent } from '@app/ga-grid/components/ga-cell.component';
import { GaEditorCellComponent } from '@app/ga-grid/components/ga-editor-cell.component';
import { AgGridColumnStorageService } from '@app/ga-grid/services/ag-grid-storage-services/ag-grid-column-storage.service';
import { AgGridFilterStorageService } from '@app/ga-grid/services/ag-grid-storage-services/ag-grid-filter-storage.service';
import { GridCodelistFilterService } from '@app/ga-grid/services/grid-codelist-filter.service';
import { GaComponentsModule } from '@app/shared/ga-components/ga-components.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridModule } from 'ag-grid-angular';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
	imports: [
		CommonModule,
		GaComponentsModule,
		AgGridModule,
		TooltipModule,
		PipesModule,
		FontAwesomeModule
	],
	declarations: [
		GaCellComponent,
		GaEditorCellComponent,
	],
	providers: [
		AgGridFilterStorageService,
		GridCodelistFilterService,
		AgGridColumnStorageService
	]
})
export class GaGridModule {
}
