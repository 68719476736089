import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormScopeProviderDirective } from '@app/shared/ga-components/components/group/form-scope-provider.directive.abstract';
import { FormScopeSavingProgress } from '@app/shared/ga-components/utils/form-scope-saving-progress';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
	selector: '[gaAnimatedSaveButton]',
	providers: [SpinnerService]
})
export class GaAnimatedSaveButtonDirective implements OnInit, OnDestroy {
	@Input('gaAnimatedSaveButton') gaGroup: FormScopeProviderDirective;

	private wasClicked = false;
	private originalContent: string;
	private destroy$ = new Subject<void>();

	constructor(private spinnerService: SpinnerService) {
	}

	@HostListener('click', ['$event'])
	public onClick() {
		this.wasClicked = true;
	}

	public ngOnInit(): void {
		this.originalContent = this.spinnerService.getElementContent();

		this.gaGroup.savingProgress$.pipe(takeUntil(this.destroy$))
			.subscribe((currentProgress: FormScopeSavingProgress) => {
				if (currentProgress === FormScopeSavingProgress.OnSubmit) {
					if (this.wasClicked) {
						this.spinnerService.showSpinner();
						this.wasClicked = false;
					}
					this.spinnerService.disableElement();
				}
				const possibleStates = [
					FormScopeSavingProgress.OnInvalid,
					FormScopeSavingProgress.OnDisabled,
					FormScopeSavingProgress.OnSuccess,
					FormScopeSavingProgress.OnError
				];
				if (possibleStates.includes(currentProgress)) {
					this.spinnerService.hideSpinner(this.originalContent);
					this.spinnerService.enableElement();
				}
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
