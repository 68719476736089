import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

type VisibilityState = {
	show: boolean
}

@Injectable()
export class GhostElementService extends ComponentStore<VisibilityState> {

	readonly showData = this.updater<boolean>((state, visibility) => {
		return {
			show: visibility
		}
	});

	readonly show$ = this.select((state) => state.show);

	constructor() {
		super({ show: false });
	}

}
