import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { TodoTask } from '@app/shared/model/types/todo';
import { PersistableGaGridComponent } from '@app/shared/persistable-ga-grid-component/persistable-ga-grid-component';
import { TodosListService } from '@app/todo/list/todos-list.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, ColGroupDef, GridReadyEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Component({
	selector: 'todos-list',
	templateUrl: './todos-list.component.html'
})
export class TodosListComponent extends PersistableGaGridComponent implements OnInit {
	@ViewChild('agGrid') agGrid: AgGridAngular;
	@HostBinding('class.view') hostClass = true;

	todos$: Observable<TodoTask[]>;
	columnDefinitions: (ColGroupDef | ColDef)[];

	gridOptions;
	readonly rowClassRules = {
		'status--todo': "data && data.status === 'todo'",
		'status--in-progress': "data && data.status === 'in-progress'",
		'status--done': "data && data.status === 'done'",
		'status--archived': "data && data.status === 'archived'",
	};
	readonly svgIconNames = SvgIconName;

	constructor(
		private acl: AclService,
		private agUtils: AgGridUtilsService,
		private todosListService: TodosListService) {
		super();
	}

	public ngOnInit(): void {
		this.todos$ = this.todosListService.getTodos();

		this.gridOptions = this.agUtils.getGridOptions({
			suppressExcelExport: !this.acl.isAllowed('export.todo_list_archive'),
			defaultExcelExportParams: {
				fileName: 'Todos Archive Export'
			},
		});

		this.columnDefinitions = [
			...this.todosListService.getTodosColDef()
		];
	}

	public onGridReady(params: GridReadyEvent): void {
		params.api.sizeColumnsToFit();
	}
}
