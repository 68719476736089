<div class="todo-whiteboard">
	<div class="todo-whiteboard__header">
		<div class="todo-whiteboard__header__filter-wrapper">
			<div class="todo-whiteboard__header__search-filter">
				<input type="text" name="search" placeholder="Search..." [formControl]="searchInput" (keydown.esc)="clearSearchInput()" />
				<ng-template #searchIcon>
					<fa-icon [icon]="faIcons.search"></fa-icon>
				</ng-template>
				<fa-icon [icon]="faIcons.times" *ngIf="searchInput.value; else searchIcon"
						 (click)="clearSearchInput()"></fa-icon>
			</div>
			<user-filter
				[showUnassignedFilter]="false"
				(onClickUser)="filterByAssigneeUser($event)"
				(onClickCancel)="cancelAssigneesFilter()"
				[users]="allAssignees$ | async"
			>
			</user-filter>
			<div class="category-filter" *ngrxLet="selectedCategories$; let selectedCategories"
				 (clickOutside)="closeFilterCategoryList()">
				<ng-container *ngrxLet="showFilterCategoryList$; let showFilterCategoryList">
					<div class="category-filter__box" [class.active]="showFilterCategoryList"
						 (click)="toggleFilterCategoryList()">
						<div class="category-filter__box__wrapper"
							 *ngIf="selectedCategories.length > 0; else noFilteredCategory">
							<div *ngFor="let category of selectedCategories" class="category-filter__box__label"
								 [style.color]="category.colorHex">{{ category.title | truncate:0:15:'...' }}</div>
						</div>
						<ng-template #noFilteredCategory>Category filter</ng-template>
						<fa-icon [icon]="faIcons.chevronDown"></fa-icon>
					</div>
					<todo-category-list
						*ngIf="showFilterCategoryList"
						@fadeUpDown
						(selectCategory)="filterByCategory($event)"
						[activeCategories]="selectedCategories"
						class="quick-list left">
					</todo-category-list>
				</ng-container>
			</div>
		</div>
		<button (click)="openAddTodoModal()" data-cy="addTodo-btn"><fa-icon [icon]="faIcons.plus"></fa-icon> Add Todo</button>
	</div>
	<div class="todo-whiteboard__board">
		<todo-whiteboard-column (onRemove)="removeTodo($event)"
								[config]="configs.todo" [tag]="todoStatus.Todo"
								[todos]="todosTodo$ | async"></todo-whiteboard-column>
		<todo-whiteboard-column (onRemove)="removeTodo($event)"
								[config]="configs.inProgress" [tag]="todoStatus.InProgress"
								[todos]="todosInProgress$ | async"></todo-whiteboard-column>
		<todo-whiteboard-column (onRemove)="removeTodo($event)"
								[config]="configs.done" [tag]="todoStatus.Done"
								[todos]="todosDone$ | async"></todo-whiteboard-column>
	</div>
</div>
