<div class="dash-panel ibox">
	<collapsible-item>
		<h4 header-left>Sales Deals</h4>
		<div class="ibox-content">
			<ag-grid-angular
				class="ag-theme-balham"
				[getRowHeight]="getRowHeight"
				[gridOptions]="gridOptions"
				[rowData]="deals$ | async"
				[columnDefs]="columnDefinitions"
			></ag-grid-angular>
		</div>
	</collapsible-item>
</div>
