<div class="animated alert flash-message alert-{{ message.type }} fadeInRightBig" [ngClass]="{ 'alert-dismissible': message.showCloseBtn }" data-cy="flashMessage-element">
	<button *ngIf="message.showCloseBtn" type="button" class="close" aria-label="Close" (click)="close()" data-cy="flashMessageClose-btn"><span aria-hidden="true">&times;</span></button>
	<p data-cy="flashMessage-content"><strong class="alert__text">{{ message.body }}</strong></p>
	<div class="alert__count">
		<span *ngIf="count > 1">{{ count }}</span>
		<fa-icon [icon]="faIcons.exclamationCircle" *ngIf="count === 1 && message.type === flashMessageType.Danger"></fa-icon>
		<fa-icon [icon]="faIcons.exclamationTriangle" *ngIf="count === 1 && message.type === flashMessageType.Warning"></fa-icon>
		<fa-icon [icon]="faIcons.info" *ngIf="count === 1 && message.type === flashMessageType.Info"></fa-icon>
		<fa-icon [icon]="faIcons.check" *ngIf="count === 1 && message.type === flashMessageType.Success"></fa-icon>
	</div>
</div>
