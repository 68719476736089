import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { Identity } from '@app/core/auth/types/identity';
import { GoogleApiService } from '@app/core/services/google-api.service';
import { ResponsiveService } from '@app/core/startup/responsive.service';
import { AvatarService } from '@app/shared/avatar/avatar.service';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { MedicalMarkersMapComponent } from '@app/widgets/medical-markers-map/medical-markers-map.component';
import { faMap, faUser } from '@fortawesome/free-solid-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-header',
	templateUrl: './app-header.component.html',
	styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
	user: Identity;
	googleApiLoaded$: Observable<boolean>;

	readonly svgIconNames = SvgIconName;

	readonly faIcons = {
		userIcon: faUser,
		map: faMap
	};

	constructor(
		public authService: AuthService,
		public responsiveService: ResponsiveService,
		private googleApiService: GoogleApiService,
		private modalService: BsModalService,
		private avatarService: AvatarService
	) {
	}

	public ngOnInit(): void {
		this.user = this.authService.getCurrentUser();
		this.avatarService.fetchUserAvatar(this.user.id);
		this.googleApiLoaded$ = this.googleApiService.getGoogleApiLoaded();
	}

	public openMapModal(): void {
		this.modalService.show(MedicalMarkersMapComponent, { class: 'modal-xl medical-markers-map centered-modal-xl' });
	}
}
