import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { IdentityProviderService } from '@app/core/auth/identity-provider.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PublicRouteGuard {

	constructor(
		private identityProvider: IdentityProviderService,
		private router: Router,
	) {
	}

	public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.identityProvider.getIdentity().isAuthenticated) {
			return this.router.parseUrl('/');
		}

		return true;
	}
}
