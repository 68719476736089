import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '@app/core/unleash/feature-flag.service';
import { FeatureFlags } from '@app/core/unleash/feature.flags';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Directive({
	selector: '[rpFeatureEnabled]',
	standalone: true
})
export class FeatureEnabledDirective implements OnDestroy {

	private destroy$ = new Subject<void>();

	constructor(
		private featureFlagService: FeatureFlagService,
		private templateRef: TemplateRef<unknown>,
		private viewContainer: ViewContainerRef
	) {}

	@Input() set rpFeatureEnabled(feature: FeatureFlags) {
		this.destroy$.next();
		this.featureFlagService.getFeatureAvailabilityUpdates(feature.toString())
			.pipe(
				distinctUntilChanged(),
				takeUntil(this.destroy$)
			).subscribe(isEnabled => {
				this.updateView(isEnabled);
		})
	}

	private updateView(isEnabled: boolean): void {
		if (isEnabled) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
