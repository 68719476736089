import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgActionButtonsRendererComponent } from '@app/shared/ag-grid-utils/ag-action-buttons-renderer/ag-action-buttons-renderer.component';
import { AgBsDatePickerEditorComponent } from '@app/shared/ag-grid-utils/ag-bs-date-picker-editor/ag-bs-date-picker-editor.component';
import { AgGridCustomLoaderComponent } from '@app/shared/ag-grid-utils/ag-grid-custom-loader.component';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { AgGroupRendererComponent } from '@app/shared/ag-grid-utils/ag-group-renderer/ag-group-renderer.component';
import { AgLinkRendererComponent } from '@app/shared/ag-grid-utils/ag-link-renderer/ag-link-renderer.component';
import { AgMedicalOpportunityFlagRendererComponent } from '@app/shared/ag-grid-utils/ag-medical-opportunity-flag-renderer/ag-medical-opportunity-flag-renderer.component';
import { AgNgSelectEditorComponent } from '@app/shared/ag-grid-utils/ag-ng-select-editor/ag-ng-select-editor.component';
import { NumericEditorComponent } from '@app/shared/ag-grid-utils/ag-number-editor/ag-number-editor.component';
import { AgServerSideStatusBarComponent } from '@app/shared/ag-grid-utils/ag-server-side-status-bar/ag-server-side-status-bar.component';
import { AgToggleRendererComponent } from '@app/shared/ag-grid-utils/ag-toggle-renderer/ag-toggle-renderer.component';
import { ConfirmationDialogModule } from '@app/shared/confirmation-dialog/confirmation-dialog.module';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { GaComponentsModule } from '@app/shared/ga-components/ga-components.module';
import { MedicalOpportunityFlagColorPipe } from '@app/widgets/pipes/medical-opportunity-flag-color.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { LetModule } from '@ngrx/component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';
import { AgColorEditorComponent } from './ag-color-editor/ag-color-editor.component';

@NgModule({
	imports: [
		CommonModule,
		BsDatepickerModule,
		NgSelectModule,
		ReactiveFormsModule,
		GaComponentsModule,
		ConfirmationDialogModule,
		FontAwesomeModule,
		ColorPickerModule,
		FontAwesomeModule,
		LetModule,
		DirectivesModule,
		BsDropdownModule,
		RouterModule,
		MedicalOpportunityFlagColorPipe,
		TooltipModule,
		FormsModule,
	],
	declarations: [
		AgGridCustomLoaderComponent,
		AgBsDatePickerEditorComponent,
		AgNgSelectEditorComponent,
		AgServerSideStatusBarComponent,
		AgToggleRendererComponent,
		AgGroupRendererComponent,
		AgLinkRendererComponent,
		AgActionButtonsRendererComponent,
		AgColorEditorComponent,
		AgMedicalOpportunityFlagRendererComponent,
		NumericEditorComponent,
	],
	exports: [
		AgGridCustomLoaderComponent,
		AgBsDatePickerEditorComponent,
		AgNgSelectEditorComponent,
		AgServerSideStatusBarComponent,
		AgToggleRendererComponent,
		AgGroupRendererComponent,
		AgLinkRendererComponent,
		AgActionButtonsRendererComponent,
		AgMedicalOpportunityFlagRendererComponent
	],
	providers: [
		AgGridUtilsService
	]
})
export class AgGridUtilsModule {
}
