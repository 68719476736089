import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Hook } from '@app/shared/ga-components/utils/hook';
import { isString } from '@app/shared/general-helper';
import { environment } from '@env/environment';
import { Observable, tap } from 'rxjs';

interface FieldListItem {
	fieldName: string;
}

@Injectable({
	providedIn: 'root'
})
export class GaApiLink {

	private counter = 0;

	constructor(
		private http: HttpClient,
	) {
	}

	public retrieve<T = any>(
		entityClass: string,
		fieldList: string[] | { fieldName: string }[],
		filter,
		sortBy = {},
		extraOptions = {}
	): Observable<T[]> {
		const normalizedFieldList = [];
		fieldList.forEach((item: string | FieldListItem) => {
			if (isString(item) && item.toString().includes('->') === false) {
				normalizedFieldList.push(item);
			} else if (isString(item)) {
				let parsedFieldList = item.toString().split(/->|,/);
				const mainEntity = parsedFieldList.splice(0, 1)[0].trim();
				parsedFieldList = parsedFieldList.map(field => {
					return mainEntity == '*' ? field.trim() : `${mainEntity}.${field.trim()}`;
				});
				normalizedFieldList.push(...parsedFieldList);
			} else {
				normalizedFieldList.push((<FieldListItem>item).fieldName);
			}
		});

		const query: any = {};
		query[entityClass] = normalizedFieldList;
		query.$conditions = filter;
		query.$sortBy = sortBy;
		const params = new HttpParams().set('query', JSON.stringify(query));

		if (environment.production) {
			return this.http.get<T[]>('/api/jql', { ...extraOptions, params });
		}

		const timerId = 'JQL timer #' + (this.counter++).toString();
		console.time(timerId);
		return this.http.get<T[]>('/api/jql', { ...extraOptions, params }).pipe(tap((val) => {
			console.group('JQL retrieve', entityClass);
			console.timeEnd(timerId);
			console.info('fieldlist', normalizedFieldList);
			console.info('filter', filter);
			console.info('result', val);
			console.groupCollapsed('trace');
			console.trace();
			console.groupEnd();
			console.groupEnd();
		}));
	}

	public update<T = any>(
		entityClass,
		entity,
		data,
		dependencies,
		config = {},
		hooks: Hook[] = [],
		responseFieldsOverride: string[] = []
	) {
		const payload = {
			entityClass: entityClass,
			id: Array.isArray(entity) ? entity : (entity.id || data.id),
			data: data,
			dependencies: dependencies,
			responseFieldsOverride: responseFieldsOverride,
			config: config,
			hooks: hooks,
		};

		return this.http.patch<T>('/api/jql', payload);
	}

	public create<T>(entityClass, data, dependencies, config = {}, hooks: Hook[] = [], responseFieldsOverride: string[] = []) {
		const payload = {
			entityClass: entityClass,
			data: data,
			dependencies: dependencies,
			responseFieldsOverride: responseFieldsOverride,
			config: config,
			hooks: hooks,
		};

		return this.http.post<T>('/api/jql', payload);
	}

	public delete(entityClass, id, hooks: Hook[] = []) {
		let httpParams = new HttpParams()
			.set('entityClass', entityClass)
			.set('id', id);
		hooks.forEach(hook => {
			httpParams = httpParams.append('hooks[]', JSON.stringify(hook));
		});
		return this.http.delete('/api/jql', { params: httpParams });
	}
}

export interface ApiLinkMessage {
	type: string;
	resource: string;
	data: any;
}
