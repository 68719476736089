import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ConfirmClickService } from '@app/shared/confirmation-dialog/confirm-click.service';
import { CustomModalDismissReason } from '@app/shared/model/constants/modal-dismiss-reason';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-loading-button',
	templateUrl: './loading-button.component.html',
	styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent implements OnDestroy {
	@Input() classes: string[];
	@Input() disabled: boolean;
	@Input() dataCy: string;
	@Input() confirmClickSubtitleText: string;
	@Input() isConfirmModal = false;
	@Output() onBtnClick = new EventEmitter<() => void>();
	isLoading: boolean;

	readonly faSpinner = faSpinner;
	private destroy$ = new Subject<void>();

	constructor(
		private confirmClickService: ConfirmClickService,
		private modalService: BsModalService,
	) {}

	public handleClick(): void {
		if (this.isConfirmModal) {
			this.showModalWindow();
		} else {
			this.handleLoadingBtnState();
		}
	}

	public handleLoadingBtnState(): void {
		this.isLoading = true;
		this.onBtnClick.emit(() => this.disableLoading());
	}

	public disableLoading(): void {
		this.isLoading = false;
	}

	public showModalWindow(): void {
		const modal: BsModalRef = this.confirmClickService.createModalInstance(null, this.confirmClickSubtitleText);
		modal.content.choice$
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				if (result) {
					this.handleLoadingBtnState();
				}
				this.modalService.setDismissReason(CustomModalDismissReason.ConfirmDialogClosed);
				modal.hide();
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
