import { Pipe, PipeTransform } from '@angular/core';
import { CodeListItem } from '@app/doctors/detail/doctor-detail-main-section/doctor-detail-main-section.model';
import { EntityId } from '@app/shared/model/types/entity-id';

@Pipe({name: 'mapToIds'})
export class MapToIdsPipe implements PipeTransform {

	public transform(value: CodeListItem[]): EntityId[] {
		if (!value) {
			return [];
		}
		return value.map(item => item.id);
	}
}
