export class WebsocketMessage {
	type: string;
	data: any;
}

export class DataWatchMessage {
	action: string;
	changeSet: ChangeSet;
	meta: {
		origin: string,
		entityRevision: string
		isExtraGenerated: boolean
		storeRevision: string
		storeToken: string
	};
}

export class ChangeSet {
	entity: string;
	id: string;
	createdBy: string;
}

export class EntityChangeSet extends ChangeSet {
	oldValues: any;
	newValues: any;
}

export class CollectionChangeSet extends ChangeSet {
	deletions: any[];
	insertions: any[];
	targetEntity: string;
	collectionProperty: string;
}

export enum StoreOperationType {
	Insert = 'insert',
	Update = 'update',
	Delete = 'delete',
	CollectionUpdate = 'collection-update',
	All = 'all'
}

export class Persistable {
	id: string;
	revision: string;
}
