import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RevisionsService } from '@app/core/revisions/revisions.service';
import { RevisionType } from '@app/core/revisions/types/revision-type';
import { BehaviorSubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
/* eslint-disable no-console */
export class ServerFieldsDefinitionService {

	definitions = new BehaviorSubject({});

	constructor(private http: HttpClient,
				private revisionsService: RevisionsService) {}

	public initFieldDefinitions(revision: string) {
		const localDefinitions = JSON.parse(localStorage.getItem('serverFieldsDefinition'));
		const revisionIsOk = this.revisionsService.isRevisionUpToDate(RevisionType.FieldDefinitionsRevision, revision);
		console.log(`* Fields definition revision is ${revisionIsOk ? '' : 'not '}ok.`);

		if (!localDefinitions || !revisionIsOk) {
			return this.http.get('/api/security/field-definitions', { observe: 'response' }).pipe(
				tap((response) => {
					console.log('* Fetching definitions from a server');
					this.definitions.next(response.body);
					localStorage.setItem('serverFieldsDefinition', JSON.stringify(response.body));
					this.revisionsService.storeRevision(RevisionType.FieldDefinitionsRevision, revision);
				})
			);
		}

		console.log('* Fetching definitions from a local storage');
		return of(localDefinitions).pipe(
			tap((data) => this.definitions.next(data))
		);
	}
}
