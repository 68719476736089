import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, ComponentRef, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { GaSimpleRendererComponent } from '@app/shared/ga-components/components/renderers/ga-simple-renderer.component';
import { GaTextRendererComponent } from '@app/shared/ga-components/components/renderers/ga-text-renderer.component';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IRenderer } from '@app/shared/ga-components/utils/IRenderer';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
	selector: 'ga-cell',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container #vc></ng-container>
	`
})
export class GaCellComponent implements AgRendererComponent, OnInit {
	@ViewChild('vc', { read: ViewContainerRef, static: true }) viewContainer;

	params: any;
	componentMetadata: ComponentMetadata;
	rendererComponent: ComponentRef<IRenderer>;

	constructor(
		public factoryResolver: ComponentFactoryResolver,
	) {}

	public refresh(): boolean {
		return false;
	}

	public agInit(params: any): void {
		this.params = params;
		this.componentMetadata = params.componentMetadata;
	}

	public ngOnInit(): void {
		let renderer: Type<IRenderer> = this.componentMetadata.renderer || GaTextRendererComponent;
		if (this.params.colDef.cellRenderer == 'agGroupCellRenderer') {
			renderer = GaSimpleRendererComponent;
		} else if (this.params.node.group) {
			return;
		}
		const factory = this.factoryResolver.resolveComponentFactory(renderer);
		this.rendererComponent = factory.create(this.viewContainer.parentInjector);
		this.rendererComponent.instance.componentMetadata = this.componentMetadata;
		this.rendererComponent.instance.params = this.componentMetadata.rendererParams;
		this.updateRenderer(this.params.value);
		this.viewContainer.insert(this.rendererComponent.hostView);
	}

	public updateRenderer(newValue) {
		this.rendererComponent.instance.value = newValue;
		this.rendererComponent.instance.dataObj = this.params.data;
		this.rendererComponent.instance.redraw();
		this.rendererComponent.changeDetectorRef.detectChanges();
	}
}
