import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { AclService } from '@app/core/security/acl.service';
import { UseCase } from '@app/core/security/use-case';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AclGuard {

	constructor(
		private aclService: AclService,
		private flashMessage: FlashMessageService
	) {
	}

	public canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		const security: UseCase = next.data.security;

		if (!security || !this.aclService.isAllowedSome(security.useCases)) {
			this.flashMessage.showMessage(FlashMessageType.Danger, 'You are not allowed to access this section.');

			return false;
		}

		return true;
	}
}
