import { Injectable } from '@angular/core';
import { GaDecoratorInterface } from '@app/shared/ga-components/utils/ga-decorator-interface';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';
import * as moment from 'moment';

@Injectable()
export class WeeksAgoDecorator implements GaDecoratorInterface {

	public create(): MetadataDecorator {
		return {
			valueFormatter: (value) => {
				if (value) {
					return moment().diff(value, 'weeks') + ' weeks';
				}

				return 'n/a';
			}
		};
	}
}
