import { Identity } from '@app/core/auth/types/identity';

export class LoggedUser implements Identity {
	id;
	isAuthenticated = false;
	firstName;
	lastName;
	role;
	roleType;
	medicalAccountExecutive;
	aeFilterActive;
	salesRepresentative;
	fundingConsultant;

	private constructor(id: number) {
		this.id = id;
	}

	public static fromJwtToken(data: JwtData) {
		const loggedUser = new LoggedUser(+data.uid);
		this.setupCommonFields(loggedUser, data);

		return loggedUser;
	}

	public getFullName(): string {
		return `${this.firstName} ${this.lastName}`;
	}

	public isAccountExecutive(): boolean {
		return this.medicalAccountExecutive;
	}

	public isSalesPerson(): boolean {
		return this.salesRepresentative;
	}

	private static setupCommonFields(loggedUser: LoggedUser, response) {
		loggedUser.firstName = response.firstName;
		loggedUser.lastName = response.lastName;
		loggedUser.role = response.role;
		loggedUser.roleType = response.roleType;
		loggedUser.medicalAccountExecutive = response.medicalAccountExecutive;
		loggedUser.aeFilterActive = response.aeFilterActive;
		loggedUser.isAuthenticated = true;
		loggedUser.salesRepresentative = response.salesRepresentative;
		loggedUser.fundingConsultant = response.fundingConsultant;
	}
}

export interface JwtData {
	iss: string;
	iat: number;
	exp: number;
	uid: string;
	role: string;
	roleType: string;
	firstName: string;
	lastName: string;
	medicalAccountExecutive: boolean;
	aeFilterActive: boolean;
	salesRepresentative: boolean;
	fundingConsultant: boolean;
}

export class EmptyJwtData implements JwtData {
	iss;
	iat;
	exp;
	uid;
	role;
	roleType;
	firstName;
	lastName;
	medicalAccountExecutive = false;
	aeFilterActive = false;
	salesRepresentative = false;
	fundingConsultant = false;
}
