import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IRenderer } from '@app/shared/ga-components/utils/IRenderer';
import { isFunction } from '@app/shared/general-helper';

@Component({
	selector: 'ga-default-link-renderer',
	template: `
		<a [attr.href]="href"
		[attr.target]="target"
		[attr.data-cy]="(componentMetadata.dataCy ?? componentMetadata.fieldName) + '-value'"
		*ngIf="externalLink">{{formattedValue}}</a>
		<a [routerLink]="[href]"
		[target]="target"
		[attr.data-cy]="(componentMetadata.dataCy ?? componentMetadata.fieldName) + '-value'"
		*ngIf="!externalLink">{{formattedValue}}</a>
	`,
	styles: ['']
})
export class GaLinkRendererComponent implements IRenderer {

	@Input() value: string;
	@Input() dataObj;
	componentMetadata: ComponentMetadata;
	params: GaLinkParams;
	formattedValue: string;
	href: string;
	target: string;
	externalLink = false;

	constructor(
		public router: Router
	) {
	}

	public redraw() {
		this.params.openInNewTab = this.params.openInNewTab || false;

		try {
			this.formattedValue = isFunction(this.componentMetadata.valueFormatter)
				? <string>this.componentMetadata.valueFormatter(this.value) : this.value;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}

		const obj = { id: null, ...this.dataObj };
		if (this.params.href) {
			const url = this.params.href(obj);
			if (RegExp('(https://)|(http://)', 'gi').test(url)) {
				this.href = url;
			} else {
				this.formattedValue = `https://${url}`;
				this.href = `//${url}`;
			}
			this.externalLink = true;
		} else if (this.params.route) {
			const url = [];

			this.params.route.forEach((chunk) => {
				if (isFunction(chunk)) {
					url.push(chunk(obj));
				} else {
					url.push(chunk);
				}
			});

			this.href = this.router.createUrlTree(url).toString();
		} else if (this.params.phone) {
			this.href = `tel:${ this.params.phone(obj) }`;
			this.externalLink = true;
		} else if (this.params.email) {
			this.href = `mailto:${ this.params.email(obj) }`;
			this.externalLink = true;
		}

		this.target = this.params.openInNewTab ? '_blank' : null;
	}

}

export interface GaLinkParams {
	route: any[],
	href: (HasId) => string,
	phone: (HasId) => string,
	email: (HasId) => string,
	openInNewTab: boolean;
}
