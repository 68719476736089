import { forwardRef, Inject, Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { Hook } from '@app/shared/ga-components/utils/hook';
import { isObject, toString } from '@app/shared/general-helper';
import * as _ from 'lodash';
import { Observable, map } from 'rxjs';

@Injectable()
export class FormScopeService {
	formGroup: UntypedFormGroup;
	private dependencies = [];

	constructor(
		@Inject(forwardRef(() => EntityScopeService)) private entityScope: EntityScopeService,
		private formBuilder: UntypedFormBuilder,
		private apiLink: GaApiLink
	) {
		this.formGroup = this.formBuilder.group({ id: this.formBuilder.control('') });
	}

	public resetForm() {
		this.formGroup = this.formBuilder.group({ id: this.formBuilder.control('') });
		this.formGroup.reset({});
	}

	public registerField(componentMetadata: ComponentMetadata): UntypedFormControl {
		let group = this.formGroup;
		const path = _.split(componentMetadata.path, '.');
		const traversedPath = [];
		while (path.length > 1) {
			const element = path.splice(0, 1)[0];
			traversedPath.push(element);
			const subgroup = group.contains(element)
				? <UntypedFormGroup>group.get(element) : this.formBuilder.group({ id: this.formBuilder.control('') });
			subgroup.patchValue({ id: _.get(this.entityScope.entity, _.concat(traversedPath, ['id'])) });
			group.addControl(element, subgroup);
			group = subgroup;
		}
		const control = new UntypedFormControl('',
			{
				validators: componentMetadata.validators,
				asyncValidators: componentMetadata.asyncValidators,
				updateOn: componentMetadata.asyncValidators.length > 0 ? 'blur' : 'change'
			}
		);
		control.patchValue(componentMetadata.valueGetter(this.entityScope.entity));
		group.addControl(path[0], control);
		this.dependencies = _.union(this.dependencies, componentMetadata.dependencies);
		return control;
	}

	public addGaGroupDependencies(dependencies) {
		this.dependencies = _.union(this.dependencies, dependencies);
	}

	public getFormGroup(metadata: ComponentMetadata): UntypedFormGroup {
		const path = _.split(metadata.path, '.');
		if (path.length > 1) {
			const pathToGroup = _.join(path.slice(0, path.length - 1), '.');
			return <UntypedFormGroup>this.formGroup.get(pathToGroup);
		}
		return this.formGroup;

	}

	public saveCollection(fieldName: string, hooks?: Hook[], responseFieldsOverride?: string[]): Observable<any> {
		const payload = {};
		payload[fieldName] = _.map(this.formGroup.get(fieldName).value, 'id');

		return this.doSave(payload, hooks, responseFieldsOverride);
	}

	public deleteFromCollection(collectionItemClass: string, itemId): Observable<any> {
		return this.apiLink.delete(collectionItemClass, itemId);
	}

	private doSave(payload: any, hooks?: Hook[], responseFieldsOverride?: string[]): Observable<any> {
		const collectionDependencies = this.parseCollectionDependencies();
		//TODO: improve dependencies scope isolation (fetch only what is needed)
		// let dependencies = _.union(this.dependencies, this.entityScope.fieldList, collectionDependencies);
		// experimentally removed dependencies from this.entityScope.fieldList
		const dependencies = _.union(this.dependencies, collectionDependencies);

		const id = this.entityScope.entity.id || payload.id;
		if (id) {
			return this.apiLink.update(
				this.entityScope.entityClass, this.entityScope.entity, payload, dependencies, {}, hooks, responseFieldsOverride
			).pipe(map((data: any) => {
					const updatedEntity = FormScopeService.smartMerge({ ...this.entityScope.entity }, data);
					this.entityScope.setEntity(updatedEntity);
					this.formGroup.reset();

					return data;
				})
			);
		} else {
			return this.apiLink.create(this.entityScope.entityClass, payload, dependencies, {}, hooks, responseFieldsOverride).pipe(
				map((data: any) => {
					const updatedEntity = FormScopeService.smartMerge({ ...this.entityScope.entity }, data);
					this.entityScope.setEntity(updatedEntity);
					this.formGroup.reset();

					return data;
				})
			);
		}
	}

	public save(hooks?: Hook[], responseFieldsOverride?: string[], payloadFilter?: (value) => void): Observable<any> {
		const payload = this.formGroup.value;

		if (payloadFilter) {
			payloadFilter(payload);
		}

		return this.doSave(payload, hooks, responseFieldsOverride);
	}

	public static smartMerge(target, source) {
		return _.transform(source, (acc, value, key, __) => {
			if (Array.isArray(value)) {
				if (acc[key]) {
					const originalCollection: any = acc[key];
					acc[key] = value.map(collectionItem => {
						const originalCollectionItem = (originalCollection.find(
							item => toString(item.id) === toString(collectionItem.id)) || {});
						return FormScopeService.smartMerge(originalCollectionItem, collectionItem);
					});
				} else {
					acc[key] = value;
				}
			} else if (isObject(value)) {
				if (acc[key]) {
					if (isObject(acc[key])) {
						acc[key] = FormScopeService.smartMerge(acc[key], value);
					} else {
						acc[key] = FormScopeService.smartMerge({ id: acc[key] }, value);
					}
				} else {
					acc[key] = value;
				}
			} else {
				acc[key] = value;
			}
		}, target);
	}

	private parseCollectionDependencies(): string[] {
		if (this.entityScope.gaCollection) {
			// if saving ga-collection-item, fetch data which are relevant to this item,
			// but are in parent's scope- I will need them after adding/editing of item
			return _.chain(this.entityScope.gaCollection.entityScope.fieldList)                // parent's scope dependencies
				.filter((item) =>
					_.startsWith(item, this.entityScope.gaCollection.field))  // filter only those which are relevant to my collection
				.map((item) => item.replace(`${this.entityScope.gaCollection.field}.`, ''))    // trim leading fieldName along with .
				.value();
		}

		return [];
	}
}
