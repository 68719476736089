import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { Observable, Subject, distinctUntilChanged, filter, map, takeUntil } from 'rxjs';

@Component({
	selector: 'rp-cost-editor',
	templateUrl: 'rp-cost-editor.component.html',
	styleUrls: ['rp-cost-editor.component.scss']
})
export class RpCostEditorComponent implements OnInit, OnDestroy {
	@Input() entity$: Observable<{ rpCost: string }>;
	@Input() maxRpCost = 4;
	@Output() rpCostChanged = new EventEmitter<string>();

	readonly faIcons = {
		dollar: faDollarSign,
		times: faTimes
	};

	rpCostControl = new FormControl(0);
	private destroy$ = new Subject<void>();

	public ngOnInit(): void {
		this.entity$?.pipe(
			map(entity => +(entity?.rpCost ?? 0)),
			distinctUntilChanged(),
			filter(rpCost => rpCost != this.rpCostControl.value),
			takeUntil(this.destroy$)
		).subscribe(rpCost => {
			this.rpCostControl.setValue(rpCost, {emitEvent: false});
		});
		this.rpCostControl.valueChanges
			.pipe(
				distinctUntilChanged(),
				takeUntil(this.destroy$)
			)
			.subscribe(value => {
				this.rpCostChanged.emit(value.toString());
			});
	}

	public resetRpCost(): void {
		this.rpCostControl.setValue(0);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
