import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarComponent } from '@app/shared/avatar/avatar.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
	imports: [
		CommonModule,
		TooltipModule,
		PipesModule,
		FontAwesomeModule
	],
	declarations: [
		AvatarComponent
	],
	exports: [
		AvatarComponent
	]
})
export class AvatarModule {}
