import { HttpClient, HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HistoricalPerformanceResponse } from '@app/dashboard/historical-performance/historical-performance.model';
import { DashboardRoundStore } from '@app/dashboard/service/dashboard-round-store.service';
import { datePickerDefaultConfig } from '@app/shared/model/constants/date-picker-default-config';
import { GhostElementService } from '@app/widgets/ghost-element/ghost-element.service';
import * as moment from 'moment';
import { filter, map, merge, Observable, startWith, switchMap, tap } from 'rxjs';

@Component({
	selector: 'historical-performance',
	templateUrl: './historical-performance.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['../shared/dashboard-date-filter-styling.scss', 'historical-performance.component.scss'],
	providers: [GhostElementService]
})
export class HistoricalPerformanceComponent implements OnInit {
	@Input() isCollapseManaged = false;

	historicalPerformance$: Observable<HistoricalPerformanceResponse>;
	round$: Observable<boolean>;
	dateFilterControl = new FormControl<Date>(null);
	dateRangeFilterControl = new FormControl<Date[]>(null);

	readonly bsDaterangeConfig = {
		...datePickerDefaultConfig,
		containerClass: 'custom-datepicker-theme without-arrow',
		ranges: [
			{ label: 'Year-to-date', value: [moment().startOf('year').toDate(), moment().toDate()] },
			{ label: 'Past year', value: [moment().subtract(1, 'year').toDate(), moment().toDate()] },
			{ label: 'Past 2 year', value: [moment().subtract(2, 'year').toDate(), moment().toDate()] },
			{
				label: 'Past 6 months',
				value: [moment().subtract(6, 'month').startOf('day').toDate(), moment().toDate()]
			},
			{
				label: 'Last month',
				value: [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()]
			},
			{ label: 'Month-to-date', value: [moment().startOf('month').toDate(), moment().toDate()] },
		],
		maxDate: new Date(),
		minDate: new Date(2015, 0, 1),
	}

	constructor(
		private dashboardRoundStore: DashboardRoundStore,
		private http: HttpClient,
		private ghostElementService: GhostElementService) {
	}

	public ngOnInit(): void {
		this.round$ = this.dashboardRoundStore.selectRound$;
		const date$ = this.dateFilterControl.valueChanges
			.pipe(
				filter(() => this.dateFilterControl.valid),
				map((date: Date) => {
					if (date == null) {
						return null;
					}
					this.dateRangeFilterControl.setValue(null, { emitEvent: false });
					const dateParam = this.getDateParam(date);
					return { startDate: null, endDate: dateParam };
				})
			);

		const dateRange$ = this.dateRangeFilterControl.valueChanges
			.pipe(
				filter(() => this.dateFilterControl.valid),
				map((range: Date[]) => {
					if (range.length == 0) {
						return null;
					}
					this.dateFilterControl.setValue(null, { emitEvent: false });
					const [startDate, endDate] = range;
					return { startDate: this.getDateParam(startDate), endDate: this.getDateParam(endDate) };
				})
			);

		this.historicalPerformance$ = merge(date$, dateRange$)
			.pipe(
				startWith(null),
				tap(() => this.ghostElementService.showData(false)),
				switchMap(dateBoundary => {
					let params = new HttpParams();
					if (dateBoundary != null) {
						const { startDate, endDate } = dateBoundary;
						if (startDate != null) {
							params = params.set('startDate', startDate);
						}
						params = params.set('endDate', endDate);
					}
					return this.http.get<HistoricalPerformanceResponse>('/api/dashboard/historical-performance', { params });
				}),
				tap(data => this.ghostElementService.showData(data !== null)),
				startWith(null)
			);
	}

	private getDateParam(date: Date): string {
		return moment({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() })
			.format('YYYY-MM-DD');
	}
}
