import { Injectable } from '@angular/core';
declare const gtag;

@Injectable({
	providedIn: 'root'
})

export class GoogleAnalyticsService {
    public trackEvent(eventName: string, data: any) {
        gtag('event', eventName, data);
    }
}