import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { GaCellComponent } from '@app/ga-grid/components/ga-cell.component';
import { ConfirmClickDirective } from '@app/shared/confirmation-dialog/confirm-click.directive';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { IRenderer } from '@app/shared/ga-components/utils/IRenderer';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
	selector: 'ga-action-buttons',
	template: `
		<button *ngFor="let action of params; let i = index" [ngClass]="[action.cssClass]" (click)="buttonClick(i)">
			<fa-icon *ngIf="action.icon" [icon]="action.icon"></fa-icon>
			{{action.title}}
			<ng-template *ngIf="action.showConfirmDialog" (confirm-click)="doAction()"></ng-template>
		</button>
	`,
})
//TODO: Replace usage of this component with AgActionButtonsRendererComponent
export class GaActionButtonsRendererComponent implements IRenderer, OnInit {
	@ViewChild(ConfirmClickDirective) confirmDialog: ConfirmClickDirective;
	value: string;
	dataObj;
	componentMetadata: ComponentMetadata;
	params: ActionButtonsParams[];
	agGridParams;
	clickedButton = 0;

	constructor(@Optional() public gaCell: GaCellComponent) {}

	public ngOnInit() {
		this.agGridParams = this.gaCell?.params;
	}

	public agInit(params): void {
		this.dataObj = params.data;
		this.params = params.buttonParams;
	}

	public refresh(): boolean {
		return true;
	}

	public buttonClick(index) {
		this.clickedButton = index;
		if (this.params[index].showConfirmDialog) {
			this.confirmDialog.onClick();
		} else {
			this.doAction();
		}
	}

	public doAction() {
		this.params[this.clickedButton].action({ dataObj: this.dataObj, params: this.params, gridParams: this.agGridParams });
	}

	public redraw() {
		return;
	}
}

interface ActionButtonsParams {
	cssClass: string;
	icon: IconProp;
	title: string;
	action: (data) => unknown;
	showConfirmDialog: boolean;
}
