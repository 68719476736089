import { Pipe, PipeTransform } from '@angular/core';

/**
 * Return number of fields in object
 */
@Pipe({ name: 'objectLength' })
export class ObjectLengthPipe implements PipeTransform {

	public transform(object: { [key: string]: any }): number {
		if (object == null) {
			return 0;
		}

		return Object.keys(object).length;
	}
}
