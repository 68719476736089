import { Injectable } from '@angular/core';
import { ServerFieldsDefinitionService } from '@app/core/fields-definition/server/server-fields-definition.service';
import * as _ from 'lodash';
import * as moment from 'moment';

// @Fixme: understand and cleanup this class, it's mess

@Injectable({
	providedIn: 'root'
})
export class FieldsDefinitionService {

	private definitionObject = {};

	private serverDefinitions;

	constructor(private serverFieldsDefinition: ServerFieldsDefinitionService) {

		serverFieldsDefinition.definitions.subscribe((definitions) => {
			if (!_.isEmpty(definitions)) {
				// eslint-disable-next-line no-console
				console.log('* Updating fields definition');
				_.each(definitions, (classDefinitions: any, shortName) => {
					this.definitionObject[shortName] = classDefinitions.fields;
					_.each(this.definitionObject[shortName], this.ensureProperties(shortName));
				});

				this.serverDefinitions = definitions;
			}
		});
	}

	public getFieldsDefinitionObject() {
		return this.definitionObject;
	}

	public getFieldDefinition(entityClass, field) {
		if (!this.definitionObject[entityClass]) {
			console.warn(`* There are no fields definitions for: ${entityClass}.`);
			return false;
		}
		return this.locateDefinition(this.definitionObject[entityClass], field.split('.'));
	}

	public getServerFieldsDefinitions() {
		return this.serverDefinitions;
	}

	private locateDefinition(entityDefinitionObject, fieldPath) {
		const fieldDefinition = entityDefinitionObject[fieldPath[0]];
		if (fieldDefinition) {
			if (fieldDefinition.type === 'reference' || fieldDefinition.type === 'search') {
				if (fieldPath.length < 2) {
					return fieldDefinition;
				}
				return this.locateDefinition(this.definitionObject[fieldDefinition.targetEntity], fieldPath.slice(1));
			} else if (fieldDefinition.type === 'codelist') {
				return fieldDefinition;
			} else {
				if (fieldPath.length !== 1) {
					throw Error('Type is not reference, but path is specified');
				}
				return fieldDefinition;
			}
		}
		return false;
	}

	private ensureProperties(entity) {
		return (definition, id) => {
			definition.id = id;
			definition.entityClass = entity;
			definition.title = definition.title || entity + '.' + id; //TODO: log missinf title
			definition.section = definition.section || 'Misc';
			if (definition.tracked) {
				definition.tooltip = this.getTooltipFunction(id);
			}
		};
	}

	private getTooltipFunction(fieldName) {
		return function (item) {
			const propertyChange = item.propertyChanges.find(el => el.propertyName === fieldName);
			if (propertyChange) {
				let html = '';
				if (propertyChange.modifiedOn) {
					html += '<span>on ' + moment(propertyChange.modifiedOn).format('llll') + '</span><br>';
				}
				if (propertyChange.modifiedBy) {
					html += '<span>by ' + propertyChange.modifiedBy.firstName + ' ' + propertyChange.modifiedBy.lastName + '</span>';
				}
				return html;
			}
			return false;
		};
	}
}
