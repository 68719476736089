<div class="wrapper wrapper-content">

	<div class="row animated fadeInRight">
		<div class="col-md-12 slideInRight">
			<div class="ibox">
				<div class="ibox-content fieldset">
					<h2>Change password</h2>
					<form [formGroup]="passwordForm" (ngSubmit)="save()" autocomplete="off" class="form flex-col">
						<p class="required">
							<label>New Password</label>
							<br>
							<input type="password" formControlName="newPassword">
						</p>
						<p class="required">
							<label>Confirm Password</label>
							<br>
							<input type="password" formControlName="confirmPassword">
						</p>
						<label class="text-danger" *ngIf="passwordForm.errors?.passwordMismatch && (passwordForm.get('confirmPassword').dirty)">
							<b>Confirm Password doesn't match with New Password.</b>
						</label>
						<button type="submit" [disabled]="passwordForm.invalid" class="btn btn-primary">Save</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
