import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FundingsTableGridFunding } from '@app/fundings/fundings-table-grid/fundings-table-grid-funding';
import { Observable } from 'rxjs';
import { FundingsTableFilterParams } from './fundings-table-filter-params.model';

@Injectable()
export class FundingsViewService {

	constructor(private http: HttpClient) {
	}

	public fetchData(
		dateRange: string[],
		dateFilter: string,
		fundingFilter: string,
		archivedFilter: string
	): Observable<FundingsTableGridFunding[]> {
		const params: FundingsTableFilterParams = {
			from: dateRange[0],
			to: dateRange[1],
			dateFilterBy: dateFilter,
			fundingType: fundingFilter,
			archived: archivedFilter
		};
		return this.http.get<FundingsTableGridFunding[]>('/api/fundings/fundings-table', { params: params });
	}
}
