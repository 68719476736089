export function trimEnd(input: string, chars: string): string {
	return input.replace(new RegExp(`^(.*?)(${chars})$`), '$1');
}

export function isString(data: unknown): boolean {
	return typeof data === 'string';
}

export function isObject(data: unknown): boolean {
	return data instanceof Object;
}

export function toString(data: unknown): string {
	return data?.toString() ?? ''
}

export function isFunction(data: unknown): boolean {
	return typeof data === 'function';
}

export function toNumber(data: unknown): number | null | typeof NaN {
	if (data === null) {
		return null
	}
	if (data === undefined) {
		return NaN;
	}
	return +data;
}

export function uniqBy<T>(arr: T[], iteratee): T[] {
	if (typeof iteratee === 'string') {
		const prop = iteratee;
		iteratee = item => item[prop];
	}

	return arr.filter((x, i, self) => i === self.findIndex(y => iteratee(x) === iteratee(y)));
}

export function getFirstObjPropName(data): string {
	return Object.keys(data)[0];
}

export function transformObjPropsToBoolean<T extends Record<string, boolean>>(obj: T, loaderValue: boolean): T {
	const newObj = {} as T;
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			newObj[key] = loaderValue as T[Extract<keyof T, string>];
		}
	}
	return newObj;
}
