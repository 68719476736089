import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { fadeInOut } from '@app/shared/animations/animations';
import { EntityId } from '@app/shared/model/types/entity-id';
import { SortMode } from '@app/shared/model/types/todo';
import { TodoCardComment } from '@app/todo/model/todo-card-data';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

@Component({
	selector: 'todo-comments',
	templateUrl: './todo-comments.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [fadeInOut]
})
export class TodoCommentsComponent implements OnInit {
	@Input() set comments(items: TodoCardComment[]) {
		this.commentsEmitter.next([...items]);
	}
	@Input() todoId: EntityId;

	isContentExpanded = false;
	comments$: Observable<TodoCardComment[]>;
	latestComment$: Observable<TodoCardComment>;

	readonly faIcons = {
		chevronDown: faChevronDown,
	};

	private sortMode$ = new BehaviorSubject<SortMode>(SortMode.OLDEST_FIRST);
	private commentsEmitter = new BehaviorSubject([]);

	public ngOnInit(): void {
		this.comments$ = combineLatest([this.sortMode$.asObservable(), this.commentsEmitter.asObservable()])
			.pipe(
				map(([sortMode, comments]) => this.sortComments(comments, sortMode)
			)
		);
		this.latestComment$ = this.comments$.pipe(map((comments) => this.sortComments([...comments], SortMode.NEWEST_FIRST)[0]));
	}

	public toggleContent(): void {
		this.changeSorting(SortMode.OLDEST_FIRST);
		this.isContentExpanded = !this.isContentExpanded;
	}

	public changeSorting(sortMode: SortMode): void {
		this.sortMode$.next(sortMode);
	}

	private sortComments(comments: TodoCardComment[], sortMode: SortMode): TodoCardComment[] {
		return comments.sort((a, b) =>
			sortMode == SortMode.OLDEST_FIRST
				? moment(a.createdAt).diff(moment(b.createdAt))
				: moment(b.createdAt).diff(moment(a.createdAt))
		);
	}
}
