import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardFundingFilterType } from '@app/shared/model/types/funding-filter-type';
import { ComponentStore } from '@ngrx/component-store';

@Injectable()
export class DashboardFilterStore extends ComponentStore<DashboardFundingFilterType> {

	readonly setFundingTypeFilter = this.updater((state, ids: number[]) => ({ ...state, ids }));
	readonly fundingParams$ = this.select(state => new HttpParams({ fromObject: { 'fundingType[]': state.ids } }));
	readonly selectedFundingTypes$ = this.select(({ ids }) => ids);

	constructor() {
		super({ ids: [] });
	}
}
