<div [ngClass]="[sms.direction]">
	<div class="inbox-message-wrapper">
		<div class="base-flex">
			<button *ngIf="isDirectionInbound(sms)"
					(click)="markActionTaken(sms)"
					[class.disabled]="sms.actionTakenAt"
					[disabled]="sms.actionTakenAt"
					title="Action taken"
					class="base-button base-button--square">
				<fa-icon [icon]="faIcons.briefcase"></fa-icon>
			</button>
			<div class="inbox-message">
				{{ sms.body }}
				<div *ngIf="sms.actionTakenAt" class="inbox-message__action-taken">
					<div>Action taken at {{ sms.actionTakenAt|date:'short' }}</div>
					by {{ sms.actionTakenBy.firstName }} {{ sms.actionTakenBy.lastName }}
				</div>
			</div>
		</div>
		<div class="inbox-message-info">
			<div *ngIf="isDirectionOutbound(sms)" class="inbox-message-info__status" [ngClass]="sms.status">{{ sms.status }}</div>
			<ng-container *ngIf="isDirectionOutbound(sms)">sent by: <ng-container *ngIf="sms.sendBy; else defaultSender">{{ sms.sendBy.firstName }} {{ sms.sendBy.lastName }}</ng-container>, on: </ng-container>{{ sms|smsMessageDate }}
		</div>
	</div>
</div>
<ng-template #defaultSender>
	{{ sms.fromNumber }}
</ng-template>
