<public-view title="New password" [subtitle]="subtitle">
	<form class="login-view__form" role="form" data-cy="newPassword-form" [formGroup]="newPasswordForm" (ngSubmit)="setPassword()" autocomplete="off" #form="ngForm">
		<div class="login-view__form__input-wrapper" [ngClass]="{'error' : newPassword.invalid && form.submitted}">
			<input placeholder="New password" type="password" name="newPassword" formControlName="newPassword" id="newPassword" data-cy="newPassword" autocomplete="off">
			<fa-icon [icon]="faIcons.exclamationCircle"></fa-icon>
			<div *ngIf="newPassword.invalid && form.submitted" class="clue-info">
				<div *ngIf="newPassword.errors.required">New Password is required</div>
				<div *ngIf="newPassword.errors.minlength">Must be least 6 characters.</div>
			</div>
		</div>
		<div class="login-view__form__input-wrapper" [ngClass]="{'error' : newPasswordForm.invalid && form.submitted}">
			<input placeholder="Confirm password" type="password" name="confirmPassword" formControlName="confirmPassword" id="confirmPassword" data-cy="confirmPassword-input" autocomplete="off">
			<fa-icon [icon]="faIcons.exclamationCircle"></fa-icon>
			<div *ngIf="newPasswordForm.errors?.passwordMismatch && form.submitted" class="clue-info">
				<div>Confirm Password doesn't match New Password</div>
			</div>
		</div>
		<button class="login-view__form__button animated" data-cy="submit-btn" (animationend)="shake = false" [ngClass]="{'shake': shake}" type="submit">
			Save new password
		</button>
	</form>
</public-view>
