export enum UrlCodelistLink {
	CustomerServiceRepresentatives = 'url-code-lists/customer-service-representatives',
	SalesRepresentatives = 'url-code-lists/sales-representatives',
	FundingSchedulers = 'url-code-lists/funding-schedulers',
	FundingConsultants = 'url-code-lists/funding-consultants',
	MedicalAEs = 'url-code-lists/medical-account-executives',
	Employees = 'url-code-lists/employees',
	SalesPersons = 'url-code-lists/sales-persons',
	Doctors = 'url-code-lists/doctors',
	MedicalFacilities = 'url-code-lists/facilities',
	CptCodes = 'url-code-lists/cpt-codes',
	Negotiators = 'url-code-lists/negotiators',
	InsuranceCompanies = 'url-code-lists/insurance-companies',
	LawFirms = 'url-code-lists/law-firms',
	Attorneys = 'url-code-lists/attorneys',
	CaseManagers = 'url-code-lists/case-managers',
	MriSchedulers = 'url-code-lists/mri-schedulers',
	LawFirmManagers = 'url-code-lists/law-firm-managers',
	Underwriters = 'url-code-lists/underwriters',
	PracticeGroups = 'url-code-lists/practice-groups',
}

export enum UrlCodelistFieldName {
	Csrs = 'customerServiceRepresentative',
	SalesRepresentatives = 'salesRepresentative',
	FundingSchedulers = 'markedAsScheduler',
	FundingConsultants = 'fundingConsultant',
	MedicalAEs = 'medicalAccountExecutive',
	SalesPersons = 'salesPerson',
	Negotiators = 'negotiator',
	CaseManagers = 'caseManager'
}

export class UrlCodelistHelper {
	private static fieldToLinkMapping = new Map<string, string>([
			[UrlCodelistFieldName.Csrs, UrlCodelistLink.CustomerServiceRepresentatives],
			[UrlCodelistFieldName.SalesRepresentatives, UrlCodelistLink.SalesRepresentatives],
			[UrlCodelistFieldName.FundingSchedulers, UrlCodelistLink.FundingSchedulers],
			[UrlCodelistFieldName.FundingConsultants, UrlCodelistLink.FundingConsultants],
			[UrlCodelistFieldName.MedicalAEs, UrlCodelistLink.MedicalAEs],
			[UrlCodelistFieldName.SalesPersons, UrlCodelistLink.SalesPersons],
			[UrlCodelistFieldName.Negotiators, UrlCodelistLink.Negotiators],
			[UrlCodelistFieldName.CaseManagers, UrlCodelistLink.CaseManagers],
		]
	);

	public static mapFieldToLink(fieldName: string): string {
		return this.fieldToLinkMapping.get(fieldName);
	}

	public static getAllUrlCodelistFieldNames() {
		return [
			UrlCodelistFieldName.Csrs,
			UrlCodelistFieldName.SalesRepresentatives,
			UrlCodelistFieldName.FundingSchedulers,
			UrlCodelistFieldName.FundingConsultants,
			UrlCodelistFieldName.MedicalAEs,
			UrlCodelistFieldName.SalesPersons,
			UrlCodelistFieldName.Negotiators,
		];
	}

	public static includes(codeListLink: string): boolean {
		return (<any>Object).values(UrlCodelistLink).includes(codeListLink);
	}
}


