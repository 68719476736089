import { Pipe, PipeTransform } from '@angular/core';
import { MedicalOpportunityStatement } from '@app/applicant/applicant-detail/applicant-detail-header/medical-funding-opportunity/model/statement-codelist.model';

@Pipe({
	name: 'medicalOpportunityFlagColor',
	standalone: true
})

export class MedicalOpportunityFlagColorPipe implements PipeTransform {

	public transform(medicalOpportunities: MedicalOpportunityStatement[]): string {
		if (medicalOpportunities?.length > 0) {
			if (medicalOpportunities.some(opportunity => opportunity.clientReferred)) {
				return 'var(--info)';
			}
			return 'var(--warning)';
		}
		return 'var(--danger)';
	}
}
