import { Component } from '@angular/core';
import { faScaleBalanced, faHouseChimneyMedical, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { DashboardLink } from './small-screen.model';

@Component({
	selector: 'small-screen',
	templateUrl: './small-screen.component.html',
	styleUrls: ['./small-screen.component.scss']
})
export class SmallScreenComponent {

	readonly dashboardLinks: DashboardLink[] = [
		{ label: 'Law Firm', icon: faScaleBalanced, path: ['/law-firms'] },
		{ label: 'Medical Facilities', icon: faHouseChimneyMedical, path: ['/medical-facilities'] },
		{ label: 'Doctors', icon: faUserDoctor, path: ['/doctors'] },
	]
}
