import { Injectable } from '@angular/core';
import { Persistable } from '@app/core/data-watch/utils';
import { FilterVisitor } from '@app/shared/filter-visitors/models/filter-visitor';
import { merge, Subject, switchMap } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Injectable()
export class MedicalMarkersMapFilterService<T extends Persistable> {
	private filterVisitors: FilterVisitor<T>[] = [];
	private filter$ = new Subject<void>();
	private destroy$ = new Subject<void>();

	applyFilter = () =>
		switchMap((doctors: T[]) =>
			this.filter$.pipe(
				map(() => doctors.filter((doctor) => this.includesInMap(doctor)))
			)
		)

	public addFilters(filters: FilterVisitor[]): void {
		this.destroy$.next(null);
		this.filterVisitors.push(...filters);
		this.refreshOnChange();
	}

	public clearFilters(): void {
		this.filterVisitors = [];
		this.destroy$.next(null);
	}

	public refreshFilters(): void {
		this.filter$.next();
	}

	private refreshOnChange(): void {
		const formControlsListener = this.filterVisitors.map(filterVisitor => filterVisitor.getFormControl().valueChanges);
		merge(...formControlsListener).pipe(
			debounceTime(600),
			takeUntil(this.destroy$)
		).subscribe(() => this.refreshFilters());
	}

	private includesInMap(doctor: T): boolean {
		const activeFilters = this.filterVisitors.filter(visitor => visitor.isActive());
		if (activeFilters.length == 0) {
			return false;
		}
		return activeFilters.every(visitor => visitor.filter(doctor))
	}
}
