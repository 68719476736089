import { Persistable } from '@app/core/data-watch/utils';
import { TodoStatus } from '@app/shared/model/constants/todo-status';
import { Codelist } from '@app/shared/model/types/codelists/codelist';
import { EntityAssociation } from '@app/shared/model/types/entity-association';
import { UserAccount } from '@app/shared/model/types/user-account';
import { FilesConfig } from '@app/todo/model/files-config';
import { TodoActivityLogConfig } from '@app/todo/model/todo-activity-log-config';
import { TodoCategory } from '@app/todo/model/todo-category';

export class Todo extends Persistable {
	status: TodoStatus;
	summary: string;
	dueDate: string;
	description: string;
	assignees: UserAccount[];
	files: Array<FilesConfig>;
	associations: EntityAssociation[];
	todoLogEntries: TodoActivityLogConfig[];
	todoCategories: TodoCategory[];
	createdBy: Partial<UserAccount>;
	timezone: string | number;
	comments: TodoComment[];
	lastEditedAt: string;
	labels: Codelist[];
}

export type TodoTask = Pick<Todo,
	'id' |
	'assignees' |
	'createdBy' |
	'dueDate' |
	'status' |
	'summary' |
	'timezone'
> & { startedAt: string, finishedAt: string }

export class TodoComment {
	id: number;
	author: UserAccount;
	todo: Todo;
	text: string;
	createdAt: string | Date;
	mentions: TodoCommentMention[];
}

export class TodoCommentRequest {
	author: number;
	todo: string;
	text: string;
	mentions: TodoCommentMentionRequest[];

	constructor(author: number, todo: string, text: string, mentions: TodoCommentMentionRequest[]) {
		this.author = author;
		this.todo = todo;
		this.text = text;
		this.mentions = mentions;
	}
}

export class TodoCommentMention {
	id: number | string;
	mentionedUser: UserAccount;
	seen: boolean;
}

export class TodoCommentMentionRequest {
	mentionedUser: number;

	constructor(mentionedUser: number) {
		this.mentionedUser = mentionedUser;
	}

}

export enum SortMode {
	OLDEST_FIRST,
	NEWEST_FIRST
}

export interface TodoUpdateRequest {
	assignees?: string[],
	summary?: string,
	status?: TodoStatus,
	description?: string,
	dueDate?: string,
	startedAt?: string,
	finishedAt?: string,
	archivedAt?: string,
	categories?: number[],
	labels?: number[],
	timezone?: number,
	comment?: string,
	mentions?: string[],
	doctorNote?: string,
	lawFirmNote?: string,
	medicalFacilityNote?: string
}
