import { Injectable } from '@angular/core';
import { TodoCardData } from '@app/todo/model/todo-card-data';
import { defaultTodoSidebarSortOption } from '@app/todo/todo-sidebar/todo-sidebar-sort/todo-sidebar-sort-config';
import { SortType, TodoSidebarSortItem } from '@app/todo/todo-sidebar/todo-sidebar-sort/todo-sidebar-sort.model';
import { SortDirection } from '@app/w-boards/shared/sort-icon/models/sort-settings';
import { faSort, faSortDown, faSortUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { orderBy } from 'lodash';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

@Injectable()
export class TodoSortService {
	private _sortDirection$ = new BehaviorSubject(SortDirection.Asc);
	private _sortItem$ = new BehaviorSubject(defaultTodoSidebarSortOption);

	get sortDirection(): SortDirection {
		return this._sortDirection$.value;
	}

	set sortDirection(direction: SortDirection) {
		this._sortDirection$.next(direction);
	}

	get getSortDirectionString$(): Observable<SortType> {
		return this._sortDirection$.asObservable().pipe(
			map((direction) => {
				switch (direction) {
					case SortDirection.Asc:
						return SortType.Asc;
					case SortDirection.Desc:
						return SortType.Desc;
					case SortDirection.None:
						return null;
				}
			})
		);
	}

	get sortDirectionIcon$(): Observable<IconDefinition> {
		return this._sortDirection$.asObservable().pipe(map((sortDirection) => {
			switch (sortDirection) {
				case SortDirection.Asc:
					return faSortUp;
				case SortDirection.Desc:
					return faSortDown;
				case SortDirection.None:
					return faSort;
			}
		}));
	}

	get sortItem$(): Observable<TodoSidebarSortItem> {
		return this._sortItem$.asObservable();
	}

	set sortItem(sortItem: TodoSidebarSortItem) {
		this._sortItem$.next(sortItem);
	}

	public sortTodos$(selectedTodos$: Observable<TodoCardData[]>): Observable<TodoCardData[]> {
		return combineLatest([
			selectedTodos$,
			this.sortItem$,
			this.getSortDirectionString$
		]).pipe(
			map(([todos, sortItem, sortType]) => {
				if (sortType && sortItem?.value) {
					return orderBy(todos, [sortItem.value], [sortType]);
				}
				return orderBy(todos, ['dueDate'], [SortType.Asc]);
			})
		);
	}
}
