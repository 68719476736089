import { Component, OnInit } from '@angular/core';
import { SmsBadgeStoreService } from '@app/sms-common/notification/sms-badge-store.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'unread-sms-badge',
	templateUrl: './unread-sms-badge.component.html',
	styleUrls: ['./unread-sms-badge.component.scss'],
})
export class UnreadSmsBadgeComponent implements OnInit {

	unreadSmsCount$: Observable<number>;

	constructor(
		private smsBadgeService: SmsBadgeStoreService) {
	}

	public ngOnInit() {
		this.unreadSmsCount$ = this.smsBadgeService.getUnreadSmsCount();
	}

}
