import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IdentityProviderService } from '@app/core/auth/identity-provider.service';
import { LocalStorageKeys } from '@app/shared/model/constants/local-storage-keys';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard {

	constructor(private identityProvider: IdentityProviderService, private router: Router) {
	}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
		Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.identityProvider.getIdentity().isAuthenticated) {
			return true;
		}

		localStorage.setItem(LocalStorageKeys.LAST_VISITED_PAGE, state.url);
		this.router.navigate(['/login']);
		return false;
	}
}
