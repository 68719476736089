import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { AclRoleType } from '@app/core/security/acl-role-type';
import { ActionsPermission } from '@app/core/security/permissions/permissions.model';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { StatisticsRepositoryService } from '@app/shared/model/statistics-repository.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
	selector: 'dashboard-charts',
	templateUrl: './dashboard-charts.component.html',
})
export class DashboardChartsComponent implements OnInit {

	selectedFundingTypes$: Observable<any[]>;
	underwritingAudit$: Observable<any>;
	serviceNotesChart$: Observable<any>;

	isSuperAdmin = false;

	readonly permissionTypes = ActionsPermission;

	constructor(
		private authService: AuthService,
		private dashboardFilterStore: DashboardFilterStore,
		private statisticsRepository: StatisticsRepositoryService) {
	}

	public ngOnInit(): void {
		this.isSuperAdmin = this.authService.getCurrentUser().roleType === AclRoleType.SuperAdmin;
		this.selectedFundingTypes$ = this.dashboardFilterStore.selectedFundingTypes$;
		this.underwritingAudit$ = this.selectedFundingTypes$
			.pipe(
				switchMap(selectedIds => this.statisticsRepository.getUnderwritingStats(selectedIds))
			);
	}
}
