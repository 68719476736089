export enum MedSchedulerColumnIds {
	ConsultationPending = 'med_scheduler_consultation_pending',
	PendingFollowUp = 'pending_follow_up',
	AppointmentConfirmed = 'appointment_confirmed',
	RecommendationPending = 'recommendation_pending',
	PendingReport = 'pending_report',
	WaitingOnReport = 'waiting_on_report',
}

export const MedSchedulerColumnNames = {
	[MedSchedulerColumnIds.ConsultationPending]: 'Consultation Pending',
	[MedSchedulerColumnIds.PendingFollowUp]: 'Pending Follow Up',
	[MedSchedulerColumnIds.AppointmentConfirmed]: 'Appointment Confirmed',
	[MedSchedulerColumnIds.RecommendationPending]: 'Recommendation Pending',
	[MedSchedulerColumnIds.PendingReport]: 'Pending Report - Archive',
	[MedSchedulerColumnIds.WaitingOnReport]: 'Waiting On Report'
}
