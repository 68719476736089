import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CollapsibleItemComponent } from '@app/dashboard/collapsible-item/collapsible-item.component';
import { DashboardDataUtilsService } from '@app/dashboard/dashboard-data-utils.service';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { TimeRelatedConfigurationComponent } from '@app/dashboard/shared/time-related-configuration/time-related-configuration.component';
import { ConfigurationChangedEvent } from '@app/dashboard/shared/time-related-configuration/time-related-configuration.model';
import { SummaryStatisticsGraphService } from '@app/dashboard/summary-statistics/summary-statistics-graph.service';
import { SummaryStatisticsModalService } from '@app/dashboard/summary-statistics/summary-statistics-modal.service';
import { SummaryStatisticsChartConfig, SummaryStatisticsChartId, SummaryStatisticsChartMode, SummaryStatisticsResponse } from '@app/dashboard/summary-statistics/summary-statistics.model';
import { SummaryStatisticsService } from '@app/dashboard/summary-statistics/summary-statistics.service';
import { CartesianSavedChart } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/cartesian-saved-chart';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { CartesianGroupedTooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/cartesian-grouped-tooltip-theme.service';
import { chartNumberAxesTheme, chartPriceAxesTheme } from '@app/shared/ag-grid-utils/chart-themes/chart-axes-theme';
import { numberColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/number-column-definition';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { SVGIconComponent } from '@app/shared/svg-icon/svg-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, ColGroupDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject, shareReplay, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
	selector: 'total-funded-table',
	templateUrl: 'total-funded-table.component.html',
	imports: [
		AsyncPipe,
		SVGIconComponent,
		NgIf,
		TooltipModule,
		NgClass,
		DirectivesModule,
		TimeRelatedConfigurationComponent,
		FontAwesomeModule,
		AgGridModule,
		NgStyle,
		LetDirective,
		CollapsibleItemComponent

	],
	providers: [
		SummaryStatisticsModalService,
		SummaryStatisticsGraphService,
	],
	standalone: true
})
export class TotalFundedTableComponent implements OnInit, OnDestroy {
	totalFundedData$: Observable<SummaryStatisticsResponse[]>;
	mode = SummaryStatisticsChartMode.Funded;
	colDefs: (ColDef | ColGroupDef)[];
	gridOptions: GridOptions;
	hasSavedChart$: Observable<boolean>;
	collapsed$ = new BehaviorSubject(false);
	collapsingIcon$: Observable<IconDefinition>;
	readonly clearIcon = SvgIconName.Preferences;
	readonly modes = SummaryStatisticsChartMode;
	private gridApi: GridApi;
	private timeConfiguration$ = new ReplaySubject<ConfigurationChangedEvent>(1);
	private readonly savedCharts = new Map<SummaryStatisticsChartId, CartesianSavedChart>();
	private destroy$ = new Subject<void>();

	constructor(
		private dashboardDataUtilsService: DashboardDataUtilsService,
		private dashboardFilterStore: DashboardFilterStore,
		private agGridUtils: AgGridUtilsService,
		private summaryStatisticsGraphService: SummaryStatisticsGraphService,
		private summaryStatisticsModalService: SummaryStatisticsModalService,
		private summaryStatisticsService: SummaryStatisticsService
	) {
	}

	public ngOnInit(): void {
		this.hasSavedChart$ = this.summaryStatisticsGraphService.hasSavedChart$;
		this.totalFundedData$ = combineLatest([this.timeConfiguration$, this.dashboardFilterStore.fundingParams$])
			.pipe(
				switchMap(([configuration, fundingTypeParams]) =>
					this.summaryStatisticsService.getTotalFundedData$(configuration, fundingTypeParams)),
				shareReplay({ bufferSize: 1, refCount: true })
			);
		this.gridOptions = this.agGridUtils.getGridOptions({
			sideBar: false,
			defaultExcelExportParams: {
				fileName: 'Summary Statistics Export'
			},
		});
		const tooltipTheme = new CartesianGroupedTooltipThemeService();
		this.colDefs = [
			{
				headerName: 'Period',
				field: 'title',
				...this.dashboardDataUtilsService.getPeriodColDef(),
				pinned: 'left',
				minWidth: 90,
				width: 90,
				chartDataType: 'category'
			},
			{
				headerName: 'Total Funded in Period',
				children: [
					{
						...tooltipTheme.addNumberColumn(
							'# of Fundings',
							'fundedDetails.numberOfFundings'
						),
						minWidth: 100,
						width: 100,
					},
					{
						field: 'fundedDetails.growthDeclineFundings',
						...this.summaryStatisticsService.getGrowthDeclineColDef()
					},
					{
						...tooltipTheme.addNumberColumn(
							'# of New Cases',
							'fundedDetails.numberOfCases'
						),
						minWidth: 120,
						width: 120,
					},
					{
						...tooltipTheme.addPriceColumn(
							'Funded Amount [$]',
							'fundedDetails.totalPrice'
						),
						minWidth: 150,
						width: 150,
					},
					{
						field: 'fundedDetails.growthDeclinePrice',
						...this.summaryStatisticsService.getGrowthDeclineColDef()
					},
					{
						...tooltipTheme.addPriceColumn(
							'Effective Rates [$]',
							'fundedDetails.dealsAmountWithEffectiveRates'
						),
						minWidth: 150,
						width: 150,
					},
					{
						field: 'fundedDetails.growthDeclineEffectiveRates',
						...this.summaryStatisticsService.getGrowthDeclineColDef()
					},
					{
						...this.getInfoButtonColDef(data => this.summaryStatisticsModalService.openDetailAdvanced(data))
					},
					{ headerName: '# fundings / biz day', ...numberColumnDefinition('fundedDetails.numberOfFundingsPerBizDay') },
					{ headerName: '# pre set fundings / biz day', ...numberColumnDefinition('fundedDetails.presetCountPerBizDay') },
					{ headerName: '# MRI fundings / biz day', ...numberColumnDefinition('fundedDetails.mriCountPerBizDay') },
					{ headerName: '# medical deals / biz day', ...numberColumnDefinition('fundedDetails.medicalCountPerBizDay') },
					{ headerName: '# case cost fundings / biz day', ...numberColumnDefinition('fundedDetails.caseCostCountPerBizDay') },
				]
			}
		];

		this.savedCharts.set(SummaryStatisticsChartId.FundedFundings, new CartesianSavedChart(
			`dashboard-summary-statistics-${SummaryStatisticsChartMode.Funded}-chart`,
			() => chartNumberAxesTheme('Summary Statistics - Funded Fundings'),
			tooltipTheme.copy()
		));
		this.savedCharts.set(SummaryStatisticsChartId.FundedDollars, new CartesianSavedChart(
			`dashboard-summary-statistics-${SummaryStatisticsChartMode.Funded}-chart`,
			() => chartPriceAxesTheme('Summary Statistics - Funded Amount'),
			tooltipTheme.copy(),
			1
		));
		this.savedCharts.set(SummaryStatisticsChartId.EffectiveRatesFundings, new CartesianSavedChart(
			`dashboard-summary-statistics-${SummaryStatisticsChartMode.EffectiveRates}-chart`,
			() => chartNumberAxesTheme('Summary Statistics - Effective Rates Fundings'),
			tooltipTheme.copy()
		));
		this.savedCharts.set(SummaryStatisticsChartId.EffectiveRatesDollars, new CartesianSavedChart(
			`dashboard-summary-statistics-${SummaryStatisticsChartMode.EffectiveRates}-chart`,
			() => chartPriceAxesTheme('Summary Statistics - Effective Rates Amount'),
			tooltipTheme.copy(),
			1
		));
		this.totalFundedData$
			.pipe(
				map(data => data.map(summaryStatistics => ({
					...summaryStatistics,
					chartLabel: this.dashboardDataUtilsService.getPeriodLabel(summaryStatistics)
				}))),
				takeUntil(this.destroy$)
			).subscribe(data => {
			this.savedCharts.forEach(savedChart => savedChart.getTooltipTheme().setTooltipData(data));
		});
		this.collapsingIcon$ = this.collapsed$.pipe(
			map((collapsed) => collapsed ? faChevronDown : faChevronUp)
		);
	}

	private getInfoButtonColDef(modalAction): ColDef {
		return this.summaryStatisticsService.getInfoButtonColDef(modalAction);
	}

	public onFirstDataRendered(params: FirstDataRenderedEvent): void {
		params.api.sizeColumnsToFit();
	}

	public onGridReady(params: GridReadyEvent): void {
		this.gridApi = params.api;
		this.summaryStatisticsGraphService.initialize(params.api, this.savedCharts);
		this.createFundedChart();
	}

	private createFundedChart(): void {
		const fundingChart: SummaryStatisticsChartConfig = {
			columns: [
				{ colId: 'fundedDetails.numberOfFundings', chartType: 'groupedColumn' },
				{ colId: 'fundedDetails.numberOfCases', chartType: 'groupedColumn' }
			],
			chartId: SummaryStatisticsChartId.FundedFundings,
			chartElementSelector: this.getChartElement(SummaryStatisticsChartId.FundedFundings)
		};

		const currencyChart: SummaryStatisticsChartConfig = {
			columns: [
				{ colId: 'fundedDetails.totalPrice', chartType: 'groupedColumn' }
			],
			chartId: SummaryStatisticsChartId.FundedDollars,
			chartElementSelector: this.getChartElement(SummaryStatisticsChartId.FundedDollars)
		};

		this.summaryStatisticsGraphService.createCharts([fundingChart, currencyChart]);
	}

	private createEffectiveRatesChart(): void {

		const fundingChart: SummaryStatisticsChartConfig = {
			columns: [
				{ colId: 'fundedDetails.numberOfFundings', chartType: 'groupedColumn' },
				{ colId: 'fundedDetails.numberOfCases', chartType: 'groupedColumn' }
			],
			chartId: SummaryStatisticsChartId.EffectiveRatesFundings,
			chartElementSelector: this.getChartElement(SummaryStatisticsChartId.EffectiveRatesFundings)
		};

		const currencyChart: SummaryStatisticsChartConfig = {
			columns: [
				{ colId: 'fundedDetails.dealsAmountWithEffectiveRates', chartType: 'groupedColumn' }
			],
			chartId: SummaryStatisticsChartId.EffectiveRatesDollars,
			chartElementSelector: this.getChartElement(SummaryStatisticsChartId.EffectiveRatesDollars)
		};

		this.summaryStatisticsGraphService.createCharts([fundingChart, currencyChart]);
	}

	public toggleChartMode(mode: SummaryStatisticsChartMode): void {
		this.mode = mode;
		if (mode === SummaryStatisticsChartMode.Funded) {
			this.createFundedChart();
		} else {
			this.createEffectiveRatesChart();
		}
	}

	public onConfigurationChanged(event: ConfigurationChangedEvent): void {
		this.timeConfiguration$.next(event);
	}

	public onChartOptionsChanged(): void {
		const modelsToSave = this.gridApi.getChartModels();
		const saveCharts = this.getSaveChartsForCurrentMode();
		saveCharts.forEach((savedChart, index) => {
			const colIds = modelsToSave[index].cellRange.columns as string[];
			savedChart.setTooltipIds(colIds);
			savedChart.saveChart(modelsToSave);
		});
		this.summaryStatisticsGraphService.hasSavedChart = true;
	}

	public clearSavedChartState(): void {
		const savedCharts = this.getSaveChartsForCurrentMode();
		savedCharts.forEach(savedChart => savedChart.clearSavedChart());
		if (this.mode === SummaryStatisticsChartMode.Funded) {
			this.createFundedChart();
		} else {
			this.createEffectiveRatesChart();
		}
		this.summaryStatisticsGraphService.hasSavedChart = false;
	}

	private getSaveChartsForCurrentMode(): CartesianSavedChart[] {
		if (this.mode === SummaryStatisticsChartMode.Funded) {
			return [
				this.savedCharts.get(SummaryStatisticsChartId.FundedFundings),
				this.savedCharts.get(SummaryStatisticsChartId.FundedDollars)
			];
		} else {
			return [
				this.savedCharts.get(SummaryStatisticsChartId.EffectiveRatesFundings),
				this.savedCharts.get(SummaryStatisticsChartId.EffectiveRatesDollars)
			];
		}
	}

	private getChartElement(chartId: SummaryStatisticsChartId): string {
		if (this.summaryStatisticsGraphService.isFundingGraph(chartId)) {
			return '#chart';
		} else {
			return '#dollars-chart';
		}
	}

	public toggleCollapsed(): void {
		this.collapsed$.next(!this.collapsed$.value);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.summaryStatisticsGraphService.destroyCharts();
		this.summaryStatisticsModalService.completeDestroyObs();
	}
}
