<div class="dash-panel ibox portfolio-wrapper">
	<div class="portfolio__header">
		<div class="ibox-title dashboard-breakdown-icon flex" (click)="toggleCollapse()">
			<fa-icon [icon]="collapsingIcon$ | async" class="m-r-sm"></fa-icon>
			<h4>Dashboard Portfolio</h4>
		</div>
		<div class="dashboard-round">
			<div class="dashboard-round__toggle">
				<label class="base-label">Round figures to the nearest dollar</label>
				<rp-switch
					data-cy="round-switch"
					[value]="roundValue$ | async"
					(switched)="toggleRound($event)"
				></rp-switch>
			</div>
		</div>
	</div>
	<div class="portfolio__row">
		<div class="portfolio__column">
			<current-month
				id="dashboard-current-month"
				*aclIsAllowed="'dashboard.current_month'"
				[isCollapseManaged]="true">
			</current-month>
			<historical-performance id="dashboard-historical-performance"
									[isCollapseManaged]="true"
									*aclIsAllowed="'dashboard.summary'"></historical-performance>
		</div>
		<div class="portfolio__column">
			<ng-container *aclIsAllowed="'dashboard.summary'">
				<total-ever-purchased id="dashboard-total-purchased"
									  [isCollapseManaged]="true"></total-ever-purchased>
				<current-portfolio id="dashboard-current-portfolio"
								   [isCollapseManaged]="true"></current-portfolio>
			</ng-container>
		</div>
	</div>
</div>
