import { Pipe, PipeTransform } from '@angular/core';
import { FundingHelperService } from '@app/shared/model/funding-helper.service';

@Pipe({ name: 'fundingAmount', standalone: true })
export class FundingAmountPipe implements PipeTransform {

	constructor(
		private fundingHelper: FundingHelperService
	) {
	}

	public transform(funding: any): any {
		if (this.fundingHelper.isPending(funding) || this.fundingHelper.isConsult(funding) || this.fundingHelper.isRejected(funding)) {
			return funding.requestedFunding;
		}
		if (this.fundingHelper.isApproved(funding)) {
			return funding.approvedAt;
		}
		if (this.fundingHelper.isFunded(funding) || this.fundingHelper.isSettled(funding)) {
			return funding.purchasePrice;
		}
	}
}
