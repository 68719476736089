import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserAccount } from '@app/shared/model/types/user-account';

@Component({
	selector: 'user-badge',
	templateUrl: './user-account-badge.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAccountBadgeComponent {
	@Input() account: UserAccount;
}
