<div class="inbox-view" *ngIf="conversations.length > 0 else noConversations">
	<inbox-conversation-list
		class="flex"
		style="position: relative"
		[conversations]="conversations"
		[showLoading]="showLoading"
		(onSelectConversation)="selectConversation($event)"
		(onScrollDown)="fetchMoreConversations()"
		(onSearch)="searchConversations($event)"
	>
	</inbox-conversation-list>
	<inbox-conversation-detail [conversation]="selectedConversation"></inbox-conversation-detail>
</div>
<ng-template #noConversations>
	<h2 class="no-conversations">There are no conversations.</h2>
</ng-template>
