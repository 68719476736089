import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CptCodesFormComponent } from '@app/settings/cpt-codes/form/cpt-codes-form.component';
import { CptCodesListComponent } from '@app/settings/cpt-codes/list/cpt-codes-list.component';
import { SharedModule } from '@app/shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LoadingButtonModule } from '@app/widgets/loading-button/loading-button.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		TabsModule,
		TooltipModule,
		RouterModule,
		AgGridModule,
		ReactiveFormsModule,
		LoadingButtonModule
	],
	declarations: [
		CptCodesListComponent,
		CptCodesFormComponent
	],
})
export class CptCodesModule {

}
