<div *ngIf="conversation; else noConversation">
	<div class="inbox__header">
		<div class="inbox__header__title">
			<a [routerLink]="['/applicants', conversation.id]">{{ conversation?.firstName }} {{ conversation?.lastName }}</a>
			<div class="inbox__header__subtitle">{{ conversation?.internalId }}</div>
		</div>
		<div class="inbox__header__info">
			<div class="inbox__header__info__box">
				<div class="inbox__header__info__box__title">Purchased Amount Total</div>
				<div>{{ conversation?.totalAmountPurchased |currency:'USD':'symbol':'1.2-2' }}</div>
			</div>
			<div *ngIf="lastMessage" class="inbox__header__info__box">
				<div class="inbox__header__info__box__title">Last Message Date</div>
				<div>{{ lastMessage|smsConversationDate }}</div>
			</div>
			<div class="inbox__header__info__box">
				<div class="inbox__header__info__box__title">Phone</div>
				<div>{{ conversation?.phoneNormalized }}</div>
			</div>
			<div class="inbox__header__info__box">
				<div class="inbox__header__info__box__title">Law Firm</div>
				<a *ngIf="conversation?.attorney" [routerLink]="['/law-firms/', conversation.attorney?.lawFirm.id, 'detail']">{{ conversation.attorney?.lawFirm.name }}</a>
			</div>
		</div>
	</div>
	<div class="inbox__discussion" #scrollArea>
		<inbox-conversation-detail-message *ngFor="let sms of conversation?.smsMessages" [sms]="sms"></inbox-conversation-detail-message>
	</div>
	<div class="inbox__footer">
		<textarea [(ngModel)]="newSms.body" class="base-input base-input--font-small" placeholder="Hi {{ conversation?.firstName }}, ..." ng-required="true"></textarea>
		<div class="inbox__footer__actions">
			<div (clickOutside)="hideTemplateSelectList()" class="base-flex base-flex--align-center" style="position: relative; justify-content: center;">
				<button (click)="toggleTemplateSelectList()" class="base-button">Templates</button>
				<div *ngIf="showTemplateList" [@fadeUpDown] class="select-list top right">
					<div class="select-list__triangle" style="transform: translateX(40px)"></div>
					<div class="select-list__wrapper">
						<div *ngFor="let template of templates" (click)="selectTemplate(template)" class="select-list__item">
							{{ template.title|truncate:0:80:'...' }}
						</div>
						<a [routerLink]="['/settings/code-lists']" class="select-list__item">
							+ Add New Template
						</a>
					</div>
				</div>
			</div>
			<button class="base-button" (click)="sendSms()"><fa-icon [icon]="faIcons.paperPlane" style="margin-right: 10px;"></fa-icon> Send</button>
		</div>
	</div>
</div>
<ng-template #noConversation>
	<div class="inbox__no-conversation">
		Please select conversation
	</div>
</ng-template>
