import { faSort, faSortDown, faSortUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';

export enum SortDirection {
	None,
	Asc,
	Desc
}

interface SortSettingProperty {
	name: string;
	path: string;
}

interface SortSettingDirection {
	id: SortDirection;
	icon: IconDefinition;
}

export abstract class SortSettings {
	property: SortSettingProperty;
	direction: SortSettingDirection;

	constructor(property: SortSettingProperty, directionId: SortDirection) {
		this.property = property
		this.direction = { id: directionId, icon: this.getSortIcon(directionId) };
	}

	public abstract ascendingSort(valueA, valueB): number;
	public abstract descendingSort(valueA, valueB): number;

	public sortFn(fundingA, fundingB): number {
		const valueA = this.getValue(fundingA);
		const valueB = this.getValue(fundingB);

		if (this.direction.id == SortDirection.Asc) {
			return this.ascendingSort(valueA, valueB);
		} else {
			return this.descendingSort(valueA, valueB);
		}

	}

	protected getValue(funding): any {
		return get(funding, this.property.path, null);
	}

	public setProperty(property: SortSettingProperty): void {
		this.property = property;
	}

	public isSortEnabled(): boolean {
		return this.direction.id !== SortDirection.None && this.property.path !== null;
	}

	public setDirection(directionId: SortDirection): void {
		this.direction = { id: directionId, icon: this.getSortIcon(directionId) };
	}

	public nextDirection(): void {
		this.direction.id = this.direction.id + 1 > SortDirection.Desc ? SortDirection.None : ++this.direction.id;
		this.direction.icon = this.getSortIcon();
	}

	private getSortIcon(direction = this.direction.id): IconDefinition {
		switch (direction) {
			case SortDirection.Asc:
				return faSortUp;
			case SortDirection.Desc:
				return faSortDown;
			case SortDirection.None:
				return faSort;
		}
	}

	public toJSON(): string {
		const obj = {
			propertyPath: this.property.path,
			directionId: this.direction.id,
		};
		return JSON.stringify(obj);
	}

}
