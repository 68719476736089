import { formatNumber } from '@angular/common';
import { Inject, Injectable, Type } from '@angular/core';
import { Validators } from '@angular/forms';
import { GaCheckboxEditorComponent } from '@app/shared/ga-components/components/editors/ga-checkbox-editor.component';
import { GaDatepickerEditorComponent } from '@app/shared/ga-components/components/editors/ga-datepicker-editor.component';
import { GaEditorParams } from '@app/shared/ga-components/components/editors/ga-editor.params';
import { GaInputEditorComponent } from '@app/shared/ga-components/components/editors/ga-input-editor.component';
import { GaSelectEditorComponent } from '@app/shared/ga-components/components/editors/ga-select-editor/ga-select-editor.component';
import { GaTextareaEditorComponent } from '@app/shared/ga-components/components/editors/ga-textarea-editor.component';
import { GaBadgeRendererComponent } from '@app/shared/ga-components/components/renderers/ga-badge-renderer.component';
import { GaTextRendererComponent } from '@app/shared/ga-components/components/renderers/ga-text-renderer.component';
import { GA_CONFIG, IGaConfig } from '@app/shared/ga-components/ga-components.config';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { AsyncValidatorOptions } from '@app/shared/ga-components/utils/async-validator-options';
import { FieldMetadata } from '@app/shared/ga-components/utils/field-metadata';
import { getMatches } from '@app/shared/ga-components/utils/get-matches';
import { IEditor } from '@app/shared/ga-components/utils/IEditor';
import { IRenderer } from '@app/shared/ga-components/utils/IRenderer';
import { JqlAsyncValidatorOptions } from '@app/shared/ga-components/utils/jql-async-validator-options';
import { UniqueFieldValidator } from '@app/shared/ga-components/utils/unique-field-validator';
import { isObject } from '@app/shared/general-helper';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

/*
 *
 * TODO:
 * whole concept of resolver needs to be redesigned - there should be "default resolver", "RP resolver - configuration"
 * each Renderer/Editor should have its own params resolver
 * somehow ability to override resolver by decorators
 */
@Injectable({ providedIn: 'root' })
export class GaResolverService {

	constructor(
		private apiLink: GaApiLink,
		@Inject(GA_CONFIG) private config: IGaConfig,
		private uniqueFieldValidator: UniqueFieldValidator
	) {
	}

	public resolveFormatter(metadata: Partial<FieldMetadata>): (...input) => string {
		switch (metadata.type) {
			case 'boolean':
				return (value) => value !== undefined ? (value ? 'Yes' : 'No') : 'N/A';
			case 'smallint':
			case 'integer':
			case 'bigint':
				return (value) => value !== null ? formatNumber(value, 'en-US') : null;
			case 'decimal':
			case 'float':
				return (value) => value !== null ? formatNumber(value, 'en-US', '1.2-2') : null;
			case 'string':
				return (value) => value !== null ? value : 'N/A';
			case 'date':
			case 'date_immutable':
			case 'ga_date':
			case 'ga_date_immutable':
				return (value) => value ? moment(value).format('MMM D, YYYY') : 'N/A';
			case 'datetime':
			case 'datetime_immutable':
				return (value) => value ? moment(value).format('MMM D, YYYY, h:mm:ss A') : 'N/A';
			case 'association':
				return (value) => {
					if (value) {
						return isObject(value)
							? _.template(metadata.association.toStringTemplate, { interpolate: this.config.templatePattern })(value)
							: value;
					}
					return 'N/A';
				};
			default:
				return (value) => value;
		}
	}

	public resolveRenderer(metadata: FieldMetadata): Type<IRenderer> {
		switch (metadata.type) {
			case 'boolean':
				return GaBadgeRendererComponent;
			default:
				return GaTextRendererComponent;
		}
	}

	public resolveEditor(metadata: FieldMetadata): Type<IEditor> {
		switch (metadata.type) {
			case 'association':
				return GaSelectEditorComponent;
			case 'boolean':
				return GaCheckboxEditorComponent;
			case 'text':
				return GaTextareaEditorComponent;
			case 'date':
			case 'date_immutable':
			case 'ga_date':
			case 'datetime_immutable':
			case 'datetime':
			case 'ga_date_immutable':
				return GaDatepickerEditorComponent;
			default:
				return GaInputEditorComponent;
		}
	}

	public resolveParser(metadata: Partial<FieldMetadata>): any {
		switch (metadata.type) {
			case 'boolean':
				return value => value !== undefined ? (value ? 1 : 0) : null;
			case 'smallint':
			case 'integer':
			case 'bigint':
				return value => value ? parseInt(value.toString().replace(',', ''), 10) : 0;
			case 'decimal':
			case 'float':
				return value => value ? parseFloat(value.toString().replace(',', '')) : 0.00;
			case 'date':
			case 'date_immutable':
			case 'ga_date':
			case 'ga_date_immutable':
				return value => value ? moment(value).format('YYYY-MM-DD') : null;
			case 'datetime':
			case 'datetime_immutable':
				return value => value ? moment(value).utc().format() : null;
			case 'association':
				return value => value;
			default:
				return value => value;
		}
	}

	public resolveGetter(metadata: FieldMetadata, path: string) {
		switch (metadata.type) {
			default:
				return (value) => _.get(value, path);
		}
	}

	public resolveEditorParams(editor: Type<IEditor>, metadata: FieldMetadata): any {
		switch (editor) {
			case GaSelectEditorComponent:
				return {
					multiple: metadata.association.isToMany(),
					onInput: (terms: Observable<string>) => {
						return terms.pipe(
							debounceTime(200),
							distinctUntilChanged(),
							switchMap(term => {
								const fieldList = getMatches(metadata.association.toStringTemplate, this.config.templatePattern, 1);
								const userFilter = _.zipObject(
									fieldList, _.times(fieldList.length, () => ({ '%like': '%' + term + '%' }))
								);
								const filter = _.merge({}, { '%or': userFilter }, metadata.constraints);
								return this.apiLink.retrieve(metadata.association.targetEntity, fieldList, filter);
							})
						);
					}
				};
			case GaDatepickerEditorComponent:
				return {
					cssClass: '',
					initializeDatepicker: true
				};
			default:
				return new GaEditorParams();
		}
	}

	public resolveRendererParams(editor: Type<IRenderer>, metadata: FieldMetadata): any {
		switch (editor) {
			case GaBadgeRendererComponent:
				if (metadata.type == 'boolean') {
					return {
						getCssClass: (value) => {
							return value !== undefined ? (value ? 'badge-success' : 'badge-danger') : 'badge-default';
						}
					};
				}
				break;
		}
	}

	public resolveValidator(validator: string | ((data) => unknown), validatorOptions?: JqlAsyncValidatorOptions | AsyncValidatorOptions) {
		switch (validator) {
			case 'NotBlank':
			case 'NotNull' :
				return Validators.required;
			case 'Email':
				return Validators.email;
			case 'UniqueAsync':
				return this.uniqueFieldValidator.validate(validatorOptions as JqlAsyncValidatorOptions);
			default:
				return validator;
		}
	}

	public resolveToStringFn(metadata: FieldMetadata): (value) => string | null {
		if (metadata.type == 'association' && !metadata.isCodelist) {
			const valueFormatter = this.resolveFormatter(metadata);
			return value => {
				const data = Array.isArray(value) ? value : _.get(value, metadata.fieldName, [value]);
				return Array.isArray(data)
					? data.reduce((acc, val) => `${acc + valueFormatter(val)} | `, '').slice(0, -2) : valueFormatter(data);
			};
		}
		return null;
	}
}
