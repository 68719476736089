<collapsible-item>
	<h4 header-left>Chart:</h4>
	<div class="row">
		<div class="col-md-8">
			<div class="dashboard-chart" [id]="this.path + '-breakdown-chart'"></div>
		</div>
		<div class="col-md-4">
			<h4>Configuration</h4>
			<div class="form-group">
				<label>Display:</label>
				<div class="base-button-group small full-width">
					<button [disabled]="!acl.isAllowed('dashboard.collected_stats')"
					        (click)="toggleChartMode(chartModes.CollectedAmount)" class="base-button"
					        [ngClass]="{'active': mode == chartModes.CollectedAmount }" type="button">Collected $
					</button>
					<button (click)="toggleChartMode(chartModes.FundedAmount)" class="base-button"
					        [ngClass]="{'active': mode == chartModes.FundedAmount }" type="button">Funded $
					</button>
					<button [disabled]="!acl.isAllowed('dashboard.collected_stats')"
					        (click)="toggleChartMode(chartModes.CollectedCount)" class="base-button"
					        [ngClass]="{'active': mode == chartModes.CollectedCount }" type="button">Collected #
					</button>
					<button (click)="toggleChartMode(chartModes.FundedCount)" class="base-button"
					        [ngClass]="{'active': mode == chartModes.FundedCount }" type="button">Funded #
					</button>
					<button (click)="toggleChartMode(chartModes.NewCases)" class="base-button"
					        [ngClass]="{'active': mode == chartModes.NewCases }" type="button">New Cases
					</button>
					<button (click)="toggleChartMode(chartModes.EffectiveRates)" class="base-button"
							[ngClass]="{'active': mode == chartModes.EffectiveRates }" type="button">Effective Rates
					</button>
				</div>
			</div>
			<time-related-configuration (configurationChanged)="onConfigurationChanged($event)"></time-related-configuration>
		</div>
	</div>
</collapsible-item>
<collapsible-item class="ibox">
	<h4 header-left>
		<fa-icon [icon]="faIcons.table" class="m-r-sm"></fa-icon>
		Data Tables:
	</h4>
	<div class="ibox-content">
		<ag-grid-angular
			style="width: 100%; height: 500px;"
			class="ag-theme-balham"
			[gridOptions]="gridOptions"
			[rowData]="rowData$ | async"
			(columnVisible)="recreateChart()"
			(gridReady)="onGridReady($event)"
		></ag-grid-angular>
	</div>
</collapsible-item>
