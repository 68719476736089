import { animate, state, style, transition, trigger } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
	state('void', style({ opacity: 0 })),
	transition(':enter, :leave', [
		animate('0.2s ease-out')
	]),
]);

export const fadeInRight = trigger('fadeInRight', [
	state('void', style({ opacity: 0, transform: 'translateX(-20px)' })),
	transition(':enter, :leave', [
		animate('0.2s ease-out')
	]),
]);

export const fadeInLeft = trigger('fadeInRight', [
	state('void', style({ opacity: 0, transform: 'translateX(20px)' })),
	transition(':enter, :leave', [
		animate('0.2s ease-out')
	]),
]);

export const fadeUpDown = trigger('fadeUpDown', [
	state('void', style({ opacity: 0, transform: 'translateY(20px)' })),
	transition(':enter, :leave', [
		animate('0.2s ease-out')
	]),
]);

export const heightGrow = trigger('heightGrow', [
	state('void', style({ height: 0 })),
	transition(':enter, :leave', [
		animate('0.2s ease-out')
	]),
]);

export const highlight = trigger('highlight', [
	state('false', style({})),
	state('true', style({ backgroundColor: '#f8ac59', opacity: 0.5 })),
	transition('0 => 1', [
		animate('500ms ease-in-out')
	]),
	transition('1 => 0', [
		animate('500ms ease-in-out')
	])
]);
