import { Directive, Input } from '@angular/core';

@Directive({
	selector: '[gaFor]',
})
export class GaForDirective {

	@Input() gaForOf;

}
