import { AfterViewInit, Directive, ViewChild } from '@angular/core';
import { MarkdownEditorComponent } from 'ngx-markdown-editor';

@Directive({
	selector: '[md-resize]',
	standalone: true
})
export class MdResizeDirective implements AfterViewInit {
	@ViewChild(MarkdownEditorComponent) mdEditor: MarkdownEditorComponent;

	public ngAfterViewInit(): void {
		this.mdEditor.editorResize();
	}
}
