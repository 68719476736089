import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardWidgetDateFilterComponent } from '@app/dashboard/shared/dashboard-widget-date-filter/dashboard-widget-date-filter.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
	imports: [
		CommonModule,
		BsDatepickerModule,
		ReactiveFormsModule
	],
	declarations: [
		DashboardWidgetDateFilterComponent
	],
	exports: [
		DashboardWidgetDateFilterComponent
	]
})
export class DashboardWidgetDateFilterModule {}
