import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { NgSelectSearchingHelperService } from '@app/shared/model/ng-select-searching-helper.service';

@Component({
	selector: 'codelist-multiselect',
	templateUrl: './codelist-multiselect.component.html',
	styleUrls: ['codelist-multiselect.component.scss']
})
export class CodelistMultiselectComponent implements OnInit {
	@Input() selectControl = new UntypedFormControl([]);
	@Input() label = '';
	@Input() codelistClass: string;
	@Input() extraOptions: { id: string, title: string, colorHex?: string }[] = [];
	@Input() placeholder = '';
	@Input() dataCyTag = 'codelistMultiselect';

	items: any[];

	constructor(private codelistRepository: CodelistRepositoryService,
				public searchingHelper: NgSelectSearchingHelperService) {
	}

	public ngOnInit(): void {
		this.codelistRepository.get<any>(this.codelistClass)
			.subscribe((items) => this.items = this.extraOptions ? [...this.extraOptions, ...items] : items);
	}

	public selectAll(): void {
		const ids = this.items.map(item => item.id);
		this.selectControl.setValue(ids);
	}

	public selectNone(): void {
		this.selectControl.setValue([]);
	}
}
