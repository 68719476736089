import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';
import { FeatureFlagService } from '../unleash/feature-flag.service';

@Injectable({
	providedIn: 'root'
})
export class ResponsiveService {

	isMobile$: Observable<boolean>;

	private readonly breakpointsToObserve = [Breakpoints.Small, Breakpoints.HandsetPortrait];

	constructor(
		private breakpointObserver: BreakpointObserver,
		private featureFlagService: FeatureFlagService) {
		this.isMobile$ = this.breakpointObserver.observe(this.breakpointsToObserve)
			.pipe(map((state: BreakpointState) => state.matches));
	}
}
