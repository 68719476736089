import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class PublicViewEventBusService {

	loadingEventBus$ = new Subject<boolean>();

	public toggleLoadingSpinner(value: boolean): void {
		this.loadingEventBus$.next(value);
	}

	public listenerLoadingSpinner(): Observable<boolean> {
		return this.loadingEventBus$.asObservable();
	}

}
