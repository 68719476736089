<div class="option-button"  [ngClass]="[optionsPlacement, customClass]" (clickOutside)="isOpen = false">
    <button *ngIf="showActionButton"
			(click)="onButtonClick($event)"
			data-cy="snooze-btn"
			class="option-button__name">
			{{label}}
	</button>
    <div class="option-button__list" [class.option-button__list--open]="isOpen" data-cy="snoozeOptions-toggle" (click)="isOpen = !isOpen">
        <div class="option-button__list__value">
            <span *ngIf="selectedOption">{{selectedOption.name}}</span>
            <span *ngIf="!selectedOption">Option</span>
        </div>
        <ul class="option-button__list__wrapper">
            <li *ngFor="let option of options.slice().reverse(); let i = index" [attr.data-cy]="'option-' + i" class="option-button__list__item" (click)="selectOption(option)">{{option.name}}</li>
        </ul>
    </div>
</div>
