<div class="doctor-addresses-widget" data-cy="doctor-addresses" (clickOutside)="closeOtherAddresses()">
	<div *ngIf="mainAddress" class="doctor-addresses-widget__main-address">
		<div data-cy="mainAddress-street">{{ mainAddress.street }}</div>
		<div data-cy="mainAddress-city">{{ mainAddress.city }}</div>
		<div>
			<span data-cy="mainAddress-zip">{{ mainAddress.zip }}</span>
			<span data-cy="mainAddress-state" *ngIf="mainAddress.state">, {{ mainAddress.state.title }}</span>
		</div>
		<div *ngIf="mainAddress.geographicLocation" data-cy="mainAddress-location">
			{{ mainAddress.geographicLocation.title }}
		</div>
	</div>
	<ng-content></ng-content>
	<div *ngIf="addresses && addresses.length > 1" data-cy="toggleOtherAddresses-btn" (click)="toggleOtherAddresses()" class="doctor-addresses-widget__toggle">Show all <fa-icon [icon]="faIcons.chevronDown" [class.active]="showOtherAddresses"></fa-icon></div>
	<div *ngIf="showOtherAddresses" class="doctor-addresses-widget__all" [@fadeUpDown]>
		<div *ngFor="let address of addresses; let i = index" [attr.data-cy]="'otherAddress-' + i" class="doctor-addresses-widget__all__row">
			<div *ngIf="address.title" data-cy="address-title" class="doctor-addresses-widget__address-title">{{ address.title }}</div>
			<div *ngIf="address.active" data-cy="activeAddress-flag" class="doctor-addresses-widget__main-marker"></div>
			<address>
				<doctor-address [address]="address"></doctor-address>
				<div *ngIf="address.phone || address.email" class="doctor-addresses-widget__contact-info">
					<div *ngIf="address.phone" class="doctor-addresses-widget__value-row">
						<div class="doctor-addresses-widget__label">Phone:</div>
						<div class="doctor-addresses-widget__value" data-cy="address-phone">{{ address.phone }}</div>
					</div>
					<div *ngIf="address.email" class="doctor-addresses-widget__value-row">
						<div class="doctor-addresses-widget__label">Email:</div>
						<div class="doctor-addresses-widget__value" data-cy="address-email">{{ address.email }}</div>
					</div>
				</div>
			</address>
		</div>
	</div>
</div>
