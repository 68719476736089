import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

	public transform(value: any[], order: 'asc' | 'desc' = 'desc', column = 'id', isNumber = true): any[] {
		if (!value) return value;
		let formattedValue = [...value];
		if (isNumber) {
			formattedValue = value.map(item => ({ ...item, [column]: parseInt(item[column]) }));
		}
		return _.orderBy(formattedValue, [column], [order]);
	}

}
