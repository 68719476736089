import { Pipe, PipeTransform } from '@angular/core';
import { LoaTypeCode } from '@app/shared/model/constants/loa-type-code';
import { LoaType } from '@app/shared/model/types/codelists/loa-type';

@Pipe({ name: 'loaTypeColor' })
export class LoaTypeColorPipe implements PipeTransform {
	public transform(loaType: LoaType): string {
		switch (loaType?.systemCode) {
			case LoaTypeCode.LongTerm:
			case LoaTypeCode.LongTermWithAA:
				return 'background__success';
			case LoaTypeCode.OneOff:
				return 'background__warning';
			default:
				return 'background__danger';
		}
	}
}
