import { Injectable } from '@angular/core';
import { AddressMarker } from '@app/applicant/applicant-detail/applicant-detail-main-section/applicant-detail-main-section-applicant/applicant-detail-main-section-applicant-map/model/address-marker';
import { Address } from '@app/shared/model/types/address';
import MarkerOptions = google.maps.MarkerOptions;

@Injectable()
export class MedicalMarkersMapHelperService {

	public createMarker(label: string, options: MarkerOptions, address: Address): AddressMarker {
		return {
			position: { lat: address.addressLat, lng: address.addressLng },
			label: { text: label },
			options: options,
			street: address.street ?? '',
			city: address.city ?? '',
			stateCode: address.state?.code ?? '',
			zip: address.zip ?? ''
		};
	}
}
