import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { AclGuard } from '@app/core/security/acl.guard';
import { UseCase } from '@app/core/security/use-case';
import { InboxComponent } from '@app/sms/inbox/inbox.component';

const routes: Routes = [
	{
		path: 'sms-inbox',
		component: InboxComponent,
		canActivate: [AuthGuard, AclGuard],
		data: { security: UseCase.for('applicants.sms'), title: 'SMS Inbox' }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SmsRoutingModule {
}
