<div class="form-group">
	<label>Resolution:</label>
	<div class="base-button-group small full-width">
		<button (click)="toggleChartResolution(Resolution.Monthly)" class="base-button"
				[ngClass]="{'active': resolution == Resolution.Monthly }" type="button">Monthly
		</button>
		<button (click)="toggleChartResolution(Resolution.Quarterly)" class="base-button"
				[ngClass]="{'active': resolution == Resolution.Quarterly }" type="button">Quarterly
		</button>
		<button (click)="toggleChartResolution(Resolution.Annually)" class="base-button"
				[ngClass]="{'active': resolution == Resolution.Annually }" type="button">Annually
		</button>
	</div>
</div>
<ng-container *ngrxLet="periodEmitter$; let period">
	<div class="form-group">
		<label>Period: </label>
		<div class="radio">
			<label><input type="radio" (click)="togglePeriod(Period.Last12Months)"
						  class="btn btn-sm btn-white margin-between"
						  [checked]="period == Period.Last12Months">Last 12 Months</label>
		</div>
		<div class="radio">
			<label><input type="radio" (click)="togglePeriod(Period.Lifetime)"
						  class="btn btn-sm btn-white margin-between"
						  [checked]="period == Period.Lifetime">Lifetime</label>
		</div>
		<div class="radio">
			<label><input type="radio" (click)="togglePeriod(Period.Custom)"
						  class="btn btn-sm btn-white margin-between"
						  [checked]="period == Period.Custom">Custom</label>
		</div>
	</div>
	<div *ngIf="period == Period.Custom" class="resolution-picker__period">
		<div class="form-group">
			<label>Start</label>
			<ng-select
				class="base-input"
				[items]="startDateOptions$ | async"
				[formControl]="startDateControl">
			</ng-select>
		</div>
		<div class="form-group">
			<label>End</label>
			<ng-select
				class="base-input"
				[items]="endDateOptions$ | async"
				[formControl]="endDateControl">
			</ng-select>
		</div>
	</div>
</ng-container>
