import { AgToggleRendererComponent } from '@app/shared/ag-grid-utils/ag-toggle-renderer/ag-toggle-renderer.component';
import { ColDef } from 'ag-grid-community';
import * as _ from 'lodash';

export function toggleColumnDefinition(field: string): ColDef {
	return {
		field,
		valueGetter: ({ data }) => _.get(data, field, false) ? 'Yes' : 'No',
		maxWidth: 120,
		cellRenderer: AgToggleRendererComponent,
		comparator: (valueA, valueB) => {
			const activeNodeA = valueA === 'Yes';
			const activeNodeB = valueB === 'Yes';
			if (activeNodeA == activeNodeB) {
				return 0;
			}
			return activeNodeA > activeNodeB ? 1 : -1;
		},
		filter: 'agSetColumnFilter',
		enableRowGroup: false,
		enableValue: false,
	};
}
