import { Directive, Input } from '@angular/core';

@Directive({
	selector: '[rpTemplateObject]',
	exportAs: 'rpTemplateObject'
})
export class TemplateObjectDirective {
	[keys: string] : any;

	@Input('rpTemplateObject') set assign(value: any) {
		if(value) {
			Object.assign(this, value);
		}
	}
}
