import { CommonModule, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogModule } from '@app/shared/confirmation-dialog/confirmation-dialog.module';
import { GaEditorComponent } from '@app/shared/ga-components/components/basic/ga-editor.component';
import { GaLabelComponent } from '@app/shared/ga-components/components/basic/ga-label.component';
import { GaPageTitleDirective } from '@app/shared/ga-components/components/basic/ga-page-title.directive';
import { GaValidationLabelsComponent } from '@app/shared/ga-components/components/basic/ga-validation-labels/ga-validation-labels.component';
import { GaValueComponent } from '@app/shared/ga-components/components/basic/ga-value.component';
import { GaCollectionItemDirective } from '@app/shared/ga-components/components/collection/ga-collection-item.directive';
import { GaCollectionComponent } from '@app/shared/ga-components/components/collection/ga-collection.component';
import { GaForDirective } from '@app/shared/ga-components/components/collection/ga-for.directive';
import { GaCheckboxEditorComponent } from '@app/shared/ga-components/components/editors/ga-checkbox-editor.component';
import { GaDatepickerEditorComponent } from '@app/shared/ga-components/components/editors/ga-datepicker-editor.component';
import { GaInputEditorComponent } from '@app/shared/ga-components/components/editors/ga-input-editor.component';
import { GaLargeInputEditorComponent } from '@app/shared/ga-components/components/editors/ga-large-input-editor.component';
import { GaSelectEditorComponent } from '@app/shared/ga-components/components/editors/ga-select-editor/ga-select-editor.component';
import { GaSwitchEditorComponent } from '@app/shared/ga-components/components/editors/ga-switch-editor.component';
import { GaTextareaEditorComponent } from '@app/shared/ga-components/components/editors/ga-textarea-editor.component';
import { GaEntityDirective } from '@app/shared/ga-components/components/entity/ga-entity.directive';
import { GaFormFieldComponent } from '@app/shared/ga-components/components/field/ga-form-field/ga-form-field.component';
import { GaGroupDirective } from '@app/shared/ga-components/components/group/ga-group.directive';
import { GaActionButtonsRendererComponent } from '@app/shared/ga-components/components/renderers/ga-action-buttons-renderer.component';
import { GaBadgeRendererComponent } from '@app/shared/ga-components/components/renderers/ga-badge-renderer.component';
import { GaLinkRendererComponent } from '@app/shared/ga-components/components/renderers/ga-link-renderer.component';
import { GaSimpleRendererComponent } from '@app/shared/ga-components/components/renderers/ga-simple-renderer.component';
import { GaTextRendererComponent } from '@app/shared/ga-components/components/renderers/ga-text-renderer.component';
import { GaToggleSwitchRendererComponent } from '@app/shared/ga-components/components/renderers/ga-toggle-switch-renderer/ga-toggle-switch-renderer.component';
import { CodelistDecorator } from '@app/shared/ga-components/decorators/codelist.decorator';
import { ConditionalCodelistDecorator } from '@app/shared/ga-components/decorators/conditional-codelist.decorator';
import { CurrencyDecorator } from '@app/shared/ga-components/decorators/currency.decorator';
import { PercentageDecorator } from '@app/shared/ga-components/decorators/percentage.decorator';
import { StateSelectboxDecorator } from '@app/shared/ga-components/decorators/state-selectbox.decorator';
import { TimeAgoDecorator } from '@app/shared/ga-components/decorators/timeAgo.decorator';
import { WeeksAgoDecorator } from '@app/shared/ga-components/decorators/weeksAgo.decorator';
import { GA_CONFIG, GaConfig } from '@app/shared/ga-components/ga-components.config';
import { ComponentMetadataBuilderService } from '@app/shared/ga-components/services/component-metadata-builder.service';
import { DecoratorProviderService } from '@app/shared/ga-components/services/decorator-provider.service';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { GaResolverService } from '@app/shared/ga-components/services/ga-resolver.service';
import { MetadataProviderService } from '@app/shared/ga-components/services/metadata-provider.service';
import { SanitizeService } from '@app/shared/ga-components/services/sanitize.service';
import { FormScopeSavingProgressHelper } from '@app/shared/ga-components/utils/form-scope-saving-progress';
import { GaAnimatedSaveButtonDirective } from '@app/shared/ga-components/utils/ga-animated-save-button.directive';
import { UniqueFieldValidator } from '@app/shared/ga-components/utils/unique-field-validator';
import { ValidationHelper } from '@app/shared/ga-components/utils/validation-helper';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { SwitchComponent } from '@app/shared/switch/switch.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ConfirmationDialogModule,
		NgSelectModule,
		BsDatepickerModule,
		AgGridModule,
		ModalModule.forChild(),
		RouterModule,
		InfiniteScrollModule,
		PipesModule,
		FontAwesomeModule
	],
	declarations: [
		GaValueComponent,
		GaLabelComponent,
		GaFormFieldComponent,
		GaEditorComponent,
		GaEntityDirective,
		GaTextRendererComponent,
		GaLinkRendererComponent,
		GaBadgeRendererComponent,
		GaInputEditorComponent,
		GaSelectEditorComponent,
		GaDatepickerEditorComponent,
		GaCheckboxEditorComponent,
		GaTextareaEditorComponent,
		GaToggleSwitchRendererComponent,
		GaGroupDirective,
		GaCollectionComponent,
		GaCollectionItemDirective,
		GaForDirective,
		GaSwitchEditorComponent,
		SwitchComponent,
		GaActionButtonsRendererComponent,
		GaPageTitleDirective,
		GaLargeInputEditorComponent,
		GaSimpleRendererComponent,
		GaAnimatedSaveButtonDirective,
		GaValidationLabelsComponent,
	],
	exports: [
		GaValueComponent,
		GaLabelComponent,
		GaFormFieldComponent,
		GaEditorComponent,
		GaEntityDirective,
		GaGroupDirective,
		GaCollectionComponent,
		GaCollectionItemDirective,
		GaForDirective,
		GaSwitchEditorComponent,
		SwitchComponent,
		GaPageTitleDirective,
		GaSelectEditorComponent,
		GaAnimatedSaveButtonDirective,
		GaValidationLabelsComponent,
	],
	providers: [
		ValidationHelper,
		UniqueFieldValidator,
		GaResolverService,
		ComponentMetadataBuilderService,
		GaApiLink,
		SanitizeService,
		CodelistDecorator,
		ConditionalCodelistDecorator,
		CurrencyDecorator,
		PercentPipe,
		PercentageDecorator,
		DecoratorProviderService,
		MetadataProviderService,
		TimeAgoDecorator,
		WeeksAgoDecorator,
		StateSelectboxDecorator,
		FormScopeSavingProgressHelper,
		{ provide: GA_CONFIG, useValue: GaConfig },
	]
})
export class GaComponentsModule {
}
