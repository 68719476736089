export type UserAccountBreakdownInfo = {
	collectedAmount: number;
	collectedCount: number;
	fundedAmount: number;
	fundedCount: number;
	id: number;
	name: string;
	newCases: number;
	dealsAmountWithEffectiveRates: number;
}

export type ChartBreakdownResponse<T extends string> = {
	[key in T]: UserAccountBreakdownInfo[];
} & {
	month?: number;
	quarter?: number;
	title: string;
	year: number;
}

export type ChartBreakdown<T extends string> =
	Omit<ChartBreakdownResponse<T>, T>
	& { [key in T]: Record<string, UserAccountBreakdownInfo> }
	& { chartLabel: string }

export enum BreakdownChartMode {
	CollectedAmount = 'collectedAmount',
	FundedAmount = 'fundedAmount',
	CollectedCount = 'collectedCount',
	FundedCount = 'fundedCount',
	NewCases = 'newCases',
	EffectiveRates = 'dealsAmountWithEffectiveRates'
}
