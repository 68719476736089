import { Component, Input, OnChanges } from '@angular/core';
import { fadeUpDown } from '@app/shared/animations/animations';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DoctorAddress } from '@app/shared/model/types/doctor-address';

@Component({
	selector: 'doctor-address-book',
	templateUrl: './doctor-address-book.component.html',
	animations: [fadeUpDown],
})
export class DoctorAddressBookComponent implements OnChanges {
	@Input() addresses;
	@Input() mainAddress: DoctorAddress = undefined;

	readonly faIcons = {
		chevronDown: faChevronDown,
	};

	showOtherAddresses = false;

	public ngOnChanges(): void {
		if (this.mainAddress !== undefined) {
			return;
		}
		if (this.addresses) {
			const activeAddresses = this.addresses.filter((address) => address.active === true);
			this.mainAddress = activeAddresses.length > 0 ? activeAddresses[0] : null;
		} else {
			this.mainAddress = undefined;
		}
	}

	public toggleOtherAddresses(): void {
		this.showOtherAddresses = !this.showOtherAddresses;
	}

	public closeOtherAddresses(): void {
		this.showOtherAddresses = false;
	}
}
