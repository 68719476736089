import { Component } from '@angular/core';
import { ServicingReportColumns } from '@app/shared/model/types/servicing-report-columns';
import { DownloadLinkConfig } from '@app/widgets/download-link/download-link-config.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
	selector: 'servicing-options-modal',
	templateUrl: './servicing-options-modal.component.html'
})
export class ServicingOptionsModalComponent {
	downloadLink$: Subject<DownloadLinkConfig> = new Subject<DownloadLinkConfig>();
	columns = [
		{id: ServicingReportColumns.AttorneysAndLawFirms, title: 'Attorney & Law Firm', include: false},
		{id: ServicingReportColumns.ClaimantName, title: 'Claimant Name', include: true},
	];

	constructor(private bsModalRef: BsModalRef) {
	}

	public download(): void {
		this.downloadLink$.next({
			url: '/api/reports/servicing-report',
			customHttpParams: {
				columns: JSON.stringify(this.columns)
			}
		});
		this.bsModalRef.hide();
	}
}
