<label (click)="assignNewValue()" class="toggle-switch" [style.box-shadow]="borderColor">
	<div class="toggle-switch__control"
	     [ngClass]="{'toggle-switch__control--on' : value == params.on.value, 'toggle-switch__control--off' : value == params.off.value}">
		<div class="toggle-switch__control__checked"
		     [style.background-color]="value ? defaultColors.checkedColor : defaultColors.uncheckedColor">{{ params.on.label }}</div>
		<div class="toggle-switch__control__slider" [style.background-color]="defaultColors.sliderColor"></div>
		<div class="toggle-switch__control__unchecked"
		     [style.background-color]="defaultColors.uncheckedColor">{{ params.off.label }}</div>
	</div>
</label>
<span style="margin-left: 0.5rem;" *ngIf="additionalDate">{{ additionalDate }}</span>
