import { Injectable } from '@angular/core';
import { AclResourceType } from '@app/core/security/acl-resource-type';

@Injectable({
	providedIn: 'root'
})
export class PermissionsStorageService {

	private permissions = new Map<AclResourceType, Map<string, boolean>>();

	public set(resourceType: AclResourceType, resourceId: string, value: boolean) {
		if (!this.permissions.has(resourceType)) {
			this.permissions.set(resourceType, new Map<string, boolean>());
		}
		this.permissions.get(resourceType).set(resourceId, value);
	}

	public get(resourceType: AclResourceType, resourceId: string): boolean {
		if (!this.permissions.has(resourceType)) {
			return false;
		}

		return this.permissions.get(resourceType).get(resourceId);
	}

	public getAll(): Map<AclResourceType, Map<string, boolean>> {
		return this.permissions;
	}

	public has(resourceType: AclResourceType, resourceId: string): boolean {
		if (!this.permissions.has(resourceType)) {
			return false;
		}

		return this.permissions.get(resourceType).has(resourceId);
	}
}
