import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { AclGuard } from '@app/core/security/acl.guard';
import { UseCase } from '@app/core/security/use-case';
import { DashboardComponent } from '@app/dashboard/dashboard.component';

const routes: Routes = [
	{
		path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, AclGuard],
		data: { security: UseCase.for('application.dashboard'), title: 'Dashboard' }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DashboardRoutingModule {}
