<div class="todo-whiteboard__board__column" gaDropzone (add)="addItem($event)" (remove)="removeItem($event)">
	<div class="todo-whiteboard__board__column__name" [style.color]="config.labelColor">{{config.label}}</div>
	<div class="todo-whiteboard__board__column__summary">
		<div class="title">Tasks</div>
		<div class="info"><span>{{ todos.length }}</span> {{config.lengthLabel}}</div>
	</div>
	<div class="todo-whiteboard__board__column__shadow-top"></div>
	<div class="todo-whiteboard__board__column__pool">
		<todo-list-widget-sort></todo-list-widget-sort>
		<todo-card *ngFor="let todo of todos; trackBy: trackByFn"
				   [todo]="todo"
				   [attr.data-cy]="'todoCard__#'+todo?.id"
				   (onRemove)="removeFromStore($event)"
				   class="todo-card">
		</todo-card>
		<div class="todo-whiteboard__board__column__pool-empty-line"></div>
	</div>
	<div class="todo-whiteboard__board__column__shadow-bottom"></div>
</div>
