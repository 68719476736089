import { Pipe, PipeTransform } from '@angular/core';
import { IdentityProviderService } from '@app/core/auth/identity-provider.service';
import { TodoCardCommentMention } from '@app/todo/model/todo-card-data';

@Pipe({
	name: 'replaceMentions',
})
export class ReplaceMentionsPipe implements PipeTransform {

	constructor(private identityProvider: IdentityProviderService) {
	}

	public transform(comment: string, mentions: TodoCardCommentMention[]): string {
		const currentUserId = this.identityProvider.getIdentity().id;
		if (mentions?.length > 0) {
			let replacedText = comment;
			mentions.forEach(mention => replacedText = replacedText.replace(
				new RegExp(`@${mention.mentionedUser.firstName} ${mention.mentionedUser.lastName}`, 'g'),
				`<span class="mention ${mention.mentionedUser.id == currentUserId && 'current-user'}">
							@${mention.mentionedUser.firstName} ${mention.mentionedUser.lastName}
						</span>`)
			);
			return replacedText;
		} else {
			return comment;
		}
	}
}
