import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Component({
	selector: 'confirmation-dialog',
	template: `
		<div data-cy="modal-body" class="modal-body text-center">
			<h5>Are you sure?</h5>
			<p [innerHTML]="safeSubtitle"></p>
			<div class="base-flex" style="justify-content: center;">
				<button type="button" data-cy="modal-submit-btn" class="base-button" (click)="onChoiceSelect(true)">
					Yes
				</button>
				<button
					type="button"
					data-cy="modal-cancel-btn"
					class="base-button base-button--cancel"
					(click)="onChoiceSelect(false)">
					No
				</button>
			</div>
		</div>
	`,
	styles: [`
		h5 {
			color: var(--text);
			font-weight: 700;
			font-size: 20px;
			margin-bottom: 20px;
		}
	`]
})
export class ConfirmationDialogComponent implements OnInit {
	@Output() onChoice = new EventEmitter<boolean>();

	choice$: Subject<boolean> = new Subject<boolean>();
	subtitle = '';

	safeSubtitle: SafeHtml;

	constructor(private sanitizer: DomSanitizer) {
	}

	public ngOnInit(): void {
		this.safeSubtitle = this.sanitizer.bypassSecurityTrustHtml(this.subtitle);
	}

	public onChoiceSelect(choice: boolean): void {
		this.choice$.next(choice);
		this.onChoice.next(choice);
	}
}
