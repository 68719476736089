import { Component, OnDestroy, OnInit } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { ReportOptionsModalComponent } from '@app/reports/modals/report-options/report-options-modal.component';
import { DatePickerRangesService } from '@app/shared/daterange-picker/date-picker-ranges/date-picker-ranges.service';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { UserAccountCodelist } from '@app/shared/model/types/codelists/user-account-codelist';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'report-options-modal-2',
	templateUrl: './report-options-modal-layout-2.component.html'
})
export class ReportOptionsModalLayout2Component extends ReportOptionsModalComponent implements OnInit, OnDestroy {

	config: ReportOptionsLayout2Config = new ReportOptionsLayout2Config();
	showsalesRepresentativesList = false;
	salesRepresentatives: UserAccountCodelist[] = [];

	readonly faIcons = {
		checkSquare: faCheckSquare,
		square: faSquare
	};

	private destroy$ = new Subject<void>();

	constructor(
		dateRangesService: DatePickerRangesService,
		codelistService: CodelistRepositoryService,
		bsModalRef: BsModalRef) {
		super(dateRangesService, codelistService, bsModalRef);
	}

	public ngOnInit(): void {
		this.init();
		this.codelistService.getByUrl<UserAccountCodelist[]>(UrlCodelistLink.SalesRepresentatives)
			.pipe(takeUntil(this.destroy$))
			.subscribe((salesRepresentatives) => {
				this.salesRepresentatives = salesRepresentatives;
			});
	}

	public updateSelectedsalesRepresentatives(salesRepresentative: any): void {
		const exists = this.config.salesRepresentatives.find(item => item.id === salesRepresentative.id);
		if (exists) {
			_.pull(this.config.salesRepresentatives, exists);
		} else {
			this.config.salesRepresentatives.push(salesRepresentative);
		}
	}

	public togglesalesRepresentativesList(): void {
		this.showsalesRepresentativesList = !this.showsalesRepresentativesList;
	}

	public hidesalesRepresentativesList(): void {
		this.showsalesRepresentativesList = false;
	}

	public selectAll(): void {
		this.selectNone();
		this.config.salesRepresentatives = [...this.salesRepresentatives];
	}

	public selectNone(): void {
		this.config.salesRepresentatives = [];
	}

	public download(): void {
		const params = {
			salesRepresentatives: JSON.stringify(_.map(this.config.salesRepresentatives, 'id')),
			from: this.config.from,
			to: this.config.to
		};
		super.download(params);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}

export class ReportOptionsLayout2Config {
	from = '';
	to = '';
	salesRepresentatives: UserAccountCodelist[] = [];
}
