<ng-container [formGroup]="viewFormGroup">
	<ng-select [items]="options$ | async"
	           [multiple]="params.multiple"
	           [closeOnSelect]="!params.multiple"
	           formControlName="control"
	           [appendTo]="params?.appendTo"
	           [ngClass]="cssClass"
	           [typeahead]="typeahead$"
	           (change)="onChange($event)"
	           (focus)=onFocus(true)
	           (blur)="onFocus(false)"
	           [virtualScroll]="true"
	           [loading]="isLoading$ | async"
	           [attr.data-cy]="params?.dataCy ? params.dataCy : componentMetadata.fieldName + '-select'"
	>
	</ng-select>
</ng-container>
