import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { AclGuard } from '@app/core/security/acl.guard';
import { UseCase } from '@app/core/security/use-case';
import { CaseManagerTrackingComponent } from '@app/sales/case-manager-tracking/case-manager-tracking.component';

const routes: Routes = [
	{
		path: 'sales', children: [
			{
				path: 'case-managers-tracking', component: CaseManagerTrackingComponent, canActivate: [AuthGuard, AclGuard],
				data: { security: UseCase.for('sales.case_managers_tracking'), title: 'Case Managers Tracking' }
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SalesRoutingModule {}
