import { Component } from '@angular/core';
import { FeatureFlags } from '@app/core/unleash/feature.flags';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';

@Component({
	selector: 'app-navigation',
	templateUrl: './app-navigation.component.html',
	styleUrls: ['./app-navigation.component.scss'],
})
export class AppNavigationComponent {
	readonly svgIconNames = SvgIconName;

	readonly featureFlags = FeatureFlags;
}
