import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'nameInitialsSort' })
export class NameInitialsSortPipe implements PipeTransform {

	public transform(array) {
		return _.orderBy(array, ['firstName', 'lastName'], ['asc', 'asc']);
	}
}
