import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'weeksAgo' })
export class WeeksAgoPipe implements PipeTransform {

	public transform(value) {
		if (value) {
			return moment().diff(value, 'weeks') + ' weeks';
		}

		return 'n/a'
	}
}
