import { Injectable } from '@angular/core';
import { FormControlValidationStatus } from '@app/shared/ga-components/utils/form-control-validation-status';

export enum FormScopeSavingProgress {
	OnSubmit = 'onSubmit',
	OnPending = 'onPending',
	OnValid = 'onValid',
	OnInvalid = 'onInvalid',
	OnDisabled = 'onDisabled',
	OnSuccess = 'onSuccess',
	OnError = 'onError',
}

@Injectable()
export class FormScopeSavingProgressHelper {
	private readonly _validationStatusToSavingProgressMapping = new Map<string, FormScopeSavingProgress>([
		[FormControlValidationStatus.Pending, FormScopeSavingProgress.OnPending],
		[FormControlValidationStatus.Valid, FormScopeSavingProgress.OnValid],
		[FormControlValidationStatus.Invalid, FormScopeSavingProgress.OnInvalid],
		[FormControlValidationStatus.Disabled, FormScopeSavingProgress.OnDisabled],
	]);

	public getSavingProgress(status: string): FormScopeSavingProgress {
		if (this._validationStatusToSavingProgressMapping.has(status)) {
			return this._validationStatusToSavingProgressMapping.get(status);
		}

		throw new Error(`Provided form control validation status: ${status} does not exist.`);
	}

}
