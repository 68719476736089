<div class="new-modal">
	<div class="new-modal__header">
		<h3>Archive reason</h3>
	</div>
	<div class="new-modal__content">
		<div class="new-modal__content__form-group" (clickOutside)="showArchiveReasonTypeSelect = false">
			<div style="position: relative;">
				<div (click)="toggleArchiveReasonTypeSelect()">
					<div class="select-item__title">Archive Reason (required)</div>
					<div class="select-item__content">
						<div *ngIf="selectedArchivedReason;  else placeholder">
							{{ selectedArchivedReason.title }}
						</div>
						<ng-template #placeholder>
							<div style="font-style: italic; color: var(--text); opacity: 0.5;">
								Please choose archive reason type
							</div>
						</ng-template>
					</div>
				</div>
				<select-list
						[items]="archivedReasonTypes$ | async"
						[show]="showArchiveReasonTypeSelect"
						[selected]="selectedArchivedReason"
						[attributes]="['title']"
						(onItemSelect)="changeArchiveReasonType($event)">
				</select-list>
			</div>
		</div>
		<div class="new-modal__content__form-group">
			<label for="additionalInfo-textarea" class="new-modal__content__form-group__label">Archive Reason Note (required)</label>
			<textarea [(ngModel)]="archivedReasonNote" id="additionalInfo-textarea"  class="base-input"></textarea>
		</div>
	</div>
	<div class="new-modal__footer">
		<button (click)="closeModal()" class="base-button base-button--cancel">Cancel</button>
		<button (throttleClick)="archiveFunding()" class="base-button">Archive</button>
	</div>
	<loading-spinner [show]="isLoading" [loadingLabel]="'Saving data...'"></loading-spinner>
</div>
