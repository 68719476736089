import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInOut, fadeUpDown } from '@app/shared/animations/animations';
import { EntityAssociation } from '@app/shared/model/types/entity-association';
import { TodoAssociationsListEntry } from '@app/todo/model/todo-associations-list-entry';
import { associationTypes, AssociationTypesConfig, TodoAssociationsSelectOption } from '@app/todo/todo-associations-select/todo-associations-select.model';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, merge, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'todo-associations-select',
	templateUrl: 'todo-associations-select.component.html',
	styleUrls: ['todo-associations-select.component.scss'],
	animations: [fadeUpDown, fadeInOut]
})
export class TodoAssociationsSelectComponent implements OnInit {
	@Input() associations: EntityAssociation[] = [];
	@Input() headerClass = '';

	@Output() onAssociationSelected = new EventEmitter<TodoAssociationsListEntry>();

	showAssociationSearchBar = false;
	showAssociationTypeMenu = false;
	associationType = associationTypes[0];
	readonly associationTypes = associationTypes;
	results$: Observable<TodoAssociationsSelectOption[]>;
	searchTerm$ = new Subject<string>();
	resultsLoading$: Subject<boolean> = new Subject<boolean>();

	private resultReset$ = new BehaviorSubject([]);

	constructor(
		private http: HttpClient) {
	}

	public ngOnInit(): void {
		this.results$ = merge(
			this.resultReset$.asObservable(),
			this.searchTerm$.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				tap(() => {
					this.resultsLoading$.next(true);
				}),
				switchMap((term) => {
					if (!term || term.length < 3) {
						return of([]);
					}
					const params = new HttpParams()
						.set('term', term)
						.set('entity', this.associationType.searchEntity);
					return this.http.get<TodoAssociationsSelectOption>('/api/smart-search', { params })
						.pipe(
							map(result => {
								const associationsToExclude = this.associations.filter((association) => {
									return association.association_type === this.associationType.type;
								});
								return result[this.associationType.searchEntity]
									.filter(item => {
										return associationsToExclude.every((association) => {
											return !this.associationType.isEqual(association, item);
										});
									})
									.map(item => ({
										referencedEntity: item,
										referencedClass: this.associationType.type,
										associationClass: this.associationType.associationClass,
										label: this.associationType.createLabel(item)
									}));
							})
						);
				}),
				tap({
					next: () => this.hideLoading(),
					error: () => this.hideLoading()
				})
			)
		);
	}

	private hideLoading(): void {
		this.resultsLoading$.next(false);
	}

	public toggleAssociationTypeMenu(): void {
		if (this.showAssociationSearchBar) {
			this.showAssociationSearchBar = false;
		} else {
			this.showAssociationTypeMenu = !this.showAssociationTypeMenu;
		}
	}

	public closeAssociationTypeMenu(): void {
		this.showAssociationTypeMenu = false;
	}

	public setAssociationType(type: AssociationTypesConfig): void {
		this.associationType = type;
		this.showAssociationSearchBar = true;
		this.closeAssociationTypeMenu();
	}

	public addAssociation(item: TodoAssociationsSelectOption): void {
		if (!item) {
			return;
		}
		const association: TodoAssociationsListEntry = {
			label: item.label,
			association_type: item.referencedClass,
			associationClass: item.associationClass,
			referenceId: item.referencedEntity.id,
			parentReferenceId: this.associationType.parentReferenceIdGetter
				? this.associationType.parentReferenceIdGetter(item.referencedEntity)
				: null,
			id: uuidv4(),
			loading: true
		};

		this.resultReset$.next([]);
		this.onAssociationSelected.emit(association);
		this.showAssociationSearchBar = false;
	}
}
