<div *ngIf="!identityProvider.getIdentity().isAuthenticated; else authorizedArea" style="height: 100%;">
	<router-outlet></router-outlet>
</div>

<ng-template #authorizedArea>
	<div class="layout" *ngrxLet="responsiveService.isMobile$; let isMobile">
		<app-navigation class="layout__side-navigation" *ngIf="!isMobile"></app-navigation>
		<app-header class="layout__header"></app-header>
		<div class="layout__content">
			<router-outlet></router-outlet>
		</div>
	</div>
</ng-template>

<flash-messages-container></flash-messages-container>
<app-tab-title></app-tab-title>
