<div class="chart-clear-wrapper">
	<button class="base-button base-button--square" tooltip="Clear graph preferences" *ngIf="hasSavedChart$ | async"
			(click)="clearSavedChartState()">
		<svg-icon class="base-icon base-icon--small" [icon]="clearIcon"></svg-icon>
	</button>
</div>
<div class="breakdown-content">
<div id="caseType-chart" class="top-five-dashboard-chart"></div>
<ag-grid-angular
	class="ag-theme-balham grid-list"
	[rowData]="rowData$ | async"
	[columnDefs]="colDefs"
	[gridOptions]="gridOptions"
	(firstDataRendered)="onFirstDataRendered($event)"
	(chartOptionsChanged)="onChartOptionsChanged()">
</ag-grid-angular>
</div>
