import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';

@Component({
	selector: 'tier-rating',
	templateUrl: './tier-rating.component.html',
})
export class TierRatingComponent implements OnInit, OnChanges {

	// TODO compute string equivalent using pipe
	@Input() tier?: { title: string };
	@Input() small = false;
	tierString: string;

	readonly svgIconNames = SvgIconName;

	public ngOnInit(): void {
		this.tierString = this.stringifyTier(this.tier);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (!changes.tier.isFirstChange() && changes.tier.previousValue !== changes.tier.currentValue) {
			this.tierString = this.stringifyTier(changes.tier.currentValue);
		}
	}

	private stringifyTier(tier: { title: string }): string {
		return tier?.title ? `t${tier.title[0]}` : '';
	}
}
