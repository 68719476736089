import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SmsRepositoryService } from '@app/shared/model/sms-repository.service';
import { SmsConversation } from '@app/sms/sms-conversation';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'inbox-conversation-list',
	templateUrl: './inbox-conversation-list.component.html',
	styleUrls: ['./inbox-conversation-list.component.scss'],
})
export class InboxConversationListComponent implements OnInit, OnDestroy {
	@Input() showMarkReadButton = true;
	@Input() conversations: SmsConversation[];
	@Input() showSender = false;
	@Input() showLoading: boolean;

	@Output() onSelectConversation = new EventEmitter<SmsConversation>();
	@Output() onScrollDown = new EventEmitter<void>();
	@Output() onSearch = new EventEmitter<string>();

	searchControl = new FormControl('');
	private destroy$ = new Subject<void>();

	constructor(private smsService: SmsRepositoryService) {
	}

	public ngOnInit(): void {
		this.searchControl.valueChanges.pipe(
			debounceTime(600),
			distinctUntilChanged(),
			takeUntil(this.destroy$)
		).subscribe((searchTerm) => this.onSearch.emit(searchTerm));
	}

	public selectConversation(conversation: SmsConversation) {
		this.onSelectConversation.emit(conversation);
	}

	public scrollDown() {
		this.onScrollDown.emit();
	}

	public markAllConversationsAsRead() {
		this.smsService.markAllAsRead(this.conversations);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
