import { Component } from '@angular/core';
import { BoardHelperService } from '@app/w-boards/services/board-helper.service';
import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'board-list-renderer',
	template: `
		<div>
			<a *ngFor="let board of boards"
				[routerLink]="board.route"
				[queryParams]="{fundingId: funding.id}"
				target="_blank">
				<b>{{ board.name }}</b> {{ board.column }}
			</a>
		</div>
	`,
	styles: ['div {display: flex; flex-direction: column;}'],
})
export class BoardListRendererComponent implements ICellRenderer {
	boards;
	funding;

	constructor(private boardHelper: BoardHelperService) {}

	public agInit(params: ICellRendererParams): void {
		this.funding = params?.data;
		this.boards = this.funding?.boards
			.map(board => this.boardHelper.resolveBoard(this.funding, board))
			.filter(boards => boards !== null);
	}

	public refresh(): boolean {
		return true;
	}

}
