import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { AclService } from '@app/core/security/acl.service';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { dateTimeColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/date-column-definition';
import { toNumber } from '@app/shared/general-helper';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { Funding } from '@app/shared/model/types/funding';
import { GridReadyEvent } from 'ag-grid-community';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subject, takeUntil } from 'rxjs';

interface CaseManagerTrackingItem {
	caseManagerName: string;
	lawFirmName: string;
	firstFundingCreated: string;
	lastFundingCreated: string;
	fundingCount: string;
}

@Component({
	selector: 'case-manager-tracking',
	templateUrl: './case-manager-tracking.component.html',
})
export class CaseManagerTrackingComponent implements OnInit, OnDestroy {

	@HostBinding('class.view') hostClass = true;

	rowData$: Observable<CaseManagerTrackingItem[]>;
	columnDefs;
	gridOptions;

	private destroy$ = new Subject<void>();

	constructor(
		private http: HttpClient,
		private codelistRepository: CodelistRepositoryService,
		private acl: AclService,
		private agUtils: AgGridUtilsService) {
	}

	private countFilteredByFundingTypes = (fundings: Funding[], fundingTypes: string[]) => fundings
		.filter(funding => {
			try {
				return fundingTypes.includes(funding.fundingType.prefix)
					&& !funding.isExternalPortfolio;
			} catch (error) {
				console.error(`Cannot determine condition.
				Funding ID: ${funding.id},
				FundingType: ${funding?.fundingType},
				Prefix: ${funding?.fundingType?.prefix},
				External: ${funding?.isExternalPortfolio}`
				);
				return false;
			}
		}).length;

	public ngOnInit(): void {
		this.gridOptions = this.agUtils.getGridOptions({
			rowClassRules: {
				'warning': (params) => this.highlightRow(params)
			},
			defaultColDef: {
				minWidth: 130
			},
			masterDetail: true,
			detailCellRendererParams: {
				detailGridOptions: {
					excelStyles: AgGridUtilsService.EXCEL_STYLES,
					defaultColDef: {
						floatingFilter: true,
						sortable: true,
						resizable: true,
						minWidth: 130
					},
					columnDefs: [
						{
							field: 'applicantName',
							valueGetter: ({ data }) => `${data.firstName} ${data.lastName}`,
							filter: 'agTextColumnFilter',
							cellRenderer: ({ data }) => this.agUtils.applicantLinkRenderer(data.id, `${data.firstName} ${data.lastName}`),
							headerName: 'Applicant'
						},
						{
							headerName: 'Last Funded Deal',
							...dateTimeColumnDefinition('fundings[0].createdOn')
						},
						{
							valueGetter: ({ data }) => data.fundings.length,
							headerName: '# of All Fundings',
							filter: 'agNumberColumnFilter'
						},
						{
							valueGetter: ({ data }) => this.countFilteredByFundingTypes(data.fundings, ['PC', 'SC']),
							headerName: '# of Plaintiff Fundings',
							filter: 'agNumberColumnFilter'
						},
						{
							valueGetter: ({ data }) => this.countFilteredByFundingTypes(data.fundings, ['LP', 'MF', 'MRI']),
							headerName: '# of Medical Fundings',
							filter: 'agNumberColumnFilter'
						},
						{
							valueGetter: ({ data }) => this.countFilteredByFundingTypes(data.fundings, ['AC', 'SA', 'CC']),
							headerName: '# of Case Cost Fundings',
							filter: 'agNumberColumnFilter'
						},
						{
							valueGetter: ({ data }) => this.countFilteredByFundingTypes(data.fundings, ['CR']),
							headerName: '# of Court Reporting Fundings',
							filter: 'agNumberColumnFilter'
						},
						{
							valueGetter: ({ data }) => data.fundings
								.filter(funding => funding.isExternalPortfolio).length,
							headerName: '# of External Fundings',
							filter: 'agNumberColumnFilter'
						}
					],
					statusBar: this.agUtils.getStatusBarSettings(),
					suppressCsvExport: true,
					suppressExcelExport: !this.acl.isAllowed('export.sales_case_managers_tracking'),
					defaultExcelExportParams: {
						fileName: 'Case Manager Tracking Export'
					}
				},
				getDetailRowData: params => {
					params.successCallback(params.data[0].caseManagementApplicants);
				}
			},
			suppressExcelExport: !this.acl.isAllowed('export.sales_case_managers_tracking'),
			defaultExcelExportParams: {
				fileName: 'Case Manager Tracking Export',
				processCellCallback: params => {
					if (typeof params.value == 'boolean') {
						return params.value ? 'Yes' : 'No';
					}
					return params.value;
				}
			}
		});

		this.columnDefs = [
			{
				field: 'caseManager',
				headerName: 'Case Manager',
				cellRenderer: 'agGroupCellRenderer',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => {
						this.codelistRepository.getByUrl(UrlCodelistLink.CaseManagers)
							.pipe(takeUntil(this.destroy$))
							.subscribe(data => {
								params.success(this.agUtils.getArrayOfStringsByFiledName(data, ['firstName', 'lastName']));
							});
					}
				},
				pinned: 'left',
				minWidth: 150
			},
			{
				field: 'caseManagerPhone',
				valueGetter: (value) => _.get(value, 'data[0].phone', ''),
				headerName: 'Case Manager Phone',
				filter: 'agTextColumnFilter',
				cellClass: 'stringFormat',
				hide: true
			},
			{
				field: 'caseManagerEmail',
				valueGetter: (value) => _.get(value, 'data[0].email'),
				headerName: 'Case Manager Email',
				filter: 'agTextColumnFilter',
				hide: true
			},
			{
				field: 'lawFirmId',
				headerName: 'Law Firm ID',
				filter: 'agTextColumnFilter',
				pinned: 'left'
			},
			{
				field: 'lawFirmName',
				headerName: 'Law Firm',
				filter: 'agTextColumnFilter',
				pinned: 'left',
				minWidth: 150,
				cellRenderer: ({ data }) => this.agUtils.lawFirmLinkRenderer(data.lawFirmId, data.lawFirmName)
			},
			{
				field: 'salesRepresentative',
				headerName: 'New Business Rep',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => {
						this.codelistRepository.getByUrl(UrlCodelistLink.SalesRepresentatives)
							.pipe(takeUntil(this.destroy$))
							.subscribe(data => {
								params.success(this.agUtils.getArrayOfStringsByFiledName(data, ['firstName', 'lastName']));
							});
					}
				},
			},
			{
				field: 'fundingConsultant',
				headerName: 'Account Growth Rep',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => {
						this.codelistRepository.getByUrl(UrlCodelistLink.FundingConsultants)
							.pipe(takeUntil(this.destroy$))
							.subscribe(data => {
								params.success(this.agUtils.getArrayOfStringsByFiledName(data, ['firstName', 'lastName']));
							});
					}
				},
			},
			{
				field: 'medAE',
				headerName: 'Med AE',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => {
						this.codelistRepository.getByUrl(UrlCodelistLink.MedicalAEs)
							.pipe(takeUntil(this.destroy$))
							.subscribe(data => {
								params.success(this.agUtils.getArrayOfStringsByFiledName(data, ['firstName', 'lastName']));
							});
					}
				},
			},
			{
				...dateTimeColumnDefinition(''),
				valueGetter: (value) => this.getFirstFundedDate(value.data[0].caseManagementApplicants),
				headerName: 'Date of First Request',
			},
			{
				...dateTimeColumnDefinition(''),
				valueGetter: (value) => this.getPreviousFundedDate(value.data[0].caseManagementApplicants),
				headerName: 'Date of Previous Request',
			},
			{
				...dateTimeColumnDefinition(''),
				valueGetter: value => this.getLastFundedDate(value.data[0].caseManagementApplicants),
				headerName: 'Date of Last Request',
				sort: 'desc',
			},
			{
				headerName: 'Number of Fundings',
				children: [
					{
						headerName: '# of All Fundings',
						valueGetter: ({ data }) => toNumber(data[0].caseManagementApplicants
							.reduce((acc, applicant) => acc + applicant.fundings.length, 0)),
						filter: 'agNumberColumnFilter'
					},
					{
						valueGetter: ({ data }) => toNumber(data[0].caseManagementApplicants.reduce((acc, applicant) => acc +
							this.countFilteredByFundingTypes(applicant.fundings, ['PC', 'SC']), 0)),
						headerName: '# of Plaintiff Fundings',
						filter: 'agNumberColumnFilter'
					},
					{
						valueGetter: ({ data }) => toNumber(data[0].caseManagementApplicants.reduce((acc, applicant) => acc +
							this.countFilteredByFundingTypes(applicant.fundings, ['LP', 'MF', 'MRI']), 0)),
						headerName: '# of Medical Fundings',
						filter: 'agNumberColumnFilter'
					},
					{
						valueGetter: ({ data }) => toNumber(data[0].caseManagementApplicants.reduce((acc, applicant) => acc +
							this.countFilteredByFundingTypes(applicant.fundings, ['AC', 'SA', 'CC']), 0)),
						headerName: '# of Case Cost Fundings',
						filter: 'agNumberColumnFilter'
					},
					{
						valueGetter: ({ data }) => toNumber(data[0].caseManagementApplicants.reduce((acc, applicant) => acc +
							this.countFilteredByFundingTypes(applicant.fundings, ['CR']), 0)),
						headerName: '# of Court Reporting Fundings',
						filter: 'agNumberColumnFilter'
					},
					{
						valueGetter: ({ data }) =>
							toNumber(data[0].caseManagementApplicants.reduce((acc, applicant) => acc + applicant.fundings
								.filter(funding => funding.isExternalPortfolio).length, 0)),
						headerName: '# of External Fundings',
						filter: 'agNumberColumnFilter'
					}
				]
			},
		];

		this.rowData$ = this.http.get<CaseManagerTrackingItem[]>('/api/statistics/case-manager-tracking');
	}

	private getSortedCreatedOnDates(applicants): string[] {
		return applicants.flatMap(applicant => applicant.fundings)
			.map(funding => moment(funding.createdOn))
			.sort((a, b) => a.diff(b))
			.map(createdOn => createdOn.format('YYYY-MM-DDTHH:mm:ss'));
	}

	private getFirstFundedDate(applicants): string {
		const sortedDates = this.getSortedCreatedOnDates(applicants);
		return sortedDates[0];

	}

	private getPreviousFundedDate(applicants): string {
		const sortedDates = this.getSortedCreatedOnDates(applicants);
		return sortedDates.length > 2 ? sortedDates[sortedDates.length - 2] : sortedDates[0];
	}

	private getLastFundedDate(applicants): string {
		const sortedDates = this.getSortedCreatedOnDates(applicants);
		return _.last(sortedDates);
	}

	private highlightRow(params): boolean {
		const firstFundedDate = this.getFirstFundedDate(params.data[0].caseManagementApplicants);
		const lastFundedDate = this.getLastFundedDate(params.data[0].caseManagementApplicants);
		return firstFundedDate && lastFundedDate
			&& moment(firstFundedDate).format('YYYY-MM-DD') == moment(lastFundedDate).format('YYYY-MM-DD');
	}

	public onGridReady(params: GridReadyEvent): void {
		params.api.sizeColumnsToFit();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
