export const fundingNoteReadOnlyFields = [
	'editedBy',
	'editedBy.firstName',
	'editedBy.lastName',
	'createdBy',
	'createdBy.firstName',
	'createdBy.lastName',
	'createdOn'
];

export const fieldsToFetch = [
	'internalId',
	'approvalStatus',
	'fundingType.title',
	'fundingType.medical',
	'requestedFunding',
	'approvedAt',
	'purchasePrice',
	'dateToFollowUp',
	'appointments -> doctor, medicalProcedureType, appointmentDate',
];
