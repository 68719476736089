export enum WebSocketMessageType {
	// SMS
	SMSMessageReceived = 'sms-message-received',
	SMSMessagesMarkedAsRead = 'sms-messages-read',
	// CODELISTS & PERMISSIONS
	UpdateCodelistCache = 'update-codelists-cache',
	UpdateUrlCodelistCache = 'update-url-codelists-cache',
	// OTHER
	Default = 'default',
	ClearPermissionsCache = 'clear-permissions-cache',
	NewWebApplication = 'new-web-application',
	ContractDocumentGenerated = 'contract-document-generated',
	PortfolioFollowUpPerc = 'portfolio-follow-up-percentage',
	Docusign = 'docusign',
	CloseDocusign = 'close-docusign',
	ApplicantAutoApprovalEligible = 'applicant-auto-approval-eligible',
	ApplicantAutoApprovalNotEligible = 'applicant-auto-approval-not-eligible',
	// New Boards
	BoardMessage = 'board-message',
	CommentCreated = 'comment-created',

	//TODOS
	TodoCreated = 'todo-created',
	//@Fixme: this is not yet implemented in API
	TodoUpdated = 'todo-updated',
	TodoDeleted = 'todo-deleted'
}
