import { Component } from '@angular/core';
import { AgToggleRendererParams } from '@app/shared/ag-grid-utils/ag-toggle-renderer/ag-toggle-renderer-params';
import { defaultSwitchParams, SwitchParams } from '@app/shared/switch/switch.model';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Component({
	selector: 'toggle-cell',
	template: `
		<rp-switch
			*ngIf="isEditable && params.data != null"
			[value]="value"
			[params]="switchParams"
			(switched)="update($event)">
		</rp-switch>
		<span
			*ngIf="!isEditable && params.data != null"
			class="badge"
			[ngClass]="{'badge-success': value, 'badge-danger': !value}">
			{{ value ? 'Yes' : 'No' }}
		</span>
	`,
	styles: ['rp-switch { position: relative; top: 0.3em;}']
})
export class AgToggleRendererComponent implements AgRendererComponent {
	value: boolean;
	property: string;
	switchParams: SwitchParams;
	updateCallback: (data, value) => Observable<any>;
	isEditable = true;
	params: AgToggleRendererParams & ICellRendererParams;

	public agInit(params: AgToggleRendererParams & ICellRendererParams): void {
		this.params = params;
		this.property = params.colDef.field;
		this.updateCallback = params.updateCallback;
		this.switchParams = params.switchParams ?? defaultSwitchParams;
		this.value = params.getDeepProperty ? params.getDeepProperty(params.data) : _.get(params.data, this.property);

		if (typeof params.isEditable == 'boolean') {
			this.isEditable = params.isEditable;
		} else if (typeof params.isEditable == 'function') {
			this.isEditable = params.isEditable(params.data);
		}
	}

	public update(switchValue: boolean): void {
		this.updateCallback(this.params.data, switchValue).subscribe((response) => {
			const rowNode = this.params.api.getRowNode(this.params.data.id);
			const responseValue = response[_.last(this.property.split('.'))]
			const newRowValue = {};
			_.set(newRowValue, this.property, responseValue);
			rowNode.setDataValue(this.params.column, newRowValue);
		});
	}

	public refresh(_params: ICellRendererParams): boolean {
		return false;
	}
}
