import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppHeaderComponent } from '@app/app-layout/app-header/app-header.component';
import { LeftPadPipe } from '@app/app-layout/app-header/pipes/left-pad.pipe';
import { SmartSearchComponent } from '@app/app-layout/app-header/smart-search/smart-search.component';
import { SmsNotificationComponent } from '@app/app-layout/app-header/sms-notification/sms-notification.component';
import { SharedModule } from '@app/shared/shared.module';
import { TodoModule } from '@app/todo/todo.module';
import { LetDirective } from '@ngrx/component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		RouterModule,
		FormsModule,
		BsDropdownModule,
		TodoModule,
		LetDirective,
		SmsNotificationComponent,
		TooltipModule
	],
	exports: [
		AppHeaderComponent
	],
	declarations: [
		AppHeaderComponent,
		SmartSearchComponent,
		LeftPadPipe
	]
})
export class AppHeaderModule {}
