import { Injectable } from '@angular/core';
import { EntityAssociationType } from '@app/shared/model/constants/entity-association-type';
import { InitialTodoAssociation, TodoAssociationsListEntry } from '@app/todo/model/todo-associations-list-entry';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class TodoAssociationsService {
	private associations$ = new BehaviorSubject<TodoAssociationsListEntry[]>([]);

	public getAssociations$(): Observable<TodoAssociationsListEntry[]> {
		return this.associations$.asObservable();
	}

	public addAssociation(initialAssociation: InitialTodoAssociation): void {
		const association: TodoAssociationsListEntry = {
			label: initialAssociation.label,
			association_type: initialAssociation.association_type,
			referenceId: initialAssociation.referenceId,
			parentReferenceId: initialAssociation.parentReferenceId,
			id: uuidv4()
		};

		this.associations$.next([...this.associations$.value, association]);
	}

	public removeAssociation(associationToRemove: TodoAssociationsListEntry): void {
		const associations = this.associations$.value;
		const indexToRemove = associations.findIndex((association) => association.id == associationToRemove.id);
		if (indexToRemove >= 0) {
			associations.splice(indexToRemove, 1)
			this.associations$.next([...associations]);
		}
	}

	get associations() {
		return this.associations$.value.map((association) => {
			let associationTypeRequest = `${association.association_type}s`;
			if (association.association_type == EntityAssociationType.Attorney) {
				associationTypeRequest = 'lawFirmStaff';
			}
			if (association.association_type == EntityAssociationType.MedicalFacility) {
				associationTypeRequest = 'medicalFacilities';
			}
			return { ...association, association_type: associationTypeRequest };
		});
	}
}
