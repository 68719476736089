<ag-grid-angular
	#agGrid
	id="funding"
	class="ag-theme-balham fundings-table-grid__ag-grid-table"
	[columnDefs]="columnDefs"
	[rowData]="gridData"
	[gridOptions]="gridOptions"
	(gridReady)="onGridReady($event)"
	(firstDataRendered)="onFirstDataRendered()"
></ag-grid-angular>
