import { Component, OnInit } from '@angular/core';
import { loadTodos } from '@app/core/todo-store/todo.actions';
import { NewTodoFormData } from '@app/todo/model/new-todo-form';
import { NewTodo } from '@app/todo/model/new-todo.model';
import { InitialTodoAssociation } from '@app/todo/model/todo-associations-list-entry';
import { TodoAssociationsService } from '@app/todo/services/todo-associations.service';
import { TodoService } from '@app/todo/services/todo.service';
import { TodoCommentService } from '@app/todo/todo-card/todo-comments/service/todo-comment.service';
import { TodoDueDateService } from '@app/todo/todo-due-date/service/todo-due-date.service';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize, tap } from 'rxjs';

@Component({
	selector: 'new-todo-modal',
	templateUrl: 'new-todo-modal.component.html',
	styleUrls: ['new-todo-modal.component.scss'],
	providers: [TodoCommentService, TodoDueDateService, TodoAssociationsService]
})
export class NewTodoModalComponent implements OnInit {
	todoFormData: NewTodoFormData;
	initialAssociation: InitialTodoAssociation;

	constructor(
		private bsModalRef: BsModalRef,
		private todoService: TodoService,
		private todoCommentService: TodoCommentService,
		private todoAssociationsService: TodoAssociationsService,
		private todoDueDateService: TodoDueDateService,
		private store: Store) {
	}

	public ngOnInit(): void {
		if (this.initialAssociation) {
			this.todoAssociationsService.addAssociation(this.initialAssociation);
		}
	}

	public todoFormChanged(todoFormData: NewTodoFormData): void {
		this.todoFormData = todoFormData;
	}

	public cancel(): void {
		this.bsModalRef.hide();
	}

	public saveTodo(btnDisableLoaderFn: () => void): void {
		const newTodo: NewTodo = {
			...this.todoFormData.value,
			comment: this.todoCommentService.newCommentText,
			mentions: this.todoCommentService.commentMentionRequests.map(mention => mention.mentionedUser),
			dueDate: this.todoDueDateService.todoDueDateString,
			timezone: this.todoDueDateService.timezoneId
		};

		const associations = this.todoAssociationsService.associations;
		associations.forEach((association) => {
			if (association.association_type in newTodo) {
				newTodo[association.association_type].push(association.referenceId);
			} else {
				newTodo[association.association_type] = [association.referenceId];
			}
		});


		this.todoService.createTodo(newTodo)
			.pipe(
				tap(() => this.store.dispatch(loadTodos())),
				finalize(() => btnDisableLoaderFn())
			)
			.subscribe(() => this.bsModalRef.hide());
	}
}
