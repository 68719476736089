import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';
import { GaEditorComponent } from '@app/shared/ga-components/components/basic/ga-editor.component';
import { GaSelectEditorComponent } from '@app/shared/ga-components/components/editors/ga-select-editor/ga-select-editor.component';
import { GaGroupDirective } from '@app/shared/ga-components/components/group/ga-group.directive';
import { MetadataProviderService } from '@app/shared/ga-components/services/metadata-provider.service';
import { isObject, isString } from '@app/shared/general-helper';
import * as _ from 'lodash';
import { Subject, filter, map, takeUntil } from 'rxjs';

@Component({
	selector: 'ga-editor-cell',
	templateUrl: './ga-editor-cell.component.html'
})
export class GaEditorCellComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(GaGroupDirective, { static: true }) gaGroup: GaGroupDirective;
	@ViewChild(GaEditorComponent) editor: GaEditorComponent;
	@ViewChild('pop', { static: true }) tooltip;
	agParams;
	entity;
	componentMetadata;
	fieldMetadata;
	entityClass: string;
	fieldName: string;
	isSelectEditor = false;
	params;
	dataObj;
	currentValue;
	previousValue;
	isEditable = true;
	editError: string;
	columnDefaultWidth = { 'width': '0' };

	private valueEmitter$ = new Subject();
	private destroy$ = new Subject();

	constructor(
		public metadataProvider: MetadataProviderService,
		public acl: AclService
	) {
	}

	public agInit(params: any): void {
		this.columnDefaultWidth.width = `${params.column.actualWidth}px`;
		this.agParams = params;
		this.entity = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
		this.componentMetadata = params.componentMetadata;
	}

	public ngOnInit() {
		this.entity = this.entity ? this.entity : this.dataObj;
		this.fieldName = this.componentMetadata.path;
		this.currentValue = this.componentMetadata.valueFormatter(this.getValue());
		this.previousValue = this.currentValue;
		this.entityClass = this.componentMetadata.entityClass;
		this.fieldMetadata = this.metadataProvider.getFieldMetadata(this.entityClass, this.fieldName);
		this.isSelectEditor = this.componentMetadata.editor === GaSelectEditorComponent;
		if (this.isSelectEditor) {
			this.componentMetadata.editorParams = { ...this.componentMetadata.editorParams, onSelect: this.valueEmitter$ };
		}
		this.editError = this.componentMetadata.editRequirements(this.entity);
		this.isEditable = this.editError === '';
	}

	public ngAfterViewInit() {
		if (!this.isEditable && this.tooltip) {
			this.tooltip.show();
			setTimeout(() => {
				this.agParams.api.stopEditing();
				this.tooltip.hide();
			}, 2000);
		} else {
			if (!this.isSelectEditor) {
				this.valueEmitter$ = this.editor.onValueChange;
			}
			this.valueEmitter$
				.pipe(
					filter((value: any) => {
						return (value !== this.currentValue)
							&& !_.isNil(value)
							&& (this.componentMetadata.editorParams.multiple
								? Array.isArray(value)
								: (isObject(value) || isString(value)));

					}),
					map((value: any) => {
						return Array.isArray(value)
							? value.map(obj => obj?.rawItem ?? obj)
							: value?.rawItem ?? value;
					}),
					takeUntil(this.destroy$)
				)
				.subscribe(formattedValue => {
					this.currentValue = formattedValue;
					this.updateField(formattedValue);
				});
		}
	}

	private getValue() {
		return _.get(this.entity, this.fieldName);
	}

	private updateField(value) {
		if (!this.agParams) {
			this.gaGroup.save(this.componentMetadata.gridPostEditHooks ?? []);
			return;
		}
		this.agParams.value = this.componentMetadata.valueFormatter(value);
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
