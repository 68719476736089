import { Component, ViewChild, ViewContainerRef, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgSelectSearchingHelperService } from '@app/shared/model/ng-select-searching-helper.service';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { get } from 'lodash';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'ag-ng-select-editor',
	templateUrl: './ag-ng-select-editor.component.html',
	styleUrls: ['ag-ng-select-editor.component.scss'],
})
export class AgNgSelectEditorComponent implements ICellEditorAngularComp {
	@ViewChild('container', { read: ViewContainerRef }) container;

	selectControl = new UntypedFormControl([]);
	items: Observable<any>;
	ngTemplateKeys = ['title'];
	ngSearchFunction = 'searchCodelist';
	additionalTemplateKeys = [];
	lodashGet = get;

	private params: any;

	constructor(public searchingHelper: NgSelectSearchingHelperService) {
	}

	public agInit(params: any): void {
		this.params = params;
		this.items = this.params.values$;
		this.additionalTemplateKeys = this.params.ngAdditionalTemplateKey;
		this.selectControl.setValue(get(params?.data, params.colDef.field));
		if (this.params?.ngSelectTemplateKeys) {
			this.ngTemplateKeys = this.params.ngSelectTemplateKeys;
		}
		if (this.params?.ngSelectSearchFunctionName) {
			this.ngSearchFunction = this.params.ngSelectSearchFunctionName;
		}
		if (this.params?.ngSelectFilter) {
			this.items = from(this.params.values$).pipe(map(values => this.params?.ngSelectFilter(this.params, values)));
		}
	}

	public getValue() {
		return this.selectControl.value;
	}

	public isPopup(): boolean {
		return true;
	}
}
