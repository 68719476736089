import { Injectable } from '@angular/core';
import { SmartSearchItem, SmartSearchLastItem, SmartSearchSection, SmartSearchVisibleItemsCounts } from '@app/app-layout/app-header/smart-search/smart-search.model';
import { SmartSearchService } from '@app/app-layout/app-header/smart-search/smart-search.service';

@Injectable()
export class KeyboardNavigationService {
	indexOfSelectedItem = 0;
	private firstItemInLists = new Map();
	private lastItemInLists = new Map<string, SmartSearchLastItem>();

	constructor(private smartSearchService: SmartSearchService) {
	}

	public moveUp(newSectionName: string, nextItem: (index: number) => SmartSearchItem) {
		if (this.indexOfSelectedItem == 0) {
			return this.trySelectPreviousLastItem(newSectionName);
		} else {
			this.indexOfSelectedItem--;

			return nextItem(this.indexOfSelectedItem);
		}
	}

	public moveDown(newSectionName: string, nextItem: (index: number) => SmartSearchItem, sectionLength: number, maxLength: number) {
		if (this.indexOfSelectedItem < sectionLength - 1 && this.indexOfSelectedItem < maxLength - 1) {
			this.indexOfSelectedItem++;

			return nextItem(this.indexOfSelectedItem);
		} else {
			this.indexOfSelectedItem = 0;

			return this.trySelectNextFirstItem(newSectionName);
		}
	}

	public trySelectNextFirstItem(startItemName: string) {
		return this.trySelectNextItem(startItemName, this.firstItemInLists);
	}

	public trySelectPreviousLastItem(startItemName: string) {
		const lastItem = this.trySelectNextItem(startItemName, this.lastItemInLists);
		this.indexOfSelectedItem = lastItem?.index;

		return lastItem?.item;
	}

	private trySelectNextItem(nameOfFirstItem: string, list: Map<string, any>) {
		let result = null;
		const keys = Array.from(list.keys());
		const indexOfFirstItem = keys.findIndex((name) => nameOfFirstItem === name);
		const conditionsToCheck = [].concat(keys.slice(indexOfFirstItem), keys.slice(0, indexOfFirstItem));
		for (let i = 0; i < conditionsToCheck.length; i++) {
			result = list.get(conditionsToCheck[i]);
			if (result) {
				break;
			}
		}

		return result;
	}

	private getSmallerNumber(firstNumber: number, secondNumber: number): number {
		return firstNumber < secondNumber ? firstNumber : secondNumber;
	}

	public setItems(items) {
		this.indexOfSelectedItem = 0;
		let reverseItems = [];
		for (const key in items) {
			this.firstItemInLists.set(key, this.tryToSelectFirstInList(items[key], key));
			const lastItemInList = this.tryToSelectLastInList(items[key], key);
			reverseItems.push({ key: key, value: lastItemInList });
		}
		reverseItems = reverseItems.reverse();
		reverseItems.forEach((item) => {
			this.lastItemInLists.set(item.key, item.value);
		});
	}

	private tryToSelectFirstInList(list, name) {
		return list?.length === 0 ? null : this.smartSearchService.createSearchItem(name, list[0]);
	}

	private tryToSelectLastInList(list, name) {
		if (list.length == 0) {
			return null;
		}
		let maxDisplayedItems = 0;
		switch (name) {
			case SmartSearchSection.Applicants :
				maxDisplayedItems = SmartSearchVisibleItemsCounts.Applicants;
				break;
			case SmartSearchSection.LawFirmStaff :
				maxDisplayedItems = SmartSearchVisibleItemsCounts.LawFirmStaff;
				break;
			case SmartSearchSection.LawFirms :
				maxDisplayedItems = SmartSearchVisibleItemsCounts.LawFirms;
				break;
			case SmartSearchSection.Doctors :
				maxDisplayedItems = SmartSearchVisibleItemsCounts.Doctors;
				break;
			case SmartSearchSection.MedicalFacilities :
				maxDisplayedItems = SmartSearchVisibleItemsCounts.MedicalFacilities;
				break;
		}
		const index = this.getSmallerNumber(list.length, maxDisplayedItems) - 1;

		return { index: index, item: this.smartSearchService.createSearchItem(name, list[index]) };
	}
}
