import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';
import { SalesActivityReportService } from '@app/reports/sales-activity-report/sales-activity-report.service';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { dateColumnDefinition, dateTimeColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/date-column-definition';
import { numberColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/number-column-definition';
import { AgGridExcelExportService } from '@app/shared/services/ag-grid-excel-export.service';
import { TodosListService } from '@app/todo/list/todos-list.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'sales-activity-report-grid',
	templateUrl: './sales-activity-report-grid.component.html',
	styleUrls: ['./sales-activity-report-grid.component.scss'],
	providers: [AgGridExcelExportService]
})
export class SalesActivityReportGridComponent implements OnInit, OnChanges, OnDestroy {

	@Input() dateRange: Date[] = [];
	@Input() gridData: any[];
	@ViewChild('agGrid') agGrid: AgGridAngular;

	columnDefs: ColDef[];
	gridOptions: GridOptions;

	private destroy$ = new Subject<void>();
	private gridApi: GridApi;

	constructor(
		private agUtils: AgGridUtilsService,
		private acl: AclService,
		private todosListService: TodosListService,
		private salesActivityReportService: SalesActivityReportService,
	) {}

	public ngOnInit(): void {
		const salesActivityTypes$ = this.salesActivityReportService.getSalesActivityTypes();

		this.columnDefs = [
			{
				field: 'subject',
				headerName: 'Summary',
				filter: 'agTextColumnFilter',
			},
			{
				field: 'content',
				headerName: 'Description',
				filter: 'agTextColumnFilter',
				width: 300,
				cellStyle: { 'white-space': 'normal' },
				autoHeight: true,
			},
			{
				field: 'createdBy',
				headerName: 'Created By',
				filter: 'agTextColumnFilter',
				valueGetter: ({ data }) => data?.createdBy ? `${data.createdBy.firstName}  ${data.createdBy.lastName}` : '',
			},
			{
				headerName: 'Created At',
				...dateTimeColumnDefinition('createdOn'),
			},
			{
				headerName: 'Activity Date',
				...dateColumnDefinition('activityDate'),
			},
			{
				field: 'activityTypes',
				headerName: 'Activity Types',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => salesActivityTypes$
						.pipe(takeUntil(this.destroy$))
						.subscribe((salesActivityTypes) =>
							params.success([...salesActivityTypes.map(activityType => activityType.title), 'Note'])),
					valueGetter: ({ data }) => data?.activityTypes?.length === 0 ? 'Note' : data?.activityTypes?.map(label => label.title)
				},
				autoHeight: true,
				valueGetter: ({ data }) => data?.activityTypes?.length > 0
					? data.activityTypes.map(activityType => activityType.title).join(', ') : 'Note',
				cellRenderer: ({ node, value, data }) => node.group ? value : this.agUtils.renderListOfLabels(data?.activityTypes, true),
			},
			{
				headerName: 'Association',
				filter: 'agTextColumnFilter',
				cellRenderer: params => this.associationLinkRenderer(params),
				valueGetter: this.associationGetter,
			},
			{
				headerName: 'Entity ID',
				...numberColumnDefinition(''),
				valueGetter: ({ data }) => {
					if (data?.lawFirm) {
						return +data.lawFirm.id;
					}
					if (data?.doctor) {
						return +data.doctor.id;
					}
					if (data?.medicalFacility) {
						return +data.medicalFacility.id;
					}
					return null;
				},
			}
		];

		this.gridOptions = this.agUtils.getGridOptions({
			isRowMaster: data => {
				return data?.todos?.length > 0;
			},

			defaultExcelExportParams: {
				fileName: 'Sales Activity Report Export'
			},
			suppressExcelExport: !this.acl.isAllowed('export.reports_sales_activity'),
		});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.dateRange) {
			this.gridApi?.showLoadingOverlay();
			this.destroy$.next();
			this.salesActivityReportService.fetchData(changes.dateRange.currentValue)
				.pipe(takeUntil(this.destroy$))
				.subscribe((data: []) => {
					this.gridData = data;
					this.gridApi?.hideOverlay();
					this.gridApi?.sizeColumnsToFit();
				});
		}
	}

	public associationLinkRenderer(params: ICellRendererParams): string {
		if (params.node.group) {
			return params.value;
		}

		if (params.data?.doctor) {
			return this.agUtils.doctorLinkRenderer(params.data?.doctor?.id, params.value);
		}
		if (params.data?.lawFirm) {
			return this.agUtils.lawFirmLinkRenderer(params.data?.lawFirm?.id, params.value);
		}
		if (params.data?.medicalFacility) {
			return this.agUtils.medicalFacilityLinkRenderer(
				params.data?.medicalFacility?.id, params.value
			);
		}
		return 'N/A';
	}

	public associationGetter({ data }): string {
		if (data?.doctor) {
			return `${data.doctor.name} (Doctor)`;
		}
		if (data?.lawFirm) {
			return `${data.lawFirm.name} (Law Firm)`;
		}
		if (data?.medicalFacility) {
			return `${data.medicalFacility.name} (Medical Facility)`;
		}
		return '';
	}

	public onGridReady(params: GridReadyEvent): void {
		params.api.sizeColumnsToFit();
		this.gridApi = params.api;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
