export enum FileType {
	ApplicantFile = 'applicantfile',
	DoctorFile = 'doctorfile',
	LawFirmFile = 'lawfirmfile',
	FundingFile = 'fundingfile',
	MedicalFacilityFile = 'medicalfacilityfile',
	TemplateFile = 'templatefile',
}

export const FileTypeReverseMapping = {
	[FileType.ApplicantFile]: 'applicantFile',
	[FileType.DoctorFile]: 'doctorFile',
	[FileType.LawFirmFile]: 'lawFirmFile',
	[FileType.FundingFile]: 'fundingFile',
	[FileType.MedicalFacilityFile]: 'medicalFacilityFile',
	[FileType.TemplateFile]: 'templateFile',
};
