<div class="todo-card__activity-log" style="position: relative;" [style.min-height]="'initial'">
    <h4 *ngIf="showHeader">Activity log <span (click)="toggleExpandActivityLog()" [class.active]="expandActivityLog"><fa-icon [icon]="faIcons.chevronDown"></fa-icon></span></h4>
    <ng-container *ngrxLet="todoLogs$; let logs">
        <div *ngIf="expandActivityLog" @heightGrow class="todo-card__activity-log__content">
            <div class="todo-card__activity-log__item" *ngFor="let log of logs">
                <todo-activity-log-item [logEntry]="log"></todo-activity-log-item>
            </div>
        </div>
    </ng-container>
	<loading-spinner [show]="isLoading" loadingLabel="Loading activity log"></loading-spinner>
</div>
