import { Injectable } from '@angular/core';
import { SmsRepositoryService } from '@app/shared/model/sms-repository.service';
import { InboxConversationDetailComponent } from '@app/sms/inbox/inbox-conversation-detail/inbox-conversation-detail.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable()
export class SmsModalService {

	constructor(
		private modal: BsModalService,
		private smsRepository: SmsRepositoryService) {
	}

	public openApplicantSmsModal(applicantId) {
		this.smsRepository.getConversation(applicantId).subscribe(
			(data) => {
				const initialState = { conversation: data };
				this.modal.show(InboxConversationDetailComponent, { initialState });
			});
	}
}
