import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface OpenModalRendererParams {
	showLink: (params: OpenModalParams) => boolean;
	showDetail: (params: OpenModalParams) => void;
}

type OpenModalParams = OpenModalRendererParams & ICellRendererParams;

@Component({
	selector: 'open-modal-renderer',
	template: `
		<a *ngIf="params.showLink(params); else plainValue" (click)="invokeParentMethod()">
			<ng-container *ngTemplateOutlet="plainValue"></ng-container>
		</a>
		<ng-template #plainValue>
			<span>{{ params.valueFormatted }}</span>
		</ng-template>
	`
})
export class OpenModalRendererComponent implements ICellRendererAngularComp {
	params: OpenModalParams;

	public agInit(params: OpenModalParams): void {
		this.params = params;
	}

	public refresh(): boolean {
		return false;
	}

	public invokeParentMethod() {
		this.params.showDetail(this.params);
	}
}
