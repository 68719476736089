export enum FieldsPermission {
	None = 'NONE',
	Read = 'READ',
	Write = 'WRITE'
}

export enum ActionsPermission {
	None = 'NONE',
	Own = 'OWN',
	All = 'ALL'
}

export const fieldsPermissionsValues = Object.values(FieldsPermission)
	.map(value => `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`);
export const actionsPermissionsValues = Object.values(ActionsPermission)
	.map(value => `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`);
