import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { TodoCommentRequest } from '@app/shared/model/types/todo';
import { NewTodo } from '@app/todo/model/new-todo.model';
import { TodoCardComment } from '@app/todo/model/todo-card-data';
import { Observable } from 'rxjs';

@Injectable()
export class TodoService {

	constructor(
		private http: HttpClient,
		private apiLink: GaApiLink) {
	}

	public createTodo(todo: NewTodo): Observable<void> {
		return this.http.post<void>('/api/todos', todo);
	}

	public createTodoComment(newComment: TodoCommentRequest): Observable<TodoCardComment> {
		return this.apiLink.create(
			'todoComment',
			newComment,
			[],
			{},
			['sendCommentCreatedWebSocketMessage'],
			[
				'id',
				'author.id',
				'author.firstName',
				'author.lastName',
				'text',
				'createdAt',
				'mentions.id',
				'mentions.seen',
				'mentions.mentionedUser.id',
				'mentions.mentionedUser.firstName',
				'mentions.mentionedUser.lastName'
			]);
	}
}
