import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileDropDirective } from '@app/widgets/file-upload/directive/file-drop.directive';
import { FileSelectDirective } from '@app/widgets/file-upload/directive/file-select.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [FileDropDirective, FileSelectDirective],
	exports: [FileDropDirective, FileSelectDirective]
})
export class FileUploadModule {
}
