import { Component, HostBinding, ViewChild } from '@angular/core';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { SelectListItem } from '@app/shared/model/types/select-list-item';
import * as moment from 'moment';

@Component({
	selector: 'fundings-view',
	templateUrl: './fundings-view.component.html',
	styleUrls: ['./fundings-view.component.scss']
})
export class FundingsViewComponent {

	@HostBinding('class.view') hostClass = true;

	@ViewChild('grid') grid;
	maxDate: Date = new Date();
	dateRange: Date[] = [moment().subtract(30, 'day').toDate(), moment().toDate()];
	dateRangeFormatted: string[];

	// fundings types filter
	showFundingTypesList = false;
	fundingTypesFilterOptions: Array<SelectListItem> = [
		{ id: 1, value: 'all', name: 'All fundings' },
		{ id: 2, value: 'plaintiff', name: 'Plaintiff fundings' },
		{ id: 3, value: 'medical', name: 'Medical fundings' },
		{ id: 4, value: 'court-reporting', name: 'Court reporting fundings' },
	];
	selectedFundingType = this.fundingTypesFilterOptions[0];

	// archived filter
	showArchivedFilterList = false;
	archivedFilterOptions: Array<SelectListItem> = [
		{ id: 1, value: 'non-archived', name: 'Exclude archived' },
		{ id: 2, value: 'all', name: 'Include archived' },
		{ id: 3, value: 'archived', name: 'Only archived' },
	];
	selectedArchivedFilter = this.archivedFilterOptions[0];

	// date type filter
	showDateFilterList = false;
	dateFilterOptions: Array<SelectListItem> = [
		{ id: 1, value: 'createdOn', name: 'Created On' },
		{ id: 2, value: 'appliedOn', name: 'Applied On' },
		{ id: 3, value: 'fundedOn', name: 'Funded On' },
		{ id: 4, value: 'settledOn', name: 'Settled On' },
	];
	selectedDateFilter = this.dateFilterOptions[0];

	dateFilterRanges = [
		{
			value: [moment().startOf('month').toDate(), moment().startOf('day').toDate()],
			label: 'Current month'
		},
		{
			value: [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
			label: 'Last month'
		},
		{
			value: [moment().subtract(1, 'year').startOf('day').toDate(), moment().startOf('day').toDate()],
			label: 'Past year'
		},
		{
			value: [moment().subtract(2, 'year').startOf('day').toDate(), moment().startOf('day').toDate()],
			label: 'Past 2 years'
		}
	];

	readonly svgIconNames = SvgIconName;

	public toggleFundingTypesList(): void {
		this.showFundingTypesList = !this.showFundingTypesList;
	}

	public hideFundingTypesList(): void {
		this.showFundingTypesList = false;
	}

	public onFundingTypeSelect(fundingType: SelectListItem): void {
		this.selectedFundingType = fundingType;
		this.hideFundingTypesList();
		this.showColumnsByFundingType();
	}

	public toggleArchivedFilterList(): void {
		this.showArchivedFilterList = !this.showArchivedFilterList;
	}

	public hideArchivedFilterList(): void {
		this.showArchivedFilterList = false;
	}

	public onArchivedFilterSelect(archivedFilter: SelectListItem): void {
		this.selectedArchivedFilter = archivedFilter;
		this.hideArchivedFilterList();
	}

	public toggleDateFilterList(): void {
		this.showDateFilterList = !this.showDateFilterList;
	}

	public hideDateFilterList(): void {
		this.showDateFilterList = false;
	}

	public onDateFilterClick(dateFilter): void {
		this.selectedDateFilter = dateFilter;
		this.hideDateFilterList();
	}

	public onDateRangeSelect([from, to]): void {
		this.dateRangeFormatted = [moment(from).format('Y-MM-DD'), moment(to).format('Y-MM-DD')];
	}

	public showColumnsByFundingType(): void {
		switch (this.selectedFundingType.value) {
			case 'all':
				this.grid.showAllColumns();
				break;
			case 'plaintiff':
				this.grid.hideMedicalColumns();
				break;
			case 'medical':
				this.grid.hidePlaintiffColumns();
				break;
			case 'court-reporting':
				this.grid.hidePlaintiffColumns();
				break;
			default:
				this.grid.showAllColumns();
		}
	}
}
