import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { fadeUpDown } from '@app/shared/animations/animations';
import { SmsRepositoryService } from '@app/shared/model/sms-repository.service';
import { SmsTemplateCodelist } from '@app/shared/model/types/codelists/sms-template-codelist';
import { Sms } from '@app/shared/model/types/sms';
import { SmsConversation } from '@app/sms/sms-conversation';
import { SmsDirection } from '@app/sms/sms-direction';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import * as moment from 'moment';
import { zip } from 'rxjs';

@Component({
	selector: 'inbox-conversation-detail',
	templateUrl: './inbox-conversation-detail.component.html',
	styleUrls: ['./inbox-conversation-detail.component.scss'],
	animations: [fadeUpDown]
})
export class InboxConversationDetailComponent implements OnChanges, OnInit, AfterViewInit {
	@Input() conversation: SmsConversation;
	@ViewChild('scrollArea') scrollArea: ElementRef;
	@HostBinding('className') className = 'inbox-list';

	readonly faIcons = {
		paperPlane: faPaperPlane,
	};

	newSms: Sms = {
		direction: SmsDirection.Outbound,
		status: 'queued',
		body: '',
		toNumber: '',
		queuedAt: null,
		sendBy: null,
		fromNumber: null,
		receivedAt: null,
		deliveredAt: null
	};
	templates: SmsTemplateCodelist[];
	showTemplateList = false;
	lastMessage = null;

	constructor(
		private smsService: SmsRepositoryService,
		private flashMessage: FlashMessageService,
		private codelistRepository: CodelistRepositoryService,
		private authService: AuthService
	) {
	}

	public ngOnInit(): void {
		this.buildTemplates();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.conversation) {
			if (this.conversation?.smsMessages) {
				const messagesCopy = [...this.conversation.smsMessages];
				this.lastMessage = messagesCopy.reverse().find(this.findLastMessageWithValidDates);
			}
			this.buildTemplates();
		}
	}

	private findLastMessageWithValidDates(sms: Sms): boolean {
		return sms.direction == SmsDirection.Inbound
			? _.get(sms, 'receivedAt', null)
			: _.get(sms, 'deliveredAt', null) || _.get(sms, 'queuedAt', null);
	}

	public sendSms(): void {
		this.newSms.toNumber = this.conversation.phoneNormalized;
		this.newSms.queuedAt = moment().toDate();

		if (this.newSms.toNumber) {
			this.smsService.sendMessage(this.conversation.id, this.newSms).subscribe({
				next: () => {
					this.conversation.smsMessages.push({ ...this.newSms, sendBy: this.authService.getCurrentUser() });
					this.newSms.body = '';
				},
				error: (response: unknown) => {
					const { data } = response as {
						data: string
					};
					this.flashMessage.showMessage(FlashMessageType.Danger, data);
				}
			});
		} else {
			this.flashMessage.showMessage(FlashMessageType.Danger, 'Please assign phone number to applicant.');
		}
	}

	public selectTemplate(template: SmsTemplateCodelist): void {
		this.newSms.body = template.body;
		this.hideTemplateSelectList();
	}

	public buildTemplates(): void {
		if (this.conversation) {
			const templates$ = this.codelistRepository.get<SmsTemplateCodelist[]>('smsTemplate');
			const templatesData$ = this.smsService.getTemplatesData(this.conversation.id);

			zip(templates$, templatesData$).subscribe((response) => {
				const applicantTemplates = Object.assign({}, response);
				_.templateSettings.interpolate = /#([\s\S]+?)#/g;
				this.templates = applicantTemplates[0].map(template => {
					const compiledTemplate = _.template(template.body);
					template.body = compiledTemplate(applicantTemplates[1]);
					return template;
				});
			});
		}
	}

	public toggleTemplateSelectList(): void {
		this.showTemplateList = !this.showTemplateList;
	}

	public hideTemplateSelectList(): void {
		this.showTemplateList = false;
	}

	public ngAfterViewInit(): void {
		if (this.scrollArea) {
			setTimeout(() => {
				this.scrollArea.nativeElement.scrollTop = this.scrollArea.nativeElement.scrollHeight;
			}, 400);
		}
	}
}
