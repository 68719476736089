<div class="chart-clear-wrapper">
	<button class="base-button base-button--square" tooltip="Clear All Graph preferences" *ngIf="hasSavedChart$ | async"
			(click)="clearSavedChartState()">
		<svg-icon class="base-icon base-icon--small" [icon]="clearIcon"></svg-icon>
	</button>
</div>
<div class="breakdown-content">
	<div id="age-breakdown-cases" class="ag-theme-alpine"></div>
	<div id="age-breakdown-prices" class="ag-theme-alpine"></div>
	<ag-grid-angular
	class="ag-theme-balham grid-list"
	[columnDefs]="columnDefs"
	[rowData]="rowData$ | async"
	[gridOptions]="gridOptions"
	(firstDataRendered)="onFirstDataRendered($event)"
	(chartOptionsChanged)="onChartOptionsChanged()">
	</ag-grid-angular>
</div>
