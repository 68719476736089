import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { selectCurrentUserUnseenMentionedTodos } from '@app/core/todo-store/todo.selectors';
import { TodoStatus } from '@app/shared/model/constants/todo-status';
import { EntityId } from '@app/shared/model/types/entity-id';
import { Todo } from '@app/shared/model/types/todo';
import { UserAccount } from '@app/shared/model/types/user-account';
import { TodoCardData } from '@app/todo/model/todo-card-data';
import { Store } from '@ngrx/store';
import { uniqBy } from 'lodash';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'todos-list-widget',
	templateUrl: './todos-list-widget.component.html',
	styleUrls: ['./todos-list-widget.component.scss']
})
export class TodosListWidgetComponent implements OnInit, OnDestroy {
	@Input() todoTasks$: Observable<TodoCardData[]>;
	@Input() unseenMentionsTabVisible = false;
	todoTasks: TodoCardData[] = [];
	filteredAssignees: Array<string> = [];
	assigneeUsers: Array<UserAccount> = [];
	offset = 1;
	filterStatus: TodoStatus = TodoStatus.Todo;
	filterItems = [
		{ offset: 0, status: null, title: 'All' },
		{ offset: 1, status: TodoStatus.Todo, title: 'Todo' },
		{ offset: 2, status: TodoStatus.InProgress, title: 'In Progress' },
		{ offset: 3, status: TodoStatus.Done, title: 'Done' }
	];
	unseenMentionsTabOffset = 4;
	showProgress = false;
	filteredTasks$ = new BehaviorSubject([]);
	unseenMentionsTasks: Partial<Todo>[];
	currentUserId$ = new BehaviorSubject('');

	private destroy$ = new Subject<void>();

	constructor(
		private authService: AuthService,
		private store: Store) {
	}

	public ngOnInit(): void {
		this.currentUserId$.next(this.authService.getCurrentUser().id.toString());
		this.store.select(selectCurrentUserUnseenMentionedTodos(this.currentUserId$.value)).pipe(takeUntil(this.destroy$))
			.subscribe((unseenMentionsTasks) => this.unseenMentionsTasks = unseenMentionsTasks);

		this.todoTasks$.pipe(
			takeUntil(this.destroy$)
		).subscribe((todoTasks) => {
			this.todoTasks = todoTasks;
			this.setAssigneeFilter();
			this.filterTasks();
		});
	}

	public filterByAssigneeUser(assignee: UserAccount | { id: number }): void {
		if (this.filteredAssignees.includes(assignee.id.toString())) {
			this.filteredAssignees = this.filteredAssignees.filter(item => item.toString() !== assignee.id.toString());
		} else {
			this.filteredAssignees.push(assignee.id.toString());
		}
		this.filterTasks();
	}

	public filterTasks(): void {
		let filteredTasks: TodoCardData[];
		if (this.offset === this.unseenMentionsTabOffset) {
			filteredTasks = [...this.unseenMentionsTasks] as unknown as TodoCardData[];
		} else {
			filteredTasks = [...this.todoTasks];
		}

		if (this.filterStatus !== null) {
			const currentUserId = this.authService.getCurrentUser().id.toString();
			filteredTasks = filteredTasks.filter((item: TodoCardData) => item.status === this.filterStatus &&
				item.assignees.some((assignee) => assignee.id.toString() == currentUserId));
		} else {
			const tasks = {};
			tasks[TodoStatus.Todo] = [];
			tasks[TodoStatus.InProgress] = [];
			tasks[TodoStatus.Done] = [];
			filteredTasks.forEach((task) => {
				tasks[task.status].push(task);
			});
		}
		if (this.filteredAssignees.length > 0) {
			filteredTasks = filteredTasks.filter((item: TodoCardData) => {
				return item.assignees.some((assignee) =>
					this.filteredAssignees.includes(assignee.id.toString()));
			});
		}

		this.filteredTasks$.next(filteredTasks);
	}

	public cancelFilters(): void {
		this.filteredAssignees = [];
		this.filterTasks();
	}

	public setAssigneeFilter(): void {
		const assigneesFromTasksArray = this.todoTasks
			? this.todoTasks.reduce((accumulator, item) => {
				accumulator.push(...item.assignees);
				return accumulator;
			}, [])
			: [];
		this.assigneeUsers = uniqBy(assigneesFromTasksArray, (item) => item.id.toString());
	}

	public filter(status: TodoStatus, offset: number): void {
		this.offset = offset;
		this.filterStatus = status;
		this.filterTasks();
	}

	public trackByFn(_index: number, item: Todo): number | null {
		return !item ? null : parseInt(item.id, 10);
	}

	public setOffset(offset: number): void {
		this.offset = offset;
	}

	public setFilterStatus(filterStatus: TodoStatus): void {
		this.filterStatus = filterStatus;
	}

	public removeTodo(todoId: EntityId) {
		this.todoTasks$.pipe(
			map(todoTasks => todoTasks.filter(todoTask => todoTask.id === todoId.toString()))
		).subscribe();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
