<todo-sidebar-expand-button tooltip="Todo list"></todo-sidebar-expand-button>
<div class="todo-widget" [class.expand]="isExpanded$ | async">
	<div class="todo-widget__wrapper">
		<div class="todo-widget__header">
			<div class="chevron-icon-wrapper">
				<fa-icon classes="chevron-icon-wrapper__icon" [icon]="faIcons.chevronRight" size="2x"
						 (click)="closeSidebar()"></fa-icon>
			</div>
			<button class="base-button add-todo" data-cy="addTodo-btn" (click)="openAddTodoModal()">
				<fa-icon [icon]="faIcons.plus"></fa-icon>&nbsp;Add Todo
			</button>
		</div>
		<todos-list-widget [todoTasks$]="todos$" [unseenMentionsTabVisible]="true" class="todos-list-widget">
			<todo-list-widget-sort></todo-list-widget-sort>
		</todos-list-widget>
	</div>
</div>
