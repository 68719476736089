import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from '@app/core/startup/responsive.service';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { DashboardRoundStore } from '@app/dashboard/service/dashboard-round-store.service';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
	selector: 'dashboard',
	templateUrl: './dashboard.component.html',
	providers: [DashboardFilterStore, DashboardRoundStore]
})
export class DashboardComponent implements OnInit {

	isMediumScreen$: Observable<boolean>;
	isLargeScreen$: Observable<boolean>;
	isDefaultScreen$: Observable<boolean>;
	isSmallScreen$: Observable<boolean>;

	constructor(
		private dashboardFilterStore: DashboardFilterStore,
		private dashboardRoundStore: DashboardRoundStore,
		private responsiveService: ResponsiveService) {
	}

	public ngOnInit() {
		this.dashboardRoundStore.setRound(localStorage.getItem('dashboardRound') == 'true');
		this.isMediumScreen$ = this.getScreenListener('screen and (min-width: 1024px) and (max-width: 1599px)');
		this.isLargeScreen$ = this.getScreenListener('screen and (min-width: 1600px)');
		this.isSmallScreen$ = this.responsiveService.isMobile$;
		this.isDefaultScreen$ = combineLatest([this.isSmallScreen$, this.isMediumScreen$, this.isLargeScreen$])
			.pipe(map(([isSmallScreen, isMediumScreen, isLargeScreen]) => !isSmallScreen && !isMediumScreen && !isLargeScreen));

	}

	private getScreenListener(mediaQueryRule: string): Observable<boolean> {
		const mediaQuery = window.matchMedia(mediaQueryRule);
		return fromEvent(mediaQuery, 'change').pipe(
			startWith(mediaQuery),
			map((event: MediaQueryListEvent) => event.matches)
		);
	}

	public onSelect(fundingTypeIds: number[]) {
		this.dashboardFilterStore.setFundingTypeFilter(fundingTypeIds);
	}
}
