import { Injectable, Injector } from '@angular/core';
import { CurrencyDecorator } from '@app/shared/ga-components/decorators/currency.decorator';
import { PercentageDecorator } from '@app/shared/ga-components/decorators/percentage.decorator';
import { StateSelectboxDecorator } from '@app/shared/ga-components/decorators/state-selectbox.decorator';
import { TimeAgoDecorator } from '@app/shared/ga-components/decorators/timeAgo.decorator';
import { WeeksAgoDecorator } from '@app/shared/ga-components/decorators/weeksAgo.decorator';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';

@Injectable({ providedIn: 'root' })
export class DecoratorProviderService {

	constructor(
		private injector: Injector
	) {
	}

	public getDecorator(name, metadata: ComponentMetadata): MetadataDecorator {
		if (name == 'currency') {
			name = CurrencyDecorator;
		} else if (name == 'timeAgo') {
			name = TimeAgoDecorator;
		} else if (name == 'weeksAgo') {
			name = WeeksAgoDecorator;
		} else if (name == 'stateSelectbox') {
			name = StateSelectboxDecorator;
		} else if (name == 'percentage') {
			name = PercentageDecorator;
		}

		return this.injector.get(name).create(metadata);
	}

}
