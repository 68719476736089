import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TodoSortService } from '@app/todo/services/todo-sort.service';
import { defaultTodoSidebarSortOption, todoSidebarSortOptions } from '@app/todo/todo-sidebar/todo-sidebar-sort/todo-sidebar-sort-config';
import { TodoSidebarSortItem } from '@app/todo/todo-sidebar/todo-sidebar-sort/todo-sidebar-sort.model';
import { SortDirection } from '@app/w-boards/shared/sort-icon/models/sort-settings';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { NgSelectModule } from '@ng-select/ng-select';
import { Observable } from 'rxjs';

@Component({
	selector: 'todo-list-widget-sort',
	templateUrl: 'todo-sidebar-sort.component.html',
	styleUrls: ['todo-sidebar-sort.component.scss'],
	imports: [
		NgSelectModule,
		FontAwesomeModule,
		CommonModule,
		ReactiveFormsModule
	],
	standalone: true
})
export class TodoSidebarSortComponent implements OnInit {

	sortIcon$: Observable<IconDefinition>;
	sortControl = new FormControl(defaultTodoSidebarSortOption);
	readonly sortOptions = todoSidebarSortOptions;

	constructor(private todoSidebarService: TodoSortService) {
	}

	public ngOnInit(): void {
		this.sortIcon$ = this.todoSidebarService.sortDirectionIcon$;
	}

	public changeSortDirection(): void {
		const currentDirection = this.todoSidebarService.sortDirection;
		const newDirection = currentDirection + 1 > SortDirection.Desc ? SortDirection.None : currentDirection + 1;
		this.todoSidebarService.sortDirection = newDirection;
	}

	public itemSelected(selectedItem: TodoSidebarSortItem): void {
		this.todoSidebarService.sortItem = selectedItem;
	}
}
