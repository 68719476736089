import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { Todo } from '@app/shared/model/types/todo';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TodoSidebarService {

	private _isExpanded$ = new BehaviorSubject(false);
	private _showDotNotification$ = new BehaviorSubject(false);
	private readonly currentUserId: number;

	constructor(private authService: AuthService) {
		this.currentUserId = this.authService.getCurrentUser().id;
	}

	get isExpanded() {
		return this._isExpanded$.value;
	}

	set isExpanded(value: boolean) {
		this._isExpanded$.next(value);
	}

	get isExpanded$() {
		return this._isExpanded$.asObservable();
	}

	public toggleIsExpanded() {
		this._isExpanded$.next(!this._isExpanded$.value);
	}

	get showDotNotification$() {
		return this._showDotNotification$.asObservable();
	}

	get showDotNotification() {
		return this._showDotNotification$.value;
	}

	public initDotNotification() {
		const savedNotificationStatus = JSON.parse(localStorage.getItem('todoNotificationStatus'));

		this._showDotNotification$.next(savedNotificationStatus && savedNotificationStatus.userId == this.currentUserId
			? savedNotificationStatus.notification === 'true' : false);
	}

	public setNotificationStatus(hasNotification: boolean): void {
		this._showDotNotification$.next(hasNotification);
		localStorage.setItem('todoNotificationStatus',
			JSON.stringify({ userId: this.currentUserId, notification: hasNotification.toString() }));
	}

	public hasNewTodoAssigned(newTodo: Todo): boolean {
		if (newTodo.createdBy &&
			newTodo.createdBy.id != this.currentUserId &&
			newTodo.assignees.find((assignee) => assignee?.id == this.currentUserId)
		) {
			return true;
		}
		return false;
	}
}
