import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardDataUtilsService } from '@app/dashboard/dashboard-data-utils.service';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { DashboardBreakdownChartService } from '@app/dashboard/shared/breakdown-chart/dashboard-breakdown-chart-service';
import { ChartBreakdownResponse } from '@app/dashboard/shared/breakdown-chart/dashboard-breakdown-chart.model';
import { ModalsService } from '@app/shared/modals/modals.service';
import { ApprovalStatus } from '@app/shared/model/constants/approval-status';

@Injectable()
export class FundingConsultantsBreakdownChartService extends DashboardBreakdownChartService<'fundingConsultants'> {

	constructor(
		private dashboardFilter: DashboardFilterStore,
		private http: HttpClient,
		private dashboardDataUtils: DashboardDataUtilsService,
		private modalService: ModalsService) {
		super();
	}

	protected callApiRequest(params: HttpParams) {
		return this.http.get<ChartBreakdownResponse<'fundingConsultants'>[]>('/api/dashboard/funding-consultants-breakdown', { params });
	}

	public async openDetailAdvanced(period, fundingConsultant): Promise<void> {
		const { from, to } = this.dashboardDataUtils.getPeriod(period);
		const search = {
			fundedOn: {
				from,
				to
			},
			applicant: {
				attorney: {
					lawFirm: {
						fundingConsultant: { id: fundingConsultant.id }
					}
				}
			},
			approvalStatus: [ApprovalStatus.Funded, ApprovalStatus.Settled],
			...(await this.getCommonModalSearchOptions(this.dashboardFilter.selectedFundingTypes$))
		};
		this.modalService.openVintageDetail({ filter: search });
	}

	public async openDetailCollected(period, fundingConsultant): Promise<void> {
		const { from, to } = this.dashboardDataUtils.getPeriod(period);
		const search = {
			settledOn: {
				from,
				to
			},
			applicant: {
				attorney: {
					lawFirm: {
						fundingConsultant: { id: fundingConsultant.id }
					}
				}
			},
			approvalStatus: [ApprovalStatus.Settled],
			...(await this.getCommonModalSearchOptions(this.dashboardFilter.selectedFundingTypes$))
		};
		this.modalService.openVintageDetail({ filter: search });
	}
}
