import { NgModule } from '@angular/core';
import { CurrencyInputDirective } from '@app/shared/custom-form-controls/currency-input.directive';

@NgModule({
	declarations: [
		CurrencyInputDirective
	],
	exports: [
		CurrencyInputDirective
	]
})
export class CustomFormControlsModule {}
