<label class="base-label" *ngIf="label">{{label}}</label>
<div class="label-multi-select">
    <ng-select [formControl]="selectControl"
               class="base-input"
               [multiple]="true"
               [closeOnSelect]="false"
               [clearSearchOnAdd]="true"
               [virtualScroll]="true"
               [attr.data-cy]="dataCyTag | cypressTag : 'select'"
               [inputAttrs]="{'data-cy': dataCyTag | cypressTag : 'input'}"
               appendTo="body"
               [items]="items"
               bindValue="id"
               [searchFn]="searchingHelper.searchCodelist"
               [placeholder]="placeholder">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="base-flex base-flex--align-center" *ngFor="let item of items | slice:0:2">
				<span class="selection-label"
                      [ngStyle]="{'background-color': item.colorHex, 'color': item.colorHex | textColor}">{{item.title}}</span>
                <span class="selection-icon"
                      [ngStyle]="{'background-color': item.colorHex, 'color': item.colorHex | textColor}"
                      (click)="clear(item)" aria-hidden="true">×</span>
            </div>
            <div *ngIf="items.length > 2">
                <span>+ {{items.length - 2}} more</span>
            </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$">
            <div class="base-flex base-flex--align-center codelist-multiselect__option">
                <input type="checkbox" [ngModel]="item$.selected">
                <span class="option">{{item.title}}</span>
            </div>
        </ng-template>
        <ng-template ng-footer-tmp>
            <div class="base-flex base-flex--justify-center codelist-multiselect__footer">
                <button class="base-button base-button--small"
                        [attr.data-cy]="dataCyTag + '__select-all-btn'"
                        (click)="selectAll()">
                    Select all
                </button>
                <button class="base-button base-button--small"
                        [attr.data-cy]="dataCyTag + '__select-none-btn'"
                        (click)="selectNone()">
                    Select none
                </button>
            </div>
        </ng-template>
    </ng-select>
</div>
