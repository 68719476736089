import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TodoCommentMentionRequest } from '@app/shared/model/types/todo';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

// TODO: Rewrite TodoService as ComponentStore and make comment part of the state
@Injectable()
export class TodoCommentService {
	private _newCommentText = new FormControl(null);
	private selectedChoices$ = new BehaviorSubject([]);
	private showCheck$ = new BehaviorSubject(false);
	private inputTouch$ = new Subject<void>();
	private _commentMentionRequests: TodoCommentMentionRequest[] = [];

	get newCommentText(): string {
		return this._newCommentText.value;
	}

	public resetNewCommentTextCtrl(): void {
		this._newCommentText.reset();
	}

	public getNewCommentTextCtrl(): FormControl<string> {
		return this._newCommentText;
	}

	public getSelectedChoices(): Observable<any[]> {
		return this.selectedChoices$.asObservable();
	}

	set selectedChoices(selectedChoices: []) {
		this.selectedChoices$.next(selectedChoices);
	}

	public getShowCheck(): Observable<boolean> {
		return this.showCheck$.asObservable();
	}

	set showCheck(value: boolean) {
		this.showCheck$.next(value);
	}

	public getInputTouch(): Observable<void> {
		return this.inputTouch$.asObservable();
	}

	public emitInputTouch(): void {
		this.inputTouch$.next();
	}

	set commentMentionRequests(commentMentionRequests: TodoCommentMentionRequest[]) {
		this._commentMentionRequests = commentMentionRequests;
	}

	get commentMentionRequests() {
		return this._commentMentionRequests;
	}
}
