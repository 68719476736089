import { Injectable } from '@angular/core';
import { FlashMessage } from '@app/core/flash-messages/flash-message/flash-message';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FlashMessageService {
	private messageSubject$: Subject<FlashMessage> = new Subject<FlashMessage>();

	public showMessage(type: string, body: string, options?: { timeout?: number, showCloseButton?: boolean }) {
		const flashMessage = new FlashMessage(this.generateMessageId(body), body, type, options?.timeout, options?.showCloseButton);
		this.messageSubject$.next(flashMessage);
	}

	get messages(): Observable<FlashMessage> {
		return this.messageSubject$.asObservable();
	}

	private generateMessageId(message: string): string {
		return message?.replace(/[^a-zA-Z0-9]+/g, '') ?? '';
	}
}
