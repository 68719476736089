import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AvatarService } from '@app/shared/avatar/avatar.service';
import { UserAvatar } from '@app/shared/model/types/user-avatar';
import { environment } from '@env/environment';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';
import { BehaviorSubject, EMPTY, filter, map, merge, Observable, ReplaySubject, startWith, Subject, takeUntil } from 'rxjs';

export type UserWithAvatar = {
	id: string | number,
	firstName?: string,
	lastName?: string,
	name?: string,
	userAvatar?: UserAvatar
}

@Component({
	selector: 'avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit, OnChanges, OnDestroy {

	@Input() user: UserWithAvatar;

	@Input() set isActive(value: boolean) {
		this.isActive$.next(value);
	}
	@Input() customClass;
	@Input() showTooltip = true;
	@Input() tooltipPlacement: AvailbleBSPositions = 'bottom';
	@Input() activeEmitter: Observable<[{ userId: number, status: boolean }]> = EMPTY;

	isActive$ = new BehaviorSubject(false);

	tooltip$: Observable<string>;
	userPropagator$ = new ReplaySubject<UserWithAvatar>(1);
	avatarPath$: Observable<string>;

	hideImage = false;

	readonly unknownUserIcon = faUser;
	private destroy$ = new Subject<void>();

	constructor(private avatarService: AvatarService) {
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes?.user?.currentValue) {
			this.userPropagator$.next(changes.user.currentValue);
		}
	}

	public ngOnInit(): void {
		this.activeEmitter
			.pipe(
				filter(users => users.some(userSelected => userSelected.userId == null || userSelected.userId == this.user.id)),
				map(userSelected => userSelected.find(user => user.userId == this.user.id)?.status ?? this.isActive),
				startWith(this.isActive$.value),
				takeUntil(this.destroy$)
			).subscribe((value) => {
			this.isActive$.next(value)
		});

		const pathFromService$ = this.avatarService.getAvatar()
			.pipe(
				filter(({ userId }) => userId == this.user?.id)
			);
		const pathFromUser$ = this.userPropagator$.asObservable()
			.pipe(
				filter(user => user != undefined)
			);

		this.avatarPath$ = merge(pathFromService$, pathFromUser$)
			.pipe(
				map(user => user?.userAvatar?.localPath),
				map(localPath => localPath ? `${environment.apiUrl}/${localPath}` : null),
			);
		this.tooltip$ = this.userPropagator$.asObservable()
			.pipe(
				filter(() => this.showTooltip),
				map(user => this.resolveUserTooltip(user))
			)
	}

	private resolveUserTooltip(user: UserWithAvatar): string {
		let tooltip = '';
		if (user?.firstName) {
			tooltip += user.firstName;
		}
		if (user?.lastName) {
			tooltip += ` ${user.lastName}`;
		}
		if (user?.name) {
			tooltip = user.name;
		}
		return tooltip;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
