<collapsible-item [isCollapsedAtFirst]="true">
	<div header-left>
		<h5>Medical purchases</h5>
	</div>
	<div class="row m-t-sm">
		<div class="col-sm-12">
			<table class="table-striped" *ngrxLet="round$; let roundValue">
				<thead>
				<tr>
					<th></th>
					<th class="number">$ Purchased</th>
					<th class="number"># Purchased</th>
					<th class="number">$ AVG PP</th>
					<th class="number">Purchase Rate</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let medical of medicalBreakdown">
					<td><b>{{ medical?.title }}</b></td>
					<td class="number">
						{{ (medical?.dealsAmount ?? 0)| currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
					</td>
					<td class="number">
						{{ (medical?.dealsCount ?? 0) | number : '1.0-1' }}
					</td>
					<td class="number">
						{{ (medical?.avgPurchasePrice ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
					</td>
					<td class="number">
						{{ (medical?.purchaseRate ?? 0) | number : roundValue ? '1.0-0' : '1.2-2'}}%
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</collapsible-item>
