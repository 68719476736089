import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DueDateControlData } from '@app/todo/todo-due-date/model/due-date-control-data.model';
import * as moment from 'moment';
import { distinctUntilChanged, map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'datepicker-input',
	templateUrl: 'datepicker-input.component.html'
})
export class DatepickerInputComponent implements OnInit, OnDestroy {
	@Input() validator: (dateString) => boolean;
	@Input() placeholder = '';
	@Input() readonly bsConfig = {
		containerClass: 'custom-datepicker-theme',
		customTodayClass: 'custom-today-class'
	};
	@Input() dataCyTag = 'datepicker-input';

	@Output() onDateControlChange = new EventEmitter<DueDateControlData>();
	dateControl = new FormControl<Date>(null);
	isOpen = false;
	stringControl = new FormControl<string>('');
	bsDate$: Observable<Date | null>;

	private destroy$ = new Subject<void>();

	public ngOnInit(): void {
		if (!this.validator) {
			this.validator = (dateString: string) => moment(dateString).isValid();
		}

		this.bsDate$ = this.stringControl.valueChanges.pipe(
			distinctUntilChanged(),
			map(dateString => {
				if (dateString === '') {
					return null;
				}
				return this.validator(dateString) ? moment(dateString).toDate() : null
			}),
			shareReplay({ bufferSize: 1, refCount: true })
		);
		this.bsDate$.pipe(takeUntil(this.destroy$)).subscribe(dateValue => {
				let error = null;
				if (!dateValue && this.stringControl.value) {
					error = { invalidDate: true };
				}
				this.dateControl.setValue(dateValue);
			this.dateControl.setErrors(error);
			this.onDateControlChange.next({ value: this.dateControl.value, isValid: this.dateControl.valid });
			}
		);
	}

	public bsDatepickerDateChanged(date: Date): void {
		if (date === null) {
			return;
		}
		this.stringControl.setValue(moment(date).format('MM/DD/YYYY'));
	}

	public open(): void {
		this.isOpen = true;
	}

	public close(): void {
		this.isOpen = false;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
