import { FormControl } from '@angular/forms';
import { Persistable } from '@app/core/data-watch/utils';
import { defaultFilterContext, FilterContext } from '@app/shared/filter-visitors/models/filter-context';
import { FilterMode } from '@app/shared/filter-visitors/models/filter-mode';

export abstract class FilterVisitor<T = any> {
	protected context: FilterContext = defaultFilterContext;
	protected id: string;
	private readonly formControl: FormControl<T>;

	protected constructor(id: string) {
		this.id = id;
		this.formControl = new FormControl(null);
	}

	public abstract filter(filterObject: Persistable): boolean;

	public abstract isActive(): boolean;

	get value() {
		return this.formControl.value;
	}

	public getFormControl() {
		return this.formControl;
	}

	public setFilter(filter: T, propagateChange = true): void {
		this.formControl.setValue(filter, { emitEvent: propagateChange });
	}

	public setContext(context: FilterContext): void {
		this.context = context;
	}

	public getMode(): FilterMode {
		return this.context.mode;
	}

	public getId(): string {
		return this.id;
	}

	public getAsObj(): {value: T, context: FilterContext, id: string} {
		return {
			value: this.value,
			context: this.context,
			id: this.id
		};
	}

	public isFiltering(): boolean {
		return this.isActive() || this.context.mode != defaultFilterContext.mode;
	}
}
