<collapsible-item>
	<h4 header-left>
		<fa-icon [icon]="faIcons.table" class="m-r-sm"></fa-icon>
		Data Table:
	</h4>
	<div>
		<div class="text-right" *aclIsAllowed="'reports.indicator'">
			<button title="Config" class="btn btn-link" (click)="panel.toggle()">
				<fa-icon [icon]="faIcons.cogs"></fa-icon>
			</button>
		</div>
		<overlay-panel #panel>
			<div class="form-group">
				<strong>Date Range</strong>
				<input class="form-control" bsDaterangepicker [bsConfig]="bsDaterangeConfig"
					   [formControl]="dateRangeControl" placement="left">
			</div>
			<div class="form-group text-right">
				<button
					class="btn btn-primary"
					[disabled]="externalLeadSourceControl.invalid"
					(click)="apply(panel)">
					Apply
				</button>
			</div>
			<div class="form-group">
				<strong>
					External Lead Source
					<small class="pull-right text-danger" *ngIf="externalLeadSourceControl.invalid">
						Please select at least one lead source
					</small>
				</strong>
				<ng-select
					*ngrxLet="externalLeadSources$; let externalLeadSources"
					[items]="externalLeadSources"
					[multiple]="true"
					[formControl]="externalLeadSourceControl"
					[clearSearchOnAdd]="true"
					[closeOnSelect]="false"
					bindLabel="title"
					bindValue="id"
					class="base-input"
					appendTo="body">
					<ng-template ng-header-tmp>
						<div>
							<button class="btn btn-primary"
									(click)="selectExternalLeadSources(externalLeadSources)">Select All
							</button>
							<button class="btn btn-primary ml-1"
									(click)="clearExternalLeadSources()">Clear All
							</button>
						</div>
					</ng-template>
				</ng-select>
			</div>
		</overlay-panel>
		<ag-grid-angular
			class="ag-theme-balham"
			[gridOptions]="gridOptions"
			[rowData]="rowData$ | async"
			[columnDefs]="columnDefs"
			(gridReady)="onGridReady($event)"
		></ag-grid-angular>
		<download-link [config$]="downloadLink$"></download-link>
	</div>
</collapsible-item>
