import { Component, Input } from '@angular/core';
import { HistoricalPerformance } from '@app/dashboard/historical-performance/historical-performance.model';

@Component({
	selector: 'historical-performance-table',
	templateUrl: 'historical-performance-table.component.html'
})
export class HistoricalPerformanceTableComponent {
	@Input() entry: HistoricalPerformance;
	@Input() roundValue: boolean;

	readonly ghostElementWidths = {
		numberContent: 7.5,
		multiplier: 3
	}

}
