import { Pipe, PipeTransform } from '@angular/core';
import { UserAccount } from '@app/shared/model/types/user-account';

@Pipe({name: 'nameInitials'})
export class NameInitialsPipe implements PipeTransform {

	public transform(userAccount: UserAccount): string {
		return this.canReturnInitials(userAccount) ? `${userAccount.firstName[0] ?? ''}${userAccount.lastName[0] ?? ''}` : '';
	}

	private canReturnInitials(userAccount: UserAccount): boolean {
		return userAccount?.firstName?.length > 0 || userAccount?.lastName?.length > 0;
	}
}
