import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { NgSelectSearchingHelperService } from '@app/shared/model/ng-select-searching-helper.service';
import { FundingType } from '@app/shared/model/types/funding-type';
import { map, Observable, Subject, takeUntil } from 'rxjs';

type FundingTypeWithGroup = FundingType & { groupTitle: string }

@Component({
	selector: 'dashboard-filter',
	templateUrl: './dashboard-filter.component.html',
})
export class DashboardFilterComponent implements OnInit, OnDestroy {
	@Output() onSelect = new EventEmitter<number[]>();

	filterItems$: Observable<FundingTypeWithGroup[]>;
	selectControl = new FormControl<FundingTypeWithGroup[]>([]);

	private destroy$ = new Subject<void>();

	constructor(private codelistRepository: CodelistRepositoryService,
				public searchingHelper: NgSelectSearchingHelperService) {}

	groupByFn = (item) => item.groupTitle;

	public ngOnInit() {
		this.filterItems$ = this.codelistRepository.get(NonUrlCodelistName.FundingType).pipe(
			map((fundingTypes: FundingType[]) => fundingTypes.map((fundingType) => this.addFilterGroup(fundingType)))
		);

		this.selectControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((selectedItems) => {
			this.onSelect.emit(selectedItems.map((item) => +item.id));
		});
	}

	private addFilterGroup(fundingType: FundingType) {
		if (fundingType.medical && !fundingType.isCourtReporting) {
			return { groupTitle: 'Medical', ...fundingType };
		} else if (!fundingType.medical) {
			return { groupTitle: 'Pre-Set', ...fundingType };
		}
		return { groupTitle: null, ...fundingType };
	}

	public selectAll(allItems: FundingTypeWithGroup[]) {
		this.selectControl.setValue(allItems);
	}

	public clearAll() {
		this.selectControl.setValue([]);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
