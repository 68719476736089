<div class="new-modal">
	<div class="new-modal__header">
		<h3>{{ isEdit ? 'Edit Activity' : 'Add Activity' }}</h3>
	</div>
	<div class="new-modal__content">
		<div class="new-modal__content__form-group salesActivity__activity">
			<div class="select-item"
				 (clickOutside)="showActivityTypeSelect = false"
				 data-cy="salesActivity-selectList">
				<div class="salesActivity__activity-select" (click)="toggleActivityTypeSelect()">
					<label class="new-modal__content__form-group__label">Sales Activity (required)</label>
					<div class="flex">
						<div *ngFor="let selectedSalesActivity of selectedSalesActivities" class="select-item__content"
							 [class.placeholder]="!selectedSalesActivity"
							 [style.color]="selectedSalesActivity ? selectedSalesActivity.colorHex : '#c1c4dc'">
							<svg-icon *ngIf="selectedSalesActivity" [icon]="selectedSalesActivity.icon"
									  [ngStyle]="{'fill': selectedSalesActivity.colorHex}"></svg-icon>
							{{ selectedSalesActivity ? selectedSalesActivity.title : 'Please choose sales activity type' }}
						</div>
					</div>
				</div>
				<select-list
					[items]="salesActivityTypes | async"
					[show]="showActivityTypeSelect"
					[selected]="selectedSalesActivities"
					[attributes]="['title']"
					[colorAttr]="'colorHex'"
					[iconAttr]="'icon'"
					multiple
					(onItemSelect)="changeSalesActivityType($event)">
				</select-list>
			</div>
		</div>
		<div class="new-modal__content__form-group">
			<label class="new-modal__content__form-group__label">Summary (required)</label>
			<input type="text" [(ngModel)]="salesActivitySummary" name="salesActivitySummary" class="base-input"
				   data-cy="salesActivitySummary-input"/>
		</div>
		<div class="new-modal__content__form-group" data-cy="salesActivityContent-element">
			<label class="new-modal__content__form-group__label">Content (required)</label>
			<md-editor name="salesActivityDescription"
					   [(ngModel)]="salesActivityDescription"
					   [height]="'200px'"
					   [options]="options"
					   data-cy="salesActivityContent-textEditor">
			</md-editor>
		</div>
		<div *ngrxLet="associations$; let associations" class="new-modal__content__associations">
			<entity-associations-select
				[associations]="associations"
				[associationTypes]="noteAssociationTypes"
				(onAssociationSelected)="associationSelected($event)"
			></entity-associations-select>
			<entity-associations-list
				[associations]="associations"
				(onRemoveAssociation)="removeAssociation($event)"
			></entity-associations-list>
		</div>
		<div class="new-modal__content__form-group">
			<label class="new-modal__content__form-group__label">Activity date (required)</label>
			<input type="text"
				   class="base-input"
				   data-cy="salesActivityDate-input"
				   [bsConfig]="{ containerClass: 'custom-datepicker-theme top', customTodayClass: 'custom-today-class' }"
				   [(bsValue)]="salesActivityDate"
				   placeholder="Date"
				   bsDatepicker
				   placement="top"
				   readonly>
		</div>
	</div>
	<div class="new-modal__footer">
		<button class="base-button base-button--cancel" (click)="closeModal()">Cancel</button>
		<button class="base-button" (throttleClick)="saveActivityNote()" data-cy="save-btn">Save</button>
	</div>
	<loading-spinner [show]="isSavingData" loadingLabel="Saving data"></loading-spinner>
</div>
