import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { AclRoleType } from '@app/core/security/acl-role-type';
import { AclService } from '@app/core/security/acl.service';
import { AgLatestNoteRendererComponent } from '@app/dashboard/board-deals-grid/ag-latest-note-renderer/ag-latest-note-renderer.component';
import { BoardListRendererComponent } from '@app/dashboard/board-deals-grid/board-list-renderer.component';
import { BoardDealsGridFunding } from '@app/dashboard/board-deals-grid/model/board-deals-grid-funding';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { currencyColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/currency-column-definition';
import { dateColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/date-column-definition';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { CurrencyHelperService } from '@app/shared/model/currency-helper.service';
import { EffectiveRate } from '@app/shared/model/types/codelists/effective-rate';
import { FundingTypeCodelist } from '@app/shared/model/types/codelists/funding-type';
import { MedicalProcedureCategory } from '@app/shared/model/types/codelists/medical-procedure-category';
import { MedicalProcedureType } from '@app/shared/model/types/codelists/medical-procedure-type';
import { ApprovalStatusPipe } from '@app/shared/pipes/approval-status.pipe';
import { BoardHelperService } from '@app/w-boards/services/board-helper.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'board-deals-grid',
	templateUrl: './board-deals-grid.component.html',
	providers: [ApprovalStatusPipe],
	styles: ['ag-grid-angular {width: 100%; height: 50vh}']
})
export class BoardDealsGridComponent implements OnInit, OnDestroy {
	deals$: Observable<BoardDealsGridFunding[]>;
	columnDefinitions: ColDef[];
	gridOptions: GridOptions;
	private destroy$ = new Subject<void>();

	constructor(
		private http: HttpClient,
		private acl: AclService,
		private agUtils: AgGridUtilsService,
		private boardHelper: BoardHelperService,
		private approvalStatus: ApprovalStatusPipe,
		private codelistService: CodelistRepositoryService,
		private authService: AuthService,
		private currencyHelper: CurrencyHelperService) {
	}

	public ngOnInit(): void {
		this.gridOptions = this.agUtils.getGridOptions({
			suppressExcelExport: !this.acl.isAllowed('export.dashboard_sales_deals_table'),
			defaultExcelExportParams: {
				fileName: 'Sales Deals Export'
			},
		});

		this.columnDefinitions = [
			{
				field: 'applicant.lawFirmManager',
				headerName: 'Law Firm Manager',
				valueGetter: ({ data }) => this.agUtils.getFullName(data?.applicant?.lawFirmManager, ''),
				sortable: true,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.getByUrl(UrlCodelistLink.LawFirmManagers)
						.pipe(takeUntil(this.destroy$))
						.subscribe(data =>
							params.success(this.agUtils.getArrayOfStringsByFiledName(data, ['firstName', 'lastName']))
						),
					buttons: ['clear', 'reset']
				},
				minWidth: 200
			},
			{
				field: 'fundingConsultant',
				headerName: 'Account Growth Rep',
				valueGetter: ({ data }) => this.agUtils.getFullName(data?.applicant?.attorney?.lawFirm?.fundingConsultant, ''),
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.getByUrl(UrlCodelistLink.FundingConsultants)
						.pipe(takeUntil(this.destroy$))
						.subscribe(data =>
							params.success(this.agUtils.getArrayOfStringsByFiledName(data, ['firstName', 'lastName']))
						),
					buttons: ['clear', 'reset']
				},
			},
			{
				field: 'internalId',
				headerName: 'Funding ID',
				cellRenderer: ({ data, value }) =>
					data?.applicant ? this.agUtils.fundingLinkRenderer(value, data?.id, data?.applicant?.id) : value,
				sortable: true,
				filter: 'agTextColumnFilter',
				minWidth: 120,
			},
			{
				field: 'fundingType.title',
				headerName: 'Funding Type',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.get<FundingTypeCodelist[]>(NonUrlCodelistName.FundingType)
						.pipe(takeUntil(this.destroy$))
						.subscribe((data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title'))),
					buttons: ['clear', 'reset']
				}
			},
			{
				field: 'appliedOn',
				headerName: 'Initiated on',
				...dateColumnDefinition('appliedOn')
			},
			{
				headerName: 'Applicant',
				valueGetter: ({ data }) => `${data?.applicant?.firstName ?? ''} ${data?.applicant?.lastName ?? ''}`,
				filter: 'agTextColumnFilter',
				sortable: true,
				cellRenderer: ({ data, value }) =>
					data ? this.agUtils.applicantLinkRenderer(data?.applicant?.id, value) : value,
				minWidth: 200,
			},
			{
				field: 'applicant.attorney.lawFirm.name',
				headerName: 'Law Firm',
				sortable: true,
				cellRenderer: ({ data, value }) => {
					return data ? this.agUtils.lawFirmLinkRenderer(data?.applicant?.attorney?.lawFirm?.id, value) : value;
				},
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => {
						this.codelistService.getByUrl(UrlCodelistLink.LawFirms)
							.pipe(takeUntil(this.destroy$))
							.subscribe(value => params.success(this.agUtils.getArrayOfStringsByFiledName(value, 'name')));
					},
					buttons: ['clear', 'reset'],
				},
				minWidth: 200
			},
			{
				field: 'medicalProcedureType.title',
				headerName: 'Medical Procedure Type',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.get<MedicalProcedureType[]>(NonUrlCodelistName.MedicalProcedureType)
						.pipe(takeUntil(this.destroy$))
						.subscribe((data) =>
							params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title'))),
					buttons: ['clear', 'reset']
				}
			},
			{
				field: 'medicalProcedureType.category.title',
				headerName: 'Medical Procedure Category',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => this.codelistService.get<MedicalProcedureCategory[]>(NonUrlCodelistName.MedicalProcedureCategory)
						.pipe(takeUntil(this.destroy$))
						.subscribe(
							(data) => params.success(this.agUtils.getArrayOfStringsByFiledName(data, 'title'))),
					buttons: ['clear', 'reset']
				}
			},
			{
				headerName: 'Effective Rate',
				field: 'medicalProcedureType.category.effectiveRate.title',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => {
						this.codelistService.get<EffectiveRate[]>(NonUrlCodelistName.EffectiveRate)
							.pipe(takeUntil(this.destroy$))
							.subscribe(effectiveRates =>
								params.success(this.agUtils.getArrayOfStringsByFiledName(effectiveRates, 'title')));
					},
					buttons: ['clear', 'reset'],
				},
				comparator: (valueA: string, valueB: string) => {
					if (!valueA) {
						return -1;
					}
					if (!valueB) {
						return 1;
					}
					return this.currencyHelper.parseCurrency(valueA) - this.currencyHelper.parseCurrency(valueB);
				},
				sortable: true,
			},
			{
				headerName: 'Approval Status',
				filter: 'agSetColumnFilter',
				valueGetter: params => this.approvalStatus.transform(params.data?.approvalStatus),
				maxWidth: 180,
			},
			{
				headerName: 'Requested Amount',
				...currencyColumnDefinition('requestedFunding'),
				sortable: true,
			},
			{
				headerName: 'Approved At',
				...currencyColumnDefinition('approvedAt'),
				sortable: true,
			},
			{
				colId: 'boards',
				headerName: 'Current Boards',
				cellRenderer: BoardListRendererComponent,
				valueGetter: ({ data }) => {
					return data?.boards.reduce((acc, board) => {
						const boardConfig = this.boardHelper.resolveBoard(data, board);
						if (boardConfig == null) {
							return acc;
						}
						return acc + `${boardConfig.name} - ${boardConfig.column} `;
					}, '');
				},
				filter: 'agTextColumnFilter',
				sortable: false,
				minWidth: 500,
				resizable: true,
				filterValueGetter: ({ data }) => {
					return data?.boards.reduce((acc, board) => {
						const boardConfig = this.boardHelper.resolveBoard(data, board);
						if (boardConfig == null) {
							return acc;
						}
						return acc + `${boardConfig.name} ${boardConfig.column} `;
					}, '');
				}
			},
			{
				headerName: 'Latest Note',
				field: 'fundingNotes',
				cellRenderer: AgLatestNoteRendererComponent,
				filter: 'agTextColumnFilter',
				minWidth: 500
			},
		];
		if (!this.authService.identity.salesRepresentative || this.authService.identity.roleType === AclRoleType.SuperAdmin) {
			this.addSupervisorSpecificData();
		}

		this.deals$ = this.http.get<BoardDealsGridFunding[]>('/api/dashboard/sales-deals');
	}

	private addSupervisorSpecificData(): void {
		this.columnDefinitions.unshift({
			headerName: 'New Business Rep',
			valueGetter: params => {
				const salesRepresentative = params.data?.applicant?.attorney?.lawFirm?.salesRepresentative;
				return salesRepresentative?.firstName && salesRepresentative?.lastName
					? `${salesRepresentative.firstName} ${salesRepresentative.lastName}` : '';
			},
			filter: 'agTextColumnFilter',
		});
	}

	getRowHeight = params => (params.data?.boards?.length ? params.data.boards?.length : 1) * 30;

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
