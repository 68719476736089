<div class="todo-card__comments" *ngrxLet="comments$; let comments">
	<h4>Comments ({{ comments?.length || '0'}})
		<span (click)="toggleContent()" [ngClass]="{'active': isContentExpanded}" data-cy="commentsExpand-btn">
			<fa-icon [icon]="faIcons.chevronDown"></fa-icon>
		</span>
	</h4>
	<ng-container *ngrxLet="latestComment$; let latestComment">
		<todo-comment *ngIf="latestComment && !isContentExpanded" [todoComment]="latestComment" [attr.data-cy]="'commentCard__#'+latestComment?.id"></todo-comment>
	</ng-container>
	<div *ngIf="isContentExpanded" class="todo-card__comments__body">
		<todo-comments-sorting (onSortChange)="changeSorting($event)"></todo-comments-sorting>
		<todo-comment *ngFor="let comment of comments" [todoComment]="comment" [attr.data-cy]="'commentCard__#'+comment?.id"></todo-comment>
		<todo-comment-card-form [todoId]="todoId"></todo-comment-card-form>
	</div>
</div>


