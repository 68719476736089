import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { AclGuard } from '@app/core/security/acl.guard';
import { UseCase } from '@app/core/security/use-case';
import { TodosListComponent } from '@app/todo/list/todos-list.component';
import { TodoWhiteboardComponent } from '@app/todo/whiteboard/whiteboard.component';

const routes: Routes = [
	{
		path: 'todos', canActivate: [AuthGuard], children: [
			{
				path: 'board', component: TodoWhiteboardComponent, canActivate: [AclGuard],
				data: { security: UseCase.for('todo.whiteboard'), title: 'To-do Board' }
			},
			{
				path: 'list', component: TodosListComponent, canActivate: [AclGuard],
				data: { security: UseCase.for('todo.list'), title: 'To-do Archive' }
			},
		]
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TodoRoutingModule {}
