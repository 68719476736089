import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { defaultSwitchParams, SwitchParams } from '@app/shared/switch/switch.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'rp-switch',
	templateUrl: './switch.component.html',
	styles: ['.toggle-switch__control--on {transform: none}']
})
export class SwitchComponent implements OnInit, OnChanges {

	@Input() value = false;
	@Input() params: SwitchParams = defaultSwitchParams;
	@Output() switched = new EventEmitter<boolean>();

	formControl = new FormControl(false);
	boxShadow: string;
	bgColor: string;
	inputId: string;

	public ngOnInit() {
		this.formControl.setValue(this.value);
		this.inputId = uuidv4();
		this.setColor();
	}

	public ngOnChanges(changes: SimpleChanges) {
		const change = changes.value;
		if (!change.isFirstChange()) {
			this.formControl.setValue(change.currentValue);
			this.setColor();
		}
	}

	public toggle() {
		this.setColor();
		this.switched.emit(this.formControl.value);
	}

	private setColor() {
		const color = this.formControl?.value ? this.params.checkedColor : this.params.uncheckedColor;
		this.boxShadow = `0 0 0 2px ${color}`;
		this.bgColor = color;
	}
}
