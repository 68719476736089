import { pipe } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';

export const dateBoundaryOperator = pipe(
	startWith(null),
	map((date: Date) => {
		if (date == null) {
			return null;
		}
		const momentLike = moment({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() });
		return momentLike.format('YYYY-MM-DD');
	})
);
