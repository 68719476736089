import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TodoDataProviderService } from '@app/core/todo-store/todo-data-provider.service';
import { fadeInOut, fadeUpDown, heightGrow } from '@app/shared/animations/animations';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { EntityId } from '@app/shared/model/types/entity-id';
import { TodoActivityLogConfig } from '@app/todo/model/todo-activity-log-config';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, filter, map, merge, Observable, switchMap, tap } from 'rxjs';

@Component({
	selector: 'todo-card-activity-log',
	templateUrl: './todo-card-activity-log.html',
	animations: [fadeUpDown, heightGrow, fadeInOut],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoCardActivityLogComponent implements OnInit {
	@Input() set todoId(id: EntityId) {
		this.todoIdEmitter.next(id);
	}

	get todoId() {
		return this.todoIdEmitter.value;
	}

	@Input() showHeader = true;

	readonly faIcons = {
		chevronDown: faChevronDown,
	};

	todoLogs$: Observable<TodoActivityLogConfig[]>;

	isLoading = false;

	expandActivityLog = false;

	private todoIdEmitter = new BehaviorSubject<EntityId>(null);

	constructor(
		private todoProvider: TodoDataProviderService,
		private apiLink: GaApiLink
	) {
	}

	public ngOnInit(): void {
		const todoUpdates$ = this.todoIdEmitter.asObservable()
			.pipe(
				switchMap(id => this.todoProvider.getUpdates(id)),
				map(todo => todo.id)
			)
		this.todoLogs$ = merge(this.todoIdEmitter.asObservable(), todoUpdates$)
			.pipe(
				filter((todoId) => todoId && this.expandActivityLog),
				tap(() => this.isLoading = true),
				switchMap(todoId => this.apiLink.retrieve(
					'todoLogEntry',
					['id', 'html', 'createdAt', 'oldValue', 'newValue'],
					{ todo: { id: todoId } })
				),
				tap(() => this.isLoading = false)
			);
	}

	public toggleExpandActivityLog(): void {
		this.expandActivityLog = !this.expandActivityLog;
		this.todoIdEmitter.next(this.todoId);
	}
}
