import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { selectSidebarTodos } from '@app/core/todo-store/todo.selectors';
import { TodoStatus } from '@app/shared/model/constants/todo-status';
import { Todo } from '@app/shared/model/types/todo';
import { TodoSidebarService } from '@app/todo/todo-sidebar/service/todo-sidebar.service';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

@Component({
	selector: 'todo-notification-dot',
	templateUrl: 'todo-notification-dot.component.html',
	styleUrls: ['todo-notification-dot.component.scss'],
	imports: [
		NgIf,
		LetDirective,
		NgClass,
		AsyncPipe
	],
	standalone: true
})
export class TodoNotificationDotComponent implements OnInit {
	showNotification$: Observable<boolean>;
	toDosCount$: Observable<number>;

	constructor(
		private todoService: TodoSidebarService,
		private authService: AuthService,
		private store: Store<{ todos: Todo[] }>,
	) {
	}

	public ngOnInit(): void {
		this.todoService.initDotNotification();
		this.showNotification$ = this.todoService.showDotNotification$;

		const currentUserId = this.authService.getCurrentUser().id;
		this.toDosCount$ = this.store.select(selectSidebarTodos(currentUserId))
			.pipe(
				map(todos => todos
					.filter(todo => todo.status === TodoStatus.Todo && todo.assignees.some(assignee => assignee.id == currentUserId))
					.length
				)
			);
	}
}
