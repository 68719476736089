import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AppHeaderModule } from '@app/app-layout/app-header/app-header.module';
import { AppNavigationComponent } from '@app/app-layout/app-navigation/app-navigation.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { PermissionsStoreService } from '@app/core/security/permissions/permissions-store.service';
import { AppInitializer } from '@app/core/startup/app-initializer.service';
import * as todoStore from '@app/core/todo-store/todo.reducer';
import { FeatureEnabledDirective } from '@app/core/unleash/feature-enabled.directive';
import { DashboardModule } from '@app/dashboard/dashboard.module';
import { dbConfig } from '@app/database/db.config';
import { FundingsModule } from '@app/fundings/fundings.module';
import { GaGridModule } from '@app/ga-grid/ga-grid.module';
import { PublicModule } from '@app/public/public.module';
import { SalesModule } from '@app/sales/sales.module';
import { CptCodesModule } from '@app/settings/cpt-codes/cpt-codes.module';
import { AvatarService } from '@app/shared/avatar/avatar.service';
import { SharedModule } from '@app/shared/shared.module';
import { SmsModule } from '@app/sms/sms.module';
import { MedicalMarkersMapModule } from '@app/widgets/medical-markers-map/medical-markers-map.module';
import { environment } from '@env/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Loader } from '@googlemaps/js-api-loader';
import { LetModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular-ivy';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
	declarations: [
		AppComponent,
		AppNavigationComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CoreModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		AccordionModule.forRoot(),
		BsDatepickerModule.forRoot(),
		BsDropdownModule.forRoot(),
		ButtonsModule.forRoot(),
		ModalModule.forRoot(),
		TabsModule.forRoot(),
		TimepickerModule.forRoot(),
		TooltipModule.forRoot(),
		PopoverModule.forRoot(),
		DashboardModule,
		SmsModule,
		SharedModule,
		CptCodesModule,
		PublicModule,
		SalesModule,
		FundingsModule,
		MarkdownModule.forRoot(),
		GaGridModule,
		StoreModule.forRoot({}),
		StoreModule.forFeature(todoStore.TODO_FEATURE_KEY, todoStore.todoReducer),
		EffectsModule.forRoot(),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production, // Restrict extension to log-only mode
		}),
		AppHeaderModule,
		FontAwesomeModule,
		// Always keep routing module last in list
		AppRoutingModule,
		MedicalMarkersMapModule,
		FeatureEnabledDirective,
		NgxIndexedDBModule.forRoot(dbConfig),
		LetModule
	],
	providers: [
		PermissionsStoreService,
		AvatarService,
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			useFactory: () => () => {
			},
			deps: [Sentry.TraceService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (init: AppInitializer) => () => init.bootstrap(),
			deps: [AppInitializer],
			multi: true
		},
		{
			provide: Loader,
			useValue: new Loader({
				apiKey: environment.googleApiToken,
				libraries: ['places']
			})
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
