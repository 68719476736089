<div (click)="selectConversation(conversation)"
	 [class.unread]="conversation.unreadMessagesCount > 0"
	 [class.selected]="conversation.isSelected"
	 class="inbox-conversation-list-item">
	<div *ngIf="conversation.unreadMessagesCount > 0" class="inbox-conversation-list-item__icon">
		<svg-icon [icon]="svgIconNames.Envelope" class="base-icon primary-color"></svg-icon>
	</div>
	<div *ngIf="conversation.unreadMessagesCount == 0" class="inbox-conversation-list-item__icon">
		<svg-icon [icon]="svgIconNames.OpenEnvelope" class="base-icon grey-color"></svg-icon>
	</div>
	<div class="inbox-conversation-list-item__wrapper">
		<div class="inbox-conversation-list-item__name">
			{{ conversation.firstName }} {{ conversation.lastName }}
			<span>{{ conversation.internalId }}</span>
			<span style="color: #c3c3d2; margin-left: 5px;">:{{ conversation.totalAmountPurchased|currency:'USD':'symbol':'1.2-2' }}</span>
		</div>
		<div class="inbox-conversation-list-item__message">
			{{ lastMessage.body|truncate:0:65:'...' }}
			<div *ngIf="isDirectionOutbound(lastMessage) && showSender"><i>Send by <ng-container *ngIf="lastMessage.sendBy; else defaultSender">{{ lastMessage.sendBy.firstName }} {{ lastMessage.sendBy.lastName }}</ng-container></i></div>
			<ng-template #defaultSender>
				{{ lastMessage.fromNumber }}
			</ng-template>
		</div>
	</div>
	<div *ngIf="conversation.unreadMessagesCount > 0" class="inbox-conversation-list-item__unread-count">{{ conversation.unreadMessagesCount }}</div>
</div>
