<div class="ibox flex-col fundings-view__wrapper">
	<div class="ibox-title flex-thin fundings-view__header">
		<h2 class="fundings-view__header-heading">Fundings</h2>
		<button class="base-button base-button--square fundings-view__header-clear-preferences-btn" *ngIf="grid.showClearButton" (click)="grid.clearSavedSettings()" tooltip="Clear table preferences">
			<svg-icon [icon]="svgIconNames.Preferences" class="base-icon base-icon--small"></svg-icon>
		</button>
		<div (clickOutside)="hideFundingTypesList()" class="fundings-view__header__funding-type">
			<input class="base-input" (click)="toggleFundingTypesList()" placeholder="Fundings filter" [value]="selectedFundingType?.name" readonly>
			<select-list
					[items]="fundingTypesFilterOptions"
					[show]="showFundingTypesList"
					[selected]="selectedFundingType"
					(onItemSelect)="onFundingTypeSelect($event)">
			</select-list>
		</div>
		<div (clickOutside)="hideArchivedFilterList()" class="fundings-view__header__archieved-filter-list">
			<input class="base-input" (click)="toggleArchivedFilterList()" placeholder="Archived filter" [value]="selectedArchivedFilter?.name" readonly>
			<select-list
					[items]="archivedFilterOptions"
					[show]="showArchivedFilterList"
					[selected]="selectedArchivedFilter"
					(onItemSelect)="onArchivedFilterSelect($event)">
			</select-list>
		</div>
		<input type="text"
		       placeholder="Select date range"
		       class="base-input fundings-view__header-date-picker"
		       bsDaterangepicker
		       [bsConfig]="{ ranges: dateFilterRanges, isAnimated: true, maxDate: maxDate, containerClass: 'custom-datepicker-theme', customTodayClass: 'custom-today-class' }"
		       [bsValue]="dateRange"
		       (bsValueChange)="onDateRangeSelect($event)">
		<div (clickOutside)="hideDateFilterList()" class="fundings-view__header__date-filter-list">
			<button class="base-button fundings-view__header__date-filter-list-toggle-date-filter-btn" (click)="toggleDateFilterList()">{{ selectedDateFilter.name }}</button>
			<select-list
					[items]="dateFilterOptions"
					align="right"
					[style]="{'right': '-55px'}"
					[show]="showDateFilterList"
					[selected]="selectedDateFilter"
					(onItemSelect)="onDateFilterClick($event)">
			</select-list>
		</div>
		<export-reports-btn *aclIsAllowed="'reports.download_as_excel'"></export-reports-btn>
	</div>
	<div class="flex">
		<fundings-table-grid class="fundings-view__fundings-table-grid" #grid [dateRange]="dateRangeFormatted" [dateFilter]="selectedDateFilter" [fundingFilter]="selectedFundingType" [archivedFilter]="selectedArchivedFilter"></fundings-table-grid>
	</div>
</div>
