import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { WebSocketMessageType } from '@app/core/web-socket/web-socket-message-type';
import { WebSocketService } from '@app/core/web-socket/web-socket.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class SmsBadgeStoreService {

	private unreadSmsCount$ = new BehaviorSubject(0);

	constructor(
		private http: HttpClient,
		private websocketService: WebSocketService,
		private authService: AuthService) {

		const count: number = parseInt(localStorage.getItem('unreadSmsCount'));
		if (!count) {
			this.getSmsMessagesCount().subscribe((fetchedCount: number) => {
				this.broadcast(fetchedCount);
			});
		} else {
			this.unreadSmsCount$.next(count);
		}

		this.websocketService
			.getConnection()
			.pipe(filter((message) => {
					return message.type == WebSocketMessageType.SMSMessageReceived;
				})
			)
			.subscribe(() => {
				const updatedCount: number = this.unreadSmsCount$.getValue() + 1;
				this.broadcast(updatedCount);
			});

		this.websocketService
			.getConnection()
			.pipe(
				filter((message) => {
					return message.type == WebSocketMessageType.SMSMessagesMarkedAsRead &&
						message.data.userId == this.authService.getCurrentUser().id;
				})
			)
			.subscribe((message) => {
				const newCount: number = parseInt(message.data.count);
				this.broadcast(newCount);
			});
	}

	public getUnreadSmsCount() {
		return this.unreadSmsCount$.asObservable();
	}

	private getSmsMessagesCount() {
		return this.http.get('/api/sms/sms-unread-count');
	}

	private broadcast(count: number) {
		this.unreadSmsCount$.next(count);
		this.updateLocalStorage(count);
	}

	private updateLocalStorage(count: number) {
		localStorage.setItem('unreadSmsCount', count.toString());
	}
}
