import { Persistable } from '@app/core/data-watch/utils';
import { FilterVisitor } from '@app/shared/filter-visitors/models/filter-visitor';

export class PricingFilterVisitor<T extends Persistable> extends FilterVisitor<number> {
	constructor(private path: string) {
		super(path);
	}

	public filter(filterObject: T): boolean {
		if (!filterObject[this.path]) {
			return false;
		}
		return filterObject[this.path] == this.value;
	}

	public isActive(): boolean {
		return this.value != null && this.value != 0;
	}
}
