export enum StaticPoolReportOptions {
	// tabs
	Curves = 'Curves',
	ProcedureAnalysis = 'ProcedureAnalysis',
	DoctorAnalysis = 'DoctorAnalysis',
	FacilityAnalysis = 'FacilityAnalysis',
	PeriodPayoffAnalysis = 'PeriodPayoffAnalysis',
	QuaterlyPerformance = 'QuaterlyPerformance',
	MonthlyPerformance = 'MonthlyPerformance',
	VintageTables = 'VintageTables',
	OutstandingByCaseType = 'OutstandingByCaseType',
	HarvestedByCaseType = 'HarvestedByCaseType',
	HarvestedByDealStatus = 'HarvestedByDealStatus',
	ReasonForShortPay = 'ReasonForShortPay',
	Dictionary = 'Dictionary',
	// columns
	AttorneysAndLawFirms = 'AttorneysAndLawFirms',
	Facility = 'Facility',
	ClaimantName = 'ClaimantName',
	EffectiveRate = 'EffectiveRate',
	// filters
	All = 'all',
	PreSettlement = 'pre-settlement',
	MedicalFactoring = 'medical-factoring',
}
