import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoctorAddressComponent } from '@app/widgets/doctor-address/doctor-address.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		DoctorAddressComponent
	],
	exports: [
		DoctorAddressComponent
	]
})
export class DoctorAddressModule {}
