<ng-container *ngrxLet="timezones$; let timezones">
	<div>
		<ng-select class="base-input"
				   placeholder="Timezone..."
				   data-cy="todoDueDateTimezone-input"
				   [ngClass]="{'disabled': disabled}"
				   [items]="timezones"
				   [groupBy]="'countryCode'"
				   [searchable]="true"
				   [searchFn]="searchingHelper.searchCodelist"
				   bindLabel="title"
				   [formControl]="timezoneControl"
				   [attr.disabled]="disabled">
		</ng-select>
	</div>
	<div class="quick-select-wrapper" [ngClass]="{'disabled': disabled}">
		<span (click)="setLaTimezone(timezones)">Use LA timezone</span>
		<span (click)="setLocalTimezone(timezones)">Use local timezone</span>
	</div>
</ng-container>
