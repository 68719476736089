import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'boolean-select',
	templateUrl: './boolean-select.component.html'
})
export class BooleanSelectComponent implements OnInit {
	@Input() inputControl: FormControl<boolean>;
	@Input() label: string
	uniqueId: string;
	readonly booleanSelectOptions = [
		{ title: '-', value: null },
		{ title: 'Yes', value: true },
		{ title: 'No', value: false },
	];

	public ngOnInit(): void {
		this.uniqueId = uuidv4();
	}
}
