import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CollapsibleItemComponent } from '@app/dashboard/collapsible-item/collapsible-item.component';
import { HistoricalPerformanceTableComponent } from '@app/dashboard/historical-performance/historical-performance-table/historical-performance-table.component';
import { HistoricalPerformanceComponent } from '@app/dashboard/historical-performance/historical-performance.component';
import { DashboardWidgetDateFilterModule } from '@app/dashboard/shared/dashboard-widget-date-filter/dashboard-widget-date-filter.module';
import { GhostElementModule } from '@app/widgets/ghost-element/ghost-element.module';
import { LetDirective } from '@ngrx/component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
	imports: [
		CommonModule,
		LetDirective,
		GhostElementModule,
		ReactiveFormsModule,
		DashboardWidgetDateFilterModule,
		BsDatepickerModule,
		CollapsibleItemComponent,
	],
	declarations: [
		HistoricalPerformanceComponent,
		HistoricalPerformanceTableComponent,
	],
	exports: [
		HistoricalPerformanceComponent
	]
})
export class HistoricalPerformanceModule {}
