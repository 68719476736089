import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeHelperService } from '@app/shared/model/date-time-helper.service';

@Pipe({ name: 'timezone' })
export class TimezonePipe implements PipeTransform {

	constructor(private dateTimeHelper: DateTimeHelperService) {
	}

	public transform(dateTime: string, timezone: string): string | null {
		return dateTime ? this.dateTimeHelper.getDateTimeBasedOnTimezone(dateTime, timezone) : null;
	}
}
