<div class="whiteboard-card__labels" *ngrxLet="todoLabels$; let todoLabels">
	<div class="whiteboard-card__labels__wrapper padding" *ngrxLet="showTodoLabelPicker$ as showTodoLabelPicker">
		<ng-container *ngIf="!showTodoLabelPicker">
			<fa-icon [icon]="faIcons.tag"
					 class="tag-icon"
					 size="2x"
			></fa-icon>
			<entity-label-badge *ngFor="let label of todoLabels" data-cy="todo-label"
			                    [label]="label"
			                    (onRemove)="removeLabel($event)"
			></entity-label-badge>
			<button *ngIf="todoLabels.length === 0" (throttleClick)="triggerTodoLabelPicker()" class="base-button base-button--small base-button--cancel">Add labels</button>
			<fa-icon *ngIf="todoLabels.length > 0" [icon]="faIcons.plusCircle" class="add-label cursor-pointer"
					 size="xs" data-cy="addTodoLabel-btn" (throttleClick)="triggerTodoLabelPicker()"></fa-icon>
		</ng-container>
		<entity-label-picker
			*ngIf="showTodoLabelPicker"
			[codeListName]="todoLabelCodeList"
			[selectedLabels]="todoLabels$ | async"
			(onSave)="updateLabels($event, false)"
			(onCancel)="hideTodoLabelPicker()"
			(click)="$event.stopPropagation()">
		</entity-label-picker>
	</div>
</div>
