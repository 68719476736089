import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from '@app/core/auth/token-storage.service';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

	constructor(private credentialsStorage: TokenStorageService) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (req.headers.has('InterceptorSkipHeader')) {
			const headers = req.headers.delete('InterceptorSkipHeader');
			return next.handle(req.clone({ headers }));
		}
		// Get the auth token from the service.
		const authToken = this.credentialsStorage.getAuthToken();

		// Clone the request and replace the original headers with
		// cloned headers, updated with the authorization.
		const authReq = req.clone({
			headers: req.headers.set('Authorization', `Bearer ${authToken}`)
		});

		// send cloned request with header to the next handler.
		return next.handle(authReq);
	}
}
