import { formatNumber, formatPercent } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { dateBoundaryOperator } from '@app/dashboard/shared/date-boundary-operator';
import { addPercentage, getSumByAllProperties, Percentage } from '@app/dashboard/top-five-section/shared/top-five-section.utils';
import { CartesianSavedChart } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/cartesian-saved-chart';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { CartesianGroupedTooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/cartesian-grouped-tooltip-theme.service';
import { chartNumberAxesTheme } from '@app/shared/ag-grid-utils/chart-themes/chart-axes-theme';
import { currencyColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/currency-column-definition';
import { numberColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/number-column-definition';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { ChartRef, ColDef, ColGroupDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { combineLatest, filter, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

type PipelineStat = {
	title: string,
	numberOfDeals: number,
	requestedAmount: number
}
type PipelineBreakdownData = Required<PipelineStat & Percentage<PipelineStat>>;

@Component({
	selector: 'pipeline-breakdown',
	templateUrl: './pipeline-breakdown.component.html',
	styleUrls: ['pipeline-breakdown.component.scss'],
})
export class PipelineBreakdownComponent implements OnInit, OnDestroy {

	rowData$: Observable<PipelineBreakdownData[]>;
	colDef: (ColDef | ColGroupDef)[];
	gridOptions: GridOptions;
	dateFilterControl = new FormControl<Date>(null);
	hasSavedChart$ = new ReplaySubject<boolean>(1);

	readonly clearIcon = SvgIconName.Preferences;
	protected gridApi: GridApi;

	private tooltipTheme = new CartesianGroupedTooltipThemeService();
	private chartSaveDirective = new CartesianSavedChart(
		'dashboard-pipeline-chart',
		() => chartNumberAxesTheme('Pipeline Chart'),
		this.tooltipTheme
	);
	private chartRef: ChartRef;
	private destroy$ = new Subject<void>();

	constructor(
		private agGridUtils: AgGridUtilsService,
		private http: HttpClient,
		private dashboardFilterStore: DashboardFilterStore,
	) {
	}

	public ngOnInit(): void {
		this.gridOptions = this.agGridUtils.getGridOptions({
			sideBar: false,
			defaultExcelExportParams: {
				fileName: 'Pipeline Dashboard Export'
			},
			getRowHeight: (params) =>
				params.node.detail ? (params.data.medicalBreakdown.length * 28) + 110 : undefined,
			domLayout: 'autoHeight',
			context: { componentParent: this },
			detailCellRendererParams: {
				detailGridOptions: {
					statusBar: true,
					defaultColDef: {
						floatingFilter: true,
						sortable: true,
						resizable: true,
					},
					onFirstDataRendered: (params) => params.api.sizeColumnsToFit(),
					domLayout: 'autoHeight',
					columnDefs: [
						{
							field: 'title',
						},
						{
							headerName: 'Count',
							...numberColumnDefinition('dealsCount'),
						},
						{
							headerName: 'Amount',
							...currencyColumnDefinition('dealsAmount'),
							valueFormatter: params => {
								return params.value != undefined
									? `$${formatNumber(params.value, 'en-US', '1.0-0')}`
									: '';
							},
						},
					],
				},
				getDetailRowData: (params) => params.successCallback(params.data.medicalBreakdown),
			}
		});
		this.rowData$ = combineLatest([
			this.dashboardFilterStore.fundingParams$,
			this.dateFilterControl.valueChanges.pipe(
				filter(() => this.dateFilterControl.valid),
				dateBoundaryOperator
			),
		]).pipe(
			tap(() => this.gridApi?.showLoadingOverlay()),
			switchMap(([params, dateFilter]) => {
				if (dateFilter != null) {
					params = params.set('dateBoundary', dateFilter);
				}
				return this.http.get('/api/dashboard/pipeline', { params });
			}),
			getSumByAllProperties<PipelineStat>(),
			addPercentage<PipelineStat>(),
			shareReplay({ bufferSize: 1, refCount: true }),
		);
		this.rowData$
			.pipe(
				map(data => data.map(datum => ({ ...datum, chartLabel: datum.title }))),
				takeUntil(this.destroy$)
			).subscribe(data => {
			this.tooltipTheme.setTooltipData(data);
		});
		this.colDef = [
			{
				field: 'title',
				chartDataType: 'category',
				valueGetter: ({ data }) => data.title,
				valueFormatter: ({ value }) => value,
				cellRenderer: 'agGroupCellRenderer',
				minWidth: 125,
			},
			{
				headerName: 'Requested Amount',
				children: [
					{
						...this.tooltipTheme.addPercentageColumn(
							'Percentage',
							'requestedAmountPercentage'
						),
						valueFormatter: ({ value }) => formatPercent(value, 'en-US', '1.0-0'),
					},
					{
						...this.tooltipTheme.addPriceColumn(
							'Requested Amount [$]',
							'requestedAmount'
						),
						headerValueGetter: ({ location }) => {
							if (location === 'chart') {
								return 'Requested Amount [$]';
							}
							return 'Amount';
						},
						valueFormatter: params => {
							return params.value != undefined
								? `$${formatNumber(params.value, 'en-US', '1.0-0')}`
								: '';
						},
					},
					{
						headerName: 'Plaintiff',
						...currencyColumnDefinition('plaintiffAmount'),
						valueFormatter: params => {
							return params.value != undefined
								? `$${formatNumber(params.value, 'en-US', '1.0-0')}`
								: '';
						},
					},
					{
						headerName: 'Case Cost',
						...currencyColumnDefinition('caseCostAmount'),
						valueFormatter: params => {
							return params.value != undefined
								? `$${formatNumber(params.value, 'en-US', '1.0-0')}`
								: '';
						},
					},
					{
						headerName: 'Medical',
						...currencyColumnDefinition('medicalAmount'),
						valueFormatter: params => {
							return params.value != undefined
								? `$${formatNumber(params.value, 'en-US', '1.0-0')}`
								: '';
						},
					}
				]
			},
			{
				headerName: 'Number Of Deals',
				children: [
					{
						...this.tooltipTheme.addPercentageColumn(
							'Percentage',
							'numberOfDealsPercentage'
						),
						valueFormatter: ({ value }) => formatPercent(value, 'en-US', '1.0-0'),
					},
					{
						...this.tooltipTheme.addNumberColumn(
							'Number Of Deals',
							'numberOfDeals'
						),
						headerValueGetter: ({ location }) => {
							if (location === 'chart') {
								return 'Number Of Deals';
							}
							return '# of Deals';
						},
					},
					{
						headerName: 'Plaintiff',
						...numberColumnDefinition('plaintiffCount')
					},
					{
						headerName: 'Case Cost',
						...numberColumnDefinition('caseCostCount')
					},
					{
						headerName: 'Medical',
						...numberColumnDefinition('medicalCount')
					}
				]
			},
		];
	}

	public onGridReady(params: GridReadyEvent): void {
		this.gridApi = params.api;
	}

	public onFirstDataRendered(params: FirstDataRenderedEvent): void {
		params.api.sizeColumnsToFit();
		params.api.forEachNode(node => node.setExpanded(true));
		const savedChart = this.getSavedChart();
		if (savedChart !== null) {
			this.chartRef = savedChart;
			this.tooltipTheme.setChart(this.chartRef.chart);
			return;
		}
		this.createDefaultChart();
	}

	private createDefaultChart(): void {
		if (this.chartRef != null) {
			this.chartRef.destroyChart();
		}
		const groupingConfig = this.tooltipTheme.getGroupedTooltipTheme(['requestedAmount', 'numberOfDeals']);
		this.chartRef = this.gridApi.createRangeChart({
			chartType: 'customCombo',
			unlinkChart: true,
			cellRange: {
				columns: ['title', 'numberOfDeals', 'requestedAmount']
			},
			seriesChartTypes: [
				{ colId: 'requestedAmount', chartType: 'groupedColumn' },
				{ colId: 'numberOfDeals', chartType: 'line', secondaryAxis: true }
			],
			chartContainer: this.getChartElement(),
			chartThemeName: 'ag-pastel',
			chartThemeOverrides: {
				...chartNumberAxesTheme('Pipeline Chart'),
				column: {
					series: {
						...groupingConfig,
						fillOpacity: 0.8
					}
				},
				line: {
					series: {
						...groupingConfig,
						strokeOpacity: 0.8,
						strokeWidth: 5
					}
				}
			}
		});
		this.tooltipTheme.setChart(this.chartRef.chart);
	}

	private getSavedChart(): ChartRef | null {
		const chartModel = this.chartSaveDirective.getSavedChart();
		if (chartModel !== null) {
			this.hasSavedChart$.next(true);
			return this.gridApi.restoreChart(chartModel, this.getChartElement());
		}
		return null;
	}

	protected getChartElement(): HTMLElement {
		return document.querySelector('#pipeline-chart');
	}

	public onChartOptionsChanged(): void {
		const modelsToSave = this.gridApi.getChartModels();
		const colIds = modelsToSave[0].cellRange.columns as string[];
		this.chartSaveDirective.setTooltipIds(colIds);
		this.chartSaveDirective.saveChart(modelsToSave);
		this.hasSavedChart$.next(true);
	}

	public clearSavedChartState(): void {
		this.chartSaveDirective.clearSavedChart();
		this.hasSavedChart$.next(false);
		this.createDefaultChart();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.chartRef?.destroyChart();
	}
}
