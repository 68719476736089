<label class="toggle-switch" [ngClass]="{'toggle-switch__control--on': formControl.value}"
	   [style.box-shadow]="boxShadow" [style.background-color]="bgColor" for="toggle-switch-{{ inputId }}">
	<input [formControl]="formControl" (change)="toggle()" class="toggle-switch__input" id="toggle-switch-{{ inputId }}"
		   type="checkbox">
	<div class="toggle-switch__control">
		<div class="toggle-switch__control__checked"
			 [style.color]="params.checkedTextColor">{{ params.checkedText }}</div>
		<div class="toggle-switch__control__slider" [style.background-color]="params.sliderColor"></div>
		<div class="toggle-switch__control__unchecked"
			 [style.color]="params.uncheckedTextColor">{{ params.uncheckedText }}</div>
	</div>
</label>
