import { FilterVisitor } from '@app/shared/filter-visitors/models/filter-visitor';
import { Doctor } from '@app/shared/model/types/doctor';

export class DoctorAddressPropertyVisitor extends FilterVisitor<string> {
	constructor(private path: string) {
		super(path);
	}

	public filter(doctor: Doctor): boolean {
		return doctor.addresses.some((address) => address[this.path]?.toLowerCase() == this.value.toLowerCase());
	}

	public isActive(): boolean {
		return this.value != null && this.value.length > 0;
	}
}
