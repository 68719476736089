import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { PublicViewEventBusService } from '@app/public/public-view.service';
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
})
export class LoginComponent {

	shake = false;
	title = 'Sign in';
	subtitle = 'Welcome to Rockpoint Legal Web App';

	loginForm = new FormGroup({
		username: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(255)]),
		password: new FormControl('', [Validators.required]),
		oneTimeToken: new FormControl('', [Validators.minLength(6), Validators.maxLength(6)])
	});

	readonly faIcons = {
		exclamationCircle: faExclamationCircle,
		infoCircle: faInfoCircle
	};

	constructor(public authService: AuthService,
				public router: Router,
				public messageService: FlashMessageService,
				public publicEventBus: PublicViewEventBusService
	) {}

	public login(): void {
		this.shake = this.loginForm.invalid;

		if (this.loginForm.valid) {
			this.publicEventBus.toggleLoadingSpinner(true);
			this.authService.login(this.loginForm.getRawValue())
				.subscribe({
					next:() => {
						this.publicEventBus.toggleLoadingSpinner(false);
						this.router.navigate(['/']);
					},
					error: (errorResponse: unknown) => {
						const { error } = errorResponse as { error: { message: string } };
						this.publicEventBus.toggleLoadingSpinner(false);
						this.messageService.showMessage(FlashMessageType.Danger, error.message, { timeout: 10000 });
					}
				})
		}
	}

	get username() {
		return this.loginForm.get('username');
	}

	get password() {
		return this.loginForm.get('password');
	}

	get oneTimeToken() {
		return this.loginForm.get('oneTimeToken');
	}
}
