import { Injectable } from '@angular/core';
import { AttorneyCodelist } from '@app/shared/model/types/codelists/attorney-codelist';
import { CptCodeCodelistItem } from '@app/shared/model/types/cpt-code';
import { DoctorAddress } from '@app/shared/model/types/doctor-address';
import { Funding } from '@app/shared/model/types/funding';
import { UsState } from '@app/shared/model/types/us-state';

@Injectable({ providedIn: 'root' })
export class NgSelectSearchingHelperService {

	searchAttorney = (term: string, item: AttorneyCodelist): boolean => {
		const terms = term.trim().toLowerCase().split(' ');
		let isMatch = true;
		terms.forEach(subTerm => {
			const firstNameMatch = (item.firstName ?? '').trim().toLowerCase().includes(subTerm);
			const lastNameMatch = (item.lastName ?? '').trim().toLowerCase().includes(subTerm);
			const lawFirmMatch = (item.lawFirm?.name ?? '').trim().toLowerCase().includes(subTerm);
			isMatch = firstNameMatch || lastNameMatch || lawFirmMatch;
		})
		return isMatch;
	};

	searchLawFirm = (term: string, item: { name: string }): boolean => {
		const normalizedTerm = this.normalizeTerm(term);
		return this.normalizeTerm(item.name).toLowerCase().indexOf(normalizedTerm.toLowerCase().trim()) > -1;
	}

	searchLawFirmContact = (term: string, item: { firstName: string, lastName: string, email: string }): boolean => {
		const terms = term.trim().toLowerCase().split(' ');
		let isMatch = true;
		terms.forEach(subTerm => {
			const firstNameMatch = (item.firstName ?? '').trim().toLowerCase().includes(subTerm);
			const lastNameMatch = (item.lastName ?? '').trim().toLowerCase().includes(subTerm);
			const emailMatch = (item.email ?? '').trim().toLowerCase().includes(subTerm);
			isMatch = isMatch && (firstNameMatch || lastNameMatch || emailMatch);
		})
		return isMatch;
	};

	searchCodelist = (term: string, item: { title: string }): boolean => {
		return (item.title).toLowerCase().includes(term.toLowerCase().trim());
	};

	searchByName = (term: string, item: { name: string }): boolean => {
		return `${item.name}`.toLowerCase().includes(term.toLowerCase().trim());
	};

	searchByFullName = (term: string, item: { firstName: string, lastName: string }): boolean => {
		term = term.replace(/ /g, '').toLowerCase();
		return `${item.firstName}${item.lastName}`.replace(/ /g, '').toLowerCase().includes(term);
	};

	searchCptCode = (term: string, item: CptCodeCodelistItem): boolean => {
		term = term.split(' ').join('').toLowerCase();
		return `${item.code}${item.title}`.toLowerCase().includes(term);
	};

	searchUsState = (term: string, state: UsState): boolean => {
		term = term.replace('-', '').split(' ').join('').toLowerCase();
		return `${state.code}${state.title}`.toLowerCase().includes(term);
	};

	searchFunding = (term: string, item: Funding): boolean => {
		term = term.split(' ').join('').toLowerCase();
		return `${item.internalId}${item.fundingType.title}`.toLowerCase().includes(term);
	};

	searchDoctorAddress = (term: string, item: DoctorAddress) => {
		const words = term.trim().toLowerCase().split(' ');
		const addressChunks = [item.street, item.city, item.state?.code ?? '', item.zip];
		return addressChunks.some(addressChunk => words.some(word => addressChunk?.toLowerCase()?.includes(word)));
	};

	compareById = (a: { id: string }, b: { id: string }): boolean => {
		return a.id == b.id;
	}

	private normalizeTerm(term: string): string {
		return term.replace(/\s+/g, ' ');
	}
}
