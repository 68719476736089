import { Injectable } from '@angular/core';
import { defaultFilterContext, FilterContext } from '@app/shared/filter-visitors/models/filter-context';
import { FilterVisitor } from '@app/shared/filter-visitors/models/filter-visitor';
import { LocalStorageKeys } from '@app/shared/model/constants/local-storage-keys';
import { BoardTypeModel } from '@app/w-boards/models/board-type.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BoardLocalStorageService {
	globalContext: FilterContext;
	board: BoardTypeModel;
	filters: { value: any, context: FilterContext, id: string }[];
	isFilterExpanded: boolean;
	showTotalAmounts: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	collapsedColumnIds: string [];

	private allBoardFilters;

	public setBoard(board: BoardTypeModel): void {
		this.board = board;
		this.loadFromStorage();
	}

	private loadFromStorage(): void {
		this.allBoardFilters = JSON.parse(localStorage.getItem(LocalStorageKeys.WHITEBOARD_FILTERS)) ?? { [this.board]: this.getDefault() };
		if (this.allBoardFilters[this.board] == undefined) {
			this.allBoardFilters[this.board] = this.getDefault();
		}
		this.globalContext = this.allBoardFilters[this.board].globalContext;
		this.filters = this.allBoardFilters[this.board].filters;
		this.isFilterExpanded = this.allBoardFilters[this.board].isFilterExpanded;
		this.showTotalAmounts.next(this.allBoardFilters[this.board].showTotalAmount);
		this.collapsedColumnIds = this.allBoardFilters[this.board].collapsedColumnIds ?? [];
	}

	private getDefault(): any {
		return { globalContext: defaultFilterContext, filters: [], isFilterExpanded: false, showTotalAmount: false, collapsedColumns: [] };
	}

	private saveToStorage(): void {
		this.allBoardFilters[this.board] = {
			globalContext: this.globalContext,
			filters: this.filters,
			isFilterExpanded: this.isFilterExpanded,
			showTotalAmount: this.showTotalAmounts.value,
			collapsedColumnIds: this.collapsedColumnIds

		};
		localStorage.setItem(LocalStorageKeys.WHITEBOARD_FILTERS, JSON.stringify(this.allBoardFilters));
	}

	public setGlobalContext(globalContext: FilterContext): void {
		this.globalContext = globalContext;
		this.saveToStorage();
	}

	public setIsFilterExpanded(isFilterExpanded: boolean): void {
		this.isFilterExpanded = isFilterExpanded;
		this.saveToStorage();
	}

	public toggleColumnCollapsing(columnId: string, isCollapsed: boolean): void {
		if (isCollapsed && !this.collapsedColumnIds.includes(columnId)) {
			this.collapsedColumnIds.push(columnId);
		} else if (!isCollapsed && this.collapsedColumnIds.includes(columnId)) {
			const index = this.collapsedColumnIds.indexOf(columnId);
			this.collapsedColumnIds.splice(index, 1);
		}
		this.saveToStorage();
	}

	public isColumnCollapsed(columnId: string): boolean {
		return this.collapsedColumnIds.includes(columnId);
	}

	public toggleTotalAmounts(): void {
		this.setShowTotalAmounts(!this.showTotalAmounts.value);
	}

	private setShowTotalAmounts(showTotalAmounts: boolean) {
		this.showTotalAmounts.next(showTotalAmounts);
		this.saveToStorage();
	}

	public saveFilters(filters: FilterVisitor[]): void {
		this.filters = filters.map(filter => filter.getAsObj());
		this.saveToStorage();
	}

	public getSavedFilter(filterId: string): { value: any, context: FilterContext, id: string } {
		const savedFilter = this.filters.find(filter => filter.id == filterId);
		return savedFilter ?? null;
	}
}
