import { Pipe, PipeTransform } from '@angular/core';

/*
 * Highlight search terms.
 *
 * Example:
 * 'ram ock' will highlight both occurrences of terms in Ramin - Rockpoint Legal Funding
 *
 * Usage:
 *   input | highlightSearch:searchTerm
*/
@Pipe({ name: 'highlightSearch' })
export class HighlightPipe implements PipeTransform {

	public transform(input, searchTerm): string {
		searchTerm.split(' ').forEach(el => {
			input = el != '' ? this.findTerms(input, el) : input
		})
		return this.highlightTerms(input);
	}

	private findTerms(input, term): string {
		const escapedTerm = this.escapeSpecialChars(term)
		return ('' + input).replace(new RegExp(escapedTerm, 'gi'), '[$&]');
	}

	private escapeSpecialChars(term: string): string {
		return term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	}

	private highlightTerms(input): string {
		input = ('' + input).replace(new RegExp('\\[', 'g'), '<span class="highlightedText">');
		input = ('' + input).replace(new RegExp('\\]', 'g'), '</span>');

		return input;
	}
}
