<ng-select [items]="codelistItems$ | async"
           [searchable]="true"
           [searchFn]="searchingHelper.searchCodelist"
           [multiple]="multiple"
           [virtualScroll]="true"
           [formControl]="inputControl"
           [appendTo]="appendTo"
           [closeOnSelect]="closeOnSelect"
           [clearSearchOnAdd]="clearSearchOnAdd"
           [inputAttrs]="{'data-cy': dataCy + '-input'}"
           [attr.data-cy]="dataCy + '-select'"
           [compareWith]="compareWith"
           [placeholder]="placeholder"
           bindValue="id"
           class="base-input">
	<ng-template ng-label-tmp let-item="item" let-clear="clear">
		<span *ngIf="multiple" class="ng-value-icon left" (click)="clear(item);" style="margin-right: 5px;" aria-hidden="true">×</span>
		<span [ngStyle]="{paddingRight: multiple ? '5px': '0'}">{{ item.title }}</span>
	</ng-template>
	<ng-template ng-option-tmp let-item="item">{{ item.title }}</ng-template>
</ng-select>
