import { CustomPeriod } from '@app/dashboard/shared/dashboard-shared.model';

export enum Resolution {
	Monthly = 'monthly',
	Quarterly = 'quarterly',
	Annually = 'annually'
}

export enum Period {
	Last12Months,
	Lifetime,
	Custom
}

export type ConfigurationChangedEvent = Pick<CustomPeriod, 'from' | 'to'> & { resolution: Resolution };
