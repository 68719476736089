import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { SmsConversation } from '@app/sms/sms-conversation';
import { SmsDirection } from '@app/sms/sms-direction';

@Component({
	selector: 'inbox-conversation-list-item',
	templateUrl: './inbox-conversation-list-item.component.html',
	styleUrls: ['./inbox-conversation-list-item.component.scss'],
})
export class InboxConversationListItemComponent implements OnInit {
	@Input() conversation: SmsConversation;
	@Output() onSelectConversation = new EventEmitter<SmsConversation>();
	@Input() showSender = false;
	lastMessage;

	readonly svgIconNames = SvgIconName;

	constructor(public router: Router) {}

	public ngOnInit(): void {
		this.lastMessage = this.conversation.smsMessages[this.conversation.smsMessages.length - 1];
	}

	public selectConversation(conversation: SmsConversation) {
		this.router.navigate(['/sms-inbox', { 'conversation': conversation.id }]).then(() => {
			this.onSelectConversation.emit(conversation);
		});
	}

	public isDirectionOutbound(sms: any) {
		return sms.direction === SmsDirection.Outbound;
	}
}
