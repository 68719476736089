<div class="base-card">
	<div class="base-card__header">
		<div class="base-card__header__title">Doctor</div>
	</div>
	<div class="base-card__content">
		<div class="row col-12">
			<div class="col-3 base-input__wrapper">
				<codelist-multiselect data-cy="doctorSpecialtyMultiselect" [selectControl]="formControls.specialization"
									  label="Specialization" [codelistClass]="'doctorSpecialization'"
									  [extraOptions]="othersOption"></codelist-multiselect>
			</div>
			<div class="col-3">
				<boolean-select label="Active" [inputControl]="formControls.active"></boolean-select>
			</div>
			<div class="col-3">
				<boolean-select label="Pursuing" [inputControl]="formControls.pursuing"></boolean-select>
			</div>
			<div class="col-3">
				<boolean-select label="Preferred Doctor" [inputControl]="formControls.preferredDoctor"></boolean-select>
			</div>
		</div>
		<div class="row col-12">
			<div class="col-3">
				<boolean-select label="Selling technical liens"
								[inputControl]="formControls.sellingTechnicalLiens"></boolean-select>
			</div>
			<div class="col-3">
				<boolean-select label="Spanish" [inputControl]="formControls.spanish"></boolean-select>
			</div>
			<div class="col-3">
				<boolean-select label="Telemed" [inputControl]="formControls.telemed"></boolean-select>
			</div>
			<div class="col-3 base-input__wrapper">
				<codelist-multiselect data-cy="doctorQuarterbackMultiselect" [selectControl]="formControls.quarterback"
									  label="Quarterback?" [codelistClass]="'quarterback'"></codelist-multiselect>
			</div>
		</div>
		<div class="row col-12">
			<div class="col-3 base-input__wrapper">
				<google-places-autocomplete (onAddressSelected)="setLocation($event)"></google-places-autocomplete>
			</div>
			<div class="col-3 base-input__wrapper">
				<label class="base-label" for="city">City</label>
				<input class="base-input" id="city" [formControl]="formControls.city"/>
			</div>
			<div class="col-3 base-input__wrapper">
				<label class="base-label" for="zip">Zip</label>
				<input class="base-input" id="zip" [formControl]="formControls.zip"/>
			</div>
			<div class="col-3 base-input__wrapper">
				<codelist-multiselect [codelistClass]="doctorTier" label="Tier" [selectControl]="formControls.tier"></codelist-multiselect>
			</div>
		</div>
		<div class="row col-12">
			<div class="col-3 base-input__wrapper">
				<codelist-multiselect [codelistClass]="doctorLocationTier" label="Location tier" [selectControl]="formControls.doctorLocationTier"></codelist-multiselect>
			</div>
			<div class="col-3">
				<rp-cost-editor (rpCostChanged)="onRpCostChanged($event)" data-cy="doctorRpCost"></rp-cost-editor>
			</div>
		</div>
	</div>
</div>









