import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AclService } from '@app/core/security/acl.service';
import { GaEditorComponent } from '@app/shared/ga-components/components/basic/ga-editor.component';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { MetadataProviderService } from '@app/shared/ga-components/services/metadata-provider.service';
import { ComponentMetadata } from '@app/shared/ga-components/utils/component-metadata';
import { FieldMetadata } from '@app/shared/ga-components/utils/field-metadata';
import { MetadataDecorator } from '@app/shared/ga-components/utils/metadata-decorator';
import { ValidationHelper } from '@app/shared/ga-components/utils/validation-helper';
import { Observable, Subject } from 'rxjs';
@Component({
	selector: 'ga-form-field',
	templateUrl: './ga-form-field.component.html',
	styleUrls: ['./ga-form-field.component.scss'],
})
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-output-rename */
export class GaFormFieldComponent implements AfterViewInit, OnInit {
	@Input('metadata') metadataOverride: MetadataDecorator;
	@Input('field') fieldPath: string;
	@Input('customInputClass') customInputClass: string;
	@Input() warningsProcessor = [];
	@Input() showLabel = true;

	@Output('onFocus') focusEvent$ = new EventEmitter<boolean>();
	@Output('onValueChange') onChange = new EventEmitter();
	@Output('onVisible') onVisible = new EventEmitter();

	@HostBinding('class.focus') isFocused = false;
	@HostBinding('class.invalid') isInvalid = false;
	@HostBinding('class.pending') isPending = false;

	@ViewChild(GaEditorComponent) private gaEditor: GaEditorComponent;

	componentMetadata: ComponentMetadata;
	fieldMetadata: FieldMetadata;
	formControlId: string;
	customErrors: string[] = [];
	warning: string;
	lastCheckedTerm: string;
	private _validationErrors$ = new Subject<object>();

	constructor(
		public entityScope: EntityScopeService,
		public metadataProvider: MetadataProviderService,
		public elementRef: ElementRef,
		public renderer: Renderer2,
		public acl: AclService,
		public domSanitizer: DomSanitizer,
		public validationHelper: ValidationHelper
	) {
	}

	public ngOnInit(): void {
		this.componentMetadata =
			this.metadataProvider.getComponentMetadata(this.entityScope.entityClass, this.fieldPath, this.metadataOverride);
		this.fieldMetadata = this.metadataProvider.getFieldMetadata(this.entityScope.entityClass, this.fieldPath);
		const el = this.elementRef.nativeElement;
		const editor = this.componentMetadata.editor;
		this.renderer.addClass(el, new editor().cssClass); //dont lke it but I have found no better way
		this.formControlId = this.fieldPath;
		this.lastCheckedTerm = this.componentMetadata.valueGetter(this.entityScope.entity);
	}
	public ngAfterViewInit() {
		this.onVisible.emit(this);
	}

	public onFocus($event) {
		this.focusEvent$.emit($event);
		this.isFocused = $event;
	}

	public onStatusChange(control: UntypedFormControl) {
		this.customErrors = [];
		this._validationErrors$.next(control.errors);
		if (control.errors) {
			this.aggregateErrors(control.errors);
		}
		if (!this.metadataOverride || !this.metadataOverride.warningValidatorOptions) {
			this.isPending = control.pending && control.touched;
		}
		this.isInvalid = !control.valid && control.touched && !this.isPending;
	}

	public onValueChange(value: string) {
		if (this.metadataOverride && this.metadataOverride.warningValidatorOptions && this.lastCheckedTerm !== value && value != null) {
			const warningOptions = this.metadataOverride.warningValidatorOptions;
			this.isPending = true;
			this.lastCheckedTerm = value;
			this.validationHelper.validate(warningOptions, value)
				.subscribe(result => {
					result = result.filter(entry => entry.id != this.entityScope.entity.id);
					this.warning = result.length > 0 ? warningOptions.errorMsg(result) : '';
					this.isPending = false;
				});
		}
		if (value == null) {
			this.warning = '';
		}
		this.onChange.emit(value);
	}

	public aggregateErrors(errors: ValidationErrors): void {
		const errorKeys = Object.keys(errors);
		errorKeys.forEach(errorKey => {
			if (this.isCustomError(errors[errorKey])) {
				this.customErrors.push(this.domSanitizer.bypassSecurityTrustHtml(errors[errorKey]) as string);
			}
		});
	}

	public isCustomError(err): boolean {
		return typeof err == 'string';
	}

	public updateEditor(value, data): void {
		this.gaEditor.updateView(value, data);
	}

	public resetEditor(): void {
		if (this.gaEditor) {
			this.gaEditor.reset();
		}
	}

	get validationErrors$(): Observable<object> {
		return this._validationErrors$.asObservable();
	}
}
