import { CommonModule, CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FlashMessageComponent } from '@app/core/flash-messages/flash-message/flash-message.component';
import { FlashMessagesContainerComponent } from '@app/core/flash-messages/flash-messages-container.component';
import { ApiErrorInterceptor } from '@app/core/interceptors/api-error-interceptor';
import { AuthInterceptor } from '@app/core/interceptors/auth.interceptor';
import { BaseUrlInterceptor } from '@app/core/interceptors/base-url.interceptor';
import { GoogleApiService } from '@app/core/services/google-api.service';
import { TabTitleDirective } from '@app/core/tab-title/tab-title.directive';
import { TodoDataProviderService } from '@app/core/todo-store/todo-data-provider.service';
import { TodoEffects } from '@app/core/todo-store/todo.effects';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
	declarations: [
		TabTitleDirective,
		FlashMessagesContainerComponent,
		FlashMessageComponent,
	],
	imports: [
		CommonModule,
		HttpClientJsonpModule,
		HttpClientModule,
		EffectsModule.forFeature([TodoEffects]),
		FontAwesomeModule
	],
	exports: [
		TabTitleDirective,
		FlashMessagesContainerComponent,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		TodoDataProviderService,
		GoogleApiService,
		CurrencyPipe,
	]
})
export class CoreModule {
	constructor(
		@Optional() @SkipSelf() parentModule: CoreModule
	) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import only in AppModule');
		}
	}
}
