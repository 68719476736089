import { Injectable } from '@angular/core';
import { SmartSearchApplicant, SmartSearchDoctor, SmartSearchDoctorContact, SmartSearchItem, SmartSearchLawFirm, SmartSearchLawFirmStaff, SmartSearchMedicalFacility, SmartSearchSection, SmartSearchSectionState, SmartSearchState } from '@app/app-layout/app-header/smart-search/smart-search.model';
import { Applicant } from '@app/shared/model/types/applicant';

@Injectable()
export class SmartSearchService {

	public createSearchItem(name, item): SmartSearchItem {
		let searchItem = null;
		switch (name) {
			case SmartSearchSection.Applicants :
				searchItem = this.createApplicantSearchItem(item);
				break;
			case SmartSearchSection.LawFirmStaff:
				searchItem = this.createLawFirmStaffSearchItem(item);
				break;
			case SmartSearchSection.LawFirms:
				searchItem = this.createLawFirmSearchItem(item);
				break;
			case SmartSearchSection.Doctors:
				searchItem = this.createDoctorSearchItem(item);
				break;
			case SmartSearchSection.MedicalFacilities:
				searchItem = this.createMedicalFacilitySearchItem(item);
				break;
			case SmartSearchSection.DoctorContacts:
				searchItem = this.createDoctorContactSearchItem(item);
				break;
		}
		return searchItem;
	}

	public createApplicantSearchItem(entityToCreate: SmartSearchApplicant): SmartSearchItem<SmartSearchApplicant> {
		return { name: SmartSearchSection.Applicants, entity: entityToCreate };
	}

	public createLawFirmStaffSearchItem(entityToCreate: SmartSearchLawFirmStaff): SmartSearchItem<SmartSearchLawFirmStaff> {
		return { name: SmartSearchSection.LawFirmStaff, entity: entityToCreate };
	}

	public createLawFirmSearchItem(entityToCreate: SmartSearchLawFirm): SmartSearchItem<SmartSearchLawFirm> {
		return { name: SmartSearchSection.LawFirms, entity: entityToCreate };
	}

	public createDoctorSearchItem(entityToCreate: SmartSearchDoctor): SmartSearchItem<SmartSearchDoctor> {
		return { name: SmartSearchSection.Doctors, entity: entityToCreate };
	}

	public createMedicalFacilitySearchItem(entityToCreate: SmartSearchMedicalFacility): SmartSearchItem<SmartSearchMedicalFacility> {
		return { name: SmartSearchSection.MedicalFacilities, entity: entityToCreate };
	}

	public createDoctorContactSearchItem(entityToCreate: SmartSearchDoctorContact): SmartSearchItem<SmartSearchDoctorContact> {
		return { name: SmartSearchSection.DoctorContacts, entity: entityToCreate };
	}

	public isApplicantSearchItem(item: SmartSearchItem): item is SmartSearchItem<Applicant> {
		return item?.name === SmartSearchSection.Applicants;
	}

	public isLawFirmStaffSearchItem(item: SmartSearchItem): item is SmartSearchItem<SmartSearchLawFirmStaff> {
		return item?.name === SmartSearchSection.LawFirmStaff;
	}

	public isLawFirmSearchItem(item: SmartSearchItem): item is SmartSearchItem<SmartSearchLawFirm> {
		return item?.name === SmartSearchSection.LawFirms;
	}

	public isDoctorSearchItem(item: SmartSearchItem): item is SmartSearchItem<SmartSearchDoctor> {
		return item?.name === SmartSearchSection.Doctors;
	}

	public isMedicalFacilityItem(item: SmartSearchItem): item is SmartSearchItem<SmartSearchMedicalFacility> {
		return item?.name === SmartSearchSection.MedicalFacilities;
	}

	public isDoctorContactItem(item: SmartSearchItem): item is SmartSearchItem<SmartSearchDoctorContact> {
		return item?.name === SmartSearchSection.DoctorContacts;
	}

	public expandSection(selectedSection: SmartSearchSection): SmartSearchSectionState {
		return {
			[SmartSearchSection.Applicants]: selectedSection === SmartSearchSection.Applicants
				? SmartSearchState.Expanded
				: SmartSearchState.Collapsed,
			[SmartSearchSection.LawFirmStaff]: selectedSection === SmartSearchSection.LawFirmStaff
				? SmartSearchState.Expanded
				: SmartSearchState.Collapsed,
			[SmartSearchSection.LawFirms]: selectedSection === SmartSearchSection.LawFirms
				? SmartSearchState.Expanded
				: SmartSearchState.Collapsed,
			[SmartSearchSection.Doctors]: selectedSection === SmartSearchSection.Doctors
				? SmartSearchState.Expanded
				: SmartSearchState.Collapsed,
			[SmartSearchSection.MedicalFacilities]: selectedSection === SmartSearchSection.MedicalFacilities
				? SmartSearchState.Expanded
				: SmartSearchState.Collapsed,
			[SmartSearchSection.DoctorContacts]: selectedSection === SmartSearchSection.DoctorContacts
				? SmartSearchState.Expanded
				: SmartSearchState.Collapsed,
		};
	}

	public getDefaultAccordionsState(): SmartSearchSectionState {
		return {
			[SmartSearchSection.Applicants]: SmartSearchState.Void,
			[SmartSearchSection.LawFirmStaff]: SmartSearchState.Void,
			[SmartSearchSection.LawFirms]: SmartSearchState.Void,
			[SmartSearchSection.Doctors]: SmartSearchState.Void,
			[SmartSearchSection.MedicalFacilities]: SmartSearchState.Void,
			[SmartSearchSection.DoctorContacts]: SmartSearchState.Void,
		};
	}
}
