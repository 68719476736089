import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GaGridModule } from '@app/ga-grid/ga-grid.module';
import { CodelistSelectsModule } from '@app/shared/codelist-selects/codelist-selects.module';
import { DraggableModule } from '@app/shared/draganddrop/draganddrop.module';
import { GaComponentsModule } from '@app/shared/ga-components/ga-components.module';
import { SharedModule } from '@app/shared/shared.module';
import { AssigneeListComponent } from '@app/todo/assignee-list/assignee-list.component';
import { TodosListComponent } from '@app/todo/list/todos-list.component';
import { TodosListService } from '@app/todo/list/todos-list.service';
import { NewTodoFormComponent } from '@app/todo/new-todo-form/new-todo-form.component';
import { NewTodoModalComponent } from '@app/todo/new-todo-modal/new-todo-modal.component';
import { AssociationIdSplitterPipe } from '@app/todo/pipes/association-id-splitter.pipe';
import { TodoCountPipe } from '@app/todo/pipes/todo-count.pipe';
import { TodoLabelPipe } from '@app/todo/pipes/todo-label.pipe';
import { TodoAssociationHelperService } from '@app/todo/services/todo-association-helper.service';
import { TodoAssociationsService } from '@app/todo/services/todo-associations.service';
import { TodoService } from '@app/todo/services/todo.service';
import { TodoAssociationsListComponent } from '@app/todo/todo-associations-list/todo-associations-list.component';
import { TodoAssociationsSelectComponent } from '@app/todo/todo-associations-select/todo-associations-select.component';
import { TodoCardSummaryComponent } from '@app/todo/todo-card-summary/todo-card-summary.component';
import { TodoActivityLogItemComponent } from '@app/todo/todo-card/todo-activity-log-item/todo-activity-log-item.component';
import { TodoAssigneesComponent } from '@app/todo/todo-card/todo-assignees/todo-assignees.component';
import { TodoCardActivityLogComponent } from '@app/todo/todo-card/todo-card-activity-log/todo-card-activity-log.component';
import { TodoCardAssociationsComponent } from '@app/todo/todo-card/todo-card-associations/todo-card-associations.component';
import { TodoCardDescriptionComponent } from '@app/todo/todo-card/todo-card-description/todo-card-description.component';
import { TodoCardDueDateComponent } from '@app/todo/todo-card/todo-card-due-date/todo-card-due-date.component';
import { TodoCardComponent } from '@app/todo/todo-card/todo-card.component';
import { TodoCategoriesComponent } from '@app/todo/todo-card/todo-categories/todo-categories.component';
import { TodoCategoryLabelsComponent } from '@app/todo/todo-card/todo-categories/todo-category-labels/todo-category-labels.component';
import { TodoCategoryListComponent } from '@app/todo/todo-card/todo-categories/todo-category-list/todo-category-list.component';
import { TodoCommentsModule } from '@app/todo/todo-card/todo-comments/todo-comments.module';
import { TodoLabelsComponent } from '@app/todo/todo-card/todo-labels/todo-labels.component';
import { DatepickerInputComponent } from '@app/todo/todo-due-date/datepicker-input/datepicker-input.component';
import { TimezoneInputComponent } from '@app/todo/todo-due-date/timezone-input/timezone-input.component';
import { TodoDueDateComponent } from '@app/todo/todo-due-date/todo-due-date.component';
import { TodoFormAssociationsComponent } from '@app/todo/todo-form-associations/todo-form-associations.component';
import { TodoRoutingModule } from '@app/todo/todo-routing.module';
import { TodoNotificationDotComponent } from '@app/todo/todo-sidebar/todo-sidebar-expand-button/todo-notification-dot/todo-notification-dot.component';
import { TodoNotificationMentionComponent } from '@app/todo/todo-sidebar/todo-sidebar-expand-button/todo-notification-mention/todo-notification-mention.component';
import { TodoSidebarExpandButtonComponent } from '@app/todo/todo-sidebar/todo-sidebar-expand-button/todo-sidebar-expand-button.component';
import { TodoSidebarSortComponent } from '@app/todo/todo-sidebar/todo-sidebar-sort/todo-sidebar-sort.component';
import { TodoSidebarComponent } from '@app/todo/todo-sidebar/todo-sidebar.component';
import { TodosListWidgetComponent } from '@app/todo/todos-list-widget/todos-list-widget.component';
import { TodoWhiteboardColumnComponent } from '@app/todo/whiteboard/whiteboard-column.component';
import { TodoWhiteboardComponent } from '@app/todo/whiteboard/whiteboard.component';
import { EntityLabelModule } from '@app/widgets/entity-label/entity-label.module';
import { LoadingButtonModule } from '@app/widgets/loading-button/loading-button.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { LetDirective } from '@ngrx/component';
import { AgGridModule } from 'ag-grid-angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		BsDropdownModule,
		DraggableModule,
		FormsModule,
		NgSelectModule,
		ReactiveFormsModule,
		BsDatepickerModule,
		TooltipModule,
		TimepickerModule,
		GaComponentsModule,
		GaGridModule,
		TodoCommentsModule,
		TodoRoutingModule,
		FontAwesomeModule,
		AgGridModule,
		CodelistSelectsModule,
		OverlayModule,
		LoadingButtonModule,
		EntityLabelModule,
		TodoSidebarSortComponent,
		TodoNotificationDotComponent,
		TodoNotificationMentionComponent,
		LetDirective,
	],
	declarations: [
		AssigneeListComponent,
		TodoCardComponent,
		TodoCardDescriptionComponent,
		TodoCardAssociationsComponent,
		TodoCardActivityLogComponent,
		TodoCountPipe,
		TodoLabelPipe,
		AssociationIdSplitterPipe,
		TodoCategoryListComponent,
		TodosListComponent,
		TodoActivityLogItemComponent,
		TodoWhiteboardComponent,
		TodosListWidgetComponent,
		TodoWhiteboardColumnComponent,
		TodoCategoryLabelsComponent,
		TodoCardSummaryComponent,
		TodoCategoriesComponent,
		TodoAssigneesComponent,
		TodoCardDueDateComponent,
		NewTodoFormComponent,
		NewTodoModalComponent,
		TodoFormAssociationsComponent,
		TodoAssociationsSelectComponent,
		TodoAssociationsListComponent,
		TodoDueDateComponent,
		DatepickerInputComponent,
		TimezoneInputComponent,
		TodoLabelsComponent,
		TodoSidebarComponent,
		TodoSidebarExpandButtonComponent
	],
	exports: [
		TodoCountPipe,
		TodoLabelPipe,
		TodosListWidgetComponent,
		TodoCategoryLabelsComponent,
		TodoCardActivityLogComponent,
		TodoCardAssociationsComponent,
		TodoCardSummaryComponent,
		TodoCategoriesComponent,
		TodoCardDescriptionComponent,
		TodoAssigneesComponent,
		TodoCardDueDateComponent,
		TodoCardComponent,
		NewTodoFormComponent,
		DatepickerInputComponent,
		TodoSidebarComponent
	],
	providers: [
		TodoService,
		TodoAssociationsService,
		TodosListService,
		TodoAssociationHelperService
	]
})

export class TodoModule {
}
