import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CollapsibleItemComponent } from '@app/dashboard/collapsible-item/collapsible-item.component';
import { DashboardDataUtilsService } from '@app/dashboard/dashboard-data-utils.service';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { TimeRelatedConfigurationComponent } from '@app/dashboard/shared/time-related-configuration/time-related-configuration.component';
import { ConfigurationChangedEvent } from '@app/dashboard/shared/time-related-configuration/time-related-configuration.model';
import { SummaryStatisticsGraphService } from '@app/dashboard/summary-statistics/summary-statistics-graph.service';
import { SummaryStatisticsModalService } from '@app/dashboard/summary-statistics/summary-statistics-modal.service';
import { SummaryStatisticsChartConfig, SummaryStatisticsChartId, SummaryStatisticsChartMode, SummaryStatisticsResponse } from '@app/dashboard/summary-statistics/summary-statistics.model';
import { SummaryStatisticsService } from '@app/dashboard/summary-statistics/summary-statistics.service';
import { CartesianSavedChart } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/cartesian-saved-chart';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { CartesianGroupedTooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/cartesian-grouped-tooltip-theme.service';
import { chartNumberAxesTheme, chartPriceAxesTheme } from '@app/shared/ag-grid-utils/chart-themes/chart-axes-theme';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { SVGIconComponent } from '@app/shared/svg-icon/svg-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, ColGroupDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject, shareReplay, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
	selector: 'settled-collected-table',
	templateUrl: 'settled-collected-table.component.html',
	providers: [
		SummaryStatisticsModalService,
		SummaryStatisticsGraphService,
	],
	imports: [
		AgGridModule,
		AsyncPipe,
		DirectivesModule,
		NgIf,
		SVGIconComponent,
		TooltipModule,
		TimeRelatedConfigurationComponent,
		FontAwesomeModule,
		LetDirective,
		NgStyle,
		CollapsibleItemComponent,
	],
	standalone: true
})
export class SettledCollectedTableComponent implements OnInit, OnDestroy {
	colDefs: (ColDef | ColGroupDef)[];
	gridOptions: GridOptions;
	hasSavedChart$: Observable<boolean>;
	data$: Observable<SummaryStatisticsResponse[]>;
	collapsed$ = new BehaviorSubject(false);
	collapsingIcon$: Observable<IconDefinition>;
	readonly clearIcon = SvgIconName.Preferences;
	private readonly savedCharts = new Map<SummaryStatisticsChartId, CartesianSavedChart>();
	private timeConfiguration$ = new ReplaySubject<ConfigurationChangedEvent>(1);
	private gridApi: GridApi;
	private destroy$ = new Subject<void>();

	constructor(
		private dashboardDataUtilsService: DashboardDataUtilsService,
		private summaryStatisticsService: SummaryStatisticsService,
		private summaryStatisticsModalService: SummaryStatisticsModalService,
		private agGridUtils: AgGridUtilsService,
		private dashboardFilterStore: DashboardFilterStore,
		private summaryStatisticsGraphService: SummaryStatisticsGraphService,
	) {
	}

	public ngOnInit(): void {
		this.collapsingIcon$ = this.collapsed$.pipe(
			map((collapsed) => collapsed ? faChevronDown : faChevronUp)
		);
		this.hasSavedChart$ = this.summaryStatisticsGraphService.hasSavedChart$;
		const tooltipTheme = new CartesianGroupedTooltipThemeService();
		this.data$ = combineLatest([this.timeConfiguration$, this.dashboardFilterStore.fundingParams$])
			.pipe(
				switchMap(([configuration, fundingTypeParams]) =>
					this.summaryStatisticsService.getSettledCollectedData$(configuration, fundingTypeParams)),
				shareReplay({ bufferSize: 1, refCount: true })
			);

		this.gridOptions = this.agGridUtils.getGridOptions({
			sideBar: false,
			defaultExcelExportParams: {
				fileName: 'Summary Statistics Export'
			},
		});
		this.colDefs = [
			{
				headerName: 'Period',
				field: 'title',
				...this.dashboardDataUtilsService.getPeriodColDef(),
				pinned: 'left',
				minWidth: 90,
				width: 90,
				chartDataType: 'category'
			},
			{
				headerName: 'Settled in Vintage',
				children: [
					{
						...tooltipTheme.addNumberColumn('# of Settled', 'settledDetails.numberOfFundings'),
						minWidth: 100,
						width: 100
					},
					{
						...tooltipTheme.addPriceColumn(
							'Principal Collected',
							'settledDetails.principalCollected'
						),
						minWidth: 150,
						width: 150,
					},
					{
						...tooltipTheme.addPriceColumn(
							'Principal Loss',
							'settledDetails.principalLoss'
						),
						minWidth: 120,
						width: 120,
					},
					{
						...tooltipTheme.addPriceColumn(
							'Principal Settled',
							'settledDetails.principalSettled'
						),
						minWidth: 150,
						width: 150,
					},
					{
						headerName: '% Settled',
						minWidth: 90,
						width: 90,
						field: 'settledDetails.settledPercentage',
						chartDataType: 'excluded'
					},
					{
						...this.getInfoButtonColDef(data => this.summaryStatisticsModalService.openDetailSettled(data))
					}
				]
			},
			{
				headerName: 'Total Collected in Period',
				children: [
					{
						...tooltipTheme.addNumberColumn(
							'# of Collected',
							'collectedDetails.numberOfFundings'
						),
						minWidth: 100,
						width: 100,
					},
					{
						...tooltipTheme.addPriceColumn(
							'$ Total',
							'collectedDetails.totalPrice'
						),
						minWidth: 120,
						width: 120,
					},
					{
						...tooltipTheme.addPriceColumn(
							'$ Principal',
							'collectedDetails.totalPrincipal'
						),
						minWidth: 120,
						width: 120,
					},
					{
						...tooltipTheme.addPriceColumn(
							'$ Fee',
							'collectedDetails.totalFee'
						),
						minWidth: 120,
						width: 120,
					},
					{
						...this.getInfoButtonColDef(data => this.summaryStatisticsModalService.openDetailCollected(data))
					}
				]
			}
		];
		this.savedCharts.set(SummaryStatisticsChartId.CollectedFundings, new CartesianSavedChart(
			`dashboard-summary-statistics-${SummaryStatisticsChartMode.Collected}-chart`,
			() => chartNumberAxesTheme('Summary Statistics - Collected Fundings'),
			tooltipTheme.copy()
		));
		this.savedCharts.set(SummaryStatisticsChartId.CollectedDollars, new CartesianSavedChart(
			`dashboard-summary-statistics-${SummaryStatisticsChartMode.Collected}-chart`,
			() => chartPriceAxesTheme('Summary Statistics - Collected Amount'),
			tooltipTheme.copy()
		));
		this.data$
			.pipe(
				map(data => data.map(summaryStatistics => ({
					...summaryStatistics,
					chartLabel: this.dashboardDataUtilsService.getPeriodLabel(summaryStatistics)
				}))),
				takeUntil(this.destroy$)
			).subscribe(data => {
			this.savedCharts.forEach(savedChart => savedChart.getTooltipTheme().setTooltipData(data));
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.summaryStatisticsGraphService.destroyCharts();
		this.summaryStatisticsModalService.completeDestroyObs();
	}


	public onConfigurationChanged(event: ConfigurationChangedEvent): void {
		this.timeConfiguration$.next(event);
	}

	public onFirstDataRendered(params: FirstDataRenderedEvent): void {
		params.api.sizeColumnsToFit();
	}

	private getInfoButtonColDef(modalAction): ColDef {
		return this.summaryStatisticsService.getInfoButtonColDef(modalAction);
	}

	private getChartElement(chartId: SummaryStatisticsChartId): string {
		if (this.summaryStatisticsGraphService.isFundingGraph(chartId)) {
			return '#collected-deals-chart';
		} else {
			return '#collected-dollars-chart';
		}
	}

	private createCollectedChart(): void {
		const fundingChart: SummaryStatisticsChartConfig = {
			columns: [
				{ colId: 'collectedDetails.numberOfFundings', chartType: 'groupedColumn' }
			],
			chartId: SummaryStatisticsChartId.CollectedFundings,
			chartElementSelector: this.getChartElement(SummaryStatisticsChartId.CollectedFundings)
		};

		const currencyChart: SummaryStatisticsChartConfig = {
			columns: [
				{ colId: 'collectedDetails.totalPrice', chartType: 'groupedColumn' }
			],
			chartId: SummaryStatisticsChartId.CollectedDollars,
			chartElementSelector: this.getChartElement(SummaryStatisticsChartId.CollectedDollars)
		};

		this.summaryStatisticsGraphService.createCharts([fundingChart, currencyChart]);
	}


	public onGridReady(params: GridReadyEvent): void {
		this.gridApi = params.api;
		this.summaryStatisticsGraphService.initialize(params.api, this.savedCharts);
		this.createCollectedChart();
	}

	public onChartOptionsChanged(): void {
		const modelsToSave = this.gridApi.getChartModels();
		const saveCharts = this.getSaveChartsForCurrentMode();
		saveCharts.forEach((savedChart, index) => {
			const colIds = modelsToSave[index].cellRange.columns as string[];
			savedChart.setTooltipIds(colIds);
			savedChart.saveChart(modelsToSave);
		});
		this.summaryStatisticsGraphService.hasSavedChart = true;
	}

	private getSaveChartsForCurrentMode(): CartesianSavedChart[] {
		return Array.from(this.savedCharts.values());
	}

	public clearSavedChartState(): void {
		const savedCharts = this.getSaveChartsForCurrentMode();
		savedCharts.forEach(savedChart => savedChart.clearSavedChart());
		this.createCollectedChart();
		this.summaryStatisticsGraphService.hasSavedChart = false;
	}

	public toggleCollapsed(): void {
		this.collapsed$.next(!this.collapsed$.value);
	}
}
