<ng-container *ngrxLet="round$; let roundValue">
	<div *ngrxLet="historicalPerformance$; let historicalPerformance" class="dash-panel ibox">
		<collapsible-item [isManagedOutside]="isCollapseManaged">
			<div header-left>
				<h4>Historical Performance:</h4>
				<span class="flex">in <ghost-element
					width="5">&nbsp;<b>{{ (historicalPerformance?.total?.totalCount ?? 0) | number: '1.0-0' }}</b>&nbsp;</ghost-element> fundings / <ghost-element
					width="5">&nbsp;<b>{{ (historicalPerformance?.total?.claims ?? 0) | number: '1.0-0' }}</b>&nbsp;</ghost-element> claims</span>
			</div>
			<h1 header-right>
				<ghost-element width="25"
							   height="4">{{ (historicalPerformance?.total?.settledAt ?? 0) | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</ghost-element>
			</h1>

			<div class="ibox-content">
				<div class="date-boundary-filter__wrapper">
					<dashboard-widget-date-filter class="date-boundary-filter"
												  [inputControl]="dateFilterControl"></dashboard-widget-date-filter>
					<input class="base-input date-boundary-filter"
						   placeholder="Filter by range..."
						   bsDaterangepicker
						   [bsConfig]="bsDaterangeConfig"
						   placement="left"
						   [formControl]="dateRangeFilterControl"/>
				</div>
				<historical-performance-table
					[entry]="historicalPerformance?.preSet"
					[roundValue]="roundValue">
					PreSet
				</historical-performance-table>
				<hr>
				<historical-performance-table
					[entry]="historicalPerformance?.medical"
					[roundValue]="roundValue">
					Medical
				</historical-performance-table>
				<hr>
				<historical-performance-table [entry]="undefined" [roundValue]="roundValue">
					Probate
				</historical-performance-table>
				<hr>
				<historical-performance-table
					[entry]="historicalPerformance?.caseCost"
					[roundValue]="roundValue">
					Case Cost
				</historical-performance-table>
				<hr>
				<historical-performance-table
					[entry]="historicalPerformance?.total"
					[roundValue]="roundValue">
					Total
				</historical-performance-table>
				<hr>
				<div class="row m-t-sm">
					<div class="col-sm-12">
						<table class="table-striped">
							<thead>
							<tr>
								<th></th>
								<th class="number">Avg. Funding Duration</th>
								<th class="number">Med. Funding Duration</th>
								<th class="number">Cash-on-cash Return</th>
								<th class="number" *aclIsAllowed="'application.show_irr'">IRR</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td><b>PreSet Cumulative</b></td>
								<td class="number">
									<ghost-element
										width="5">{{ (historicalPerformance?.preSetCumulative?.settlementDurationAvg ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ (historicalPerformance?.preSetCumulative?.settlementDurationMedian ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ historicalPerformance?.preSetCumulative?.cashOnCashReturn | number : '1.0-2' }}
										x
									</ghost-element>
								</td>
								<td class="number" *aclIsAllowed="'application.show_irr'">
									<ghost-element
											width="5">{{ historicalPerformance?.preSetCumulative?.irr | number : '1.0-1' }}
										%
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td><b>PreSet</b></td>
								<td class="number">
									<ghost-element
										width="5">{{ (historicalPerformance?.preSet?.settlementDurationAvg ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ (historicalPerformance?.preSet?.settlementDurationMedian ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ historicalPerformance?.preSet?.cashOnCashReturn | number : '1.0-2' }}
										x
									</ghost-element>
								</td>
								<td class="number" *aclIsAllowed="'application.show_irr'">
									<ghost-element
											width="5">{{ historicalPerformance?.preSet?.irr | number : '1.0-1' }}
										%
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td><b>Medical</b></td>
								<td class="number">
									<ghost-element
										width="5">{{ (historicalPerformance?.medical?.settlementDurationAvg ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ (historicalPerformance?.medical?.settlementDurationMedian ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ historicalPerformance?.medical?.cashOnCashReturn | number : '1.0-2' }}
										x
									</ghost-element>
								</td>
								<td class="number" *aclIsAllowed="'application.show_irr'">
									<ghost-element
											width="5">{{ historicalPerformance?.medical?.irr | number : '1.0-1' }}
										%
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td><b>Probate</b></td>
								<td class="number">
									<ghost-element
										width="5">{{ (undefined ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ (undefined ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ (undefined ?? 0) | number : '1.0-2' }}
										x
									</ghost-element>
								</td>
								<td class="number" *aclIsAllowed="'application.show_irr'">
									<ghost-element
										width="5">{{ (undefined ?? 0) | number : '1.0-1' }}
										%
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td><b>Case Cost</b></td>
								<td class="number">
									<ghost-element
										width="5">{{ (historicalPerformance?.caseCost?.settlementDurationAvg ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ (historicalPerformance?.caseCost?.settlementDurationMedian ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ historicalPerformance?.caseCost?.cashOnCashReturn | number : '1.0-2' }}
										x
									</ghost-element>
								</td>
								<td class="number" *aclIsAllowed="'application.show_irr'">
									<ghost-element
											width="5">{{ historicalPerformance?.caseCost?.irr | number : '1.0-1' }}
										%
									</ghost-element>
								</td>
							</tr>
							<tr>
								<td><b>Total</b></td>
								<td class="number">
									<ghost-element
										width="5">{{ (historicalPerformance?.total?.settlementDurationAvg ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ (historicalPerformance?.total?.settlementDurationMedian ?? 0) | number : '1.0-1' }}</ghost-element>
								</td>
								<td class="number">
									<ghost-element
										width="5">{{ historicalPerformance?.total?.cashOnCashReturn | number : '1.0-2' }}
										x
									</ghost-element>
								</td>
								<td class="number" *aclIsAllowed="'application.show_irr'">
									<ghost-element
											width="5">{{ historicalPerformance?.total?.irr | number : '1.0-1' }}
										%
									</ghost-element>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</collapsible-item>
	</div>
</ng-container>
