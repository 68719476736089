<div *ngIf="show" [@fadeUpDown] data-cy="selectList-body" class="select-list" [ngClass]="[align, class]" [ngStyle]="style">
	<div class="select-list__triangle"></div>
	<div class="select-list__wrapper">
		<ng-container *ngTemplateOutlet="enableThrottleClick ? withThrottleClick : withoutThrottleClick" ></ng-container>
		<div *ngIf="items?.length === 0" class="select-list__no-options">
			No options provided
		</div>
	</div>
</div>


<ng-template #withThrottleClick>
	<div *ngFor="let item of items; trackBy: trackByFn"
	     (throttleClick)="onClick(item)"
	     [attr.data-cy]="item?.nextState ? item.nextState + '-btn' : 'option__#'+item.id"
	     [class.selected]="isSelected(item)"
	     [style.color]="colorAttr ? resolvePath(item, colorAttr) : '#73768c'"
	     class="select-list__item">
		<svg-icon *ngIf="iconAttr" [icon]="resolvePath(item, iconAttr)" [ngStyle]="{'fill': colorAttr ? resolvePath(item, colorAttr) : '#73768c'}"></svg-icon>
		{{ getOptionTitle(item) }}
	</div>
</ng-template>
<ng-template #withoutThrottleClick>
	<div *ngFor="let item of items; trackBy: trackByFn"
	     (click)="onClick(item)"
	     [attr.data-cy]="item?.nextState ? item.nextState + '-btn' : 'option__#'+item.id"
	     [class.selected]="isSelected(item)"
	     [style.color]="colorAttr ? resolvePath(item, colorAttr) : '#73768c'"
	     class="select-list__item">
		<svg-icon *ngIf="iconAttr" [icon]="resolvePath(item, iconAttr)" [ngStyle]="{'fill': colorAttr ? resolvePath(item, colorAttr) : '#73768c'}"></svg-icon>
		{{ getOptionTitle(item) }}
	</div>
</ng-template>
