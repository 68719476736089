<div class="ibox flex-col">
	<div class="ibox-title flex-thin" style="align-items: center;">
		<h2 style="flex: 1;">To-do tasks</h2>
		<button class="base-button base-button--square" *ngIf="showClearButton" (click)="clearSavedSettings()" tooltip="Clear table preferences">
			<svg-icon [icon]="svgIconNames.Preferences" class="base-icon base-icon--small"></svg-icon>
		</button>
	</div>

	<ag-grid-angular
		#agGrid
		class="ag-theme-balham grid-list"
		[columnDefs]="columnDefinitions"
		[rowData]="todos$ | async"
		[gridOptions]="gridOptions"
		[rowClassRules]="rowClassRules"
		(firstDataRendered)="onFirstDataRendered()"
		(gridReady)="onGridReady($event)"
	></ag-grid-angular>
</div>
