import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { WebSocketMessageType } from '@app/core/web-socket/web-socket-message-type';
import { WebSocketService } from '@app/core/web-socket/web-socket.service';
import { fadeInRight } from '@app/shared/animations/animations';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { SmsRepositoryService } from '@app/shared/model/sms-repository.service';
import { SVGIconComponent } from '@app/shared/svg-icon/svg-icon.component';
import { SmsCommonModule } from '@app/sms-common/sms-common.module';
import { SmsConversation } from '@app/sms/sms-conversation';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-sms-notification',
	templateUrl: './sms-notification.component.html',
	styleUrls: ['./sms-notification.component.scss'],
	animations: [fadeInRight],
	imports: [
		DirectivesModule,
		SVGIconComponent,
		SmsCommonModule,
		NgIf,
		RouterLink
	],
	standalone: true
})
export class SmsNotificationComponent implements OnInit, OnDestroy {

	conversations = [];
	showDot: boolean;
	dropdownOpened = false;
	isInboxReady = false;

	readonly svgIconNames = SvgIconName;

	private isInboxSynced = false;
	private destroy$ = new Subject<void>();

	constructor(
		private websocketService: WebSocketService,
		private smsService: SmsRepositoryService) {
	}

	public ngOnInit(): void {
		if (localStorage.getItem('showDot') == null) {
			localStorage.setItem('showDot', 'true');
		}
		this.showDot = localStorage.getItem('showDot') == 'true';
		this.websocketService
			.getConnection()
			.pipe(
				filter((message) => {
					return message.type == WebSocketMessageType.SMSMessageReceived;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.showDot = true;
				this.isInboxSynced = false;
				localStorage.setItem('showDot', 'true');
			});
	}

	public openSmsWidget(): void {
		this.dropdownOpened = !this.dropdownOpened;
		if (!this.isInboxSynced) {
			this.fetchSmsConversations();
		}
		if (this.dropdownOpened) {
			this.updateToolbar();
		}
	}

	public selectConversation(conversation: SmsConversation): void {
		this.dropdownOpened = !this.dropdownOpened;
		if (conversation.unreadMessages.length) {
			this.markConversationAsRead(conversation);
		}
	}

	public markConversationAsRead(conversation: SmsConversation): void {
		this.smsService.markConversationAsRead(conversation);
	}

	public markAllConversationsAsRead(): void {
		this.smsService.markAllAsRead(this.conversations);
	}

	public updateToolbar(): void {
		if (this.showDot) {
			this.fetchSmsConversations();
			localStorage.setItem('showDot', 'false');
			this.showDot = false;
		}
	}

	public fetchSmsConversations(): void {
		this.isInboxReady = false;
		this.smsService.prepareInbox(0, 10)
			.pipe(takeUntil(this.destroy$))
			.subscribe((inboxData) => {
				this.conversations = inboxData.conversations;
				this.isInboxReady = true;
				this.isInboxSynced = true;
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
