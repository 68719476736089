import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { FieldsDefinitionService } from '@app/core/fields-definition/fields-definition.service';
import { AclResourceType } from '@app/core/security/acl-resource-type';
import { AclRoleType } from '@app/core/security/acl-role-type';
import { PermissionsStorageService } from '@app/core/security/permissions/permissions-storage.service';
import { PermissionsStoreService } from '@app/core/security/permissions/permissions-store.service';
import { ActionsPermission, FieldsPermission } from '@app/core/security/permissions/permissions.model';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { isObject, toString } from '@app/shared/general-helper';
import * as _ from 'lodash';
import { BehaviorSubject, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AclService {

	constructor(private authService: AuthService,
				private permissionsStore: PermissionsStoreService,
				private fieldsDefinitionService: FieldsDefinitionService,
				private http: HttpClient,
				private gaApiLink: GaApiLink,
				private permissionsCacheService: PermissionsStorageService
	) {
	}

	public setFieldPermission(role, entityName, fieldName, permission) {
		return this.http.patch('/api/security/field-permissions', {
			role: role,
			entityName: entityName,
			fieldName: fieldName,
			permission: permission
		}).pipe(
			tap(() => this.permissionsStore.getPermissions().fields[role][entityName][fieldName] = permission)
		);
	}

	public setActionPermission(role, action, permission) {
		return this.http.patch('/api/security/action-permissions', {
			role: role,
			action: action,
			permission: permission
		}).pipe(
			tap(() => this.permissionsStore.getPermissions().actions[role][action] = permission)
		);
	}

	public isReadable(field: string | any, entity, userAccount?): boolean {
		userAccount = userAccount || this.authService.getCurrentUser(); //preparation for querying other users
		if (userAccount && this.permissionsStore.getPermissions().fields) {
			const fieldDefinition = isObject(field) ? field : this.fieldsDefinitionService.getFieldDefinition(entity, field);
			if (!fieldDefinition) {
				console.warn('FIELD DEFINITION DOES NOT EXIST!', field, entity);
			}
			if (userAccount.roleType === AclRoleType.SuperAdmin) {
				return true;
			}
			if (Array.isArray(fieldDefinition.dependencies) && fieldDefinition.dependencies.length > 0) {
				const result = fieldDefinition.dependencies.map(pathExpression => {
					const dependencyDefinition = this.fieldsDefinitionService.getFieldDefinition(entity, pathExpression);
					if (dependencyDefinition) {
						return this.isReadable(dependencyDefinition.id, dependencyDefinition.entityClass, userAccount);
					}
					console.warn('No dependency definition found for:', entity, pathExpression);
					return true;
				})
				return !result.includes(false);
			} else {
				const permission = _.get(this.permissionsStore.getPermissions().fields,
					[userAccount.role, fieldDefinition.entityClass, fieldDefinition.id]);
				if (!permission) {
					return true; //outside of acl zone
				}
				return permission === FieldsPermission.Read || permission === FieldsPermission.Write;
			}
		}
		return undefined;
	}

	public isWritable(field: string | any, entity, userAccount?) {
		userAccount = userAccount || this.authService.getCurrentUser(); //preparation for querying other users
		if (userAccount && this.permissionsStore.getPermissions().fields) {
			const fieldDefinition = isObject(field) ? field : this.fieldsDefinitionService.getFieldDefinition(entity, field);
			if (!fieldDefinition) {
				console.warn('FIELD DEFINITION DOES NOT EXIST!', field, entity);
			}

			if (userAccount.roleType === AclRoleType.SuperAdmin) {
				return true;
			}

			if (Array.isArray(fieldDefinition.dependencies) && fieldDefinition.dependencies.length > 0) {
				const result = fieldDefinition.dependencies.map(pathExpression => {
					const dependencyDefinition = this.fieldsDefinitionService.getFieldDefinition(entity, pathExpression);
					if (dependencyDefinition) {
						return this.isWritable(dependencyDefinition.id, dependencyDefinition.entityClass, userAccount);
					}
					console.warn('No dependency definition found for:', entity, pathExpression);
					return true;
				})
				return !result.includes(false);
			} else {
				const permission = _.get(this.permissionsStore.getPermissions().fields,
					[userAccount.role, fieldDefinition.entityClass, fieldDefinition.id]);
				if (!permission) {
					return true; //outside of acl zone
				}
				return permission === FieldsPermission.Write;
			}
		}
		return undefined;
	}

	public isAllowed(action, userAccount?) {
		return this.isAllowedForPermission(action, ActionsPermission.All, userAccount)
			|| this.isAllowedForPermission(action, ActionsPermission.Own, userAccount);
	}

	public isAllowedForPermission(action, permissionToTest: ActionsPermission, userAccount?): boolean {
		userAccount = userAccount || this.authService.getCurrentUser();
		if (userAccount && this.permissionsStore.getPermissions().actions) {
			if (userAccount.roleType === AclRoleType.SuperAdmin) {
				return true;
			}
			const permission = this.permissionsStore.getPermissions().actions[userAccount.role][action];
			return permission === permissionToTest;
		}
		return false;
	}

	//should not be called as a part of loop (eg. instead of testing every applicant, test the lawfirm)
	public isResourceAllowed(action: string, resourceType: AclResourceType, resourceId) {
		const result = new BehaviorSubject<boolean>(false);
		const userAccount = this.authService.getCurrentUser();

		if (userAccount && this.permissionsStore.getPermissions().actions) {
			if (userAccount.roleType === AclRoleType.SuperAdmin) {
				result.next(true);
			}
			const permission = this.permissionsStore.getPermissions().actions[userAccount.role][action];
			if (permission === ActionsPermission.All) {
				result.next(true);
			} else if (permission === ActionsPermission.Own) {
				if (this.permissionsCacheService.has(resourceType, resourceId)) {
					const value = this.permissionsCacheService.get(resourceType, resourceId);
					result.next(value);
				} else {
					const field = this.resolveSecurityFieldName(userAccount.roleType);
					const path = this.resolvePath(resourceType).concat([field, 'id']).join('.');
					this.gaApiLink.retrieve(resourceType, [path], { id: resourceId }).subscribe((response) => {
						const value = toString(_.get(response[0], path)) === toString(userAccount.id);
						this.permissionsCacheService.set(resourceType, resourceId, value);
						result.next(value);
					});
				}
			}
		} else {
			result.next(false);
		}
		return result;
	}

	public isAllowedSome(actions: string[], userAccount?) {
		return actions.reduce((result, action) => result || this.isAllowed(action, userAccount), false);
	}

	public isAllowedAll(actions: string[], userAccount?) {
		return actions.reduce((result, action) => result && this.isAllowed(action, userAccount), true);
	}

	private resolveSecurityFieldName(roleType: AclRoleType): string {
		switch (roleType) {
			case AclRoleType.Admin:
				return 'salesRepresentative';
			case AclRoleType.Consultant:
				return 'fundingConsultant';
			default:
				throw Error('Role is not supported');
		}
	}

	private resolvePath(resourceType: AclResourceType): string[] {
		switch (resourceType) {
			case AclResourceType.LawFirm:
				return [];
			case AclResourceType.UserAccount:
				return ['lawFirm'];
			case AclResourceType.Applicant:
				return ['attorney', 'lawFirm'];
			case AclResourceType.Funding:
				return ['applicant', 'attorney', 'lawFirm'];
		}
		throw Error('Unsupported resource');
	}
}
