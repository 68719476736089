import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class PermissionsStoreService {

	private permissions;

	private notifier$ = new Subject();

	public getNotifier$() {
		return this.notifier$;
	}

	public setPermissions(permissions) {
		this.permissions = permissions;
		this.notifier$.next(permissions);
	}

	public getPermissions() {
		return this.permissions;
	}
}
