import { EventEmitter, Injectable } from '@angular/core';
import { ArchivedReasonComponent } from '@app/applicant/applicant-detail/modals/archived-reason/archived-reason.component';
import { FundingNoteFormModalComponent } from '@app/applicant/applicant-detail/modals/funding-note-form-modal/funding-note-form-modal.component';
import { PortalCaseStatusChangesTableComponent } from '@app/security/portal-case-status-changes/portal-case-status-changes-table.component';
import { EntityId } from '@app/shared/model/types/entity-id';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable()
export class ModalService {

	constructor(
		private modalService: BsModalService
	) {
	}

	public openFundingNoteModal(note): void {
		this.modalService.show(FundingNoteFormModalComponent, {
			initialState: {
				fundingNote: note
			}
		});
	}

	public openArchiveReasonModal(funding): EventEmitter<any> {
		this.modalService.show(ArchivedReasonComponent, {
			initialState: {
				funding: funding,
			}
		});

		return this.modalService.onHide;
	}

	public openCaseUpdatesModal(caseStatusUpdatesIds: number[]): EventEmitter<EntityId> {
		const component = this.modalService.show(PortalCaseStatusChangesTableComponent, {
			initialState: {
				caseStatusUpdatesIds: caseStatusUpdatesIds
			}
		});

		return component.content.caseStatusUpdated;
	}
}
