<div class="row m-t m-b" *ngIf="chartDataSets.length > 0">
	<div class="col-sm-3">
		<label>Date Range</label>
		<daterange-picker [rangesEmitter]="ranges$" (dateRangeSelected)="onDateRangeSelected($event)"></daterange-picker>
	</div>
	<div class="col-sm-3">
		<label>Resolution:</label>
		<div class="base-button-group small full-width">
			<button (click)="toggleChartMode('daily')" class="base-button" [ngClass]="{'active': chartMode == 'daily' }" type="button">Daily</button>
			<button (click)="toggleChartMode('weekly')" class="base-button" [ngClass]="{'active': chartMode == 'weekly' }" type="button">Weekly</button>
			<button (click)="toggleChartMode('monthly')" class="base-button" [ngClass]="{'active': chartMode == 'monthly' }" type="button">Monthly</button>
		</div>
	</div>
</div>

<collapsible-item>
	<h4 header-left>Chart:</h4>
	<div class="row m-t m-b" *ngIf="chartDataSets.length > 0">
		<div class="col-sm-12 canvas-wrapper">
			<canvas baseChart id="line" class="chart chart-bar"
					[datasets]="chartDataSets"
					[labels]="chartLabels"
					[options]="chartOptions"
					(chartClick)="onChartClick($event)"
					[type]="'bar'">
			</canvas>
		</div>
	</div>
</collapsible-item>
<div *ngIf="chartDataSets.length == 0" class="jumbotron"><h2 class="text-center">No data available to show</h2></div>
