import { Component } from '@angular/core';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'ag-medical-opportunity-flag-renderer',
	templateUrl: 'ag-medical-opportunity-flag-renderer.component.html',
})
export class AgMedicalOpportunityFlagRendererComponent implements AgRendererComponent {

	params: ICellRendererParams;
	readonly flagIcon = faFlag;

	public agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	public refresh(_params: ICellRendererParams): boolean {
		return false;
	}

}
