import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { currencyColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/currency-column-definition';
import { dateColumnDefinition } from '@app/shared/ag-grid-utils/column-definitions/date-column-definition';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { destroy$ } from '@app/shared/modals/vintage-detail/vintage-detail-config';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { Funding } from '@app/shared/model/types/funding';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Observable, takeUntil } from 'rxjs';

@Component({
	selector: 'rp-detail-modal',
	templateUrl: 'rp-detail-modal.component.html',
	styleUrls: ['rp-detail-modal.component.scss'],
	imports: [
		AgGridModule,
		AsyncPipe
	],
	standalone: true
})
export class RpDetailModalComponent implements OnInit {
	@Input() fundingIds: number[];
	fundings$: Observable<Funding[]>;
	gridOptions: GridOptions;
	columnDefs: ColDef[];
	private fieldList = [
		'internalId',
		'applicant -> firstName, lastName',
		'applicant.attorney -> firstName, lastName, lawFirm.name',
		'fundingType.title',
		'fundedOn',
		'fundedAt',
		'purchasePrice'
	];

	constructor(
		private apiLink: GaApiLink,
		private agUtils: AgGridUtilsService,
		private codelistService: CodelistRepositoryService
	) {
	}

	public ngOnInit(): void {
		this.fundings$ = this.apiLink.retrieve('funding', this.fieldList, { id: { '%in': this.fundingIds } });
		this.gridOptions = this.agUtils.getGridOptions({ sideBar: false });
		this.columnDefs = [
			{
				field: 'internalId',
				cellRenderer: ({ data, value }) =>
					data?.applicant ? this.agUtils.fundingLinkRenderer(value, data?.id, data?.applicant?.id) : value,
				filter: 'agTextColumnFilter',
			},
			{
				field: 'applicant',
				valueGetter: ({ data }) => `${data?.applicant?.firstName ?? ''} ${data?.applicant?.lastName ?? ''}`,
				sortable: true,
				filter: 'agTextColumnFilter',
				cellRenderer: ({ data, value }) =>
					data ? this.agUtils.applicantLinkRenderer(data?.applicant?.id, value) : value
			},
			{
				field: 'applicant.attorney.lawFirm.name',
				headerName: 'Law Firm',
				sortable: true,
				cellRenderer: ({ data, value }) =>
					data ? this.agUtils.lawFirmLinkRenderer(data?.applicant?.attorney?.lawFirm?.id, value) : value,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => {
						this.codelistService.getByUrl(UrlCodelistLink.LawFirms)
							.pipe(takeUntil(destroy$))
							.subscribe(value => params.success(this.agUtils.getArrayOfStringsByFiledName(value, 'name')));
					},
					buttons: ['clear', 'reset'],
				},
				minWidth: 200,
			},
			{
				headerName: 'Attorney',
				width: 200,
				field: 'applicant.attorney',
				valueFormatter: ({ value }) => value?.firstName ? `${value?.firstName} ${value?.lastName}` : value,
				cellRenderer: ({ value }) =>
					this.agUtils.attorneyLinkRenderer(value?.lawFirm?.id, value?.id, `${value?.firstName} ${value?.lastName}`),
				filter: 'agSetColumnFilter',
				keyCreator: ({ value }) => value ? `${value?.firstName} ${value?.lastName}` : '',
				filterParams: {
					values: (params) => {
						this.codelistService.getByUrl(UrlCodelistLink.Attorneys)
							.pipe(takeUntil(destroy$))
							.subscribe(attorneys => {
								params.success(this.agUtils.getArrayOfStringsByFiledName(attorneys, ['firstName', 'lastName']));
							});
					},
					buttons: ['clear', 'reset']
				},
				comparator: (a, b) => this.agUtils.stringComparatorByName(a, b),
			},
			{
				headerName: 'Funded On',
				...dateColumnDefinition('fundedOn'),
			},
			{
				field: 'fundingType.title',
				headerName: 'Funding Type',
				filter: 'agTextColumnFilter'
			},
			{
				headerName: 'Funded Amount',
				...currencyColumnDefinition('fundedAt'),
			},
			{
				headerName: 'Purchase Price',
				...currencyColumnDefinition('purchasePrice')
			},
		];
	}

	public onGridReady(params: GridReadyEvent): void {
		params.api.sizeColumnsToFit();
	}
}
