<div class="base-card">
	<div class="base-card__header">
		<div class="base-card__header__title">Portfolio</div>
	</div>
	<div class="base-card__content">
		<div class="base-card__content__table-wrapper">
			<ul class="nav nav-tabs">
				<li *ngFor="let tabConfig of config | entries"
					[ngClass]="{'active' : tabConfig.key == currentTab}">
					<a (click)="changeTab(tabConfig.key)">{{ tabConfig.value.title }} <span class="label label-default"
																							*ngrxLet="counts$; let counts">{{ counts?.[tabConfig.key] }}</span></a>
				</li>
			</ul>
			<div class="tab-content">
				<div class="tab-pane active">
					<div class="tabs-container">
						<ag-grid-angular
							class="ag-theme-balham grid-list"
							[columnDefs]="colDefs"
							[rowData]="fundings$ | async"
							[gridOptions]="gridOptions"
							(gridReady)="onGridReady($event)"
						></ag-grid-angular>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
