import { Component } from '@angular/core';
import { ModalsService } from '@app/shared/modals/modals.service';
import { SelectListItem } from '@app/shared/model/types/select-list-item';
import { DownloadLinkConfig } from '@app/widgets/download-link/download-link-config.model';
import { Subject } from 'rxjs';

@Component({
	selector: 'export-reports-btn',
	templateUrl: './export-reports-button.component.html'
})
export class ExportReportsButtonComponent {

	downloadLink$: Subject<DownloadLinkConfig> = new Subject<DownloadLinkConfig>();
	showReportsList = false;
	reportListOptions: ReportsItem[] = [
		{
			id: 1,
			name: 'Static Pool Rep.',
			aclRule: 'reports.static_pool',
			callback: () => this.modalsService.openStaticPoolOptionsModal()
		},
		{
			id: 2,
			name: 'Static Pool Data Rep.',
			aclRule: 'reports.static_pool_data',
			callback: () => this.downloadStaticPoolDataReport()
		},
		{
			id: 3,
			name: 'Accounting Rep.',
			aclRule: 'reports.accounting',
			callback: () => this.downloadAccountingReport()
		},
		{
			id: 4,
			name: 'Asset Schedule Rep.',
			aclRule: 'reports.asset_schedule',
			callback: () => this.modalsService.openReportOptionsModalLayout1('/api/reports/asset-schedule-report')
		},
		{
			id: 6,
			name: 'Attorney Performance Rep.',
			aclRule: 'reports.attorneys_performance',
			callback: () => this.modalsService.openReportOptionsModalLayout2('/api/reports/attorneys-performance-report')
		},
		{
			id: 7,
			name: 'Collections Rep.',
			aclRule: 'reports.collections',
			callback: () => this.modalsService.openReportOptionsModalLayout1('/api/reports/collections-report')
		},
		{
			id: 8,
			name: 'Commission Rep.',
			aclRule: 'reports.commission',
			callback: () => this.modalsService.openReportOptionsModalLayout2('/api/reports/commission-report')
		},
		{
			id: 9,
			name: 'Servicing Rep.',
			aclRule: 'reports.servicing',
			callback: () => this.modalsService.openServicingOptionsModal()
		},
		{
			id: 10,
			name: 'Banc Covenants Rep.',
			aclRule: 'reports.banc_covenants',
			callback: () => this.downloadBancCovenantsReport()
		},
		{
			id: 11,
			name: 'Regional Rep.',
			aclRule: 'reports.regional',
			callback: () => this.modalsService.openReportOptionsModalLayout1('/api/reports/regional-report')
		},
	];

	constructor(public modalsService: ModalsService) {
	}

	public onReportSelect(report: ReportsItem) {
		report.callback();
	}

	public downloadStaticPoolDataReport() {
		this.downloadLink$.next({ url: '/api/reports/static-pool-data-report' });
	}

	public downloadAccountingReport() {
		this.downloadLink$.next({ url: '/api/reports/accounting-report' });
	}

	public downloadBancCovenantsReport() {
		this.downloadLink$.next({ url: '/api/reports/banc-covenants-report' });
	}

	public toggleReportsList() {
		this.showReportsList = !this.showReportsList;
	}

	public hideReportsList() {
		this.showReportsList = false;
	}
}

export interface ReportsItem extends SelectListItem {
	callback?: () => void
}
