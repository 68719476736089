import { Component, HostBinding, OnInit } from '@angular/core';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { GaToggleSwitchRendererComponent } from '@app/shared/ga-components/components/renderers/ga-toggle-switch-renderer/ga-toggle-switch-renderer.component';
import { FormScopeService } from '@app/shared/ga-components/services/form-scope.service';
import { GaToggleSwitchParams } from '@app/shared/ga-components/utils/ga-toggle-switch-params';
import * as moment from 'moment';

@Component({
	selector: 'case-manager-tracking-toggle',
	templateUrl: './case-manager-tracking-toggle.component.html',
	providers: [EntityScopeService, FormScopeService],
	styles: [':host {align-items: center;}']
})
export class CaseManagerTrackingToggleComponent extends GaToggleSwitchRendererComponent implements OnInit {
	@HostBinding('class.view') hostClass = true;

	additionalDate: string = null;
	params: CMTrackingParams;

	public ngOnInit() {
		super.ngOnInit();
		this.assignAdditionalValue();
	}

	public redraw() {
		super.redraw();
		this.assignAdditionalValue();
	}

	private assignAdditionalValue(): void {
		const date = this.params.dateToShow(this.dataObj);
		this.additionalDate = date ? moment(date).format('MM/DD/YYYY') : null;
	}

	public assignNewValue() {
		super.assignNewValue();
		this.additionalDate = this.value ? moment(new Date()).format('MM/DD/YYYY') : null;
	}
}

interface CMTrackingParams extends GaToggleSwitchParams {
	dateToShow: (dataObj) => string
}
