import { Pipe, PipeTransform } from '@angular/core';
import { TodoStatus } from '@app/shared/model/constants/todo-status';
import { TodoCardData } from '@app/todo/model/todo-card-data';

@Pipe({ name: 'todoCount' })
export class TodoCountPipe implements PipeTransform {

	public transform(todos: TodoCardData[], status: TodoStatus | null, currentUserId: string): number {
		if (!todos) {
			return 0;
		}
		if (status === null) {
			return todos.length;
		}
		return todos.filter((item) => item.status === status &&
			item.assignees.some((assignee) => assignee.id.toString() == currentUserId)).length;
	}
}
