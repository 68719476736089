import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stringifyObjectArray' })
export class StringifyObjectArrayPipe implements PipeTransform {

	public transform(value: Record<string, unknown>[] | undefined, property: string): string {
		if (!value || value.length === 0) {
			return '';
		}
		return value.map((codelistItem) => codelistItem[property]).join(', ');
	}
}
