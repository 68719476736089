export enum MedicalDreamColumnIds {
	ProviderPricingProcedure = 'mdb_preop_ppp',
	ReadyForReview = 'mdb_ready_for_review',
	ApprovedLop = 'mdb_approved_lop',
	LopProcessed = 'mdb_preop_lop_processed',
	PreOp = 'mdb_preop_preop',
	Schedule = 'mdb_op_schedule',
	Remind = 'mdb_op_remind',
	Confirm = 'mdb_op_confirm',
	PIPacket = 'mdb_postop_pi_packet',
	Close = 'mdb_postop_close',
	Send = 'mdb_postop_send',
}

export const MedicalDreamBoardColumnNames = {
	[MedicalDreamColumnIds.ProviderPricingProcedure]: 'Provider, Pricing and Procedure',
	[MedicalDreamColumnIds.ReadyForReview]: 'Ready For Review',
	[MedicalDreamColumnIds.ApprovedLop]: 'Approved LOP',
	[MedicalDreamColumnIds.LopProcessed]: 'LOP Processed',
	[MedicalDreamColumnIds.PreOp]: 'Pre-Op',
	[MedicalDreamColumnIds.Schedule]: 'Schedule',
	[MedicalDreamColumnIds.Remind]: 'Remind',
	[MedicalDreamColumnIds.Confirm]: 'Confirm',
	[MedicalDreamColumnIds.PIPacket]: 'PI Packet',
	[MedicalDreamColumnIds.Close]: 'Close',
	[MedicalDreamColumnIds.Send]: 'Review and Send',
}
