<collapsible-item>
	<h4 header-left>Chart:</h4>
	<div class="row">
		<div class="col-md-9">
			<div class="chart-clear-wrapper">
				<button class="base-button base-button--square" tooltip="Clear Graph preferences"
						*ngIf="hasSavedChart$ | async"
						(click)="clearSavedChartState()">
					<svg-icon class="base-icon base-icon--small" [icon]="clearIcon"></svg-icon>
				</button>
			</div>
			<div class="dashboard-collected-chart" id="collected-deals-chart"></div>
			<div class="dashboard-collected-chart" id="collected-dollars-chart"></div>
		</div>
		<div class="col-md-3">
			<h4>Configuration</h4>
			<div class="form-group">
				<label>Display: Collected</label>
				<div class="base-button-group small full-width">
					<button class="base-button active" type="button">Collected</button>
				</div>
				<time-related-configuration
					(configurationChanged)="onConfigurationChanged($event)">
				</time-related-configuration>
			</div>
		</div>
	</div>
</collapsible-item>
<br>
<div class="ibox-title" (click)="toggleCollapsed()">
	<div class="flex">
		<div class="flex">
			<div class="collapsible m-r-sm">
				<fa-icon [icon]="collapsingIcon$ | async"></fa-icon>
			</div>
			<div>
				<h4>Data Table:</h4>
			</div>
		</div>
	</div>
</div>
<ng-container *ngrxLet="collapsed$; let collapsed">
	<div [ngStyle]="{ display: collapsed ? 'none' : 'block' }">
		<ag-grid-angular
			class="ag-theme-balham grid-list"
			[gridOptions]="gridOptions"
			[columnDefs]="colDefs"
			[rowData]="data$ | async"
			(gridReady)="onGridReady($event)"
			(firstDataRendered)="onFirstDataRendered($event)"
			(chartOptionsChanged)="onChartOptionsChanged()"
		></ag-grid-angular>
	</div>
</ng-container>
