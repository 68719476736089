import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ButtonOption {
	id: number,
	name: string,
	value: string
}

@Component({
	selector: 'option-button',
	templateUrl: './option-button.component.html',
	styleUrls: ['./option-button.component.scss'],
})
export class OptionButtonComponent {

	@Input() label: string;
	@Input() options: ButtonOption[];
	@Input() selectedOption: ButtonOption | null = null;
	@Input() optionsPlacement = 'right';
	@Input() customClass = '';
	@Input() showActionButton = true;

	@Output() selected = new EventEmitter<any>();
	@Output() buttonClick = new EventEmitter<any>();

	isOpen =  false;

	public selectOption(option): void {
		this.selected.emit(option);
	}

	public onButtonClick(event): void {
		this.buttonClick.emit(event);
	}

}
