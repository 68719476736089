import { Component, OnInit } from '@angular/core';
import { TodoAssociationsListEntry } from '@app/todo/model/todo-associations-list-entry';
import { TodoAssociationsService } from '@app/todo/services/todo-associations.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'todo-form-associations',
	templateUrl: 'todo-form-associations.component.html'
})
export class TodoFormAssociationsComponent implements OnInit {
	associations$: Observable<TodoAssociationsListEntry[]>;

	constructor(private todoAssociationsService: TodoAssociationsService) {
	}

	public ngOnInit(): void {
		this.associations$ = this.todoAssociationsService.getAssociations$();
	}

	public associationSelected(event: TodoAssociationsListEntry): void {
		this.todoAssociationsService.addAssociation(event);
	}

	public removeAssociation(associationToRemove: TodoAssociationsListEntry): void {
		this.todoAssociationsService.removeAssociation(associationToRemove);
	}
}
