<address>
	<div *ngIf="address.street" class="doctor-addresses-widget__value-row">
		<div class="doctor-addresses-widget__label">Street:</div>
		<div class="doctor-addresses-widget__value"
			 data-cy="address-street">{{ address.street }}</div>
	</div>
	<div *ngIf="address.city" class="doctor-addresses-widget__value-row">
		<div class="doctor-addresses-widget__label">City:</div>
		<div class="doctor-addresses-widget__value"
			 data-cy="address-city">{{ address.city }}</div>
	</div>
	<div *ngIf="address.zip" class="doctor-addresses-widget__value-row">
		<div class="doctor-addresses-widget__label">Zip:</div>
		<div class="doctor-addresses-widget__value"
			 data-cy="address-zip">{{ address.zip }}</div>
	</div>
	<div *ngIf="address.state" class="doctor-addresses-widget__value-row">
		<div class="doctor-addresses-widget__label">State:</div>
		<div class="doctor-addresses-widget__value"
			 data-cy="address-state">{{ address.state.title }}</div>
	</div>
	<div *ngIf="address.geographicLocation" class="doctor-addresses-widget__value-row">
		<div class="doctor-addresses-widget__label">Location:</div>
		<div class="doctor-addresses-widget__value"
			 data-cy="address-location">{{ address.geographicLocation.title }}</div>
	</div>
	<div *ngIf="address.locationNote" class="doctor-addresses-widget__value-row">
		<div class="doctor-addresses-widget__label">Address Note:</div>
		<div class="doctor-addresses-widget__value"
			 data-cy="address-locationNote">{{ address.locationNote }}</div>
	</div>
</address>
