import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { selectBoardTodos } from '@app/core/todo-store/todo.selectors';
import { Todo } from '@app/shared/model/types/todo';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

@Component({
	selector: 'todo-notification-mention',
	templateUrl: 'todo-notification-mention.component.html',
	styleUrls: ['todo-notification-mention.component.scss'],
	imports: [
		AsyncPipe,
		NgIf
	],
	standalone: true
})
export class TodoNotificationMentionComponent implements OnInit {
	isCurrentUserMentioned$: Observable<boolean>;

	constructor(
		private store: Store<{ todos: Todo[] }>,
		private authService: AuthService,
	) {
	}

	public ngOnInit(): void {
		const currentUserId = this.authService.getCurrentUser().id;
		this.isCurrentUserMentioned$ = this.store.select(selectBoardTodos)
			.pipe(map((todos) => todos.some((todo) =>
					todo.comments.some((comment) =>
						comment.mentions.some((mention) => mention.mentionedUser.id == currentUserId && !mention.seen)
					)
				))
			);
	}
}
