import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'overlay-panel',
	templateUrl: './overlay-panel.component.html',
	animations: [
		trigger('flyIn', [
			state(
				'collapsed',
				style({
					maxWidth: 0,
					display: 'none',
				})
			),
			state(
				'visible',
				style({
					maxWidth: '100%',
					overflow: 'auto',
				})
			),
			transition('collapsed <=> visible', animate(500)),
		]),
		trigger('dim', [
			state(
				'collapsed',
				style({
					display: 'none',
					opacity: 0,
				})
			),
			state(
				'visible',
				style({
					opacity: 0.3,
				})
			),
			transition('collapsed <=> visible', animate(500)),
		]),
	],
})
export class OverlayPanelComponent {
	overlayState = 'collapsed';

	readonly faIcons = {
		times: faTimes,
	};

	public toggle() {
		this.overlayState = this.overlayState == 'collapsed' ? 'visible' : 'collapsed';
	}
}
