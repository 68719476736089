<table class="table-striped">
	<thead>
	<tr>
		<th></th>
		<th class="number">Fndg./Claims</th>
		<th class="number">Principal</th>
		<th class="number">Fee</th>
		<th class="number">Total</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let group of groups">
		<th>
			<ghost-element width="10">
				<a *ngIf="group.fundings.length > 0; else rpDetail" (click)="openDetail(group.fundings)">
					{{ group.creditFacility }}-{{ group.fundingType }}</a>
				<ng-template #rpDetail>
					<span>{{ group.creditFacility }}-{{ group.fundingType }}</span>
				</ng-template>
			</ghost-element>
		</th>
		<td class="number">
			<ghost-element width="10">{{ group.dealsCount }} / {{ group.claims }}</ghost-element>
		</td>
		<td class="number">
			<ghost-element
				width="10">{{ group.principal | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
			</ghost-element>
		</td>
		<td class="number">
			<ghost-element
				width="10">{{ group.fee | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
			</ghost-element>
		</td>
		<td class="number">
			<ghost-element
				width="10">{{ group.faceValue | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
			</ghost-element>
		</td>
	</tr>
	<tr>
		<th>Total</th>
		<th></th>
		<td class="number">
			<ghost-element
				width="10">{{ groups | sumProperties : 'principal' | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
			</ghost-element>
		</td>
		<td class="number">
			<ghost-element
				width="10">{{ groups | sumProperties : 'fee' | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
			</ghost-element>
		</td>
		<td class="number">
			<ghost-element
				width="10">{{ groups | sumProperties : 'faceValue' | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
			</ghost-element>
		</td>
	</tr>
	</tbody>
</table>
