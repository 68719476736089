<div class="ibox-title cursor-pointer" (click)="toggleCollapsed()">
	<div class="flex">
		<div class="flex">
			<div class="collapsible m-r-sm" *ngIf="showIcon">
				<fa-icon [icon]="collapsingIcon$ | async"></fa-icon>
			</div>
			<div>
				<ng-content select="[header-left]"></ng-content>
			</div>
		</div>
		<ng-content select="[header-right]"></ng-content>
	</div>
</div>
<ng-container *ngIf="expanded$ | async">
	<ng-content></ng-content>
</ng-container>
