import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { NgSelectSearchingHelperService } from '@app/shared/model/ng-select-searching-helper.service';
import { TimezoneItem } from '@app/shared/model/types/timezone';
import { TimezoneInputService } from '@app/todo/todo-due-date/timezone-input/service/timezone-input.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'timezone-input',
	templateUrl: 'timezone-input.component.html',
	styleUrls: ['timezone-input.component.scss']
})
export class TimezoneInputComponent implements OnInit {
	@Input() disabled = false;
	timezoneControl: FormControl<TimezoneItem>;
	timezones$: Observable<TimezoneItem[]>

	constructor(
		public searchingHelper: NgSelectSearchingHelperService,
		private timezoneInputService: TimezoneInputService,
		private codelistRepository: CodelistRepositoryService) {
	}

	public ngOnInit(): void {
		this.timezoneControl = this.timezoneInputService.timezoneCtrl;
		this.timezones$ = this.codelistRepository.get<TimezoneItem[]>(NonUrlCodelistName.Timezone);
	}

	public setLocalTimezone(timezones: TimezoneItem[]) {
		if (!this.disabled) {
			this.timezoneControl.setValue(timezones.find((item) => item.zone === Intl.DateTimeFormat().resolvedOptions().timeZone));
		}
	}

	public setLaTimezone(timezones: TimezoneItem[]) {
		if (!this.disabled) {
			this.timezoneControl.setValue(timezones.find((item) => item.default));
		}
	}
}
