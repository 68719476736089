import { formatCurrency, formatPercent } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EntityStat } from '@app/dashboard/top-five-section/top-entities-stats/top-entities-stats.model';
import { TopEntitiesStatsService } from '@app/dashboard/top-five-section/top-entities-stats/top-entities-stats.service';
import { PieSavedChart } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/pie-saved-chart';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { PieGroupedTooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/pie-grouped-tooltip-theme.service';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { ChartRef, FirstDataRenderedEvent, GridApi } from 'ag-grid-community';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'top-entities-stats',
	templateUrl: 'top-entities-stats.component.html',
	providers: [TopEntitiesStatsService]
})
export class TopEntitiesStatsComponent implements OnInit, OnDestroy {
	@Input() dataUrl: string;
	@Input() titleHeaderName: string;
	@Input() storageKey: string;

	data$: Observable<EntityStat[]>;

	columnDefs;
	gridOptions;

	hasSavedChart$ = new ReplaySubject<boolean>(1);

	readonly clearIcon = SvgIconName.Preferences;

	protected gridApi: GridApi;

	private tooltipTheme = new PieGroupedTooltipThemeService();
	private pieChartDirective: PieSavedChart;
	private chartRef: ChartRef = null;

	private destroy$ = new Subject<void>();

	constructor(
		private statsService: TopEntitiesStatsService,
		private agGridUtils: AgGridUtilsService) {
	}

	public ngOnInit() {
		this.pieChartDirective = new PieSavedChart(
			this.storageKey,
			() => ({}),
			this.tooltipTheme
		);
		this.statsService.setUrl(this.dataUrl);
		this.gridOptions = this.agGridUtils.getGridOptions({
			sideBar: false,
			defaultExcelExportParams: {
				fileName: `Top 5 ${this.titleHeaderName}`
			},
			domLayout: 'autoHeight'
		});
		this.data$ = this.statsService.getData();
		this.data$
			.pipe(
				map(data => data.map(datum => ({ ...datum, chartLabel: datum.title }))),
				takeUntil(this.destroy$)
			).subscribe(data => {
			this.tooltipTheme.setTooltipData(data);
		});
		this.columnDefs = [
			{
				...this.tooltipTheme.addPercentageColumn(
					'Percentage',
					'percentage'
				),
				valueFormatter: ({ value }) => formatPercent(value, 'en-US', '1.0-0'),
			},
			{
				field: 'title',
				headerName: this.titleHeaderName,
				chartDataType: 'category'
			},
			{
				...this.tooltipTheme.addPriceColumn(
					'Purchase Price',
					'purchasePriceSum'
				)
			}
		];
	}

	public onFirstDataRendered(params: FirstDataRenderedEvent) {
		params.api.sizeColumnsToFit();
		this.gridApi = params.api;
		const savedChart = this.getSavedChart();
		if (savedChart !== null) {
			this.chartRef = savedChart;
			return;
		}
		this.createDefaultChart();
	}

	private createDefaultChart() {
		if (this.chartRef !== null) {
			this.chartRef.destroyChart();
		}
		this.chartRef = this.gridApi.createRangeChart({
			chartType: 'doughnut',
			cellRange: {
				columns: ['title', 'purchasePriceSum']
			},
			chartThemeName: 'ag-pastel',
			chartContainer: this.getChartElement(),
			chartThemeOverrides: {
				pie: {
					title: {
						enabled: false,
						text: `Purchase Price by ${this.titleHeaderName}`
					},
					legend: {
						position: 'bottom',
					},
					padding: {
						bottom: 40
					},
					series: {
						title: {
							enabled: false,
						},
						tooltip: {
							renderer: (chartParams) => {
								return {
									content: formatCurrency(chartParams.angleValue, 'en-US', '$', '1.2-2'),
									title: `${chartParams.angleName} - ${chartParams.datum.title}`
								};
							}
						}
					}
				}
			}
		});
	}

	private getSavedChart() {
		const chartModel = this.pieChartDirective.getSavedChart();
		if (chartModel !== null) {
			this.hasSavedChart$.next(true);
			return this.gridApi.restoreChart(chartModel, this.getChartElement());
		}
		return null;
	}

	protected getChartElement(): HTMLElement {
		return document.querySelector('#chart');
	}

	public onChartOptionsChanged() {
		const modelsToSave = this.gridApi.getChartModels();
		const colIds = modelsToSave[0].cellRange.columns as string[];
		this.pieChartDirective.setTooltipIds(colIds);
		this.pieChartDirective.saveChart(modelsToSave);
		this.hasSavedChart$.next(true);
	}

	public clearSavedChartState() {
		this.pieChartDirective.clearSavedChart();
		this.hasSavedChart$.next(false);
		this.createDefaultChart();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.chartRef?.destroyChart();
	}
}
