import { TODO_FEATURE_KEY, TodoState } from '@app/core/todo-store/todo.reducer';
import { TodoStatus } from '@app/shared/model/constants/todo-status';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as _ from 'lodash';

const _selectTodos = createFeatureSelector<TodoState>(TODO_FEATURE_KEY);

export const selectSidebarTodos = currentUserId => createSelector(
	_selectTodos,
	state => state.filter(todo => {
		return [TodoStatus.InProgress, TodoStatus.Todo, TodoStatus.Done].includes(todo.status)
			&& (todo?.createdBy?.id == currentUserId || todo?.assignees.some(assignee => assignee?.id == currentUserId));
	})
);

export const selectCurrentUserUnseenMentionedTodos = currentUserId => createSelector(
	_selectTodos,
	state => state.filter(todo => todo.comments.some((comment) => comment.mentions.some((mention) =>
		mention.mentionedUser.id == currentUserId && !mention.seen))
	)
);

export const selectBoardTodos = createSelector(
	_selectTodos,
	state => state.filter(todo => todo.status != TodoStatus.Archived)
);

export const selectTodo = todoId => createSelector(
	_selectTodos,
	state => _.merge({}, state.find(todo => todo.id == todoId))
);

export const hasTodo = todoId => createSelector(
	_selectTodos,
	state => state.some(todo => todo.id === todoId)
);

