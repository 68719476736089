import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';

@Directive({
	selector: '[aclIsWritable]'
})
export class AclIsWritableDirective implements OnInit {
	@Input() set aclIsWritable(field: string | any) {
		this._field = field;
	}

	@Input() set aclIsWritableEntity(entity: any) {
		this._entity = entity;
	}

	private _field: string | any;
	private _entity: any;

	constructor(private acl: AclService,
				private templateRef: TemplateRef<any>,
				private viewContainer: ViewContainerRef) {
	}

	public ngOnInit(): void {
		if (this.acl.isWritable(this._field, this._entity)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
