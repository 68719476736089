import { Persistable } from '@app/core/data-watch/utils';
import { FilterVisitor } from '@app/shared/filter-visitors/models/filter-visitor';
import { get } from 'lodash';

export class BooleanFilterVisitor<T extends Persistable> extends FilterVisitor<boolean> {
	constructor(private path: string) {
		super(path);
	}

	public filter(filterObject: T): boolean {
		return get(filterObject, this.path, null) === this.value;
	}

	public isActive(): boolean {
		return this.value != null
	}
}
