import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camelCaseToDashString' })
export class CamelCaseToDashStringPipe implements PipeTransform {

	public transform(str: string): string {
		return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
	}

}
