export enum UnderwritingColumnIds {
	ReadyForReview = 'ready_for_review',
}

export const UnderwritingColumnNames = {
	[UnderwritingColumnIds.ReadyForReview]: {
		preset: 'Ready for Review - PreSet',
		caseCost: 'Ready for Review - Case Cost',
		medical: 'Ready for Review - Medical',
		courtReporting: 'Ready for Review - Court Reporting'
	},
}
