import { AfterViewInit, Component, ContentChild, forwardRef, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges, SkipSelf, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { CollectionItemConfig } from '@app/shared/ga-components/components/collection/ga-collection-item.directive';
import { GaForDirective } from '@app/shared/ga-components/components/collection/ga-for.directive';
import { EntityScopeService } from '@app/shared/ga-components/components/entity/entity-scope.service';
import { FormScopeService } from '@app/shared/ga-components/services/form-scope.service';
import { GaMetadataAdapterService } from '@app/shared/ga-components/services/ga-metadata-adapter.service';
import { AssociationMetadata } from '@app/shared/ga-components/utils/association-metadata';
import { FieldMetadata } from '@app/shared/ga-components/utils/field-metadata';
import { GaCollectionItem } from '@app/shared/ga-components/utils/ga-collection-item';
import { Hook } from '@app/shared/ga-components/utils/hook';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject, Subscription, filter, takeUntil } from 'rxjs';

@Component({
	selector: 'ga-collection',
	providers: [FormScopeService],
	template: `
		<ng-container #vc></ng-container>
		<ng-container *ngFor="let item of filteredItems$ | async">
			<ng-container *ngTemplateOutlet="tmpl; context: {$implicit: item}"></ng-container>
		</ng-container>
	`
})
export class GaCollectionComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

	@Input() field;
	@Input() filterPredicate: (item) => boolean = null;
	@Input() sortFn: (a: { data: any }, b: { data: any }) => number = null;
	@Input() selfDestruct = true;
	@Input() useVirtualScroll = false;
	@Input() searchEmitter$: Observable<any>;

	@ContentChild(GaForDirective, { read: TemplateRef, static: true }) tmpl: TemplateRef<any>;
	@ViewChild('vc', { read: ViewContainerRef, static: true }) vc: ViewContainerRef;

	fieldMetadata: FieldMetadata;
	items: GaCollectionItem[] = null;
	filteredItems$: BehaviorSubject<GaCollectionItem[]> = new BehaviorSubject<GaCollectionItem[]>([]);
	formArray: UntypedFormArray;
	formScope: FormScopeService;

	private endIndex: number;
	private itemsPerPage = 9;
	private internalFieldList = [];
	private isPristine = true;
	private destroy$ = new Subject();

	constructor(
		@Inject(forwardRef(() => EntityScopeService)) public entityScope: EntityScopeService,
		public metadataAdapter: GaMetadataAdapterService,
		private formBuilder: UntypedFormBuilder,
		@Optional() @SkipSelf() parentScope: FormScopeService,
		collectionScope: FormScopeService,
	) {
		//either use parent form scope as container, or provide own scope
		this.formScope = parentScope || collectionScope;
		this.formArray = formBuilder.array([]);
	}

	public ngOnInit() {
		if (this.searchEmitter$) {
			this.searchEmitter$.pipe(takeUntil(this.destroy$))
				.subscribe(() => this.filterItems());
		}
		this.endIndex = this.itemsPerPage;
		this.clearCollection(false);
		const config = { editMode: false, persisted: true };
		this.formScope.formGroup.addControl(this.field, this.formArray);

		if (this.entityScope.entity) {
			this.items = _.map(this.entityScope.entity[this.field], (data: any) => ({ data, config, id: data.id }));
			this.filterItems();
		}
		this.fieldMetadata = this.metadataAdapter.getMetadata(this.entityScope.entityClass, this.field);
		this.vc.createEmbeddedView(this.tmpl, {});

		this.entityScope.emitter.pipe(
			filter(data => !_.isNil(data) && this.isPristine),
			takeUntil(this.destroy$)
		).subscribe((data) => {
			while (this.formArray.length > 0) {
				this.formArray.removeAt(0);
			}
			if (data) {
				this.items = _.map(_.get(this.entityScope.entity, this.field,
					data[this.field]), (value) => ({ data: value, config, id: value.id }));
			}
			this.filterItems();
		});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.filterPredicate && !changes.filterPredicate.isFirstChange()) {
			this.filterItems();
		}
	}

	public ngAfterViewInit(): void {
		this.vc.clear();
	}

	public reload() {
		this.items = [];
		this.entityScope.reload(this.internalFieldList);
	}

	public addField(dependencies: string[]) {
		this.entityScope.addField(dependencies.map(dependency => this.field + '.' + dependency));
		this.internalFieldList = _.uniq(this.internalFieldList.concat(dependencies.map(dependency => this.field + '.' + dependency)));
	}

	public registerCollectionItem(formGroup) {
		this.formArray.push(formGroup);
	}

	public addItem(data, config: CollectionItemConfig = {}, isPristine = false) {
		this.isPristine = isPristine;
		const tmpId = 'tmp_' + this.items.length.toString();
		config.mappedByField = this.fieldMetadata.association.mappedBy;
		config.mappedById = this.fieldMetadata.association.isManyToMany()
			? { '%add': [this.entityScope.entity.id] } : this.entityScope.entity.id;
		config.persisted = false;
		config.addOnTop = config.addOnTop || false;
		const newItem = { data, config, id: tmpId };
		const filteredItems = this.filteredItems$.value;
		if (config.addOnTop) {
			filteredItems.unshift(newItem);
			this.items.unshift(newItem);
		} else {
			filteredItems.push(newItem);
			this.items.push(newItem);
		}
		this.filterItems();
	}

	public removeItem(item, doPersist = true, hooks?: Hook[], responseFieldsOverride?: string[]): Subscription {
		this.isPristine = false;
		const idx = _.indexOf(this.items, item);
		this.formArray.removeAt(idx);
		this.items = _.pull(this.items, item);
		this.filterItems();

		//TODO maybe put information if object is persisted into config part?
		if (doPersist && item.data.id) {
			if (this.fieldMetadata?.association?.type === AssociationMetadata.ONE_TO_MANY) {
				const collectionItemClass = this.fieldMetadata?.association?.targetEntity;
				if (collectionItemClass) {
					return this.formScope.deleteFromCollection(collectionItemClass, item.data.id).subscribe();
				}
			}

			responseFieldsOverride = responseFieldsOverride ?? ['id', this.field, `${this.field}.id`];
			return this.formScope.saveCollection(this.field, hooks, responseFieldsOverride).subscribe();
		}
	}

	public clearCollection(doPersist: boolean, hooks?: Hook[], responseFieldsOverride?: string[]): void {
		this.formArray.clear();
		this.items = [];
		this.filteredItems$.next([]);

		if (doPersist) {
			responseFieldsOverride = responseFieldsOverride ?? ['id', this.field, `${this.field}.id`];
			this.formScope.saveCollection(this.field, hooks, responseFieldsOverride).subscribe();
		}
	}

	public updateCollectionItem(uid, item) {
		const idx = this.items.findIndex(el => el.id === uid);
		// merge updated values into collection
		this.items[idx].data = item;
		this.filterItems();
		const newEntity = { ...this.entityScope.entity };
		newEntity[this.field] = _.map(this.items, 'data');
		this.isPristine = true;
		this.entityScope.setEntity(newEntity);
	}

	private filterItems() {
		let filteredItems;
		if (this.filterPredicate === null) {
			filteredItems = [...this.items];
		} else {
			/**	earlier code use to directly check includes with tmp, tmp is used to add a doctor/notes for ga-component
			 * the issue here was, notes list is having id prop in text format ('123'), and doctors list is having id in number format (123)
			 * so had to cast and use string to make it work constant for search and add functionality
			 * working fine for law firm doctor, time line notes search/add
			 */
			filteredItems = this.items.filter(item => String(item.id).includes('tmp') || this.filterPredicate(item.data));
		}

		if (this.sortFn) {
			filteredItems = filteredItems.sort(this.sortFn);
		}

		filteredItems = this.useVirtualScroll ? filteredItems.slice(0, this.endIndex) : filteredItems;

		this.filteredItems$.next(filteredItems);
	}

	public loadMoreItems(): void {
		this.endIndex += this.itemsPerPage;
		this.filterItems();
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
		if (this.selfDestruct && this.formScope && this.formScope.formGroup) {
			this.formScope.formGroup.removeControl(this.field);
		}
	}
}
