import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AgGridStorageService } from '@app/ga-grid/services/ag-grid-storage-services/ag-grid-storage.service';
import * as _ from 'lodash';

@Injectable()
export class AgGridFilterStorageService extends AgGridStorageService {
	readonly storageId = 'ag-grid-filters-model';

	constructor(router: Router) {
		super(router);
	}

	public getSpecificFilterModel(filterId: string): any {
		const wholeFilterModel = this.getPersistedState();
		return _.get(wholeFilterModel, filterId, null);
	}
}
