import { Injectable } from '@angular/core';
import { IdentityProviderService } from '@app/core/auth/identity-provider.service';
import { TodoDataProviderService } from '@app/core/todo-store/todo-data-provider.service';
import { removeTodo, updateTodo } from '@app/core/todo-store/todo.actions';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { EntityId } from '@app/shared/model/types/entity-id';
import { TodoCardComment, TodoCardData } from '@app/todo/model/todo-card-data';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { exhaustMap, filter, map, Observable, switchMap, takeUntil, tap } from 'rxjs';

type TodoCardState = {
	todo: TodoCardData,
	hasDetail: boolean,
	isLoadingDetail: boolean
}

@Injectable()
export class TodoCardStoreService extends ComponentStore<TodoCardState> {

	readonly todo$ = this.select(state => state.todo);

	readonly todoId$ = this.select(state => state.todo?.id);

	readonly assignees$ = this.select(state => state.todo?.assignees);

	readonly isLoading$ = this.select(state => state.isLoadingDetail);

	readonly comments$ = this.select(state => state.todo?.comments);

	readonly hasUnreadMentions$ = this.select(({ todo }) => {
		const currentUserId = this.identityProvider.getIdentity().id;
		return todo?.comments.some((comment) =>
			comment.mentions.some((mention) => mention.mentionedUser.id == currentUserId && !mention.seen)
		);
	});

	readonly setTodo = this.effect((todo$: Observable<TodoCardData>) => {
		return todo$.pipe(
			tap((todoUpdate: TodoCardData) => this.patchState({ todo: todoUpdate }))
		);
	});

	readonly fetchDetail = this.effect((todoId$: Observable<EntityId>) => {
		return todoId$.pipe(
			filter(() => !this.get().hasDetail),
			tap(() => {
				this.patchState({
					hasDetail: true,
					isLoadingDetail: true
				});
			}),
			switchMap(todoId => {
				return this.dataProviderService.fetchDetail(todoId.toString())
					.pipe(
						tap(todoUpdate => {
							this.updateTodo(todoUpdate);
						}),
						tap(todoUpdate => this.setState((state) => ({
								...state,
								isLoadingDetail: false,
								todo: {
									...state.todo,
									...todoUpdate
								}
							})
						))
					);
			})
		);
	});

	readonly markAsSeen = this.effect((ignored$: Observable<null>) => {
		const currentUserId = this.identityProvider.getIdentity().id;
		return ignored$.pipe(
			map(() => {
					return this.get().todo.comments.reduce((acc, comment) => {
						const filteredMentionIds = comment.mentions.filter((mention) =>
							mention.mentionedUser.id == currentUserId
							&& !mention.seen
							&& mention.id != null).map((mention) => mention.id);
						return [...acc, ...filteredMentionIds];
					}, []);
				}
			),
			filter((mentions) => mentions.length > 0),
			exhaustMap((mentions) => {
					return this.apiLink.update('todoCommentMention', mentions, { seen: true }, {})
						.pipe(
							tap(() => this.markMentionsAsSeen(mentions))
						);
				}
			),
		);
	});

	readonly updateComment = this.updater((state, comment: TodoCardComment) => {
		const todoComments = [...state.todo.comments];
		const todoComment = { ...comment };
		const persistedTodoIndex = todoComments.findIndex(persistedTodo => persistedTodo.id == todoComment.id);

		if (persistedTodoIndex != -1) {
			todoComments.splice(persistedTodoIndex, 1, todoComment);
		} else {
			todoComments.push(todoComment);
		}

		return {
			...state,
			todo: {
				...state.todo,
				comments: todoComments
			}
		};
	});

	constructor(
		private identityProvider: IdentityProviderService,
		private apiLink: GaApiLink,
		private store: Store,
		private dataProviderService: TodoDataProviderService) {
		super({ todo: null, hasDetail: false, isLoadingDetail: false });
		this.todoId$
			.pipe(
				filter(todoId => !!todoId),
				switchMap(todoId => this.dataProviderService.getCommentUpdates(todoId)),
				takeUntil(this.destroy$)
			).subscribe(commentUpdate => {
			this.updateComment(commentUpdate);
		});
	}

	readonly updateTodo = (todoUpdate: Partial<TodoCardData>) => {
		this.patchState(state => {
			const todo = { ...state.todo, ...todoUpdate };

			return {
				...state,
				todo
			}
		});
		this.store.dispatch(updateTodo({ payload: todoUpdate }));
	}

	readonly removeTodo = (todoId: EntityId) => this.store.dispatch(removeTodo({ id: todoId.toString() }));

	private markMentionsAsSeen(mentions: EntityId[]): void {
		const todo = this.get().todo;
		const comments: TodoCardComment[] = [...todo.comments]
			.map(comment => {
				return {
					...comment,
					mentions: comment.mentions.map(mention => ({
						...mention,
						seen: mention.seen || mentions.includes(mention.id)
					}))
				}
			});

		this.updateTodo({ id: todo.id, comments });
	}
}
