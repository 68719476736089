import { Component, Input } from '@angular/core';
import { TodoCardComment } from '@app/todo/model/todo-card-data';

@Component({
	selector: 'todo-comment',
	templateUrl: './todo-comment.component.html',
	styleUrls: ['./todo-comment.component.scss', '../../../../shared/avatar/avatar.component.scss']
})
export class TodoCommentComponent {
	@Input() todoComment: TodoCardComment = null;
}
