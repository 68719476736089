<div class="user-filter">
	<unassigned-avatar
		class="avatar-margin"
		*ngIf="showUnassignedFilter"
		(click)="filterByUser(unassignedUser)"
		[activeEmitter]="activeUser$">
	</unassigned-avatar>
	<avatar
		class="avatar-margin"
		*ngFor="let user of users | nameInitialsSort"
		[user]="user"
		[activeEmitter]="activeUser$"
		(click)="filterByUser(user)">
	</avatar>
	<div class="user-filter__clear"
		 tooltip="Cancel filter"
		 [delay]="800"
		 (click)="cancelFilters()"
		 [class.active]="selectedUserIds?.length > 0">
		<fa-icon [icon]="faIcons.times"></fa-icon>
	</div>
</div>
