import { TooltipCreator } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/tooltip-creator';

export class CartesianTooltipCreator implements TooltipCreator {
	private axesFormatter;

	public create(tooltipConfig): any {
		return {
			chartOptions: {
				...this.axesFormatter(),
				column: {
					series: {
						...tooltipConfig,
						fillOpacity: 0.8,
					}
				},
				line: {
					series: {
						...tooltipConfig,
						strokeOpacity: 0.8,
						strokeWidth: 5,
					}
				}
			}
		}
	}

	public setAxesFormatter(axesFormatter): void {
		this.axesFormatter = axesFormatter;
	}
}
