import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';
import { fadeUpDown } from '@app/shared/animations/animations';
import { trimEnd } from '@app/shared/general-helper';
import * as _ from 'lodash';

//TODO refactor and potentially write tests
@Component({
	selector: 'select-list',
	templateUrl: './select-list.component.html',
	animations: [
		fadeUpDown,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectListComponent implements OnInit {
	@Input() items: any[] = [];
	@Input() show = false;
	@Input() selected: any = null;
	@Input() align = 'left';
	@Input() style: any;
	@Input() class = '';
	@Input() attributes: string[] = ['name'];
	@Input() template = '';
	@Input() multiple = false;
	@Input() checkAcl = false;
	@Input() colorAttr: string;
	@Input() iconAttr: string;
	@Input() enableThrottleClick = true;
	@Output() onItemSelect = new EventEmitter<any>();

	constructor(private acl: AclService) {
	}

	public ngOnInit(): void {
		if (this.checkAcl) {
			this.items = this.items.filter(item => {
				return this.acl.isAllowed(item.aclRule);
			});
		}
	}

	public onClick(item): void {
		this.onItemSelect.emit(item);
	}

	public getOptionTitle(item) {
		if (this.template) {
			const compiledTemplate = _.template(this.template);

			return compiledTemplate(item);
		} else if (this.attributes) {
			const option = _.reduce(this.attributes, (result, attr) => {
				return result += `${_.get(item, attr, '')},  `;
			}, '');
			return trimEnd(option, ', ');
		}

		return 'n/a';
	}

	public isSelected(item) {
		if (this.multiple) {
			return this.selected.some(option => parseInt(option.id, 10) === parseInt(item.id, 10)) > 0;
		}
		if (this.selected && item) {
			const selected = Array.isArray(this.selected) ? this.selected[0] : this.selected;
			if (selected) {
				return parseInt(selected.id, 10) === parseInt(item.id, 10);
			}
		}
		return false;
	}

	public resolvePath(obj, path: string) {
		return path.split('.').reduce((o, i) => o[i], obj);
	}

	public trackByFn(index) {
		return index;
	}
}
