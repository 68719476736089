import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';

@Directive({
	selector: '[aclIsAllowedSome]'
})
export class AclIsAllowedSomeDirective {

	constructor(private acl: AclService,
				private templateRef: TemplateRef<any>,
				private viewContainer: ViewContainerRef) { }

	@Input() set aclIsAllowedSome(useCases: string[]) {
		if (this.acl.isAllowedSome(useCases)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
