import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { TodoDataProviderService } from '@app/core/todo-store/todo-data-provider.service';
import { fetchDetail, loadTodos, replaceTodo, setTodos, updateTodo } from '@app/core/todo-store/todo.actions';
import { hasTodo, selectTodo } from '@app/core/todo-store/todo.selectors';
import { WebSocketMessageType } from '@app/core/web-socket/web-socket-message-type';
import { WebSocketService } from '@app/core/web-socket/web-socket.service';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { TodoCardCommentMention, TodoCardData } from '@app/todo/model/todo-card-data';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, exhaustMap, filter, first, map, mapTo, merge, mergeMap, of, switchMap } from 'rxjs';

@Injectable()
export class TodoEffects {

	loadTodos$ = createEffect(() => this.actions$.pipe(
		ofType(loadTodos),
		mergeMap(() => this.todoDataProvider.fetchAll()),
		map(todos => ({ type: '[Todos] Store Todos', todos }))
	));

	readonly websocketConnection = this.webSocketServer.getConnection()
		.pipe(
			//@todo: DataWatch replaced with TodoCreated but API doesn't send this message type yet
			filter((message) => message.type === WebSocketMessageType.TodoUpdated || message.type === WebSocketMessageType.TodoDeleted),
			switchMap(message => {
				const todoId = message.data.id;
				if (message.type === WebSocketMessageType.TodoDeleted) {
					return of(todoId);
				} else {
					return this.store.select(hasTodo(todoId))
						.pipe(
							first(),
							switchMap(isTodoInStore => {
								if (isTodoInStore) {
									return this.todoDataProvider.fetchUpdate(todoId);
								}
								return this.todoDataProvider.fetch([todoId])
									.pipe(
										map(([todo]) => {
											if (this.isTodoForCurrentUser(todo)) {
												return todo;
											}
											return null;
										})
									);
							}),
							map(todo => replaceTodo({ payload: todo }))
						)
				}
			}),
			catchError(() => EMPTY)
		);

	readonly commentWebsocketConnection = this.webSocketServer.getConnection()
		.pipe(
			filter((message) => message.type === WebSocketMessageType.CommentCreated),
			map((message) => message.data.comment),
			switchMap(comment => {
				return this.store.select(selectTodo(comment.todo.id))
					.pipe(
						first(),
						switchMap(todo => {
							if (todo?.id) {
								return of(null).pipe(mapTo(updateTodo(
									{
										payload:
											{ id: todo.id, comments: [...todo.comments, comment] }
									})));
							}
							return this.todoDataProvider.fetch([comment.todo.id])
								.pipe(
									map(([fetchedTodo]) => {
										if (this.isTodoForCurrentUser(fetchedTodo)) {
											return replaceTodo({ payload: fetchedTodo });
										}
										return updateTodo({ payload: null });
									})
								);
						}),
					)
			}),
		);

	storeTodos$ = createEffect(() => this.actions$.pipe(
		ofType(setTodos),
		mergeMap(() => merge(this.websocketConnection, this.commentWebsocketConnection))
	));

	loadTodoDetail$ = createEffect(() => this.actions$.pipe(
		ofType(fetchDetail),
		concatLatestFrom(action => this.store.select(selectTodo(action.id))),
		filter(([_, todo]) => this.todoDataProvider.mustFetchDetail(todo)),
		exhaustMap(([_, todo]) => this.todoDataProvider.fetchDetail(todo.id)),
		map(todo => ({ type: '[Todos] Update Todo', payload: todo }))
	));

	constructor(
		private actions$: Actions,
		private store: Store,
		private apiLink: GaApiLink,
		private webSocketServer: WebSocketService,
		private authService: AuthService,
		private todoDataProvider: TodoDataProviderService) {
	}

	private isTodoForCurrentUser(todo: TodoCardData): boolean {
		const currentUserId = this.authService.getCurrentUser().id;

		return todo?.createdBy?.id == currentUserId ||
			todo.assignees.some((assignee) => assignee.id == currentUserId) ||
			this.isUserMentioned(todo.comments.flatMap(comment => comment.mentions), currentUserId);
	}

	private isUserMentioned(todoMentions: TodoCardCommentMention[], userId: number): boolean {
		return todoMentions.some(mention => mention.mentionedUser.id == userId && mention.seen === false)
	}
}
