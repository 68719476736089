<div class="todo-card__description">
    <h4 *ngIf="showHeader">Description</h4>
    <div class="todo-card__description__text dont-break-out" *ngIf="!editDescription" (dblclick)="editDesc()" data-cy="todoDescription-value">
        {{ description }}
        <div class="todo-card__description__text__no-message" *ngIf="!description" @fadeInOut>There is <span>no description yet</span>. <span>Double-click</span> to add some.</div>
    </div>
    <div class="todo-card__description__edit" data-cy="todoDescription-element" *ngIf="editDescription">
        <textarea [(ngModel)]="description" data-cy="todoDescription-input" #tooltip="bs-tooltip" triggers="" tooltip="Description must contain at least 1 character"></textarea>
        <div class="todo-card__description__edit__buttons">
            <button class="btn btn--cancel" data-cy="cancel-btn" (click)="cancelEditDescription()">Cancel</button>
            <button class="btn btn--save" data-cy="submit-btn" (throttleClick)="saveDescription(tooltip)">Save</button>
        </div>
    </div>
</div>
