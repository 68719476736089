import { Injectable } from '@angular/core';
import { DropzoneDirective } from '@app/shared/draganddrop/dropzone.directive';

/**
 Service is responsible for hadling what is drag source and target, it also registers if drag operation is in progress
 */
@Injectable()
export class DraganddropService {

	isDrag = false;

	sourceZone: DropzoneDirective;

	targetZone: DropzoneDirective;

	/**
	 * drop is invoked with data that was dragged. It emits add event on target and remove event on sorce
	 *
	 */
	public drop(data) {
		this.isDrag = false;
		if (this.sourceZone) {
			this.sourceZone.dragOver = false;
		}
		if (this.targetZone) {
			this.targetZone.dragOver = false;
		}
		if (this.sourceZone && this.targetZone && this.sourceZone !== this.targetZone) {
			this.sourceZone.remove.emit(data);
			this.targetZone.add.emit(data);
		}
		this.sourceZone = this.targetZone;
		this.targetZone = undefined;
	}

	/**
	 * Registers source and target dropozones. When cursor enters dropzone and drag oparation
	 * is not in progress it sets source, when oparation is in progress it sets target
	 * @param event
	 * @param zone
	 */
	public enterDropzone(event: MouseEvent, zone) {
		if (event.which == 0) {
			this.isDrag = false;
			zone.dragOver = false;
		}
		if (this.isDrag) {
			zone.dragOver = true;
			this.targetZone = zone;
		} else {
			zone.dragOver = false;
			this.sourceZone = zone;
		}
	}

	/**
	 * when cursor leaves dropzone it sets target/source to undefined
	 * @param event
	 * @param zone
	 */
	public leaveDropzone(event: MouseEvent, zone) {
		if (this.isDrag) {
			zone.dragOver = false;
			this.targetZone = undefined;
		} else {
			this.sourceZone = undefined;
		}
	}
}
