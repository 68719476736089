<form role="search" class="navbar-form-custom" (clickOutside)="close()" autocomplete="off">
	<div class="input-section">
		<input placeholder="Search for something..."
			   class="smart-search__input base-input"
			   name="top-search"
			   id="top-search"
			   type="text"
			   autocomplete="off"
			   [(ngModel)]="searchTerm"
			   (input)="searchTerm$.next($event.target.value)"
			   (click)="open($event)"
			   (keydown)="keyDown($event)">

		<fa-icon *ngIf="inProgress" [icon]="faIcons.spinner" [spin]="true" size="2x" class="spinner"></fa-icon>

		<div class="exact-match" *ngIf="!(responsiveService.isMobile$|async)">
			<span>Exact match</span>
			<rp-switch
				[value]="exactMatch$.value"
				(switched)="onSwitched()">
			</rp-switch>
		</div>
	</div>

	<!--Search results-->
	<div class="search-suggestions" *ngIf="showSuggestions">
		<div class="items-suggestions" *ngIf="searchTerm.trim().length < 3">
			<span class="suggestion"><small>Type at least 3 letters...</small></span>
		</div>

		<!--Applicants section-->
		<div class="section applicants" [@outerResize]="sectionsState.applicants">
			<div class="items-suggestions" *ngIf="applicants.length > 0">
				<span class="suggestion-type">Applicants</span>
				<a [class.hide-me]="sectionsState.applicants !== state.Expanded" class="suggestion smart-button"
				   (click)="toggleAccordion(section.Applicants)">
					<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
					<b>Back</b></a>
				<ng-container
					*ngTemplateOutlet="applicantItems;context: {$implicit: applicants | slice:0:visibleItemsCounts.Applicants}"></ng-container>
				<div *ngIf="sectionsState.applicants === state.Expanded" [@innerResize]="sectionsState.applicants">
					<ng-container
						*ngTemplateOutlet="applicantItems;context: {$implicit: applicants | slice:visibleItemsCounts.Applicants}"></ng-container>
					<a class="suggestion smart-button" (click)="toggleAccordion(section.Applicants)">
						<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
						<b>Back</b></a>
				</div>
				<a class="suggestion smart-button" *ngIf="applicants.length > visibleItemsCounts.Applicants"
				   [class.hide-me]="sectionsState.applicants !== state.Void"
				   (click)="toggleAccordion(section.Applicants)">
					Show {{ applicants.length - visibleItemsCounts.Applicants }} more...
				</a>
			</div>
		</div>
		<ng-template #applicantItems let-items>
			<a *ngFor="let applicant of items" (click)="clean()" [routerLink]="['applicants', applicant.id]"
			   class="suggestion" [ngClass]="{'selected': isSelected(applicant)}">
				<fa-icon *ngIf="applicant.attorney && applicant.attorney.highPriority" [icon]="faIcons.star"></fa-icon>
				<strong class="searched-string"
						[innerHTML]="'#'+(applicant|uid:'applicant'|highlightSearch:searchTerm)"></strong>
				<strong class="searched-string"
						[innerHTML]="(applicant.firstName + ' ' + applicant.lastName)|highlightSearch:searchTerm"></strong>
				<i *ngIf="applicant.attorney?.lawFirm">{{ applicant.attorney.lawFirm.name }}</i>
				<i *ngIf="applicant.dateOfBirth">, <b> DOB: </b> {{ applicant.dateOfBirth | date }}</i>
				<i *ngIf="applicant.incidentDate">, <b> Incident date: </b> {{ applicant.incidentDate | date }} </i>
				<span class="funding">
					<span *ngFor="let funding of applicant.fundings">
						<span class="badge"
							  [innerHTML]="('#'+(funding|uid:'funding')+' ('+(funding.id|leftPad:6)+')'|highlightSearch:searchTerm)"
							  [ngClass]="{
						'badge__pending': fundingHelper.isPending(funding) && fundingHelper.isActive(funding),
						'badge__approved': fundingHelper.isApproved(funding) && fundingHelper.isActive(funding),
						'badge__funded': fundingHelper.isFunded(funding) && fundingHelper.isActive(funding),
						'badge__rejected': fundingHelper.isRejected(funding) && fundingHelper.isActive(funding),
						'badge__settled': fundingHelper.isSettled(funding) && fundingHelper.isActive(funding),
						'badge__archived': fundingHelper.isPreArchived(funding) || fundingHelper.isArchived(funding)
						}"></span>
					</span>
				</span>
			</a>
		</ng-template>

		<!--Attorneys section-->
		<div class="section attorneys" [@outerResize]="sectionsState.lawFirmStaff">
			<hr *ngIf="lawFirmStaff?.length > 0 && sectionsState.lawFirmStaff !== state.Expanded">
			<div class="items-suggestions" *ngIf="lawFirmStaff?.length > 0">
				<span class="suggestion-type">Law Firm Staff</span>
				<a [class.hide-me]="sectionsState.lawFirmStaff !== state.Expanded" class="suggestion smart-button"
				   (click)="toggleAccordion(section.LawFirmStaff)">
					<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
					<b>Back</b></a>
				<ng-container
					*ngTemplateOutlet="lawFirmStaffItems; context: { $implicit: lawFirmStaff | slice:0 : visibleItemsCounts.LawFirmStaff }"></ng-container>
				<div *ngIf="sectionsState.lawFirmStaff === state.Expanded" [@innerResize]="sectionsState.lawFirmStaff">
					<ng-container
						*ngTemplateOutlet="lawFirmStaffItems; context: { $implicit: lawFirmStaff | slice : visibleItemsCounts.LawFirmStaff }"></ng-container>
					<a class="suggestion smart-button" (click)="toggleAccordion(section.LawFirmStaff)">
						<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
						<b>Back</b></a>
				</div>
				<a class="suggestion smart-button" *ngIf="lawFirmStaff?.length > visibleItemsCounts.LawFirmStaff"
				   [class.hide-me]="sectionsState.lawFirmStaff !== state.Void"
				   (click)="toggleAccordion(section.LawFirmStaff)">
					Show {{ lawFirmStaff.length - visibleItemsCounts.LawFirmStaff }} more...
				</a>
			</div>
		</div>
		<ng-template #lawFirmStaffItems let-items>
			<a *ngFor="let lawFirmStaff of items" (click)="clean()"
			   [routerLink]="lawFirmStaff.lawFirm?.id ? ['/law-firms', lawFirmStaff.lawFirm?.id, 'staff', lawFirmStaff.id] : ['/law-firms/staff', lawFirmStaff.id]"
			   class="suggestion" [ngClass]="{'selected': isSelected(lawFirmStaff)}">
				<fa-icon *ngIf="lawFirmStaff.highPriority" [icon]="faIcons.star"></fa-icon>
				<strong class="searched-string"
						[innerHTML]="'#'+(lawFirmStaff | uid :'attorney'| highlightSearch : searchTerm)"></strong>
				<strong class="searched-string"
						[innerHTML]="lawFirmStaff | fullName: '' | highlightSearch:searchTerm"></strong>,
				<i><span *ngIf="lawFirmStaff.lawFirm">{{ lawFirmStaff.lawFirm.name }}</span><span
					*ngIf="lawFirmStaff.phone">, {{ lawFirmStaff.phone }}</span></i>
			</a>
		</ng-template>

		<!--Law Firms section-->
		<div class="section law-firms" [@outerResize]="sectionsState.lawFirms">
			<hr *ngIf="lawFirms?.length > 0 && sectionsState.lawFirms !== state.Expanded">
			<div class="items-suggestions" *ngIf="lawFirms?.length > 0">
				<span class="suggestion-type law-firm">Law Firms</span>
				<a [class.hide-me]="sectionsState.lawFirms !== state.Expanded" class="suggestion smart-button"
				   (click)="toggleAccordion(section.LawFirms)">
					<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
					<b>Back</b></a>
				<ng-container
					*ngTemplateOutlet="lawFirmItems;context: {$implicit: lawFirms | slice:0:visibleItemsCounts.LawFirms}"></ng-container>
				<div *ngIf="sectionsState.lawFirms === state.Expanded" [@innerResize]="sectionsState.lawFirms">
					<ng-container
						*ngTemplateOutlet="lawFirmItems;context: {$implicit: lawFirms | slice:visibleItemsCounts.LawFirms}"></ng-container>
					<a class="suggestion smart-button" (click)="toggleAccordion(section.LawFirms)">
						<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
						<b>Back</b></a>
				</div>
				<a class="suggestion smart-button" *ngIf="lawFirms?.length > visibleItemsCounts.LawFirms"
				   [class.hide-me]="sectionsState.lawFirms !== state.Void" (click)="toggleAccordion(section.LawFirms)">
					Show {{ lawFirms.length - visibleItemsCounts.LawFirms }} more...
				</a>
			</div>
		</div>
		<ng-template #lawFirmItems let-items>
			<a *ngFor="let lawFirm of items" (click)="clean()" [routerLink]="['/law-firms',  lawFirm.id, 'detail']"
			   class="suggestion" [ngClass]="{'selected': isSelected(lawFirm)}">
				<fa-icon *ngIf="lawFirm.highPriorityFirm" [icon]="faIcons.star"></fa-icon>
				<strong class="searched-string" [innerHTML]="'#'+lawFirm.id|highlightSearch:searchTerm"></strong>
				<strong class="searched-string" [innerHTML]="lawFirm.name|highlightSearch:searchTerm"></strong>,
				<i>
					<span *ngIf="lawFirm.street">{{ lawFirm.street }}</span>
					<span *ngIf="lawFirm.city">, {{ lawFirm.city }}</span>
					<span *ngIf="lawFirm.state">, {{ lawFirm.state.code }}</span>
				</i>
			</a>
		</ng-template>

		<!-- Doctors Section !-->
		<div class="section doctors" [@outerResize]="sectionsState.doctors">
			<hr *ngIf="doctors?.length > 0 && sectionsState.doctors !== state.Expanded">
			<div class="items-suggestions" *ngIf="doctors?.length > 0">
				<span class="suggestion-type law-firm">Doctors</span>
				<a [class.hide-me]="sectionsState.doctors !== state.Expanded" class="suggestion smart-button"
				   (click)="toggleAccordion(section.Doctors)">
					<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
					<b>Back</b></a>
				<ng-container
					*ngTemplateOutlet="doctorItems;context: {$implicit: doctors | slice:0:visibleItemsCounts.Doctors}"></ng-container>
				<div *ngIf="sectionsState.doctors === state.Expanded" [@innerResize]="sectionsState.doctors">
					<ng-container
						*ngTemplateOutlet="doctorItems;context: {$implicit: doctors | slice:visibleItemsCounts.Doctors}"></ng-container>
					<a class="suggestion smart-button" (click)="toggleAccordion(section.Doctors)">
						<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
						<b>Back</b></a>
				</div>
				<a class="suggestion smart-button" *ngIf="doctors?.length > visibleItemsCounts.Doctors"
				   [class.hide-me]="sectionsState.doctors !== state.Void" (click)="toggleAccordion(section.Doctors)">
					Show {{ doctors.length - visibleItemsCounts.Doctors }} more...
				</a>
			</div>
		</div>
		<ng-template #doctorItems let-items>
			<a *ngFor="let doctor of items" (click)="clean()" [routerLink]="['/doctors', doctor.id]" class="suggestion"
			   [ngClass]="{'selected': isSelected(doctor)}">
				<strong class="searched-string" [innerHTML]="'#'+doctor.id|highlightSearch:searchTerm"></strong>
				<strong class="searched-string" [innerHTML]="doctor.name|highlightSearch:searchTerm"></strong>,
				<i>
					{{ doctor.specialization?.title || 'N/A' }}
				</i>
			</a>
		</ng-template>

		<!-- Medical Facilities Section !-->
		<div class="section medical-facilities" [@outerResize]="sectionsState.medicalFacilities">
			<hr *ngIf="medicalFacilities?.length > 0 && sectionsState.medicalFacilities !== state.Expanded">
			<div class="items-suggestions" *ngIf="medicalFacilities?.length > 0">
				<span class="suggestion-type law-firm">Medical Facilities</span>
				<a [class.hide-me]="sectionsState.medicalFacilities !== state.Expanded" class="suggestion smart-button"
				   (click)="toggleAccordion(section.MedicalFacilities)">
					<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
					<b>Back</b></a>
				<ng-container
					*ngTemplateOutlet="medicalFacilityItems;context: {$implicit: medicalFacilities | slice:0:visibleItemsCounts.MedicalFacilities}"></ng-container>
				<div *ngIf="sectionsState.medicalFacilities === state.Expanded"
					 [@innerResize]="sectionsState.medicalFacilities">
					<ng-container
						*ngTemplateOutlet="medicalFacilityItems;context: {$implicit: medicalFacilities | slice:visibleItemsCounts.MedicalFacilities}"></ng-container>
					<a class="suggestion smart-button" (click)="toggleAccordion(section.MedicalFacilities)">
						<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
						<b>Back</b></a>
				</div>
				<a class="suggestion smart-button"
				   *ngIf="medicalFacilities?.length > visibleItemsCounts.MedicalFacilities"
				   [class.hide-me]="sectionsState.medicalFacilities !== state.Void"
				   (click)="toggleAccordion(section.MedicalFacilities)">
					Show {{ medicalFacilities.length - visibleItemsCounts.MedicalFacilities }} more...
				</a>
			</div>
		</div>
		<ng-template #medicalFacilityItems let-items>
			<a *ngFor="let medicalFacility of items" (click)="clean()"
			   [routerLink]="['/medical-facilities', medicalFacility.id]" class="suggestion"
			   [ngClass]="{'selected': isSelected(medicalFacility)}">
				<strong class="searched-string"
						[innerHTML]="'#'+medicalFacility.id|highlightSearch:searchTerm"></strong>
				<strong class="searched-string" [innerHTML]="medicalFacility.name|highlightSearch:searchTerm"></strong>,
				<i>
					<span *ngIf="medicalFacility.medicalFacilityType">{{ medicalFacility.medicalFacilityType.title }}
						, </span>
					<div class="loa-type-wrapper">
						<div class="loa-type" *ngIf="medicalFacility.loaType">
							<div class="loa-type__dot" [ngClass]="medicalFacility.loaType | loaTypeColor"></div>
							<span>{{ medicalFacility.loaType.title }}</span>
						</div>
					</div>
				</i>
			</a>
		</ng-template>

		<!-- Doctor Contacts Section !-->
		<div class="section doctor-contacts" [@outerResize]="sectionsState.doctorContacts">
			<hr *ngIf="doctorContacts?.length > 0 && sectionsState.doctorContacts !== state.Expanded">
			<div class="items-suggestions" *ngIf="doctorContacts?.length > 0">
				<span class="suggestion-type law-firm">Doctor Contacts</span>
				<a [class.hide-me]="sectionsState.doctorContacts !== state.Expanded" class="suggestion smart-button"
				   (click)="toggleAccordion(section.DoctorContacts)">
					<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
					<b>Back</b></a>
				<ng-container
					*ngTemplateOutlet="doctorContactsItems;context: {$implicit: doctorContacts | slice:0:visibleItemsCounts.DoctorContacts}"></ng-container>
				<div *ngIf="sectionsState.doctorContacts === state.Expanded"
					 [@innerResize]="sectionsState.doctorContacts">
					<ng-container
						*ngTemplateOutlet="doctorContactsItems;context: {$implicit: doctorContacts | slice:visibleItemsCounts.DoctorContacts}"></ng-container>
					<a class="suggestion smart-button" (click)="toggleAccordion(section.DoctorContacts)">
						<fa-icon [icon]="faIcons.chevronLeft"></fa-icon>
						<b>Back</b></a>
				</div>
				<a class="suggestion smart-button"
				   *ngIf="doctorContacts?.length > visibleItemsCounts.DoctorContacts"
				   [class.hide-me]="sectionsState.doctorContacts !== state.Void"
				   (click)="toggleAccordion(section.DoctorContacts)">
					Show {{ doctorContacts.length - visibleItemsCounts.DoctorContacts }} more...
				</a>
			</div>
		</div>
		<ng-template #doctorContactsItems let-items>
			<a *ngFor="let doctorContacts of items" (click)="clean()"
			   [routerLink]="['/doctors', doctorContacts.doctor.id]" class="suggestion"
			   [ngClass]="{'selected': isSelected(doctorContacts)}">
				<strong class="searched-string"
						[innerHTML]="'#'+doctorContacts.id|highlightSearch:searchTerm"></strong>
				<strong class="searched-string"
						[innerHTML]="doctorContacts.firstName+' '+doctorContacts.lastName|highlightSearch:searchTerm"></strong>,
				<i> {{ doctorContacts?.doctor.name }} </i>
			</a>
		</ng-template>
	</div>
</form>
