import { createAction, props } from '@ngrx/store';

// fetch all fundings
export const loadFundings = createAction('[BOARDS] Load Fundings', props<{ board: string }>());
export const storeFundings = createAction('[BOARDS] Fundings Loaded Success', props<{ loadedBoard: string, fundings: Array<any> }>());
// remove fundings
export const removeFundings = createAction('[BOARDS] Remove Fundings', props<{ ids: Array<string> }>());
// update fundings
export const socketUpdateFundings = createAction('[BOARDS] Socket Update Fundings', props<{ ids: Array<string> }>());
export const updateFundings = createAction('[BOARDS] Update Fundings', props<{ fundings: Array<any> }>());

export const clearBoards = createAction('[BOARDS] Clear');
