import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Output, Renderer2 } from '@angular/core';
import { DraganddropService } from '@app/shared/draganddrop/draganddrop.service';

@Directive({
	selector: '[gaDropzone]'
})
/* eslint-disable @angular-eslint/no-output-rename */
export class DropzoneDirective {

	@Output('add') add: EventEmitter<any> = new EventEmitter<any>();
	@Output('remove') remove: EventEmitter<any> = new EventEmitter<any>();
	@HostBinding('class.drag-over') dragOver = false;
	element;

	constructor(
		private renderer: Renderer2,
		private draganddropService: DraganddropService,
		public elementRef: ElementRef
	) {
		this.element = elementRef.nativeElement;
	}

	@HostListener('mouseenter', ['$event'])
	public onEnter(event: MouseEvent) {
		this.draganddropService.enterDropzone(event, this);
	}

	@HostListener('mouseleave', ['$event'])
	public onLeave(event: MouseEvent) {
		this.draganddropService.leaveDropzone(event, this);
	}
}
