import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({name: 'codelist'})
export class CodelistPipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) {}

	public transform(input, property): SafeHtml {
		let content;
		const val = property ? input[property] : input;
		if (val) {
			if (val.other) {
				content = 'Other:' + input[property + 'Other'];
				return this.sanitizer.bypassSecurityTrustHtml(content);
			}
			return this.sanitizer.bypassSecurityTrustHtml(val.title);
		}
		return this.sanitizer.bypassSecurityTrustHtml("<span class='badge badge-default'>N/A</span>");
	}
}
