import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { EntityStat } from '@app/dashboard/top-five-section/top-entities-stats/top-entities-stats.model';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class TopEntitiesStatsService {
	private dataUrl: string;
	private filter$ = new BehaviorSubject<string>('');

	constructor(
		private dashboardFilterStore: DashboardFilterStore,
		private http: HttpClient) {
	}

	public setUrl(url: string): void {
		this.dataUrl = url;
	}

	public getData(): Observable<EntityStat[]> {
		return this.dashboardFilterStore.fundingParams$.pipe(
			switchMap(params => this.http.get<EntityStat[]>(this.dataUrl, { params })),
			map(entitiesStats => {
				const totalPurchasePriceSum = entitiesStats.reduce((acc, entityStat) => acc + entityStat.purchasePriceSum, 0);
				return entitiesStats.map(entityStat => ({
					...entityStat,
					title: entityStat.title ?? '(Blanks)',
					percentage: entityStat.purchasePriceSum / totalPurchasePriceSum
				}));
			}),
			shareReplay({ bufferSize: 1, refCount: true })
		);
	}

	public updateFilter(filter: string): void {
		this.filter$.next(filter);
	}

}
