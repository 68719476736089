import { formatPercent } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { addPercentage, getSumByAllProperties, Percentage } from '@app/dashboard/top-five-section/shared/top-five-section.utils';
import { CartesianSavedChart } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/cartesian-saved-chart';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { CartesianGroupedTooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/cartesian-grouped-tooltip-theme.service';
import { chartNumberAxesTheme } from '@app/shared/ag-grid-utils/chart-themes/chart-axes-theme';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { ChartRef, ColDef, ColGroupDef, FirstDataRenderedEvent, GridApi } from 'ag-grid-community';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

type CaseTypeBreakdownData = Required<CaseTypeStat & Percentage<CaseTypeStat>>;

@Component({
	selector: 'case-type-breakdown',
	templateUrl: 'case-type-breakdown.component.html',
	styles: [':host { width: 100%; }', '.grid-list { height: 300px; }'],
})
export class CaseTypeBreakdownComponent implements OnInit, OnDestroy {

	rowData$: Observable<CaseTypeBreakdownData[]>;
	colDefs: (ColDef | ColGroupDef)[];
	gridOptions;

	hasSavedChart$ = new ReplaySubject<boolean>(1);

	readonly clearIcon = SvgIconName.Preferences;

	protected gridApi: GridApi;

	private tooltipTheme = new CartesianGroupedTooltipThemeService();
	private chartSaveDirective = new CartesianSavedChart(
		'dashboard-case-type-chart',
		() => chartNumberAxesTheme('Case Type Breakdown'),
		this.tooltipTheme
	);
	private chartRef: ChartRef;

	private destroy$ = new Subject<void>();

	constructor(
		private agGridUtils: AgGridUtilsService,
		private dashboardFilterStore: DashboardFilterStore,
		private http: HttpClient,
	) {
	}

	public ngOnInit(): void {
		this.gridOptions = this.agGridUtils.getGridOptions({
			sideBar: false,
			defaultExcelExportParams: {
				fileName: 'Case Type Breakdown Export'
			}
		});
		this.rowData$ = this.dashboardFilterStore.fundingParams$
			.pipe(
				tap(() => this.gridApi?.showLoadingOverlay()),
				switchMap(params => this.http.get('/api/dashboard/case-type-breakdown', { params })),
				map((res: { caseTypeBreakdown: CaseTypeStat[] }) => res.caseTypeBreakdown),
				getSumByAllProperties<CaseTypeStat>(),
				addPercentage<CaseTypeStat>(),
				tap(() => this.gridApi?.hideOverlay()),
				shareReplay({ bufferSize: 1, refCount: true })
			);
		this.rowData$
			.pipe(
				map(data => data.map(datum => ({ ...datum, chartLabel: datum.title }))),
				takeUntil(this.destroy$)
			).subscribe(data => {
			this.tooltipTheme.setTooltipData(data);
		});

		this.colDefs = [
			{
				field: 'title',
				chartDataType: 'category'
			},
			{
				headerName: 'Fundings',
				children: [
					{
						...this.tooltipTheme.addPercentageColumn(
							'Percentage',
							'fundingsPercentage'
						),
						valueFormatter: ({ value }) => formatPercent(value, 'en-US', '1.0-0'),
					},
					{
						...this.tooltipTheme.addNumberColumn(
							'Fundings',
							'fundings'
						),
						headerValueGetter: ({ location }) => {
							if (location === 'chart') {
								return 'Fundings';
							}
							return 'Amount';
						},
					}
				]
			},
			{
				headerName: 'Applicants',
				children: [
					{
						...this.tooltipTheme.addPercentageColumn(
							'Percentage',
							'applicantsPercentage'
						),
						valueFormatter: ({ value }) => formatPercent(value, 'en-US', '1.0-0'),
					},
					{
						...this.tooltipTheme.addNumberColumn(
							'Applicants',
							'applicants'
						),
						headerValueGetter: ({ location }) => {
							if (location === 'chart') {
								return 'Applicants';
							}
							return 'Amount';
						},
					}
				]
			}
		];
	}

	public onFirstDataRendered(params: FirstDataRenderedEvent) {
		params.api.sizeColumnsToFit();
		this.gridApi = params.api;
		const savedChart = this.getSavedChart();
		if (savedChart !== null) {
			this.chartRef = savedChart;
			this.tooltipTheme.setChart(this.chartRef.chart);
			return;
		}
		this.createDefaultChart();
	}

	private createDefaultChart() {
		if (this.chartRef != null) {
			this.chartRef.destroyChart();
		}
		const groupedTooltips = this.tooltipTheme.getGroupedTooltipTheme(['fundings', 'applicants']);
		this.chartRef = this.gridApi.createRangeChart({
			chartType: 'customCombo',
			cellRange: {
				columns: ['title', 'fundings', 'applicants']
			},
			seriesChartTypes: [
				{ colId: 'fundings', chartType: 'groupedColumn' },
				{ colId: 'applicants', chartType: 'groupedColumn' }
			],
			chartThemeName: 'ag-pastel',
			chartContainer: this.getChartElement(),
			chartThemeOverrides: {
				...chartNumberAxesTheme('Case Type Breakdown'),
				column: {
					series: {
						...groupedTooltips
					}
				}
			}
		});

		this.tooltipTheme.setChart(this.chartRef.chart);
	}

	private getSavedChart() {
		const chartModel = this.chartSaveDirective.getSavedChart();
		if (chartModel !== null) {
			this.hasSavedChart$.next(true);
			return this.gridApi.restoreChart(chartModel, this.getChartElement());
		}
		return null;
	}

	protected getChartElement(): HTMLElement {
		return document.querySelector('#caseType-chart');
	}

	public onChartOptionsChanged() {
		const modelsToSave = this.gridApi.getChartModels();
		const colIds = modelsToSave[0].cellRange.columns as string[];
		this.chartSaveDirective.setTooltipIds(colIds);
		this.chartSaveDirective.saveChart(modelsToSave);
		this.hasSavedChart$.next(true);
	}

	public clearSavedChartState() {
		this.chartSaveDirective.clearSavedChart();
		this.hasSavedChart$.next(false);
		this.createDefaultChart();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.chartRef?.destroyChart();
	}

}

type CaseTypeStat = {
	fundings: number;
	applicants: number;
	title: string;
}
