import { Component, OnInit } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { fadeInOut } from '@app/shared/animations/animations';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { ModalDismissReason } from '@app/shared/model/constants/modal-dismiss-reason';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { Codelist } from '@app/shared/model/types/codelists/codelist';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Component({
	selector: 'archived-reason',
	templateUrl: './archived-reason.component.html',
	animations: [
		fadeInOut
	],
})
export class ArchivedReasonComponent implements OnInit {

	funding: any;
	isLoading = false;
	archivedReasonNote: string;
	selectedArchivedReason: Codelist;
	archivedReasonTypes$: Observable<Codelist[]>;
	showArchiveReasonTypeSelect = false;

	constructor(public codeListService: CodelistRepositoryService,
				public flashMessage: FlashMessageService,
				private modalService: BsModalService,
				public _bsModalRef: BsModalRef,
				public apiLink: GaApiLink) {
	}

	public ngOnInit(): void {
		this.archivedReasonTypes$ = this.codeListService.get<Codelist[]>(NonUrlCodelistName.ArchivedReasonType);
	}

	public closeModal(): void {
		this.modalService.setDismissReason(ModalDismissReason.Cancel);
		this._bsModalRef.hide();
	}

	public archiveFunding(): void {
		if (this.selectedArchivedReason && this.archivedReasonNote) {
			this.isLoading = true;
			const sb = this.apiLink.update(
				'funding',
				this.funding,
				{
					preArchivedOn: null,
					archived: true,
					archivedReasonType: this.selectedArchivedReason.id,
					archivedReasonNote: this.archivedReasonNote
				},
				[]).subscribe(() => {
					this.isLoading = false;
					this.funding.preArchivedOn = null;
					this.funding.archived = true;
					this.modalService.setDismissReason(ModalDismissReason.Success);
					this._bsModalRef.hide();
					sb.unsubscribe();
				}
			);
		} else {
			this.flashMessage.showMessage(FlashMessageType.Info, 'Please fill in all required fields.');
		}
	}

	public toggleArchiveReasonTypeSelect(): void {
		this.showArchiveReasonTypeSelect = !this.showArchiveReasonTypeSelect;
	}

	public changeArchiveReasonType(archiveReasonType): void {
		this.selectedArchivedReason = archiveReasonType;
		this.showArchiveReasonTypeSelect = false;
	}

}
