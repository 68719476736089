import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RevisionStorageService } from '@app/core/revisions/revision-storage.service';
import { IRevisions, Revisions, RevisionType } from '@app/core/revisions/types/revision-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class RevisionsService {

	constructor(
		private http: HttpClient,
		private revisionStorage: RevisionStorageService
	) {}

	public getCurrentRevisions(): Observable<IRevisions> {
		return this.http.get('/api/revisions')
			.pipe(
				map((response) => RevisionsService.transformRevisions(response))
			);
	}

	public getRevision(type: RevisionType) {
		return this.revisionStorage.getRevision(type);
	}

	public isRevisionUpToDate(type: RevisionType, revisionToCheck: string): boolean {
		return this.revisionStorage.isRevisionUpToDate(type, revisionToCheck);
	}

	public storeRevision(revisionType: RevisionType, newRevision: string) {
		console.info(`* Storing revision '${newRevision}' for '${revisionType}'...`);
		this.revisionStorage.storeRevision(revisionType, newRevision);
	}

	public clearRevision(revisionType: RevisionType) {
		this.revisionStorage.storeRevision(revisionType, '');
	}

	private static transformRevisions(response: any): IRevisions {
		return Revisions.fromResponse(response);
	}
}
