import { Injectable } from '@angular/core';
import { Address } from '@app/shared/model/types/address';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class GaUtils {

	public determineTextColor(bgColor) {
		if (bgColor) {
			const rgbOfBackgroundColor =
				[parseInt(bgColor[1] + bgColor[2], 16), parseInt(bgColor[3] + bgColor[4], 16), parseInt(bgColor[5] + bgColor[6], 16)];

			const lightnessOfBackgroundColor =
				(rgbOfBackgroundColor[0] * 0.2126 + rgbOfBackgroundColor[1] * 0.7152 + rgbOfBackgroundColor[2] * 0.0722) / 255;

			return lightnessOfBackgroundColor > 0.6 ? 'black' : 'white';
		}

		return 'black';
	}

	public calculateDiffInWeeks(date) {
		if (date) {
			return moment().diff(date, 'weeks');
		}

		return 'n/a'
	}

	public formatDate(date, format = 'MM/DD/YYYY') {
		if (date) {
			return moment(date).format(format);
		}

		return 'n/a';
	}

	public formatUserAccountName(userAccountObject, defaultValue = 'N/A') {
		if (!userAccountObject) {
			return defaultValue;
		} else {
			const userName = userAccountObject instanceof Object
				? `${userAccountObject?.firstName?.trim() ?? ''} ${userAccountObject?.lastName?.trim() ?? ''}`
				: userAccountObject;
			return userName.trim();
		}
	}

	public formatFullAddress(addressObject: Address, defaultValue = 'N/A'): string {
		if (!addressObject) return defaultValue;

		let fullAddress = '';
		fullAddress += addressObject.street ? `${addressObject.street}, ` : '';
		fullAddress += addressObject.city ? `${addressObject.city}, ` : '';
		fullAddress += addressObject.state ? `${addressObject.state.title}, ` : '';
		fullAddress += addressObject.zip ? `${addressObject.zip}, ` : '';
		fullAddress += addressObject.geographicLocation ? `${addressObject.geographicLocation.title}` : '';

		return fullAddress;
	}
}
