import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { PlacesAutocompleteAddress } from '@app/shared/model/types/places-autocomplete-address';
import { UsState } from '@app/shared/model/types/us-state';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'google-places-autocomplete',
	templateUrl: './google-places-autocomplete.component.html'
})
export class GooglePlacesAutocompleteComponent implements OnInit, OnDestroy {
	@Output() onAddressSelected = new EventEmitter<PlacesAutocompleteAddress>();

	options = { fields: ['adr_address'] };

	private states: UsState[];
	private destroy$ = new Subject<void>();

	constructor(
		private codelistRepository: CodelistRepositoryService,
	) {
	}

	public ngOnInit(): void {
		this.codelistRepository.get<UsState[]>(NonUrlCodelistName.UsState)
			.pipe(takeUntil(this.destroy$))
			.subscribe(states => this.states = states);
	}

	public handleAddressChange(addressString: string): void {
		const address = { street: '', city: '', state: '', zip: '', stateCodelistId: null, stateCodelist: null };
		const addressBridge = [
			{ stringPath: 'street-address', path: 'street' },
			{ stringPath: 'locality', path: 'city' },
			{ stringPath: 'region', path: 'state' },
			{ stringPath: 'postal-code', path: 'zip' }
		];
		addressBridge.forEach(config => {
			const match = addressString?.match(`(?<=${config.stringPath}">)[A-Za-z0-9 ]+`);
			address[config.path] = match ? match[0] : '';
		});
		const matchingState = this.states.find(state => state.code === address.state);
		address.stateCodelistId = matchingState?.id;
		address.stateCodelist = matchingState;
		if (address.stateCodelist !== undefined) {
			delete address.stateCodelist.entityString;
		}
		this.onAddressSelected.emit(address);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
