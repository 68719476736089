<div class="row m-t m-b">
	<div class="col-sm-3">
		<label>Date Range</label>
		<daterange-picker
			[bsRangeValue]="datesFilter"
			(dateRangeSelected)="onDateRangeSelected($event)">
		</daterange-picker>
	</div>
</div>

<collapsible-item>
	<h4 header-left>Chart:</h4>
	<div class="row m-t m-b" *ngIf="salesChartConfig.getLabels().length > 0">
		<div class="col-sm-12">
			<canvas
				baseChart
				id="line"
				class="chart chart-bar"
				[datasets]="salesChartConfig.getDataset()"
				[options]="chartOptions"
				[labels]="salesChartConfig.getLabels()"
				(chartClick)="onChartClick($event)"
				[type]="'bar'">
			</canvas>
		</div>
	</div>
</collapsible-item>
<div class="jumbotron" *ngIf="salesChartConfig.getLabels().length == 0 || isLoading">
	<h2 *ngIf="salesChartConfig.getLabels().length == 0 && !isLoading" class="text-center">No data available to show</h2>
	<loading-spinner class="col-sm-12" [style]="{backgroundColor : 'transparent'}" [show]="isLoading"></loading-spinner>
</div>
