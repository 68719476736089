import { Component, OnDestroy, OnInit } from '@angular/core';
import { AclService } from '@app/core/security/acl.service';
import { DashboardDataUtilsService } from '@app/dashboard/dashboard-data-utils.service';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { ConfigurationChangedEvent } from '@app/dashboard/shared/time-related-configuration/time-related-configuration.model';
import { SummaryStatisticsGraphService } from '@app/dashboard/summary-statistics/summary-statistics-graph.service';
import { SummaryStatisticsModalService } from '@app/dashboard/summary-statistics/summary-statistics-modal.service';
import { SummaryStatisticsChartConfig, SummaryStatisticsChartId, SummaryStatisticsChartMode, SummaryStatisticsResponse } from '@app/dashboard/summary-statistics/summary-statistics.model';
import { SummaryStatisticsService } from '@app/dashboard/summary-statistics/summary-statistics.service';
import { CartesianSavedChart } from '@app/shared/ag-grid-utils/ag-chart-setting-persisting/cartesian-saved-chart';
import { AgGridUtilsService } from '@app/shared/ag-grid-utils/ag-grid-utils.service';
import { CartesianGroupedTooltipThemeService } from '@app/shared/ag-grid-utils/chart-themes/cartesian-grouped-tooltip-theme.service';
import { chartNumberAxesTheme, chartPriceAxesTheme } from '@app/shared/ag-grid-utils/chart-themes/chart-axes-theme';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ColDef, ColGroupDef, FirstDataRenderedEvent, GridApi, GridReadyEvent } from 'ag-grid-community';
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject, shareReplay, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
	selector: 'sales-summary-statistics',
	templateUrl: 'sales-summary-statistics.component.html',
	providers: [
		SummaryStatisticsService,
		SummaryStatisticsModalService,
		SummaryStatisticsGraphService,
	]
})
export class SalesSummaryStatisticsComponent implements OnInit, OnDestroy {

	data$: Observable<SummaryStatisticsResponse[]>;

	colDefs: (ColDef | ColGroupDef)[];
	gridOptions;

	hasSavedChart$: Observable<boolean>;

	readonly clearIcon = SvgIconName.Preferences;

	collapsed$ = new BehaviorSubject(false);
	collapsingIcon$: Observable<IconDefinition>;

	private gridApi: GridApi;

	private readonly savedCharts = new Map<SummaryStatisticsChartId, CartesianSavedChart>();
	private timeConfiguration$ = new ReplaySubject<ConfigurationChangedEvent>(1);

	private destroy$ = new Subject<void>();

	constructor(
		private dashboardDataUtilsService: DashboardDataUtilsService,
		private dashboardFilterStore: DashboardFilterStore,
		private agGridUtils: AgGridUtilsService,
		private acl: AclService,
		private summaryStatisticsGraphService: SummaryStatisticsGraphService,
		private summaryStatisticsModalService: SummaryStatisticsModalService,
		private summaryStatisticsService: SummaryStatisticsService
	) {
	}

	public ngOnInit(): void {
		this.hasSavedChart$ = this.summaryStatisticsGraphService.hasSavedChart$;
		this.data$ = combineLatest([this.timeConfiguration$, this.dashboardFilterStore.fundingParams$])
			.pipe(
				switchMap(([configuration, fundingTypeParams]) =>
					this.summaryStatisticsService.getTotalFundedData$(configuration, fundingTypeParams)),
				shareReplay({ bufferSize: 1, refCount: true })
			);
		this.gridOptions = this.agGridUtils.getGridOptions({
			sideBar: false,
			defaultExcelExportParams: {
				fileName: 'Summary Statistics Export'
			},
		});
		const tooltipTheme = new CartesianGroupedTooltipThemeService();
		this.colDefs = [
			{
				headerName: 'Period',
				field: 'title',
				...this.dashboardDataUtilsService.getPeriodColDef(),
				pinned: 'left',
				minWidth: 90,
				width: 90,
				chartDataType: 'category'
			},
			{
				headerName: 'Total Funded in Period',
				children: [
					{
						...tooltipTheme.addNumberColumn(
							'# of Fundings',
							'fundedDetails.numberOfFundings'
						),
						minWidth: 100,
						width: 100,
					},
					{
						field: 'fundedDetails.growthDeclineFundings',
						...this.summaryStatisticsService.getGrowthDeclineColDef()
					},
					{
						...tooltipTheme.addNumberColumn(
							'# of New Cases',
							'fundedDetails.numberOfCases'
						),
						minWidth: 120,
						width: 120,
					},
					{
						...tooltipTheme.addPriceColumn(
							'Sales [$]',
							'fundedDetails.dealsAmountWithEffectiveRates'
						),
						minWidth: 150,
						width: 150,
					},
					{
						field: 'fundedDetails.growthDeclinePrice',
						...this.summaryStatisticsService.getGrowthDeclineColDef()
					},
					{
						...this.getInfoButtonColDef(data => this.summaryStatisticsModalService.openFundedDetailForSales(data))
					}
				]
			}
		];
		if (this.acl.isAllowed('dashboard.collected_stats')) {
			this.colDefs.push({
					headerName: 'Settled in Vintage',
					children: [
						{
							...tooltipTheme.addNumberColumn('# of Settled', 'settledDetails.numberOfFundings'),
							minWidth: 100,
							width: 100
						},
						{
							...tooltipTheme.addPriceColumn(
								'Principal Collected',
								'settledDetails.principalCollected'
							),
							minWidth: 150,
							width: 150,
						},
						{
							...tooltipTheme.addPriceColumn(
								'Principal Loss',
								'settledDetails.principalLoss'
							),
							minWidth: 120,
							width: 120,
						},
						{
							...tooltipTheme.addPriceColumn(
								'Principal Settled',
								'settledDetails.principalSettled'
							),
							minWidth: 150,
							width: 150,
						},
						{
							headerName: '% Settled',
							minWidth: 90,
							width: 90,
							field: 'settledDetails.settledPercentage',
							chartDataType: 'excluded'
						},
						{
							...this.getInfoButtonColDef(data => this.summaryStatisticsModalService.openDetailSettled(data))
						}
					]
				},
				{
					headerName: 'Total Collected in Period',
					children: [
						{
							...tooltipTheme.addNumberColumn(
								'# of Collected',
								'collectedDetails.numberOfFundings'
							),
							minWidth: 100,
							width: 100,
						},
						{
							...tooltipTheme.addPriceColumn(
								'$ Total',
								'collectedDetails.totalPrice'
							),
							minWidth: 120,
							width: 120,
						},
						{
							...tooltipTheme.addPriceColumn(
								'$ Principal',
								'collectedDetails.totalPrincipal'
							),
							minWidth: 120,
							width: 120,
						},
						{
							...tooltipTheme.addPriceColumn(
								'$ Fee',
								'collectedDetails.totalFee'
							),
							minWidth: 120,
							width: 120,
						},
						{
							...this.getInfoButtonColDef(data => this.summaryStatisticsModalService.openDetailCollected(data))
						}
					]
				});
		}

		this.savedCharts.set(SummaryStatisticsChartId.EffectiveRatesFundings, new CartesianSavedChart(
			`sales-dashboard-summary-statistics-${SummaryStatisticsChartMode.EffectiveRates}-chart`,
			() => chartNumberAxesTheme('Summary Statistics - Effective Rates Fundings'),
			tooltipTheme.copy()
		));
		this.savedCharts.set(SummaryStatisticsChartId.EffectiveRatesDollars, new CartesianSavedChart(
			`sales-dashboard-summary-statistics-${SummaryStatisticsChartMode.EffectiveRates}-chart`,
			() => chartPriceAxesTheme('Summary Statistics - Effective Rates Amount'),
			tooltipTheme.copy(),
			1
		));
		this.data$
			.pipe(
				map(data => data.map(summaryStatistics => ({
					...summaryStatistics,
					chartLabel: this.dashboardDataUtilsService.getPeriodLabel(summaryStatistics)
				}))),
				takeUntil(this.destroy$)
			).subscribe(data => {
			this.savedCharts.forEach(savedChart => savedChart.getTooltipTheme().setTooltipData(data));
		});
		this.collapsingIcon$ = this.collapsed$.pipe(
			map((collapsed) => collapsed ? faChevronDown : faChevronUp)
		);
	}

	private getInfoButtonColDef(modalAction): ColDef {
		return this.summaryStatisticsService.getInfoButtonColDef(modalAction);
	}

	public onFirstDataRendered(params: FirstDataRenderedEvent): void {
		params.api.sizeColumnsToFit();
	}

	public onGridReady(params: GridReadyEvent): void {
		this.gridApi = params.api;
		this.summaryStatisticsGraphService.initialize(params.api, this.savedCharts);
		this.createEffectiveRatesChart();
	}

	private createEffectiveRatesChart(): void {
		const fundingChart: SummaryStatisticsChartConfig = {
			columns: [
				{ colId: 'fundedDetails.numberOfFundings', chartType: 'groupedColumn' },
				{ colId: 'fundedDetails.numberOfCases', chartType: 'groupedColumn' }
			],
			chartId: SummaryStatisticsChartId.EffectiveRatesFundings,
			chartElementSelector: '#sales-summary-statistics-deals-chart'
		};

		const currencyChart: SummaryStatisticsChartConfig = {
			columns: [
				{ colId: 'fundedDetails.dealsAmountWithEffectiveRates', chartType: 'groupedColumn' }
			],
			chartId: SummaryStatisticsChartId.EffectiveRatesDollars,
			chartElementSelector: '#sales-summary-statistics-dollars-chart'
		};

		this.summaryStatisticsGraphService.createCharts([fundingChart, currencyChart]);
	}

	public onConfigurationChanged(event: ConfigurationChangedEvent): void {
		this.timeConfiguration$.next(event);
	}

	public onChartOptionsChanged(): void {
		const modelsToSave = this.gridApi.getChartModels();
		const saveCharts = this.getSaveChartsForCurrentMode();
		saveCharts.forEach((savedChart, index) => {
			const colIds = modelsToSave[index].cellRange.columns as string[];
			savedChart.setTooltipIds(colIds);
			savedChart.saveChart(modelsToSave);
		});
		this.summaryStatisticsGraphService.hasSavedChart = true;
	}

	public clearSavedChartState(): void {
		const savedCharts = this.getSaveChartsForCurrentMode();
		savedCharts.forEach(savedChart => savedChart.clearSavedChart());
		this.createEffectiveRatesChart();
		this.summaryStatisticsGraphService.hasSavedChart = false;
	}

	private getSaveChartsForCurrentMode(): CartesianSavedChart[] {
		return Array.from(this.savedCharts.values());
	}

	public toggleCollapsed(): void {
		this.collapsed$.next(!this.collapsed$.value);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.summaryStatisticsGraphService.destroyCharts();
		this.summaryStatisticsModalService.completeDestroyObs();
	}
}
